import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M11.944 3.333v18.334h5.12v15l11.944-20h-6.826l5.12-13.334H11.944z"
      ></path>
    </svg>
  );
}

export default Icon;
