import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import "./styles.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
}));

export default function MenuModal(props) {
  const classes = useStyles();
  const intl = useIntl();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props["open"] || false);

  const items = [
    {
      id: "value",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.VALUE" }),
      image_url: "/images/dashboard/value.jpg",
      description: intl.formatMessage({ id: "CLIENT.DASHBOARD.VALUE_DESC" }),
    },
    {
      id: "value_list",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.VALUE_LIST" }),
      image_url: "/images/dashboard/value-list.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.VALUE_LIST_DESC",
      }),
    },
    {
      id: "static_text",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.STATIC_TEXT" }),
      image_url: "/images/dashboard/static-text.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.STATIC_TEXT_DESC",
      }),
    },
    {
      id: "alarm_summary",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.ALARM_SUMMARY" }),
      image_url: "/images/dashboard/alarm-summary.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.ALARM_SUMMARY_DESC",
      }),
    },
    {
      id: "attachment_image",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.ATTACHMENT_IMAGE" }),
      image_url: "/images/dashboard/attachment-image.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.ATTACHMENT_IMAGE_DESC",
      }),
    },
    {
      id: "direction",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.DIRECTION" }),
      image_url: "/images/dashboard/direction.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.DIRECTION_DESC",
      }),
    },
    {
      id: "historic_table",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.HISTORIC_TABLE" }),
      image_url: "/images/dashboard/historic-table.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.HISTORIC_TABLE_DESC",
      }),
    },
    {
      id: "standard_chart",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.STANDARD_CHART" }),
      image_url: "/images/dashboard/standard-chart.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.STANDARD_CHART_DESC",
      }),
    },
    {
      id: "location_map",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.LOCATION_MAP" }),
      image_url: "/images/dashboard/location-map.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.LOCATION_MAP_DESC",
      }),
    },
    {
      id: "gauge_chart",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.GAUGE_CHART" }),
      image_url: "/images/dashboard/gauge-chart.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.GAUGE_CHART_DESC",
      }),
    },
    {
      id: "custom_chart",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.CUSTOM_CHART" }),
      image_url: "/images/dashboard/custom.png",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.CUSTOM_CHART_DESC",
      }),
    },
    {
      id: "prediction_parameter_history_table",
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.PREDICTION_PARAMETER_HISTORY_TABLE",
      }),
      image_url: "/images/dashboard/historic-table.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.PREDICTION_PARAMETER_HISTORY_TABLE",
      }),
    },
    {
      id: "prediction_parameter_standard_chart",
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.PREDICTION_PARAMETER_LINE_CHART",
      }),
      image_url: "/images/dashboard/standard-chart.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.PREDICTION_PARAMETER_LINE_CHART",
      }),
    },
    {
      id: "aggregate_table",
      title: intl.formatMessage({ id: "CLIENT.DASHBOARD.AGGREGATE_TABLE" }),
      image_url: "/images/dashboard/historic-table.jpg",
      description: intl.formatMessage({
        id: "CLIENT.DASHBOARD.AGGREGATE_TABLE_DESC",
      }),
    },
  ];

  const addNewCard = (type) => {
    console.log("type", type);
    props["handleCreate"](type.toUpperCase(), null);
    props["handleClose"]();
  };

  const renderHeader = () => {
    return (
      <div className="menu-modal-header">
        <div className="menu-modal-header-left">
          <FormattedMessage id="CLIENT.DASHBOARD.SELECT_AN_ITEM" />
        </div>
        <div className="menu-modal-header-right">
          <span className="menu-modal-header-icon">
            <CloseIcon onClick={props["handleClose"]} />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="menu-modal-body">
        {items.map((item) => (
          <div
            className="menu-modal-card"
            onClick={() => addNewCard(item["id"])}
            key={item["id"]}
          >
            <img
              src={item["image_url"] || "/images/dashboard.png"}
              alt=""
              className="menu-modal-card-image"
            />

            <div className="menu-modal-card-text">
              <h3 className="menu-modal-card-title">{item["title"]}</h3>
              <p className="menu-modal-card-description">
                {item["description"]}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  React.useEffect(() => {
    setOpen(props["open"]);
  }, [props]);

  return (
    <Modal
      open={open}
      onClose={props["handleClose"]}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}
