import crypto from 'crypto-js';
const secretKey = "reeCoTech2021Crypto"

const encryptKey = (id) => {
  let str = crypto.AES.encrypt(id.toString(), secretKey).toString();
  str = str.replace(/[+]/g, 'xMl3Jk')
    .replace(/[/]/g, 'Por21Ld')
    .replace(/[=]/g, 'Ml32');
  return str;
}


const decryptKey = (cipher) => {
  cipher = cipher.replace(/xMl3Jk/g, '+')
    .replace(/Por21Ld/g, '/')
    .replace(/Ml32/g, '=');

  // Decrypt
  return crypto.AES.decrypt(cipher, secretKey)
    .toString(crypto.enc.Utf8);

}

export {
  encryptKey,
  decryptKey
}