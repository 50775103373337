import React from "react";
import "./styles.css";
import { Tooltip } from "@material-ui/core";

const DEFAULT_SIZE = {
  small: "13px",
  medium: "30px",
  large: "50px",
};

const ColorPoint = React.forwardRef(
  (
    {
      color,
      defaultColor = "#000000",
      tooltip,
      size = "medium",
      borderRadius = "50%",
      style,
      ...props
    },
    ref
  ) => (
    <Tooltip title={tooltip}>
      <div
        style={{
          backgroundColor: color || defaultColor,
          width: DEFAULT_SIZE[size],
          height: DEFAULT_SIZE[size],
          cursor: "pointer",
          borderRadius,
          ...style,
        }}
        // className="color-point"
        ref={ref}
        {...props}
      />
    </Tooltip>
  )
);

export default ColorPoint;
