import React, { useState, useEffect } from "react";
import { ListItem, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/NavigateNext";
import RoomIcon from "@material-ui/icons/Room";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Link, useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { setActiveSubItem } from "../../../../../redux/actions/dashboardActions";
import useDetectDevice from "app/shared/hooks/useDetectDevice";
import { triggerSidebar } from "app/redux/actions/sidebar.action";

export default function SidebarItem(props) {
  const {
    id,
    link,
    itemKey,
    currentPath,
    renderKey,
    activeIcon,
    icon,
    name,
    subs,
    workspaces,
    renderNavs,
    iconType = "",
    active,
  } = props;
  const [itemHover, setItemHover] = useState(null);
  const [activeItems, setActiveItems] = useState([]);
  const location = useLocation();

  const dispatch = useDispatch();
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual
  );

  const { deviceType } = useDetectDevice();

  useEffect(() => {
    for (let item of workspaces) {
      if (item.subs?.length > 0) {
        for (let sub of item.subs) {
          const currentPath = `/${location.pathname.split("/")[1]}`;
          if (sub.link === currentPath) {
            const id = `${renderKey}-${item.key}-${item.id}`;
            const tempArr = [...activeItems];
            const index = tempArr.findIndex((item) => item === id);
            if (index === -1) {
              setActiveItems((prevState) => [...prevState, id]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  const handleHoverItem = (id) => {
    if (id) {
      setItemHover(id);
    } else {
      setItemHover(null);
    }
  };

  const handleClick = (id) => {
    const tempArr = [...activeItems];
    const index = tempArr.findIndex((item) => item === id);
    if (index === -1) {
      setActiveItems((prevState) => [...prevState, id]);
    } else {
      tempArr.splice(index, 1);
      setActiveItems(tempArr);
    }
  };

  const handleLinkClick = () => {
    dispatch(setActiveSubItem(null));
    if (deviceType !== "desktop") dispatch(triggerSidebar());
  };

  const renderIcon = (type, id) => {
    switch (type) {
      case "dashboard":
        return <DashboardIcon style={{ color: "#ffc700" }} />;
      case "station":
        return <RoomIcon style={{ color: "#50cd89" }} />;

      default:
        return (
          <div
            className="main-icon"
            style={{
              backgroundImage:
                itemHover === id ||
                activeItems.includes(id) ||
                link === currentPath
                  ? `url("${activeIcon}")`
                  : `url("${icon}")`,
            }}
          ></div>
        );
    }
  };

  return (
    <>
      {link ? (
        <Link to={link} key={id}>
          <ListItem
            className={`${itemKey} ${
              link === currentPath ? "active-item" : ""
            }`}
            button
            key={id}
            onMouseEnter={() =>
              handleHoverItem(`${renderKey}-${itemKey}-${id}`)
            }
            onMouseLeave={() => handleHoverItem()}
            onClick={() => handleLinkClick()}
          >
            {renderIcon(iconType, `${renderKey}-${itemKey}-${id}`)}
            <p>{name}</p>
          </ListItem>
        </Link>
      ) : (
        subs &&
        subs.length > 0 && (
          <div key={id}>
            <ListItem
              className={`${itemKey} ${
                activeItems.includes(`${renderKey}-${itemKey}-${id}`)
                  ? "active-item"
                  : ""
              }`}
              button
              onClick={() => handleClick(`${renderKey}-${itemKey}-${id}`)}
              onMouseEnter={() =>
                handleHoverItem(`${renderKey}-${itemKey}-${id}`)
              }
              onMouseLeave={() => handleHoverItem()}
            >
              <div
                className="main-icon"
                style={{
                  backgroundImage:
                    itemHover === `${renderKey}-${itemKey}-${id}` ||
                    activeItems.includes(`${renderKey}-${itemKey}-${id}`)
                      ? `url("${activeIcon}")`
                      : `url("${icon}")`,
                }}
              ></div>
              <p>{name}</p>
              <ExpandLess
                className={`navi-icon ${
                  activeItems.includes(`${renderKey}-${itemKey}-${id}`)
                    ? "active-navi"
                    : ""
                }`}
                style={{ fontSize: 14 }}
              />
            </ListItem>
            {subs && subs.length > 0 ? (
              <Collapse
                in={activeItems.includes(`${renderKey}-${itemKey}-${id}`)}
                timeout="auto"
                unmountOnExit
                className={active ? "" : "display-none"}
              >
                {renderNavs(subs, id)}
              </Collapse>
            ) : null}
          </div>
        )
      )}
    </>
  );
}
