import { useLocalStorage } from './useLocalStorage';
import {
  DEFAULT_LANGUAGE_CONFIG_OBJ_KEY,
  LOCALES,
} from '../../languages/constants';
import { useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";

export const useLanguage = () => {
  const [languageObj, setLanguageObj] = useLocalStorage(
    DEFAULT_LANGUAGE_CONFIG_OBJ_KEY,
    { language: LOCALES.ENGLISH }
  );
  const { user } = useSelector(
    ({ user }) => ({
        user: user.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    const languageChanged = JSON.parse(localStorage.getItem("language_changed"));
    if (user && !languageChanged) {
      setLanguageObj({
        language: user.language,
      });
      localStorage.setItem("language_changed", true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user && user.language]);

  const setValue = (value) => {
    window.location.reload();

    setLanguageObj({
      language: value,
    });
  };

  return [languageObj['language'], setValue];
};
