import { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Station } from "./components";
import { DATE_TIME_FORMAT, STATION_A_ID, STATION_B_ID } from "./constants";
import mapApi from "app/api/mapApi";
import { FormattedMessage } from "react-intl";
import "./index.css";

const EmbeddedWidget = (props) => {
  const [lastTime, setLastTime] = useState("");
  const [stationA, setStationA] = useState({});
  const [stationB, setStationB] = useState({});

  const fetchStation = async (id) => {
    try {
      const res = await mapApi.getStationData(id);

      return res.data || [];
    } catch (error) {
      console.log(error);

      return [];
    }
  };

  const fetchStations = async () => {
    const stationA = await fetchStation(STATION_A_ID);
    const stationB = await fetchStation(STATION_B_ID);

    setStationA(stationA);
    setStationB(stationB);
  };

  useEffect(() => {
    const fetchData = () => {
      fetchStations();
      setLastTime(moment().format(DATE_TIME_FORMAT));
    };

    fetchData();

    let timer = setInterval(() => {
      fetchData();
    }, 2 * 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      {stationA && <Station station={stationA} />}
      {stationB && <Station station={stationB} />}

      {stationA && stationB && (
        <>
          <div className="last-recorded-time">
            <FormattedMessage id="CLIENT.DASHBOARD.LAST_RECORDED_TIME" />{" "}
            {lastTime}
          </div>
          <div className="copyright">&copy; VnEmisoft, {moment().year()}</div>
        </>
      )}
    </div>
  );
};

export default EmbeddedWidget;
