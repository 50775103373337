/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Container, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getFileSize, trimFileName } from 'app/pages/DataFiles/helper';
import { useDataFile } from 'app/pages/DataFiles/hooks';
import { useFormik } from 'formik';
import Papa from "papaparse";
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import * as Yup from "yup";
import dataFileApi from '../../../api/dataFileApi';
import SharedSelectField from '../../../shared/components/SharedSelectField';

function DataFilesAdd(props) {
  const { intl } = props;

  const {
    loading,
    data,
    mainData,
    files,
    postFiles,
    history,
    language,
    fileInputEl,
    pageLoading,
    setPageLoading,
    setMainData,
    setData,
    setFiles,
    enableLoading,
    disableLoading,
    setTemplateFile,
    handleDownloadAll,
    onChangeFilesEdit,
    parseFilesEdit,
    removeFileEdit
  } = useDataFile({ dataFileApi });

  const { userId } = useSelector(
    ({ user }) => ({
      userId: user.user?.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchData = async () => {
      const [
        users,
        status
      ] = await Promise.all([
        dataFileApi.getUsers(),
        dataFileApi.getAllStatus(),
      ])
      if (users.success && status.success) {
        setData(prevData => ({
          ...prevData,
          users: users.data,
          status: status.data,
        }))
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageLoading(true);
        const id = parseInt(props.match.params.id);
        const res = await dataFileApi.getDataFile(id);
        if (res.success) {
          setMainData(res.data);
          if (res.data.data_upload?.length > 0) {
            let files = {}
            for (let i = 0; i < res.data.data_upload?.length; i++) {
              files[res.data.data_upload?.[i]?.id] = {
                id: res.data.data_upload?.[i]?.id,
                src: `${process.env.REACT_APP_API_URL}/${res.data.data_upload?.[i]?.url_data_upload}`,
                originalSrc: res.data.data_upload?.[i]?.url_data_upload,
                // name: res.data.data_upload?.[i]?.url_data_upload,
                name: res.data.data_upload?.[i]?.url_data_upload?.split("/")?.[res.data.data_upload?.[i]?.url_data_upload?.split("/")?.length - 1],
                size: 0,
                isNew: false
              }
            }
            setFiles(files)
          }
        }
      } catch (error) {
        console.log(error);
        history.push("/not-found")
      } finally {
        setPageLoading(false)
      }
    }
    fetchData()
  }, [props.match.params.id])

  const schema = Yup.object().shape({
    user_id: Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    data_sharing_request_id: Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
  });


  const formik = useFormik({
    initialValues: {
      user_id: userId,
      data_file_status_id: mainData?.data_file_status_id,
      data_sharing_request_id: mainData?.data_sharing_request_id
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      if ((files && !Object.keys(files).length) || !files) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.DATA_FILES.MISSING_FILES_WARNING',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return;
      }
      const validFile = await parseFilesEdit();
      if (!validFile) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.DATA_FILES.INVALID_FILES_WARNING',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return;
      }
      try {
        setSubmitting(true);
        enableLoading();
        const form = new FormData();
        form.append("user_id", values.user_id);
        form.append("data_sharing_request_id", values.data_sharing_request_id);
        form.append("data_file_status_id", values.data_file_status_id);
        for (let file of Object.values(postFiles)) {
          const _file = new File([file], file.name, { type: file.type });
          if (_file.isNew) {
            delete _file.src;
            delete _file.isNew;
          }
          form.append("data_upload", _file);
        }
        const dataIds = mainData?.data_upload.filter(item => Object.keys(files)?.includes(item.id.toString())).map(elm => elm.id);
        if (dataIds.length) {
          for (let id of dataIds) {
            form.append("data_upload_id", id);
          }
        }
        const removeIds = mainData?.data_upload.filter(item => !Object.keys(files)?.includes(item.id.toString())).map(elm => elm.id);
        if (removeIds.length) {
          for (let id of removeIds) {
            form.append("remove_data_upload_id", id);
          }
        }
        const id = parseInt(props.match.params.id);
        const res = await dataFileApi.updateDataFile(id, form);
        if (res.success) {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_SUCCESS_TITLE"
            }),
            text: intl.formatMessage({
              id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_SUCCESS_MESSAGE"
            }),
            icon: 'success',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          }).then(() => {
            props.history.push("/data-files")
          })
        } else {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_FAILED_TITLE"
            }),
            text: intl.formatMessage({
              id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_FAILED_MESSAGE"
            }),
            icon: 'error',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          })
        }
      } catch (error) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_FAILED_TITLE"
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_FILES.EDIT_DATA_FILE_FAILED_MESSAGE"
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON"
          })
        })
      } finally {
        setSubmitting(false);
        disableLoading();
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const dataSharingRequests = await dataFileApi.getDataSharingRequestByUsers(formik.values["user_id"]);
      if (dataSharingRequests.success) {
        const newDataSharingRequests = [...dataSharingRequests.data].map(item => {
          if (item.template_file) {
            const BOM = "\uFEFF";
            const csvContent = new Buffer.from(item.template_file.data).toString();
            const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            return {
              ...item,
              template_file: {
                url,
                name: item.name_template_file,
              }
            }
          }
          return item
        })
        setData(preData => ({
          ...preData,
          dataSharingRequests: newDataSharingRequests,
        }))
      }
    }
    if (formik.values["user_id"]) {
      fetchData();
    }
  }, [formik.values["user_id"]]);

  useEffect(() => {
    if (formik.values["data_sharing_request_id"]) {
      if (data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file) {
        Papa.parse(data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.url, {
          download: true,
          complete: (results) => {
            setTemplateFile(results?.data);
          }
        })
      } else {
        setTemplateFile([])
      }
    }
  }, [formik.values["data_sharing_request_id"], data.dataSharingRequests])

  return (

    <Container className="enduser-page">
      {
        pageLoading ?
          <Box textAlign="center">
            <CircularProgress />
          </Box> :
          <div className="main-content enduser data-files">
            <h2 className="main-title">
              {
                mainData && mainData?.data_file_status_id === 3 ?
                  <FormattedMessage id="CLIENT.DATA_FILES.VIEW" /> :
                  <FormattedMessage id="CLIENT.DATA_FILES.EDIT" />
              }
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <Box mb={3} display="flex" justifyContent="space-between">
                <Box width="100%">
                  <SharedSelectField
                    label={intl.formatMessage({ id: "CLIENT.DATA_FILES.DATA_SHARING_REQUEST" })}
                    placeholder={intl.formatMessage({ id: "CLIENT.DATA_FILES.DATA_SHARING_REQUEST_HINT" })}
                    disableClearable
                    name="data_sharing_request_id"
                    options={data.dataSharingRequests?.map(item => ({
                      id: item.id,
                      value: item.id,
                      label: item.title
                    }))}
                    defaultValue={formik.values["data_sharing_request_id"]}
                    onChange={formik.setFieldValue}
                    errorMsg={formik.touched["data_sharing_request_id"] && formik.errors["data_sharing_request_id"]}
                    disabled={mainData?.data_file_status_id !== 1 || formik.isSubmitting}
                    required={mainData?.data_file_status_id === 1}
                    className={mainData && mainData?.data_file_status_id !== 1 ? "disabled-field text-normal" : "text-normal"}
                  />
                </Box>
              </Box>

              <Box mb={3} display="flex" justifyContent="space-between">
                <Box width="49%">
                  <SharedSelectField
                    label={intl.formatMessage({ id: "CLIENT.DATA_FILES.STATUS_LABEL" })}
                    disableClearable
                    name="data_file_status_id"
                    options={data.status.map((item) => ({
                      id: item.id,
                      value: item.id,
                      label: item[`name_${language}`]
                    }))}
                    defaultValue={formik.values["data_file_status_id"]}
                    onChange={formik.setFieldValue}
                    disabled={true}
                    required={false}
                    className={mainData && mainData?.data_file_status_id !== 1 ? "disabled-field text-normal" : "text-normal"}
                  />
                </Box>
                {
                  data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file &&
                  <Box width="49%">
                    <Box className="form-label">
                      {intl.formatMessage({ id: "CLIENT.DATA_FILES.TEMPLATE" })}
                    </Box>
                    <Box className="uploaded-files template">
                      <Grid item className="uploaded-file">
                        <div className="uploaded-file-left">
                          <span className="uploaded-file-icon">
                            <DescriptionIcon fontSize="large" />
                          </span>

                          <div className="uploaded-file-text">
                            <h5>
                              {
                                data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.name
                              }
                            </h5>
                          </div>
                        </div>

                        <Box display="flex" className="uploaded-file-right">
                          <a
                            href={data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.url}
                            download={`${data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.name}.csv`}
                            target="__blank"
                          >
                            <span className="uploaded-file-icon-button">
                              <GetAppIcon />
                            </span>
                          </a>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                }
              </Box>

              <Box mb={3}>
                <div className="form-label">
                  {
                    mainData && mainData?.data_file_status_id !== 3 &&
                    <span className="required">*</span>
                  }
                  <FormattedMessage id="CLIENT.DATA_FILES.DATA_UPLOAD" />
                </div>
                <Grid container className="uploaded-files">
                  {files &&
                    <> {
                      Object.keys(files).map((key, index) => (
                        <Grid item className="uploaded-file" key={key}>
                          <div className="uploaded-file-left">
                            <span className="uploaded-file-icon">
                              <DescriptionIcon fontSize="large" />
                            </span>

                            <div className="uploaded-file-text">
                              <h5>
                                {trimFileName(files[key]['name'])}
                              </h5>
                              <p>{getFileSize(files[key]['size'])}</p>
                            </div>
                          </div>

                          <Box display="flex" className="uploaded-file-right">
                            <a href={files[key]["src"]} download={files[key]["name"]} target="__blank" >
                              <span className="uploaded-file-icon-button">
                                <GetAppIcon />
                              </span>
                            </a>
                            {
                              mainData && mainData?.data_file_status_id === 1 &&
                              <span
                                className="uploaded-file-icon-button"
                                onClick={() => removeFileEdit(key)}
                              >
                                <CloseIcon />
                              </span>
                            }
                          </Box>
                        </Grid>
                      ))}
                    </>
                  }
                </Grid>
                {
                  mainData && mainData?.data_file_status_id !== 3 &&
                  <input
                    type="file"
                    accept=".csv"
                    multiple
                    style={{ display: 'none' }}
                    ref={fileInputEl}
                    onChange={onChangeFilesEdit}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                  />
                }
                <Box display="flex" alignItems="flex-end">
                  {
                    mainData && mainData?.data_file_status_id !== 3 &&
                    <Box mt={3} mr={3}>
                      <Button
                        className="custom-button light-button"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputEl.current.click();
                        }}
                      >
                        <FormattedMessage id="CLIENT.DATA_FILES.UPLOAD_FILES" />
                      </Button>
                    </Box>
                  }
                  {
                    files && Object.keys(files)?.length > 0 &&
                    <Box mt={3}>
                      <Button
                        className="custom-button light-button"
                        onClick={handleDownloadAll}
                      >
                        <FormattedMessage id="CLIENT.DATA_FILES.DOWNLOAD_ALL" />
                      </Button>
                    </Box>
                  }
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {
                    mainData && mainData?.data_file_status_id === 1 &&
                    <Button
                      className="custom-button"
                      type="submit"
                      disabled={
                        formik.isSubmitting ||
                        !formik.isValid
                      }
                    >
                      <FormattedMessage id="CLIENT.DATA_FILES.SAVE" />
                      {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
                    </Button>
                  }
                  <Box ml={mainData && mainData?.data_file_status_id === 1 ? 3 : 0}>
                    <Link to='/data-files'>
                      <Button
                        className="custom-button light-button"
                        type="button"
                      >
                        <FormattedMessage id="CLIENT.DATA_FILES.CANCEL" />
                      </Button>
                    </Link>
                  </Box>
                </Box>
                {
                  mainData && mainData?.data_file_status_id !== 3 &&
                  <Box className="note-msg">
                    *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
                  </Box>
                }
              </Box>
            </form>
          </div>
      }
    </Container>
  )
}

export default injectIntl(DataFilesAdd);