import React from "react";
import LanguageProvider from "./languages/LanguageProvider";
import { initDB } from "react-indexed-db-hook";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import theme from "../assets/styles/theme";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import PermissionsProvider from "./shared/hooks/permissions/PermissionsProvider";
import { DBConfig } from "./db/init";
import "../assets/styles/global.css";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ErrorHandler } from "./utils/utils";

export const queryClientGlobal = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const customHandlerError = query?.meta?.customHandlerError;
      ErrorHandler(error, customHandlerError);
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

initDB(DBConfig);
const App = (props) => {
  return (
    <QueryClientProvider client={queryClientGlobal}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <PermissionsProvider>
              <LanguageProvider>
                <Router>
                  <Routes />
                </Router>
              </LanguageProvider>
            </PermissionsProvider>
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
