import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import StationItem from './StationItem';
import { v4 as uuidv4 } from 'uuid';

import './style.css';

function StationList(props) {
  const { stations } = props;
  const [listValues, setListValues] = React.useState([]);
  const [data, setData] = React.useState([]);

  const onAddNewStation = () => {
    const newStation = {
      row_id: uuidv4(),
      editing: true,
      new: true,
    };

    props['onChange']([...data, newStation]);
  };

  const onApply = (id) => (newValue) => {
    const newStations = [...data].map((item) => {
      if (item.row_id === id) {
        return {
          ...newValue,
          new: false,
        };
      }

      return item;
    });

    props['onChange'](newStations);
  };

  const onChange = (id) => (name, value) => {
    const newStations = [...listValues].map((item) => {
      if (item.row_id === id) {
        const newItem = {
          ...item,
          [name]: value,
        };
        return newItem;
      }
      return item;
    });
    setListValues(newStations);
    // console.log(listValues);
  };

  const onDeleteStation = (id) => {
    const newStations = listValues.filter((item) => item.row_id !== id);

    const indexDeleted = data.findIndex((item) => item.row_id === id);
    if (indexDeleted > -1) {
      var newListStationDelete = [
        ...props['dashboardLocationMapStationDelete'],
      ];
      if (
        !newListStationDelete.some((item) => item === data[indexDeleted].id)
      ) {
        props['onDelete']([...newListStationDelete, data[indexDeleted].id]);
      }
    }

    props['onChange'](newStations);
  };

  const onCancel = (id) => {
    if (data.some((d) => d.row_id === id && d.new === true)) {
      const newData = data.filter((d) => d.row_id !== id);
      props['onChange'](newData);
    } else {
      const oldData = data.map((item) => {
        if (item.row_id === id) {
          const oldItem = {
            ...item,
            editing: false,
          };
          return oldItem;
        }

        return item;
      });
      props['onChange'](oldData);
    }
  };

  React.useEffect(() => {
    if (props['data']) {
      const dataReceive = [...props['data']].map((item) => {
        const newItem = { ...item };
        if (!newItem.row_id) {
          newItem.row_id = uuidv4();
        }
        return newItem;
      });
      setData(dataReceive);
    }
  }, [props['data']]);

  React.useEffect(() => {
    const newValue = data.map((item, index) => {
      if (
        listValues.some(
          (vl) => vl.row_id === item.row_id && item.editing && vl.editing
        )
      ) {
        return listValues.find((x) => x.row_id === item.row_id);
      }
      return item;
    });
    setListValues(newValue);
  }, [data]);

  return (
    <div className="station-list">
      <Button className="custom-button" type="button" onClick={onAddNewStation}>
        <FormattedMessage id="CLIENT.STATION_ACTIONS.NEW" />
      </Button>
      {listValues?.length > 0 ? (
        <Grid container className="grid-data">
          <Grid item xs={5} className="grid-data-head">
            <FormattedMessage id="CLIENT.DASHBOARD.STATION_NAME" />
          </Grid>
          <Grid item xs={4} className="grid-data-head">
            <FormattedMessage id="CLIENT.DASHBOARD.LOCATION_TYPE" />
          </Grid>
          <Grid item xs={3} className="grid-data-head action">
            <FormattedMessage id="GENERAL.ACTIONS" />
          </Grid>

          {listValues.map((item) => (
            <StationItem
              key={item.row_id}
              item={item}
              onChange={onChange(item.row_id)}
              onApply={onApply(item.row_id)}
              onDelete={() => onDeleteStation(item.row_id)}
              onCancel={() => onCancel(item.row_id)}
              stations={stations}
              listValues={listValues}
              dataMode={props['dataMode']}
            />
          ))}
        </Grid>
      ) : null}
    </div>
  );
}

export default injectIntl(StationList);
