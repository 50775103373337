import React from "react";
import { Box, Grid } from "@material-ui/core";
import DatePicker from "../../../../shared/components/DatePicker";
import moment from "moment";

const BirthdayInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item md={6} xs={12}>
      <Box mb={3} mr={2}>
        <DatePicker
          name="date_of_birth"
          label={intl.formatMessage({ id: "CLIENT.SIGN_UP.DOB_LABEL" })}
          type="date"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.DOB_HINT",
          })}
          value={formik.values["date_of_birth"]}
          disabled={formik.isSubmitting}
          onChange={formik.setFieldValue}
          currentError={
            moment(formik.values["date_of_birth"])?.isValid() &&
            formik.errors["date_of_birth"]
          }
        />
      </Box>
    </Grid>
  );
};

export default BirthdayInput;
