import axiosClient from "./axiosClient";

const accountApi = {
  getAllAccounts(params, token) {
    const url = "/api/v1/users/";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}, params})
  },
  getAllAccountsFullName(params, token) {
    const url = "/api/v1/users/get-all-user-with-fullname";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}, params})
  },
  lockAccount(id, token) {
    const url = `/api/v1/users/change-status/${id}`;
    return axiosClient.put(url, {}, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getAccount(id, token) {
    const url = `/api/v1/users/${id}`;
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  addAccount(data, token) {
    const url = "/api/v1/users/";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  updateAccount(id, data, token) {
    const url = `/api/v1/users/${id}`;
    return axiosClient.put(url, data, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  getAccountInfo(username, code) {
    const url = `/api/v1/users/get-use-complete-account?username=${username}&code=${code}`;
    return axiosClient.get(url);
  },
  activeAccount(data) {
    const url = "/api/v1/users/complete-your-account";
    return axiosClient.put(url, data);
  },
}

export default accountApi;
