import * as Yup from "yup";

const GaugeChartModalSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    name_vi: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    range_min: Yup.number().nullable()
      .when("range_type", {
        is: "FIXED",
        then: Yup.number().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
      }),
    range_max: Yup.number().nullable()
      .when("range_type", {
        is: "FIXED",
        then: Yup.number().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
      })
      .when(["range_min", "range_type"], (minValue, rangeType) => {
        if (rangeType === "FIXED") {
          return Yup.number().required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            }))
            .moreThan(minValue, intl.formatMessage({
              id: "CLIENT.DASHBOARD.GAUGE.INVALID_MAX_VALUE"
            }));
        }
      }),
    parameter_id: Yup.string().nullable()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
  });

  return schema;
}

export default GaugeChartModalSchema