import { Box, Button, CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import mapApi from "app/api/mapApi";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { isValidLayerFile } from "../../helpers/layer-setting.helper";
import { useLayerSetting } from "../../hooks";
import { LayerConfigItem } from "./layer-config-item.component";

export const _LayerSettingModal = (props) => {
  const { intl, data, handleClose, mapId } = props;

  const layerMaster = useLayerSetting({
    modalOpen: props["open"],
    mapId,
    handleClose,
    data,
    intl,
    mapApi,
  });

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.MAP.MANAGE_LAYER" />
        </div>
        <div className="value-modal-header-right">
          <span
            className="value-modal-header-icon"
            onClick={layerMaster?.toggleOpen}
          >
            <CloseIcon onClick={layerMaster?.toggleOpen} />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <Box>
          <div className="layers-container">
            {_.map(layerMaster?.items, (item, index) => (
              <LayerConfigItem
                key={item.row_id}
                data={item}
                handleMove={layerMaster?.handleMoveLayer}
                disabledMoveDown={index === layerMaster?.items?.length - 1}
                disabledMoveUp={index === 0}
                disabledDelete={layerMaster?.items?.length === 1 && !item.id}
                handleRemove={layerMaster?.handleRemoveLayer}
                onChange={layerMaster?.handleItemChange}
                touched={layerMaster?.touched[item.row_id] || 0}
              />
            ))}
          </div>
        </Box>

        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          mt={4}
        >
          <Box mr="auto">
            <Button
              className="custom-button light-button"
              type="button"
              onClick={layerMaster?.onAddLayer}
            >
              <FormattedMessage id="CLIENT.MAP.ADD_LAYER" />
            </Button>
          </Box>

          <Box display="flex" alignItems="center" ml="auto">
            <Button
              className="custom-button"
              type="button"
              onClick={layerMaster?.handleSubbmit}
              disabled={
                layerMaster?.loading ||
                layerMaster?.items.some(
                  (item) => isValidLayerFile(item) === false
                )
              }
            >
              {layerMaster?.loading ? (
                <CircularProgress size={20} color="#fff" />
              ) : (
                <FormattedMessage id="CLIENT.DASHBOARD.SAVE_ITEM" />
              )}
            </Button>

            <Box ml={3}>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={layerMaster?.toggleOpen}
              >
                <FormattedMessage id="CLIENT.DASHBOARD.CANCEL" />
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    layerMaster?.setOpen(props?.open);

    if (data?.length) {
      const newItems = data?.map((item) => {
        if (!item.row_id) {
          return {
            ...item,
            row_id: uuidv4(),
          };
        }
        return item;
      });

      layerMaster?.setItems(newItems);
    } else if (!layerMaster?.items?.length) {
      layerMaster?.onAddLayer();
    }

    return () => {
      layerMaster?.setItems([]);
      layerMaster?.setTouched({});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props?.open]);

  return (
    <Modal
      open={layerMaster?.open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={layerMaster?.modalStyle}
        className={layerMaster?.classes.paper}
      >
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
};

_LayerSettingModal.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  handleClose: PropTypes.func,
  selectedValues: PropTypes.array,
};

export const LayerSettingModal = injectIntl(_LayerSettingModal);
