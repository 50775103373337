import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shallowEqual, useSelector } from "react-redux";
import { Box, Button, CircularProgress } from '@material-ui/core';
import swal from 'sweetalert';
import * as Yup from "yup";
import SharedInputField from '../../../../../../shared/components/SharedInputField';
import { useFormik } from 'formik';
import { useLocation } from "react-router-dom";
import "./index.css";
import { EXPIRY_OPTIONS } from './constant';
import securityApi from '../../../../../../api/securityApi';
import SharedSelectField from '../../../../../../shared/components/SharedSelectField';
import { useLanguage } from '../../../../../../shared/hooks';
import accountApi from '../../../../../../api/accountApi';
import dashboardSecurityApi from '../../../../../../api/dasboardSecurityApi';
import mapApi from 'app/api/mapApi';

const initialValues = {
  user_id: "",
  expiry: "",
  custom_expiry_day: "",
  station_permission_id: null,
}

const MapsSecurityAdd = (props) => {
  const { intl, mapId, setSecurityPage } = props;
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user
    }),
    shallowEqual
  );
  const location = useLocation();
  const [language] = useLanguage()
  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const [
          permissions,
          users
        ] = await Promise.all([
          securityApi.getAllStationPermission(token),
          accountApi.getAllAccountsFullName({ organizationId: user.organizationId }, token)
        ])
        if (permissions.success) {
          setPermissions(permissions.data);
        }
        if (users.success) {
          setUsers(users.data.filter(item => item.id !== user.id));
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchPermissions();
  }, [])

  const SecurityAddSchema = Yup.object().shape({
    user_id: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    custom_expiry_day: Yup.number()
      .when("expiry", {
        is: (val) => (val && val === "custom"),
        then: Yup.number()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .positive(
            intl.formatMessage({
              id: "CLIENT.GENERAL.POSITIVE_NUMBER"
            })
          ).integer(
            intl.formatMessage({
              id: "CLIENT.GENERAL.INTERGER_NUMBER"
            })
          ),
      }),
    station_permission_id: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      station_permission_id: permissions && permissions.length && permissions[0].id,
      user_id: users && users.length ? users[0].id : ""
    },
    validationSchema: SecurityAddSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        enableLoading();
        const data = {
          user_id: values.user_id,
          expiry: values &&
            !values.expiry ?
            "" :
            (
              values.expiry === "custom" ?
                (values.custom_expiry_day || "") :
                values.expiry
            ),
          map_permission_id: values.station_permission_id
        }
        const res = await mapApi.createMapSecurity(mapId, data, token);

        if (res.success) {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.SECURITY_ACTIONS.ADD_SECURITY_SUCCESS_TITLE"
            }),
            text: intl.formatMessage({
              id: "CLIENT.SECURITY_ACTIONS.ADD_SECURITY_SUCCESS_MESSAGE"
            }),
            icon: 'success',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          }).then(() => {
            setSecurityPage("list")
          })
        } else {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.SECURITY_ACTIONS.ADD_SECURITY_FAILED_TITLE"
            }),
            text: `${intl.formatMessage({
              id: "CLIENT.SECURITY_ACTIONS.ADD_SECURITY_FAILED_MESSAGE"
            })}`,
            icon: 'error',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          })
        }
      } catch (error) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.SECURITY_ACTIONS.ADD_SECURITY_FAILED_TITLE"
          }),
          text: `${error.response.data.code === 10006 ?
            language === "en" ? "This user is already added to this map" : "Người dùng đã được thêm vào bản đồ này" :
            error.response.data.error}`,
          icon: 'error',
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON"
          })
        })
      } finally {
        setSubmitting(false);
        disableLoading();
      }
    },
  });

  return (
    <div className="main-content dashboard-security-add">
      <h2 className="main-title">
        <FormattedMessage id="CLIENT.MAP.SETTINGS.CREATE_SECURITY" />
      </h2>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={3}>
          <SharedSelectField
            label={intl.formatMessage({ id: "CLIENT.SECURITY_MANAGEMENT.USER_LABEL" })}
            className="text-normal"
            disableClearable
            name="user_id"
            required
            options={users.map(item => ({
              id: item.id,
              value: item.id,
              label: `${item.fullname} (${item.username})`
            }))}
            defaultValue={formik.values["user_id"]}
            onChange={formik.setFieldValue}
            disabled={formik.isSubmitting}
            errorMsg={formik.touched["user_id"] && formik.errors["user_id"]}
          />
        </Box>

        <Box mb={3}>
          <SharedSelectField
            label={intl.formatMessage({ id: "CLIENT.SECURITY_MANAGEMENT.EXPIRY_LABEL" })}
            disableClearable
            name="expiry"
            required
            options={EXPIRY_OPTIONS(intl)}
            defaultValue={formik.values["expiry"]}
            onChange={formik.setFieldValue}
            disabled={formik.isSubmitting}
            errorMsg={formik.touched["expiry"] && formik.errors["expiry"]}
            className="text-normal"
          />
        </Box>

        {
          formik.values["expiry"] === "custom" &&
          <Box mb={3}>
            <SharedInputField
              name="custom_expiry_day"
              label={intl.formatMessage({ id: 'CLIENT.SECURITY_MANAGEMENT.CUSTOM_EXPIRY_LABEL' })}
              type="number"
              required
              placeholder={intl.formatMessage({ id: 'CLIENT.SECURITY_MANAGEMENT.CUSTOM_EXPIRY_HINT' })}
              {...formik.getFieldProps("custom_expiry_day")}
              disabled={formik.isSubmitting}
              errorMsg={formik.touched["custom_expiry_day"] && formik.errors["custom_expiry_day"]}
            />
          </Box>
        }

        <Box mb={3}>
          <SharedSelectField
            label={intl.formatMessage({ id: "CLIENT.SECURITY_MANAGEMENT.PERMISSION_LABEL" })}
            disableClearable
            name="station_permission_id"
            required
            options={permissions.map(item => ({
              id: item.id,
              value: item.id,
              label: language === "en" ? item.name : item.name_vi
            }))}
            defaultValue={formik.values["station_permission_id"]}
            onChange={formik.setFieldValue}
            disabled={formik.isSubmitting}
            errorMsg={formik.touched["station_permission_id"] && formik.errors["station_permission_id"]}
          />
        </Box>

        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Button
              className="custom-button"
              type="button"
              onClick={formik.handleSubmit}
              disabled={
                formik.isSubmitting ||
                !formik.isValid
              }
            >
              <FormattedMessage id="CLIENT.SECURITY_ACTIONS.SAVE" />
              {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
            </Button>
            <Box ml={3}>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={() => setSecurityPage("list")}
              >
                <FormattedMessage id="CLIENT.SECURITY_ACTIONS.CANCEL" />
              </Button>
            </Box>
          </Box>
          <Box className="note-msg">
            *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default injectIntl(MapsSecurityAdd);