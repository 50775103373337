import React, { Fragment, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ScrollToTop from "./shared/components/ScrollToTop";
import Chat from "./shared/components/ChatBox/Chat";
import { usePermissions } from "./shared/hooks";
import MainLayout from "./shared/layouts/MainLayout";
import { RouteWithNavBar } from "app/shared/components/RouteWithNavBar";
import Loadable from "./shared/components/Loader/Loadable";
import { FeatureRoutes } from "app/shared/components/FeatureRoutes";
import { connectionRoutes } from "app/pages/Stations/features/connection/routes";
import { parameterRoutes } from "app/pages/Stations/features/parameter/routes";
import { ftpShareRoutes } from "app/pages/Stations/features/ftp-share/routes";
import { stationRoutes } from "app/pages/Stations/features/station/routes";
import { mapRoutes } from "app/pages/Maps/routes";

import { FEATURE_ID } from "./constant/features";
import { isFeatureEnabled } from "./utils/features";

const ProfileEdit = Loadable(
  lazy(() => import("./pages/Profile/ProfileEdit/index")),
);
const AccountAdd = Loadable(
  lazy(() => import("./pages/Accounts/AccountAdd/index")),
);
const AccountApprove = Loadable(
  lazy(() => import("./pages/Accounts/AccountApprove/index")),
);
const AccountEdit = Loadable(
  lazy(() => import("./pages/Accounts/AccountEdit/index")),
);
const Account = Loadable(lazy(() => import("./pages/Accounts/Account/index")));
const OrganizationAdd = Loadable(
  lazy(() => import("./pages/Organization/OrganizationAdd/index")),
);
const OrganizationEdit = Loadable(
  lazy(() => import("./pages/Organization/OrganizationEdit/index")),
);
const OrganizationProfile = Loadable(
  lazy(() => import("./pages/Organization/OrganizationProfile/index")),
);
const OrganizationsList = Loadable(
  lazy(() => import("./pages/Organization/OrganizationsList/index")),
);
const ProfileChangePassword = Loadable(
  lazy(() => import("./pages/Profile/ProfileChangePassword/index")),
);
const RoleAdd = Loadable(lazy(() => import("./pages/Roles/RoleAdd/index")));
const RoleEdit = Loadable(lazy(() => import("./pages/Roles/RoleEdit/index")));
const RoleList = Loadable(lazy(() => import("./pages/Roles/RoleList/index")));
const StationTypesList = Loadable(
  lazy(() => import("./pages/StationTypes/StationTypesList/index")),
);
const StationTypesAdd = Loadable(
  lazy(() => import("./pages/StationTypes/StationTypesAdd/index")),
);
const StationTypesEdit = Loadable(
  lazy(() => import("./pages/StationTypes/StationTypesEdit/index")),
);
const WorkspaceList = Loadable(
  lazy(() => import("./pages/Workspace/WorkspaceList")),
);
const WorkspaceAdd = Loadable(
  lazy(() => import("./pages/Workspace/WorkspaceAdd")),
);
const WorkspaceEdit = Loadable(
  lazy(() => import("./pages/Workspace/WorkspaceEdit")),
);
const SecurityAdd = Loadable(
  lazy(
    () => import("./pages/Stations/features/security/pages/security-add.page"),
  ),
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const TicketsList = Loadable(lazy(() => import("./pages/Tickets/TicketsList")));
const TicketAdd = Loadable(lazy(() => import("./pages/Tickets/TicketAdd")));
const TicketEdit = Loadable(lazy(() => import("./pages/Tickets/TicketEdit")));
const TicketView = Loadable(
  lazy(() => import("./pages/Tickets/TicketView/index")),
);
const Notifications = Loadable(
  lazy(() => import("./pages/Notifications/Notifications/index")),
);
const NotificationView = Loadable(
  lazy(() => import("./pages/Notifications/NotificationView/index")),
);
const DataSharingRequestList = Loadable(
  lazy(() => import("./pages/DataSharingRequest/DataSharingRequestList")),
);
const DataFilesList = Loadable(
  lazy(() => import("./pages/DataFiles/DataFilesList")),
);
const DataFilesAdd = Loadable(
  lazy(() => import("./pages/DataFiles/DataFilesAdd")),
);
const DataSharingAdd = Loadable(
  lazy(() => import("./pages/DataSharingRequest/DataSharingAdd")),
);
const DataSharingEdit = Loadable(
  lazy(() => import("./pages/DataSharingRequest/DataSharingEdit")),
);
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard/Dashboard")));
const DataFilesEdit = Loadable(
  lazy(() => import("./pages/DataFiles/DataFilesEdit")),
);
const ChatList = Loadable(lazy(() => import("./pages/Chat/ChatList")));
const NotificationSystemAdd = Loadable(
  lazy(() => import("./pages/Notifications/NotificationSystemAdd/index")),
);
const SystemNotificationSystemEdit = Loadable(
  lazy(() => import("./pages/Notifications/NotificationSystemEdit/index")),
);
const NotificationSystemView = Loadable(
  lazy(() => import("./pages/Notifications/NotificationSystemView/index")),
);
const ImportData = Loadable(
  lazy(() => import("./pages/ImportData/ImportData")),
);
const ExportData = Loadable(
  lazy(() => import("./pages/ExportData/ExportData/index")),
);
const GuideEdit = Loadable(lazy(() => import("./pages/Guide/GuideEdit")));
const GuideList = Loadable(lazy(() => import("./pages/Guide/GuideList")));
const SyncRequestList = Loadable(
  lazy(() => import("./pages/SyncData/Requester/ListRequest")),
);
const SyncRequest = Loadable(
  lazy(() => import("./pages/SyncData/Requester/SyncRequest")),
);
const SyncResponseList = Loadable(
  lazy(() => import("./pages/SyncData/Sharer/ResponseList")),
);
const SyncResponse = Loadable(
  lazy(() => import("./pages/SyncData/Sharer/SyncResponse")),
);
const ApiKeyList = Loadable(lazy(() => import("./pages/ApiKeys/ApiKeyList")));
const ApiKeyAdd = Loadable(lazy(() => import("./pages/ApiKeys/ApiKeyAdd")));
const ApiKeyEdit = Loadable(lazy(() => import("./pages/ApiKeys/ApiKeyEdit")));
const SyncDataRequestSecurityAdd = Loadable(
  lazy(() => import("./pages/SyncData/Requester/SecutityAdd")),
);
const MQTTList = Loadable(lazy(() => import("./pages/MQTT/MQTTList")));
const MQTTAdd = Loadable(lazy(() => import("./pages/MQTT/MQTTAdd")));
const MQTTEdit = Loadable(lazy(() => import("./pages/MQTT/MQTTEdit")));
const MQTTView = Loadable(lazy(() => import("./pages/MQTT/MQTTView")));
const ApiKeyView = Loadable(lazy(() => import("./pages/ApiKeys/ApiKeyView")));
const WorkspaceView = Loadable(
  lazy(() => import("./pages/Workspace/WorkspaceView")),
);

const routes = [
  connectionRoutes,
  parameterRoutes,
  ftpShareRoutes,
  stationRoutes,
  mapRoutes,
];

export default function BasePage(props) {
  const { isAllowed } = usePermissions();

  return (
    <>
      <ScrollToTop />
      <Chat />
      <Switch>
        <Redirect
          exact
          from="/"
          to={
            isAllowed("workspaces", "view")
              ? "/monitoring-map"
              : "/profile/edit/"
          }
        />
        <RouteWithNavBar
          path="/accounts/users"
          exact={true}
          component={() => <MainLayout>Hello</MainLayout>}
        />

        {isAllowed("my_profile", "edit") && (
          <RouteWithNavBar
            exact
            isAllowed
            path="/profile/edit/"
            component={ProfileEdit}
          />
        )}
        {isAllowed("change_password", "edit") && (
          <RouteWithNavBar
            path="/profile/change-password/"
            component={ProfileChangePassword}
            isAllowed
          />
        )}

        {isAllowed("organizations", "view") && (
          <RouteWithNavBar
            path="/organizations"
            component={OrganizationsList}
            exact={true}
            isAllowed={isAllowed("organizations", "view")}
          />
        )}
        {isAllowed("organizations", "new") && (
          <RouteWithNavBar
            isAllowed={isAllowed("organizations", "new")}
            path="/organizations/add"
            component={OrganizationAdd}
          />
        )}
        {isAllowed("organizations", "edit") && (
          <RouteWithNavBar
            isAllowed={isAllowed("organizations", "edit")}
            path="/organizations/edit/:id"
            component={OrganizationEdit}
          />
        )}

        {isAllowed("my_organization", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("my_organization", "view")}
            path="/organization/edit"
            component={OrganizationProfile}
          />
        )}

        {isAllowed("roles", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("roles", "view")}
            path="/roles"
            component={RoleList}
            exact={true}
          />
        )}
        {isAllowed("roles", "new") && (
          <RouteWithNavBar
            isAllowed={isAllowed("roles", "new")}
            path="/roles/add"
            component={RoleAdd}
          />
        )}
        {isAllowed("roles", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("roles", "view")}
            path="/roles/edit/:id"
            component={RoleEdit}
          />
        )}

        {isAllowed("account", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("account", "view")}
            path="/account"
            component={Account}
            exact={true}
          />
        )}
        {isAllowed("account", "new") && (
          <RouteWithNavBar
            isAllowed={isAllowed("account", "new")}
            path="/account/add"
            component={AccountAdd}
          />
        )}
        {isAllowed("account", "edit") && (
          <RouteWithNavBar
            isAllowed={isAllowed("account", "edit")}
            path="/account/edit/:id"
            component={AccountEdit}
          />
        )}

        {isAllowed("account_requests", "edit") && (
          <RouteWithNavBar
            isAllowed={isAllowed("account_requests", "edit")}
            path="/account/approve/:id"
            component={AccountApprove}
          />
        )}

        {isAllowed("station_types", "view") && (
          <RouteWithNavBar
            path="/station-types"
            component={StationTypesList}
            exact={true}
            isAllowed={isAllowed("station_types", "view")}
          />
        )}
        {isAllowed("station_types", "new") && (
          <RouteWithNavBar
            isAllowed={isAllowed("station_types", "new")}
            path="/station-types/add"
            component={StationTypesAdd}
          />
        )}
        {isAllowed("station_types", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("station_types", "view")}
            path="/station-types/edit/:id"
            component={StationTypesEdit}
          />
        )}

        {isAllowed("workspaces", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("workspaces", "view")}
            path="/workspaces"
            component={WorkspaceList}
            exact={true}
          />
        )}
        {isAllowed("workspaces", "new") && (
          <RouteWithNavBar
            isAllowed={isAllowed("workspaces", "new")}
            path="/workspaces/add"
            component={WorkspaceAdd}
          />
        )}
        {isAllowed("workspaces", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("workspaces", "view")}
            path="/workspaces/edit/:id"
            component={WorkspaceEdit}
          />
        )}
        {isAllowed("workspaces", "view") && (
          <RouteWithNavBar
            isAllowed={isAllowed("workspaces", "view")}
            path="/workspaces/view/:id"
            component={WorkspaceView}
          />
        )}
        {isAllowed("stations", "edit") && (
          <RouteWithNavBar
            path="/station/edit/:id/security/add"
            component={SecurityAdd}
            isAllowed={isAllowed("stations", "edit")}
          />
        )}
        {isAllowed("tickets", "view") && (
          <RouteWithNavBar
            path="/tickets"
            exact
            component={TicketsList}
            isAllowed={isAllowed("tickets", "view")}
          />
        )}
        {isAllowed("tickets", "new") && (
          <RouteWithNavBar
            path="/tickets/add"
            component={TicketAdd}
            isAllowed={isAllowed("tickets", "new")}
          />
        )}
        {isAllowed("tickets", "edit") && (
          <RouteWithNavBar
            path="/tickets/edit/:id"
            component={TicketEdit}
            isAllowed={isAllowed("tickets", "edit")}
          />
        )}
        {isAllowed("tickets", "view") && (
          <RouteWithNavBar
            path="/tickets/view/:id"
            component={TicketView}
            isAllowed={isAllowed("tickets", "view")}
          />
        )}
        {isAllowed("my_notifications", "view") && (
          <RouteWithNavBar
            exact
            path="/notifications"
            component={Notifications}
            isAllowed={isAllowed("my_notifications", "view")}
          />
        )}
        {isAllowed("my_notifications", "view") && (
          <RouteWithNavBar
            path="/notifications/view/:id"
            component={NotificationView}
            isAllowed={isAllowed("my_notifications", "view")}
          />
        )}
        {/* edit later */}
        {isAllowed("my_notifications", "view") && (
          <RouteWithNavBar
            path="/notifications/add"
            component={NotificationSystemAdd}
            isAllowed={isAllowed("my_notifications", "view")}
          />
        )}

        {isAllowed("my_notifications", "view") && (
          <RouteWithNavBar
            path="/notifications/edit/:id"
            component={SystemNotificationSystemEdit}
            isAllowed={isAllowed("my_notifications", "view")}
          />
        )}
        {isAllowed("my_notifications", "view") && (
          <RouteWithNavBar
            path="/notifications/sys/view/:id"
            component={NotificationSystemView}
            isAllowed={isAllowed("my_notifications", "view")}
          />
        )}

        {isAllowed("chats", "view") && (
          <RouteWithNavBar
            path="/chats"
            component={ChatList}
            isAllowed={isAllowed("chats", "view")}
          />
        )}

        <RouteWithNavBar
          path="/workspaces/:workspaceId/dashboards/:id"
          exact
          component={Dashboard}
          isAllowed={true}
        />

        <RouteWithNavBar
          path="/import-data"
          exact
          component={ImportData}
          isAllowed={isAllowed("data_sharing_request", "view")}
        />

        <RouteWithNavBar
          path="/workspaces/:id/export-data"
          exact
          component={ExportData}
          isAllowed={isAllowed("stations", "view")}
        />

        {/* Data Sharing */}
        {isFeatureEnabled(FEATURE_ID.DATA_SHARING) && [
          <RouteWithNavBar
            exact
            path="/data-sharing-request"
            component={DataSharingRequestList}
            isAllowed={isAllowed("data_sharing_request", "view")}
          />,
          <RouteWithNavBar
            path="/data-sharing-request/add"
            component={DataSharingAdd}
            isAllowed={isAllowed("data_sharing_request", "new")}
          />,
          <RouteWithNavBar
            path="/data-sharing-request/edit/:id"
            component={DataSharingEdit}
            isAllowed={isAllowed("data_sharing_request", "edit")}
          />,
          <RouteWithNavBar
            exact
            path="/data-files"
            component={DataFilesList}
            isAllowed={isAllowed("data_files", "view")}
          />,
          <RouteWithNavBar
            path="/data-files/add"
            component={DataFilesAdd}
            isAllowed={isAllowed("data_files", "new")}
          />,
          <RouteWithNavBar
            path="/data-files/edit/:id"
            component={DataFilesEdit}
            isAllowed={isAllowed("data_files", "edit")}
          />,
        ]}
        {/* Sync Data */}
        {isFeatureEnabled(FEATURE_ID.SYNC_DATA) && [
          <RouteWithNavBar
            path="/sync-data-request"
            exact
            component={SyncRequestList}
            isAllowed={isAllowed("stations", "view")}
          />,
          <RouteWithNavBar
            path="/sync-data-request/add"
            component={SyncRequest}
            isAllowed={isAllowed("stations", "view")}
          />,
          <RouteWithNavBar
            path="/sync-data-response"
            component={SyncResponseList}
            exact
            isAllowed={isAllowed("stations", "view")}
          />,
          <RouteWithNavBar
            path="/sync-data-response/:status/edit/:id"
            component={SyncResponse}
            isAllowed={isAllowed("stations", "view")}
          />,
          <RouteWithNavBar
            path="/sync-data-request/:status/edit/:id"
            exact
            component={SyncRequest}
            isAllowed={isAllowed("stations", "view")}
          />,
          <RouteWithNavBar
            path="/sync-data-request/:id/security/add"
            component={SyncDataRequestSecurityAdd}
            isAllowed={isAllowed("stations", "view")}
          />,
        ]}
        {/* API keys */}
        {isFeatureEnabled(FEATURE_ID.API_KEYS) && [
          <RouteWithNavBar
            path="/api-keys"
            component={ApiKeyList}
            isAllowed={isAllowed("api_key", "view")}
            exact={true}
          />,
          <RouteWithNavBar
            path="/api-keys/add"
            component={ApiKeyAdd}
            isAllowed={isAllowed("api_key", "new")}
          />,
          <RouteWithNavBar
            path="/api-keys/edit/:id"
            component={ApiKeyEdit}
            isAllowed={isAllowed("api_key", "edit")}
          />,
          <RouteWithNavBar
            path="/api-keys/view/:id"
            component={ApiKeyView}
            isAllowed={isAllowed("api_key", "view")}
          />,
        ]}

        {/* MQTT */}
        {isFeatureEnabled(FEATURE_ID.MQTT) && [
          <RouteWithNavBar
            path="/mqtt"
            component={MQTTList}
            isAllowed={isAllowed("mqtt", "view")}
            exact={true}
          />,
          <RouteWithNavBar
            path="/mqtt/add"
            component={MQTTAdd}
            isAllowed={isAllowed("mqtt", "new")}
          />,
          <RouteWithNavBar
            path="/mqtt/edit/:id"
            component={MQTTEdit}
            isAllowed={isAllowed("mqtt", "edit")}
          />,
          <RouteWithNavBar
            path="/mqtt/view/:id"
            component={MQTTView}
            isAllowed={isAllowed("mqtt", "view")}
          />,
        ]}
        {/* User guide */}
        {isFeatureEnabled(FEATURE_ID.USER_GUIDE) && [
          <RouteWithNavBar
            path="/category/:categoryId/user-guide/:folderId/guide/:groupId/edit/:language/:id"
            component={GuideEdit}
            isAllowed={isAllowed("chats", "view")}
          />,
          <RouteWithNavBar
            path="/category/:categoryId/user-guide/:folderId/guide/:groupId/add/:language"
            component={GuideEdit}
            isAllowed={isAllowed("chats", "view")}
          />,
          <RouteWithNavBar
            path="/category/:categoryId/user-guide/:folderId"
            component={GuideList}
            isAllowed={isAllowed("stations", "view")}
          />,
        ]}

        <FeatureRoutes routes={routes} />
        <Route exact path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}
