import React, { memo, useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import "./ChatWindow.css";

import ChatWindowFooter from "./ChatWindowFooter";
import MessageList from "./MessageList";
import ChatHeader from "./ChatHeader";

import chatApi from "../../../../api/chatApi";
import endUserChatApi from "../../../../api/endUserChatApi";
import EndUserForm from "../EndUserForm";

const ChatWindow = (props) => {
  const { onClose, onHide, isNew, item, socket, token, userId } = props;

  const [messageList, setMessageList] = useState([]);
  const [staticMessageList, setStaticMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(item.page || 1);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    socket?.on(item.roomChatId, (res) => {
      if (!res.success && res?.length > 0) {
        const data = res
          .map((file) => ({
            roomChatMemberId: file.roomChatMemberId,
            roomChatId: item.roomChatId,
            "RoomChatAttachments.attachmentPath": file?.attachmentPath,
            "RoomChatAttachments.extensionAttachment":
              file?.extensionAttachment,
            "RoomChatAttachments.id": file?.id,
            "RoomChatAttachments.originalNameAttachment":
              file?.originalNameAttachment,
            createdAt: file.createdAt,
            id: file.id,
          }))
          ?.reverse();
        setMessageList((prevList) => [...prevList, ...data]);
        setStaticMessageList((prevList) => [...prevList, ...data]);
      }
      if (res.success) {
        setMessageList((prevList) => [...prevList, res.data]);
        setStaticMessageList((prevList) => [...prevList, res.data]);
      }
    });
  }, [item.roomChatId]);

  useEffect(() => {
    if (!messageList?.length) {
      setMessageList(item.data);
      setStaticMessageList(item.data);
    }
  }, [item.data]);

  useEffect(() => {
    const chatSession = JSON.parse(localStorage.getItem("reeco-chat-session"));

    const fetchMessages = async () => {
      try {
        setLoading(true);
        const data = {
          token: chatSession?.token,
          limit: 8,
          page,
        };
        const resp = await endUserChatApi.getEndUserMessageList(data);
        if (resp.success) {
          if (resp.message?.length === 0) {
            setHasMore(false);
          } else {
            const list = resp?.message?.reverse();
            setMessageList((prevList) => [...list, ...prevList]);
            document.querySelector(".chat-window-main").scrollTop = 200;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (page !== 1 && chatSession?.token) {
      fetchMessages();
    }
  }, [page]);

  const handleSubmit = async (text, files) => {
    const value = text.trim();
    const chatSession = JSON.parse(localStorage.getItem("reeco-chat-session"));
    if (files && files.length > 0) {
      const form = new FormData();
      for (let file of files) {
        form.append("attachment", file.file);
      }
      form.append("roomChatMemberId", item.roomChatMemberId);
      const resp = await chatApi.uploadFile(form, chatSession.token);
      if (resp.success) {
        const postData = {
          roomChatMemberId: item.roomChatMemberId,
          roomChatId: item.roomChatId,
          message: "",
          token: chatSession.token,
          senderUserId: userId,
          attachment: resp?.data?.attachment,
        };
        socket?.emit("Client-sent-data", postData);
        const data = resp?.data?.attachment
          ?.map((file) => ({
            roomChatMemberId: item.roomChatMemberId,
            roomChatId: item.roomChatId,
            senderUserId: userId,
            "RoomChatAttachments.attachmentPath": file?.attachmentPath,
            "RoomChatAttachments.extensionAttachment":
              file?.extensionAttachment,
            "RoomChatAttachments.id": file?.id,
            "RoomChatAttachments.originalNameAttachment":
              file?.originalNameAttachment,
            createdAt: file.createdAt,
            id: file.id,
          }))
          ?.reverse();

        setMessageList((prevList) => [...prevList, ...data]);
        setStaticMessageList((prevList) => [...prevList, ...data]);
      }
    }
    if (!value) return;
    try {
      const data = {
        roomChatMemberId: item.roomChatMemberId,
        roomChatId: item.roomChatId,
        message: value,
        senderUserId: userId,
        token: chatSession.token,
        attachment: null,
      };
      socket?.emit("Client-sent-data", data);
      setMessageList((prevList) => [
        ...prevList,
        {
          ...data,
          createdAt: new Date(),
          id: uuidv4(),
        },
      ]);
      setStaticMessageList((prevList) => [
        ...prevList,
        {
          ...data,
          createdAt: new Date(),
          id: uuidv4(),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const renderMessages = () => {
    return (
      <>
        {loading && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size={20} />
          </Box>
        )}
        <MessageList
          messageList={messageList}
          loading={loading}
          hasMore={hasMore}
          setPage={setPage}
          item={item}
          // userAvatar={userAvatar}
          lastMessageRef={lastMessageRef}
        />
      </>
    );
  };

  return (
    <div className="chat-window">
      <ChatHeader
        item={item}
        staticMessageList={staticMessageList}
        onHide={onHide}
        onClose={onClose}
      />

      <div
        className="chat-window-main"
        style={{
          maxHeight: messageList ? "400px" : "unset",
          pointerEvents: loading ? "none" : "all",
        }}
      >
        {item.status === "contact" ? <EndUserForm /> : renderMessages()}
      </div>

      {!isNew && item.status !== "contact" && (
        <ChatWindowFooter onSubmit={handleSubmit} id={item.id} />
      )}
    </div>
  );
};

export default memo(ChatWindow);
