import { CircularProgress, Tooltip } from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ViewListIcon from "@material-ui/icons/ViewList";
import { STATISTIC_TABLE } from "app/pages/Dashboard/components/Modals/contants";
import {
  DASHBOARD_TYPE,
  TABLE_ALARM_COLOR,
} from "app/shared/constants/dashboard";
import { invertColor } from "app/utils/utils";
import clsx from "clsx";
import { cloneDeep, findIndex, isEmpty, lowerCase, map, keyBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import workspaceDashboardApi from "../../../../api/workspaceDashboardApi";
import { DATE_TIME_FORMAT } from "../../../../constant/date-time-format";
import { useLanguage } from "../../../../shared/hooks";
import { formatString } from "../../../../utils/string-formatter";
import { DashboardCardButtons } from "../DashboardCardButtons";
import {
  adjustFunction,
  checkInTimeRange,
  getListKeysSorted,
  getNextTime,
  prepareDataObject,
} from "./helper";
import "./styles.css";

const { FUNCTIONS } = STATISTIC_TABLE;

const HistoricTableCard = (props) => {
  const ZERO_TIME_RANGE = "0";

  const [language] = useLanguage();

  const renderHistoricTableCardTitle = () =>
    props.data.dashboard_type_code ===
    DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE ? (
      <FormattedMessage id="CLIENT.DASHBOARD.ITEM.PREDICTION_HISTORIC_NEW" />
    ) : (
      <FormattedMessage id="CLIENT.DASHBOARD.ITEM.HISTORIC_NEW" />
    );

  const name =
    (props["data"] && props["data"][`name_${language}`]) ||
    renderHistoricTableCardTitle();
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;
  const displayMode =
    props?.data?.dashboard_historic_table?.display_mode ||
    props?.data?.dashboard_prediction_parameter_history_table?.display_mode ||
    "portrait";
  // const predictionDisplayMode =
  // 	props?.data?.dashboard_prediction_parameter_history_table?.display_mode || "portrait";

  const [dataAlarm, setDataAlarm] = useState({});

  const [data, setData] = useState({});
  const [functionValue, setFunctionValue] = useState(null);
  const [predictionData, setPredictionData] = useState({});
  const [nextTime, setNextTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [tableConfig, setTableConfig] = useState(null); // using for prediction table
  const { width: cardWidth, ref: cardRef } = useResizeDetector();
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  const loadNewStatisticData = async () => {
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getLatestAriDataPublic(
          props?.data?.public_id,
          preparePayload()
        );
      } else {
        res = await workspaceDashboardApi.getLatestAriData(
          props?.data?.id,
          preparePayload(),
          token
        );
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const preparePayload = () => {
    const parameterDto =
      data?.data?.parameterDatas?.map((item) => ({
        parameterId: item?.parameterDto?.parameterId,
        organizationId: item?.parameterDto?.organizationId,
        stationId: item?.parameterDto?.stationId,
      })) || [];
    return {
      interval: props?.data?.dashboard_historic_table?.interval,
      parameterDto,
      nextTime,
    };
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      if (dashboardData?.tableConfig) {
        setTableConfig(dashboardData.tableConfig);
      }

      const dataObject = prepareDataObject(
        dashboardData?.parameterDatas || [],
        language
      );
      const predictionDataObject = prepareDataObject(
        dashboardData?.prediction_value?.parameterDatas || [],
        language
      );

      const parameterInfo = {};
      let alarmList = [];

      if (
        props.data.dashboard_type_code ===
        DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE
      ) {
        props.data.dashboard_prediction_parameter_history_table.dashboard_prediction_parameter_historic_table_parameter?.forEach(
          (item) => {
            parameterInfo[item?.parameter?.id] = {
              id: item?.parameter?.id,
              name_en: item?.parameter?.name,
              name_vi: item?.parameter?.name_vi,
              main_color: item.main_color,
              alarm_color: item.alarm_color,
              display_type: item.display_type,
              unit: item.parameter?.unit,
              format: item.parameter?.parameter_format_text,
            };

            alarmList = alarmList.concat(item.parameter?.data_alarm);
          }
        );
      } else {
        props.data.dashboard_historic_table.dashboard_historic_table_parameter?.forEach(
          (item) => {
            parameterInfo[item?.parameter?.id] = {
              id: item?.parameter?.id,
              name_en: item?.parameter?.name,
              name_vi: item?.parameter?.name_vi,
              main_color: item.main_color,
              alarm_color: item.alarm_color,
              display_type: item.display_type,
              unit: item.parameter?.unit,
              format: item.parameter?.parameter_format_text,
            };

            alarmList = alarmList.concat(item.parameter?.data_alarm);
          }
        );
      }

      const keys = getListKeysSorted(dataObject);
      const predictionKeys = getListKeysSorted(predictionDataObject);
      const data = {
        data: dashboardData,
        dataObject,
        parameterInfo,
        keys,
      };
      const predictionData = {
        data: dashboardData?.prediction_value,
        dataObject: predictionDataObject,
        parameterInfo,
        keys: predictionKeys,
      };
      setDataAlarm(keyBy(alarmList, "id"));
      setNextTime(moment(keys[0], DATE_TIME_FORMAT).format(DATE_TIME_FORMAT));
      setData(data);
      setPredictionData(predictionData);
    };

    if (
      props.data &&
      (props.data.dashboard_historic_table ||
        props.data.dashboard_prediction_parameter_history_table)
    ) {
      initData();
      setFunctionValue(
        props.data?.dashboard_historic_table?.aggregate_function
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["data"]]);

  const adjustDataRealtime = () => {
    const newParameterDatas =
      cloneDeep(
        data?.dataObject?.[
          moment(new Date(props.realTimeData?.eventTime)).format(
            DATE_TIME_FORMAT
          )
        ]
      ) || [];
    const Index = findIndex(
      newParameterDatas,
      (pdata) => pdata.paramId === props.realTimeData?.paramId
    );

    if (Index === -1) {
      newParameterDatas?.push({
        ...props.realTimeData,
        parameterId: props.realTimeData?.paramId,
      });
    } else {
      newParameterDatas[Index] = {
        ...newParameterDatas[Index],
        value: props.realTimeData?.value,
      };
    }

    const dataObject = {
      ...data?.dataObject,
      [moment(new Date(props.realTimeData?.eventTime)).format(
        DATE_TIME_FORMAT
      )]: newParameterDatas,
    };
    // Nếu dữ liệu mới nhận về là mới nhất, thì xóa dữ liệu ở thời điểm cuối cùng để đẩy dữ liệu này vào
    if (
      moment(props.realTimeData?.eventTime) >
      moment(data?.keys?.at(0), DATE_TIME_FORMAT)
    ) {
      delete dataObject[data?.keys?.at(-1)];
    }
    setData((previous) => ({
      ...previous,
      data: {
        ...previous.data,
        endTime: moment(new Date(props.realTimeData?.eventTime)),
      },
      dataObject: {
        ...previous.dataObject,
        ...dataObject,
      },
    }));
  };

  const adjustDataWithFunction = async () => {
    const newRawData = await loadNewStatisticData();
    const newData = prepareDataObject(
      newRawData?.parameterDatas || [],
      language
    );
    const newKeys = getListKeysSorted(newData);
    if (newKeys?.length > 0) {
      const dataObject = {
        ...data?.dataObject,
        ...newData,
      };
      // remove old data
      const oldKeys = Object.keys(dataObject).filter((key) => {
        const timeType = `${props?.data?.time_range}_${props?.data?.time_range_type}`;
        if (timeType == "0_DAY") {
          return !checkInTimeRange(
            key,
            props?.data?.historical_start_time,
            props?.data?.historical_end_time
          );
        } else {
          const start = moment(newKeys[0]).subtract(
            +props?.data?.time_range,
            `${lowerCase(props?.data?.time_range_type)}s`
          );
          return !checkInTimeRange(key, start, undefined);
        }
      });

      oldKeys.forEach((key) => {
        delete dataObject[key];
      });

      const keys = getListKeysSorted(dataObject);
      if (
        moment(keys[0], DATE_TIME_FORMAT) >=
        getNextTime(nextTime, props?.data?.dashboard_historic_table?.interval)
      ) {
        setNextTime(moment(keys[0], DATE_TIME_FORMAT).format(DATE_TIME_FORMAT));
      }
      setData((previous) => ({
        ...previous,
        data: {
          ...previous.data,
          endTime: moment(),
        },
        dataObject,
        keys,
      }));
    }
  };

  // Data realtime processing
  useEffect(() => {
    if (
      props.data.historical_time_range !== ZERO_TIME_RANGE &&
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId &&
      data?.parameterInfo?.[props.realTimeData?.paramId]
    ) {
      if (functionValue == FUNCTIONS[0].value) {
        adjustDataRealtime();
      } else {
        adjustDataWithFunction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
    props.data.historical_time_range,
  ]);

  // Alarm processing
  useEffect(() => {
    if (
      props.data.historical_time_range !== ZERO_TIME_RANGE &&
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId &&
      data?.parameterInfo?.[props.realTimeDataAlarm?.paramId]
    ) {
      const eventTimeKey = moment(
        new Date(props.realTimeDataAlarm?.eventTime)
      ).format(DATE_TIME_FORMAT);
      if (
        !data?.dataObject?.[eventTimeKey]?.find(
          (item) => item.parameterId === props.realTimeDataAlarm?.paramId
        )
      )
        return;
      const dataObject = {
        ...data?.dataObject,
        [eventTimeKey]: map(data?.dataObject?.[eventTimeKey], (item) => {
          if (item.parameterId === props.realTimeDataAlarm?.paramId) {
            return {
              ...item,
              isAlarm: true,
              alarmId: props.realTimeDataAlarm?.alarmId,
              status: props.realTimeDataAlarm?.[`name_${language}`],
            };
          }
          return item;
        }),
      };
      setData((previous) => ({
        ...previous,
        data: {
          ...previous.data,
          endTime: props.realTimeData?.eventTime,
        },
        dataObject: {
          ...previous.dataObject,
          ...dataObject,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.realTimeDataAlarm?.eventTime,
    props.realTimeDataAlarm?.paramId,
    props.data.historical_time_range,
  ]);

  useEffect(() => {
    if (!isEmpty(data?.dataObject)) {
      const keys = getListKeysSorted(data?.dataObject);
      setData((previous) => ({
        ...previous,
        keys,
      }));
    }
  }, [data?.dataObject]);

  const TableHeader = () => {
    return (
      <table className="historic-table-header-container">
        <tr
          className={clsx(
            { "historic-table-header": tableConfig === null },
            { "historic-table-custom": tableConfig }
          )}
        >
          <th
            style={
              tableConfig
                ? {
                    width: getWidth(data?.keys?.length),
                    minWidth: getWidth(data?.keys?.length),
                    backgroundColor: `${tableConfig.column_header_color}`,
                    color: `${invertColor(
                      tableConfig.column_header_color,
                      true
                    )}`,
                    borderColor: `${invertColor(
                      tableConfig.column_header_color,
                      true
                    )}`,
                  }
                : {
                    width: getWidth(data?.keys?.length),
                    minWidth: getWidth(data?.keys?.length),
                  }
            }
          >
            <FormattedMessage id="CLIENT.GENERAL.TIME_LABEL" />
          </th>

          {data?.data?.parameterDatas?.map((paramsData) => {
            if (data?.parameterInfo[paramsData?.parameterDto?.parameterId]) {
              return (
                <th
                  style={
                    tableConfig
                      ? {
                          width: getWidth(data?.keys?.length),
                          minWidth: getWidth(data?.keys?.length),
                          backgroundColor: `${tableConfig.column_header_color}`,
                          color: `${invertColor(
                            tableConfig.column_header_color,
                            true
                          )}`,
                          borderBottomColor: `${invertColor(
                            tableConfig.column_header_color,
                            true
                          )}`,
                          borderColor: `${invertColor(
                            tableConfig.column_header_color,
                            true
                          )}`,
                        }
                      : {
                          width: getWidth(data?.keys?.length),
                          minWidth: getWidth(data?.keys?.length),
                        }
                  }
                >
                  <div>
                    {paramsData?.parameterDto?.display_name ||
                      data?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ][`name_${language}`]}
                  </div>
                  <div>
                    (
                    {
                      data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                        ?.unit
                    }
                    )
                  </div>
                </th>
              );
            }

            return null;
          })}
        </tr>
      </table>
    );
  };

  const getWidth = (n) => {
    //  && n * COLUMN_WIDTH + 240 > cardWidth
    if (n > 2) {
      return 160;
    }

    if (n === 1) n = 2;

    if (cardWidth / n <= 120) {
      return cardWidth / n;
    }

    return 160;
  };

  const TableHeaderReverse = () => {
    return (
      <table
        className="historic-table-header-container"
        style={{ borderCollapse: "separate", borderSpacing: 0 }}
      >
        <tr className="historic-table-header">
          <th
            style={{
              width: getWidth(data?.keys?.length),
              minWidth: getWidth(data?.keys?.length),
            }}
          >
            Parameter
          </th>

          {data?.keys?.map((key) => (
            <th
              style={{
                width: getWidth(data?.keys?.length),
                minWidth: getWidth(data?.keys?.length),
              }}
            >
              {moment(key, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT)}
            </th>
          ))}
        </tr>
      </table>
    );
  };

  const Row = () => {
    return data?.keys?.map((key) => {
      return (
        <table
          className="historic-table"
          style={{
            minWidth: cardWidth,
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <tr>
            <th
              style={
                tableConfig
                  ? {
                      width: getWidth(data?.keys?.length),
                      minWidth: getWidth(data?.keys?.length),
                      backgroundColor: `${tableConfig.history_row_color}`,
                      color: `${invertColor(
                        tableConfig.history_row_color,
                        true
                      )}`,
                      borderColor: `${invertColor(
                        tableConfig.history_row_color,
                        true
                      )}`,
                      borderBottomColor: `${invertColor(
                        tableConfig.history_row_color,
                        true
                      )}`,
                    }
                  : {
                      width: getWidth(data?.keys?.length),
                      minWidth: getWidth(data?.keys?.length),
                    }
              }
            >
              {moment(key, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT)}
            </th>

            {data?.data?.parameterDatas?.map((paramsData) => {
              // TODO: rollback when testing completed
              const paramValue = data.dataObject[key]?.find(
                (item) =>
                  item.parameterId === paramsData?.parameterDto?.parameterId
              );
              //const paramValue = data.dataObject[key]?.find(
              // (item) => item.parameterId === 1
              // );

              if (!paramValue) {
                return (
                  <td
                    style={{
                      background:
                        data?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                      color: `${invertColor(
                        data?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      )}`,
                      borderColor: `${invertColor(
                        data?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      )}`,
                      borderBottomColor: `${invertColor(
                        data?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      )}`,
                      width:
                        data?.keys?.length === 1
                          ? getWidth(data?.keys?.length) + 10
                          : getWidth(data?.keys?.length),
                      minWidth:
                        data?.keys?.length === 1
                          ? getWidth(data?.keys?.length) + 10
                          : getWidth(data?.keys?.length),
                    }}
                  ></td>
                );
              }

              const background = paramValue.isAlarm
                ? dataAlarm?.[paramValue.alarmId]?.alarm_color ||
                  TABLE_ALARM_COLOR
                : data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                    ?.main_color;

              const invertBackground = invertColor(background, true);
              const func = adjustFunction(functionValue);
              const value = isNaN(Number(paramValue[func]))
                ? paramValue[func]
                : data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                    ?.format
                ? formatString(
                    data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                      ?.format,
                    paramValue[func]
                  )
                : paramValue[func];
              const status = paramValue.status || "";
              return (
                <td
                  style={
                    tableConfig
                      ? {
                          backgroundColor: `${tableConfig.history_row_color}`,
                          width: getWidth(data?.keys?.length),
                          minWidth: getWidth(data?.keys?.length),
                          color: `${invertBackground}`,
                          borderColor: `${invertBackground}`,
                          borderBottomColor: `${invertBackground}`,
                        }
                      : {
                          background: background,
                          color: `${invertBackground}`,
                          borderColor: `${invertBackground}`,
                          borderBottomColor: `${invertBackground}`,
                          width: getWidth(data?.keys?.length),
                          minWidth: getWidth(data?.keys?.length),
                        }
                  }
                >
                  {data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                    ?.display_type === "status"
                    ? status
                    : value}
                </td>
              );
            })}
          </tr>
        </table>
      );
    });
  };

  const RowPrediction = () => {
    return predictionData?.keys?.map((key) => {
      return (
        <table
          className="historic-table"
          style={{
            minWidth: cardWidth,
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <tr>
            <th
              style={
                tableConfig
                  ? {
                      width: getWidth(data?.keys?.length),
                      minWidth: getWidth(data?.keys?.length),
                      backgroundColor: `${tableConfig.prediction_row_color}`,
                      color: `${invertColor(
                        tableConfig.prediction_row_color,
                        true
                      )}`,
                      borderColor: `${invertColor(
                        tableConfig.prediction_row_color,
                        true
                      )}`,
                      borderBottomColor: `${invertColor(
                        tableConfig.prediction_row_color,
                        true
                      )}`,
                    }
                  : {
                      width: getWidth(data?.keys?.length),
                      minWidth: getWidth(data?.keys?.length),
                    }
              }
            >
              {moment(key, DATE_TIME_FORMAT).format(DATE_TIME_FORMAT)}
            </th>

            {predictionData?.data?.parameterDatas?.map((paramsData) => {
              // TODO: rollback when testing completed
              const paramValue = predictionData.dataObject[key]?.find(
                (item) =>
                  item.parameterId === paramsData?.parameterDto?.parameterId
              );
              //const paramValue = data.dataObject[key]?.find(
              // (item) => item.parameterId === 1
              // );

              if (!paramValue) {
                return (
                  <td
                    style={{
                      background:
                        predictionData?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                      color: invertColor(
                        predictionData?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      ),
                      borderColor: invertColor(
                        predictionData?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      ),
                      borderBottomColor: invertColor(
                        predictionData?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.main_color,
                        true
                      ),
                      width:
                        predictionData?.keys?.length === 1
                          ? getWidth(predictionData?.keys?.length) + 10
                          : getWidth(predictionData?.keys?.length),
                      minWidth:
                        predictionData?.keys?.length === 1
                          ? getWidth(predictionData?.keys?.length) + 10
                          : getWidth(predictionData?.keys?.length),
                    }}
                  ></td>
                );
              }

              const background = paramValue.isAlarm
                ? dataAlarm?.[paramValue.alarmId]?.alarm_color ||
                  TABLE_ALARM_COLOR
                : predictionData?.parameterInfo[
                    paramsData?.parameterDto?.parameterId
                  ]?.main_color;
              const value = isNaN(Number(paramValue.value))
                ? paramValue.value
                : predictionData?.parameterInfo[
                    paramsData?.parameterDto?.parameterId
                  ]?.format
                ? formatString(
                    predictionData?.parameterInfo[
                      paramsData?.parameterDto?.parameterId
                    ]?.format,
                    paramValue.value
                  )
                : paramValue.value;
              const status = paramValue.status || "";
              return (
                <td
                  style={
                    tableConfig
                      ? {
                          backgroundColor: `${tableConfig.prediction_row_color}`,
                          color: `${invertColor(
                            tableConfig.prediction_row_color,
                            true
                          )}`,
                          borderColor: `${invertColor(
                            tableConfig.prediction_row_color,
                            true
                          )}`,
                          borderBottomColor: `${invertColor(
                            tableConfig.prediction_row_color,
                            true
                          )}`,
                          width: getWidth(predictionData?.keys?.length),
                          minWidth: getWidth(predictionData?.keys?.length),
                        }
                      : {
                          background: background,
                          color: `${invertColor(background, true)}`,
                          borderColor: `${invertColor(background, true)}`,
                          borderBottomColor: `${invertColor(background, true)}`,
                          width: getWidth(predictionData?.keys?.length),
                          minWidth: getWidth(predictionData?.keys?.length),
                        }
                  }
                >
                  {predictionData?.parameterInfo[
                    paramsData?.parameterDto?.parameterId
                  ]?.display_type === "status"
                    ? status
                    : value}
                </td>
              );
            })}
          </tr>
        </table>
      );
    });
  };

  const RowReverse = () => {
    return (
      <table
        className="historic-table"
        style={{
          minWidth: cardWidth,
          borderCollapse: "separate",
          borderSpacing: 0,
        }}
      >
        {data?.data?.parameterDatas?.map((paramsData) => {
          if (data?.parameterInfo[paramsData?.parameterDto?.parameterId]) {
            return (
              <tr>
                <th
                  style={{
                    width: getWidth(data?.keys?.length),
                    borderColor: "#fff",
                  }}
                >
                  <div>
                    {paramsData?.parameterDto?.display_name ||
                      data?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ][`name_${language}`]}
                  </div>
                  <div>
                    (
                    {
                      data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                        ?.unit
                    }
                    )
                  </div>
                </th>

                {data?.keys?.map((key) => {
                  const paramValue = data.dataObject[key]?.find(
                    (item) =>
                      item.parameterId === paramsData?.parameterDto?.parameterId
                  );

                  if (!paramValue) {
                    return (
                      <td
                        style={{
                          background:
                            data?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                          width: getWidth(data?.keys?.length),
                          color: `${invertColor(
                            data?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          )}`,
                          borderBottomColor: invertColor(
                            data?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          ),
                          borderColor: invertColor(
                            data?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          ),
                        }}
                      ></td>
                    );
                  }

                  const background = paramValue.isAlarm
                    ? dataAlarm?.[paramValue.alarmId]?.alarm_color ||
                      TABLE_ALARM_COLOR
                    : data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                        ?.main_color;
                  const func = adjustFunction(functionValue);
                  const value = isNaN(Number(paramValue[func]))
                    ? paramValue[func]
                    : data?.parameterInfo[paramsData?.parameterDto?.parameterId]
                        ?.format
                    ? formatString(
                        data?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.format,
                        paramValue[func]
                      )
                    : paramValue[func];

                  const status = paramValue.status || "";

                  return (
                    <td
                      style={{
                        background: background,
                        color: invertColor(background),
                        width: getWidth(data?.keys?.length),
                        borderBottomColor: invertColor(background),
                        borderColor: invertColor(background),
                      }}
                    >
                      {data?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ]?.display_type === "status"
                        ? status
                        : value}
                    </td>
                  );
                })}
              </tr>
            );
          }

          return null;
        })}
      </table>
    );
  };

  const RowPredictionReverse = () => {
    return (
      <table
        className="historic-table"
        style={{
          minWidth: cardWidth,
          borderCollapse: "separate",
          borderSpacing: 0,
        }}
      >
        {predictionData?.data?.parameterDatas?.map((paramsData) => {
          if (
            predictionData?.parameterInfo[paramsData?.parameterDto?.parameterId]
          ) {
            return (
              <tr>
                <th style={{ width: getWidth(predictionData?.keys?.length) }}>
                  <div>
                    {
                      predictionData?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ][`name_${language}`]
                    }
                  </div>
                  <div>
                    (
                    {
                      predictionData?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ]?.unit
                    }
                    )
                  </div>
                </th>

                {predictionData?.keys?.map((key) => {
                  const paramValue = predictionData.dataObject[key]?.find(
                    (item) =>
                      item.parameterId === paramsData?.parameterDto?.parameterId
                  );

                  if (!paramValue) {
                    return (
                      <td
                        style={{
                          background:
                            predictionData?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                          color: invertColor(
                            predictionData?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          ),
                          borderBottomColor: invertColor(
                            predictionData?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          ),
                          borderColor: invertColor(
                            predictionData?.parameterInfo[
                              paramsData?.parameterDto?.parameterId
                            ]?.main_color,
                            true
                          ),
                          width: getWidth(predictionData?.keys?.length),
                        }}
                      ></td>
                    );
                  }

                  const background = paramValue.isAlarm
                    ? dataAlarm?.[paramValue.alarmId]?.alarm_color ||
                      TABLE_ALARM_COLOR
                    : predictionData?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ]?.main_color;
                  const value = isNaN(Number(paramValue.value))
                    ? paramValue.value
                    : predictionData?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ]?.format
                    ? formatString(
                        predictionData?.parameterInfo[
                          paramsData?.parameterDto?.parameterId
                        ]?.format,
                        paramValue.value
                      )
                    : paramValue.value;

                  const status = paramValue.status || "";

                  return (
                    <td
                      style={{
                        background: background,
                        color: invertColor(background, true),
                        width: getWidth(predictionData?.keys?.length),
                        borderBottomColor: invertColor(background, true),
                        borderColor: invertColor(background, true),
                      }}
                    >
                      {predictionData?.parameterInfo[
                        paramsData?.parameterDto?.parameterId
                      ]?.display_type === "status"
                        ? status
                        : value}
                    </td>
                  );
                })}
              </tr>
            );
          }

          return null;
        })}
      </table>
    );
  };

  return (
    <div
      ref={cardRef}
      className={`historic-table-card dashboard-item-card ${
        displayMode === "landscape"
          ? "historic-table-card--landscape"
          : "historic-table-card--portrait"
      } ${
        cardWidth <= 300
          ? "historic-table-card--mobile"
          : "historic-table-card--normal"
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {showHeader && (
        <p
          className="historic-table-card-title"
          style={{
            background: headerColor,
            color: invertColor(headerColor, true),
            borderColor: invertColor(headerColor, true),
            borderBottomColor: invertColor(headerColor, true),
          }}
        >
          <ViewListIcon />
          &nbsp;&nbsp;<span>{name}</span>
        </p>
      )}

      <div className="historic-table-card-main">
        {(props["loading"] || loading) && (
          <div className="no-data-area">
            <p className="no-data-label">
              <CircularProgress />
            </p>
          </div>
        )}

        {!props[loading] && !loading && (
          <>
            {data?.dataObject && Object.keys(data?.dataObject).length > 0 ? (
              <>
                <table
                  className="historic-table"
                  style={{ borderCollapse: "separate", borderSpacing: 0 }}
                >
                  {displayMode === "portrait"
                    ? TableHeader()
                    : TableHeaderReverse()}

                  {displayMode === "portrait" ? RowPrediction() : null}
                  {displayMode === "portrait" ? Row() : RowReverse()}
                </table>
              </>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </>
        )}

        <Tooltip
          title={`${props?.intl?.formatMessage({
            id: "CLIENT.DASHBOARD.LAST_RECORDED_TIME",
          })}: ${moment(data?.data?.endTime).format(DATE_TIME_FORMAT)}`}
          placement="right"
        >
          <span
            className="time-tooltip"
            style={{
              left: 24,
              bottom: hovered && props["isView"] === false ? 64 : 24,
            }}
          >
            <QueryBuilderIcon />
          </span>
        </Tooltip>
      </div>

      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Line_chart_icon_Noun_70892_cc_White.svg/1200px-Line_chart_icon_Noun_70892_cc_White.svg.png"
        alt=""
        className="historic-table-card-image"
      />

      <DashboardCardButtons
        visible={hovered === true && props["isView"] === false}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default injectIntl(HistoricTableCard);
