import React from "react";
import { Box, Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Alert } from "@material-ui/lab";
import { isEmail } from "../../../../shared/utils/functions";

export const getNotiMsg = (isRequested, value) => {
  if (isRequested && isEmail(value)) {
    return (
      <Grid item xs={12}>
        <Box mb={5}>
          <Alert severity="success" color="info">
            <FormattedMessage id="CLIENT.FORGOT.EMAIL_SENT" />
          </Alert>
        </Box>
      </Grid>
    );
  } else if (isRequested && !isEmail(value)) {
    return (
      <Grid item xs={12}>
        <Box mb={5}>
          <Alert severity="success" color="info">
            <FormattedMessage id="CLIENT.FORGOT.CODE_SENT" />
          </Alert>
        </Box>
      </Grid>
    );
  }
};
