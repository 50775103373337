import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { FormattedMessage } from "react-intl";

export const DashboardCardButtons = ({
  visible = true,
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const stopPropagation = (e) => e.stopPropagation();

  if (!visible) {
    return null;
  }

  return (
    <div className="dashboard-item-action-area">
      <div className="dashboard-item-action-button-group">
        <span
          className="dashboard-item-action-button"
          onClick={onEdit}
          onMouseDown={stopPropagation}
        >
          <EditIcon fontSize="small" />

          <span>
            <FormattedMessage id="CLIENT.DASHBOARD.ITEM.EDIT" />
          </span>
        </span>

        <span
          className="dashboard-item-action-button"
          onClick={onDelete}
          onMouseDown={stopPropagation}
        >
          <DeleteIcon fontSize="small" />

          <span>
            <FormattedMessage id="CLIENT.DASHBOARD.ITEM.REMOVE" />
          </span>
        </span>
      </div>
    </div>
  );
};
