import { useState } from "react";
import swal from "sweetalert";
import authApi from "../../../../api/authApi";
import {
  getResponseMessage,
  isEmail,
} from "../../../../shared/utils/functions";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  email: "",
};

export const useForgotPassword = ({ intl }) => {
  const [isRequested, setIsRequested] = useState(false);
  const [canResend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(60);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        enableLoading();
        setSubmitting(true);
        const params = {
          username: values.email,
        };
        const res = await authApi.forgotPassword(params);
        if (res.success) {
          if (isRequested) {
            swal({
              title: intl.formatMessage({
                id: isEmail(values.email)
                  ? "CLIENT.FORGOT.RESENT_EMAIL_TITLE"
                  : "CLIENT.FORGOT.RESENT_CODE_TITLE",
              }),
              text: intl.formatMessage({
                id: isEmail(values.email)
                  ? "CLIENT.FORGOT.RESENT_EMAIL_SUCCESSFULL"
                  : "CLIENT.FORGOT.RESENT_CODE_SUCCESSFULL",
              }),
              icon: "success",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
            setResend(false);
            setCounter(60);
          } else {
            setIsRequested(true);
          }
        }
      } catch (error) {
        setIsRequested(false);
        swal({
          title: intl.formatMessage({
            id: isEmail(values.email)
              ? "CLIENT.FORGOT.EMAIL_TITLE_FAILED"
              : "CLIENT.FORGOT.CODE_TITLE_FAILED",
          }),
          text: intl.formatMessage(
            { id: getResponseMessage(error.response.data.code) },
            { name: values.email }
          ),
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
        if (isRequested) {
          swal({
            title: intl.formatMessage({
              id: isEmail(values.email)
                ? "CLIENT.FORGOT.RESENT_EMAIL_TITLE"
                : "CLIENT.FORGOT.RESENT_CODE_TITLE",
            }),
            text: intl.formatMessage({
              id: isEmail(values.email)
                ? "CLIENT.FORGOT.RESENT_EMAIL_FAIL"
                : "CLIENT.FORGOT.RESENT_CODE_FAIL",
            }),
            icon: "error",
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON",
            }),
          });
        }
      } finally {
        disableLoading();
        setSubmitting(false);
      }
    },
  });

  return {
    // Getters
    isRequested,
    canResend,
    loading,
    counter,
    ForgotPasswordSchema,
    formik,

    // Setters
    setIsRequested,
    setResend,
    setLoading,
    setCounter,

    // Utils
    enableLoading,
    disableLoading,
  };
};
