import { Button, FormHelperText, Box } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./index.css";

const ImageUploader = (props) => {
  const fileEl = React.useRef(null);
  const [imageFile, setImageFile] = React.useState(null);
  const [image, setImage] = React.useState("");

  const handleChangeImage = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImageFile(file);
      setImage(reader.result);

      props["onChange"]({
        imageFile: file,
        image: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setImage("");

    props["onChange"]({
      imageFile: null,
      image: "",
    });
  };

  const handleUploadImage = () => {
    fileEl.current.click();
  };

  React.useEffect(() => {
    setImageFile(props["imageFile"] || null);
    setImage(props["image"] || "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["imageFile"], props["image"]]);

  return (
    <>
      <input
        type="file"
        onChange={handleChangeImage}
        style={{ display: "none" }}
        accept="image/png, image/jpg, image/jpeg, image/tif, image/tiff .tif"
        ref={fileEl}
        onClick={(event) => {
          event.target.value = null;
        }}
      />

      {image === "" ? (
        <div className="image-wrapper">
          <img src="/images/no-image.png" className="image-uploader-icon" />
        </div>
      ) : (
        <div className="image-wrapper">
          <img src={image} alt="" className="image" />
        </div>
      )}
      {!image && props.error && (
        <FormHelperText className="error-message">{props.error}</FormHelperText>
      )}

      <div className="image-uploader-buttons" styles={{ marginTop: "24px" }}>
        <Box mt={2} display="flex">
          {image !== "" && (
            <Button
              className="custom-button light-button"
              type="button"
              style={{ marginRight: 12 }}
              onClick={handleRemoveImage}
            >
              <FormattedMessage id="CLIENT.GENERAL.CANCEL_BUTTON" />
            </Button>
          )}
          <Button
            className="custom-button light-button"
            type="button"
            onClick={handleUploadImage}
          >
            <FormattedMessage id="CLIENT.GENERAL.CHOOSE_FILE" />
          </Button>
        </Box>
      </div>
    </>
  );
};

export default ImageUploader;
