import { SET_TOP_MENU } from "./types";

export const setTopMenu = ({ title, breadcrumbs }) => {
  return {
    type: SET_TOP_MENU,
    payload: {
      title,
      breadcrumbs,
    },
  };
};
