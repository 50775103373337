import React from 'react'
import './styles.css';

export default function ConfigureArea(props) {
    const { children, title, mb = 0 } = props;
    return (
        <div style={{ marginBottom: mb }}>
            <h2 className="dashboard-title-1">
                {title}
            </h2>
            <div className="dashboard-configure-area">
                {children}
            </div>
        </div>
    )
}

