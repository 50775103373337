
import { memo } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';

const ChatHeader = (props) => {
  const { item, staticMessageList, onHide, onClose } = props;
  return (
    <div className='chat-window-header'>
      <Box display="flex" alignItems="center">
        <span className='icon-wrapper' style={{ marginLeft: 0, marginRight: 8 }}>
          <ChatIcon />
        </span>

        <span>
          { item.status === "contact" ? 
            <FormattedMessage id="CLIENT.END_USER_CHAT.CLIENT_SUPPORT" /> :
            <FormattedMessage id="CLIENT.END_USER_CHAT.CHAT_WITH_ADMIN" />
          }
        </span> 
      </Box>

      <div className='chat-window-header-right'>
        <span className='icon-wrapper' onClick={() => onHide && onHide(item.id, staticMessageList)}>
          <RemoveIcon />
        </span>
        <span className='icon-wrapper' onClick={() => onClose && onClose(item.id)}>
          <CloseIcon />
        </span>
      </div>
    </div>
  )
}


ChatHeader.propTypes = {
  item: PropTypes.object,
  staticMessageList: PropTypes.array,
  onHide: PropTypes.func,
  onClose: PropTypes.func,
};

export default memo(ChatHeader);