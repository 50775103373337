import { isLatitude, isLongitude } from "app/shared/utils/functions";
import { phoneRegExp } from "app/shared/utils/validateReg";
import * as Yup from "yup";

const EditDataSharingSchema = ({ intl, locationType }) => {
  if (!intl) {
    return undefined;
  }
  return Yup.object().shape({
    user: Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    title: Yup.string()
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    data_key: Yup.string().nullable()
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    address: locationType !== 1 && Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    latitude: locationType === 1 && Yup.mixed()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .test({
        name: "is latitude",
        test: (value) => isLatitude(value),
        message: intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_LATITUDE",
        })
      }),
    longtitude: locationType === 1 && Yup.mixed()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .test({
        name: "is longitude",
        test: (value) => isLongitude(value),
        message: intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_LONGTITUDE",
        })
      }),
    fullname: Yup.string().nullable()
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    email: Yup.string().nullable()
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .email(
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_EMAIL_FORMAT",
        })
      ),
    phone: Yup.string().nullable()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
        })
      ),
    organization_name: Yup.string().nullable()
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
    organization_address: Yup.string().nullable()
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
  });
}

export default EditDataSharingSchema