import React from "react";

export default function PopoverFilterDate({
  standardChartData = {},
  showFilter = false,
  language = "en",
  handleShowFilter = () => {},
  historicalTimeRange = [],
  onSelectRange = () => {},
}) {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (e) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      handleShowFilter(false);
    }
  };

  function isSelectedTime(time) {
    const currentTimeValue = standardChartData.historical_time_range;
    const currentTimeType = standardChartData.historical_time_range_type;
    const timeValue = `${time}`.split("-")?.at(0);
    const timeType = `${time}`.split("-")?.at(1);
    const isCustomRange = time === "0";

    return (
      isCustomRange ||
      (currentTimeValue === timeValue && currentTimeType === timeType)
    );
  }

  if (showFilter) {
    return (
      <div className="date-range-box" ref={wrapperRef}>
        <div className="date-range-box-extended"></div>

        <div className="date-range-box-main">
          {historicalTimeRange.map((range) => (
            <div
              key={range.id}
              className={
                isSelectedTime(range.value)
                  ? "date-range-box-item date-range-box-item--active"
                  : "date-range-box-item"
              }
              onClick={() => onSelectRange(range.value)}
            >
              {range[`name_${language}`]}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
