import { lazy } from "react";
import Loadable from "app/shared/components/Loader/Loadable";

export const parameterRoutes = [
  {
    path: "/station/edit/:id/parameter/add",
    component: Loadable(lazy(() => import("./pages/parameter-add.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/parameter/edit/:parameterId",
    component: Loadable(lazy(() => import("./pages/parameter-edit.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/parameter/view/:parameterId",
    component: Loadable(lazy(() => import("./pages/parameter-view.page"))),
    permissionId: "stations",
    action: "view",
  },
  {
    path: "/station/edit/:id/parameter/duplicate/:parameterId",
    component: Loadable(lazy(() => import("./pages/parameter-duplicate.page"))),
    permissionId: "stations",
    action: "edit",
  },
];
