const MODAL_TOP = 50;
const MODAL_LEFT = 50;

export const MODAL_STYLES = {
  top: `${MODAL_TOP}%`,
  left: `${MODAL_LEFT}%`,
  transform: `translate(-${MODAL_TOP}%, -${MODAL_LEFT}%)`,
  maxHeight: "80vh",
  overflowY: "auto",
};

export const DIRECTION_OPTIONS = [
  {
    id: 4,
    value: "4",
    label: "4",
  },
  {
    id: 8,
    value: "8",
    label: "8",
  },
  {
    id: 16,
    value: "16",
    label: "16",
  },
];

export const TIME_RANGE_TYPE = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  H: "H",
};

export const HISTORICAL_DATA = "HISTORICAL_DATA";

export const PREDICTION_DATA = "PREDICTION_DATA";

export const COMBINED_DATA = "COMBINED_DATA";

export const DEFAULT_TIME_RANGE = "1";

export const DEFAULT_HEADER_COLOR = "#1E1E2D";

export const DEFAULT_COLUMN_HEADER_COLOR = "#3599ff";

export const DEFAULT_HISTORY_ROW_COLOR = "#e1f0ff";

export const DEFAULT_PREDICTION_ROW_COLOR = "#c2e0e0";

export const DEFAULT_ALARM_COLOR = "#FCC400";

export const DEFAULT_MAIN_COLOR = "#009CE0";

export const DEFAULT_TEXT_COLOR = "#22194D";

export const DEFAULT_SCAN_IMAGE_TIME = 5;

export const DEFAULT_NUMBER_OF_DIRETIONS = "4";

export const DEFAULT_DATA_MODE = HISTORICAL_DATA;

export const DEFAULT_TIME_RANGE_TYPE = TIME_RANGE_TYPE.DAY;

export const DEFAULT_PREDICT_TIME_RANGE_TYPE = TIME_RANGE_TYPE.H;

export const DEFAULT_RANGE_TYPE = "STATES";

export const DEFAULT_GAUGE_CHART_TYPE = "ARC";

export const DEFAULT_SORT_BY = "name";

export const DEFAULT_ZOOM = 20;

export const DEFAULT_MAP_TYPE = 1;

export const DEFAULT_DISPLAY_COORDINATE = "none";

export const DEFAULT_TEXT_CONTENT_TYPE = "TEXT";

export const UPLOAD_TYPE = {
  STATIC_IMAGE: "STATIC_IMAGE",
  DYNAMIC_IMAGE: "DYNAMIC_IMAGE",
  RTSP: "RTSP",
};

export const IMAGE_TYPE = {
  JPEG: "JPEG",
  PNG: "PNG",
};

export const SCAN_TIMES = [
  {
    id: 1,
    value: 5,
    label: "5",
  },
  {
    id: 2,
    value: 10,
    label: "10",
  },
  {
    id: 3,
    value: 15,
    label: "15",
  },
];

export const IMAGE_FORMATS = [
  {
    id: 1,
    value: IMAGE_TYPE.JPEG,
    label: "JPEG (*.jpg; *.jpeg; *.jpe; *.jfif)",
  },
  {
    id: 2,
    value: IMAGE_TYPE.PNG,
    label: "PNG (*.png)",
  },
];

export const UPLOAD_TYPES = [
  {
    id: 1,
    value: UPLOAD_TYPE.STATIC_IMAGE,
    name_en: "Static Image",
    name_vi: "Ảnh Tĩnh",
  },
  // {
  //   id: 2,
  //   value: UPLOAD_TYPE.DYNAMIC_IMAGE,
  //   name_en: "Dynamic Image",
  //   name_vi: "Ảnh Động",
  // },
  {
    id: 2,
    value: UPLOAD_TYPE.RTSP,
    name_en: "RTSP",
    name_vi: "RTSP",
  },
];

export const GAUGE_CHART_TYPES = [
  {
    id: 1,
    value: "ARC",
    name_vi: "Nửa Hình Tròn",
    name_en: "Arc",
  },
  {
    id: 2,
    value: "CIRCULAR",
    name_vi: "Hình Tròn",
    name_en: "Circular",
  },
  {
    id: 3,
    value: "HORIZONTAL",
    name_vi: "Thanh Ngang",
    name_en: "Horizontal Bar",
  },
  {
    id: 4,
    value: "VERTICAL",
    name_vi: "Thanh Dọc",
    name_en: "Vertical Bar",
  },
];

export const RANGE_TYPES = [
  {
    id: 1,
    value: "STATES",
    name_vi: "Trạng Thái",
    name_en: "States",
  },
  {
    id: 2,
    value: "FIXED",
    name_vi: "Cố Định",
    name_en: "Fixed",
  },
];

export const COORDINATE_FORMATS = [
  {
    name_en: "None",
    name_vi: "Không Hiển Thị",
    token: "none",
  },
  {
    name_en: "Degrees Minutes Seconds (DMS)",
    name_vi: "Độ Phút Giây (DMS)",
    token: "FFf",
  },
  // {
  //     name: "Degrees Decimal Minutes (DDM)",
  //     token: "Ff",
  // },
  {
    name_en: "Decimal Degrees (DD)",
    name_vi: "Độ Thập Phân (DD)",
    token: "d",
  },
];

export const TEXT_CONTENT_TYPES = [
  {
    name_en: "Text",
    name_vi: "Văn Bản",
    value: "TEXT",
  },
  {
    name_en: "HTML",
    name_vi: "HTML",
    value: "HTML",
  },
];

export const VALUE_LIST_SORT_OPTIONS = [
  {
    id: 0,
    value: "name",
    name_en: "Name",
    name_vi: "Tên",
  },
  {
    id: 1,
    value: "status",
    name_en: "State",
    name_vi: "Trạng Thái",
  },
  {
    id: 2,
    value: "value",
    name_en: "Value",
    name_vi: "Giá Trị",
  },
  {
    id: 3,
    value: "time",
    name_en: "Time",
    name_vi: "Thời Gian",
  },
];

export const AGGREGATE_TABLE = {
  FUNCTIONS: [
    {
      id: 1,
      name_en: "Sum",
      name_vi: "Tổng",
      value: "SUM",
    },
    {
      id: 2,
      name_en: "Average",
      name_vi: "Trung Bình",
      value: "AVG"
    },
    {
      id: 3,
      name_en: "Min",
      name_vi: "Nhỏ Nhất",
      value: "MIN"
    },
    {
      id: 4,
      name_en: "Max",
      name_vi: "Lớn Nhất",
      value: "MAX"
    },
    {
      id: 5,
      name_en: "Count",
      name_vi: "Số Lượng",
      value: "COUNT"
    }
  ]
}

export const STATISTIC_TABLE = {
  FUNCTIONS: [
    {
      id: 0,
      name_en: "Raw",
      name_vi: "Dữ Liệu Thô",
      value: "RAW"
    },
    ...AGGREGATE_TABLE.FUNCTIONS,
  ],
  INTERVALS : [
    {
      id : 1,
      name_en : "30 minutes",
      name_vi : "30 phút",
      value : "MIN_30",
      time : 30,
      type : "minutes"
    },
    {
      id : 2,
      name_en : "8 hour",
      name_vi : "8 giờ",
      value : "HOUR_8",
      time : 8,
      type : "hours"
    }, 
    {
      id : 3,
      name_en : "1 day",
      name_vi : "1 ngày",
      value : "DAY_1",
      time : 1,
      type : "days"
    },
    {
      id : 4,
      name_en : "3 day",
      name_vi : "3 ngày",
      value : "DAY_3",
      time : 3,
      type : "days"
    },
    {
      id: 5,
      name_en: "1 week",
      name_vi: "1 tuần",
      value: "WEEK_1",
      time : 1,
      type : "weeks"
    }, 
    {
      id: 6,
      name_en: "2 week",
      name_vi: "2 tuần",
      value: "WEEK_2",
      time : 2,
      type : "weeks"
    }, 
    {
      id : 7,
      name_en : "1 month",
      name_vi : "1 tháng",
      value : "MONTH_1",
      time : 1,
      type : "months"
    }
  ]
};
