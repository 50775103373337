import swal from "sweetalert";

export const showRegisterSuccessModal = ({ intl, message, onAccept }) => {
  swal({
    title: intl.formatMessage({
      id: "CLIENT.REGISTRATION.REGISTRATION_COMPLETED_TITLE",
    }),
    text: message,
    icon: "success",
    button: intl.formatMessage({
      id: "CLIENT.GENERAL.OK_BUTTON",
    }),
  }).then(() => {
    onAccept();
  });
};

export const showRegisterFailedModal = ({ intl, message, onAccept }) => {
  swal({
    title: intl.formatMessage({
      id: "CLIENT.REGISTRATION.REGISTRATION_FAILED_TITLE",
    }),
    text: message,
    icon: "error",
    button: intl.formatMessage({
      id: "CLIENT.GENERAL.OK_BUTTON",
    }),
  }).then(() => {
    onAccept();
  });
};
