import AlarmIcon from "@material-ui/icons/Alarm";
import { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress } from "@material-ui/core";
import { invertColor } from "app/utils/utils";
import moment from "moment";
import ReactFitText from "react-fittext";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "app/constant/date-time-format";
import { useLanguage } from "app/shared/hooks";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";

const _periods = {
  "1-DAY": {
    id: 0,
    value: "1-DAY",
    name_en: "1 Day",
    name_vi: "1 Ngày",
  },
  "3-DAY": {
    id: 1,
    value: "1-DAY",
    name_en: "3 Days",
    name_vi: "3 Ngày",
  },
  "7-DAY": {
    id: 2,
    value: "7-DAY",
    name_en: "7 Days",
    name_vi: "7 Ngày",
  },
  "2-WEEK": {
    id: 3,
    value: "2-WEEK",
    name_en: "2 Weeks",
    name_vi: "2 Tuần",
  },
  "1-MONTH": {
    id: 4,
    value: "1-MONTH",
    name_en: "1 Month",
    name_vi: "1 Tháng",
  },
  "2-MONTH": {
    id: 5,
    value: "2-MONTH",
    name_en: "2 Months",
    name_vi: "2 Tháng",
  },
  "3-MONTH": {
    id: 6,
    value: "3-MONTH",
    name_en: "3 Months",
    name_vi: "3 Tháng",
  },
  "6-MONTH": {
    id: 7,
    value: "6-MONTH",
    name_en: "6 Months",
    name_vi: "6 Tháng",
  },
  "1-YEAR": {
    id: 8,
    value: "1-YEAR",
    name_en: "1 Year",
    name_vi: "1 Năm",
  },
};

export default function AlarmSummaryCard(props) {
  const ZERO_TIME_RANGE = "0";
  const RANGE_LAN = {
    FROM: {
      en: "From",
      vi: "Từ",
    },
    TO: {
      en: "To",
      vi: "Đến",
    },
  };
  const intl = useIntl();
  const [language] = useLanguage();
  const name =
    (props["data"] && props["data"][`name_${language}`]) ||
    intl.formatMessage({ id: "CLIENT.DASHBOARD.MODAL.NEW_ALARM_SUMMARY" });
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;
  const mainColor =
    (props["data"] && props["data"]["dashboard_alarm_summary"]["main_color"]) ||
    "#ffffff";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    count: 0,
    lastRecorded: null,
    period: "",
  });

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );
      const parameter = dashboardData?.parameterDatas?.at(0);

      const countAlarm = parameter?.dataPointDtos?.length || 0;

      // let period = "";
      // if (props.data.time_range && props.data.time_range_type) {
      //   period = _periods.find(
      //     (item) =>
      //       item.value ===
      //       `${props.data.time_range}-${props.data.time_range_type}`
      //   )?.[`name_${language}`];
      // }
      // if (props.data.historical_start_time && props.data.historical_end_time) {
      // }

      setData({
        count: countAlarm,
        lastRecorded: moment(dashboardData?.endTime).format(DATE_TIME_FORMAT),
        // period: period,
        parameterId:
          dashboardData?.parameterDatas?.[0]?.parameterDto?.parameterId,
      });
    };
    if (props.data && props.data.dashboard_alarm_summary) {
      initData();
    }
  }, [props["data"]]);

  useEffect(() => {
    if (
      props.data.historical_time_range !== ZERO_TIME_RANGE &&
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId
    ) {
      if (data?.parameterId === props.realTimeDataAlarm?.paramId) {
        setData((prevData) => ({
          ...prevData,
          count: prevData.count + 1,
          lastRecorded: moment(props.realTimeDataAlarm?.eventTime).format(
            DATE_TIME_FORMAT
          ),
        }));
      }
    }
  }, [
    props.realTimeDataAlarm?.eventTime,
    props.realTimeDataAlarm?.paramId,
    props.data.historical_time_range,
  ]);

  const renderTimeRange = useCallback(() => {
    if (props.data?.time_range !== "0" && props.data?.time_range_type)
      return (
        <ReactFitText minFontSize={15} compressor={2.5} maxFontSize={30}>
          <p
            className="value-card-status"
            style={{ color: invertColor(mainColor) }}
          >
            <FormattedMessage id="CLIENT.DASHBOARD.IN" />{" "}
            {
              _periods[
                `${props?.data?.time_range}-${props?.data?.time_range_type}`
              ]?.[`name_${language}`]
            }
          </p>
        </ReactFitText>
      );

    if (
      new Date(props.data.historical_start_time) !== "Invalid Date" &&
      new Date(props.data.historical_end_time) !== "Invalid Date"
    )
      return (
        <ReactFitText minFontSize={9} compressor={1.5} maxFontSize={30}>
          <p
            // className="value-card-status"
            style={{ color: invertColor(mainColor) }}
          >
            {`${RANGE_LAN.FROM[language]} ${moment(
              props.data.historical_start_time
            ).format(DATE_FORMAT)} ${RANGE_LAN.TO[language]} ${moment(
              props.data.historical_end_time
            ).format(DATE_FORMAT)}`}
          </p>
        </ReactFitText>
      );

    return null;
  }, [props.data, mainColor, language]);

  return (
    <div
      className="alarm-summary-card dashboard-item-card"
      style={{ background: mainColor, color: invertColor(mainColor) }}
    >
      {showHeader && (
        <Box
          className="alarm-summary-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <Box display="flex" alignItems="center">
            <AlarmIcon />
            &nbsp;&nbsp;{name}
          </Box>
        </Box>
      )}
      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div
          className="alarm-summary-card-body"
          style={{ color: invertColor(mainColor) }}
        >
          <div className="alarm-summary-card-content">
            {props["data"] &&
            props["data"]["dashboard_alarm_summary"]["parameter_id"] ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={2}>
                  <p
                    className="value-card-value"
                    style={{ color: invertColor(mainColor) }}
                  >
                    {data?.count}{" "}
                    <FormattedMessage id="CLIENT.DASHBOARD.ALARM" />
                    {language === "en" && data?.count > 1 ? "s" : ""}
                  </p>
                </Box>
                <Box mb={1}>
                  <ReactFitText
                    minFontSize={15}
                    compressor={2.5}
                    maxFontSize={30}
                  >
                    {renderTimeRange()}
                  </ReactFitText>
                </Box>
                {props.data?.time_range !== "0" && (
                  <ReactFitText
                    minFontSize={12}
                    compressor={2.5}
                    maxFontSize={18}
                  >
                    <p
                      className="mt-6 value-card-date"
                      style={{ color: invertColor(mainColor) }}
                    >
                      <FormattedMessage id="CLIENT.DASHBOARD.LAST_RECORDED_TIME" />
                      : {data?.lastRecorded}
                    </p>
                  </ReactFitText>
                )}
              </Box>
            ) : (
              <div className="no-data-area">
                <p
                  className="no-data-label"
                  style={{ color: invertColor(mainColor) }}
                >
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
}
