import "./index.css";
import MapIconActive from "assets/images/map-icon.svg";
import MapIconGuide from "assets/images/map-icon-guide.svg";
import TextLogo from "assets/images/text-logo.png";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Drawer, List } from "@material-ui/core";
import { SYSTEM_MANAGEMENT } from "./constants";
import { memo, useEffect, useState } from "react";
import useWindowSize from "app/shared/hooks/useWindowSizes";
import { useLocation } from "react-router-dom";
import workspaceApi from "app/api/workspaceApi";
import { connect, useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import OrganizationIcon from "assets/images/organization-icon.svg";
import ActiveOrganizationIcon from "assets/images/active-organization-icon.svg";
import UserIcon from "assets/images/user-icon.svg";
import ActiveUserIcon from "assets/images/active-user-icon.svg";
import LockIcon from "assets/images/lock-icon.svg";
import ActiveLockIcon from "assets/images/active-lock-icon.svg";
import WorkspaceIcon from "assets/images/workspace-icon.svg";
import ActiveWorkspaceIcon from "assets/images/active-workspace-icon.svg";
import StationTypesIcon from "assets/images/station-types-icon.svg";
import ActiveStationTypesIcon from "assets/images/active-station-types-icon.svg";
import TicketIcon from "assets/images/ticket.svg";
import ActiveTicket from "assets/images/ticket-active.svg";
import ActiveDataIcon from "assets/images/active-data-icon.svg";
import DataIcon from "assets/images/data-icon.svg";
import ActiveFileIcon from "assets/images/active-file-icon.svg";
import FileIcon from "assets/images/file-icon.svg";
import DataSharingIcon from "assets/images/data-sharing-icon.svg";
import ActiveDataSharingicon from "assets/images/active-data-sharing-icon.svg";
import SyncDataIcon from "assets/images/sync_data_icon.svg";
import SyncDataActiveIcon from "assets/images/active_sync_data_icon.svg";
import SyncDataRequestIcon from "assets/images/sync_data_request_icon.svg";
import ActiveSyncDataRequestIcon from "assets/images/active_sync_data_request_icon.svg";
import SyncDataResponseIcon from "assets/images/sync_data_response_icon.svg";
import ActiveSyncDataResponseIcon from "assets/images/active_sync_data_response_icon.svg";
import { injectIntl } from "react-intl";
import { useLanguage } from "app/shared/hooks";
import SidebarItem from "./SidebarItem";
import WorkspaceSidebarItem from "./WorkspaceSidebarItem";
import UserGuideIcon from "assets/images/user-guide-icon.svg";
import ActiveUserGuideIcon from "assets/images/active-user-guide-icon.svg";
import { isFeatureEnabled } from "app/utils/features";
import { FEATURE_ID } from "app/constant/features";
import { triggerSidebar } from "app/redux/actions/sidebar.action";

const Sidebar = (props) => {
  const { active, setActive, intl } = props;
  const [hoverActive, setHoverActive] = useState(false);
  const [workspaces, setWorkpaces] = useState([]);
  const location = useLocation();
  const size = useWindowSize();
  const [language] = useLanguage();
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const { token, reloadData, role, user } = useSelector(
    ({ user }) => ({
      token: user.token,
      role: user.role,
      user: user.user,
      reloadData: user.reloadData,
    }),
    shallowEqual,
  );
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual,
  );
  const { openSidebar } = useSelector((state) => state?.sidebar, shallowEqual);
  const mapboxInstance = useSelector(
    ({ maps }) => maps?.mapboxInstance,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const IS_OWNER_OR_ADMIN = role?.id === 1 || role?.id === 3;
  const roles =
    role.permission_sys &&
    role.permission_sys.length &&
    role.permission_sys.filter((item) => item.view).map((item) => item.code);
  const addNewRoles =
    role.permission_sys &&
    role.permission_sys.length &&
    role.permission_sys.filter((item) => item.new).map((item) => item.code);
  const editRoles =
    role.permission_sys &&
    role.permission_sys.length &&
    role.permission_sys.filter((item) => item.edit).map((item) => item.code);
  const deleteRoles =
    role.permission_sys &&
    role.permission_sys.length &&
    role.permission_sys.filter((item) => item.delete).map((item) => item.code);

  const fetchWorkSpaces = async (options) => {
    try {
      const params = {
        amount: 50,
        arrangement: "DESC",
        order: "createdAt",
      };
      const res = await workspaceApi.getAllworkspaces(params, token);
      const newData = [
        {
          id: 9,
          icon: MapIconGuide,
          activeIcon: MapIconActive,
          name: intl.formatMessage({ id: "CLIENT.GENERAL.MENU_MAPS" }),
          link: "/monitoring-map",
          code: "workspaces",
          key: "parent",
          enabled: true,
          subs: [],
          showsLeftArrow: false,
        },
        {
          id: 1,
          icon: OrganizationIcon,
          activeIcon: ActiveOrganizationIcon,
          name: intl.formatMessage({
            id: "CLIENT.ORGANIZATION_MANAGEMENT.PAGE_TITLE",
          }),
          link: "/organizations",
          subs: [],
          code: "organizations",
          key: "parent",
          enabled: true,
        },
        {
          id: 2,
          icon: UserIcon,
          activeIcon: ActiveUserIcon,
          name: intl.formatMessage({
            id: "CLIENT.ACCOUNT_MANAGEMENT.PAGE_TITLE",
          }),
          key: "parent",
          enabled: true,
          link: "",
          subs: [
            {
              id: 1,
              icon: UserIcon,
              activeIcon: ActiveUserIcon,
              name: intl.formatMessage({ id: "CLIENT.GENERAL.ACCOUNTS" }),
              link: "/account",
              key: "children",
              code: "account",
              subs: [],
            },
            {
              id: 2,
              icon: LockIcon,
              activeIcon: ActiveLockIcon,
              name: intl.formatMessage({
                id: "CLIENT.ROLE_MANAGEMENT.PAGE_TITLE",
              }),
              link: "/roles",
              key: "children",
              code: "roles",
              subs: [],
            },
          ].filter(
            (item) =>
              !item.code ||
              (roles && roles.length && roles.indexOf(item.code) !== -1),
          ),
        },
        {
          id: 3,
          icon: WorkspaceIcon,
          activeIcon: ActiveWorkspaceIcon,
          name: intl.formatMessage({ id: "CLIENT.GENERAL.WORKSPACES" }),
          link: "/workspaces",
          key: "parent",
          code: "workspaces",
          actionType: "workspaces",
          canAddNew: addNewRoles?.indexOf("workspaces") !== -1,
          enabled: true,
          subs: res.data
            .map((item) => ({
              id: item.id,
              icon: WorkspaceIcon,
              activeIcon: ActiveWorkspaceIcon,
              name: language === "en" ? item.name : item.name_vi,
              link: `/workspaces/edit/${item.id}?tab=0`,
              key: "children",
              code: "workspaces",
              actionType: "workspaces-children",
              canAddNew: addNewRoles?.indexOf("stations") !== -1,
              canEdit: editRoles?.indexOf("workspaces") !== -1,
              canDelete: deleteRoles?.indexOf("workspaces") !== -1,
              canAddNewDashBoard: addNewRoles?.indexOf("workspaces") !== -1,
              dashboards:
                item.workspace_dashboard &&
                item.workspace_dashboard &&
                item.workspace_dashboard.map((elm) => ({
                  id: elm.id,
                  name: elm[`name_${language}`],
                  iconType: "dashboard",
                  link: `/workspaces/${item.id}/dashboards/${elm.id}`,
                  key: "sub-sub-children",
                  actionType: "dashboard",
                  code: "workspaces",
                  workspaceId: item.id, // important,
                  renameNavId: options?.renameNavId,
                  isView:
                    elm?.workspace_dashboard_security
                      ?.workspace_dashboard_permission_id === 1,
                  subs: [],
                  canEdit: editRoles?.indexOf("workspaces") !== -1,
                  canDelete: deleteRoles?.indexOf("workspaces") !== -1,
                })),
              subs:
                item.station &&
                item.station.length &&
                item.station.map((elm) => ({
                  id: elm.id,
                  disableMoveDelete: elm.create_by !== user?.id,
                  name: language === "en" ? elm.name : elm.name_vi,
                  iconType: "station",
                  link: `/station/edit/${elm.id}?tab=0`,
                  actionType: "station",
                  key: "sub-sub-children",
                  code: "stations",
                  workspaceId: item.id, // important
                  subs: [],
                  canEdit: editRoles?.indexOf("stations") !== -1,
                  canDelete: deleteRoles?.indexOf("stations") !== -1,
                })),
              maps: [],
            }))
            .filter(
              (item) =>
                !item.code ||
                (roles && roles.length && roles.indexOf(item.code) !== -1),
            ),
        },
        {
          id: 5,
          icon: StationTypesIcon,
          activeIcon: ActiveStationTypesIcon,
          name: intl.formatMessage({
            id: "CLIENT.STATION_TYPES_MANAGEMENT.PAGE_TITLE",
          }),
          link: "/station-types",
          key: "parent",
          code: "station_types",
          activeLinks: [
            "/station-types",
            "/station-types/add",
            "/station-types/edit",
          ],
          subs: [],
          enabled: true,
        },
        {
          id: 4,
          icon: TicketIcon,
          activeIcon: ActiveTicket,
          name: intl.formatMessage({ id: "CLIENT.GENERAL.TICKETS" }),
          link: "/tickets",
          key: "parent",
          code: "tickets",
          subs: [],
          enabled: true,
        },
        {
          id: 6,
          icon: DataIcon,
          activeIcon: ActiveDataIcon,
          name: intl.formatMessage({ id: "CLIENT.GENERAL.DATA_SHARING" }),
          link: "",
          key: "parent",
          enabled: isFeatureEnabled(FEATURE_ID.DATA_SHARING),
          code: "",
          subs: [
            {
              id: 1,
              icon: FileIcon,
              activeIcon: ActiveFileIcon,
              name: intl.formatMessage({
                id: "CLIENT.DATA_FILES.PAGE_TITLE",
              }),
              link: "/data-files",
              key: "children",
              code: "data_files",
              subs: [],
            },
            {
              id: 2,
              icon: DataSharingIcon,
              activeIcon: ActiveDataSharingicon,
              name: intl.formatMessage({
                id: "CLIENT.DATA_SHARING_REQUEST.PAGE_TITLE",
              }),
              link: "/data-sharing-request",
              key: "children",
              code: "data_sharing_request",
              subs: [],
            },
          ].filter(
            (item) =>
              !item.code ||
              (roles && roles.length && roles.indexOf(item.code) !== -1),
          ),
        },
        {
          id: 7,
          icon: SyncDataIcon,
          activeIcon: SyncDataActiveIcon,
          name: intl.formatMessage({ id: "CLIENT.GENERAL.SYNC_DATA" }),
          link: "",
          key: "parent",
          code: "",
          enabled: isFeatureEnabled(FEATURE_ID.SYNC_DATA),
          subs: [
            {
              id: 1,
              icon: SyncDataRequestIcon,
              activeIcon: ActiveSyncDataRequestIcon,
              name: intl.formatMessage({
                id: "CLIENT.SYNC_DATA_REQUEST.PAGE_TITLE",
              }),
              link: "/sync-data-request",
              key: "children",
              code: "sync_data",
              subs: [],
            },
            {
              id: 2,
              icon: SyncDataResponseIcon,
              activeIcon: ActiveSyncDataResponseIcon,
              name: intl.formatMessage({
                id: "CLIENT.SYNC_DATA_RESPONSE.PAGE_TITLE",
              }),
              link: "/sync-data-response",
              key: "children",
              code: "sync_data_response",
              subs: [],
            },
          ].filter((item) => {
            if (item.code === "sync_data_response") {
              return IS_OWNER_OR_ADMIN;
            }
            return (
              !item.code ||
              (roles && roles.length && roles.indexOf(item.code) !== -1)
            );
          }),
        },
        {
          id: 8,
          icon: UserGuideIcon,
          activeIcon: ActiveUserGuideIcon,
          name: intl.formatMessage({ id: "MENU.USER_GUIDE" }),
          link: "#",
          key: "parent",
          code: "content",
          actionType: "content",
          subs: [],
          enabled: isFeatureEnabled(FEATURE_ID.USER_GUIDE),
        },
      ].filter(
        (item) =>
          (!item.code ||
            (roles && roles.length && roles.indexOf(item.code) !== -1)) &&
          !process.env.REACT_APP_HIDDEN_ITEM_ID?.split(",").includes(
            item.id.toString(),
          ) &&
          item.enabled,
      );

      setAllWorkspaces(
        res.data.map((item) => ({
          id: item.id,
          value: item.id,
          label: language === "en" ? item.name : item.name_vi,
        })),
      );
      setWorkpaces(newData);
    } catch (error) {
      const newData = [
        ...SYSTEM_MANAGEMENT,
        {
          id: 3,
          icon: WorkspaceIcon,
          activeIcon: ActiveWorkspaceIcon,
          name: "Workspace Management",
          key: "workspace-management",
          link: "",
          subs: [
            {
              id: 0,
              icon: WorkspaceIcon,
              activeIcon: ActiveWorkspaceIcon,
              name: "Workspace",
              link: "/workspaces",
              key: "workspace-management-children",
              code: "workspaces",
              subs: [],
            },
            {
              id: 1,
              icon: WorkspaceIcon,
              activeIcon: ActiveWorkspaceIcon,
              name: "Workspace Details",
              link: "",
              key: "workspace-management-children",
              code: "workspaces",
              subs: [],
            },
            {
              id: 3,
              icon: StationTypesIcon,
              activeIcon: ActiveStationTypesIcon,
              name: "Station Types",
              link: "/station-types",
              key: "workspace-management-children",
              code: "station_types",
              subs: [],
            },
          ],
        },
        {
          id: 4,
          icon: UserGuideIcon,
          activeIcon: ActiveUserGuideIcon,
          name: intl.formatMessage({ id: "MENU.USER_GUIDE" }),
          link: "#",
          key: "parent",
          code: "content",
          actionType: "content",
          enabled: isFeatureEnabled(FEATURE_ID.USER_GUIDE),
          subs: [],
        },
      ];
      setWorkpaces(newData);
    }
  };

  useEffect(() => {
    fetchWorkSpaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, dashboardState.name]);

  const handleMouseEnter = () => {
    if (!hoverActive && !active) {
      setHoverActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (hoverActive && !active) {
      setHoverActive(false);
    }
  };

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    dispatch(triggerSidebar());
  };

  const handleToggle = () => {
    setActive(!active);
    localStorage.setItem("active_nav", !active);

    let mapContentWidth = "calc(100vw - ";

    if (active) {
      document.getElementById("main-layout").style.paddingLeft = "70px";
      mapContentWidth += "30px)";
    } else {
      document.getElementById("main-layout").style.paddingLeft = "286px";
      mapContentWidth += "243px)";
    }

    const mapContent = document.getElementById("mapbox__content");
    if (mapContent) {
      mapContent.style.width = mapContentWidth;
      mapboxInstance?.resize();
    }
  };
  const renderNavs = (items, key = "") => {
    let currentPath = `/${location.pathname.split("/")[1]}`;

    if (currentPath?.startsWith("/v2")) {
      currentPath = `/v2/${location.pathname.split("/")[2]}`;
    }

    return items.map((item) => {
      return (item.code === "workspaces" ||
        item.code === "stations" ||
        item.code === "maps" ||
        item.code === "userGuides" ||
        item.code === "content") &&
        item.link ? (
        <div key={item.id}>
          <WorkspaceSidebarItem
            itemKey={item.key}
            id={item.id}
            link={item.link}
            currentPath={currentPath}
            renderKey={item.code === "content" ? item.code : key}
            activeIcon={item.activeIcon}
            icon={item.icon}
            name={item.name}
            subs={item.subs}
            dashboards={item.dashboards}
            workspaces={workspaces}
            renderNavs={renderNavs}
            iconType={item.iconType}
            actionType={item.actionType}
            reloadData={fetchWorkSpaces}
            token={token}
            workspaceId={item.workspaceId || item.id}
            active={active}
            isView={item.isView}
            canAddNew={item.canAddNew}
            renameNavId={item.renameNavId}
            renameMapId={item.renameMapId}
            maps={item.maps}
            sourceData={items}
            setWorkpaces={setWorkpaces}
            userGuides={item.userGuides}
            order={item?.order}
            categoryList={item?.categoryList}
            allWorkspaces={allWorkspaces}
            canEdit={item.canEdit}
            canDelete={item.canDelete}
            canAddNewDashBoard={item.canAddNewDashBoard}
            disableMoveDelete={item?.disableMoveDelete}
            showsLeftArrow={item?.showsLeftArrow}
          />
        </div>
      ) : (
        <div key={item.id}>
          <SidebarItem
            itemKey={item.key}
            id={item.id}
            link={item.link}
            currentPath={currentPath}
            renderKey={key}
            activeIcon={item.activeIcon}
            icon={item.icon}
            name={item.name}
            subs={item.subs}
            workspaces={workspaces}
            renderNavs={renderNavs}
            iconType={item.iconType}
            active={active}
            allWorkspaces={allWorkspaces}
          />
        </div>
      );
    });
  };

  return (
    <>
      <div className="sidebar desktop-sidebar">
        <div
          className={`wrapper ${
            !active && !hoverActive ? "inactive-wrapper" : ""
          }`}
        >
          <div className="inner">
            <div className="header">
              <div className="logo organization-name">
                {props.name ? (
                  language === "en" ? (
                    props.name
                  ) : (
                    props.nameVi
                  )
                ) : (
                  <img src={TextLogo} alt="logo" />
                )}
              </div>
              <div className="toggle" onClick={handleToggle}>
                <div className={`icon ${!active ? "inactive-icon" : ""}`}></div>
              </div>
            </div>
            <div
              className="content"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <p className="list-header">
                    {active || hoverActive ? (
                      intl.formatMessage({
                        id: "CLIENT.GENERAL.SYSTEM_MANAGEMENT",
                      })
                    ) : (
                      <MoreHorizIcon />
                    )}
                  </p>
                }
              >
                {renderNavs(workspaces)}
              </List>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        anchor="left"
        open={size.width >= 1000 ? false : openSidebar}
        onClose={toggleDrawer()}
      >
        <div className="sidebar mobile-sidebar">
          <div className="wrapper">
            <div className="inner">
              <div className="content">
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <p className="list-header">
                      {active || hoverActive ? (
                        intl.formatMessage({
                          id: "CLIENT.GENERAL.SYSTEM_MANAGEMENT",
                        })
                      ) : (
                        <MoreHorizIcon />
                      )}
                    </p>
                  }
                >
                  {renderNavs(workspaces)}
                </List>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
function mapStateToProps(state) {
  const { user } = state;
  return {
    name: (user.userOrganization && user.userOrganization.name) || null,
    nameVi: (user.userOrganization && user.userOrganization.nameVi) || null,
  };
}
export default memo(injectIntl(connect(mapStateToProps)(Sidebar)));
