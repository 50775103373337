import PropTypes from "prop-types";
import { memo } from "react";

import AlarmSummaryCard from "../../../../shared/components/Dashboard/AlarmSummaryCard";
import AttachmentImageCard from "../../../../shared/components/Dashboard/AttachmentImageCard";
import CustomChartCard from "../../../../shared/components/Dashboard/CustomChartCard";
import DirectionCard from "../../../../shared/components/Dashboard/DirectionCard";
import GaugeChartCard from "../../../../shared/components/Dashboard/GaugeChartCard";
import HistoricTableCard from "../../../../shared/components/Dashboard/HistoricTableCard";
import LocationMapCard from "../../../../shared/components/Dashboard/LocationMapCard";
import StandardChartCardForPrediction from "../../../../shared/components/Dashboard/StandardChardCardForPrediction";
import StandardChartCard from "app/shared/components/Dashboard/StandardChartCard_v2";
import StaticTextCard from "../../../../shared/components/Dashboard/StaticTextCard";
import ValueCard from "../../../../shared/components/Dashboard/ValueCard";
import ValueListCard from "../../../../shared/components/Dashboard/ValueListCard";
import AggregateTableCard from "app/shared/components/Dashboard/AggregateTableCard";

import { DASHBOARD_TYPE } from "../../../../shared/constants/dashboard";

const DashboardCard = (props) => {
  const {
    type,
    data,
    id,
    onEdit,
    onRemove,
    onEditDashboard,
    loading,
    isView,
    dragging,
    realTimeData,
    realTimeDataAlarm,
    isPublic,
    token,
    isRendering,
    gridResizeEvent,
    setGridResizeEvent,
  } = props;

  const handleEdit = (e) => {
    e.stopPropagation();

    if (onEdit) {
      onEdit(id, type);
    }
  };

  const handleRemove = (e) => {
    e.stopPropagation();

    if (onRemove) {
      onRemove(id);
    }
  };

  const getCart = () => {
    switch (type) {
      case DASHBOARD_TYPE.VALUE:
        return (
          <ValueCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            type={type}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.VALUE_LIST:
        return (
          <ValueListCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            type={type}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.STATIC_TEXT:
        return (
          <StaticTextCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            id={id}
            isView={isView}
            isPublic={isPublic}
            token={token}
          />
        );
      case DASHBOARD_TYPE.ATTACHMENT_IMAGE:
        return (
          <AttachmentImageCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            id={id}
            isView={isView}
            isPublic={isPublic}
            token={token}
          />
        );
      case DASHBOARD_TYPE.ALARM_SUMMARY:
        return (
          <AlarmSummaryCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.HISTORIC_TABLE:
        return (
          <HistoricTableCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE:
        return (
          <HistoricTableCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.DIRECTION:
        return (
          <DirectionCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.LOCATION_MAP:
        return (
          <LocationMapCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            dragging={dragging}
            realTimeData={realTimeData}
            isPublic={isPublic}
          />
        );

      case DASHBOARD_TYPE.STANDARD_CHART:
        return (
          <StandardChartCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            onEditDashboard={onEditDashboard}
            loading={loading}
            key={id}
            id={id}
            isView={isView}
            type={type}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
            isRendering={isRendering}
            gridResizeEvent={gridResizeEvent}
            setGridResizeEvent={setGridResizeEvent}
          />
        );

      case DASHBOARD_TYPE.PREDICTION_PARAMETER_STANDARD_CHART:
        return (
          <StandardChartCardForPrediction
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            onEditDashboard={onEditDashboard}
            loading={loading}
            key={id}
            id={id}
            isView={isView}
            type={type}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );

      case DASHBOARD_TYPE.GAUGE_CHART:
        return (
          <GaugeChartCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            isView={isView}
            realTimeData={realTimeData}
            realTimeDataAlarm={realTimeDataAlarm}
            isPublic={isPublic}
          />
        );
      case DASHBOARD_TYPE.CUSTOM_CHART:
        return (
          <CustomChartCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            id={id}
            type={type}
            isView={isView}
            onEditDashboard={onEditDashboard}
            realTimeData={realTimeData}
            isPublic={isPublic}
            realTimeDataAlarm={realTimeDataAlarm}
          />
        );
      case DASHBOARD_TYPE.AGGREGATE_TABLE:
        return (
          <AggregateTableCard
            data={data}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            loading={loading}
            key={id}
            id={id}
            type={type}
            isView={isView}
            isPublic={isPublic}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{getCart()}</>;
};

DashboardCard.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  isView: PropTypes.bool,
  dragging: PropTypes.bool,
  realTimeDataAlarm: PropTypes.object,
  realTimeData: PropTypes.object,
  token: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(DashboardCard);
