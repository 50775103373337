import { USER_LOG_OUT } from 'app/redux/actions/types';
import { store } from 'app/redux/store';
import axios from 'axios';
import queryString from 'query-string';

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // request config
  const token = store?.getState()?.user?.token;
  config.headers.Authorization = `Bearer ${token}`
  return config;
});

axiosClient.interceptors.response.use((response) => {
  // response config
  if (response && response.data) {
    return response.data;
  }

  return response;
}, (error) => {
  if ([401].includes(error?.response?.status) || ['jwt expired'].includes(error?.response?.data?.error)) {
    store.dispatch({ type: USER_LOG_OUT });
  }
  else {
    // Handle errors
    throw error;
  }
});

export default axiosClient;
