import React from "react";
import { Grid } from "@material-ui/core";
import FormHeader from "./FormHeader";
import FormLabel from "./FormLabel";
import AccountTypeSelect from "./AccountTypeSelect";
import OrganizationTypeSelect from "./OrganizationTypeSelect";
import OrganizationInput from "./OrganizationInput";
import ChannelSelect from "./ChannelSelect";
import EmailInput from "./EmailInput";
import PhoneInput from "./PhoneInput";
import PasswordInput from "./PasswordInput";
import ConfirmPasswordInput from "./ConfirmPasswordInput";
import FullNameInput from "./FullNameInput";
import GenderSelect from "./GenderSelect";
import BirthdayInput from "./BirthdayInput";
import AddressInput from "./AddressInput";
import TermsCheckBox from "./TermsCheckBox";
import RequiredFields from "./RequiredFields";
import FormButtons from "./FormButtons";

const MainForm = ({ intl, pageData }) => {
  const { formik, accountType, channel } = pageData;

  return (
    <form className="form register-form" onSubmit={formik.handleSubmit}>
      <Grid container className="container">
        <FormHeader messageId="CLIENT.SIGN_UP.ACCOUNT_TYPE" />
        <Grid item xs={12}>
          <FormLabel messageId="CLIENT.SIGN_UP.ACCOUNT_TYPE_MESSAGE" />
          <AccountTypeSelect pageData={pageData} />
          {accountType === "member" && (
            <>
              <OrganizationTypeSelect intl={intl} pageData={pageData} />
              <OrganizationInput intl={intl} pageData={pageData} />
            </>
          )}
        </Grid>

        <FormHeader messageId="CLIENT.SIGN_UP.ACCOUNT_INFORMATION" />

        <Grid item xs={12}>
          <FormLabel messageId="CLIENT.SIGN_UP.MESSAGE" />
          <ChannelSelect pageData={pageData} />
        </Grid>

        {channel === "email" && (
          <EmailInput intl={intl} pageData={pageData} xs={12} required={true} />
        )}

        {channel === "phone" && (
          <PhoneInput intl={intl} pageData={pageData} xs={12} required={true} />
        )}

        <PasswordInput intl={intl} pageData={pageData} />
        <ConfirmPasswordInput intl={intl} pageData={pageData} />

        <FormHeader messageId="CLIENT.SIGN_UP.CONTACT_INFORMATION" />

        <FullNameInput intl={intl} pageData={pageData} />
        <GenderSelect intl={intl} pageData={pageData} />
        <BirthdayInput intl={intl} pageData={pageData} />

        {channel === "email" && (
          <PhoneInput intl={intl} pageData={pageData} ml={2} required={false} />
        )}
        {channel === "phone" && (
          <EmailInput intl={intl} pageData={pageData} ml={2} required={false} />
        )}

        <AddressInput intl={intl} pageData={pageData} />
        <TermsCheckBox intl={intl} pageData={pageData} />
        <RequiredFields />
        <FormButtons pageData={pageData} />
      </Grid>
    </form>
  );
};

export default MainForm;
