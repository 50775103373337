import React from "react";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

export const ExpandButton = ({ expanded, onClick }) => {
  return expanded ? (
    <ArrowDropUp className="action-icon" onClick={onClick} />
  ) : (
    <ArrowDropDown className="action-icon" onClick={onClick} />
  );
};
