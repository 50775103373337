import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const FullNameInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={12} md={6}>
      <Box mb={3} mr={2}>
        <SharedInputField
          name="full_name"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.FULL_NAME_LABEL",
          })}
          type="text"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.FULL_NAME_HINT",
          })}
          required
          errorMsg={formik.touched["full_name"] && formik.errors["full_name"]}
          {...formik.getFieldProps("full_name")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default FullNameInput;
