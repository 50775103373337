import React from "react";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { isEmail } from "../../../../shared/utils/functions";
import SharedInputField from "../../../../shared/components/SharedInputField";
import { Link } from "react-router-dom";
import { getNotiMsg } from "../helpers";

const MainForm = ({ intl, pageData }) => {
  const { formik, isRequested, canResend, loading, counter } = pageData;

  return (
    <form className="form forgot-form" onSubmit={formik.handleSubmit}>
      <Grid container className="small-container">
        {getNotiMsg(isRequested, formik.values["email"])}

        <Grid item xs={12}>
          <Box mb={2}>
            <SharedInputField
              name="email"
              label={intl.formatMessage({
                id: "CLIENT.FORGOT.IDENTITY_LABEL",
              })}
              type="text"
              placeholder={intl.formatMessage({
                id: "CLIENT.FORGOT.IDENTITY_HINT",
              })}
              required
              errorMsg={formik.touched["email"] && formik.errors["email"]}
              {...formik.getFieldProps("email")}
              disabled={formik.isSubmitting}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            height="100%"
            className="note-msg"
            mb={3}
          >
            *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="forgot-form-buttons"
          >
            <Box mr={2}>
              {isRequested ? (
                <Button
                  type="submit"
                  className="custom-button form-button"
                  disabled={!canResend || formik.isSubmitting}
                >
                  {isEmail(formik.values.email) ? (
                    <FormattedMessage id="CLIENT.FORGOT.RESEND_EMAIL" />
                  ) : (
                    <FormattedMessage id="CLIENT.FORGOT.RESEND_CODE" />
                  )}
                  {!canResend && `(${counter}s)`}
                  {loading && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="custom-button form-button"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <FormattedMessage id="CLIENT.FORGOT.SUBMIT" />
                  {loading && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </Button>
              )}
            </Box>

            <Box ml={2}>
              <Link to="/auth/login">
                <Button className="custom-button light-button form-button">
                  <FormattedMessage id="CLIENT.FORGOT.CANCEL" />
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default MainForm;
