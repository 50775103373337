import { lazy } from "react";
import Loadable from "app/shared/components/Loader/Loadable";

export const connectionRoutes = [
  {
    path: "/station/edit/:id/connection/duplicate/:connectionId",
    component: Loadable(
      lazy(() => import("./pages/connection-duplicate.page"))
    ),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/connection/add",
    component: Loadable(lazy(() => import("./pages/connection-add.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/connection/edit/:connectionId",
    component: Loadable(lazy(() => import("./pages/connection-edit.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/connection/history/:connectionId",
    component: Loadable(lazy(() => import("./pages/connection-history.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/connection/view/:connectionId",
    component: Loadable(lazy(() => import("./pages/connection-view.page"))),
    permissionId: "stations",
    action: "view",
  },
];
