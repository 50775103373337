/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./styles.css";

import ModalContainer from "../../common/ModalContainer";
import { UPLOAD_TYPE } from "../contants";
import { prepareAttachmentImageOutput } from "../helpers";
import AttachmentImageModalSchema from "../schemas/AttachmentImageFormSchema";
import useModal from "../useModal";
import AttachmentImageForm from "./AttachmentImageForm";
import { ATTACHMENT_IMAGE_INITIALVALUES } from "../initialValues";

export default function MenuModal(props) {
  const { open, handleClose } = props;

  const intl = useIntl();

  const { language, openModal, setOpenModal } = useModal({
    modalOpen: open,
    handleClose: handleClose,
  });

  const [imageFile, setImageFile] = React.useState(null);
  const [image, setImage] = React.useState("");

  const toggleOpen = () => {
    props["handleClose"]();
  };

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || ATTACHMENT_IMAGE_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || ATTACHMENT_IMAGE_INITIALVALUES.name_vi,
      header_color:
        props.data?.header_color || ATTACHMENT_IMAGE_INITIALVALUES.header_color,
      show_header:
        props.data?.show_header === undefined
          ? ATTACHMENT_IMAGE_INITIALVALUES.show_header
          : props.data.show_header,
      upload_type:
        props.data?.dashboard_attachment_image?.upload_type ||
        ATTACHMENT_IMAGE_INITIALVALUES.upload_type,
      image_format:
        props.data?.dashboard_attachment_image?.image_format ||
        ATTACHMENT_IMAGE_INITIALVALUES.image_format,
      scan_time:
        parseInt(props.data?.dashboard_attachment_image?.scan_time) ||
        ATTACHMENT_IMAGE_INITIALVALUES.scan_time,
      link:
        props.data?.dashboard_attachment_image?.link ||
        ATTACHMENT_IMAGE_INITIALVALUES.link,
    },
    enableReinitialize: true,
    validationSchema: AttachmentImageModalSchema({ intl }),
    onSubmit: async (values, { setFieldError }) => {
      if (!image && values.upload_type === UPLOAD_TYPE.STATIC_IMAGE) {
        setFieldError(
          "image",
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          })
        );

        return;
      }
      const { id } = props;

      props["handleEdit"](
        id,
        prepareAttachmentImageOutput(values, imageFile, image)
      );
      props["handleClose"]();
    },
  });

  useEffect(() => {
    if (formik.values["upload_type"] === UPLOAD_TYPE.STATIC_IMAGE) {
      formik.setFieldError("link", "");
      formik.setFieldTouched("link", false);
    } else {
      formik.setFieldError("image", "");
    }
  }, [formik.values["upload_type"]]);

  useEffect(() => {
    return () => {
      formik.resetForm();
      if (
        props["data"] &&
        props["data"]["dashboard_attachment_image"] &&
        props["data"]["dashboard_attachment_image"]["url_image"]
      ) {
        setImage(
          `${process.env.REACT_APP_API_URL}/${props["data"]["dashboard_attachment_image"]["url_image"]}`
        );
      }
    };
  }, [open]);

  useEffect(() => {
    if (!formik.isValid && image !== "") {
      formik.setFieldTouched("image", false);
    }
  }, [formik?.isValid, image]);

  React.useEffect(() => {
    setOpenModal(props["open"]);

    if (
      props["data"] &&
      props["data"]["dashboard_attachment_image"] &&
      props["data"]["dashboard_attachment_image"]["url_image"]
    ) {
      setImage(
        `${process.env.REACT_APP_API_URL}/${props["data"]["dashboard_attachment_image"]["url_image"]}`
      );
    } else {
      setImage("");
    }
  }, [props["open"], props["data"]]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      disabledSave={formik.isSubmitting || !formik.isValid}
      toggleOpen={toggleOpen}
      newText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_ATTACHMENT_IMAGE" />
      }
      editText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_ATTACHMENT_IMAGE" />
      }
    >
      <AttachmentImageForm
        formik={formik}
        intl={intl}
        language={language}
        image={image}
        setImage={setImage}
        setImageFile={setImageFile}
        imageFile={props?.data?.imageFile}
      />
    </ModalContainer>
  );
}
