import React from "react";
import { injectIntl } from "react-intl";
import { useLanguage } from "../../shared/hooks";
import AuthLayout from "../../shared/layouts/AuthLayout";
import termsContent from "./termsContent";

function TermsConditions(props) {
  const { intl } = props;
  const [language] = useLanguage();

  return (
    <AuthLayout
      title={intl.formatMessage({ id: "CLIENT.TERMS_CONDITIONS.PAGE_TITLE" })}
      description={intl.formatMessage({
        id: "CLIENT.TERMS_CONDITIONS.PAGE_SUBTITLE",
      })}
    >
      <div className="form terms">
        <ul className="list">
          {termsContent.map((item) => (
            <li className="item" key={item.id}>
              <div className="title">
                <p className="number">{item.id}.</p>
                {item.title[language]}
              </div>
              <div className="terms-content">{item.content[language]}</div>
            </li>
          ))}
        </ul>
      </div>
    </AuthLayout>
  );
}

export default injectIntl(TermsConditions);
