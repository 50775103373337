import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 38 34"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M0 18.95C0 8.486 8.507 0 19 0s19 8.485 19 18.95c0 5.836-2.647 11.056-6.8 14.529a2.24 2.24 0 01-3.149-.276 2.226 2.226 0 01.277-3.141 14.454 14.454 0 005.086-9.272H27.94a1.953 1.953 0 01-1.956-1.95c0-1.078.876-1.952 1.956-1.952h5.442C32.468 10.48 27.348 5.422 20.9 4.582v4.893c0 1.078-.876 1.951-1.956 1.951a1.953 1.953 0 01-1.956-1.95v-4.88c-6.395.884-11.462 5.921-12.371 12.292H9.5c1.08 0 1.956.874 1.956 1.951 0 1.078-.876 1.951-1.956 1.951H4.586a14.454 14.454 0 005.086 9.272 2.226 2.226 0 01.277 3.14 2.24 2.24 0 01-3.15.277C2.648 30.006 0 24.786 0 18.95zm16.86-1.018a2.24 2.24 0 013.162 0l5.703 8.425c.185.273-.143.601-.416.417l-8.448-5.69a2.225 2.225 0 010-3.152z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
