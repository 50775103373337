import { Box, Button, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { FormattedMessage } from "react-intl";
import { MODAL_STYLES } from "../Modals/contants";
import "./ModalStyle.scss";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
  textField: {
    background: "white",
  },
}));

const ModalContainer = ({
  open,
  handleClose,
  editing,
  toggleOpen,
  newText,
  editText,
  children,
  formik,
  disabledSave,
}) => {
  const classes = useStyles();
  const renderHeader = () => {
    return (
      <div className="custom-chart-header">
        <div className="custom-chart-header-left">
          {editing ? editText : newText}
        </div>
        <div className="custom-chart-header-right">
          <span className="custom-chart-header-icon" onClick={toggleOpen}>
            <Close onClick={handleClose} />
          </span>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        mt={4}
      >
        <Box display="flex" alignItems="center" ml="auto">
          <Button
            className="custom-button"
            onClick={() => formik.handleSubmit()}
            disabled={disabledSave}
          >
            <FormattedMessage id="CLIENT.DASHBOARD.SAVE_ITEM" />
          </Button>
          <Box ml={3}>
            <Button
              className="custom-button light-button"
              type="button"
              onClick={toggleOpen}
            >
              <FormattedMessage id="CLIENT.DASHBOARD.CANCEL" />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={MODAL_STYLES}
        className={classes.paper + " card-modal-reponsive"}
      >
        {renderHeader()}
        {children}
        {renderFooter()}
      </div>
    </Modal>
  );
};

export default ModalContainer;
