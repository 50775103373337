/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Container, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { DataSharingAddInitialValues } from 'app/pages/DataSharingRequest/constants';
import { useDataSharing } from 'app/pages/DataSharingRequest/hooks';
import { AddDataSharingSchema } from 'app/pages/DataSharingRequest/Schemas';
import PageLoading from 'app/shared/components/Loader/PageLoading';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dataSharingApi from '../../../api/dataSharingApi';
import SharedInputField from '../../../shared/components/SharedInputField';
import "./index.css";
import IndexModal from "./IndexModal";
import IndexTable from './IndexTable';

const DataSharingAdd = (props) => {
  const { intl } = props;
  const {
    language,
    data,
    loading,
    activeItem,
    activeId,
    isEditing,
    showIndexModal,
    masterData,
    locationType,
    pageLoading,
    enablePageLoading,
    disablePageLoading,
    setLocationType,
    setMasterData,
    handleCreateItem,
    toggleEditItem,
    onDelete,
    toggleModal,
    handleEditItem,
    setIsEditing,
    onSubmitAdd

  } = useDataSharing({ intl, dataSharingApi });

  const { userId } = useSelector(
    ({ user }) => ({
      userId: user.user?.id
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchData = async () => {
      enablePageLoading();
      try {
        const [
          indexTypes,
          user,
          timeTypes,
          categories,
          methods,
          locationTypes
        ] = await Promise.all([
          dataSharingApi.getIndexTypes(),
          dataSharingApi.getUsers(),
          dataSharingApi.getTimeTypes(),
          dataSharingApi.getCategories(),
          dataSharingApi.getAllMethods(),
          dataSharingApi.getLocationTypes()
        ])
        if (indexTypes.success && user.success && timeTypes.success && categories.success && locationTypes.success) {
          setMasterData(prevData => ({
            ...prevData,
            indexTypes: indexTypes.data,
            user: user.data,
            timeTypes: timeTypes.data,
            categories: categories.data,
            methods: methods.data,
            locationTypes: locationTypes.data
          }))
        }
      } catch (error) {
      } finally {
        disablePageLoading()
      }
    }

    fetchData();
  }, [])

  const formik = useFormik({
    initialValues: {
      ...DataSharingAddInitialValues,
      user: userId
    },
    enableReinitialize: true,
    validationSchema: AddDataSharingSchema({ intl, locationType }),
    onSubmit: async (values) => {
      onSubmitAdd(values);
    },
  });

  const handleLocationTypeChange = (event) => {
    setLocationType(parseInt(event.target.value));
    if (event.target.value === 1) {
      formik.setErrors("address", "");
      formik.setFieldValue("address", "")
    } else {
      formik.setErrors({
        latitude: "",
        longtitude: ""
      });
      formik.setValues({
        ...formik.values,
        latitude: "",
        longtitude: ""
      })
    }
  };

  return (
    <Container className="enduser-page">
      <div className="main-content data-sharing enduser">
        <h2 className="main-title">
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.PAGE_TITLE_ADD" />
        </h2>
        <PageLoading loading={pageLoading}>
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <SharedInputField
                name="title"
                required
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.REQUEST_TITLE_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.REQUEST_TITLE_HINT' })}
                {...formik.getFieldProps("title")}
                disabled={formik.isSubmitting}
                errorMsg={formik.touched["title"] && formik.errors["title"]}
              />
            </Box>
            <h3 className="sub-title">
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDEX_DEVICE_TITLE" />
            </h3>
            <Box mb={3}>
              <Button className="custom-button" onClick={toggleModal}>
                <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_NEW_ITEM" />
              </Button>
            </Box>

            {Object.keys(data).length > 0 && (
              <IndexTable
                data={data}
                timeTypes={masterData.timeTypes}
                categories={masterData.categories}
                methods={masterData.methods}
                handleEdit={toggleEditItem}
                handleRemove={onDelete}
                showActions={true}
              />
            )}

            <h3 className="sub-title">
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.LOCATION_TITLE" />
            </h3>
            <Box mb={3}>
              <Box className="form-label">
                <p className="required">*</p>
                <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.LOCATION_TYPE_LABEL" />
              </Box>
              <RadioGroup
                className="radio-group"
                value={locationType}
                onChange={handleLocationTypeChange}
              >
                <Box display="flex">
                  {masterData.locationTypes.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      value={item.id}
                      disabled={formik.isSubmitting}
                      control={<Radio size="small" color="primary" />}
                      label={item[`name_${language}`]}
                    />
                  ))}
                </Box>
              </RadioGroup>
            </Box>
            {
              locationType === 1 ?
                <Box display="flex" justifyContent="space-between">
                  <Box mb={3} width="49%">
                    <SharedInputField
                      name="latitude"
                      label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LATITUDE_LABEL' })}
                      type="text"
                      placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LATITUDE_HINT' })}
                      {...formik.getFieldProps("latitude")}
                      disabled={formik.isSubmitting}
                      required
                      errorMsg={formik.touched["latitude"] && formik.errors["latitude"]}
                    />
                  </Box>

                  <Box mb={3} width="49%">
                    <SharedInputField
                      name="longtitude"
                      label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LONGTITUDE_LABEL' })}
                      type="text"
                      placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LONGTITUDE_HINT' })}
                      {...formik.getFieldProps("longtitude")}
                      disabled={formik.isSubmitting}
                      required
                      errorMsg={formik.touched["longtitude"] && formik.errors["longtitude"]}
                    />
                  </Box>
                </Box> :
                <Box mb={3}>
                  <SharedInputField
                    name="address"
                    required
                    label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADDRESS_LABEL' })}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADDRESS_HINT' })}
                    {...formik.getFieldProps("address")}
                    disabled={formik.isSubmitting}
                    errorMsg={formik.touched["address"] && formik.errors["address"]}
                  />
                </Box>
            }
            <h3 className="sub-title">
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.CONTACT_TITLE" />
            </h3>

            <Box mb={3}>
              <SharedInputField
                name="fullname"
                required
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FULL_NAME_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FULL_NAME_HINT' })}
                type="text"
                {...formik.getFieldProps("fullname")}
                disabled={formik.isSubmitting}
                errorMsg={formik.touched["fullname"] && formik.errors["fullname"]}
              />
            </Box>

            <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
              <Box width="49%"
              >
                <SharedInputField
                  name="email"
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.EMAIL_LABEL' })}
                  placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.EMAIL_HINT' })}
                  type="text"
                  {...formik.getFieldProps("email")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["email"] && formik.errors["email"]}
                />
              </Box>
              <Box width="49%"
              >
                <SharedInputField
                  name="phone"
                  required
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.PHONE_NUMBER_LABEL' })}
                  placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.PHONE_NUMBER_HINT' })}
                  type="text"
                  {...formik.getFieldProps("phone")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["phone"] && formik.errors["phone"]}
                />
              </Box>
            </Box>

            <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
              <Box width="49%"
              >
                <SharedInputField
                  name="organization_name"
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_LABEL' })}
                  placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_HINT' })}
                  type="text"
                  {...formik.getFieldProps("organization_name")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["organization_name"] && formik.errors["organization_name"]}
                />
              </Box>
              <Box width="49%"
              >
                <SharedInputField
                  name="organization_address"
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_ADDRESS_LABEL' })}
                  placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_ADDRESS_HINT' })}
                  type="text"
                  {...formik.getFieldProps("organization_address")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["organization_address"] && formik.errors["organization_address"]}
                />
              </Box>
            </Box>

            <IndexModal
              editing={isEditing}
              open={showIndexModal}
              handleClose={() => {
                toggleModal();
                setIsEditing(false);
              }}
              id={activeId}
              data={activeItem}
              handleCreate={handleCreateItem}
              handleEdit={handleEditItem}
              categories={masterData.categories}
              timeTypes={masterData.timeTypes}
              methods={masterData.methods}
              activeData={Object.values(data)}
            />

            <Box display="flex" alignItems="flex-end" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Button
                  className="custom-button"
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid
                  }
                >
                  <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
                  {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
                </Button>
                <Box ml={3}>
                  <Link to='/data-sharing-request'>
                    <Button
                      className="custom-button light-button"
                      type="button"
                    >
                      <FormattedMessage id="CLIENT.GENERAL.ACTION_CANCEL" />
                    </Button>
                  </Link>
                </Box>
              </Box>
              <Box className="note-msg">
                *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
              </Box>
            </Box>
          </form>
        </PageLoading>
      </div>
    </Container>
  );
}

export default injectIntl(DataSharingAdd);