import React from "react";
import { FormattedMessage } from "react-intl";
import { Box } from "@material-ui/core";

const FormLabel = ({ messageId }) => {
  return (
    <Box className="form-label">
      <p className="required">*</p>
      <FormattedMessage id={messageId} />
    </Box>
  );
};

export default FormLabel;
