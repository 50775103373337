import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedSelectField from "../../../../shared/components/SharedSelectField";
import { genders } from "app/pages/Accounts/constants";

const GenderSelect = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={12} md={6}>
      <Box mb={3} ml={2}>
        <SharedSelectField
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.GENDER_LABEL",
          })}
          disableClearable
          name="gender"
          options={genders.map((item) => ({
            id: item.value,
            value: item.value,
            label: intl.formatMessage({ id: item.label }),
          }))}
          defaultValue={formik.values["gender"]}
          onChange={formik.setFieldValue}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default GenderSelect;
