import { memo } from 'react';
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';

import SharedSelectField from '../../../SharedSelectField';

const UserSelection = (props) => {
  const { userList, handleInitChat, item } = props;
  const intl = useIntl();

  const handleChangeUser = (name, value) => {
    handleInitChat(
      item.id, 
      value, 
      userList.find(item => item.value === value)?.email, userList.find(item => item.value === value)?.avatar
    )
  }

  return (
    <div className='chat-window-info'>
      <SharedSelectField
        className="text-normal bg-white"
        label={intl.formatMessage({
          id: "CLIENT.CHAT.ACCOUNT_TO_CHAT"
        })}
        placeholder={intl.formatMessage({
          id: "CLIENT.CHAT.ACCOUNT_TO_CHAT_HINT"
        })}
        disableClearable
        name="user"
        options={userList}
        onChange={handleChangeUser}
      />
    </div>
  )
}


UserSelection.propTypes = {
  userList: PropTypes.array,
  item: PropTypes.object,
  handleInitChat: PropTypes.func
};

export default memo(UserSelection);