import { ADD_WAITING_MESSAGE, REMOVE_WAITING_MESSAGE } from "../actions/types";


const initialState = {
  // chatMessages: [],
  waitMessage: []
};


const chatMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WAITING_MESSAGE: {
      return{ ...state, waitMessage: state.waitMessage.concat(action.payload) };
    }
    case REMOVE_WAITING_MESSAGE: {
      const { messageId } = action.payload;
      return { ...state, waitMessage: state.waitMessage.filter(item => item?.id !== messageId) };
    }
    default:
      return state;
  };
}

export default chatMessageReducer;
