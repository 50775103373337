/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreateAccount from "./pages/Auth/CreateAccount";
import ForgotPassword from "./pages/Auth/ForgotPassword/index";
import Login from "./pages/Auth/Login/index";
import Registration from "./pages/Auth/Registration/index";
import ResetPassword from "./pages/Auth/ResetPassword/index";
import TermsConditions from "./pages/Auth/TermsConditions";
import Chat from "./shared/components/NotLoginEndUserChatBox/Chat";

const AuthPage = () => {
  return (
    <>
      <Chat />

      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/registration" component={Registration} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/reset-password" component={ResetPassword} />
        <Route path="/auth/create" component={CreateAccount} />
        <Route path="/terms-conditions" component={TermsConditions} />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </Switch>
    </>
  );
};

export default AuthPage;
