import paramtersApi from "app/api/parametersApi";
import { DATE_FORMAT } from "app/constant/date-time-format";
import { DASHBOARD_TYPE } from "app/shared/constants/dashboard";
import moment from "moment";
import { useMemo } from "react";
import {
  prepareHistoricTableOutput,
  preparePredictionLineChartOutput
} from "../../helpers";
import PredictionParameterHistoryTableSchema from "../../schemas/PredictionParameterHistoryTableFormSchema";
const { useFormik } = require("formik");
const {
  HISTORIC_TABLE_INITIALVALUES,
  STANDARD_CHART_INITIALVALUES,
} = require("../../initialValues");

export const useFormikInitialized = ({data, id, type, handleEdit, handleClose, intl, listParameterPrediction, listParameterDelete}) => {
    const formik = useFormik({
		initialValues: {
			name_en: data?.name_en || HISTORIC_TABLE_INITIALVALUES.name_en,
			name_vi: data?.name_vi || HISTORIC_TABLE_INITIALVALUES.name_vi,
			header_color:
				data?.header_color || HISTORIC_TABLE_INITIALVALUES.header_color,
			show_header:
				data?.show_header === undefined
					? HISTORIC_TABLE_INITIALVALUES.show_header
					: data.show_header,
			// display_mode:
			// 	props?.data?.dashboard_prediction_parameter_history_table?.display_mode ||
			// 	HISTORIC_TABLE_INITIALVALUES.display_mode,
			// display_mode: HISTORIC_TABLE_INITIALVALUES.display_mode,
			parameter_id:
				data?.dashboard_prediction_parameter_history_table?.dashboard_prediction_parameter_historic_table_parameter?.at(
					0
				)?.parameter_id ||
				data?.dashboard_prediction_parameter_standard_chart?.dashboard_prediction_parameter_standard_chart_parameter?.at(
					0
				)?.parameter_id ||
				undefined,
			station_id:
				data?.dashboard_prediction_parameter_history_table?.dashboard_prediction_parameter_historic_table_parameter?.at(
					0
				)?.station_id || 
				data?.dashboard_prediction_parameter_standard_chart?.dashboard_prediction_parameter_standard_chart_parameter?.at(
					0
				)?.station_id || 0,
			prediction_parameter_id:
				data?.dashboard_prediction_parameter_history_table?.dashboard_prediction_parameter_historic_table_parameter?.at(
					0
				)?.prediction_parameter_id || 0,
			column_header_color:
				data?.dashboard_prediction_parameter_history_table
					?.column_header_color ||
				HISTORIC_TABLE_INITIALVALUES.column_header_color,
			history_row_color:
				data?.dashboard_prediction_parameter_history_table
					?.history_row_color || HISTORIC_TABLE_INITIALVALUES.history_row_color,
			prediction_row_color:
				data?.dashboard_prediction_parameter_history_table
					?.prediction_row_color ||
				HISTORIC_TABLE_INITIALVALUES.prediction_row_color,
			historical_time_range:
				data?.historical_time_range ||
				HISTORIC_TABLE_INITIALVALUES.historical_time_range,
			historical_time_range_type:
				data?.historical_time_range_type ||
				HISTORIC_TABLE_INITIALVALUES.historical_time_range_type,
			prediction_time_range:
				data?.prediction_time_range ||
				HISTORIC_TABLE_INITIALVALUES.prediction_time_range,
			prediction_time_range_type:
				data?.prediction_time_range_type ||
				HISTORIC_TABLE_INITIALVALUES.prediction_time_range_type,
			historical_start_time: data?.historical_start_time
				? moment(data?.historical_start_time)
				: moment(moment().format(DATE_FORMAT), DATE_FORMAT),
			historical_end_time: data?.historical_end_time
				? moment(data?.historical_end_time)
				: moment(moment().format(DATE_FORMAT), DATE_FORMAT),
			zoom:
				data?.dashboard_prediction_parameter_standard_chart?.zoom !==
				undefined
					? data?.dashboard_prediction_parameter_standard_chart?.zoom
					: STANDARD_CHART_INITIALVALUES.zoom,
			legend:
				data?.dashboard_prediction_parameter_standard_chart?.legend !==
				undefined
					? data?.dashboard_prediction_parameter_standard_chart?.legend
					: STANDARD_CHART_INITIALVALUES.legend,
			range_selection:
				data?.dashboard_prediction_parameter_standard_chart
					?.range_selection !== undefined
					? data?.dashboard_prediction_parameter_standard_chart
							?.range_selection
					: STANDARD_CHART_INITIALVALUES.range_selection,
			full_screen:
				data?.dashboard_prediction_parameter_standard_chart
					?.full_screen !== undefined
					? data?.dashboard_prediction_parameter_standard_chart
							?.full_screen
					: STANDARD_CHART_INITIALVALUES.full_screen,
		},
		enableReinitialize: true,
		validationSchema: PredictionParameterHistoryTableSchema({ intl }),
		onSubmit: async (values) => {
			if (type === DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE) {
				handleEdit(
					id,
					prepareHistoricTableOutput(data, values)
				);
				handleClose();
			} else {
                values.list_prediction_parameter_ids = listParameterPrediction;
				handleEdit(
					id,
					preparePredictionLineChartOutput(data, values)
				);
                if (listParameterDelete && listParameterDelete.length > 0) {
                    paramtersApi.updateRemoveParameterPredictionInLineChart({listParameterDelete});
                }
				handleClose();
			}
		},
	});

  return useMemo(() => formik, [formik]);
};
