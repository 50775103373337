import React from "react";
import { FormattedMessage } from "react-intl";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { channelTypes } from "../constants";

const ChannelSelect = ({ pageData }) => {
  const { formik, channel, handleChannelChange } = pageData;

  return (
    <RadioGroup
      className="radio-group"
      value={channel}
      onChange={handleChannelChange}
    >
      {channelTypes.map((item) => (
        <FormControlLabel
          key={item.id}
          value={item.value}
          disabled={formik.isSubmitting}
          control={<Radio size="small" color="primary" />}
          label={<FormattedMessage id={item.subLabel} />}
        />
      ))}
    </RadioGroup>
  );
};

export default ChannelSelect;
