import swal from 'sweetalert';
import chatApi from '../../api/chatApi';
import {
  NOT_LOGIN_CHAT_END_USER_RESET_DATA,
  NOT_LOGIN_SET_END_USER_CHAT_ICONS,
  NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
  NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI,
  NOT_LOGIN_SET_END_USER_ROOMS_NOTI,
  NOT_LOGIN_SET_END_USER_ROOM_ID_LIST,
} from './types';
import { v4 as uuidv4 } from 'uuid';
import endUserChatApi from '../../api/endUserChatApi';

export const removeRoomInitNotification = (roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { user, notLoginEndUserChat } = getState();
    const { token } = user;
    const { roomsInitNoti } = notLoginEndUserChat;
    try {
      const resp = await chatApi.removeChatNoti({ roomChatMemberId }, token);
      if (resp.success) {
        const list = [...roomsInitNoti];
        const index = list.indexOf(roomChatMemberId);
        list.splice(index, 1);
        dispatch({
          type: NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti: list,
          },
        });
      }
    } catch (error) {}
  };
};

export const handleInitChatWindow = () => {
  return async (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { roomsInitNoti } = notLoginEndUserChat;
    const chatSession = JSON.parse(localStorage.getItem('reeco-chat-session'));
    // console.log(chatSession)
    if (!chatSession?.token) {
      const windowItem = {
        id: uuidv4(),
        status: 'contact',
      };
      dispatch({
        type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
        payload: {
          chatWindows: [windowItem],
        },
      });
    } else {
      const data = {
        token: chatSession?.token,
        page: 1,
        limit: 8,
      };
      const resp = await endUserChatApi.getEndUserMessageList(data);
      if (resp.success) {
        dispatch(
          removeRoomNotification(
            chatSession?.roomChatId,
            chatSession?.roomChatMemberId
          )
        );
        if (roomsInitNoti.includes(chatSession.roomChatMemberId)) {
          dispatch(removeRoomInitNotification(chatSession.roomChatMemberId));
        }
        const windowItem = {
          id: chatSession?.roomChatId,
          roomChatMemberId: chatSession?.roomChatMemberId,
          roomChatId: chatSession?.roomChatId,
          data: resp?.message?.reverse() || [],
        };
        dispatch({
          type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
          payload: {
            chatWindows: [windowItem],
          },
        });
      }
    }
    try {
    } catch (error) {}
  };
};
export const handleCreateChatBox = (data) => {
  return async (dispatch, getState) => {
    try {
      const windowItem = {
        id: uuidv4(),
        roomChatMemberId: data?.roomChatMemberId,
        roomChatId: data?.roomChatId,
        data: [],
      };
      dispatch({
        type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
        payload: {
          chatWindows: [windowItem],
        },
      });
    } catch (error) {}
  };
};
export const closeChatWindow = (id) => {
  return (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatWindows } = notLoginEndUserChat;
    const list = [...chatWindows];
    const index = list.findIndex((item) => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
        payload: {
          chatWindows: list,
        },
      });
    }
  };
};
export const closeChatIcon = (id) => {
  return (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatIcons } = notLoginEndUserChat;
    const list = [...chatIcons];
    const index = list.findIndex((item) => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: NOT_LOGIN_SET_END_USER_CHAT_ICONS,
        payload: {
          chatIcons: list,
        },
      });
    }
  };
};
export const handleHideChatWindow = (id, messageList) => {
  return (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatWindows, chatIcons } = notLoginEndUserChat;
    const item = chatWindows?.find((item) => item.id === id);
    const newChatIcons = [
      ...chatIcons,
      {
        ...item,
        data: messageList,
      },
    ];
    dispatch(closeChatWindow(id));
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: newChatIcons,
      },
    });
  };
};
export const fetchRoomIds = (token, userId) => {
  return async (dispatch) => {
    try {
      const data = { userId };
      const resp = await chatApi.getAllRoomIds(data, token);
      if (resp.success) {
        dispatch({
          type: NOT_LOGIN_SET_END_USER_ROOM_ID_LIST,
          payload: {
            roomIdList: resp.data.map((item) => item.roomChatId),
          },
        });
      }
    } catch (error) {}
  };
};
export const setInitRoomsNotification = (roomChatId, userId) => {
  return (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatWindows, roomsNoti } = notLoginEndUserChat;
    if (
      !chatWindows.length ||
      (chatWindows &&
        chatWindows?.length > 0 &&
        chatWindows.findIndex((item) => item.roomChatId === roomChatId) === -1)
    ) {
      dispatch({
        type: NOT_LOGIN_SET_END_USER_ROOMS_NOTI,
        payload: {
          roomsNoti: [
            ...roomsNoti,
            {
              roomChatId,
              userId,
            },
          ],
        },
      });
    }
  };
};
export const getAllNotification = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { token } = user;
      const resp = await chatApi.getAllNoti({ userId }, token);
      if (resp.success && resp.data?.length > 0) {
        const roomsInitNoti = resp.data
          ?.filter((item) => item.isSeen === 0)
          ?.map((item) => item.roomChatMemberId);
        dispatch({
          type: NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti,
          },
        });
      }
    } catch (error) {}
  };
};
export const setRoomNotification = (roomsNoti) => {
  return {
    type: NOT_LOGIN_SET_END_USER_ROOMS_NOTI,
    payload: {
      roomsNoti,
    },
  };
};
export const removeRoomNotification = (roomChatId, roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { notLoginEndUserChat, user } = getState();
    const { token } = user;
    const { roomsNoti } = notLoginEndUserChat;
    if (roomsNoti?.find((elm) => elm?.roomChatId === roomChatId)) {
      try {
        const resp = await chatApi.removeChatNoti(
          { roomChatMemberId: roomChatMemberId },
          token
        );
        if (resp.success) {
          const list = [...roomsNoti];
          const index = list.findIndex((elm) => elm.roomChatId === roomChatId);
          list.splice(index, 1);
          dispatch(setRoomNotification(list));
        }
      } catch (error) {
        swal({
          title: error,
          icon: 'error',
        });
      }
    }
  };
};
export const handleUnHideChatWindow = (
  id,
  messageList,
  roomChatMemberId,
  isMediate = false
) => {
  return async (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatIcons } = notLoginEndUserChat;
    const item = chatIcons.find((item) => item.id === id);
    // remove noti if have
    if (roomChatMemberId) {
      const roomChatId = chatIcons.find(
        (item) => item.roomChatMemberId === roomChatMemberId
      )?.roomChatId;
      dispatch(removeRoomNotification(roomChatId, roomChatMemberId));
    }
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: [
          {
            ...item,
            data: messageList,
          },
        ],
      },
    });
    dispatch(closeChatIcon(id));
  };
};
export const handleCloseAllChat = () => {
  return (dispatch) => {
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: [],
      },
    });
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: [],
      },
    });
  };
};
export const handleHideAllChat = () => {
  return (dispatch, getState) => {
    const { notLoginEndUserChat } = getState();
    const { chatWindows } = notLoginEndUserChat;
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: [],
      },
    });
    dispatch({
      type: NOT_LOGIN_SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: [...chatWindows],
      },
    });
  };
};
export const resetData = () => {
  return {
    type: NOT_LOGIN_CHAT_END_USER_RESET_DATA,
  };
};
