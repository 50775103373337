import { max } from "lodash";
import * as Yup from "yup";
import { HISTORICAL_DATA } from "../contants";

const StandardChartSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    name_vi: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    historical_start_time: Yup.date()
      .when('data_mode', {
        is: (option) => option === HISTORICAL_DATA,
        then: Yup.date()
          .when('historical_time_range', {
            is: value => value === '0',
            then: Yup.date()
              .typeError(
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE.INVALID",
                })
              )
              .max(Yup.ref('historical_end_time'), intl.formatMessage({
                id: "CLIENT.SYNC_DATA_REQUEST.FROM_TO_DATE.INVALID",
              }))
              .nullable()
              .required(
                intl.formatMessage({
                  id: "CLIENT.GENERAL.REQUIRED_FIELD",
                })
              )
          })
      }),
    historical_end_time: Yup.date()
      .when('data_mode', {
        is: (option) => option === HISTORICAL_DATA,
        then: Yup.date()
          .when('historical_time_range', {
            is: value => value === '0',
            then: Yup.date()
              .typeError(
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE.INVALID",
                })
              )
              .nullable()
              .required(
                intl.formatMessage({
                  id: "CLIENT.GENERAL.REQUIRED_FIELD",
                })
              )
          })
      }),
  });
  return schema;
}

export default StandardChartSchema