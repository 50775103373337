import { get } from 'lodash';
import axiosClient from './axiosClient';

const organizationApi = {
  getAllOrganizations(params) {
    const url = '/api/v1/organizations/';
    return axiosClient.get(url, { params });
  },
  getOrganization(id, token) {
    const url = `/api/v1/organizations/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  addOrganization(data, token) {
    const url = '/api/v1/organizations';
    return axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteOrganization(id, token) {
    const url = `/api/v1/organizations/${id}`;
    return axiosClient.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateOrganization(id, data, token) {
    const url = `/api/v1/organizations/${id}`;
    return axiosClient.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  generateUuid(token) {
    const url = '/api/v1/organizations/generate-id';
    return axiosClient.post(
      url,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  getOrganizationByIdentify(data) {
    const url = `/api/v1/organizations/identify`;
    return axiosClient.post(url, data);
  },
};

export default organizationApi;
