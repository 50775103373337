import { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classes from "./styles.module.css";
import Chip from "@material-ui/core/Chip";
import LockIcon from "@material-ui/icons/Lock";
import SettingsModal from "app/pages/Dashboard/components/Modals/SettingsModal";
import { useLanguage } from "app/shared/hooks";
import PublicIcon from "@material-ui/icons/Public";
import { FormattedMessage, useIntl } from "react-intl";
import RenameModal from "../Modals/RenameModal";
import { PAGE_MODE } from "../../constant";
import InputBase from "@material-ui/core/InputBase";
import EllipsisMenu from "./components/EllipsisMenu";
import { shallowEqual, useSelector } from "react-redux";
import SelectDropdownButton from "app/shared/components/SelectDropdownButton";
import useDetectDevice from "app/shared/hooks/useDetectDevice";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#3699FF",
    color: "white",
    border: "1px solid #ced4da",
    fontWeight: "500",
    fontSize: "14px !important",
    padding: "10px 16px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      backgroundColor: "#3699FF",
      color: "white",
      boxShadow: "0 0 0 0.1rem #3699FF",
    },
  },
}))(InputBase);
export const DashboardHeader = ({
  dashboard,
  onCreate = () => {},
  hasPermission = false,
  pageMode,
  setPageMode = () => {},
  dashboardView,
  screenMode,
  setScreenMode,
  onLayoutChange,
  disableSaveScreen,
  resetLayout,
  isRendering,
  deviceType,
}) => {
  const [language] = useLanguage();
  const [showsSettings, setShowsSettings] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [name, setName] = useState(dashboard?.[`name_${language}`]);
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual,
  );

  const toggleSettings = () => setShowsSettings((prev) => !prev);
  const toggleRename = () => setShowRename((prev) => !prev);
  useEffect(() => {
    if (dashboardState.id === dashboard.id && dashboardState.name !== "") {
      setName(dashboardState.name);
    }
  }, [dashboardState.name]);
  useEffect(() => {
    if (deviceType !== "desktop") {
      setPageMode(PAGE_MODE.VIEW);
    }
  }, [deviceType]);
  return (
    <Box
      className={classes.container}
      // style={{ top: deviceType === "desktop" ? 50 : 120 }}
    >
      <Box className={classes.header}>
        <Box className={classes.headerLeft}>
          <p className={classes.headerWorkspaceName}>
            {dashboard?.workspace?.[language === "en" ? "name" : "name_vi"]}
          </p>
          <Box className={classes.headerTitle}>
            <h2 className={classes.headerName}>{name}</h2>
          </Box>
        </Box>
        <Box className={classes.headerRightMenu}>
          <Box className={classes.headerRight}>
            {deviceType === "desktop" && (
              <SelectDropdownButton
                value={pageMode}
                onChange={(e) => setPageMode(e)}
                disabled={isRendering}
                option={[
                  {
                    key: PAGE_MODE.VIEW,
                    value: (
                      <FormattedMessage id="CLIENT.DASHBOARD.HEADER.VIEW_MODE" />
                    ),
                  },
                  {
                    key: PAGE_MODE.ARRANGE,
                    value: (
                      <FormattedMessage id="CLIENT.DASHBOARD.HEADER.ARRANGE_MODE" />
                    ),
                  },
                  {
                    key: PAGE_MODE.EDIT,
                    value: (
                      <FormattedMessage id="CLIENT.DASHBOARD.HEADER.EDIT_MODE" />
                    ),
                  },
                ]}
              />
            )}
          </Box>
          <EllipsisMenu
            toggleRename={toggleRename}
            toggleSettings={toggleSettings}
          />
        </Box>
      </Box>

      {pageMode === PAGE_MODE.ARRANGE && (
        <Box className={classes.buttonsContainer}>
          <Box className={classes.buttons}>
            <Box className={classes.buttonsLeft}>
              <SelectDropdownButton
                value={screenMode}
                onChange={(e) => setScreenMode(e)}
                disabled={isRendering}
                option={dashboardView?.map((e, index) => {
                  return {
                    key: e?.id,
                    value: <>{e?.[`name_${language}`]}</>,
                  };
                })}
              />
            </Box>

            <Box className={classes.buttonsRight}>
              <Button
                onClick={resetLayout}
                style={styles.button}
                disabled={isRendering || disableSaveScreen}
              >
                <FormattedMessage id="CLIENT.DASHBOARD.HEADER_DISCARD_CHANGER" />
              </Button>

              <Button
                onClick={onLayoutChange}
                style={styles.button}
                disabled={isRendering || disableSaveScreen}
              >
                <FormattedMessage id="CLIENT.DASHBOARD.HEADER_SAVE_CHANGER" />
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {pageMode === PAGE_MODE.EDIT && (
        <Box className={classes.buttonsContainer}>
          {hasPermission && (
            <Box className={classes.buttonsEdit}>
              <Button
                onClick={onCreate}
                startIcon={<AddIcon style={styles.buttonIcon} />}
                style={styles.button}
                // disabled={!isEditMode}
              >
                <FormattedMessage id="CLIENT.DASHBOARD.HEADER.NEW_ITEM" />
              </Button>
            </Box>
          )}
          {/* <Box className={classes.switchContainer}>
            <CustomizedTooltip
              className="dashboard-header-tooltip"
              title={intl.formatMessage({
                id: isEditMode
                  ? "CLIENT.DASHBOARD.HEADER.EDIT_MODE_TOOLTIP"
                  : "CLIENT.DASHBOARD.HEADER.VIEW_MODE_TOOLTIP",
              })}
            >
              <label className={classes.switchLabel}>
                <InfoOutlinedIcon className={classes.switchHelpIcon} />
                <span>
                  <FormattedMessage id="CLIENT.DASHBOARD.HEADER.EDIT_MODE" />
                </span>
              </label>
            </CustomizedTooltip>

            <Android12Switch
              checked={isEditMode}
              color="primary"
              onChange={(e) => setIsEditMode(e.target.checked)}
            />
          </Box> */}
        </Box>
      )}

      <RenameModal
        open={showRename}
        handleClose={() => setShowRename(false)}
        name={dashboard?.[`name_${language}`]}
        id={dashboard?.id}
      />
      <SettingsModal
        open={showsSettings}
        handleClose={() => setShowsSettings(false)}
        dashboardId={dashboard?.id}
      />
    </Box>
  );
};

const styles = {
  button: {
    padding: "10px 16px",
    textTransform: "none",
  },
  transform: {
    textTransform: "none",
  },
  buttonActive: {
    padding: "10px 16px",
    background: "#3699FF",
    color: "white",
    textTransform: "none",
  },
  primaryButton: {
    color: "white",
    background: "green",
    padding: "10px 16px",
    textTransform: "none",
  },
  buttonIcon: {
    color: "#3699FF",
  },
  activeButton: {
    background: "#3699FF",
    color: "white",
    textTransform: "none",
    minWidth: "120px",
  },
};
