import React from "react";
import { Box } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const OrganizationInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Box mb={3}>
      <SharedInputField
        name="organization"
        label={intl.formatMessage({
          id: "CLIENT.SIGN_UP.ORGANIZATION_LABEL",
        })}
        type="text"
        placeholder={intl.formatMessage({
          id: "CLIENT.SIGN_UP.ORGANIZATION_HINT",
        })}
        {...formik.getFieldProps("organization")}
        disabled={formik.isSubmitting}
      />
    </Box>
  );
};

export default OrganizationInput;
