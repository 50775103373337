import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M30.6 13.067C29.6 12.56 28.494 12 26.667 12s-2.933.56-3.933 1.067l-.107.052c-.819.404-1.514.748-2.627.748-1.2 0-1.866-.334-2.733-.8l-.104-.052C16.19 12.535 15.11 12 13.333 12c-1.828 0-2.919.553-3.913 1.057l-.02.01-.034.017c-.85.432-1.541.783-2.699.783v2.573c1.772 0 2.898-.556 3.887-1.044l.046-.023.034-.017c.851-.432 1.542-.783 2.7-.783 1.173 0 1.866.334 2.733.8l.02.01c.994.504 2.085 1.057 3.913 1.057 1.776 0 2.857-.534 3.83-1.015l.104-.052.106-.052c.82-.404 1.515-.748 2.627-.748 1.2 0 1.867.334 2.733.8 1 .507 2.107 1.067 3.934 1.067v-2.6c-1.2 0-1.867-.333-2.734-.773zM26.667 23.84c-1.772 0-2.898.555-3.887 1.044l-.046.023-.107.052c-.819.404-1.514.747-2.627.747-1.2 0-1.866-.333-2.733-.8l-.02-.01c-.994-.503-2.085-1.056-3.913-1.056-1.777 0-2.857.534-3.83 1.015l-.104.052-.13.063c-.807.4-1.489.736-2.603.736v2.6c1.772 0 2.898-.555 3.887-1.043l.046-.023c.867-.467 1.534-.8 2.734-.8s1.866.333 2.733.8l.02.01c.994.504 2.085 1.056 3.913 1.056 1.776 0 2.857-.534 3.83-1.015l.104-.051c.866-.467 1.533-.8 2.733-.8 1.2 0 1.867.333 2.733.8 1 .507 2.107 1.067 3.934 1.067v-2.6c-1.2 0-1.867-.334-2.734-.8-1-.507-2.133-1.067-3.933-1.067zm-3.887-4.89c.989-.488 2.115-1.043 3.887-1.043 1.8 0 2.933.56 3.933 1.066.867.467 1.534.8 2.734.8v2.6c-1.827 0-2.934-.56-3.934-1.066-.866-.467-1.533-.8-2.733-.8-1.112 0-1.808.343-2.627.747l-.106.053-.105.051c-.972.481-2.053 1.015-3.829 1.015-1.828 0-2.919-.553-3.914-1.057l-.019-.01c-.867-.466-1.573-.8-2.733-.8-1.113 0-1.808.344-2.627.748l-.107.053-.046.023c-.989.488-2.115 1.043-3.887 1.043v-2.6c1.2 0 1.867-.333 2.733-.8l.105-.051c.972-.481 2.052-1.015 3.829-1.015 1.828 0 2.919.552 3.914 1.057l.019.01c.867.466 1.533.8 2.733.8 1.2 0 1.867-.334 2.734-.8l.046-.024z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
