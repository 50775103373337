import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 43 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M8.063 5.583a18.904 18.904 0 018.958-2.25c9.89 0 17.916 7.467 17.916 16.667 0 9.2-8.026 16.667-17.916 16.667-3.261 0-6.325-.834-8.959-2.25 5.358-2.884 8.959-8.25 8.959-14.417 0-6.166-3.601-11.533-8.959-14.416zM31.354 20c0-7.35-6.432-13.333-14.333-13.333-.61 0-1.219.033-1.81.117 3.422 3.6 5.393 8.3 5.393 13.216 0 4.917-1.97 9.617-5.393 13.217.591.083 1.2.117 1.81.117 7.901 0 14.333-5.984 14.333-13.334z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
