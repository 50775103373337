import style0 from "../icon/line-dash-type/style0.svg";
import style1 from "../icon/line-dash-type/style1.svg";
import style2 from "../icon/line-dash-type/style2.svg";
import style3 from "../icon/line-dash-type/style3.svg";
import style4 from "../icon/line-dash-type/style4.svg";
import style5 from "../icon/line-dash-type/style5.svg";
const data = [
    {
        icon: style0,
        value: 0,
        name_en: "None",
        name_vi: "None"
    },
    {
        icon: style1,
        value: 1,
        name_en: "Dotted",
        name_vi: "Dotted",
    },
    {
        icon: style2,
        value: 2,
        name_en: "Dashed",
        name_vi: "Dashed"
    },
    {
        icon: style3,
        value: 3,
        name_en: "Dash Dot",
        name_vi: "Dash Dot"
    },
    {
        icon: style4,
        value: 4,
        name_en: "Long Dash",
        name_vi: "Long Dash"
    },
    {
        icon: style5,
        value: 5,
        name_en: "Long Dash Dot",
        name_vi: "Long Dash Dot"
    },
];
export default data;