import axiosClient from "./axiosClient";

const mapApi = {
  createMap(data) {
    const url = "/api/v1/map/";
    return axiosClient.post(url, data, {})
  },
  updateMap(id, data) {
    const url = `/api/v1/map/${id}`;
    return axiosClient.put(url, data, {})
  },
  getListMapByWorkspaceId(workspaceId) {
    const url = `/api/v1/map/workspace/${workspaceId}`;
    return axiosClient.get(url, {})
  },
  getMap(id) {
    const url = `/api/v1/map/${id}`;
    return axiosClient.get(url, {})
  },
  getDisplayStations(id) {
    const url = `/api/v1/map/display-station/${id}`;
    return axiosClient.get(url, {})
  },
  getPublicDisplayStation(id) {
    const url = `/api/v1/map/public/display-station/${id}`;
    return axiosClient.get(url)
  },
  updateDisplayStation(id, data) {
    const url = `/api/v1/map/display-station/${id}`;
    return axiosClient.put(url, data, {})
  },
  updateGeneral(id, data) {
    const url = `/api/v1/map/general/${id}`;
    return axiosClient.put(url, data, {})
  },
  deleteMap(id) {
    const url = `/api/v1/map/${id}`;
    return axiosClient.delete(url, {});
  },
  getAllSecurityByMap(mapId, params) {
    const url = `/api/v1/map-security/get-all-security/${mapId}`;
    return axiosClient.get(url, { params })
  },
  deleteMapSecurity(id) {
    const url = `/api/v1/map-security/${id}`;
    return axiosClient.delete(url, {});
  },
  createMapSecurity(mapId, data) {
    const url = `/api/v1/map-security/${mapId}`;
    return axiosClient.post(url, data, {})
  },
  getDisplayLayers(mapId) {
    const url = `/api/v1/map-layer/get-list-map-layer?map_id=${mapId}`;
    return axiosClient.get(url, {})
  },
  createMapLayers(data) {
    const url = `/api/v1/map-layer/create-map-layer`;
    return axiosClient.post(url,
      data, {})
  },
  updateMapLayers(data) {
    const url = `/api/v1/map-layer/update-map-layer`;
    return axiosClient.put(url,
      data, {})
  },
  uploadLoadFileLayer(data, token, onUploadProgress) {
    const url = "/api/v1/map-layer/upload-map-layer";
    return axiosClient.post(url,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onUploadProgress(percentCompleted)
        }
      })
  },
  getStationData(id) {
    const url = `/api/v1/map/data-app/${id}`;
    return axiosClient.get(url, {})
  }
}

export default mapApi;