import { DASHBOARD_TYPE } from "app/shared/constants/dashboard";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ModalContainer from "../../common/ModalContainer";
import useModal from "../useModal";
import HistoryTableForm from "./components/HistoryTableForm";
import LineChartForm from "./components/LineChartForm";
import { useFormikInitialized } from "./hooks/useFormikInitialize";

const PredictionParameterModal = (props) => {
  const { intl, open, handleClose, type } = props;
  const { openModal, setOpenModal, toggleOpen } = useModal({
    handleClose,
    modalOpen: open,
  });

  const parseListPredictionParameterIds = (rawList = []) => {
    const parseResult = [];
    if (rawList && rawList.length > 0) {
      rawList.forEach((rawItem) => {
        const parseObject = {
          id: rawItem.id,
          parameter_prediction_id: rawItem.prediction_parameter_id,
          temp_parameter_prediction_id: rawItem.prediction_parameter_id,
          color: rawItem.main_color,
          temp_color: rawItem.main_color,
          isEdit: false,
        };
        parseResult.push(parseObject);
      });
    }
    return parseResult;
  };
  const [listParameterPrediction, setListParameterPrediction] = useState([]);
  const [listParameterDelete, setListParameterDelete] = useState([]);

  const formik = useFormikInitialized({
    data: props.data,
    id: props.id,
    type: props.type,
    handleEdit: props.handleEdit,
    handleClose: props.handleClose,
    intl,
    listParameterPrediction,
    listParameterDelete,
  });

  useEffect(() => {
    // why i have to do that because formik so fucking stupid
    setListParameterPrediction(
      parseListPredictionParameterIds(
        props.data?.dashboard_prediction_parameter_standard_chart
          ?.dashboard_prediction_parameter_standard_chart_parameter
      )
    );
    setListParameterDelete([]);
  }, [
    props.data?.dashboard_prediction_parameter_standard_chart
      ?.dashboard_prediction_parameter_standard_chart_parameter,
    props["open"],
  ]);

  useEffect(() => {
    setOpenModal(props["open"]);

    return () => {
      formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["open"]]);

  const isListPredictionParameterValid = useMemo(() => {
    if (type === DASHBOARD_TYPE.PREDICTION_PARAMETER_STANDARD_CHART) {
      if (listParameterPrediction.length > 0) {
        for (const item of listParameterPrediction) {
          if (!item.parameter_prediction_id) {
            return false;
          }
        }
      } else {
        return false;
      }
    }
    return true;
  }, [listParameterPrediction, type])

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={handleClose}
      open={openModal}
      toggleOpen={toggleOpen}
      disabledSave={formik.isSubmitting || !formik.isValid || !isListPredictionParameterValid}
      newText={
        type === DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE ? (
          <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_PREDICTION_HISTORIC_TABLE" />
        ) : (
          <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_PREDICTION_STANDARD_CHART" />
        )
      }
      editText={
        type === DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE ? (
          <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_PREDICTION_HISTORIC_TABLE" />
        ) : (
          <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_PREDICTION_STANDARD_CHART" />
        )
      }
    >
      {type === DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE ? (
        <HistoryTableForm
          formik={formik}
          intl={intl}
          parameters={props?.parameters}
        />
      ) : (
        <LineChartForm
          formik={formik}
          intl={intl}
          parameters={props?.parameters}
          listParameterPrediction={listParameterPrediction}
          setListParameterPrediction={setListParameterPrediction}
          listParameterDelete={listParameterDelete}
          setListParameterDelete={setListParameterDelete}
        />
      )}
    </ModalContainer>
  );
};

const PredictionParameterModalFinal = injectIntl(PredictionParameterModal);

export default React.memo(PredictionParameterModalFinal);
