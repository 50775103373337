import { Button, Container } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Logo from "../../../../../assets/images/app-icon.png";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import { Link } from "react-router-dom";
import "./index.css";

const Header = (props) => {
  return (
    <div className="auth-header">
      <Container maxWidth="lg" className="container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div> 
        <div className="actions">
          <LanguageSwitcher />
          <div className="button-groups">
            <Link to="/auth/login">
              <Button className="custom-button header-button">
                <FormattedMessage id="CLIENT.GENERAL.LOGIN" />
              </Button>
            </Link>
            <Link to="/auth/registration">
              <Button className="custom-button light-button header-button">
                <FormattedMessage id="CLIENT.GENERAL.SIGN_UP" />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;


