import thumbnail1 from "assets/images/basemap-thumbnail/basemap1.jpg";
import thumbnail2 from "assets/images/basemap-thumbnail/basemap2-2.jpg";
import thumbnail3 from "assets/images/basemap-thumbnail/basemap3.jpg";
import thumbnail4 from "assets/images/basemap-thumbnail/basemap4.jpg";
// import thumbnail5 from "assets/images/basemap-thumbnail/basemap5.jpg";

export const BASE_MAPS = [
  {
    id: 1,
    name: "Style 1",
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    options: {
      attribution: "",
    },
    thumbnail:
      "https://designerfreelance.net/wp-content/uploads/2019/11/Sin-t%C3%ADtulo-1.jpg",
  },
  {
    id: 2,
    name: "Style 2",
    url: "https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
    options: {
      attribution: "",
    },
    thumbnail:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/3/3/6.png",
  },
  // {
  //     id: 3,
  //     name: "Style 3",
  //     url: "http://{s}.sm.mapstack.stamen.com/" +
  //         "(toner-lite,$fff[difference],$fff[@23],$fff[hsl-saturation@20])/" +
  //         "{z}/{x}/{y}.png",
  //     options: {
  //         attribution: "",
  //     },
  //     thumbnail: thumbnail3
  // },
  // {
  //     id: 4,
  //     name: "Style 4",
  //     url: "https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
  //     options: {
  //         attribution: "",
  //     },
  //     thumbnail: thumbnail4
  // },
];
