import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 43 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M21.473 31.666c3.015 0 5.47-2.283 5.47-5.088 0-3.542-4.76-8.484-4.964-8.694l-.506-.521-.506.521c-.205.21-4.964 5.152-4.964 8.694 0 2.805 2.455 5.088 5.47 5.088zM34.757 20.967c2.569-1.366 3.408-4.477 1.87-6.931-1.941-3.1-8.705-5.267-8.995-5.358l-.717-.227-.145.686c-.06.276-1.404 6.758.538 9.857 1.537 2.455 4.88 3.34 7.45 1.973zM8.243 20.967c2.57 1.366 5.912.481 7.45-1.973 1.942-3.1.597-9.581.537-9.858l-.145-.686-.717.227c-.29.09-7.053 2.258-8.995 5.358-1.538 2.454-.698 5.565 1.87 6.932z"
      ></path>
    </svg>
  );
}

export default Icon;
