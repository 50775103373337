const historicalTimeRange = [
  {
    id: 1,
    value: "1-DAY",
    name_en: "1 Day",
    name_vi: "1 Ngày",
  },
  {
    id: 2,
    value: "3-DAY",
    name_en: "3 Days",
    name_vi: "3 Ngày",
  },
  {
    id: 3,
    value: "7-DAY",
    name_en: "7 Days",
    name_vi: "7 Ngày",
  },
  {
    id: 4,
    value: "2-WEEK",
    name_en: "2 Weeks",
    name_vi: "2 Tuần",
  },
  {
    id: 5,
    value: "1-MONTH",
    name_en: "1 Month",
    name_vi: "1 Tháng",
  },
  {
    id: 6,
    value: "2-MONTH",
    name_en: "2 Months",
    name_vi: "2 Tháng",
  },
  {
    id: 7,
    value: "3-MONTH",
    name_en: "3 Months",
    name_vi: "3 Tháng",
  },
  {
    id: 8,
    value: "6-MONTH",
    name_en: "6 Months",
    name_vi: "6 Tháng",
  },
  {
    id: 9,
    value: "1-YEAR",
    name_en: "1 Year",
    name_vi: "1 Năm",
  },
  {
    id: 10,
    value: "0-DAY",
    name_en: "Customize (without realtime data)",
    name_vi: "Tuỳ Chỉnh (không có dữ liệu thời gian thực)",
  }
];

const predictionTimePoint = [
  {
    id: 1,
    value: "5-MINUTE",
    name_en: "5 min",
    name_vi: "5 phút",
  },
  {
    id: 2,
    value: "10-MINUTE",
    name_en: "10 min",
    name_vi: "10 phút",
  },
  {
    id: 3,
    value: "15-MINUTE",
    name_en: "15 min",
    name_vi: "15 phút",
  },
  {
    id: 4,
    value: "1-H",
    name_en: "1 hour",
    name_vi: "1 giờ",
  },
  {
    id: 5,
    value: "3-H",
    name_en: "3 hours",
    name_vi: "3 giờ",
  },
  {
    id: 6,
    value: "5-H",
    name_en: "5 hours",
    name_vi: "5 giờ",
  },
  {
    id: 7,
    value: "1-NEWEST",
    name_en: "Newest",
    name_vi: "Mới nhất",
  },
];

const predictionTimeRange = [
  {
    id: 1,
    value: "1-DAY",
    name_en: "1 Day",
    name_vi: "1 Ngày",
  },
  {
    id: 2,
    value: "3-DAY",
    name_en: "3 Days",
    name_vi: "3 Ngày",
  },
  {
    id: 3,
    value: "7-DAY",
    name_en: "7 Days",
    name_vi: "7 Ngày",
  },
  {
    id: 4,
    value: "2-WEEK",
    name_en: "2 Weeks",
    name_vi: "2 Tuần",
  },
  {
    id: 5,
    value: "1-MONTH",
    name_en: "1 Month",
    name_vi: "1 Tháng",
  },
  {
    id: 6,
    value: "2-MONTH",
    name_en: "2 Months",
    name_vi: "2 Tháng",
  },
  {
    id: 7,
    value: "3-MONTH",
    name_en: "3 Months",
    name_vi: "3 Tháng",
  },
  {
    id: 8,
    value: "6-MONTH",
    name_en: "6 Months",
    name_vi: "6 Tháng",
  },
  {
    id: 9,
    value: "1-YEAR",
    name_en: "1 Year",
    name_vi: "1 Năm",
  },
];

export { historicalTimeRange, predictionTimeRange, predictionTimePoint };
