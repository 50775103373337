import { useState } from "react";
import authApi from "../../../../api/authApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import {
  showResetPasswordFailedModal,
  showResetPasswordSuccessModal,
} from "../helpers/modals";

const initialValues = {
  username: "",
  password: "",
  confirmPassword: "",
  code: "",
};

export const useResetPassword = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const query = queryString.parse(props?.location?.search);
  const { username, code_confirm, code } = query;

  const ResetSchema = Yup.object().shape({
    username: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "CLIENT.GENERAL.INVALID_CONFIRM_PASSWORD",
          })
        ),
      }),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      code: code_confirm,
      username,
    },
    enableReinitialize: true,
    validationSchema: ResetSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        enableLoading();
        const data = {
          username: values.username,
          code: values.code,
          password: values.password,
          passwordConfirm: values.confirmPassword,
        };
        const res = await authApi.resetPassword(data);
        if (res.success) {
          const message = intl.formatMessage({
            id: "CLIENT.RESET.RESET_PASSWORD_SUCCESS",
          });

          showResetPasswordSuccessModal({
            intl,
            message,
            onAccept: () => {
              setStatus(message);
              setSubmitting(false);

              props.history.push("/auth/login");
            },
          });
        }
      } catch (error) {
        const message = intl.formatMessage({
          id: "CLIENT.RESET.RESET_PASSWORD_FAIL",
        });

        showResetPasswordFailedModal({
          intl,
          message,
          onAccept: () => setStatus(message),
        });
      } finally {
        disableLoading();
      }
    },
  });

  return {
    // Getters
    formik,
    loading,
    query,
    ResetSchema,
    username,
    code_confirm,
    code,

    // Setters
    setLoading,

    // Utils
    enableLoading,
    disableLoading,
  };
};
