import { DASHBOARD_KEYS } from "../constants/dashboard";

export const getResponseMessage = (code, type, fieldError = undefined) => {
  switch (code) {
    case 10001:
      return "CLIENT.GENERAL.TIME_OUT_ERROR_MESSAGE";
    case 10002:
      return "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE";
    case 10003:
      return "CLIENT.GENERAL.NULL_DATA_ERROR_MESSAGE";
    case 10004:
      return "CLIENT.GENERAL.INVALID_DATA_ERROR_MESSAGE";
    case 10005:
      return "CLIENT.GENERAL.NOT_FOUND_ERROR_MESSAGE";
    case 10006: {
      if (type === "organization") return "CLIENT.GENERAL.ORGANIZATION_EXIST";
      if (fieldError === "email")
        return "CLIENT.REGISTRATION.EXIST_EMAIL_ERROR_MESSAGE";
      else return "CLIENT.REGISTRATION.EXIST_PHONE_ERROR_MESSAGE";
    }
    case 10007:
      return "CLIENT.REGISTRATION.UNCONFIRMRED_ERROR_MESSAGE";
    case 10008:
      return "CLIENT.LOGIN.INCORRECT_ERROR_MESSAGE";
    case 10009:
      return "CLIENT.GENERAL.CAN_NOT";
    case 10010:
      return "CLIENT.LOGIN.ACCOUNT_INACTIVE";
    default:
      return "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE";
  }
};

export const isEmail = (str) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};
export const isLatitude = (lat) => {
  return isFinite(lat) && Math.abs(lat) <= 90;
};

export const isLongitude = (lng) => {
  return isFinite(lng) && Math.abs(lng) <= 180;
};

export const isIPAddress = (ipaddress) => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
};

const alarmValuesRangeOverlaps = (aStart, aEnd, bStart, bEnd) => {
  if (aStart <= bStart && bStart <= aEnd) return true; // b starts in a
  if (aStart <= bEnd && bEnd <= aEnd) return true; // b ends in a
  if (bStart < aStart && aEnd < bEnd) return true; // a in b
  return false;
};

export const multipleAlarmValuesRangeOverlaps = (values) => {
  let i, j;
  // if (values.length % 2 !== 0)
  //   throw new TypeError('Arguments length must be a multiple of 2');
  for (i = 0; i < values.length - 1; i += 1) {
    for (j = i + 1; j < values.length; j += 1) {
      if (
        alarmValuesRangeOverlaps(
          values[i]["compare_min_value"],
          values[i]["compare_max_value"],
          values[j]["compare_min_value"],
          values[j]["compare_max_value"]
        )
      )
        return true;
    }
  }
  return false;
};

export const hasDashboardPermission = (data) => {
  return data?.[DASHBOARD_KEYS.SECURITY]?.[DASHBOARD_KEYS.PERMISSION_ID] !== 1;
};

export const encodePageData = (data) => {
  return window.btoa(encodeURIComponent(JSON.stringify(data)));
};

export const decodePageData = (msg) => {
  return JSON.parse(decodeURIComponent(window.atob(msg)));
};

export const deleteSearchParams = () => {
  const url = new URL(window.location);
  url.searchParams.delete("pageData");
  url.searchParams.delete("ppi");
  url.searchParams.delete("ps");
  window.history.pushState(null, "", url.toString());
};
