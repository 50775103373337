import CircularProgress from "@material-ui/core/CircularProgress";
import { formatString } from "app/utils/string-formatter";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useLanguage } from "../../../hooks";
import "./styles.css";

// UTILS

Chart.register(annotationPlugin);
Chart.register(zoomPlugin);

const lineDash = {
  1: [1, 1],
  2: [10, 5],
  3: [10, 3, 3, 3],
  4: [20, 5],
  5: [20, 3, 3, 3],
};

const plugin = {
  id: "plugin",
  // afterDatasetDraw: function (chart, args) {
  //   const {ctx, data} = chart;
  //   console.log(chart.getActiveElements());
  //   chart.getActiveElements().forEach((active) => {
  //     const value = data.datasets[active.datasetIndex].data[active.index];
  //     console.log(value);
  //   });
  // },
};

const convertHexToRGBA = (hexCode, opacity) => {
  if (!hexCode) {
    return `rgba(0,0,0,${opacity || 1})`;
  }
  let hex = hexCode?.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

const lineStyles = {
  0: [0, 0],
  1: [0, 5],
  2: [5, 5],
  3: [0, 5, 5, 5],
  4: [20, 5],
  5: [0, 5, 20, 5],
};

const pointStyles = {
  0: "circle",
  1: "circle",
  2: "rectRot",
  3: "circle",
  4: "rectRot",
  5: "rect",
};

const markerPosition = {
  LEFT: "before",
  RIGHT: "after",
  CENTER: "middle",
};

const getBackgroundOpacity = (fillType, style, opacity) => {
  if (style === "COLUMN_SERIES" && fillType === "NONE") return 0;
  if (style === "COLUMN_SERIES" && fillType === "SOLID") return opacity / 100;
  if (fillType === "GRADIENT") return 0.2;
  return 1;
};

const isCustomLineColumn = (style, lineType) => {
  if (style === "COLUMN_SERIES" && ["1", "2", "3", "4", "5"].includes(lineType))
    return true;
  return false;
};

// END UTILS

const ColumnChartForPrediction = (props) => {
  const {
    chartRef,
    showLegend,
    dashboardParameters,
    timeRange,
    startTime,
    endTime,
    isLoading,
    // setStartTime,
    // setEndTime,
    // onZoomInSlider,
    // onZoomOutSlider,
    // masterTimeRange
    showGridlines = true,
  } = props;

  const [chartData, setChartData] = React.useState({
    datasets: [],
    labels: [],
  });
  const [language] = useLanguage();
  const annotations = [];
  // const [renderSlider, setRenderSlider] = useState(false);
  // const { width, ref: sliderContainerRef } = useResizeDetector();
  // const chartRangeState = useSelector((state) => state?.chartRange);
  // const dispatch = useDispatch();

  const getLabelRangeLimit = (label) => {
    if (!moment(label, "DD-MM-YYYY HH:mm").isValid()) {
      return "";
    }

    const formattedStartTime = moment(startTime).format("DD-MM-YYYY");
    const formattedEndTime = moment(endTime).format("DD-MM-YYYY");
    const formattedLabel = moment(label, "DD-MM-YYYY HH:mm");

    const hoursDiff = Math.abs(
      moment(formattedStartTime, "DD-MM-YYYY").diff(
        moment(formattedEndTime, "DD-MM-YYYY"),
        "hours"
      )
    );

    const diff = Math.abs(
      moment(formattedStartTime, "DD-MM-YYYY").diff(
        moment(formattedEndTime, "DD-MM-YYYY"),
        "days"
      )
    );

    const isSameYear = moment(formattedStartTime, "DD-MM-YYYY").isSame(
      moment(formattedEndTime, "DD-MM-YYYY"),
      "year"
    );

    if (hoursDiff <= 24) {
      return formattedLabel.format("DD-MM HH:mm");
    } else {
      if (diff < 10) {
        return formattedLabel.format("DD-MM HH:mm");
      } else {
        if (isSameYear) {
          return formattedLabel.format("DD-MM");
        } else {
          return formattedLabel.format("DD-MM-YYYY");
        }
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = {
    plugins: {
      legend: {
        display: showLegend,
        labels: {
          generateLabels: function (chart) {
            const datasets = chart.data.datasets;
            const legend = datasets?.map(function (dataset, index) {
              return {
                datasetIndex: index,
                fillStyle: dataset.backgroundColor,
                // lineDash: dataset.borderDash,
                lineDash: lineDash[dataset.lineStyle],
                lineWidth: dataset.isCustomLineColumn ? 1 : dataset.borderWidth,
                strokeStyle: dataset.borderColor,
                text: dataset.label,
                hidden: !chart.isDatasetVisible(index),
              };
            });
            return legend;
          },
        },
      },
      tooltip: {
        mode: "point",
        callbacks: {
          label: function (tooltipItem, data) {
            let param = dashboardParameters.find(
              (item) =>
                item?.name_en === tooltipItem?.dataset?.label ||
                item?.name_vi === tooltipItem?.dataset?.label
            );
            const value = isNaN(tooltipItem?.raw)
              ? tooltipItem?.raw?.y
              : tooltipItem?.raw;
            return formatString(param?.parameter?.parameter_format_text, value);
          },
        },
      },
      annotation: {
        annotations,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          mode: "xy",
        },
      },
    },

    hover: { mode: "point" },
    // scales: getScales(chartData.datasets),
    scales: {
      x: {
        grid: {
          display: showGridlines,
        },
        ticks: {
          autoSkip: false,
          // minRotation: 90,
          callback: function (val, index, ticks) {
            let points = [];
            let okPoint = 2 * Math.floor(ticks?.length / 2) + 1;

            for (let i = 0; i < 9; i++) {
              points.push(Math.ceil((okPoint / 9) * i));
            }

            if (index === 0 || index === ticks?.length - 1) {
              return getLabelRangeLimit(
                this.getLabelForValue(val),
                ticks?.length
              );
            } else {
              if (ticks?.length < 30) {
                return getLabelRangeLimit(
                  this.getLabelForValue(val),
                  ticks?.length
                );
              } else {
                if (points.indexOf(index) > -1) {
                  return getLabelRangeLimit(
                    this.getLabelForValue(val),
                    ticks?.length
                  );
                } else {
                  return "";
                }
              }
            }
          },
        },
      },
      y: {
        beginAtZero: false,
        grid: {
          display: showGridlines,
        },
      },
    },
  };

  const isFuture = (ctx, compareValue, value) => {
    if (
      moment(compareValue, "DD-MM-YYYY HH:mm").valueOf() > moment().valueOf()
    ) {
      return value;
    } else {
      return undefined;
    }
  };

  const generateDatasets = (data, labelsValue) => {
    return Object.values(data)?.map((item) => {
      const data = [];
      if (item?.values) {
        for (const [key, value] of Object.entries(item.values)) {
          data.push({
            x: key,
            y: value,
          });
        }
      }
      return {
        type: item["style"] === "LINE_SERIES" ? "line" : "bar",
        label:
          language === "en"
            ? item.parameter?.name || item.prediction_parameter?.name
            : item.parameter?.name_vi || item.prediction_parameter?.name_vi,
        // data: item?.values ? Object.values(item?.values) : [],
        data: data,
        backgroundColor: convertHexToRGBA(
          item["main_color"],
          getBackgroundOpacity(
            item["fill_type"],
            item["style"],
            Number(item["fill_opacity"])
          )
        ),
        borderColor: item["main_color"],
        tension: item["line_type"] === "SPLINE" ? 0.4 : 0,
        stepped:
          item["line_type"] === "STEP"
            ? markerPosition[item["marker_position"]]
            : false,
        fill: item["fill_type"] === "SOLID" || item["fill_type"] === "GRADIENT",
        borderWidth: isCustomLineColumn(item["style"], item["line_style"])
          ? 0
          : item["line_width"],
        borderCapStyle:
          item["line_style"] === "1" ||
          item["line_style"] === "3" ||
          item["line_style"] === "5"
            ? "round"
            : "butt",
        borderDash: lineStyles[parseInt(item["line_style"])],
        pointRadius:
          parseInt(item["marker_type"]) === 0
            ? 0
            : parseInt(item["marker_size"]),
        pointHoverRadius: 2,
        radius: 5,
        pointStyle: pointStyles[parseInt(item["marker_type"])],
        pointBorderColor: item["main_color"],
        pointBackgroundColor:
          parseInt(item["marker_type"]) === 3 ||
          parseInt(item["marker_type"]) === 4 ||
          parseInt(item["marker_type"]) === 5
            ? "rgba(0, 0, 0, 0)"
            : item["main_color"],
        grouped: false,
        // isCustomLineColumn: isCustomLineColumn(item["style"], item["line_style"]),
        realBorderWidth: item["line_width"],
        lineStyle: item["line_style"],
        columnPlacement: item["column_placement"],
        order: Number(item["index"]),
        segment: {
          borderDash:
            labelsValue.length > 0
              ? (ctx) => isFuture(ctx, labelsValue[ctx.p0.parsed.x], [6, 6])
              : undefined,
        },
      };
    });
  };

  const generateData = (data) => {
    const labelsValue = [
      ...new Set(
        Object.values(data)
          ?.map((item) => (item?.values ? Object.keys(item?.values) : []))
          .flat()
      ),
    ];
    const sortLabel = labelsValue.sort((a, b) => {
      if (
        moment(b, "DD-MM-YYYY HH:mm").valueOf() >
        moment(a, "DD-MM-YYYY HH:mm").valueOf()
      ) {
        return -1;
      } else {
        return 1;
      }
    });
    const resultValue = generateDatasets(data, labelsValue);
    return {
      labels: sortLabel,
      datasets: resultValue,
    };
  };

  useEffect(() => {
    const newChartData = generateData(props["data"]);

    if (
      newChartData?.datasets?.length > 0 &&
      newChartData?.labels?.length > 0
    ) {
      setChartData(newChartData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["data"]]);

  const dataChart = useMemo(
    () => (
      <Bar
        ref={(reference) => {
          if (chartRef) {
            chartRef.current = reference;
          }
        }}
        data={chartData}
        plugins={[plugin]}
        options={options}
        redraw // bỏ dòng này ra vẫn crash
      />
    ),
    [chartData, chartRef, options]
  );

  return (
    <div>
      <div className="chart-wrapper" style={{ position: "relative" }}>
        {dataChart}

        {isLoading && (
          <span style={styles.loader}>
            <CircularProgress />
          </span>
        )}
      </div>

      <p style={styles.timeRangeLabel}>
        {moment(new Date(timeRange.startTime)).format("DD-MM-YYYY, HH:mm")} -{" "}
        {moment(new Date(timeRange.endTime)).format("DD-MM-YYYY, HH:mm")}
      </p>
    </div>
  );
};

const styles = {
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    padding: "24px",
    borderRadius: "100%",
    border: "1px solid #eee",
  },
  zoomBar: {
    display: "flex",
    alignItems: "center",
  },
  zoomButton: {
    color: "white",
    background: "#3499fe",
    width: 30,
    height: 30,
    marginTop: 30,
    borderRadius: 3,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px 4px",
  },
  timeRangeLabel: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 24,
  },
  sliderLoaderContainer: {
    textAlign: "center",
    marginTop: 12,
  },
};

const ColumnChartForPredictionMemo = React.memo(ColumnChartForPrediction);
export default ColumnChartForPredictionMemo;
