import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M9.363 13.4c2.187-4 6.58-6.734 11.637-6.734 6.37 0 11.672 4.317 12.862 10.067C38.412 17.033 42 20.6 42 25c0 4.6-3.92 8.333-8.75 8.333H10.5c-5.793 0-10.5-4.483-10.5-10 0-5.15 4.095-9.4 9.363-9.933zm21.07 3.983C29.54 13.1 25.585 10 21 10c-3.605 0-6.86 1.9-8.523 4.95l-.875 1.583-1.872.183c-3.553.35-6.23 3.2-6.23 6.617 0 3.684 3.133 6.667 7 6.667h22.75c2.888 0 5.25-2.25 5.25-5 0-2.584-2.135-4.767-4.865-4.933l-2.677-.184-.525-2.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
