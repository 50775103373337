import React from "react";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import useDetectDevice from "app/shared/hooks/useDetectDevice";

export const ListPageButtons = ({
  showsAddNew = true,
  usePagePath = true,
  addPagePath = "/",
  onClear = () => {},
  onSearch = () => {},
  onAdd = () => {},
  addLabel = "New Item",
  clearLabel = "Clear",
  searchLabel = "Search",
  addIcon = <AddIcon />,
  clearIcon = <YoutubeSearchedForIcon />,
  searchIcon = <SearchIcon />,
  justifyContent = "space-between",
}) => {
  const { deviceType } = useDetectDevice();

  return (
    <>
      {deviceType !== "mobile" && (
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent={justifyContent}
        >
          {showsAddNew && (
            <Box>
              {usePagePath ? (
                <Link to={addPagePath}>
                  <Button className="custom-button light-button" type="button">
                    {addLabel}
                  </Button>
                </Link>
              ) : (
                <Button
                  className="custom-button light-button"
                  type="button"
                  onClick={onAdd}
                >
                  {addLabel}
                </Button>
              )}
            </Box>
          )}

          <Box display="flex" ml="auto">
            <Box mr={2}>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={onClear}
              >
                {clearLabel}
              </Button>
            </Box>

            <Button className="custom-button" type="button" onClick={onSearch}>
              {searchLabel}
            </Button>
          </Box>
        </Box>
      )}

      {deviceType === "mobile" && (
        <Box
          display="flex"
          justifyContent={justifyContent}
          alignItems="center"
          mb={2}
          style={{ marginTop: -14 }}
        >
          {showsAddNew && (
            <Box>
              {usePagePath ? (
                <Link to={addPagePath}>
                  <Button
                    className="custom-button light-button"
                    type="button"
                    style={{ width: 12 }}
                  >
                    {addIcon}
                  </Button>
                </Link>
              ) : (
                <Button
                  className="custom-button light-button"
                  type="button"
                  style={{ width: 12 }}
                  onClick={onAdd}
                >
                  {addIcon}
                </Button>
              )}
            </Box>
          )}

          <Box display="flex" ml="auto">
            <Button
              className="custom-button light-button"
              type="button"
              onClick={onClear}
              style={{ marginRight: 6, width: 12 }}
            >
              {clearIcon}
            </Button>

            <Button
              className="custom-button"
              type="button"
              onClick={onSearch}
              style={{ marginLeft: 6, width: 12 }}
            >
              {searchIcon}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
