import { FormattedMessage } from "react-intl";
import "../styles.css";
import { ReactECharts } from "app/shared/components/Dashboard/Echart/index";

export default function StandardChart({
  chartConfig = {},
  dashboardChartId,
  isRealTimeLoading = false,
  isChartLoading = false,
}) {
  return (
    <div class={`standard-chart-card-content chart-${dashboardChartId}`}>
      {chartConfig ? (
        <ReactECharts
          chartId={dashboardChartId}
          style={{ height: "100%", minHeight: "200px", minWidth: "200px" }}
          option={chartConfig}
          loading={isRealTimeLoading || isChartLoading}
          settings={{ notMerge: true }}
        />
      ) : (
        <div className="no-data-area">
          <p className="no-data-label">
            <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
          </p>
        </div>
      )}
    </div>
  );
}
