import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const EmailInput = ({ intl, pageData, xs = 6, ml = 0, required }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={xs}>
      <Box mb={3} ml={ml}>
        <SharedInputField
          name="email"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.EMAIL_LABEL",
          })}
          type="text"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.EMAIL_HINT",
          })}
          required={required}
          errorMsg={formik.touched["email"] && formik.errors["email"]}
          {...formik.getFieldProps("email")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default EmailInput;
