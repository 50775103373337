import React from 'react';
import {
    Radio,
    RadioGroup,
    IconButton,
    FormControlLabel,
    FormHelperText,
    Tooltip,
    Grid,
    Box,
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SharedSelectField from '../../../../shared/components/SharedSelectField';
import { useLanguage } from '../../../../shared/hooks';
import { useFormik } from "formik";
import * as Yup from "yup";
import paramtersApi from '../../../../api/parametersApi';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const initialValues = {
    id: "",
    station_id: "",
    location_type: "STATIC" | "DYNAMIC",
    latitude_parameter_id: "",
    longtitude_parameter_id: "",
    editing: true,
    name: "",
}

const StationItem = (props) => {
    const { item,
        onApply,
        onDelete,
        stations,
        listValues,
        intl,
        dataMode } = props;
    const [language] = useLanguage();
    const [parameters, setParameters] = React.useState([]);

    const onEdit = () => {
        onApply({
            ...item,
            editing: true,
        });
    };

    const handleChange = (name, value) => {
        formik.setFieldValue(name, value);
        props['onChange'](name, value);
    }

    const getStationParameters = async (stationId) => {
        try {
            const res = await paramtersApi.getAllParameters(stationId, {});
            if (res.success) {
                setParameters(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const schema = Yup.object().shape({
        station_id: Yup.number()
            .required(<FormattedMessage id='CLIENT.GENERAL.REQUIRED_FIELD' />),
        latitude_parameter_id: Yup.number()
            .nullable()
            .when("location_type", {
                is: (val) => val === "DYNAMIC",
                then: Yup.number()
                    .required(<FormattedMessage id='CLIENT.GENERAL.REQUIRED_FIELD' />)
            }),
        longtitude_parameter_id: Yup.number()
            .nullable()
            .when("location_type", {
                is: (val) => val === "DYNAMIC",
                then: Yup.number()
                    .required(<FormattedMessage id='CLIENT.GENERAL.REQUIRED_FIELD' />)
            }),
        location_type: Yup.string()
            .when("data_mode", {
                is: (val) => dataMode === 'PREDICTION_DATA',
                then: Yup.string()
                    .matches('DYNAMIC', intl.formatMessage({
                        id: "CLIENT.DASHBOARD.DATAMODE.PREDICTION.ERROR",
                    }))
            })
    });

    const formik = useFormik({
        initialValues: {
            ...initialValues,
            id: item?.id || initialValues.id,
            station_id: item?.station_id || initialValues.station_id,
            location_type: item?.location_type || (dataMode === 'PREDICTION_DATA' ? 'DYNAMIC' : 'STATIC'),
            latitude_parameter_id: item?.latitude_parameter_id || initialValues.latitude_parameter_id,
            longtitude_parameter_id: item?.longtitude_parameter_id || initialValues.longtitude_parameter_id,
            name: stations.find(station => station.id === item?.station_id)?.[`${language === 'en' ? 'name' : 'name_vi'}`]
                || initialValues.name,
            data_mode: dataMode
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            props['onApply']({
                ...values,
                editing: false
            });
        },
    });

    React.useEffect(() => {
        formik.values['station_id'] > 0 && getStationParameters(formik.values['station_id']);

        return () => {
            setParameters([])
        }
    }, [formik.values['station_id']]);

    React.useEffect(() => {
        formik.validateField("location_type");
        formik.setFieldTouched('location_type');
    }, [props['dataMode'], formik.values.location_type]);

    return (
        <>
            {
                !item.editing ? (
                    <Grid container className={`${formik.isValid ? '' : 'error-row'}`}>
                        <Grid item xs={5} className="grid-data-item">
                            {formik.values.name}
                        </Grid>
                        <Grid item xs={4} className="grid-data-item">
                            <Grid container>
                                {item.location_type === 'STATIC' ?
                                    <FormattedMessage id='CLIENT.DASHBOARD.STATIC' />
                                    : <FormattedMessage id='CLIENT.DASHBOARD.DYNAMIC' />}
                                {(!formik.isValid && formik.touched["location_type"]) &&
                                    <ErrorTip />
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className="grid-data-item action">
                            <EditIcon onClick={onEdit} />
                            <DeleteIcon onClick={onDelete} />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid xs={12}>
                        <Box mt={1} mb={1}>
                            <div className={`config-area ${item.editing ? 'edit' : ''}`}>
                                <Box mb={3}>
                                    <h2 className="form-label">
                                        {item.new === true ? <FormattedMessage id='CLIENT.DASHBOARD.ADD_NEW_STATION' />
                                            : <FormattedMessage id='CLIENT.DASHBOARD.EDIT_STATION' />}
                                    </h2>
                                    <SharedSelectField
                                        required
                                        className="bg-white"
                                        label={<FormattedMessage id='CLIENT.TICKETS_MANAGEMENT.STATION_LABEL' />}
                                        disableClearable
                                        name="station_id"
                                        options={[{
                                            id: "",
                                            name: "-- Select Station --",
                                            name_vi: "-- Chọn Trạm --",
                                        }].concat((stations || [])).map(item => ({
                                            id: item.id,
                                            value: item.id,
                                            label: language === 'en' ? item.name : item.name_vi,
                                            disabled: item.id === "" ||
                                                listValues.some(x => x.station_id === item.id)
                                        }))}
                                        defaultValue={formik.values["station_id"]}
                                        onChange={handleChange}
                                        errorMsg={formik.touched["station_id"] && formik.errors["station_id"]}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <Box className="form-label">
                                        <span className="required">*</span>
                                        <FormattedMessage id='CLIENT.DASHBOARD.LOCATION_TYPE' />
                                    </Box>
                                    <RadioGroup
                                        row
                                        className="radio-group"
                                        aria-label="location_type"
                                        name="location_type"
                                        value={formik.values['location_type']}
                                        onChange={(e) => {
                                            handleChange('location_type', e.target.value)
                                        }}
                                    >
                                        <FormControlLabel disabled={dataMode === 'PREDICTION_DATA'} value="STATIC" control={<Radio color="primary" />} label={<FormattedMessage id='CLIENT.DASHBOARD.STATIC' />} />
                                        <FormControlLabel value="DYNAMIC" control={<Radio color="primary" />} label={<FormattedMessage id='CLIENT.DASHBOARD.DYNAMIC' />} />
                                    </RadioGroup>
                                    {(!formik.isValid && formik.touched["location_type"]) && (
                                        <FormHelperText className="error-message">{formik.errors['location_type']}</FormHelperText>
                                    )}
                                </Box>
                                <>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        spacing={2}
                                        className="mt-2"
                                    >
                                        <Grid item xs={5}>
                                            <SharedSelectField
                                                required={formik.values.location_type !== 'STATIC'}
                                                className={`bg-white ${formik.values.location_type === 'STATIC' && "disabled-field"}`}
                                                label={`${intl.formatMessage({ id: 'CLIENT.DASHBOARD.LOCATION.LATITUDE_PARAMETER_LABEL' })}`}
                                                disableClearable
                                                name="latitude_parameter_id"
                                                options={[{
                                                    id: "",
                                                    name: "-- Select Parameter --",
                                                    name_vi: "-- Chọn Thông Số --"
                                                }].concat(parameters).map(item => ({
                                                    id: item.id,
                                                    value: item.id,
                                                    label: language === 'en' ? item.name : item.name_vi,
                                                    disabled: item.id === ""
                                                }))}
                                                defaultValue={formik.values["latitude_parameter_id"]}
                                                onChange={handleChange}
                                                disabled={formik.values.location_type === 'STATIC'}
                                                errorMsg={formik.touched["latitude_parameter_id"] && formik.errors["latitude_parameter_id"]}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <SharedSelectField
                                                required={formik.values.location_type !== 'STATIC'}
                                                className={`bg-white ${formik.values.location_type === 'STATIC' && "disabled-field"}`}
                                                label={`${intl.formatMessage({ id: 'CLIENT.DASHBOARD.LOCATION.LONGITUDE_PARAMETER_LABEL' })}`}
                                                disableClearable
                                                name="longtitude_parameter_id"
                                                options={[{
                                                    id: "",
                                                    name: "-- Select Parameter --",
                                                    name_vi: "-- Chọn Thông Số --"
                                                }].concat(parameters).map(item => ({
                                                    id: item.id,
                                                    value: item.id,
                                                    label: language === 'en' ? item.name : item.name_vi,
                                                    disabled: item.id === ""
                                                }))}
                                                defaultValue={formik.values["longtitude_parameter_id"]}
                                                onChange={handleChange}
                                                disabled={formik.values.location_type === 'STATIC'}
                                                errorMsg={formik.touched["longtitude_parameter_id"] && formik.errors["longtitude_parameter_id"]}
                                            />
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                                            <Box className="form-label d-block">
                                                <span>
                                                    {/* <FormattedMessage id='GENERAL.ACTIONS' /> */}
                                                </span>
                                            </Box>
                                            <Box mt={5}>
                                                <span
                                                    style={{
                                                        color: '#008a00',
                                                        cursor: 'pointer',
                                                        marginRight: 3,
                                                        marginLeft: 3
                                                    }}
                                                    disabled={
                                                        formik.isSubmitting ||
                                                        !formik.isValid
                                                    }
                                                    onClick={() => formik.handleSubmit()}
                                                >
                                                    <CheckIcon />
                                                </span>
                                                <span
                                                    style={{
                                                        color: '#db3700',
                                                        cursor: 'pointer',
                                                        marginRight: 3,
                                                        marginLeft: 3
                                                    }}
                                                    onClick={props['onCancel']}
                                                >
                                                    <CloseIcon />
                                                </span>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>

                            </div>
                        </Box>
                    </Grid>
                )
            }
        </>
    );
};

const ErrorTip = () => {
    return (
        <Tooltip
            placement="bottom"
            title={<Box style={{ fontSize: "14px", padding: "5px" }}>
                <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.PREDICTION.ERROR" />
            </Box>}
        >
            <IconButton
                size="small"
            >
                <InfoOutlinedIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}


export default injectIntl(StationItem);