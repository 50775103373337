import { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import FileSaver from "file-saver";
import _ from "lodash";
import DeleteIcon from '@material-ui/icons/Delete';
import FileDownloadOutlinedIcon from "@material-ui/icons/GetApp";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import DefaultUser from "../../../../../../assets/images/user.png";
import { DATE_TIME_FORMAT } from "../../../../../constant/date-time-format";
import { Typography } from "@material-ui/core";
import { useIndexedDB } from "react-indexed-db-hook";

const MessageList = (props) => {
  const {
    messageList,
    loading,
    hasMore,
    setPage,
    item,
    userAvatar,
    lastMessageRef,
    onResend,
    removeMessage,
  } = props;
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [currentMessages, setCurrentMessages] = useState([]);
  const observer = useRef(null);
  const { getByID } = useIndexedDB('files');
  const firstMsgRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !scrollToBottom && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, scrollToBottom, hasMore, setPage]
  );

  const [files, setFiles] = useState({});

  const getFileById = (id) => {
    return getByID(id);
  };

  useEffect(() => {
    if (
      Array.isArray(messageList) &&
      (currentMessages?.length === 0 ||
        _.last(messageList)?.id !== _.last(currentMessages)?.id)
    ) {
      setCurrentMessages(messageList);

      if (lastMessageRef?.current) {
        lastMessageRef?.current?.scrollIntoView();
        setScrollToBottom(false);
      }
    }
    const fetchFiles = async () => {
      const lst = []
      for (const message of messageList) {
        if (message.error && message.fileId) {
          const file = await getFileById(message.fileId);
          lst.push(file);
        }
      }
      setFiles(_.keyBy(lst, "id"));
    }
    fetchFiles();
  }, [currentMessages, lastMessageRef, messageList]);
  return (
    <div>
      {messageList?.map((message, index) => {
        return (
          < >
            <div
              ref={
                index === 0
                  ? firstMsgRef
                  : index === messageList.length - 1
                    ? lastMessageRef
                    : null
              }
              className={`chat-window-message chat-window-${message.roomChatMemberId === item.roomChatMemberId
                ? "outbox"
                : "inbox"
                }`}
              key={
                message?.["RoomChatAttachments.extensionAttachment"]
                  ? `${message.id}-${uuidv4()}`
                  : message.id
              }
            >
              <img
                src={
                  message.roomChatMemberId === item.roomChatMemberId
                    ? userAvatar
                      ? `${process.env.REACT_APP_API_URL}/${userAvatar}`
                      : DefaultUser
                    : item.avatar
                }
                className="chat-window-avatar"
              />

              {
                !message.error ? (
                  <div className="chat-window-content chat-window-inbox-content">
                    {!message?.["RoomChatAttachments.extensionAttachment"] ? (
                      <>
                        <p>{message.message}</p>
                      </>
                    ) : message?.["RoomChatAttachments.extensionAttachment"]?.split(
                      "/"
                    )?.[0] === "image" ? (
                      <div className="messsage-image">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`}
                        />
                        <div
                          className="remove-file"
                          onClick={() =>
                            FileSaver.saveAs(
                              `${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`,
                              message?.["RoomChatAttachments.originalNameAttachment"]
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon fontSize="small" />
                        </div>
                      </div>
                    ) : (
                      <div className="file-file">
                        <div className="file-icon">
                          <AttachFileIcon />
                        </div>
                        <div className="file-name">
                          <span>
                            {message?.["RoomChatAttachments.originalNameAttachment"]
                              ?.split(".")
                              ?.slice(0, -1)
                              ?.join()}
                          </span>
                          <span>
                            .
                            {message?.["RoomChatAttachments.originalNameAttachment"]
                              ?.split(".")
                              .pop()}
                          </span>
                        </div>
                        <div
                          className="remove-file"
                          onClick={() =>
                            FileSaver.saveAs(
                              `${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`,
                              message?.["RoomChatAttachments.originalNameAttachment"]
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon fontSize="small" />
                        </div>
                      </div>
                    )}
                    <p className="chat-window-time">
                      {moment(message?.createdAt).format(DATE_TIME_FORMAT)}
                    </p>
                  </div>
                ) : (
                  <div
                    className="chat-window-content chat-window-inbox-content"
                    style={{
                      border: "1px solid #F44336",
                    }}
                  >
                    {!(files[message.fileId]) ? (
                      <>
                        <p>{message.message}</p>
                      </>
                    ) : files[message.fileId]?.type === "image" ? (
                      <div className="messsage-image">
                        <img
                          src={files[message.fileId]?.url}
                        />
                        <div
                          className="remove-file"
                          onClick={() =>
                            FileSaver.saveAs(
                              files[message.fileId]?.url,
                              files[message.fileId]?.file?.name
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon fontSize="small" />
                        </div>
                      </div>
                    ) : (
                      <div className="file-file">
                        <div className="file-icon">
                          <AttachFileIcon />
                        </div>
                        <div className="file-name">
                          <span>
                            {files[message.fileId]?.file?.name
                              ?.split(".")
                              ?.slice(0, -1)
                              ?.join()}
                          </span>
                          <span>
                            .
                            {files[message.fileId]?.file?.name
                              ?.split(".")
                              .pop()}
                          </span>
                        </div>
                        <div
                          className="remove-file"
                          onClick={() =>
                            FileSaver.saveAs(
                              files[message.fileId]?.url
                              ,
                              files[message.fileId]?.file?.name
                            )
                          }
                        >
                          <FileDownloadOutlinedIcon fontSize="small" />
                        </div>
                      </div>
                    )}
                    <p className="chat-window-time">
                      {moment(message?.createdAt).format(DATE_TIME_FORMAT)}
                    </p>
                  </div>
                )
              }
              {
                message.error && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                    }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{
                        marginLeft: "6px",
                        marginRight: "6px",
                        cursor: "pointer",
                        color: "#9c9695",
                      }}
                      onClick={() => removeMessage(message.id)}
                    />
                  </div>
                )
              }

            </div>
            {message.error && (
              <div
                className={`chat-window-${message.roomChatMemberId === item.roomChatMemberId
                  ? "outbox"
                  : "inbox"
                  }`}
                style={{
                  marginBottom: "12px",
                }}
              >
                <Typography
                  // error
                  variant="body2"
                  color="error"
                  className="chat-message-error"
                  onClick={() => onResend(message.id)}
                >
                  Sending failed. Click to resend
                </Typography>
              </div>
            )}
          </>
        )
      })}
    </div>
  );
};

MessageList.propTypes = {
  messageList: PropTypes.array,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  setPage: PropTypes.func,
  item: PropTypes.object,
  userAvatar: PropTypes.string,
  lastMessageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default memo(MessageList);
