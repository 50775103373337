const data = [
  {
    id: 1,
    imageUrl: '/images/dashboard/value.jpg',
  },
  {
    id: 2,
    imageUrl: '/images/dashboard/value-list.jpg',
  },
  {
    id: 3,
    imageUrl: '/images/dashboard/alarm-summary.jpg',
  },
  {
    id: 4,
    imageUrl: '/images/dashboard/custom.png',
  },
  {
    id: 5,
    imageUrl: '/images/dashboard/direction.jpg',
  },
  {
    id: 6,
    imageUrl: '/images/dashboard/location-map.jpg',
  },
  {
    id: 7,
    imageUrl: '/images/dashboard/standard-chart.jpg',
  },
  {
    id: 8,
    imageUrl: '/images/dashboard/gauge-chart.jpg',
  },
];
export default data;