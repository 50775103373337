import {
  startsWithZero,
  endsWithZero,
  hasHash,
  hasDot,
  hasComma,
  replaceAll,
  commify,
  hasLiteralString,
  hasExponential,
  countLetters,
} from "./utils";

const LEADING_ZERO = "LEADING_ZERO";
const TRAILING_ZERO = "TRAILING_ZERO";
const DECIMAL_SEPARATOR = "DECIMAL_SEPARATOR";
const THOUSAND_SEPARATOR = "THOUSAND_SEPARATOR";
const EXPONENTIAL = "EXPONENTIAL";
const LITERAL_STRING = "LITERAL_STRING";

function getPatterns(pattern) {
  let patterns = [];

  if (hasHash(pattern)) {
    if (endsWithZero(pattern)) {
      if (!hasDot(pattern)) {
        patterns.push(TRAILING_ZERO);
      }
    }

    if (startsWithZero(pattern)) {
      patterns.push(LEADING_ZERO);
    }

    if (hasDot(pattern)) {
      patterns.push(DECIMAL_SEPARATOR);
    }

    if (hasComma(pattern)) {
      patterns.push(THOUSAND_SEPARATOR);
    }

    if (hasExponential(pattern)) {
      patterns.push(EXPONENTIAL);
    }

    if (
      hasLiteralString(pattern) ||
      pattern.endsWith(" %") ||
      pattern.endsWith(" ‰")
    ) {
      patterns.push(LITERAL_STRING);
    }
  }

  return patterns;
}

function processPattern(patternName, pattern, value) {
  switch (patternName) {
    case LEADING_ZERO: {
      const firstHashIndex = pattern.match("#").index;
      const leadingPart = pattern.substr(0, firstHashIndex);
      return `${leadingPart}${Number(value)}`;
    }

    case TRAILING_ZERO: {
      return `${Number(value)}`;
    }

    case DECIMAL_SEPARATOR: {
      let newPattern = pattern;
      if (endsWithZero(newPattern)) {
        const lastHashIndex = newPattern.lastIndexOf("#");
        newPattern = newPattern.substr(0, lastHashIndex + 1);
      }

      if (
        hasLiteralString(pattern) ||
        pattern.endsWith(" %") ||
        pattern.endsWith(" ‰")
      ) {
        if (pattern.endsWith(" %") || pattern.endsWith(" ‰")) {
          return Number(value).toFixed(1);
        } else {
          const parts = newPattern.split(" ")?.[0]?.split(".");
          const afterDotCount = parts[1]?.length;

          return Number(value).toFixed(afterDotCount);
        }
      } else {
        if (pattern.endsWith(" %") || pattern.endsWith(" ‰")) {
          return Number(value).toFixed(1);
        } else {
          let afterDotCount = 0;
          const parts = newPattern?.split(".");

          if (parts[1]?.startsWith("#")) {
            // eslint-disable-next-line no-unused-vars
            afterDotCount = countLetters(parts[1], "#");
          }

          return Number(value).toFixed(afterDotCount);
        }
      }
    }

    case THOUSAND_SEPARATOR: {
      let finalValue = value?.toString()?.replace(/^0+/, "");

      return commify(finalValue);
    }

    case EXPONENTIAL: {
      const digits = pattern.split(".")?.[1]?.split("e")?.[0]?.length;
      return Number(value).toExponential(digits);
    }

    case LITERAL_STRING: {
      const parts = replaceAll(pattern, '"', "")?.split(" ");
      const leadingPatterns = getPatterns(parts[0]);
      let output = value;

      leadingPatterns.forEach((n) => {
        output = processPattern(n, parts[0], output);
      });

      return `${output} ${parts[1]}`;
    }

    default: {
      return value;
    }
  }
}

export function formatString(pattern, value) {
  const patterns = getPatterns(pattern);
  let output = value;

  patterns.forEach((patternName) => {
    output = processPattern(patternName, pattern, output);
  });

  return output;
}

export function getFormattedExample(pattern) {
  const digits = "1068.62401";
  return formatString(pattern, Number(digits));
}
