export const DBConfig = {
  name: `vnemisoft-${process.env.REACT_APP_DEPLOY_MODE}`,
  version: 1,
  objectStoresMeta: [
    {
      store: "files",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "file", keypath: "file" },
      ],
    },
  ],
};