import { TAB_DRAWER } from "app/pages/Maps/constants/v2";
import {
  MAP_DETAIL_STATION,
  MAP_ELLIPSIS,
  TOGGLE_DRAWER_TAB,
  SET_MAP_INSTANCE,
  CLEAR_MAP_STATE,
} from "../actions/types";

let initialState = {
  open: false,
  x: 0,
  y: 0,
  isPublic: false,
  detailStation: {
    open: false,
    id: "",
    parameterTitle: "",
    formatText: "#.##",
    unit: "",
    indexInCard: 0,
  },
  activeTab: TAB_DRAWER.NONE,
  mapboxInstance: null,
};
const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP_ELLIPSIS: {
      const { open, x, y, isPublic, url, name, dashboardId, workspaceId } =
        action.payload;
      return {
        ...state,
        open,
        x,
        y,
        isPublic,
        url,
        name,
        dashboardId,
        workspaceId,
      };
    }
    case MAP_DETAIL_STATION: {
      const { detailStation } = action.payload;
      return { ...state, detailStation };
    }

    case TOGGLE_DRAWER_TAB: {
      let toggleTabValue = action.payload;
      if (
        toggleTabValue === state.activeTab ||
        toggleTabValue === TAB_DRAWER.NONE
      ) {
        toggleTabValue = TAB_DRAWER.NONE;
      }

      return {
        ...state,
        ...(toggleTabValue !== TAB_DRAWER.STATION
          ? {
              detailStation: {
                open: false,
                id: "",
                parameterTitle: "",
                formatText: "#.##",
                unit: "",
                indexInCard: 0,
              },
            }
          : {}),
        activeTab: toggleTabValue,
      };
    }

    case SET_MAP_INSTANCE: {
      return { ...state, mapboxInstance: action.payload };
    }

    case CLEAR_MAP_STATE: {
      return {
        open: false,
        x: 0,
        y: 0,
        isPublic: false,
        detailStation: {
          open: false,
          id: "",
          parameterTitle: "",
          formatText: "#.##",
          unit: "",
          indexInCard: 0,
        },
        activeTab: TAB_DRAWER.NONE,
        mapboxInstance: null,
      };
    }

    default: {
      return state;
    }
  }
};
export default mapReducer;
