import { Box, CircularProgress } from "@material-ui/core";
import { Close, Description, GetApp } from "@material-ui/icons";
import React from "react";

const TemplateFile = ({
  generateLoading,
  language,
  file,
  formik,
  mainData,
  setFile
}) => {
  return (
    <Box style={style.card} >
      {
        generateLoading ?
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px"
            }}
          >
            <CircularProgress color="primary" size={22} style={{ marginRight: "15px" }} />
            <span style={{
              fontSize: "16px"
            }}>
              {
                language === "en" ?
                  "Generating template file, please wait..." :
                  "Đang tạo tệp mẫu, vui lòng đợi..."
              }
            </span>
          </div> :
          formik?.values?.data_key && file.url &&
          <div className="uploaded-file ">
            <div className="uploaded-file-left">
              <span className="uploaded-file-icon">
                <Description fontSize="large" />
              </span>

              <div className="uploaded-file-text">
                <h5>
                  {file.name}
                </h5>
              </div>
            </div>

            <Box display="flex" className="uploaded-file-right">
              {
                <a href={file.url} target="__blank" download={`${file.name}.csv`}>
                  <span className="uploaded-file-icon-button">
                    <GetApp />
                  </span>
                </a>
              }
              {
                mainData.status && mainData.status.id === 1 &&
                <span
                  className="uploaded-file-icon-button"
                  onClick={() => setFile({})}
                >
                  <Close />
                </span>
              }
            </Box>
          </div>
      }
    </Box>
  )
}

TemplateFile.propTypes = {
};

const style={
  card:{
    display:"flex",
    width:"100%"
  }
}
export default TemplateFile