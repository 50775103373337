import { Box, Grid } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import { FormattedMessage } from "react-intl";
import { AlarmColorField, MainColorField } from "../../common";
import useDataAlarm from "app/shared/hooks/DataAlarm/data-alarm.hook";

const ValueForm = ({ formik, intl, parameters }) => {
  const { isLoadingDataAlarm, getDataAlarm } = useDataAlarm();

  async function onChangeParameter(stationId, parameterId) {
    const dataAlarm = await getDataAlarm(parameterId);
    formik.setValues((pre) => ({
      ...pre,
      parameter_id: parameterId,
      station_id: stationId,
      data_alarm: dataAlarm,
    }));
  }

  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        <ConfigureArea
          title={intl.formatMessage({
            id: "CLIENT.PARAMETERS_ACTIONS.PARAMETERS",
          })}
        >
          <Box mb={3}>
            <Box className="form-label">
              <p className="required">*</p>
              <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
            </Box>
            <SelectParamsByStaionDropdown
              disabled={isLoadingDataAlarm}
              data={parameters || []}
              value={{
                parameterId: formik.values["parameter_id"],
                stationId: formik.values["station_id"],
              }}
              onChange={({ stationId, parameterId }) =>
                onChangeParameter(stationId, parameterId)
              }
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MainColorField formik={formik} />
            </Grid>

            <Grid item xs={3}>
              <AlarmColorField formik={formik} />
            </Grid>
          </Grid>
        </ConfigureArea>
      </form>
    </div>
  );
};

export default ValueForm;
