import { encryptKey, decryptKey } from "app/utils/crypto";
import moment from "moment";
import { columns, PUBLIC_DASHBOARD_SESSION_KEY, TIMEOUT_SESSION } from "./constant";

export const findNearestPlace = (layout) => {

  if (!layout) return { x: 0, y: 0 };

  var checked = {};
  layout?.forEach(item => {
    for (let x = item.x; x < item.x + item.w; x++) {
      for (let y = item.y; y < item.y + item.h; y++) {
        checked = {
          ...checked,
          [x]: {
            ...checked[x],
            [y]: true
          }
        }
      }
    }
  });

  for (let y = 0; y < Infinity; y++) {
    for (let x = 0; x < columns; x++) {
      if (!checked[x]?.[y]) return { x, y } // if [x,y] is undefined
    }
  }
}

export const storeDashboardPassword = (id, password) => {
  const publicDashboardSession = {
    key: encryptKey(password),
    expired: moment(new Date()).add(TIMEOUT_SESSION.amount, TIMEOUT_SESSION.unit)
  }
  localStorage.setItem(`${PUBLIC_DASHBOARD_SESSION_KEY}_${id}`, JSON.stringify(publicDashboardSession))
}

export const clearDashboardPassword = (id) => {
  localStorage.removeItem(`${PUBLIC_DASHBOARD_SESSION_KEY}_${id}`)
}

export const getDashboardPassword = (id) => {
  const str = localStorage.getItem(`${PUBLIC_DASHBOARD_SESSION_KEY}_${id}`);
  if (str) {
    const publicDashboardSession = JSON.parse(str);

    if (moment(publicDashboardSession.expired).isAfter(moment(new Date())))
      return decryptKey(publicDashboardSession?.key) || "";
  }
  clearDashboardPassword(id);
  return "";
}