import { 
  TOGGLE_CHAT_WINDOW,
  SET_CHAT_WINDOWS,
  SET_CHAT_ICONS,
  SET_ROOM_ID_LIST,
  CHAT_WINDOWS_LOAD,
  CHAT_WINDOWS_FINISH,
  ROOM_IDS_LOAD,
  ROOM_IDS_FINISH,
  SET_ROOMS_NOTI,
  SET_ROOMS_INIT_NOTI,
  RESET_DATA,
  SET_END_USER_ROOMS,
} from '../actions/types';

const initialState = {
  chatWindows: [],
  chatIcons: [],
  roomIdList: [],
  roomsNoti: [],
  roomsInitNoti: [],
  endUserRooms: [],
  loadingRoomId: true,
  loadingChatWindow: true,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CHAT_WINDOW: {
      const { chatWindows, chatIcons } = action.payload;
      return { 
        ...state,
        chatWindows, 
        chatIcons: chatIcons || state.chatIcons
      };
    }
    case SET_CHAT_WINDOWS: {
      const { chatWindows } = action.payload;
      return { ...state, chatWindows };
    }
    case SET_CHAT_ICONS: {
      const { chatIcons } = action.payload;
      return { ...state, chatIcons };
    }
    case SET_ROOM_ID_LIST: {
      const { roomIdList } = action.payload;
      return { ...state, roomIdList };
    }
    case CHAT_WINDOWS_LOAD: {
      return { ...state, loadingChatWindow: true };
    }
    case CHAT_WINDOWS_FINISH: {
      return { ...state, loadingChatWindow: false };
    }
    case ROOM_IDS_LOAD: {
      return { ...state, loadingRoomId: true };
    }
    case ROOM_IDS_FINISH: {
      return { ...state, loadingRoomId: false };
    }
    case SET_ROOMS_NOTI: {
      const { roomsNoti } = action.payload;
      return { ...state, roomsNoti };
    }
    case SET_ROOMS_INIT_NOTI: {
      const { roomsInitNoti } = action.payload;
      return { ...state, roomsInitNoti };
    }
    case SET_END_USER_ROOMS: {
      const { endUserRooms } = action.payload;
      return { ...state, endUserRooms };
    }
    case RESET_DATA: {
      return {
        chatWindows: [],
        chatIcons: [],
        roomIdList: [],
        roomsNoti: [],
        roomsInitNoti: [],
        endUserRooms: [],
        loadingRoomId: true,
        loadingChatWindow: true,
      };
    }
    default: {
      return state;
    }
  }
}
export default chatReducer;