import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Modal, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import SharedInputField from '../../../../shared/components/SharedInputField';
import { useFormik } from "formik";
import * as Yup from "yup";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '80vh',
    overflowY: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: '100%',
  },
}));

const getDefaultValues = () => {
  return {
    password: '',
  };
};

function ModalPassword(props) {
  const { intl, open, handleClose} = props;
  const classes = useStyles();
  const [values, setValues] = React.useState(getDefaultValues());
  const [modalStyle] = React.useState(getModalStyle);
  const GeneralSchema = Yup.object().shape({
    password: Yup.string()
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
  });
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const formik = useFormik({
    initialValues: {
      password: values.password,

    },
    validationSchema: GeneralSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {    
      // props['handleClose']();
      props['handleChange'](values);
    },
  });

  const onSubmit = () => {
    props['handleChange'](values);
    // props['handleClose']();
  };

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id='CLIENT.LOGIN.PASSWORD_HINT' />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <form onSubmit={formik.handleSubmit}>        
          <Grid container spacing={2} mt={5}>
            <Grid item xs={12}>
              <SharedInputField
                // label={intl.formatMessage({
                //   id: 'CLIENT.CONNECTION_ACTIONS.PASSWORD_LABEL',
                // })}
                name="password"
                className=""
                type="password"
                isPasswordField
                required
                placeholder={intl.formatMessage({ id: 'CLIENT.CONNECTION_ACTIONS.PASSWORD_HINT' })}
                disabled={formik.isSubmitting}
                value={formik.values.password}
                errorMsg={formik.touched["password"] && formik.errors["password"]}
                {...formik.getFieldProps("password")}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="end" mt={3}>
            <Button
              type="submit"
              disabled={
                formik.isSubmitting ||
                !formik.isValid
              }
              className="custom-button"
            >
              <FormattedMessage id="CLIENT.GENERAL.OK_BUTTON" />
            </Button>
          </Box>

        </form>
      </div>
    );
  };

  React.useEffect(() => {
    return () => {
      formik.resetForm();
    }
  }, [props['open'], props['data']]);


  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

export default injectIntl(ModalPassword)