
import { memo } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';

const ChatHeader = (props) => {
  const { isNew, item, staticMessageList, onHide, onClose } = props;
  return (
    <div className='chat-window-header'>
      <Box display="flex" alignItems="center">
        <span className='icon-wrapper' style={{ marginLeft: 0, marginRight: 8 }}>
          <ChatIcon />
        </span>

        {isNew ?
          <span>
            <FormattedMessage id="CLIENT.CHAT.CHAT_WITH_AN_ACCOUNT" />
          </span> :
          <span className="chat-window-header-username"><FormattedMessage id="CLIENT.CHAT.CHAT_WITH" /> <strong>{item.username}</strong></span>
        }
      </Box>

      <div className='chat-window-header-right'>
        {!isNew &&
          <span className='icon-wrapper' onClick={() => onHide && onHide(item.id, staticMessageList)}>
            <RemoveIcon />
          </span>
        }
        <span className='icon-wrapper' onClick={() => onClose && onClose(item.id)}>
          <CloseIcon />
        </span>
      </div>
    </div>
  )
}


ChatHeader.propTypes = {
  isNew: PropTypes.bool,
  item: PropTypes.object,
  staticMessageList: PropTypes.array,
  onHide: PropTypes.func,
  onClose: PropTypes.func,
};

export default memo(ChatHeader);