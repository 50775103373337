import React from "react";
import { FormattedMessage } from "react-intl";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { accountTypes } from "../constants";

const AccountTypeSelect = ({ pageData }) => {
  const { accountType, handleAccountTypesChange, formik } = pageData;

  return (
    <RadioGroup
      className="radio-group"
      value={accountType}
      onChange={handleAccountTypesChange}
    >
      {accountTypes.map((item) => (
        <FormControlLabel
          key={item.id}
          value={item.value}
          control={<Radio size="small" color="primary" />}
          disabled={formik.isSubmitting}
          label={
            <div>
              <strong>
                <FormattedMessage id={item.label} />
              </strong>{" "}
              (<FormattedMessage id={item.subLabel} />)
            </div>
          }
        />
      ))}
    </RadioGroup>
  );
};

export default AccountTypeSelect;
