import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

export function MapSecurityGrid(language) {
  return {
    columns: [
      {
        field: 'user.fullname',
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_FULLNAME" />
        ),
        width: 150,
        align: 'left',
        sortable: true,
      },
      {
        field: 'user.username',
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_EMAIL" />
        ),
        width: 200,
        align: 'left',
        sortable: true,
      },
      {
        field: `map_permission.name_${language}`,
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_PERMISSION" />
        ),
        width: 150,
        align: 'left',
        sortable: true,
      },
      {
        field: 'expiry_day',
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_EXPIRY" />
        ),
        width: 150,
        align: 'left',
        sortable: true,
      },
      {
        field: 'expiry_start_day',
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_EXPIRY_START_DAY" />
        ),
        width: 150,
        align: 'left',
        sortable: true,
        renderCell: (params) => <>{params ? moment(params, 'MM-DD-YYYY').format("DD-MM-YYYY") : ""}</>
      },
      {
        field: 'expiry_end_day',
        headerName: (
          <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.TABLE_HEAD_EXPIRY_END_DAY" />
        ),
        width: 150,
        align: 'left',
        sortable: true,
        renderCell: (params) => <>{params ? moment(params, 'MM-DD-YYYY').format("DD-MM-YYYY") : ""}</>
      },
    ],
    rows: [
      {
        field: 'user.fullname',
        type: 'text',
        default: '',
      },
      {
        field: 'user.username',
        type: 'text',
        default: '',
      },
      {
        field: `map_permission.name_${language}`,
        type: 'text',
      },
      {
        field: 'expiry_day',
        type: 'text',
      },
      {
        field: 'expiry_start_day',
        type: 'text',
      },
      {
        field: 'expiry_end_day',
        type: 'text',
      },
    ],

    actions: {
      delete: <DeleteIcon style={{ fontSize: '20px' }} />,
    },
  };
}
