import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M20.477 3.333c1.791 0 3.498.267 5.119.767C28.95 4.956 36.02 9 37.455 18.334c.05.55.085 1.1.085 1.666 0 9.2-7.644 16.667-17.063 16.667-9.42 0-17.064-7.467-17.064-16.667 0-9.2 7.644-16.666 17.064-16.666zM18.77 33.218C12.03 32.4 6.826 26.8 6.826 20c0-1.033.136-2.017.358-2.983L15.358 25v1.667C15.357 28.5 16.892 30 18.77 30v3.217zm15.22-14.883c.07.55.137 1.1.137 1.666 0 3.467-1.365 6.617-3.583 8.983-.444-1.35-1.706-2.316-3.242-2.316h-1.706v-5c0-.917-.768-1.667-1.707-1.667H13.651v-3.333h3.413c.938 0 1.706-.75 1.706-1.667v-3.333h3.413c1.877 0 3.413-1.5 3.413-3.333 2.184.354 6.92 2.85 8.395 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
