import React from "react";
import ParameterIcon from "assets/icon/parameter-icon";
import { formatString } from "app/utils/string-formatter";
import _ from "lodash";

const Parameter = ({ parameter }) => {
  const {
    icon,
    name,
    value,
    parameter_format_text: format,
    isAlarm = true,
  } = parameter;

  if (!_.isEmpty(parameter)) {
    return (
      <div className={isAlarm ? "parameter parameter-alarm" : "parameter"}>
        <span className="parameter-icon">
          <ParameterIcon id={icon} />
        </span>

        <span className="parameter-name">{name}</span>

        <span className="parameter-value">
          <span className="parameter-number">
            {formatString(format, value)}
          </span>
        </span>
      </div>
    );
  }

  return <div className="parameter"></div>;
};

export default Parameter;
