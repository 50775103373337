import React, { Fragment } from 'react';
import { useLanguage } from '../shared/hooks';
import { IntlProvider } from 'react-intl';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/vi';

import english from './messages/en';
import vietnamese from './messages/vi';

const allMessages = {
  en: english,
  vi: vietnamese,
};

const LanguageProvider = ({ children }) => {
  const [language, _] = useLanguage();
  const messages = allMessages[language];

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={language}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

LanguageProvider.displayName = 'LanguageProvider';

export default LanguageProvider;
