import React from 'react';

import './styles.css';

import ReactResizeDetector from 'react-resize-detector';
import { invertColor } from 'app/utils/utils';


// https://css-tricks.com/building-progress-ring-quickly/
const CircularGauge = (props) => {

  const MAX_PORTION = 40;
  const SPACE_BETWEEN_TWO_LINE = 50;
  const SPACE_BETWEEN_LABEL_CIRCLE = 15;
  const LINE_LENGTH = 8;
  const CHART_SIZE_RATE = 0.6
  const refChartContainer = React.useRef(null);

  const nearOptions = [2, 4, 5, 10];
  const maxValue = props['maxValue'] || 100;
  const minValue = props['minValue'] || 0;
  const value = props['value'] || 0;

  const color = props['color'] || '#46AE4E';
  const label = props['label'] || '';

  const [data, setData] = React.useState([]);

  const [paneConfig, setPaneConfig] = React.useState({
    containerWidth: 0,
    containerHeight: 0,
    circumference: 0,
    circumferenceInner: 0,
    x: 0,
    y: 0,
    r: 0,
    d: 0,
    rInner: 0,
    rHint: 0,
    valueWidth: 0,
    valueOffset: 0,
    lineDefault: [0, 0, 0, 0],
    pointDefault: [0, 0, 0, 0]
  });

  const getCircumference = (r) => {
    return r * 2 * Math.PI;
  }

  const getPercent = (value) => {
    const range = maxValue - minValue;
    return ((value - minValue) / Math.abs(range));
  }

  const getOffset = (value, r) => {
    const percent = getPercent(value);
    const circumference = getCircumference(r);
    return circumference - percent * circumference;
  }

  const setSize = () => {
    const containerWidth = refChartContainer?.current?.clientWidth;
    const containerHeight = refChartContainer?.current?.clientHeight;
    const d = CHART_SIZE_RATE * (Math.min(containerWidth, containerHeight));
    const r = d / 2;
    const rInner = r * 0.75;
    const rHint = rInner - 5;
    const percent = getPercent(value);
    const circumference = getCircumference(rInner);
    const offset = circumference - percent * circumference;
    const x = refChartContainer?.current?.clientWidth / 2;
    const y = refChartContainer?.current?.clientHeight / 2;
    setPaneConfig({
      containerWidth: containerWidth,
      containerHeight: containerHeight,
      d: d,
      r: r,
      x: x,
      y: y,
      rInner: rInner,
      rHint: rHint,
      valueWidth: (r - rInner) * 2, // diameter of inner circle
      valueOffset: offset,
      circumferenceInner: circumference,
      lineDefault: [x - r, y, x - r + LINE_LENGTH, y], // 8 is the length of line
      pointDefault: [x - r - SPACE_BETWEEN_LABEL_CIRCLE, y],
      circumference: getCircumference(r)
    });
  }

  /* Desired result: Number of portions that circumference can be devided.
   the number must be divisor of 5 and multiple of 360 */
  const getNumOfPortion = (circumference) => {

    var num = Math.min(
      Math.round(Math.floor(circumference / SPACE_BETWEEN_TWO_LINE) / 5) * 5
      , MAX_PORTION);
    while (360 % num !== 0) {
      num--;

      if (num <= 2) {
        num = 2;
        break;
      }

    }
    return num;
  }

  const roundNear = (number, value) => {
    return (Math.round(number * value) / value).toFixed(2);
  };

  const rotateWithDefaultCenter = (x, y, degree) => {
    var angleInRadians = (degree) * Math.PI / 180.0;
    const xa = paneConfig.x + (x - paneConfig.x) * Math.cos(angleInRadians) - (y - paneConfig.y) * Math.sin(angleInRadians);
    const ya = paneConfig.y + (x - paneConfig.x) * Math.sin(angleInRadians) + (y - paneConfig.y) * Math.cos(angleInRadians);
    return {
      x: xa,
      y: ya
    }
  }

  const drawData = () => {
    var nearOptionIndex = 0;
    var numOfPortion = getNumOfPortion(getCircumference(paneConfig.r));
    const range = maxValue - minValue;
    var step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
    while (step == 0) {
      nearOptionIndex++;
      step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      while (nearOptionIndex == 3 && step == 0) {
        numOfPortion = numOfPortion * 0.8;
        step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      }
    }

    var vl = Number(Math.floor(minValue));
    var newDataLines = [];
    while (vl <= maxValue) {
      if (vl >= minValue) {
        vl = Number(Number(vl).toFixed(2));
        newDataLines.push({
          value: vl,
          label: Number(vl) > 1000 ? `${Math.floor(vl / 1000)}k` : vl
        });
      }
      vl += Number(step);
    }
    setData(newDataLines);

  }

  const onResize = React.useCallback(() => {
    if (refChartContainer) {
      setSize();
    }
  }, []);

  React.useEffect(() => {
    if (refChartContainer) {
      setSize();
    }
  }, [refChartContainer]);

  React.useEffect(() => {
    if (paneConfig.circumference > 0)
      drawData();
  }, [paneConfig, props['minValue'], props['maxValue'], props['value']]);

  return (
    <ReactResizeDetector
      handleWidth handleHeight
      onResize={onResize}
      refreshRate="500"
      refreshMode='debounce'>
      {({ width, height }) => (
        <div className="circular-gauge" ref={refChartContainer}>
          <div className="circular-gauge-container"
            style={{
              width: paneConfig.containerWidth,
              height: paneConfig.containerHeight,
            }}>

            <svg viewBox={`0 0 ${paneConfig.containerWidth} ${paneConfig.containerHeight}`}>
              <circle cx={paneConfig.x} cy={paneConfig.y} r={paneConfig.r} fill={`${invertColor(color)}4d`} stroke="none" />

              <circle
                cx={paneConfig.x}
                cy={paneConfig.y}
                r={paneConfig.rInner}
                stroke={invertColor(color)}
                strokeWidth={paneConfig.valueWidth}
                strokeDasharray={`${paneConfig.circumferenceInner} ${paneConfig.circumferenceInner}`}
                strokeDashoffset={getOffset(value, paneConfig.rInner)}
                fill="transparent"
                transform={`rotate(180 ${paneConfig.x} ${paneConfig.y})`}
              />

              <circle cx={paneConfig.x}
                cy={paneConfig.y}
                r={paneConfig.rHint}
                fill={color}
                stroke="none" />

              {props['showLines'] && data.length > 0 && (
                <g>
                  {data.map((line, index) => {
                    const alpha = getPercent(line.value) * 360;
                    return (
                      <path
                        key={line.value}
                        fill="none"
                        stroke={invertColor(color)}
                        strokeWidth="1.2"
                        d={`M ${paneConfig.lineDefault[0]} ${paneConfig.lineDefault[1]}
                        L ${paneConfig.lineDefault[2]} ${paneConfig.lineDefault[3]}`} opacity="1"
                        transform={`rotate(${alpha} ${paneConfig.x} ${paneConfig.y})`}></path>
                    )
                  })}
                </g>
              )}

              {props['showNumbers'] && data.length > 0 && (
                <g>

                  {data.map((line, index) => {
                    var alpha = getPercent(line.value) * 360;
                    const { x, y } = rotateWithDefaultCenter(paneConfig.pointDefault[0], paneConfig.pointDefault[1], alpha);

                    if (index === data.length - 1 && data[0].value === Number(minValue))
                      return null;

                    return (<text
                      x={x}
                      y={y}
                      fill={invertColor(color)}
                      font-family="sans-serif"
                      font-size="12"
                      text-anchor="middle"
                      alignment-baseline="middle"
                    >
                      {line.label}
                    </text>);
                  })}
                </g>
              )}
            </svg>

            <div className="circular-gauge-info">
              <div className="circular-gauge-currentvalue" style={{ fontSize: Math.min(paneConfig.r * 0.22, 60) }}>
                <p style={{ fontSize: Math.min(paneConfig.r * 0.22, 63), color: invertColor(color) }}>
                  {value}
                </p>
                <p style={{ fontSize: Math.min(paneConfig.r * 0.16, 43), color: invertColor(color) }}>{`(${props['unit']})`}</p>
              </div>
              <div className="circular-gauge-currentstate"
                style={{
                  fontSize: Math.min(paneConfig.r * 0.15, 30),
                  color: invertColor(color)
                }}>
                <span style={{
                  width: paneConfig.r + paneConfig.valueWidth - 30,
                }}>
                  {label}
                </span>
              </div>
            </div>
          </div>

        </div>
      )}
    </ReactResizeDetector>

  );
};

export default CircularGauge;
