import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSizes";
import { getDeviceScreen } from "app/utils/utils";

export default function useDetectDevice() {
  const [deviceType, setDeviceType] = useState("desktop");
  const { width } = useWindowSize();

  useEffect(() => {
    const device = getDeviceScreen(width);
    setDeviceType(device);
  }, [width]);

  return { deviceType };
}
