import React from "react";
import { TwitterPicker } from "react-color";
import "./index.css";

const ColorPicker = React.forwardRef(
  ({ color, colors, triangle = "hide", ...props }, ref) => {
    const wrapperRef = React.useRef(null);
    const [showPalette, setShowPalette] = React.useState(false);

    const handleChange = (color, event) => {
      props["onChange"](color["hex"]);
    };

    const togglePalette = () => {
      if (!props.disabled) {
        setShowPalette(!showPalette);
      }
    };

    const handleClickOutside = (e) => {
      if (wrapperRef && !wrapperRef.current.contains(e.target)) {
        setShowPalette(false);
      }
    };

    React.useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const style = {
      ...props.style,
    };

    if (props["position"] === "right") {
      style["left"] = 0;
    } else {
      style["right"] = 0;
    }

    return (
      <div
        className={`color-picker ${props.disabled ? "disabled" : ""}`}
        ref={wrapperRef}
      >
        <span
          className="color-picker-color"
          onClick={togglePalette}
          style={{ backgroundColor: color || "#000", ...props.style }}
        ></span>

        {showPalette && (
          <div className="color-picker-palette" style={style}>
            <TwitterPicker
              ref={ref}
              triangle={triangle}
              color={color}
              colors={
                colors || [
                  "#CCCCCC",
                  "#808080",
                  "#4D4D4D",
                  "#333333",
                  "#FCDC00",
                  "#FB9E00",
                  "#FE9200",
                  "#FDA1FF",
                  "#DBDF00",
                  "#A4DD00",
                  "#68CCCA",
                  "#73D8FF",
                  "#AEA1FF",
                  "#F44E3B",
                  "#AB149E",
                  "#B0BC00",
                  "#68BC00",
                  "#16A5A5",
                  "#009CE0",
                  "#7B64FF",
                  "#D33115",
                  "#660066",
                  "#808900",
                  "#194D33",
                  "#0C797D",
                  "#0062B1",
                  "#653294",
                  "#9F0500",
                ]
              }
              {...props}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    );
  }
);

export default ColorPicker;
