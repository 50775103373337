import AcUnit from "./AcUnit";
import AirPlane from "./AirPlane";
import Beach from "./Beach";
import Brightness from "./Brightness";
import Cloud from "./Cloud";
import CloudCloud from "./CloudCloud";
import CloudRain from "./CloudRain";
import DirectionBoat from "./DirectionBoat";
import Drops from "./Drops";
import FlashOn from "./FlashOn";
import Gauge from "./Gauge";
import Global from "./Global";
import Ph from "./PH";
import PinWheel from "./PinWheel";
import Sunny from "./Sunny";
import Temparature from "./Temparature";
import Wave from "./Wave";
import Wind from "./Wind";

const icons = {
  1: Temparature,
  2: Cloud,
  3: CloudCloud,
  4: CloudRain,
  5: Wave,
  6: FlashOn,
  7: AcUnit,
  8: Ph,
  9: Drops,
  10: DirectionBoat,
  11: Beach,
  12: Brightness,
  13: Sunny,
  14: AirPlane,
  15: Global,
  16: Wind,
  17: PinWheel,
  18: Gauge
}

function ParameterIcon(props) {
  const IconComponent = icons[props?.id];

  if (!IconComponent)
    return <Temparature {...props} />; // return Default Icon

  return <IconComponent {...props} />
}

export default ParameterIcon;

export { icons, ParameterIcon };
