import * as Yup from "yup";

const LocationMapSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    name_vi: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    dashboard_location_map_station: Yup.array()
      .when("data_mode", {
        is: "PREDICTION_DATA",
        then: Yup.array()
          .of(Yup
            .object()
            .shape({
              location_type: Yup.string().oneOf(["DYNAMIC"], ""),
            }))
      }),
  });
  
  return schema;
}

export default LocationMapSchema