import axiosClient from "./axiosClient";

const securityApi = {
  getAllSecurity(id, params, token) {
    const url = `/api/v1/security/get-all-security/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  },
  getAllStationPermission() {
    const url = "/api/v1/station-permission";
    return axiosClient.get(url);
  },
  deleteSecurity(id, token) {
    const url = `/api/v1/security/${id}`;
    return axiosClient.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  addSecurity(id, data, token) {
    const url = `/api/v1/security/${id}`;
    return axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getAllSyncDataSecurity(id, params, token) {
    const url = `/api/v1/sync-data-securities/list/${id}`;

    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  },
  addSyncDataSecurity(data, token) {
    const url = `/api/v1/sync-data-securities`;

    return axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  deleteSyncDataSecurity(id, token) {
    const url = `/api/v1/sync-data-securities/${id}`;

    return axiosClient.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export default securityApi;
