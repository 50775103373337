import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const AddressInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={12}>
      <Box mb={3}>
        <SharedInputField
          name="address"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.ADDRESS_LABEL",
          })}
          type="text"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.ADDRESS_HINT",
          })}
          errorMsg={formik.touched["address"] && formik.errors["address"]}
          {...formik.getFieldProps("address")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default AddressInput;
