import { Box } from "@material-ui/core";
import ColorPicker from "app/shared/components/ColorPicker";
import React from "react";
import { FormattedMessage } from "react-intl";

const MainColorField = ({ formik }) => {
  return (
    <>
      <Box className="form-label">
        <p className="required">*</p>
        <FormattedMessage id="CLIENT.DASHBOARD.MAIN_COLOR_LABEL" />
      </Box>

      <ColorPicker
        position="right"
        color={formik.values.main_color}
        onChange={(colorValue) =>
          formik.setFieldValue("main_color", colorValue)
        }
      />
    </>
  );
};

export default MainColorField;
