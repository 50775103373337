import { DEVICE_TYPE } from "app/shared/constants/constants";
import { isAxiosError } from "axios";

export function startsWithZero(pattern) {
  return pattern?.startsWith("0");
}

export function hasHash(pattern) {
  return pattern?.includes("#");
}

export function hasDot(pattern) {
  return pattern?.includes(".");
}

export function hasComma(pattern) {
  return pattern?.includes(",");
}

export function hasExponential(pattern) {
  return pattern?.includes("#e#");
}

export function hasSpace(pattern) {
  return pattern?.indexOf(" ") >= 0;
}

export function hasZero(pattern) {
  return pattern?.indexOf("0") >= 0;
}

export function endsWithZero(pattern) {
  return pattern?.endsWith("0");
}

export function hasLetter(pattern) {
  var regExp = /[a-zA-Z%‰]/g;
  return regExp.test(pattern);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

export function commify(n) {
  const parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
}

export function hasLiteralString(str) {
  return str.includes('"');
}

export function countLetters(str, letter) {
  let letters = str.split("");
  let count = 0;

  for (let i = 0; i < letters.length; i++) {
    if (letters[i] === letter) {
      count += 1;
    }
  }

  return count;
}

function padZero(str, len) {
  let length = len || 2;
  let zeros = new Array(length).join("0");
  return (zeros + str).slice(-length);
}

export const invertColor = (hexInput, blackWhite) => {
  blackWhite = true;

  if (!hexInput) return "#404040";

  let hex = hexInput;
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.log("Invalid HEX color.");
    return "#404040";
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (blackWhite) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#404040" : "#FFFFFF";
  }
  // invert color components
  let invertR = (255 - r).toString(16);
  let invertG = (255 - g).toString(16);
  let invertB = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(invertR) + padZero(invertG) + padZero(invertB);
};

export function ErrorHandler(error, customHandler = () => {}) {
  if (process.env.NODE_ENV === "development") {
    if (!isAxiosError(error)) {
      console.error("An unexpected error:", error);
      return;
    }

    console.error("AxiosErr:", error);
  }

  if (typeof customHandler === "function") {
    customHandler(error);
  }
}

export function getDeviceScreen(width) {
  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia("(pointer:coarse)");

    if (mQ && mQ.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;

      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  if (width < 768) {
    return DEVICE_TYPE.MOBILE;
  } else if (width <= 1024) {
    return DEVICE_TYPE.TABLET;
  } else {
    return !hasTouchScreen ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.TABLET;
  }
}
