import { Box, FormHelperText, Grid } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import { FormattedMessage } from "react-intl";
import { AlarmColorField, MainColorField } from "../../common_v1";

const AlarmSummaryForm = ({ formik, intl, parameters }) => {
  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.HEADER_LABEL',
          })}>

          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.DATAMODE',
          })}>

          <DataModeConfigurator
            showHistoricTimeRange
            options={[
              'HISTORICAL_DATA',
            ]}
            values={{
              historical_time_range: `${formik.values['historical_time_range']}-${formik.values['historical_time_range_type']}`,
              prediction_time_range: `${formik.values['prediction_time_range']}-${formik.values['prediction_time_range_type']}`,
              historical_start_time: formik.values.historical_start_time,
              historical_end_time: formik.values.historical_end_time,
            }}
            mode={formik.values['data_mode']}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            error={formik.errors["historical_start_time"]}
          />
        </ConfigureArea>

        <ConfigureArea title={intl.formatMessage({
          id: 'CLIENT.PARAMETERS_ACTIONS.PARAMETERS',
        })}>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
            <Box mb={3} width="100%">
              <Box className="form-label">
                <p className="required">*</p>
                <FormattedMessage id='CLIENT.PARAMETERS_ACTIONS.PARAMETERS' />
              </Box>
              <SelectParamsByStaionDropdown
                data={parameters || []}
                value={{
                  parameterId: formik.values["parameter_id"],
                  stationId: formik.values["station_id"]
                }}
                onChange={({ stationId, parameterId }) => {
                  formik.setFieldValue('parameter_id', parameterId);
                  formik.setFieldValue('station_id', stationId);
                }} />
              {
                formik.touched["parameter_id"] && formik.errors["parameter_id"] &&
                <FormHelperText className="error-message">{formik.errors["parameter_id"]}</FormHelperText>
              }
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MainColorField formik={formik} />
            </Grid>

            {/* <Grid item xs={3}>
              <AlarmColorField formik={formik} />
            </Grid> */}
          </Grid>
        </ConfigureArea>
      </form>
    </div>
  );
}

export default AlarmSummaryForm