import { Container, Box } from "@material-ui/core";
import Logo from "assets/images/logo.png";
import LanguageSwitcher from "app/shared/components/LanguageSwitcher";
import User from "./User";
import { useState, useEffect, useCallback } from "react";
import CustomBreadcrumbs from "./Breadcrumbs";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import organizationApi from "app/api/organizationApi";
import { setUserOrganization } from "app/redux/actions";
import { USER_ROLE_ID } from "app/shared/constants/users";
import { MobileHeader } from "./MobileHeader.component";
import RightDrawer from "./RightDrawer/RightDrawer.component";
import useDetectDevice from "app/shared/hooks/useDetectDevice";
import "./index.css";
import { triggerSidebar } from "app/redux/actions/sidebar.action";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Header = (props) => {
  const { active, breadcrumbs, title } = props;
  const [showsRightDrawer, setShowsRightDrawer] = useState(false);
  const { token, user, userOrganization } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user,
      userOrganization: user.userOrganization,
    }),
    shallowEqual,
  );
  const { deviceType } = useDetectDevice();

  const dispatch = useDispatch();
  const onToggleMenu = useCallback(() => {
    dispatch(triggerSidebar());
  }, [dispatch]);

  const onOpenRightDrawer = () => setShowsRightDrawer(true);

  const onCloseRightDrawer = () => setShowsRightDrawer(false);

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const id = user.organizationId;
        const res = await organizationApi.getOrganization(id, token);
        if (res.success) {
          props.setUserOrganization({
            ...res.data,
            url_logo: res.data.url_logo
              ? `${process.env.REACT_APP_API_URL}/${res.data.url_logo}`
              : null,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    getOrganization();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {deviceType !== "desktop" ? (
        <MobileHeader
          title={title}
          onToggleMenu={onToggleMenu}
          onToggleRightDrawer={onOpenRightDrawer}
          breadcrumbs={breadcrumbs}
        />
      ) : (
        <div
          className={`main-header ${!active ? "main-header-inactive" : ""} ${
            user.roleId === USER_ROLE_ID.CONTENT_ROLE ? "non-left" : ""
          }`}
        >
          <Container maxWidth={false} className="container">
            <div className="logo">
              <img
                src={(userOrganization && userOrganization.url_logo) || Logo}
                alt="logo"
              />
            </div>

            <div className="actions">
              <Link to="/chats">
                <Box className="desktop-header-chat-icon">
                  <img src="/images/header-chat-icon.png" alt="" />
                  <span>
                    <FormattedMessage id="CLIENT.CHATS.PAGE_TITLE" />
                  </span>
                </Box>
              </Link>

              <LanguageSwitcher />
              <User onToggleRightDrawer={onOpenRightDrawer} />
            </div>
          </Container>

          {breadcrumbs?.length > 0 && (
            <div className="breadcrumbs-wrapper">
              <CustomBreadcrumbs title={title} items={breadcrumbs} />
            </div>
          )}
        </div>
      )}

      <RightDrawer visible={showsRightDrawer} onClose={onCloseRightDrawer} />
    </>
  );
};

export default connect(null, { setUserOrganization })(Header);
