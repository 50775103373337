import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M19.25 1.75h3.5v4.917h-3.5V1.75zM8.68 5.917L11.83 8.9l-2.485 2.35-3.133-2.983 2.468-2.35zM7 18.333H1.75v3.334H7v-3.334zm28.783-10.08l-2.462-2.345-3.13 2.982 2.462 2.345 3.13-2.982zM30.17 31.1l3.133 3 2.467-2.35-3.15-2.983-2.45 2.333zm10.08-12.767H35v3.334h5.25v-3.334zM21 10c-5.793 0-10.5 4.483-10.5 10S15.207 30 21 30s10.5-4.483 10.5-10S26.793 10 21 10zm-7 10c0 3.683 3.133 6.667 7 6.667s7-2.984 7-6.667-3.133-6.667-7-6.667-7 2.984-7 6.667zm8.75 13.333v4.917h-3.5v-4.917h3.5zm-14.07.75l-2.467-2.35 3.132-3 2.467 2.35-3.132 3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
