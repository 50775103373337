import { USER_LOG_IN, USER_LOG_OUT, USER_UPDATE, RELOAD_DATA, USER_ORGANIZATION_SET } from './types';

export const logIn = (user, token, role) => {
  return {
    type: USER_LOG_IN,
    payload: {
      user,
      token,
      role
    },
  };
};

export const updateUser = (user) => {
  return {
    type: USER_UPDATE,
    payload: {
      user,
    },
  };
};

export const reloadData = () => {
  return {
    type: RELOAD_DATA,
  };
};

export const logOut = () => {
  return {
    type: USER_LOG_OUT,
  };
};

export const setUserOrganization = (userOrganization) => {
  return {
    type: USER_ORGANIZATION_SET,
    payload: {
      userOrganization
    }
  };
}
