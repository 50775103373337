import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import ColorPicker from "../../ColorPicker";
import SharedInputField from "../../SharedInputField";
import { injectIntl } from "react-intl";

function HeaderConfigurator(props) {
  const { intl, formik } = props;

  useEffect(() => {
    if (!formik.values["show_header"]) {
      formik.setErrors({
        ...formik.errors,
        name_en: "",
        name_vi: "",
      });
      formik.setTouched({
        ...formik.errors,
        name_en: false,
        name_vi: false,
      });
    }
  }, [formik.values["show_header"]]);
  return (
    <>
      <Box mb={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.show_header}
              onChange={(e) =>
                formik.setFieldValue("show_header", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: "CLIENT.DASHBOARD.SHOW_HEADER_LABEL",
          })}
        />
      </Box>

      <Box mb={3}>
        <SharedInputField
          name="name_en"
          label={intl.formatMessage({ id: "CLIENT.DASHBOARD.NAME_EN_LABEL" })}
          type="text"
          required={formik.values["show_header"]}
          className={`bg-white ${
            formik.values["show_header"] ? "" : "disabled-field"
          }`}
          placeholder={intl.formatMessage({
            id: "CLIENT.DASHBOARD.NAME_EN_HINT",
          })}
          {...formik.getFieldProps("name_en")}
          disabled={formik.isSubmitting || !formik.values["show_header"]}
          errorMsg={formik.touched["name_en"] && formik.errors["name_en"]}
        />
      </Box>

      <Box mb={3}>
        <SharedInputField
          name="name_vi"
          label={intl.formatMessage({ id: "CLIENT.DASHBOARD.NAME_VI_LABEL" })}
          type="text"
          required={formik.values["show_header"]}
          className={`bg-white ${
            formik.values["show_header"] ? "" : "disabled-field"
          }`}
          placeholder={intl.formatMessage({
            id: "CLIENT.DASHBOARD.NAME_VI_HINT",
          })}
          {...formik.getFieldProps("name_vi")}
          disabled={formik.isSubmitting || !formik.values["show_header"]}
          errorMsg={formik.touched["name_vi"] && formik.errors["name_vi"]}
        />
      </Box>

      <Box className="form-label">
        {intl.formatMessage({
          id: "CLIENT.DASHBOARD.HEADER_COLOR_LABEL",
        })}
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={3}>
            <ColorPicker
              disabled={!formik.values?.show_header}
              color={formik.values?.header_color}
              position="right"
              onChange={(colorValue) =>
                formik.setFieldValue("header_color", colorValue)
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default injectIntl(HeaderConfigurator);
