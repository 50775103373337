import React, { memo, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import './ChatWindow.css';

import ChatWindowFooter from './ChatWindowFooter';
import MessageList from './MessageList';
import ChatHeader from './ChatHeader';

import chatApi from '../../../../api/chatApi';
import UserSelection from './UserSelection';
import { useDispatch } from 'react-redux';
import { addWaitingMessage, removeWaitingMessage } from 'app/redux/actions/chatMessageAction';
import { useIndexedDB } from 'react-indexed-db-hook';

const ChatWindow = (props) => {
  const {
    onClose,
    onHide,
    isNew,
    handleInitChat,
    userList,
    item,
    socket,
    subSocket,
    userId,
    token,
    userAvatar,
    waitMessage,
  } = props;
  const [messageList, setMessageList] = useState([]);
  const [staticMessageList, setStaticMessageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(item?.page || 1);
  const lastMessageRef = useRef(null);
  const dispatch = useDispatch();
  const { add, deleteRecord , getByID } = useIndexedDB('files');

  const setMessages = (data) => {
    setMessageList(data);
    setStaticMessageList(data);
  }

  useEffect(() => {
    socket?.on(item.roomChatId, (res) => {
      if (!res.success && res?.length > 0) {
        const data = res
          .map((file) => ({
            roomChatMemberId: file.roomChatMemberId,
            roomChatId: item.roomChatId,
            'RoomChatAttachments.attachmentPath': file?.attachmentPath,
            'RoomChatAttachments.extensionAttachment':
              file?.extensionAttachment,
            'RoomChatAttachments.id': file?.id,
            'RoomChatAttachments.originalNameAttachment':
              file?.originalNameAttachment,
            createdAt: file.createdAt,
            id: file.id,
          }))
          ?.reverse();
        setMessageList((prevList) => [...prevList, ...data]);
        setStaticMessageList((prevList) => [...prevList, ...data]);
      }
      if (res.success) {
        setMessageList((prevList) => [...prevList, res.data]);
        setStaticMessageList((prevList) => [...prevList, res.data]);
      }
    });
    subSocket?.on(item.roomChatId, (res) => {
      if (!res.success && res?.length > 0) {
        const data = res
          .map((file) => ({
            roomChatMemberId: file.roomChatMemberId,
            roomChatId: item.roomChatId,
            'RoomChatAttachments.attachmentPath': file?.attachmentPath,
            'RoomChatAttachments.extensionAttachment':
              file?.extensionAttachment,
            'RoomChatAttachments.id': file?.id,
            'RoomChatAttachments.originalNameAttachment':
              file?.originalNameAttachment,
            createdAt: file.createdAt,
            id: file.id,
          }))
          ?.reverse();
        setMessageList((prevList) => [...prevList, ...data]);
        setStaticMessageList((prevList) => [...prevList, ...data]);
      }
      if (res.success) {
        setMessageList((prevList) => [...prevList, res.data]);
        setStaticMessageList((prevList) => [...prevList, res.data]);
      }
    })
  }, [item.roomChatId, socket, subSocket])

  useEffect(() => {
    if (!messageList?.length && item?.data?.length) {
      setMessages([
        ...item?.data,
        ...waitMessage?.filter((item) => item.roomChatId === item.roomChatId),
      ])
    }
  }, [item.data, messageList?.length])

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setLoading(true);
        let resp;

        if (item.type === 'external') {
          const data = {
            userId,
            roomChatId: item.roomChatId,
            page,
            limit: 8,
          };
          resp = await chatApi.joinEndUserRoom(data, token);
        } else {
          const data = {
            userSourceId: userId,
            userTargetId: item.userId,
            limit: 8,
            page,
          };

          resp = await chatApi.initChatRoom(data, token);
        }

        if (resp?.success) {
          if (resp?.data?.length === 0 || resp?.listMessage?.length === 0) {
            setHasMore(false);
          } else {
            if (resp?.listMessage || resp?.data) {
              const list =
                item.type === 'external'
                  ? resp?.listMessage?.reverse()
                  : resp?.data?.reverse();
              setMessageList((prevList) => [...list, ...prevList]);

              document.querySelector('.chat-window-main').scrollTop = 200;
            }

            setHasMore(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (page !== 1) {
      fetchMessages();
    }
  }, [item.roomChatId, item.type, item.userId, page, token, userId])
  
  const handleSubmit = async (text , files , oldMessage) => {
    try {
      let messageError = [];
      let displayData = [];
      let payload = []
      const value = text?.trim();
      const isAttachment = !!files && files.length > 0;
      const information  =  {
        roomChatMemberId: oldMessage?.roomChatMemberId || item.roomChatMemberId,
        roomChatId: oldMessage?.oldMessage || item.roomChatId,
        senderUserId: userId,
        receiverUserId: oldMessage?.userId || item.userId,
      }
      if (isAttachment) {
        const form = new FormData();
        for (let file of files) {
          form.append('attachment', file.file);
        }
        form.append('roomChatMemberId', item.roomChatMemberId);

        try {
          const res = await chatApi.uploadFile(form, token);
          if(res.success) {
            payload.push({
              ...information,
              attachment : res?.data?.attachment,
              message: '',
            })
            const data = res?.data?.attachment
            ?.map((file) => ({
              ...information,
              'RoomChatAttachments.attachmentPath': file?.attachmentPath,
              'RoomChatAttachments.extensionAttachment':
                file?.extensionAttachment,
              'RoomChatAttachments.id': file?.id,
              'RoomChatAttachments.originalNameAttachment':
                file?.originalNameAttachment,
              createdAt: file.createdAt,
              id: file.id,
            }))
            ?.reverse();
            displayData = [...data]
          }
        } catch (error) {
          for (let item of files) {
            let fileId = await add(item);
            messageError.push({
              ...information,
              message: '',
              fileId,
            })
          } 
        }
      }  
      
      if (value) {
        const id = uuidv4();
        displayData.push({
          ...information,
          message: value,
          createdAt: new Date(),
          id,
        })
        payload.push({
          ...information,
          message: value,
          attachment: null,
          id,
        })
      }
      if (payload.length > 0) { 
        for (let item of payload) {
          if (!information.receiverUserId) {
            if (subSocket.connected === false) {
              messageError.push({
                ...item,
              })
              displayData = displayData.filter((item) => item.id !== item.id);
              continue;
            }
            subSocket?.emit('Client-sent-data', item);
          } else {
            if (socket.connected === false) {
              messageError.push({
                ...item,
              })
              displayData = displayData.filter((item) => item.id !== item.id);
              continue;
            }
            socket?.emit('Client-sent-data', item);
          }
        }
        setMessages((prevList) => [
          ...prevList,
          ...displayData,
        ])
      }
      if (messageError.length > 0) {
        for (let item of messageError) {
          let idMessage = uuidv4();
          dispatch(addWaitingMessage({
            ...item,
            createdAt: new Date(),
            id: idMessage,
            error: true,
          }));
          setMessages((prevList) => [
            ...prevList,
            {
              ...item,
              createdAt: new Date(),
              id: idMessage,
              error : true,
            },
          ])
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const renderSelect = () => {
    return (
      <UserSelection
        userList={userList}
        handleInitChat={handleInitChat}
        item={item}
      />
    );
  };
  
  const onResend = async (messageId) => {
    const message = waitMessage.find(item => item.id === messageId);
    setMessageList((prevList) => prevList.filter(item => item.id !== messageId));
    dispatch(removeWaitingMessage(message.id));
    const file = await getByID(message?.fileId);
    await handleSubmit(message.message, message?.fileId ? [file] : null, message);
    if(message?.fileId) {
      await deleteRecord(message?.fileId);
    }
  }

  const removeMessage = async (messageId) => {
    const message = waitMessage.find(item => item.id === messageId);
    if(message?.fileId) {
      await deleteRecord(message?.fileId);
    }
    dispatch(removeWaitingMessage(message.id));
    setMessageList((prevList) => prevList.filter(item => item.id !== messageId));
  }
  
  const renderMessages = () => {
    return (
      <>
        {loading && (
          <Box textAlign="center" mb={2}>
            <CircularProgress size={20} />
          </Box>
        )}

        <MessageList
          messageList={messageList}
          loading={loading}
          hasMore={hasMore}
          page={page}
          setPage={setPage}
          item={item}
          userAvatar={userAvatar}
          lastMessageRef={lastMessageRef}
          onResend={onResend}
          removeMessage={removeMessage}
        />
      </>
    );
  };


  return (
    <div className="chat-window">
      <ChatHeader
        isNew={isNew}
        item={item}
        staticMessageList={staticMessageList}
        onHide={onHide}
        onClose={onClose}
      />

      <div
        className="chat-window-main"
        style={{
          maxHeight: messageList ? '400px' : 'unset',
          pointerEvents: loading ? 'none' : 'all',
        }}
      >
        {isNew && renderSelect()}
        {!isNew && renderMessages()}
      </div>
      {!isNew && <ChatWindowFooter onSubmit={handleSubmit} id={item.id} />}
    </div>
  );
};

export default memo(ChatWindow);
