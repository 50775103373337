import { CircularProgress } from "@material-ui/core";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { useLanguage } from "app/shared/hooks";
import { formatString } from "app/utils/string-formatter";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import ArcGauge from "../ArcGauge";
import CircularGauge from "../CircularGauge";
import HorizontalGauge from "../HorizontalGauge";
import VerticalGauge from "../VerticalGauge";

import { DATE_TIME_FORMAT } from "app/constant/date-time-format";
import { NON_TABLE_ALARM_COLOR } from "app/shared/constants/dashboard";
import { invertColor } from "app/utils/utils";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";

const DirectionCard = (props) => {
  const [language] = useLanguage();
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_GAUGE_CHART" />
  );
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;

  const mainColor = props.data?.dashboard_gauge_chart?.main_color || "#fff";
  const dataAlarm =
    props.data?.dashboard_gauge_chart?.parameter?.data_alarm || [];
  const [backgroundColor, setBackgroundColor] = useState();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  React.useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      const parameter = dashboardData?.parameterDatas?.at(0);

      if (parameter?.dataPointDtos?.length > 0) {
        const lastDataPoint = parameter.dataPointDtos.at(-1); // get last value

        const parmeter_name =
          language === "en"
            ? props.data?.dashboard_gauge_chart?.parameter?.name
            : props.data?.dashboard_gauge_chart?.parameter?.name_vi;

        let min, max;
        if (props.data.dashboard_gauge_chart.range_type === "STATES") {
          /* Range type = STATES thì có 2 trường hợp
          - TH1: Value thuộc Alarm thì lấy min, max của alarm
          - TH2: Value không thuộc alarm nào thì min = 0 và max = value * 2 */

          if (lastDataPoint?.isAlarm) {
            min = lastDataPoint?.min_value || 0;
            max =
              lastDataPoint?.max_value ||
              Math.ceil(Number(lastDataPoint?.value) * 2);
          } else {
            min = 0;
            max = Math.ceil(Number(lastDataPoint?.value) * 2);
          }
        } else {
          min = props.data.dashboard_gauge_chart?.range_min || 0;
          max =
            props.data.dashboard_gauge_chart?.range_max ||
            Math.ceil(Number(lastDataPoint?.value) * 2);
        }

        let value = lastDataPoint?.value || 0;
        value = Number(value)?.toFixed(2);
        const data = {
          ...props.data.dashboard_gauge_chart,
          min_value: min,
          max_value: max,
          unit: props.data?.dashboard_gauge_chart?.parameter?.unit,
          format:
            props.data?.dashboard_gauge_chart?.parameter?.parameter_format_text,
          name: parmeter_name,
          value: isNaN(value) ? 0 : value,
          date: moment(lastDataPoint.eventTime).format(DATE_TIME_FORMAT),
          label: lastDataPoint && lastDataPoint[`name_${language}`],
        };

        if (lastDataPoint?.isAlarm) {
          dataAlarm?.forEach((alarm) => {
            if (alarm.id === lastDataPoint?.alarmId) {
              setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
            }
          });
        } else {
          setBackgroundColor();
        }

        setData((pre) => ({
          ...pre,
          ...data,
        }));
      }
    };

    if (props.data && props.data.dashboard_gauge_chart) {
      initData();
    }
  }, [props["data"]]);

  //Data
  React.useEffect(() => {
    if (
      props.data?.dashboard_gauge_chart &&
      data?.parameter_id &&
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId
    ) {
      if (data?.parameter_id === props.realTimeData?.paramId) {
        let dataValue = props.realTimeData?.value; // get last value

        let min, max;
        if (props.data?.dashboard_gauge_chart?.range_type === "STATES") {
          /* Range type = STATES thì có 2 trường hợp
          - TH1: Value thuộc Alarm thì lấy min, max của alarm
          - TH2: Value không thuộc alarm nào thì min = 0 và max = value * 2 */

          // if (isAlarm) {
          //   min = props.realTimeData?.min_value || 0;
          //   max = props.realTimeData?.max_value || Math.ceil(Number(dataValue) * 2);
          // }
          // else {

          // }
          min = 0;
          max = Math.ceil(Number(dataValue) * 2);
        } else {
          min = props.data.dashboard_gauge_chart?.range_min || 0;
          max =
            props.data.dashboard_gauge_chart?.range_max ||
            Math.ceil(Number(dataValue) * 2);
        }
        dataValue = Number(dataValue).toFixed(2);
        const newData = {
          ...data,
          min_value: min,
          max_value: max,
          format:
            props.data?.dashboard_gauge_chart?.parameter?.parameter_format_text,
          value: isNaN(dataValue) ? 0 : dataValue,
          label: "", //props.realTimeData?.[`name_${language}`] || '',
          date:
            moment(props.realTimeData?.eventTime).format(DATE_TIME_FORMAT) ||
            data?.date,
        };
        setData((pre) => ({
          ...pre,
          ...newData,
        }));
      }
    }
  }, [
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
    data?.parameter_id,
  ]);

  // Alarm
  React.useEffect(() => {
    if (
      props.data?.dashboard_gauge_chart &&
      data?.parameter_id &&
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId
    ) {
      if (data?.parameter_id === props.realTimeDataAlarm?.paramId) {
        let dataValue = props.realTimeDataAlarm?.value; // get last value

        let min, max;
        if (props.data?.dashboard_gauge_chart?.range_type === "STATES") {
          min = props.realTimeDataAlarm?.min_value || 0;
          max =
            props.realTimeDataAlarm?.max_value ||
            Math.ceil(Number(dataValue) * 2);
        } else {
          min = props.data.dashboard_gauge_chart?.range_min || 0;
          max =
            props.data.dashboard_gauge_chart?.range_max ||
            Math.ceil(Number(dataValue) * 2);
        }
        dataValue = Number(dataValue).toFixed(2);
        const newData = {
          ...data,
          min_value: min,
          max_value: max,
          format:
            props.data?.dashboard_gauge_chart?.parameter?.parameter_format_text,
          value: isNaN(dataValue) ? 0 : dataValue,
          label: props.realTimeDataAlarm?.[`name_${language}`] || "",
          color: props.data?.dashboard_gauge_chart?.alarm_color,
          date:
            moment(props.realTimeDataAlarm?.eventTime).format(
              DATE_TIME_FORMAT
            ) || data?.date,
        };

        dataAlarm?.forEach((alarm) => {
          if (alarm.id === props.realTimeDataAlarm?.alarmId) {
            setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
          }
        });

        setData((pre) => ({
          ...pre,
          ...newData,
        }));
      }
    }
  }, [
    props.realTimeDataAlarm?.eventTime,
    props.realTimeDataAlarm?.paramId,
    data?.parameter_id,
  ]);

  return (
    <div
      className="gauge-chart-card dashboard-item-card"
      style={{
        backgroundColor: data?.parameter_id
          ? (backgroundColor ?? mainColor) || "#3699ff"
          : "#ffffff",
      }}
    >
      {showHeader && (
        <p
          className="gauge-chart-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <DonutLargeIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}

      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div className="gauge-chart-card-body">
          {data?.parameter_id ? (
            <>
              {data?.chart_type === "HORIZONTAL" && (
                <HorizontalGauge
                  minValue={data?.min_value}
                  maxValue={data?.max_value}
                  unit={data?.unit}
                  showLines={data?.show_line}
                  showNumbers={data?.show_number}
                  value={formatString(data?.format, data?.value)}
                  label={data?.label}
                  color={(backgroundColor ?? mainColor) || "#3699ff"}
                  showHeader={showHeader}
                />
              )}

              {data?.chart_type === "VERTICAL" && (
                <VerticalGauge
                  minValue={data?.min_value}
                  maxValue={data?.max_value}
                  unit={data?.unit}
                  showLines={data?.show_line}
                  showNumbers={data?.show_number}
                  value={formatString(data?.format, data?.value)}
                  label={data?.label}
                  color={(backgroundColor ?? mainColor) || "#3699ff"}
                  showHeader={showHeader}
                />
              )}

              {data?.chart_type === "ARC" && (
                <ArcGauge
                  minValue={data?.min_value}
                  maxValue={data?.max_value}
                  unit={data?.unit}
                  showLines={data?.show_line}
                  showNumbers={data?.show_number}
                  value={formatString(data?.format, data?.value)}
                  label={data?.label}
                  color={(backgroundColor ?? mainColor) || "#3699ff"}
                  showHeader={showHeader}
                />
              )}

              {data?.chart_type === "CIRCULAR" && (
                <CircularGauge
                  minValue={data?.min_value}
                  maxValue={data?.max_value}
                  unit={data?.unit}
                  showLines={data?.show_line}
                  showNumbers={data?.show_number}
                  value={formatString(data?.format, data?.value)}
                  label={data?.label}
                  color={(backgroundColor ?? mainColor) || "#3699ff"}
                  showHeader={showHeader}
                />
              )}

              <div
                className="dash-title-timestamp"
                style={{
                  color: invertColor(
                    (backgroundColor ?? mainColor) || "#3699ff"
                  ),
                }}
              >
                <span>
                  <FormattedMessage id="CLIENT.DASHBOARD.LAST_RECORDED_TIME" />{" "}
                  {`: ${data?.date}`}
                </span>
              </div>
            </>
          ) : (
            <div
              className="no-data-area"
              style={{
                color: invertColor((backgroundColor ?? mainColor) || "#3699ff"),
              }}
            >
              <p className="no-data-label">
                <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
              </p>
            </div>
          )}
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default DirectionCard;
