import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ListItem, Collapse, Box, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import RoomIcon from "@material-ui/icons/Room";
import MapIcon from "@material-ui/icons/Map";
import FolderIcon from "@material-ui/icons/FolderOpen";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { FormattedMessage, useIntl } from "react-intl";
import swal from "sweetalert";
import workspaceApi from "app/api/workspaceApi";
import stationsApi from "app/api/stationApi";
import mapApi from "app/api/mapApi";
import workspaceDashboard from "app/api/workspaceDashboardApi";
import SettingsModal from "app/pages/Dashboard/components/Modals/SettingsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentDashboard,
  setActiveSubItem,
} from "app/redux/actions/dashboardActions";
import MapSettingsModal from "app/pages/Dashboard/components/Modals/MapSettingsModal";
import { map, reverse, first, last } from "lodash";
import userGuide from "app/api/userGuide";
import FolderOpen from "assets/images/folder-open.svg";
import ActiveFolderOpen from "assets/images/active-folder-open.svg";
import FolderModal from "../FolderModal";
import { shallowEqual } from "react-intl/src/utils";
import MoveStationModal from "app/pages/Dashboard/components/Modals/MoveStationModal";
import { useLanguage } from "app/shared/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoveToPlaceIcon from "@material-ui/icons/ImportExport";
import SettingsIcon from "@material-ui/icons/Settings";
import RenameIcon from "@material-ui/icons/Spellcheck";
import AddIcon from "@material-ui/icons/Add";
import FileExportIcon from "assets/icon/dashboard-icon/file-export.icon";
import MoveDownIcon from "@material-ui/icons/ArrowDownward";
import MoveUpIcon from "@material-ui/icons/ArrowUpward";

import "./styles.css";
import RenderMenu from "./components/render-menu-item.component";
import MenuRenameModal from "app/pages/Dashboard/components/Modals/MenuRenameModal";
import {
  ADD_NEW_DASHBOARD,
  ADD_NEW_MAP,
  RENAME_DASHBOARD,
  RENAME_MAP,
} from "../constants";
import useDetectDevice from "app/shared/hooks/useDetectDevice";
import { triggerSidebar } from "app/redux/actions/sidebar.action";

const defaultActionsMenu = {
  workspaceList: null,
  workspace: null,
  dashboardList: null,
  stationList: null,
  station: null,
  dashboard: null,
  mapList: null,
  categoryList: null,
  folderList: null,
  userGuidesList: null,
};

export default function WorkspaceSidebarItem(props) {
  const {
    id,
    link,
    itemKey,
    currentPath,
    renderKey,
    activeIcon,
    icon,
    name,
    subs,
    workspaces,
    dashboards,
    renderNavs,
    iconType = "",
    actionType = "",
    reloadData,
    token,
    workspaceId,
    active,
    isView,
    canAddNew,
    renameNavId,
    maps,
    setWorkpaces,
    renameMapId,
    userGuides,
    order,
    sourceData,
    categoryList,
    // activeSubItem,
    // setActiveSubItem,
    allWorkspaces,
    canEdit,
    canDelete,
    canAddNewDashBoard,
    disableMoveDelete,
    showsLeftArrow = true,
  } = props;

  const intl = useIntl();
  const [itemHover, setItemHover] = useState(null);
  const [actionsMenu, setActionsMenu] = useState(defaultActionsMenu);
  const [language] = useLanguage();
  const [activeItems, setActiveItems] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [rename, setRename] = useState(null);
  const [renameValue, setRenameValue] = useState("");
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const [renameLoading, setRenameLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showMapSettings, setShowMapSettings] = useState(false);
  const [moveFolderId, setMoveFolderId] = useState(null);
  const [showMoveStation, setShowMoveStation] = useState(false);
  const [modalRename, setModalRename] = useState({
    open: false,
    title: "CLIENT.GENERAL.MENU_ADD_NEW_DASHBOARD",
    label: "CLIENT.DASHBOARD.LABEL.RENAME",
    placeholder: "CLIENT.DASHBOARD.PLACEHOLDER.RENAME",
    type: ADD_NEW_DASHBOARD,
  });
  // const [activeSubItem, setActiveSubItem] = useState("");
  const dispatch = useDispatch();
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual,
  );

  const { deviceType } = useDetectDevice();

  useEffect(() => {
    if (renameNavId) {
      setRename(renameNavId);
      setRenameValue(
        intl.formatMessage({
          id: "CLIENT.DASHBOARD.PAGE_TITLE",
        }),
      );
    }
  }, [renameNavId]);
  useEffect(() => {
    if (renameMapId) {
      setRename(renameMapId);
      setRenameValue(
        intl.formatMessage({
          id: "CLIENT.DASHBOARD.MAP",
        }),
      );
    }
  }, [renameMapId]);
  const handleMenuClick = (event, menu) => {
    event.preventDefault();
    event.stopPropagation();
    setActionsMenu({
      ...defaultActionsMenu,
      [menu]: event.currentTarget,
    });
  };
  const handleMenuClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setActionsMenu(defaultActionsMenu);
  };

  const getActiveMenu = (parent, items) => {
    for (let item of items) {
      const currentPath = location.pathname.split("?")[0];
      if (item.link.split("?")[0] === currentPath) {
        if (item.actionType === "station") {
          const workspaceId = "-parent-3";
          const id = `${renderKey}-${parent.key}-${parent.id}`;
          const staticId = `stations-${parent.key}-${parent.id}`;
          const tempArr = [...activeItems];
          const index = tempArr.findIndex((item) => item === id);
          if (index === -1) {
            setActiveItems((prevState) => [
              ...prevState,
              id,
              workspaceId,
              staticId,
            ]);
          }
          break;
        } else if (item.actionType === "dashboard") {
          const workspaceId = "-parent-3";
          const id = `${renderKey}-${parent.key}-${parent.id}`;
          const staticId = `dashboard-${parent.key}-${parent.id}`;
          const tempArr = [...activeItems];
          const index = tempArr.findIndex((item) => item === id);
          if (index === -1) {
            setActiveItems((prevState) => [
              ...prevState,
              id,
              workspaceId,
              staticId,
            ]);
          }
          break;
        } else {
          const id = `${renderKey}-${parent.key}-${parent.id}`;
          const tempArr = [...activeItems];
          const index = tempArr.findIndex((item) => item === id);
          if (index === -1) {
            setActiveItems((prevState) => [...prevState, id]);
          }
          break;
        }
      }
      if (item.subs?.length > 0) {
        getActiveMenu(item, item.subs);
      }
      if (item.dashboards?.length > 0) {
        getActiveMenu(item, item.dashboards);
      }
    }
  };

  useEffect(() => {
    for (let item of workspaces) {
      if (item.subs?.length > 0) {
        getActiveMenu(item, item.subs);
      }
      if (item.dashboards?.length > 0) {
        getActiveMenu(item, item.dashboards);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  const handleHoverItem = (id) => {
    if (id) {
      setItemHover(id);
    } else {
      setItemHover(null);
    }
  };

  const fetchNewMapsDataAndReloadData = async (renameMapId) => {
    try {
      const resp = await mapApi.getListMapByWorkspaceId(workspaceId, token);
      if (resp.success) {
        const newWorkspace = workspaces.map((item) => {
          if (item.code === "workspaces") {
            return {
              ...item,
              subs: map(item.subs, (workspaceItem) => {
                if (workspaceItem.id === workspaceId) {
                  return {
                    ...workspaceItem,
                    maps: map(reverse(resp.data), (map) => ({
                      id: map.id,
                      name: map[`name_${language}`],
                      iconType: "maps",
                      link: `/workspaces/${workspaceId}/maps/${map.id}`,
                      actionType: "map",
                      key: "sub-sub-children",
                      code: "maps",
                      workspaceId: workspaceId, // important
                      subs: [],
                      renameMapId,
                      canEdit: canEdit,
                      canDelete: canDelete,
                    })),
                  };
                }
                return workspaceItem;
              }),
            };
          }
          return item;
        });
        setWorkpaces(newWorkspace);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchNewUserGuideDataAndReloadData = async () => {
    try {
      const resp = await userGuide.getAllCategory(token);
      if (resp.success) {
        const newWorkspace = workspaces.map((item) => {
          if (item.code === "content") {
            return {
              ...item,
              subs: map(resp.data, (category) => ({
                id: category["id"],
                icon: FolderOpen,
                activeIcon: ActiveFolderOpen,
                name: category["name_en"],
                link: `#`,
                key: "children",
                code: "content",
                actionType: "folder",
                order: category.apply_order,
                userGuides: map(category["user_guide"], (userGuide) => ({
                  id: userGuide.id,
                  name: userGuide["name_en"],
                  iconType: "userGuides",
                  icon: FolderOpen,
                  activeIcon: ActiveFolderOpen,
                  link: `/category/${category.id}/user-guide/${userGuide.id}`,
                  actionType: "userGuides",
                  key: "non-icon-sub-sub-children",
                  code: "userGuides",
                  workspaceId: workspaceId, // important
                  order: userGuide.apply_order,
                  subs: [],
                  categoryList: resp.data,
                  isActive: false,
                })).sort((a, b) => a.order - b.order),
              })).sort((a, b) => a.order - b.order),
            };
          }
          return item;
        });
        setWorkpaces(newWorkspace);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handleClick = async (id) => {
    const tempArr = [...activeItems];
    const index = tempArr.findIndex((item) => item === id);
    if (index === -1) {
      setActiveItems((prevState) => [...prevState, id]);
      if (id.split("-")[0] === "content" && id.split("-")[1] === "parent") {
        await fetchNewUserGuideDataAndReloadData();
      }
      if (id.split("-")[0] === "maps") {
        await fetchNewMapsDataAndReloadData();
      }
      // if (id.split("-")[0] === "userGuide") {
      //   await fetchNewUserGuidePostDataAndReloadData(id.split("-")[2])
      // }
    } else {
      tempArr.splice(index, 1);
      setActiveItems(tempArr);
    }
  };

  const handleClickLink = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    handleClick(id);
  };

  const onShowPopup = () => {
    const wrapper = document.createElement("div");

    ReactDOM.render(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>,
      wrapper,
    );

    swal({
      title: `${intl.formatMessage({ id: "GENERAL.DELETING" })}...`,
      content: wrapper.firstChild,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });

    return () => {
      swal.close();
      wrapper.remove();
    };
  };

  const handleDeleteWorkspace = (e, id) => {
    e.preventDefault();
    setActionsMenu(defaultActionsMenu);
    swal({
      title: intl.formatMessage({
        id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteWorkspace = async () => {
          const closePopup = onShowPopup();
          try {
            const res = await workspaceApi.deleteworkspace(id, token);
            closePopup();
            if (res?.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              reloadData();
              const currentPath = location.pathname.split("?")[0];
              if (currentPath === `/workspaces/edit/${id}`) {
                history.push("/workspaces");
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            closePopup();
            swal({
              title: intl.formatMessage({
                id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.WORKSPACE_MANAGEMENT.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteWorkspace();
      }
    });
  };

  const handleDeleteStation = (e, id) => {
    e.preventDefault();
    setActionsMenu(defaultActionsMenu);

    swal({
      title: intl.formatMessage({
        id: "CLIENT.STATION_MANAGEMENT.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.STATION_MANAGEMENT.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteWorkspace = async () => {
          try {
            const res = await stationsApi.deleteStation(id, token);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.STATION_MANAGEMENT.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.STATION_MANAGEMENT.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              reloadData();
              const currentPath = location.pathname.split("?")[0];
              reloadData();
              if (currentPath === `/station/edit/${id}`) {
                history.push(`/workspaces/edit/${workspaceId}`);
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.STATION_MANAGEMENT.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.STATION_MANAGEMENT.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.STATION_MANAGEMENT.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.STATION_MANAGEMENT.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteWorkspace();
      }
    });
  };

  const handleDeleteDashboard = (e, id) => {
    e.preventDefault();
    setActionsMenu(defaultActionsMenu);
    swal({
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.DASHBOARD.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteWorkspace = async () => {
          try {
            const res = await workspaceDashboard.deleteDashboard(id, token);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              const currentPath = `/dashboards/${
                location.pathname.split("/")[
                  location.pathname.split("/").length - 1
                ]
              }`;
              reloadData();
              if (currentPath === `/dashboards/${id}`) {
                history.push(`/workspaces/edit/${workspaceId}`);
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.DASHBOARD.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.DASHBOARD.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteWorkspace();
      }
    });
  };

  const handleDeleteMap = (e, id) => {
    e.preventDefault();
    setActionsMenu(defaultActionsMenu);
    swal({
      title: intl.formatMessage({
        id: "CLIENT.MAP.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.MAP.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteMap = async () => {
          try {
            const res = await mapApi.deleteMap(id, token);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.MAP.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.MAP.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              const currentPath = `/maps/${
                location.pathname.split("/")[
                  location.pathname.split("/").length - 1
                ]
              }`;
              fetchNewMapsDataAndReloadData();
              if (currentPath === `/maps/${id}`) {
                history.push(`/workspaces/edit/${workspaceId}`);
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.MAP.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.MAP.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.MAP.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.MAP.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteMap();
      }
    });
  };

  const handleAction = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    if (path) {
      history.push(path);
      setActionsMenu(defaultActionsMenu);
    }
  };

  const handleAddNewDashboard = async (event, dashboardName) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const data = {
        workspace_id: id,
        ["dashboard_name_" + language]: dashboardName.trim(),
      };
      const resp = await workspaceDashboard.createDashboard(data, token);
      if (resp.success) {
        setActionsMenu(defaultActionsMenu);
        const tempArr = [...activeItems];
        const index = tempArr.findIndex(
          (item) => item === `dashboard-${itemKey}-${id}`,
        );
        if (index === -1) {
          handleClick(`dashboard-${itemKey}-${id}`);
        }
        reloadData({
          renameNavId: resp.data,
        });
        history.push(`/workspaces/${id}/dashboards/${resp.data}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleAddNewMap = async (event, mapName) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const data = {
        workspace_id: id,
        ["map_name_" + language]: mapName.trim(),
      };
      const resp = await mapApi.createMap(data, token);
      if (resp.success) {
        setActionsMenu(defaultActionsMenu);
        const tempArr = [...activeItems];
        const index = tempArr.findIndex(
          (item) => item === `maps-${itemKey}-${id}`,
        );
        if (index === -1) {
          handleClick(`maps-${itemKey}-${id}`);
        }

        await fetchNewMapsDataAndReloadData(resp.data);
        history.push(`/workspaces/${id}/maps/${resp.data}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleAddNewFolder = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const data = {
        name_en: "Thư Mục 1",
        name_vi: "Thư Mục 1",
        description: "123456",
        apply_order: subs.length + 1,
      };
      const resp = await userGuide.addUserGuideCategory(token, data);
      if (resp.success) {
        setActionsMenu(defaultActionsMenu);
        const tempArr = [...activeItems];
        const index = tempArr.findIndex(
          (item) => item === `${renderKey}-${itemKey}-${id}`,
        );
        if (index === -1) {
          handleClick(`${renderKey}-${itemKey}-${id}`);
        }

        await fetchNewUserGuideDataAndReloadData();
        // history.push(`/category/${resp.data.id}/user-guide/${}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleAddNewUserGuideFolder = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const data = {
        name_en: "Thư Mục 1.1",
        name_vi: "Thư Mục 1.1",
        description: "Thư mục con",
        apply_order: userGuides.length + 1,
      };
      const resp = await userGuide.addUserGuideByCategoryId(token, data, id);
      if (resp.success) {
        setActionsMenu(defaultActionsMenu);
        const tempArr = [...activeItems];
        const index = tempArr.findIndex(
          (item) => item === `userGuide-${itemKey}-${id}`,
        );
        if (index === -1) {
          handleClick(`userGuide-${itemKey}-${id}`);
        }

        await fetchNewUserGuideDataAndReloadData();
        history.push(`/category/${id}/user-guide/${resp.data.id}`);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRename = (event, id) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setRenameValue(name);
    setRename(id);
    setActionsMenu(defaultActionsMenu);
  };

  const handleBlur = () => {
    setRenameValue("");
    setRename(null);
  };

  const handleNameChange = (e) => {
    setRenameValue(e.target.value);
  };

  const handleRenameData = async (menuType, nameValueModal) => {
    const renameData =
      typeof nameValueModal === "string"
        ? nameValueModal.trim()
        : renameValue.trim();

    try {
      setRenameLoading(true);
      const data = {
        ["name_" + language]: renameData,
      };
      let resp;
      switch (menuType) {
        case "dashboard": {
          resp = await workspaceDashboard.updateDashboard(id, data, token);
          break;
        }
        case "folder": {
          resp = await userGuide.updateUserGuideCategory(id, data, token);
          break;
        }
        case "userGuides": {
          resp = await userGuide.updateUserGuide(id, data, token);
          break;
        }
        default: {
          resp = await mapApi.updateMap(id, data, token);
          break;
        }
      }
      if (resp.success) {
        setRenameValue("");
        setRename(null);
        if (menuType === "map") {
          await fetchNewMapsDataAndReloadData();
        } else if (menuType === "folder" || menuType === "userGuides") {
          await fetchNewUserGuideDataAndReloadData();
        } else {
          dispatch(
            updateCurrentDashboard({
              id,
              name: renameData,
            }),
          );
          reloadData();
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setRenameLoading(false);
    }
  };

  const handleMoveFolder = async (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const itemIndex = sourceData.findIndex((item) => item.id === id);
      const neighborItem =
        sourceData?.[type === "up" ? itemIndex - 1 : itemIndex + 1];
      const data = {
        apply_order: type === "up" ? order - 1 : order + 1,
      };
      const neighborData = {
        apply_order:
          type === "up" ? neighborItem.order + 1 : neighborItem.order - 1,
      };
      const [resp1, resp2] = await Promise.all([
        userGuide.updateUserGuideCategory(id, data, token),
        userGuide.updateUserGuideCategory(neighborItem.id, neighborData, token),
      ]);
      if (resp1.success && resp2.success) {
        await fetchNewUserGuideDataAndReloadData();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteFolderCategory = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setActionsMenu(defaultActionsMenu);
    swal({
      title: intl.formatMessage({
        id: "CLIENT.USER_GUIDE.FOLDER.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.USER_GUIDE.FOLDER.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteCategory = async () => {
          try {
            const res = await userGuide.deleteUserGuideCategory(id);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              fetchNewUserGuideDataAndReloadData();
              const currentPath = location.pathname.split("?")[0];
              if (currentPath?.includes(`/category/${id}`)) {
                history.push("/profile/edit");
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteCategory();
      }
    });
  };

  const handleDeleteFolder = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setActionsMenu(defaultActionsMenu);
    swal({
      title: intl.formatMessage({
        id: "CLIENT.USER_GUIDE.FOLDER.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.USER_GUIDE.FOLDER.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteCategory = async () => {
          try {
            const res = await userGuide.deleteUserGuide(id);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              fetchNewUserGuideDataAndReloadData();
              const currentPath = location.pathname.split("?")[0];
              if (currentPath?.includes(`/user-guide/${id}`)) {
                history.push("/profile/edit");
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.USER_GUIDE.FOLDER.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteCategory();
      }
    });
  };

  const handleMoveFolderUserGuide = async (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const itemIndex = sourceData.findIndex((item) => item.id === id);
      const neighborItem =
        sourceData?.[type === "up" ? itemIndex - 1 : itemIndex + 1];
      const data = {
        apply_order: type === "up" ? order - 1 : order + 1,
      };
      const neighborData = {
        apply_order:
          type === "up" ? neighborItem.order + 1 : neighborItem.order - 1,
      };
      const [resp1, resp2] = await Promise.all([
        userGuide.updateUserGuide(id, data, token),
        userGuide.updateUserGuide(neighborItem.id, neighborData, token),
      ]);
      if (resp1.success && resp2.success) {
        await fetchNewUserGuideDataAndReloadData();
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleMoveFolderToAnotherPlace = (event, id) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setMoveFolderId(id);
    setActionsMenu(defaultActionsMenu);
  };

  const handleKeyDown = async (e, menuType) => {
    if (e.key === "Escape") {
      handleBlur();
    }
    if (e.key === "Enter" && renameValue.trim()) {
      await handleRenameData(menuType);
    }
  };

  const renderIcon = (type, id, active = false) => {
    let isActive = active;

    if (!active) {
      isActive =
        itemHover === id ||
        activeItems.includes(id) ||
        (actionType === "workspaces-children" &&
          link.split("?")[0] === location.pathname.split("?")[0]) ||
        link === currentPath;
    }

    switch (type) {
      case "dashboard":
        return <DashboardIcon style={{ color: "#ffc700" }} />;
      case "station":
        return <RoomIcon style={{ color: "#50cd89" }} />;
      case "maps":
        return <MapIcon style={{ color: "#3699FF" }} />;

      case "userGuide":
        return <FolderIcon style={{ color: "#3699FF" }} />;

      default:
        return (
          <>
            <div
              className="main-icon"
              style={{
                backgroundImage: isActive
                  ? `url("${activeIcon}")`
                  : `url("${icon}")`,
              }}
            ></div>
          </>
        );
    }
  };

  const preventClickAction = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const toggleSettings = (event, isMap) => {
    event.preventDefault();
    event.stopPropagation();
    setActionsMenu(defaultActionsMenu);
    if (isMap) {
      setShowMapSettings(!showMapSettings);
    } else {
      setShowSettings(!showSettings);
    }
  };

  const toggleMoveStation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // setActionsMenu(defaultActionsMenu);
    // if (isMap) {
    //   setShowMapSettings(!showMapSettings);
    // } else {
    //   setShowSettings(!showSettings);
    // }
    handleMenuClose(event);
    setShowMoveStation(!showMoveStation);
  };

  const handleSubmitRenameModal = async (e, nameValueModal) => {
    let redirect = null;

    switch (modalRename.type) {
      case ADD_NEW_DASHBOARD:
        handleAddNewDashboard(e, nameValueModal);
        break;

      case ADD_NEW_MAP:
        handleAddNewMap(e, nameValueModal);
        break;

      case RENAME_DASHBOARD:
        await handleRenameData("dashboard", nameValueModal);
        redirect = "dashboards";
        break;

      case RENAME_MAP:
        await handleRenameData("maps", nameValueModal);
        redirect = "maps";
        break;

      default:
        break;
    }

    setRenameValue(nameValueModal);
    if (!redirect) {
      dispatch(triggerSidebar());
      return;
    }
    const waitRenameComplete = setTimeout(() => {
      dispatch(triggerSidebar());
      history.push(`/workspaces/${workspaceId}/${redirect}/${id}`);
      clearTimeout(waitRenameComplete);
    }, 500);
  };

  const renderActions = (actionType) => {
    switch (actionType) {
      case "workspaces":
        return canAddNew ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "workspaceList")}
            open={!!actionsMenu.workspaceList}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.workspaceList}
            items={[
              {
                visible: true,
                messageId: "CLIENT.GENERAL.MENU_ADD_NEW_WORKSPACE",
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleAction(e, "/workspaces/add"),
              },
            ]}
          />
        ) : null;
      case "workspaces-children":
        return (
          !(
            [
              "Workspace for Data Sharing",
              "Khu vực trạm chia sẻ dữ liệu",
            ].includes(name) &&
            !canEdit &&
            !canDelete
          ) && (
            <RenderMenu
              deviceType={deviceType}
              handleMenuClick={(e) => handleMenuClick(e, "workspace")}
              open={!!actionsMenu.workspace}
              handleMenuClose={handleMenuClose}
              anchorEl={actionsMenu.workspace}
              items={[
                {
                  visible: canEdit,
                  messageId: "CLIENT.GENERAL.MENU_EDIT",
                  Icon: () => <EditIcon htmlColor="black" />,
                  iconColor: "black",
                  handleAction: (e) =>
                    handleAction(e, `/workspaces/edit/${id}`),
                },
                {
                  visible: ![
                    "Workspace for Data Sharing",
                    "Khu vực trạm chia sẻ dữ liệu",
                  ].includes(name),
                  Icon: () => <FileExportIcon htmlColor="black" />,
                  iconColor: "black",
                  messageId: "CLIENT.GENERAL.MENU_EXPORT_DATA",
                  handleAction: (e) =>
                    handleAction(e, `/workspaces/${id}/export-data`),
                },
                {
                  visible: canDelete,
                  divider: canDelete,
                  Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                  iconColor: "#FB1F1F",
                  messageId: "CLIENT.GENERAL.MENU_DELETE",
                  handleAction: (e) => handleDeleteWorkspace(e, id),
                },
              ]}
            />
          )
        );
      case "dashboards":
        return canAddNewDashBoard ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "dashboardList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.dashboardList}
            open={!!actionsMenu.dashboardList}
            items={[
              {
                visible: true,
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",
                messageId: "CLIENT.GENERAL.MENU_ADD_NEW_DASHBOARD",
                handleAction: (e) => {
                  return deviceType === "desktop"
                    ? handleAddNewDashboard(
                        e,
                        intl.formatMessage({
                          id: "CLIENT.DASHBOARD.PAGE_TITLE",
                        }),
                      )
                    : setModalRename({
                        open: true,
                        title: "CLIENT.GENERAL.MENU_ADD_NEW_DASHBOARD",
                        label: "CLIENT.DASHBOARD.LABEL.RENAME",
                        placeholder: "CLIENT.DASHBOARD.PLACEHOLDER.RENAME",
                        type: ADD_NEW_DASHBOARD,
                      });
                },
              },
            ]}
          />
        ) : null;

      case "stations":
        return canAddNew &&
          ![
            "Workspace for Data Sharing",
            "Khu vực trạm chia sẻ dữ liệu",
          ].includes(name) ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "stationsList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.stationsList}
            open={!!actionsMenu.stationsList}
            items={[
              {
                visible: true,
                messageId: "CLIENT.GENERAL.MENU_ADD_NEW_STATION",
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleAction(e, `/station/add/${id}`),
              },
            ]}
          />
        ) : null;

      case "station":
        return canEdit || canDelete ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "station")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.station}
            open={!!actionsMenu.station}
            items={[
              {
                visible: canEdit,
                messageId: "CLIENT.GENERAL.MENU_EDIT",
                Icon: () => <EditIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleAction(e, `/station/edit/${id}`),
              },
              {
                visible: true,
                disabled: disableMoveDelete,

                messageId: "CLIENT.GENERAL.MENU_MOVE",
                Icon: () => <MoveToPlaceIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => toggleMoveStation(e),
              },
              {
                visible: canDelete,
                divider: canDelete,
                Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                iconColor: "#FB1F1F",
                messageId: "CLIENT.GENERAL.MENU_DELETE",
                disabled: disableMoveDelete,
                handleAction: (e) => handleDeleteStation(e, id),
              },
            ]}
          />
        ) : null;
      case "dashboard":
        return canEdit || canDelete ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "dashboard")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.dashboard}
            open={!!actionsMenu.dashboard}
            items={[
              {
                visible: !isView && canEdit,
                messageId: "CLIENT.GENERAL.MENU_RENAME",
                Icon: () => <RenameIcon htmlColor="black" />,
                iconColor: "black",
                handleAction: (e) => {
                  return deviceType === "desktop"
                    ? handleRename(e, id)
                    : setModalRename({
                        open: true,
                        title: "CLIENT.DASHBOARD.HEADER.MODULE.RENAME",
                        label: "CLIENT.DASHBOARD.LABEL.RENAME",
                        placeholder: "CLIENT.DASHBOARD.PLACEHOLDER.RENAME",
                        type: RENAME_DASHBOARD,
                      });
                },
              },
              {
                visible: !isView && canEdit,

                messageId: "CLIENT.DASHBOARD.SETTINGS",
                Icon: () => <SettingsIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => toggleSettings(e),
              },
              {
                visible: !isView && canEdit && canDelete,
                divider: !isView && canEdit && canDelete,
                Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                iconColor: "#FB1F1F",
                messageId: "CLIENT.GENERAL.MENU_DELETE",
                handleAction: (e) => handleDeleteDashboard(e, id),
              },
            ]}
          />
        ) : null;
      case "maps":
        return canAddNewDashBoard ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "mapList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.mapList}
            open={!!actionsMenu.mapList}
            items={[
              {
                visible: true,
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",
                messageId: "CLIENT.GENERAL.MENU_ADD_NEW_MAP",
                handleAction: (e) => {
                  return deviceType === "desktop"
                    ? handleAddNewMap(
                        e,
                        intl.formatMessage({
                          id: "CLIENT.DASHBOARD.MAP",
                        }),
                      )
                    : setModalRename({
                        open: true,
                        title: "CLIENT.GENERAL.MENU_ADD_NEW_MAP",
                        label: "CLIENT.MAP.LABEL.RENAME",
                        placeholder: "CLIENT.MAP.PLACEHOLDER.RENAME",
                        type: ADD_NEW_MAP,
                      });
                },
              },
            ]}
          />
        ) : null;
      case "map":
        return canEdit || canDelete ? (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "dashboard")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.dashboard}
            open={!!actionsMenu.dashboard}
            items={[
              {
                visible: true,
                messageId:
                  !isView && canEdit
                    ? "CLIENT.GENERAL.MENU_EDIT"
                    : "CLIENT.GENERAL.MENU_VIEW",
                Icon: () => <EditIcon htmlColor="black" />,
                iconColor: !isView && canEdit ? "black" : null,
                handleAction: (e) =>
                  handleAction(e, `/workspaces/${workspaceId}/maps/${id}`),
              },
              {
                visible: !isView && canEdit,
                messageId: "CLIENT.GENERAL.MENU_RENAME",
                Icon: () => <RenameIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => {
                  return deviceType === "desktop"
                    ? handleRename(e, id)
                    : setModalRename({
                        open: true,
                        title: "CLIENT.MAP.MODAL.TITLE.RENAME",
                        label: "CLIENT.MAP.LABEL.RENAME",
                        placeholder: "CLIENT.MAP.PLACEHOLDER.RENAME",
                        type: RENAME_MAP,
                      });
                },
              },
              {
                visible: !isView && canEdit,
                messageId: "CLIENT.DASHBOARD.SETTINGS",
                Icon: () => <SettingsIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => toggleSettings(e, true),
              },
              {
                visible: !isView && canEdit && canDelete,
                divider: !isView && canEdit && canDelete,
                Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                iconColor: "#FB1F1F",
                messageId: "CLIENT.GENERAL.MENU_DELETE",
                handleAction: (e) => handleDeleteMap(e, id),
              },
            ]}
          />
        ) : null;
      case "content":
        return (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "categoryList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.categoryList}
            open={!!actionsMenu.categoryList}
            items={[
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.ADD_NEW",
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleAddNewFolder(e),
              },
            ]}
          />
        );
      case "folder":
        return (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "folderList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.folderList}
            open={!!actionsMenu.folderList}
            items={[
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.ADD_NEW",
                Icon: () => <AddIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleAddNewUserGuideFolder(e),
              },
              {
                visible: true,
                divider: true,
                messageId: "CLIENT.GENERAL.MENU_RENAME",
                Icon: () => <RenameIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleRename(e, id),
              },
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.MOVE_UP",
                disabled: first(sourceData).id === id,
                Icon: () => <MoveUpIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleMoveFolder(e, "up"),
              },
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.MOVE_DOWN",
                Icon: () => <MoveDownIcon htmlColor="black" />,
                iconColor: "black",
                disabled: last(sourceData).id === id,

                handleAction: (e) => handleMoveFolder(e, "down"),
              },
              {
                visible: true,
                divider: true,
                Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                iconColor: "#FB1F1F",
                messageId: "CLIENT.ROLE_ACTIONS.DELETE_LABEL",
                handleAction: (e) => handleDeleteFolderCategory(e),
              },
            ]}
          />
        );
      case "userGuides":
        return (
          <RenderMenu
            deviceType={deviceType}
            handleMenuClick={(e) => handleMenuClick(e, "userGuidesList")}
            handleMenuClose={handleMenuClose}
            anchorEl={actionsMenu.userGuidesList}
            open={!!actionsMenu.userGuidesList}
            items={[
              {
                visible: true,
                messageId: "CLIENT.GENERAL.MENU_RENAME",
                Icon: () => <RenameIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleRename(e, id),
              },
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.MOVE_UP",
                disabled: first(sourceData).id === id,
                Icon: () => <MoveUpIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleMoveFolderUserGuide(e, "up"),
              },
              {
                visible: true,
                messageId: "MENU.USER_GUIDE.MOVE_DOWN",
                Icon: () => <MoveDownIcon htmlColor="black" />,
                iconColor: "black",

                disabled: last(sourceData).id === id,

                handleAction: (e) => handleMoveFolderUserGuide(e, "down"),
              },
              {
                visible: true,
                divider: true,

                messageId: "MENU.USER_GUIDE.MOVE_TO",
                Icon: () => <MoveToPlaceIcon htmlColor="black" />,
                iconColor: "black",

                handleAction: (e) => handleMoveFolderToAnotherPlace(e, id),
              },
              {
                visible: true,
                divider: true,
                Icon: () => <DeleteIcon htmlColor="#FB1F1F" />,
                iconColor: "#FB1F1F",
                messageId: "CLIENT.ROLE_ACTIONS.DELETE_LABEL",
                handleAction: (e) => handleDeleteFolder(e),
              },
            ]}
          />
        );

      default:
        return;
    }
  };

  const handleCheckClick = async (e, menuType) => {
    e.preventDefault();
    e.stopPropagation();
    if (renameValue.trim()) {
      handleRenameData(menuType);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef, rename]);

  useEffect(() => {
    if (rename) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rename]);

  const handleClickOutside = (event) => {
    const { target } = event;
    if (!wrapperRef?.current?.contains(target)) {
      document.removeEventListener("click", handleClickOutside);
      handleBlur();
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleBlur();
  };

  const onWorkspacesListMenuClick = () => {
    dispatch(setActiveSubItem(`${renderKey}-${itemKey}-${id}`));
    if (deviceType !== "desktop") dispatch(triggerSidebar());
  };

  const renderItem = () => {
    if (renderKey === "content") {
      return userGuides && userGuides.length > 0 ? (
        <Collapse
          in={activeItems.includes(`${renderKey}-${itemKey}-${id}`)}
          timeout="auto"
          unmountOnExit
          className={active ? "" : "display-none"}
        >
          {renderNavs(userGuides, id)}
        </Collapse>
      ) : null;
    } else {
      return (
        <>
          {![
            "Workspace for Data Sharing",
            "Khu vực trạm chia sẻ dữ liệu",
          ].includes(name) && (
            <>
              <ListItem
                className={`
            ${itemKey} 
            static-navi
            ${
              activeItems.includes(`dashboard-${itemKey}-${id}`)
                ? "active-item"
                : ""
            }
          `}
                button
                onMouseEnter={() =>
                  handleHoverItem(`dashboard-${itemKey}-${id}`)
                }
                onMouseLeave={() => handleHoverItem()}
                onClick={() => handleClick(`dashboard-${itemKey}-${id}`)}
              >
                <ArrowRightIcon
                  className={`
              navi-icon 
              first-navi-icon 
              ${
                activeItems.includes(`dashboard-${itemKey}-${id}`)
                  ? "active-navi"
                  : ""
              }
            `}
                />
                <DashboardIcon style={{ color: "#ffc700" }} />
                <p>
                  <FormattedMessage id="CLIENT.GENERAL.MENU_DASHBOARDS" />
                </p>
                {renderActions("dashboards")}
              </ListItem>
              {dashboards && dashboards.length > 0 ? (
                <Collapse
                  in={activeItems.includes(`dashboard-${itemKey}-${id}`)}
                  timeout="auto"
                  unmountOnExit
                  className={active ? "" : "display-none"}
                >
                  {renderNavs(dashboards, id)}
                </Collapse>
              ) : null}
            </>
          )}
          <ListItem
            className={`
            ${itemKey} 
            static-navi
            ${
              activeItems.includes(`stations-${itemKey}-${id}`)
                ? "active-item"
                : ""
            }
          `}
            button
            onMouseEnter={() => handleHoverItem(`stations-${itemKey}-${id}`)}
            onMouseLeave={() => handleHoverItem()}
            onClick={() => handleClick(`stations-${itemKey}-${id}`)}
          >
            <ArrowRightIcon
              className={`
              navi-icon 
              first-navi-icon 
              ${
                activeItems.includes(`stations-${itemKey}-${id}`)
                  ? "active-navi"
                  : ""
              }
            `}
            />
            <RoomIcon style={{ color: "#50cd89" }} />
            <p>
              <FormattedMessage id="CLIENT.GENERAL.MENU_STATIONS" />
            </p>
            {renderActions("stations")}
          </ListItem>
          {subs && subs.length > 0 ? (
            <Collapse
              in={activeItems.includes(`stations-${itemKey}-${id}`)}
              timeout="auto"
              unmountOnExit
              className={active ? "" : "display-none"}
            >
              {renderNavs(subs, id)}
            </Collapse>
          ) : null}
          {![
            "Workspace for Data Sharing",
            "Khu vực trạm chia sẻ dữ liệu",
          ].includes(name) && (
            <>
              <ListItem
                className={`
            ${itemKey} 
            static-navi
            ${
              activeItems.includes(`maps-${itemKey}-${id}`) ? "active-item" : ""
            }
          `}
                button
                onMouseEnter={() => handleHoverItem(`maps-${itemKey}-${id}`)}
                onMouseLeave={() => handleHoverItem()}
                onClick={() => handleClick(`maps-${itemKey}-${id}`)}
              >
                <ArrowRightIcon
                  className={`
              navi-icon 
              first-navi-icon 
              ${
                activeItems.includes(`maps-${itemKey}-${id}`)
                  ? "active-navi"
                  : ""
              }
            `}
                />
                <MapIcon style={{ color: "#3699FF" }} />
                <p>
                  <FormattedMessage id="CLIENT.GENERAL.MENU_MAPS" />
                </p>
                {renderActions("maps")}
              </ListItem>
              {maps && maps.length > 0 ? (
                <Collapse
                  in={activeItems.includes(`maps-${itemKey}-${id}`)}
                  timeout="auto"
                  unmountOnExit
                  className={active ? "" : "display-none"}
                >
                  {renderNavs(maps, id)}
                </Collapse>
              ) : null}
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Link to={link}>
        <ListItem
          className={`${itemKey} ${
            link === currentPath ||
            activeItems.includes(`${renderKey}-${itemKey}-${id}`) ||
            ((actionType === "workspaces-children" ||
              actionType === "station" ||
              actionType === "map") &&
              link.split("?")[0] === location.pathname.split("?")[0]) ||
            (actionType === "dashboard" && link === location.pathname)
              ? "active-item"
              : ""
          }`}
          button
          onMouseEnter={() => handleHoverItem(`${renderKey}-${itemKey}-${id}`)}
          onMouseLeave={() => handleHoverItem()}
          onClick={onWorkspacesListMenuClick}
        >
          {(!iconType || iconType === "userGuide") && showsLeftArrow && (
            <ArrowRightIcon
              className={`
                navi-icon 
                first-navi-icon 
                ${
                  activeItems.includes(`${renderKey}-${itemKey}-${id}`)
                    ? "active-navi"
                    : ""
                }
                ${
                  ((actionType === "workspaces-children" ||
                    actionType === "station" ||
                    actionType === "userGuide") &&
                    link.split("?")[0] === location.pathname.split("?")[0]) ||
                  link === currentPath
                    ? "active-first-navi"
                    : ""
                }
              `}
              onClick={(e) => {
                handleClickLink(e, `${renderKey}-${itemKey}-${id}`);
              }}
            />
          )}
          {renderIcon(
            iconType,
            `${renderKey}-${itemKey}-${id}`,
            dashboardState.activeSubItem === `${renderKey}-${itemKey}-${id}`,
          )}
          {(actionType === "dashboard" ||
            actionType === "map" ||
            actionType === "folder" ||
            actionType === "userGuides") &&
          id === rename ? (
            <Box ref={wrapperRef}>
              <TextField
                inputRef={inputRef}
                className="rename-field"
                onClick={(event) => preventClickAction(event)}
                value={renameValue}
                onChange={handleNameChange}
                onKeyDown={(e) => handleKeyDown(e, actionType)}
                disabled={renameLoading}
                InputProps={{
                  endAdornment: (
                    <Box
                      zIndex="99999"
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={0.5}
                        onClick={(e) => handleCheckClick(e, actionType)}
                      >
                        <CheckRoundedIcon
                          style={{
                            fontSize: "18px",
                            color: "rgb(74, 174, 32)",
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={handleCancelClick}
                      >
                        <CloseRoundedIcon
                          style={{ fontSize: "18px", color: "#f64e60" }}
                        />
                      </Box>
                    </Box>
                  ),
                }}
              />
            </Box>
          ) : (
            <>
              <p
                style={{
                  color:
                    dashboardState.activeSubItem ===
                    `${renderKey}-${itemKey}-${id}`
                      ? "white"
                      : "#a2a3b7",
                }}
              >
                {name}
              </p>

              <div
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                {renderActions(actionType)}
              </div>
            </>
          )}
        </ListItem>
      </Link>
      {!renderKey || (renderKey === "content" && itemKey === "parent") ? (
        subs && subs.length > 0 ? (
          <Collapse
            in={activeItems.includes(`${renderKey}-${itemKey}-${id}`)}
            timeout="auto"
            unmountOnExit
            className={active ? "" : "display-none"}
          >
            {renderNavs(subs, id)}
          </Collapse>
        ) : null
      ) : (
        <Collapse
          in={activeItems.includes(`${renderKey}-${itemKey}-${id}`)}
          timeout="auto"
          unmountOnExit
          className={active ? "" : "display-none"}
        >
          {renderItem()}
        </Collapse>
      )}
      <SettingsModal
        // data={settingsData}
        open={showSettings}
        handleClose={() => setShowSettings(!showSettings)}
        // handleEdit={handleEditSettings}
        dashboardId={id}
      />
      <MapSettingsModal
        open={showMapSettings}
        handleClose={() => setShowMapSettings(!showMapSettings)}
        mapId={id}
      />
      <MoveStationModal
        open={showMoveStation}
        handleClose={() => setShowMoveStation(!showMoveStation)}
        mapId={id}
        reloadData={reloadData}
        workspaces={allWorkspaces}
      />
      <FolderModal
        isOpen={!!moveFolderId}
        handleClose={() => setMoveFolderId(null)}
        categoryList={categoryList}
        id={moveFolderId}
        token={token}
        refetchData={fetchNewUserGuideDataAndReloadData}
      />
      <MenuRenameModal
        isOpen={modalRename.open}
        intl={intl}
        name={name}
        title={modalRename.title}
        label={modalRename.label}
        placeholder={modalRename.placeholder}
        modalType={modalRename.type}
        handleClose={() => setModalRename((pre) => ({ ...pre, open: false }))}
        handleRename={handleSubmitRenameModal}
      />
    </>
  );
}
