import { Box } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DashboardValueList from "app/shared/components/Dashboard/DashboardValueList";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SharedSelectField from "app/shared/components/SharedSelectField";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  HISTORICAL_DATA,
  PREDICTION_DATA,
  VALUE_LIST_SORT_OPTIONS,
} from "../contants";
const ValueListForm = ({ formik, intl, language, parameters }) => {
  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        {/* <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.PARAMETERS_ACTIONS.PARAMETERS',
          })}>
          <Box mb={3}>
            <SharedSelectField
              className="text-normal bg-white"
              label={intl.formatMessage({
                id: 'CLIENT.DASHBOARD.PARAMETER_SORT_BY',
              })}
              disableClearable
              required
              name="sort_parameter_by"
              options={VALUE_LIST_SORT_OPTIONS.map(item => ({
                ...item,
                label: item[`name_${language}`]
              }))}
              defaultValue={formik.values['sort_parameter_by']}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Box>

        </ConfigureArea> */}
        <Box mb={3}>
          <h2 className="dashboard-title-1">
            <FormattedMessage id="CLIENT.DASHBOARD.VALUE_LIST.VALUE" />
          </h2>
          <DashboardValueList
            data={formik.values["params"]}
            params_delete={formik.values["params_delete"]}
            onChange={(data) => {
              formik.setFieldValue("params", data);
            }}
            onDelete={(data) => {
              formik.setFieldValue("params_delete", data);
            }}
            pickColors={true}
            parameters={parameters || []}
            intl={intl}
          />
        </Box>
      </form>
    </div>
  );
};

ValueListForm.propTypes = {};

export default ValueListForm;
