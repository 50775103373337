import { useGeoLocation, useLanguage } from "app/shared/hooks";
import { map as lodashMap, sortBy } from "lodash";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

export const useMapPage = ({ intl, mapApi, stationApi }) => {
  const [language] = useLanguage();
  const location = useGeoLocation();
  const zoomControlRef = useRef();
  const [mapStyle, setMapStyle] = useState(1);
  const [map, setMap] = useState();
  const history = useHistory();
  const [openAddStationModal, setOpenAddStationModal] = useState(false);
  const [openLayerModal, setOpenLayerModal] = useState(false);
  const [layers, setLayers] = useState([]);
  const [configData, setConfigData] = useState({
    workspaceId: undefined,
    mapId: undefined,
    stations: [],
  });
  const [stations, setStations] = useState([]);
  const [checked, setChecked] = useState({
    stations: [],
    layers: [],
  });
  const getDisplayStations = async (mapId) => {
    try {
      const res = await mapApi.getDisplayStations(mapId);
      if (res.success) {
        const displayStations = lodashMap(res.data, (item) => ({
          ...item,
          value: item.station_id,
          label:
            mapMaster?.language === "en"
              ? item?.station?.name
              : item?.station?.name_vi,
        }));
        setStations(displayStations);
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
  };

  const getDisplayPublicStations = async (mapId) => {
    try {
      const res = await mapApi.getPublicDisplayStation(mapId);
      if (res.success) {
        const displayStations = lodashMap(res.data, (item) => ({
          ...item,
          value: item.station_id,
          label:
            mapMaster?.language === "en"
              ? item?.station?.name
              : item?.station?.name_vi,
        }));
        setStations(displayStations);
      }
    } catch (error) {}
  };

  const getAllStations = async (workspaceId) => {
    try {
      const params = {};
      const res = await stationApi.getAllStations(workspaceId, params);

      if (res.success) {
        const stations = res.data;

        setConfigData((pre) => ({ ...pre, stations }));

        return {
          success: true,
          resStations: stations,
        };
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
    return {
      success: false,
    };
  };

  const getDisplayLayers = async (mapId) => {
    try {
      const res = await mapApi.getDisplayLayers(mapId);
      if (res.success) {
        setLayers(sortBy(res.data, ["apply_order", "asc"]));
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
  };

  const onChangeLayerType = (style) => {
    setMapStyle(style);
  };

  const handleOpenAddStationModal = () => {
    setOpenAddStationModal(true);
  };

  const handleCloseAddStationModal = () => {
    setOpenAddStationModal(false);
  };

  const handleOpenLayerModal = () => {
    setOpenLayerModal(true);
  };

  const handleCloseLayerModal = (reload) => {
    setOpenLayerModal(false);
    if (reload) {
      getDisplayLayers(configData.mapId);
    }
  };

  const updateDisplayStations = async (values) => {
    try {
      const data = {
        station_id: values,
      };
      const res = await mapApi.updateDisplayStation(configData.mapId, data);
      if (res.success) {
        return res.success;
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
    return false;
  };

  const onDropdownChange = (field) => (newValue) => {
    setChecked((pre) => ({
      ...pre,
      [field]: newValue,
    }));
  };

  const onChangeStations = (values) => {
    updateDisplayStations(lodashMap(values, (item) => item.value))
      .then(() => setStations(values))
      .catch((error) => {});
  };

  const onLocateMyLocation = () => {
    if (mapMaster?.location?.loaded && !mapMaster?.location?.error) {
      mapMaster?.map?.flyTo(mapMaster?.location?.coordinates, 12, {
        animate: true,
      });
    }
  };

  const onZoom = (option) => {
    mapMaster?.zoomControlRef?.current?.[`_zoom${option}Button`]?.click();
  };

  const mapMaster = {
    language,
    location,
    zoomControlRef,
    mapStyle,
    map,
    openAddStationModal,
    checked,
    stations,
    layers,
    configData,
    openLayerModal,
    history,

    onChangeLayerType,
    setMap,
    handleOpenAddStationModal,
    handleCloseAddStationModal,
    setChecked,
    setConfigData,
    getDisplayStations,
    onZoom,
    onLocateMyLocation,
    onChangeStations,
    onDropdownChange,
    handleOpenLayerModal,
    getAllStations,
    handleCloseLayerModal,
    getDisplayLayers,
    getDisplayPublicStations,
  };

  return mapMaster;
};
