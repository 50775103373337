export const INITIAL_VALUES = {
  name_en: "",
  name_vi: "",
  show_header: true,
  header_color: "#1E1E2D",
  prediction_time_range: "1-DAY",
  historical_time_range: "1-DAY",
  data_mode: "HISTORICAL_DATA",
  zoom: true,
  legend: true,
  range_selection: true,
  full_screen: true,
  dashboard_standard_chart_parameter: [],
};

export const HEADER_TOOLBAR_ICONS = {
  RESTORE_ICON: "RestoreIcon",
  TODAY_ICON: "TodayIcon",
  ZOOM_OUT_ICON: "ZoomOutIcon",
  ZOOM_IN_ICON: "ZoomInIcon",
  FULLSCREEN_ICON: "FullscreenIcon",
};

export const ZERO_TIME_RANGE = "0";
