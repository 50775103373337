import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  Switch,
  CircularProgress
} from "@material-ui/core";
import { MapsSecutityList, MapsSecurityAdd } from "./Maps";
import CopyToClipboard from "react-copy-to-clipboard";
import SharedInputField from "app/shared/components/SharedInputField";
import mapApi from "app/api/mapApi";
import { shallowEqual, useSelector } from "react-redux";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '80vh',
    overflowY: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: '100%',
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const MapSettingsModal = (props) => {
  const { handleClose, open, mapId } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(open || false);
  const [modalStyle] = useState(getModalStyle);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({
    public: false,
    url: "",
    urlCopied: false
  })
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (open && mapId) {
      (async () => {
        try {
          setLoading(true);
          const resp = await mapApi.getMap(mapId, token);
          if (resp.success) {
            setValues(prevValues => ({
              ...prevValues,
              public: resp.data.public_access,
              url: resp.data.public_url,
            }))
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [mapId, open])

  useEffect(() => {
    setIsOpen(open);
    setValues(prevValues => ({
      ...prevValues,
      urlCopied: false
    }))
  }, [open]);

  const updateGeneral = async (values) => {
    try {
      const data = {
        public_access: values,
      }
      const resp = await mapApi.updateGeneral(mapId, data, token);
      setValues(prevValues => ({
        ...prevValues,
        url: resp.data
      }))
    } catch (error) {
      console.log(error);
    }
  }

  const renderSecurityTab = () => {
    return (
      <>
        {
          page === "list" ?
            <MapsSecutityList
              setSecurityPage={setPage}
              mapId={mapId}
            /> :
            <MapsSecurityAdd
              setSecurityPage={setPage}
              mapId={mapId}
            />
        }
      </>
    )
  }

  const toggleOpen = () => {
    handleClose();
  };

  const generatePublicLink = (url) => {
    return `${window.location.origin}/public/map?key=${url}`

    // ## use the code below if backend return path
    // return `${window.location.origin}/dashboard/public/${path}`
  }

  const onClickCopy = async () => {
    setValues(prevValues => ({
      ...prevValues,
      urlCopied: true,
    }))
  }

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.MAP.SETTING.HEADER" />
        </div>
        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={handleClose} />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        {loading ? <CircularProgress /> :
          <Box mb={3}>
            <FormControlLabel
              control={<Android12Switch checked={values.public}
                color="primary"
                onChange={(e) => {
                  updateGeneral(e.target.checked);
                  setValues(prevValues => ({
                    ...prevValues,
                    public: e.target.checked,
                  }))
                }} />}
              label={intl.formatMessage({
                id: 'CLIENT.DASHBOARD.SETTING.PUBLIC_ACCESS',
              })}
            />

            <Box>
              {values.public && (
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <SharedInputField
                        label={intl.formatMessage({
                          id: 'CLIENT.DASHBOARD.SETTING.URL',
                        })}
                        name="name_vi"
                        className="disabled-field target-url"
                        type="text"
                        placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.NAME_VI_HINT' })}
                        disabled={true}
                        value={values.url !== "" ? generatePublicLink(values.url) : ""}
                      />
                      {values.urlCopied && (
                        <FormHelperText className="success-message">
                          <FormattedMessage id="CLIENT.DASHBOARD.SETTING.COPY_SUCCESS" />
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <CopyToClipboard text={generatePublicLink(values.url)} onCopy={onClickCopy}>
                        <Button
                          className="custom-button light-button"
                          type="button"
                          size="large"
                          style={{ marginTop: '30.2px', padding: '16px 27px', width: '100%' }}
                        >
                          <FormattedMessage id="CLIENT.DASHBOARD.SETTING.COPY" />
                        </Button>
                      </CopyToClipboard>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        }

        {renderSecurityTab()}
      </div>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  )
}

MapSettingsModal.propTypes = {
  open: PropTypes.bool,
  mapId: PropTypes.number,
  handleClose: PropTypes.func
};

export default MapSettingsModal