import { ALL_STATION_ID, ALL_WORKSPACE_ID } from "app/pages/Maps/constants/v2";
import {
  SET_ANCHOR_POPOVER_MARKER,
  SET_HAS_LOADED_ALL_ICONS_TO_MAP,
  SET_SELECTED_STATION,
  SET_SELECTED_WORKSPACE,
  CLEAR_STATION_PANEL_STATE,
} from "../actions/types";

const initialState = {
  selectedWorkspaceId: ALL_WORKSPACE_ID,
  selectedStation: {
    id: ALL_STATION_ID,
    value: ALL_WORKSPACE_ID,
    label: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
  },
  anchorMarkerPopover: null,
  hasLoadedAllIconsToMap: false,
};

const stationPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_WORKSPACE: {
      return { ...state, selectedWorkspaceId: action.payload };
    }

    case SET_SELECTED_STATION: {
      return { ...state, selectedStation: action.payload };
    }

    case SET_ANCHOR_POPOVER_MARKER: {
      return { ...state, anchorMarkerPopover: action.payload };
    }

    case SET_HAS_LOADED_ALL_ICONS_TO_MAP: {
      return { ...state, hasLoadedAllIconsToMap: true };
    }

    case CLEAR_STATION_PANEL_STATE: {
      return {
        selectedWorkspaceId: ALL_WORKSPACE_ID,
        selectedStation: {
          id: ALL_STATION_ID,
          value: ALL_WORKSPACE_ID,
          label: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
        },
        anchorMarkerPopover: null,
        hasLoadedAllIconsToMap: false,
      };
    }

    default: {
      return state;
    }
  }
};
export default stationPanelReducer;
