/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import "./styles.css";

import { useFormik } from "formik";
import ModalContainer from "../../common/ModalContainer";
import { prepareValueListOutput } from "../helpers";
import { VALUE_LIST_INITIALVALUES } from "../initialValues";
import ValueListSchema from "../schemas/ValueListSchema";
import useModal from "../useModal";
import ValueListForm from "./ValueListForm";

function MenuModal(props) {
  const { intl, open, handleClose } = props;
  const { openModal, language, setOpenModal, toggleOpen } = useModal({
    modalOpen: open,
    handleClose,
  });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || VALUE_LIST_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || VALUE_LIST_INITIALVALUES.name_vi,
      header_color:
        props.data?.header_color || VALUE_LIST_INITIALVALUES.header_color,
      show_header:
        props.data?.show_header === undefined
          ? VALUE_LIST_INITIALVALUES.show_header
          : props.data.show_header,
      sort_parameter_by:
        props?.data?.dashboard_value_list?.sort_parameter_by ||
        VALUE_LIST_INITIALVALUES.sort_parameter_by,
      params:
        props.data?.dashboard_value_list?.dashboard_value_list_parameter || [],
      params_delete: [],
      data_mode: props.data?.data_mode || VALUE_LIST_INITIALVALUES.data_mode,
      prediction_time_range:
        props.data?.time_point && props.data?.time_point !== "0"
          ? props.data?.time_point
          : VALUE_LIST_INITIALVALUES.prediction_time_range,
      prediction_time_range_type:
        props.data?.time_point_type && props.data?.time_point_type !== "0"
          ? props.data?.time_point_type
          : VALUE_LIST_INITIALVALUES.prediction_time_range_type,
    },
    enableReinitialize: true,
    validationSchema: ValueListSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props["handleEdit"](
        id,
        prepareValueListOutput(props?.data?.dashboard_value_list?.id, values)
      );
      props["handleClose"]();
    },
  });

  React.useEffect(() => {
    setOpenModal(props["open"]);

    return () => {
      formik.resetForm();
    };
  }, [props["open"]]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      toggleOpen={toggleOpen}
      disabledSave={
        formik.isSubmitting ||
        !formik.isValid ||
        formik.values["params"]?.find((item) => item.editing === true)
      }
      newText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.ADD_NEW_VALUE_LIST" />
      }
      editText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_VALUE_LIST" />
      }
    >
      <ValueListForm
        formik={formik}
        intl={intl}
        language={language}
        parameters={props?.parameters || []}
      />
    </ModalContainer>
  );
}

export default injectIntl(MenuModal);
