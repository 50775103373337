import { Button, IconButton } from "@material-ui/core";
import { Fragment, useState } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { CHART_TYPES } from "../constants";
import { useLanguage } from "../../../../../../shared/hooks";
import ChartModal from "../ChartModal";

const getUnitNameId = (data) => {
  const sortedData = data
    ?.map((item) => {
      const nameEnId = item["name_en"]?.split(" ")[2]
        ? Number(
            item["name_en"]?.split(" ")[item["name_en"]?.split(" ")?.length - 1]
          )
        : 0;
      const nameViId = item["name_vi"]?.split(" ")[2]
        ? Number(
            item["name_vi"]?.split(" ")[item["name_vi"]?.split(" ")?.length - 1]
          )
        : 0;
      if (nameEnId <= nameViId) {
        return nameViId;
      }
      return nameEnId;
    })
    ?.sort((a, b) => a - b);
  return sortedData[sortedData?.length - 1];
};

export default function ChartList(props) {
  const { data, onChange } = props;
  const [editStatuses, setEditStatuses] = useState([]);
  const [language] = useLanguage();
  const [forms, setForms] = useState([]);

  const getNewChart = () => {
    return {
      name_en: "New Chart",
      name_vi: "Biểu Đồ Mới",
      style: "LINE_SERIES",
      parameter_id: null,
      station_id: null,
      line_type: "NORMAL",
      fill_type: "NONE",
      line_width: 2,
      line_style: 0,
      marker_type: 1,
      marker_size: 2,
      state_thresholds: "ALL_STATES",
      color: "AUTOMATIC",
      main_color: "#3699FF",
    };
  };

  const addNewChart = () => {
    const chartId = uuidv4();
    const newChart = getNewChart();
    const nameId = (Number(getUnitNameId(data)) || 0) + 1;

    newChart["id"] = chartId;
    newChart["name_en"] = `New Chart ${nameId}`;
    newChart["name_vi"] = `Biểu Đồ Mới ${nameId}`;

    onChange([...data, newChart]);
  };

  const handleMove = (id, direction) => {
    const position = data.findIndex((i) => i.id === id);
    if (position < 0) {
      throw new Error("Given item not found.");
    } else if (
      (direction === -1 && position === 0) ||
      (direction === 1 && position === data.length - 1)
    ) {
      return;
    }

    const item = data.find((item) => item.id === id);
    const newItems = data.filter((i) => i.id !== id);
    newItems.splice(position + direction, 0, item);

    onChange(newItems);
  };

  const removeForm = (id) => {
    const tempForms = [...forms];
    const indexForm = forms.findIndex((item) => item.id === id);
    tempForms.splice(indexForm, 1);
    setForms(tempForms);
  };

  const removeChart = (id) => {
    const tempData = [...data];
    const index = tempData.findIndex((item) => item.id === id);
    tempData.splice(index, 1);
    onChange(tempData);
    removeForm(id);
  };

  const toggleEditStatus = (id) => {
    const newEditStatuses = [...editStatuses];

    if (newEditStatuses.includes(id)) {
      const index = newEditStatuses.indexOf(id);
      newEditStatuses.splice(index, 1);
      setEditStatuses(newEditStatuses);
    } else {
      setEditStatuses((prev) => [...prev, id]);
    }
  };

  const applyTempData = (id) => {
    forms.find((item) => item.id === id).form.handleSubmit();
  };

  const handleEditChart = (id, newData) => {
    const tempData = [...data].map((item) => {
      if (item.id === id) {
        return {
          ...item,
          id,
          ...newData,
        };
      }
      return item;
    });
    onChange(tempData);
    toggleEditStatus(id);
    removeForm(id);
  };

  const renderCharts = () => {
    return (
      <div className="charts-table">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>
                <FormattedMessage id="CLIENT.DASHBOARD.INDEX" />
              </th>
              <th>
                <FormattedMessage id="CLIENT.DASHBOARD.NAME_EN" />
              </th>
              <th>
                <FormattedMessage id="CLIENT.DASHBOARD.NAME_VI" />
              </th>
              <th>
                <FormattedMessage id="CLIENT.DASHBOARD.TYPE" />
              </th>
              <th style={{ textAlign: "center" }}>
                <FormattedMessage id="GENERAL.ACTIONS" />
              </th>
            </tr>
          </thead>

          <tbody>
            {data &&
              data.map((item, index) => (
                <Fragment key={item["id"]}>
                  <tr
                    style={{
                      background: editStatuses.includes(item["id"])
                        ? "#e2e2e2"
                        : "white",
                    }}
                  >
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td>{item["name_en"]}</td>
                    <td>{item["name_vi"]}</td>
                    <td>
                      {
                        CHART_TYPES.find(
                          (chart) => chart.value === item["style"]
                        )[`name_${language}`]
                      }
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {!editStatuses.includes(item["id"]) && (
                        <span
                          className={
                            index === data.length - 1
                              ? "chart-button-disabled"
                              : "chart-button"
                          }
                        >
                          <ArrowDownwardIcon
                            fontSize="small"
                            onClick={() => handleMove(item["id"], 1)}
                          />
                        </span>
                      )}

                      {!editStatuses.includes(item["id"]) && (
                        <span
                          className={
                            index === 0
                              ? "chart-button-disabled"
                              : "chart-button"
                          }
                        >
                          <ArrowUpwardIcon
                            fontSize="small"
                            onClick={() => handleMove(item["id"], -1)}
                          />
                        </span>
                      )}

                      {!editStatuses.includes(item["id"]) && (
                        <span className="chart-button">
                          <EditIcon
                            fontSize="small"
                            onClick={() => toggleEditStatus(item["id"])}
                          />
                        </span>
                      )}

                      {!editStatuses.includes(item["id"]) && (
                        <span className="chart-button">
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => removeChart(item["id"])}
                          />
                        </span>
                      )}

                      {editStatuses.includes(item["id"]) && (
                        <span className="chart-button">
                          <IconButton size="small" style={{ color: "#008a00" }}>
                            <CheckIcon
                              onClick={() => applyTempData(item["id"])}
                            />
                          </IconButton>
                        </span>
                      )}

                      {editStatuses.includes(item["id"]) && (
                        <span className="chart-button">
                          <IconButton size="small" style={{ color: "#db3700" }}>
                            <CloseIcon
                              onClick={() => toggleEditStatus(item["id"])}
                            />
                          </IconButton>
                        </span>
                      )}
                    </td>
                  </tr>

                  {editStatuses.includes(item["id"]) && (
                    <>
                      <tr>
                        <td colSpan={6} style={{ padding: 0 }}>
                          <ChartModal
                            isEditing={editStatuses.includes(item["id"])}
                            id={item["id"]}
                            data={item}
                            // handleClose={() => toggleEditStatus(item['id'])}
                            setForms={setForms}
                            handleEdit={handleEditChart}
                            handleRemove={removeChart}
                            parameters={props["parameters"]?.map(
                              (category) => ({
                                ...category,
                                items: category.items?.map((parameter) => ({
                                  ...parameter,
                                  disabled:  data.some( item => item.parameter_id === parameter.id),
                                })),
                              })
                            )}
                            charts={data}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}></td>
                      </tr>
                    </>
                  )}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <Button className="custom-button light-button" onClick={addNewChart}>
        <FormattedMessage id="CLIENT.DASHBOARD.NEW_CHART" />
      </Button>
      {data && data?.length > 0 && renderCharts()}
    </div>
  );
}
