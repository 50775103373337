import { useEffect, useRef, useState } from "react";
import "./index.css";
import ChatWindow from "../ChatWindow/ChatWindow";
import ChatUserIcon from "../ChatUserIcon";
import ChatActions from "../ChatActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import {
  handleInitChatWindow,
  closeChatWindow,
  handleHideChatWindow,
  fetchRoomIds,
  setInitRoomsNotification,
  getAllNotification,
  resetData,
  handleUnHideChatWindow,
  closeChatIcon,
  handleCloseAllChat,
  handleHideAllChat,
} from "../../../../redux/actions/endUserChatActions";

const Chat = (props) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user,
    }),
    shallowEqual,
  );
  const { chatWindows, chatIcons, roomsNoti, roomsInitNoti, roomIdList } =
    useSelector(
      ({ endUserChat }) => ({
        chatWindows: endUserChat.chatWindows,
        chatIcons: endUserChat.chatIcons,
        roomIdList: endUserChat.roomIdList,
        roomsNoti: endUserChat.roomsNoti,
        roomsInitNoti: endUserChat.roomsInitNoti,
      }),
      shallowEqual,
    );
  const socketRef = useRef();
  const subSocketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient.connect(
      `${process.env.REACT_APP_API_URL}`,
    );
    subSocketRef.current = socketIOClient.connect(
      `${process.env.REACT_APP_API_URL}/customer-service`,
    );
    // clean up
    return () => {
      socketRef.current.disconnect();
      subSocketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchRoomIds(token, user.id));
  }, [user.id]);

  useEffect(() => {
    socketRef.current?.on("Server-send-all-user", (resp) => {
      dispatch(handleInitChatWindow(resp.receiverUserId));
    });
  }, []);

  useEffect(() => {
    dispatch(getAllNotification(user.id));
  }, [user.id]);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const closeWindow = (id) => {
    dispatch(closeChatWindow(id));
  };

  const closeIcons = (id) => {
    dispatch(closeChatIcon(id));
  };

  const handleInitChat = async () => {
    if (chatWindows?.length > 0 || chatIcons?.length > 0) return;
    dispatch(handleInitChatWindow(user.id));
  };

  const handleHideChat = (id, messageList) => {
    dispatch(handleHideChatWindow(id, messageList));
  };

  const handleUnHide = (id, messageList, roomNoti) => {
    dispatch(handleUnHideChatWindow(id, messageList, roomNoti));
  };

  const handleCloseAll = () => {
    dispatch(handleCloseAllChat());
  };

  const handleHideAll = () => {
    dispatch(handleHideAllChat());
  };

  return null;

  return (
    <div className="chat-box">
      <div className="chat-icon" onClick={handleInitChat}>
        <img src="/images/chat-icon.png" />
        {(chatWindows.filter((item) => item.status !== "new").length > 0 ||
          chatIcons.length > 0) && (
          <ChatActions onHideAll={handleHideAll} onCloseAll={handleCloseAll} />
        )}
        {(roomsInitNoti?.length > 0 || roomsNoti?.length > 0) && (
          <div className="all-noti"></div>
        )}
      </div>
      <div className="chat-windows">
        {chatWindows?.map((item) => (
          <ChatWindow
            key={item.id}
            item={item}
            onClose={closeWindow}
            onHide={handleHideChat}
            handleInitChat={handleInitChat}
            socket={socketRef.current}
            subSocket={subSocketRef.current}
            token={token}
            userId={user.id}
          />
        ))}
      </div>
      <div className="chat-user-icons">
        {chatIcons?.map((item) => (
          <ChatUserIcon
            key={item.id}
            item={item}
            onUnHide={handleUnHide}
            onClose={closeIcons}
            socket={socketRef.current}
            roomNoti={roomsNoti.find(
              (elm) => elm.roomChatId === item.roomChatId,
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default Chat;
