import {
	Box, Checkbox,
	FormControlLabel,
	Grid,
	Typography
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ColorPicker from "app/shared/components/ColorPicker";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import {
	historicalTimeRange
} from "app/shared/components/Dashboard/DataModeConfigurator/data";
import TimeRangeSeletor from "app/shared/components/Dashboard/DataModeConfigurator/TimeRangeSeletor";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { useLanguage } from "app/shared/hooks";
import clsx from "clsx";
import { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { FEATURES_OPTIONS } from "../../StandardChartModal/constants";
import { usePredictionParameters } from "../hooks/usePredictionParameters";
import { useStyles } from "../styles";

const LineChartForm = (props) => {
	const { formik, intl, listParameterPrediction, setListParameterPrediction, setListParameterDelete } = props;
	const [language] = useLanguage();
	const { predictionParameterList, fetchPredictionParametersByParameter } =
		usePredictionParameters(formik.values["parameterId"]);
	
	const classes = useStyles();
	const historical_time_range = useMemo(() => {
		return `${formik.values["historical_time_range"]}-${formik.values["historical_time_range_type"]}`;
	}, [formik.values]);
	// const prediction_time_range = useMemo(() => {
	// 	return `${formik.values["prediction_time_range"]}-${formik.values["prediction_time_range_type"]}`;
	// }, [formik.values]);

	const predictionParametersCanSelect = useMemo(() => {
		let result = predictionParameterList.map((elm) => {
			if (listParameterPrediction.some((element) => element.temp_parameter_prediction_id === elm.id)) {
				return {
					...elm,
					isSelected: true
				}
			}
			return {
				...elm
			}
			
		});
		return result;
	}, [listParameterPrediction, predictionParameterList]);

	const addParameterAtFirst = () => {
		let newParameters = [
			{
				id: 0,
				parameter_prediction_id: 0,
				temp_parameter_prediction_id: 0,
				color: "#000000",
				temp_color: "#000000",
				isEdit: true,
			},
		];
		const newList = [...listParameterPrediction, ...newParameters];
		setListParameterPrediction(newList);
	};

	const updatePredictionParameter = ({ index, value }) => {
		setListParameterPrediction((prev) => {
			const predictionParameterAtIndex = prev.at(index);
			predictionParameterAtIndex.temp_parameter_prediction_id = value;
			return [...prev];
		});
	};

	const updatePredictionParameterColor = ({ index, value }) => {
		setListParameterPrediction((prev) => {
			const predictionParameterAtIndex = prev.at(index);
			predictionParameterAtIndex.temp_color = value;
			return [...prev];
		});
	};

	const savePredictionParameter = (index) => {
		if (listParameterPrediction.at(index).temp_parameter_prediction_id) {
			setListParameterPrediction((prev) => {
				const clonePrev = [...prev];
				const predictionParameterAtIndex = clonePrev.at(index);
				predictionParameterAtIndex.isEdit = false;
				predictionParameterAtIndex.parameter_prediction_id = predictionParameterAtIndex.temp_parameter_prediction_id;
				predictionParameterAtIndex.color = predictionParameterAtIndex.temp_color;
				return clonePrev;
			});
		}
	};

	const cancelEditPredictionParameter = (index) => {
		if (listParameterPrediction.at(index).temp_parameter_prediction_id) {
			setListParameterPrediction((prev) => {
				const predictionParameterAtIndex = prev.at(index);
				predictionParameterAtIndex.isEdit = false;
				predictionParameterAtIndex.temp_parameter_prediction_id =
				predictionParameterAtIndex.parameter_prediction_id;
				predictionParameterAtIndex.temp_color = predictionParameterAtIndex.color;
				return [...prev];
			});
		} else {
			deletePredictionParameter(index);
		}
	};

	const editPredictionParameter = (index) => {
		setListParameterPrediction((prev) => {
			prev[index].isEdit = true;
			return [...prev];
		});
	};

	const deletePredictionParameter = (index) => {
		const parameterDeleteId = listParameterPrediction.at(index).id;
		
		if (parameterDeleteId) {
			setListParameterDelete((prev) => [...prev, parameterDeleteId]);
		}
		
		setListParameterPrediction((prev) => {
			const copyPrev = [...prev];
			copyPrev.splice(index, 1);
			return copyPrev;
		});
	};

	return (
		<div className={classes.modalBody}>
			<form onSubmit={formik.handleSubmit}>
				<ConfigureArea
					mb={30}
					title={intl.formatMessage({
						id: "CLIENT.DASHBOARD.HEADER_LABEL",
					})}
				>
					<HeaderConfigurator formik={formik} />
				</ConfigureArea>

				<ConfigureArea
					mb={30}
					title={intl.formatMessage({
						id: "CLIENT.DASHBOARD.FEATURE",
					})}
				>
					<Grid container>
						{FEATURES_OPTIONS.map((item) => (
							<Grid item xs={6} md={3} key={item.value}>
								<FormControlLabel
									control={
										<Checkbox
											checked={formik.values[item.value]}
											onChange={(e) =>
												formik.setFieldValue(item.value, e.target.checked)
											}
										/>
									}
									label={item[`name_${language}`]}
								/>
							</Grid>
						))}
					</Grid>
				</ConfigureArea>

				<ConfigureArea
					mb={30}
					title={intl.formatMessage({
						id: "CLIENT.DASHBOARD.DATA",
					})}
				>
					<Grid container>
						<Grid container item className={classes.formDataSectionContainer}>
							<Grid item xs={6} className={classes.gridContainerPaddingRight}>
								<Box className={classes.formFieldLabel}>
									<span>*</span>
									<Typography component="p">
										<FormattedMessage id="CLIENT.DASHBOARD.PARAMETER" />
									</Typography>
								</Box>
								<SelectParamsByStaionDropdown
									data={props.parameters || []}
									value={{
										parameterId: formik.values["parameter_id"],
										stationId: formik.values["station_id"],
									}}
									// disabled={!item?.editing}
									onChange={({ stationId, parameterId }) => {
										formik.setFieldValue("parameter_id", parameterId);
										formik.setFieldValue("station_id", stationId);
										fetchPredictionParametersByParameter(parameterId);
									}}
								/>
								{formik.touched["parameter_id"] && formik.errors["parameter_id"] && (
									<Typography className={classes.errorMsg}>
										{formik.errors["parameter_id"]}
									</Typography>
								)}
							</Grid>
							<Grid item xs={6} className={classes.gridContainerPaddingLeft}>
								<SharedSelectField
									required
									className="bg-white"
									label={
										<FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.HISTORICAL.TIMERANGE" />
									}
									disableClearable
									name="historical_time_range"
									options={historicalTimeRange.map((item) => ({
										...item,
										label: item[`name_${language}`],
									}))}
									defaultValue={
										historicalTimeRange.some(
											(item) => item.value === historical_time_range
										)
											? historical_time_range
											: historicalTimeRange.at(0)?.value
									}
									onChange={(name, value) => {
										const times = value.split("-");
										formik.setFieldValue(`${name}`, times[0]);
										formik.setFieldValue(`${name}_type`, times[1]);
									}}
								/>
							</Grid>
						</Grid>

						{historical_time_range === historicalTimeRange.at(9).value && (
							<Grid xs={12} item>
								<TimeRangeSeletor
									error={formik.error}
									onChange={(field, value) => {
										formik.setFieldValue(field, value);
									}}
									endTime={formik.values.historical_end_time}
									startTime={formik.values.historical_start_time}
								/>
							</Grid>
						)}

						<Grid
							container
							item
							style={{ marginTop: 20 }}
							className={clsx(
								{ [classes.formDataSectionContainer]: true },
								{
									[classes.formDataSectionDisabled]: !(
										formik.values && predictionParameterList.length !== 0
									),
								}
							)}
						>
							<Grid item xs={12}>
								<Box className={classes.formFieldLabel}>
									<span>*</span>
									<Typography component="p">
										<FormattedMessage id="CLIENT.DASHBOARD.LIST_PREDICTION_PARAMETER" />
									</Typography>
								</Box>
								<Box
									className={clsx("bg-white", {
										[classes.gridAddParameter]: true,
									})}
								>
									{listParameterPrediction.length > 0 &&
										listParameterPrediction.map(
											(predictionParameter, index) => { return (
												<Grid container key={index}>
													<Grid item xs={4}>
														<SharedSelectField
															required
															className={clsx(
																{
																	[classes.disabled]:
																		predictionParameterList.length === 0,
																},
																{
																	"bg-white":
																		predictionParameterList.length !== 0,
																}
															)}
															disabled={
																predictionParameterList.length === 0 ||
																predictionParameter.isEdit === false
															}
															disableClearable
															name="prediction_parameter_id"
															options={predictionParametersCanSelect.map((item) => ({
																value: item.id,
																label:
																	language === "en" ? item.name : item.name_vi,
																disabled: item.isSelected
															}))}
															defaultValue={
																predictionParameter.isEdit === false
																	? predictionParameterList.some(
																			(item) =>
																				item.id ===
																				predictionParameter.parameter_prediction_id
																	  )
																		? predictionParameter.parameter_prediction_id
																		: 0
																	: predictionParameterList.some(
																			(item) =>
																				item.id ===
																				predictionParameter.temp_parameter_prediction_id
																	  )
																	? predictionParameter.temp_parameter_prediction_id
																	: 0
															}
															onChange={(name, value) => {
																updatePredictionParameter({ index, value });
															}}
														/>
													</Grid>
													<Grid
														container
														item
														xs={8}
														className={
															classes.predictionParameterSelectColorContainer
														}
													>
														<Grid
															item
															xs={3}
															className={classes.predictionParameterSelectColor}
														>
															<ColorPicker
																disabled={predictionParameter.isEdit === false ? true : false}
																color={predictionParameter.temp_color}
																position="right"
																onChange={(colorValue) =>
																	updatePredictionParameterColor({
																		index,
																		value: colorValue,
																	})
																}
															/>
														</Grid>
														{predictionParameter.isEdit === true ? (
															<Grid
																item
																container
																xs={9}
																className={classes.actionButtonGroup}
															>
																<Grid
																	item
																	xs={1}
																	className="check-icon"
																	onClick={() =>
																		savePredictionParameter(index)
																	}
																>
																	<CheckIcon
																		className="icon-check"
																		fontSize="small"
																	/>
																</Grid>
																<Grid
																	item
																	xs={1}
																	className="close-icon"
																	onClick={() =>
																		cancelEditPredictionParameter(index)
																	}
																>
																	<CloseIcon
																		className="icon-check"
																		fontSize="small"
																	/>
																</Grid>
															</Grid>
														) : (
															<Grid
																item
																container
																xs={9}
																className={classes.actionButtonGroup}
															>
																<Grid
																	item
																	xs={1}
																	className="edit-icon"
																	onClick={() => editPredictionParameter(index)}
																>
																	<EditIcon
																		className="icon-check"
																		fontSize="small"
																	/>
																</Grid>
																<Grid
																	item
																	xs={1}
																	className="delete-icon"
																	onClick={() =>
																		deletePredictionParameter(index)
																	}
																>
																	<DeleteIcon
																		className="icon-check"
																		fontSize="small"
																	/>
																</Grid>
															</Grid>
														)}
													</Grid>
												</Grid>
											)}
										)}
									<Box
										className={classes.buttonAddParameter}
										onClick={() => addParameterAtFirst()}
									>
										<div className="param-item text-blue">
											<span className="add-icon">+ </span>
											{intl.formatMessage({
												id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.NEW_PARAMETER",
											})}
										</div>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</ConfigureArea>
			</form>
		</div>
	);
};

export default injectIntl(LineChartForm);
