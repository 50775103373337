import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSizes";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../../components/ScrollToTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setTopMenu } from "../../../redux/actions/topMenuActions";

const MainLayout = (props) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState();
  const { children, breadcrumbs, title, hideFooter } = props;
  const size = useWindowSize();

  const mapboxInstance = useSelector(
    ({ maps }) => maps?.mapboxInstance,
    shallowEqual
  );

  useEffect(() => {
    setActive(JSON.parse(localStorage.getItem("active_nav")));
  }, []);

  useEffect(() => {
    dispatch(
      setTopMenu({
        title,
        breadcrumbs,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs, title]);

  useEffect(() => {
    const active = JSON.parse(localStorage.getItem("active_nav"));
    let mapContentWidth = "calc(100vw ";
    if (size.width < 1000) {
      document.getElementById("main-layout").style.paddingLeft = "0";
      mapContentWidth += "+ 40px)";
    } else {
      if (!active) {
        document.getElementById("main-layout").style.paddingLeft = "70px";
        mapContentWidth += "- 30px)";
      } else {
        document.getElementById("main-layout").style.paddingLeft = "286px";
        mapContentWidth += "- 243px)";
      }
    }

    const mapContent = document.getElementById("mapbox__content");
    if (mapContent && mapboxInstance) {
      mapContent.style.width = mapContentWidth;
      mapboxInstance?.resize();
    }
  }, [size, mapboxInstance]);

  return (
    <div
      id="main-layout"
      style={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <Header active={active} breadcrumbs={breadcrumbs} title={title} />

      <div
        style={{
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
          paddingBottom: 24,
          ...props?.contentStyle,
        }}
      >
        {children}

        {!hideFooter && <Footer />}
      </div>

      <ScrollToTop />
    </div>
  );
};

export default MainLayout;
