import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage, injectIntl } from "react-intl";
import { Box, Button } from "@material-ui/core";
import SharedMultiSelect from "app/shared/components/SharedLiteMultiSelect";
import PropTypes from "prop-types";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
}));

function _AddStationModal(props) {
  const { intl, data, selectedValues, onChange, handleClose } = props;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(props["open"] || false);
  const [selected, setSelected] = useState([]);

  const handleClear = () => {
    setSelected([]);
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelected(data);
    } else {
      handleClear();
    }
  };

  const onSelect = (options) => {
    if (options?.length === 0) {
      handleClear();
    }
  };

  const onToggleOption = (selectedOptions) => {
    setSelected(selectedOptions);
  };

  const toggleOpen = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleSubbmit = () => {
    if (onChange) {
      onChange(
        selected.map((item) => ({
          ...item,
          checked: true,
        }))
      );
      handleClose();
    }
  };

  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);
  
  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.MAP.NEW_STATION" />
        </div>
        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={toggleOpen} />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <Box>
          <Box className="form-label">
            {intl.formatMessage({ id: "CLIENT.WORKSPACE_ACTIONS.STATIONS" })}
          </Box>
          <SharedMultiSelect
            placeholder={
              selected?.length === 0
                ? intl.formatMessage({
                    id: "CLIENT.MAP.SELECT_STATION",
                  })
                : ""
            }
            items={data}
            selectedValues={selected}
            getOptionLabel={(option) => `${option.label}`}
            getOptionDisabled={(option) => option.value === "foo"}
            selectAllLabel={intl.formatMessage({
              id: "CLIENT.GENERAL.SELECT_ALL_LABEL",
            })}
            noOptionsText={intl.formatMessage({
              id: "CLIENT.GENERAL.NO_OPTION",
            })}
            onToggleOption={onToggleOption}
            onClearOptions={handleClear}
            onSelectAll={handleSelectAll}
            onSelect={onSelect}
            className="text-normal"
            label=""
            defaultRenderTags
            limitTags={-1}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={4}
        >
          <Box display="flex" alignItems="center" ml="auto" mr="auto">
            <Button className="custom-button" onClick={handleSubbmit}>
              <FormattedMessage id="CLIENT.DASHBOARD.SAVE_ITEM" />
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    setOpen(props?.open);
    setSelected(selectedValues || []);
  }, [props?.open, selectedValues]);

  return (
    <Modal
      open={open}
      onClose={props["handleClose"]}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

_AddStationModal.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  handleClose: PropTypes.func,
  selectedValues: PropTypes.array,
};

export const AddStationModal = injectIntl(_AddStationModal);
