import { ADD_WAITING_MESSAGE, REMOVE_WAITING_MESSAGE } from "./types";

export const addWaitingMessage = (message) => {
  return {
    type: ADD_WAITING_MESSAGE,
    payload: {
      ...message,
    },
  };
}

export const removeWaitingMessage = (messageId) => {
  return {
    type: REMOVE_WAITING_MESSAGE,
    payload: {
      messageId,
    },
  };
}