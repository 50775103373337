import OrganizationIcon from "../../../../../assets/images/organization-icon.svg";
import ActiveOrganizationIcon from "../../../../../assets/images/active-organization-icon.svg";
import UserIcon from "../../../../../assets/images/user-icon.svg";
import ActiveUserIcon from "../../../../../assets/images/active-user-icon.svg";
import LockIcon from "../../../../../assets/images/lock-icon.svg";
import ActiveLockIcon from "../../../../../assets/images/active-lock-icon.svg";
import NotificationActiveIcon from "../../../../../assets/images/notification-active.svg";
import NotificationIcon from "../../../../../assets/images/notification.svg";

// config: {
//   id: id of item,
//   icon: icon of item,
//   activeIcon: icon of item when hover or active,
//   key: key of group item. So we have "" || "children" || "sub-children" || "sub-sub-children"....,
//   name: name of item,
//   link: link of item if have link,
//   subs: sub item of item if have
// }

export const SYSTEM_MANAGEMENT = [
  {
    id: 1,
    icon: OrganizationIcon,
    activeIcon: ActiveOrganizationIcon,
    key: "",
    name: "Organization Management",
    link: "/organizations",
    subs: [],
    code: "organizations",
  },
  {
    id: 2,
    icon: UserIcon,
    activeIcon: ActiveUserIcon,
    name: "Account Management",
    key: "",
    link: "",
    subs: [
      {
        id: 1,
        icon: UserIcon,
        activeIcon: ActiveUserIcon,
        name: "Accounts",
        link: "/account",
        key: "children",
        code: "account",
        subs: [],
      },
      {
        id: 3,
        icon: LockIcon,
        activeIcon: ActiveLockIcon,
        name: "Roles",
        link: "/roles",
        key: "children",
        code: "roles",
        subs: [],
      },
    ],
  },
  // {
  //   id: 3,
  //   icon: WorkspaceIcon,
  //   activeIcon: ActiveWorkspaceIcon,
  //   name: "Workspace Management",
  //   key: "",
  //   link: "",
  //   subs: [
  //     {
  //       id: 0,
  //       icon: UserIcon,
  //       activeIcon: ActiveLockIcon,
  //       name: "Workspace",
  //       link: "/workspaces",
  //       key: "children",
  //       subs: []
  //     },
  //     {
  //       id: 1,
  //       icon: UserIcon,
  //       activeIcon: ActiveUserIcon,
  //       name: "Workspace Details",
  //       link: "",
  //       key: "children",
  //       subs: [
  //         {
  //           id: 1,
  //           icon: WorkspaceIcon,
  //           activeIcon: ActiveWorkspaceIcon,
  //           name: "Ben Tre",
  //           link: "",
  //           key: "sub-children",
  //           subs: [
  //             {
  //               id: 1,
  //               icon: StationTypesIcon,
  //               activeIcon: ActiveStationTypesIcon,
  //               name: "Ba Lai",
  //               link: "/",
  //               key: "sub-sub-children",
  //               subs: []
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 3,
  //       icon: StationTypesIcon,
  //       activeIcon: ActiveStationTypesIcon,
  //       name: "Station Types",
  //       link: "/station-types",
  //       key: "children",
  //       subs: []
  //     },
  //   ]
  // },
];

export const PROFILE_MANAGEMENT = (intl) => {
  return [
    {
      id: 1,
      icon: OrganizationIcon,
      activeIcon: ActiveOrganizationIcon,
      name: intl.formatMessage({
        id: "CLIENT.ORGANIZATION_ACTIONS.MY_ORGANIZATION_TITLE",
      }),
      link: "/organization/edit",
      code: "my_organization",
      subs: [],
    },
    {
      id: 2,
      icon: UserIcon,
      activeIcon: ActiveUserIcon,
      name: intl.formatMessage({ id: "CLIENT.PROFILE.PAGE_TITLE" }),
      link: "/profile/edit",
      code: "my_profile",
      subs: [],
    },
    {
      id: 3,
      icon: LockIcon,
      activeIcon: ActiveLockIcon,
      name: intl.formatMessage({ id: "CLIENT.CHANGE_PASSWORD.PAGE_TITLE" }),
      link: "/profile/change-password",
      code: "change_password",
      subs: [],
    },
    {
      id: 4,
      icon: NotificationIcon,
      activeIcon: NotificationActiveIcon,
      name: intl.formatMessage({
        id: "CLIENT.NOTIFICATIONS_MANAGEMENT.PAGE_TITLE",
      }),
      link: "/my-notifications",
      code: "my_notifications",
      subs: [],
    },
    {
      id: 5,
      icon: NotificationIcon,
      activeIcon: NotificationActiveIcon,
      name: intl.formatMessage({ id: "CLIENT.CHATS.PAGE_TITLE" }),
      link: "/chats",
      code: "chats",
      subs: [],
    },
    // {
    //   id: 4,
    //   icon: NotificationIcon,
    //   activeIcon: NotificationActiveIcon,
    //   name: intl.formatMessage({id: "CLIENT.NOTIFICATIONS_MANAGEMENT.PAGE_TITLE" }),
    //   link: "/notifications",
    //   code: "my_notifications",
    //   subs: []
    // },
  ];
};

export const ADD_NEW_DASHBOARD = "ADD_NEW_DASHBOARD";
export const ADD_NEW_MAP = "ADD_NEW_MAP";
export const RENAME_DASHBOARD = "RENAME_DASHBOARD";
export const RENAME_MAP = "RENAME_MAP";
