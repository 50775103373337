import axiosClient from "./axiosClient";

const chatApi = {
  initChatRoom(data, token) {
    const url = "/api/v1/room-chat/create-room-chat";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getAllRoomIds(data, token) {
    const url = "/api/v1/room-chat/get-all-room-chat";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  removeChatNoti(data, token) {
    const url = "/api/v1/room-chat-notification/seen-chat-notification";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getAllNoti(data, token) {
    const url = "/api/v1/room-chat-notification/check-notification-chat";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getAllLatestChat(data, token) {
    const url = "/api/v1/room-chat-member/get-all-lates-chat";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  deleteChat(data, token) {
    const url = "/api/v1/room-chat/delete-chat-room";
    return axiosClient.delete(url, { data, headers: {"Authorization" : `Bearer ${token}`} })
  },
  uploadFile(data, token) {
    const url = "/api/v1/room-chat-attachment";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type": "multipart/form-data"}});
  },
  getAllEndUserNoti(token) {
    const url = "/api/v1/room-chat-customer-service-notification";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  joinEndUserRoom(data, token) {
    const url = "/api/v1/room-chat/join-chat-room";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  removeEndUserChatNoti(data, token) {
    const url = "/api/v1/room-chat-customer-service-notification/seen-customer-service-notification";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
}

export default chatApi;
