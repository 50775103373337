import { getRandomDarkColor } from "app/utils/color";
import { keyBy, map } from "lodash";
import { useCallback, useState } from "react";
import {
  getChartOption,
  getColumnStyle,
  getFormattedValue,
  getLineStyle,
} from "app/shared/components/Dashboard/Echart/echart.utils";
import useDetectDevice from "app/shared/hooks/useDetectDevice";

const DEFAULT_COLOR_SETTING = "AUTOMATIC";

const useStandardChart = ({ language, header_color }) => {
  const [chartConfig, setChartConfig] = useState();
  const [listParameterId, setParameterId] = useState({});
  const { deviceType } = useDetectDevice();

  const getParameterData = (receiveData, chartConfigByParameter) => {
    let parameterData = {};
    let parameterIds = {};
    let isNoData = true;

    receiveData.forEach((data) => {
      parameterIds[data.parameterDto.parameterId] =
        data.parameterDto.parameterName;

      parameterData[data.parameterDto.parameterId] = map(
        data.dataPointDtos,
        (point) => {
          isNoData = false;
          const format =
            chartConfigByParameter[data.parameterDto.parameterId]?.parameter
              ?.parameter_format_text;

          const x = point.eventTime;
          const y = getFormattedValue(point.value, format);

          return [x, y];
        }
      );
    });

    setParameterId(parameterIds);

    return {
      parameterData,
      isNoData,
      listParameterId,
    };
  };

  /* parameterDatas is an array of objects
  it's structure is as follows:
  [
    {
      dataPointDtos: [
          {
            eventTime: String,
            isAlarm: Boolean,
            value: Number
          },
          ...
      ],
      parameterDto: {
        parameterId: Number,
        stationId: Number,
        organizationId: Number,
      }
    },
    ...
  ]
  */
  const receiveDataAndConfig = useCallback(
    (receiveData, standardChartConfig) => {
      if (!receiveData || !receiveData.parameterDatas || !standardChartConfig)
        return;

      const chartConfigByParameter = keyBy(
        standardChartConfig.dashboard_standard_chart_parameter,
        "parameter_id"
      );

      const { parameterData, isNoData } = getParameterData(
        receiveData.parameterDatas,
        chartConfigByParameter
      );

      if (isNoData) return;

      const DEFAULT_WIDTH = 2;
      let series = [];
      let listColor = [];

      standardChartConfig.dashboard_standard_chart_parameter.forEach(
        (element) => {
          const isColumnType =
            element.style === "COLUMN_SERIES" ? "bar" : "line";
          const width = +element.line_width || DEFAULT_WIDTH;
          const fillType = element.fill_type;
          const fillOpacity = +element.fill_opacity;
          const markerType = +element.marker_type;
          const markerSize = +element.marker_size;
          const lineType = element.line_type;
          const lineStyle = element.line_style;
          const color =
            element.color === DEFAULT_COLOR_SETTING
              ? getRandomDarkColor()
              : element.main_color;

          listColor.push(color);

          let chartStyle;
          if (element.style === "COLUMN_SERIES") {
            chartStyle = getColumnStyle(width, color, fillType, fillOpacity);
          } else {
            chartStyle = getLineStyle(
              markerType,
              markerSize,
              color,
              fillType,
              lineType,
              width,
              lineStyle
            );
          }

          let seriesData = {
            id: `${element.parameter_id}_${crypto.randomUUID()}`,
            type: isColumnType,
            name: element[`name_${language}`],
            data: parameterData[element.parameter_id],
          };
          seriesData = Object.assign(chartStyle, seriesData);

          series.push(seriesData);
        }
      );

      const { gridlines: showGrid, legend: showLegend } =
        standardChartConfig || {};

      const chartOption = getChartOption({
        dataSeries: series,
        showGrid,
        showLegend,
        header_color,
        deviceType,
        listColor,
      });

      setChartConfig(chartOption);
    },

    [language, deviceType]
  );
  return { chartConfig, listParameterId, receiveDataAndConfig };
};

export default useStandardChart;
