import { useFormik } from 'formik';
import { createYupSchema } from '../utils/yupSchemaCreator';
import * as yup from "yup";
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import SharedSelectField from '../components/SharedSelectField';
import SharedInputField from '../components/SharedInputField';
import SharedCheckboxField from '../components/SharedCheckboxField';
import { Link } from "react-router-dom";
import DatePicker from '../components/DatePicker';
import useDetectDevice from './useDetectDevice';

const getField = (field, form) => {
  switch (field.fieldType) {
    case "select": {
      return (
        <SharedSelectField 
          name={field.name}
          label={field.label}
          options={field.options}
          required={field.required}
          disableClearable={field.disableClearable}
          placeholder={field.placeholder}
          errorMsg={form.touched[field.name] && form.errors[field.name]}
          disabled={form.isSubmitting}
          defaultValue={form.values[field.name]}
          onChange={form.setFieldValue}
        />
      )
    }
    case "input": {
      return (
        <SharedInputField 
          name={field.name}
          label={field.label}
          value={form.values[field.name]}
          type={field.type}
          required={field.required}
          isPasswordField={field.isPasswordField}
          placeholder={field.placeholder}
          onChange={form.handleChange}
          errorMsg={form.touched[field.name] && form.errors[field.name]}
          disabled={form.isSubmitting}
          {...form.getFieldProps(field.name)}
        />
      )
    }
    case "checkbox": {
      return (
        <SharedCheckboxField 
          name={field.name}
          label={field.label}
          options={field.options}
          required={field.required}
          values={form.values[field.name]}
          onChange={form.setFieldValue}
          errorMsg={form.touched[field.name] && form.errors[field.name]}
          disabled={form.isSubmitting}
          {...form.getFieldProps(field.name)}
        />
      )
    }
    case "date": {
      return (
        <DatePicker 
          name={field.name}
          label={field.label}
          value={form.values[field.name]}
          type={field.type}
          required={field.required}
          placeholder={field.placeholder}
          onChange={form.setFieldValue}
          currentError={form.errors[field.name]}
          setFieldError={form.setFieldError}
          disabled={form.isSubmitting}
        />
      )
    }
    default: return
  }
}

export const useForm = (values) => {
  const {
    formData,
    buttons,
    onSubmit,
    spacing,
    buttonPosition,
    noteMsg,
    isLoginPage,
    forgotPasswordText,
    loading,
    buttonsWrapperWidth,
    noteMsgWrapperWidth,
    noteMsgPosition,
    noteMsgOrder,
    buttonOrder,
  } = values;

  // create initialValues
  const initialValues = {};
  formData.forEach((item) => {
    initialValues[item.name] = item.value;
  });

  // create validate schema
  const yepSchema = formData.reduce(createYupSchema, {});
  const validateSchema = yup.object().shape(yepSchema);
  const { deviceType } = useDetectDevice();
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (onSubmit) {
        onSubmit(values, setSubmitting, resetForm)
      }
    }
  });

  // render form
  const renderForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={spacing || 2}>
          {
            formData.map(item =>
              <Grid 
                item
                key={item.name} 
                xs={item.width}
              >
                {
                  getField(item, formik)
                }
              </Grid>
            )
          }
          {
            isLoginPage ? 
            <>
              <Grid 
                item
                xs={12}
                className="note-msg"
              >
                  *{noteMsg}
              </Grid>
              <Grid 
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Link to="/auth/forgot-password" className="custom-link">
                  {forgotPasswordText}
                </Link>
                {
                  buttons.map(item =>
                    <Box 
                      key={item.id} 
                      pr={item.pr} 
                      pl={item.pl}
                      pt={item.pt}
                      pb={item.pb}
                      mr={item.mr} 
                      ml={item.ml}
                      mt={item.mt}
                      mb={item.mb}
                      display="inline-block"
                    >
                      {
                        item.type === "submit" ?
                        <Button 
                          type={item.type}
                          className={`custom-button ${item.buttonStyle === "light" ? "light-button" : ""}`}
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            padding: "13px 30px",
                          }}
                          disabled={
                            formik.isSubmitting ||
                            !formik.isValid
                          }  
                        >
                          {item.label}
                          {loading && <CircularProgress color="inherit" size={16} style={{marginLeft: "5px"}}/>}
                        </Button> :
                        <Button
                          variant={item.variant} 
                          type={item.type}
                          onClick={item.onClick}
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            padding: "13px 30px",
                          }}
                        >
                          {item.label}
                        </Button>
                      }
                    </Box>
                  )
                }
              </Grid>
            </> :
            <Grid item container> 
             {
                noteMsg &&
                <Grid 
                  item
                  style={{order: noteMsgOrder}} 
                  xs={12}
                  sm={noteMsgWrapperWidth}
                >
                  <Box 
                    display="flex" 
                    justifyContent={noteMsgPosition === "right" ? "flex-end" :"flex-start"}
                    height="100%" 
                    alignItems="flex-end" 
                    className="note-msg"
                    mt={deviceType==="mobile"&&1}
                  >
                    *{noteMsg}
                  </Box>
                </Grid>
              }
              <Grid 
                item
                xs={12}
                sm={buttonsWrapperWidth}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: buttonPosition === "center" ? 
                  "center" : 
                  buttonPosition === "right" ?
                  "flex-end" :
                  "flex-start",
                  order: buttonOrder
                }}
              >
                {
                  buttons.map(item =>
                    <Box 
                      key={item.id} 
                      pr={item.pr} 
                      pl={item.pl}
                      pt={item.pt}
                      pb={item.pb}
                      mr={item.mr} 
                      ml={item.ml}
                      mt={item.mt}
                      mb={item.mb}
                      display="inline-block"
                      width={deviceType==="mobile"&&"100%"}
                    >
                      {
                        item.type === "submit" ? 
                          <Button
                            className={`custom-button ${item.buttonStyle === "light" ? "light-button" : ""}`}
                            type={item.type}
                            onClick={item.onClick}
                            disabled={
                              formik.isSubmitting ||
                              !formik.isValid
                            }
                            style={{width: deviceType==="mobile"&&"100%"}}
                          >
                            {item.label}
                            {loading && <CircularProgress color="inherit" size={16} style={{marginLeft: "5px"}}/>}
                          </Button> :
                          <Button
                            variant={item.variant} 
                            className={`custom-button ${item.buttonStyle === "light" ? "light-button" : ""}`}
                            type={item.type}
                            onClick={item.onClick}
                            style={{width: deviceType==="mobile"&&"100%"}}
                          >
                            {item.label}
                          </Button>
                      }
                    </Box>
                  )
                }
              </Grid>
            </Grid>
          }
        </Grid>
      </form>
    )
  }

  return { renderForm };
};
