import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M23.625 6.667c-1.45 0-2.625 1.12-2.625 2.5 0 .92-.784 1.667-1.75 1.667s-1.75-.747-1.75-1.667c0-3.222 2.742-5.833 6.125-5.833s6.125 2.611 6.125 5.833S27.008 15 23.625 15H8.75C7.784 15 7 14.254 7 13.334c0-.921.784-1.667 1.75-1.667h14.875c1.45 0 2.625-1.12 2.625-2.5s-1.175-2.5-2.625-2.5zM3.5 20c0-.92.784-1.666 1.75-1.666h27.125c3.383 0 6.125 2.611 6.125 5.833 0 3.221-2.742 5.833-6.125 5.833s-6.125-2.611-6.125-5.833c0-.92.784-1.667 1.75-1.667s1.75.746 1.75 1.667c0 1.38 1.175 2.5 2.625 2.5S35 25.547 35 24.167s-1.175-2.5-2.625-2.5H5.25c-.966 0-1.75-.746-1.75-1.667zm1.75 6.667C5.25 25.747 6.034 25 7 25h9.625c3.383 0 6.125 2.612 6.125 5.834 0 3.221-2.742 5.833-6.125 5.833S10.5 34.055 10.5 30.833c0-.92.784-1.666 1.75-1.666s1.75.746 1.75 1.666c0 1.381 1.175 2.5 2.625 2.5s2.625-1.119 2.625-2.5c0-1.38-1.175-2.5-2.625-2.5H7c-.966 0-1.75-.746-1.75-1.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
