import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const PasswordInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={12} md={6}>
      <Box mb={3} mr={2}>
        <SharedInputField
          name="password"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.PASSWORD_LABEL",
          })}
          type="password"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.PASSWORD_HINT",
          })}
          required
          isPasswordField
          errorMsg={formik.touched["password"] && formik.errors["password"]}
          {...formik.getFieldProps("password")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default PasswordInput;
