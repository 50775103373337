import { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import FileSaver from 'file-saver'

import FileDownloadOutlinedIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import DefaultUser from "../../../../../../assets/images/user.png";
import { DATE_TIME_FORMAT } from '../../../../../constant/date-time-format';

const MessageList = (props) => {
  const { messageList, loading, hasMore, setPage, item, userAvatar, lastMessageRef } = props;
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const observer = useRef(null);
  const firstMsgRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !scrollToBottom && hasMore) {
        setPage(prevPage => prevPage + 1)
      }
    })
    if (node) observer.current.observe(node);
  }, [loading, scrollToBottom, hasMore])

  useEffect(() => {
    if (lastMessageRef?.current) {
      lastMessageRef?.current?.scrollIntoView();
      setScrollToBottom(false)
    }
  }, [messageList?.[messageList.length - 1]?.["id"]])

  return (
    <div>
      {messageList?.map((message, index) =>
        <div
          ref={index === 0 ? firstMsgRef : (index === messageList.length - 1 ? lastMessageRef : null)}
          className={`chat-window-message chat-window-${message.roomChatMemberId === item.roomChatMemberId ? "outbox" : "inbox"}`}
          key={message?.["RoomChatAttachments.extensionAttachment"] ? `${message.id}-${uuidv4()}` : message.id}
        >
          <img src={
            message.roomChatMemberId === item.roomChatMemberId ?
              userAvatar ? `${process.env.REACT_APP_API_URL}/${userAvatar}` : DefaultUser :
              item.avatar || DefaultUser
          } className='chat-window-avatar' />

          <div className='chat-window-content chat-window-inbox-content'>
            {!message?.["RoomChatAttachments.extensionAttachment"] ?
              <>
                <p>{message.message}</p>
                <p className='chat-window-time'>{moment(message?.createdAt).format(DATE_TIME_FORMAT)}</p>
              </> :
              (
                message?.["RoomChatAttachments.extensionAttachment"]?.split("/")?.[0] === "image" ?
                  <div className="messsage-image">
                    <img src={`${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`} />
                    <div
                      className="remove-file"
                      onClick={() => FileSaver.saveAs(`${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`, message?.["RoomChatAttachments.originalNameAttachment"])}
                    >
                      <FileDownloadOutlinedIcon fontSize="small" />
                    </div>
                  </div> :
                  <div className="file-file">
                    <div className="file-icon">
                      <AttachFileIcon />
                    </div>
                    <div className="file-name">
                      <span>{message?.["RoomChatAttachments.originalNameAttachment"]?.split('.')?.slice(0, -1)?.join()}</span>
                      <span>.{message?.["RoomChatAttachments.originalNameAttachment"]?.split('.').pop()}</span>
                    </div>
                    <div
                      className="remove-file"
                      onClick={() => FileSaver.saveAs(`${process.env.REACT_APP_API_URL}/${message?.["RoomChatAttachments.attachmentPath"]}`, message?.["RoomChatAttachments.originalNameAttachment"])}
                    >
                      <FileDownloadOutlinedIcon fontSize="small" />
                    </div>
                  </div>
              )
            }
          </div>
        </div>
      )}
    </div>
  )
}

MessageList.propTypes = {
  messageList: PropTypes.array,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  setPage: PropTypes.func,
  item: PropTypes.object,
  userAvatar: PropTypes.string,
  lastMessageRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default memo(MessageList);