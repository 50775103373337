import { DATE_TIME_FORMAT } from "app/constant/date-time-format";
import moment from "moment";
import {
  COMBINED_DATA,
  HISTORICAL_DATA,
  PREDICTION_DATA,
  UPLOAD_TYPE,
} from "./contants";
import {
  HISTORIC_TABLE_INITIALVALUES,
  VALUE_INITIALVALUES,
} from "./initialValues";

const CUSTOM_TIME_RANGE_VALUE = "0";

export const prepareCustomChartOutput = (customchartData, values) => {
  const newRanges = values.dashboard_custom_chart_range.map((item) => ({
    ...item,
    min: item.min.toString(),
    max: item.max.toString(),
  }));

  const rangesDelete =
    customchartData?.dashboard_custom_chart?.dashboard_custom_chart_range
      ?.map((r) => r.id)
      ?.filter(
        (item) =>
          !values.dashboard_custom_chart_range?.map((e) => e.id)?.includes(item)
      ) || [];

  const dataUpdate = {
    ...values,
    time_range:
      values["data_mode"] === HISTORICAL_DATA
        ? values["historical_time_range"]
        : values["prediction_time_range"],
    time_range_type:
      values["data_mode"] === HISTORICAL_DATA
        ? values["historical_time_range_type"]
        : values["prediction_time_range_type"],
    dashboard_custom_chart_range: newRanges,
    dashboard_custom_chart_range_delete: rangesDelete,
    historical_start_time:
      values.historical_start_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_start_time)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
        : "",
    historical_end_time:
      values.historical_end_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_end_time)
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
        : "",
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareAlarmSummaryOutput = (values) => {
  const output = {
    ...values,
    dataUpdate: {
      ...values,
      is_public: true,
      time_point:
        values.data_mode !== HISTORICAL_DATA
          ? values.prediction_time_range
          : "",
      time_point_type:
        values.data_mode !== HISTORICAL_DATA
          ? values.prediction_time_range_type
          : "",
      time_range:
        values.data_mode === HISTORICAL_DATA
          ? values.historical_time_range
          : "",
      time_range_type:
        values.data_mode === HISTORICAL_DATA
          ? values.historical_time_range_type
          : "",
      historical_start_time:
        values.historical_start_time &&
        values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
          ? moment(values.historical_start_time)
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
          : "",
      historical_end_time:
        values.historical_end_time &&
        values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
          ? moment(values.historical_end_time)
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
          : "",
    },
  };

  return output;
};

export const prepareAttachmentImageOutput = (values, imageFile, image) => {
  const dataUpdate = {
    ...values,
    link: values.upload_type === UPLOAD_TYPE.STATIC_IMAGE ? "" : values.link,
    image_format:
      values.upload_type === UPLOAD_TYPE.STATIC_IMAGE
        ? ""
        : values.image_format,
    scan_time:
      values.upload_type === UPLOAD_TYPE.STATIC_IMAGE ? "" : values.scan_time,
    imageFile:
      values.upload_type === UPLOAD_TYPE.DYNAMIC_IMAGE ? "" : imageFile,
    image: values.upload_type === UPLOAD_TYPE.DYNAMIC_IMAGE ? "" : image,
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareDirectionOutput = (values) => {
  const output = {
    ...values,
    dataUpdate: {
      ...values,
      is_public: true,
      time_point:
        values["data_mode"] === PREDICTION_DATA
          ? values["prediction_time_range"]
          : undefined,
      time_point_type:
        values["data_mode"] === PREDICTION_DATA
          ? values["prediction_time_range_type"]
          : undefined,
    },
  };

  return output;
};

export const prepareGaugeChartOutput = (values) => {
  const dataUpdate = {
    ...values,
    time_point:
      values["data_mode"] === PREDICTION_DATA
        ? values["prediction_time_range"]
        : undefined,
    time_point_type:
      values["data_mode"] === PREDICTION_DATA
        ? values["prediction_time_range_type"]
        : undefined,
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareHistoricTableOutput = (data, values) => {
  const dataUpdate = {
    ...values,
    is_public: true,
    dashboard_historic_table_parameter: (values?.params || [])
      .filter((item) => item.parameter_id !== -1)
      .map((item) => ({
        id: item.id,
        parameter_id: item.parameter_id,
        station_id: item.station_id,
        main_color: item.main_color,
        alarm_color: item.alarm_color,
        display_type: item.display_type,
        dashboard_historic_table_id: data?.dashboard_historic_table?.id,
        order_number: item.order_number,
        display_name: item.display_name || "",
      })),
    dashboard_historic_table_parameter_delete: values.params_delete || [],
    time_point:
      values.data_mode !== HISTORICAL_DATA ? values.prediction_time_range : "",
    time_point_type:
      values.data_mode !== HISTORICAL_DATA
        ? values.prediction_time_range_type
        : "",
    time_range:
      values.data_mode === HISTORICAL_DATA ? values.historical_time_range : "",
    time_range_type:
      values.data_mode === HISTORICAL_DATA
        ? values.historical_time_range_type
        : "",
    data_mode: [HISTORICAL_DATA, PREDICTION_DATA, COMBINED_DATA].includes(
      values.data_mode
    )
      ? values.data_mode
      : HISTORIC_TABLE_INITIALVALUES.data_mode,
    historical_start_time:
      values.historical_start_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_start_time)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
        : "",
    historical_end_time:
      values.historical_end_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_end_time)
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
        : "",
    aggregate_function: values.aggregate_function,
    interval: values.interval,
  };

  const output = {
    ...values,
    dataUpdate,
    date: moment().format(DATE_TIME_FORMAT),
  };

  return output;
};

export const prepareAggregateTableOutput = (data, values) => {
  if (values.params?.length > 0) {
    values.params = values.params.map((item) => ({
      ...item,
      parameter_id: item.parameter.id || null,
    }));
  }

  const dataUpdate = {
    ...values,
    is_public: true,
    start_date: values.start_date
      ? moment(values.start_date)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      : "",
    end_date: values.end_date
      ? moment(values.end_date)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
      : "",
  };

  const output = {
    ...values,
    dataUpdate,
    date: moment().format(DATE_TIME_FORMAT),
  };

  return output;
};

export const preparePredictionLineChartOutput = (data, values) => {
  const dataUpdate = {
    ...values,
    is_public: true,
    dashboard_historic_table_parameter: (values?.params || [])
      .filter((item) => item.parameter_id !== -1)
      .map((item) => ({
        id: item.id,
        parameter_id: item.parameter_id,
        station_id: item.station_id,
        main_color: item.main_color,
        alarm_color: item.alarm_color,
        display_type: item.display_type,
        dashboard_historic_table_id: data?.dashboard_historic_table?.id,
      })),
    dashboard_historic_table_parameter_delete: values.params_delete || [],
    time_point:
      values.data_mode !== HISTORICAL_DATA ? values.prediction_time_range : "",
    time_point_type:
      values.data_mode !== HISTORICAL_DATA
        ? values.prediction_time_range_type
        : "",
    time_range:
      values.data_mode === HISTORICAL_DATA ? values.historical_time_range : "",
    time_range_type:
      values.data_mode === HISTORICAL_DATA
        ? values.historical_time_range_type
        : "",
    data_mode: [HISTORICAL_DATA, PREDICTION_DATA, COMBINED_DATA].includes(
      values.data_mode
    )
      ? values.data_mode
      : HISTORIC_TABLE_INITIALVALUES.data_mode,
    historical_start_time:
      values.historical_start_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_start_time)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
        : "",
    historical_end_time:
      values.historical_end_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_end_time)
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
        : "",
  };

  const output = {
    ...values,
    dataUpdate,
    date: moment().format(DATE_TIME_FORMAT),
  };

  return output;
};

export const prepareLocatopnMapOutput = (values) => {
  const dataUpdate = {
    ...values,
    is_public: true,
    dashboard_location_map_station: (
      values.dashboard_location_map_station || []
    ).filter((x) => x?.station_id && x?.station_id !== ""),
    dashboard_location_map_station_delete:
      values.dashboard_location_map_station_delete || [],
    time_point:
      values["data_mode"] === PREDICTION_DATA
        ? values["prediction_time_range"]
        : undefined,
    time_point_type:
      values["data_mode"] === PREDICTION_DATA
        ? values["prediction_time_range_type"]
        : undefined,
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareStandardChartOutput = (data, values) => {
  const dataUpdate = {
    ...values,
    prediction_time_range:
      values["data_mode"] === PREDICTION_DATA ||
      values["data_mode"] === COMBINED_DATA
        ? values["prediction_time_range"]
        : undefined,
    prediction_time_range_type:
      values["data_mode"] === PREDICTION_DATA ||
      values["data_mode"] === COMBINED_DATA
        ? values["prediction_time_range_type"]
        : undefined,
    historical_time_range:
      values["data_mode"] === HISTORICAL_DATA ||
      values["data_mode"] === COMBINED_DATA
        ? values["historical_time_range"]
        : undefined,
    historical_time_range_type:
      values["data_mode"] === HISTORICAL_DATA ||
      values["data_mode"] === COMBINED_DATA
        ? values["historical_time_range_type"]
        : undefined,
    dashboard_standard_chart_parameter:
      values["dashboard_standard_chart_parameter"]?.length > 0
        ? values["dashboard_standard_chart_parameter"].map((item, index) => ({
            ...item,
            id: item.originId || "",
            index,
          }))
        : [],
    dashboard_standard_chart_parameter_delete:
      data?.dashboard_standard_chart?.dashboard_standard_chart_parameter
        ?.map((item) => item.id)
        ?.filter(
          (item) =>
            !values["dashboard_standard_chart_parameter"]
              ?.map((item) => item.id)
              ?.includes(item)
        ) || [],
    historical_start_time:
      values.historical_start_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_start_time)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
        : "",
    historical_end_time:
      values.historical_end_time &&
      values.historical_time_range === CUSTOM_TIME_RANGE_VALUE
        ? moment(values.historical_end_time)
            .set("hour", 23)
            .set("minute", 59)
            .set("second", 59)
        : "",
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareStaticTextOutput = (values) => {
  const dataUpdate = {
    name_en: values.name_en,
    name_vi: values.name_vi,
    is_public: true,
    content_type: values.content_type,
    content: values.content?.replace(/&lt;/g, "<")?.replace(/&gt;/g, ">"),
    header_color: values.header_color,
    show_header: values.show_header,
  };

  const output = {
    ...values,
    dataUpdate,
  };

  return output;
};

export const prepareValueListOutput = (id, values) => {
  const dataUpdate = {
    ...values,
    is_public: true,
    dashboard_value_list_parameter: (values?.params || [])
      .filter((item) => item.parameter_id !== -1)
      .map((item) => ({
        id: item.id,
        dashboard_value_list_id: id,
        parameter_id: item.parameter_id,
        station_id: item.station_id,
        main_color: item.main_color,
        alarm_color: item.alarm_color,
        order_number: item.order_number,
        display_name: item.display_name,
      })),
    dashboard_value_list_parameter_delete: values.params_delete || [],
    time_point:
      values["data_mode"] === "PREDICTION_DATA"
        ? values["prediction_time_range"]
        : undefined,
    time_point_type:
      values["data_mode"] === "PREDICTION_DATA"
        ? values["prediction_time_range_type"]
        : undefined,
    data_mode: ["HISTORICAL_DATA", "PREDICTION_DATA", "COMBINED_DATA"].includes(
      values.data_mode
    )
      ? values.data_mode
      : VALUE_INITIALVALUES.data_mode,
  };
  const output = {
    ...values,
    date: moment().format(DATE_TIME_FORMAT),
    dataUpdate,
  };

  return output;
};

export const prepareValueOutput = (values) => {
  const dataUpdate = {
    ...values,
    is_public: true,
    time_point:
      values["data_mode"] === "PREDICTION_DATA"
        ? values["prediction_time_range"]
        : undefined,
    time_point_type:
      values["data_mode"] === "PREDICTION_DATA"
        ? values["prediction_time_range_type"]
        : undefined,
    data_mode: ["HISTORICAL_DATA", "PREDICTION_DATA", "COMBINED_DATA"].includes(
      values.data_mode
    )
      ? values.data_mode
      : VALUE_INITIALVALUES.data_mode,
  };

  const output = {
    ...values,
    date: moment().format(DATE_TIME_FORMAT),
    dataUpdate,
  };

  return output;
};
