import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SharedInputField from "app/shared/components/SharedInputField";
import React from "react";
import { FormattedMessage } from "react-intl";

const Location = ({
  mainData,
  locationType,
  handleLocationTypeChange,
  formik,
  language,
  intl,
  clsx,
  masterData
}) => {
  return (
    <>
      <h3 className="sub-title">
        <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.LOCATION_TITLE" />
      </h3>

      <Box mb={3}>
        <Box className="form-label">
          {
            mainData.status && mainData.status.id === 1 &&
            <p className="required">*</p>
          }
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.LOCATION_TYPE_LABEL" />
        </Box>
        <RadioGroup
          className="radio-group"
          value={locationType}
          onChange={handleLocationTypeChange}
        >
          <Box display="flex">
            {masterData.locationTypes.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
                control={<Radio size="small" color="primary" />}
                label={item[`name_${language}`]}
              />
            ))}
          </Box>
        </RadioGroup>
      </Box>

      {
        locationType === 1 ?
          <Box display="flex" justifyContent="space-between">
            <Box mb={3} width="49%">
              <SharedInputField
                name="latitude"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LATITUDE_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LATITUDE_HINT' })}
                {...formik.getFieldProps("latitude")}
                disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
                className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
                required={mainData.status && mainData.status.id === 1}
                errorMsg={formik.touched["latitude"] && formik.errors["latitude"]}
              />
            </Box>

            <Box mb={3} width="49%">
              <SharedInputField
                name="longtitude"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LONGTITUDE_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.LONGTITUDE_HINT' })}
                {...formik.getFieldProps("longtitude")}
                disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
                className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
                required={mainData.status && mainData.status.id === 1}
                errorMsg={formik.touched["longtitude"] && formik.errors["longtitude"]}
              />
            </Box>
          </Box> :
          <Box mb={3}>
            <SharedInputField
              name="address"
              required={mainData.status && mainData.status.id === 1}
              label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADDRESS_LABEL' })}
              type="text"
              placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADDRESS_HINT' })}
              {...formik.getFieldProps("address")}
              disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
              className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
              errorMsg={formik.touched["address"] && formik.errors["address"]}
            />
          </Box>
      }
    </>
  )
}

export default Location