import { combineReducers } from "redux";
import userReducer from "./userReducer";
import chatReducer from "./chatReducer";
import endUserChatReducer from "./endUserChatReducer";
import notLoginEndUserChatReducer from "./notLoginEndUserChatReducer";
import dashboardReducer from "./dashboardReducer";
import topMenuReducer from "./topMenuReducer";
import chartRangeReducer from "./chartRangeReducer";
import chatMessageReducer from "./chatMessageReducer";
import sidebarReducer from "./sidebar.reducer";
import stationPanelReducer from "./station-panel.reducer";
import mapReducer from "./mapReducer";

export default combineReducers({
  chatMessage: chatMessageReducer,
  user: userReducer,
  chat: chatReducer,
  endUserChat: endUserChatReducer,
  notLoginEndUserChat: notLoginEndUserChatReducer,
  dashboard: dashboardReducer,
  topMenu: topMenuReducer,
  chartRange: chartRangeReducer,
  sidebar: sidebarReducer,
  stationPanel: stationPanelReducer,
  maps: mapReducer,
});
