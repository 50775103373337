import axiosClient from "./axiosClient";

const endUserChatApi = {
  initChatRoom(data, token) {
    const url = "/api/v1/room-chat/create-room-chat-for-end-user";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  createToken(data) {
    const url = "/api/v1/room-chat/create-room-chat-for-end-user/no-login";
    return axiosClient.post(url, data)
  },
  getEndUserMessageList(data) {
    const url = "/api/v1/room-chat-message/request-messages-of-end-user";
    return axiosClient.post(url, data)
  },
}

export default endUserChatApi;
