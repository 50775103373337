import axiosClient from "./axiosClient";

const dataFileApi = {
  getDataFile(id) {
    const url = `/api/v1/data-file/${id}`;
    return axiosClient.get(url, {});
  },
  deleteDataFile(id) {
    const url = `/api/v1/data-file/${id}`;
    return axiosClient.delete(url, {});
  },
  getAllDataFiles(params) {
    const url = `/api/v1/data-file/`;
    return axiosClient.get(url, { params });
  },
  getAllStatus() {
    const url = `/api/v1/data-file/status/`;
    return axiosClient.get(url, {});
  },
  getUsers() {
    const url = "/api/v1/data-sharing-request/enduser/";
    return axiosClient.get(url, {});
  },
  getDataSharingRequestByUsers(id) {
    const url = `/api/v1/data-sharing-request/approved-of-user/${id}`;
    return axiosClient.get(url, {});
  },
  addDataFile(data) {
    const url = "/api/v1/data-file/";
    return axiosClient.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateDataFile(id, data) {
    const url = `/api/v1/data-file/${id}`;
    return axiosClient.put(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  pushDataFile(id) {
    const url = `/api/v1/data-file/${id}/push`;
    return axiosClient.get(url, {});
  },
};

export default dataFileApi;
