/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useLanguage } from '../../../../../shared/hooks';
import { prepareGaugeChartOutput } from '../helpers';
import './styles.css';
import ModalContainer from '../../common/ModalContainer';
import GaugeChartModalSchema from '../schemas/GaugeChartFormSchema';
import useModal from '../useModal';
import GaugeChartForm from './GaugeChartForm';
import { GAUGE_CHART_INITIALVALUES } from "../initialValues";

function MenuModal(props) {
  const { intl, handleClose, open } = props;
  const [language] = useLanguage();

  const {
    openModal,
    setOpenModal } = useModal({
      language,
      openModal: open,
      handleClose
    })

  const toggleOpen = () => {
    props['handleClose']();
  };

  const formik = useFormik({
    initialValues: {
      // ...initialValues,
      name_en: props.data?.name_en || '',
      name_vi: props.data?.name_vi || '',
      header_color: props.data?.header_color || GAUGE_CHART_INITIALVALUES.header_color,
      main_color: props.data?.dashboard_gauge_chart?.main_color || GAUGE_CHART_INITIALVALUES.main_color,
      data_alarm: props.data?.dashboard_gauge_chart?.parameter?.data_alarm || [],
      parameter_id: props.data?.dashboard_gauge_chart?.parameter_id,
      station_id: props.data?.dashboard_gauge_chart?.station_id,
      parameter_display: '',
      show_header: props.data?.show_header === undefined ? GAUGE_CHART_INITIALVALUES.show_header : props.data.show_header,
      data_mode: props.data?.data_mode || GAUGE_CHART_INITIALVALUES.data_mode,
      chart_type: props.data?.dashboard_gauge_chart?.chart_type || GAUGE_CHART_INITIALVALUES.chart_type,
      prediction_time_range: (props.data?.time_point && props.data?.time_point !== "0") ? props.data?.time_point : GAUGE_CHART_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: (props.data?.time_point_type && props.data?.time_point_type !== "0") ? props.data?.time_point_type : GAUGE_CHART_INITIALVALUES.prediction_time_range_type,
      range_type: props.data?.dashboard_gauge_chart?.range_type || GAUGE_CHART_INITIALVALUES.range_type,
      range_min: props.data?.dashboard_gauge_chart?.range_min || GAUGE_CHART_INITIALVALUES.range_min,
      range_max: props.data?.dashboard_gauge_chart?.range_max || GAUGE_CHART_INITIALVALUES.range_max,
      show_line: props.data?.dashboard_gauge_chart?.show_line === undefined ? GAUGE_CHART_INITIALVALUES.show_line : props.data?.dashboard_gauge_chart?.show_line,
      show_number: props.data?.dashboard_gauge_chart?.show_number === undefined ? GAUGE_CHART_INITIALVALUES.show_number : props.data?.dashboard_gauge_chart?.show_number,
    },
    enableReinitialize: true,
    validationSchema: GaugeChartModalSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props['handleEdit'](id, prepareGaugeChartOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      disabledSave={formik.isSubmitting ||
        !formik.isValid}
      toggleOpen={toggleOpen}
      newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_GAUGE_CHART" />}
      editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_GAUGE_CHART" />}>
      <GaugeChartForm
        formik={formik}
        intl={intl}
        parameters={props?.parameters || []}
        language={language} />
    </ModalContainer>
  )
}

export default injectIntl(MenuModal)
