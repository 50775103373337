import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DateRangePicker } from "../MUIDateRangePicker";
import { injectIntl } from "react-intl";
import moment from "moment";
import clsx from "clsx";
import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from "date-fns";

import "./styles.css";
import { DATE_FORMAT } from "../../../constant/date-time-format";

function DateRangePickerComponent(props) {
  const { intl, className, left, bottom, minDate, maxDate } = props;
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [predefinedRanges, setPredefinedRanges] = React.useState([]);

  const toggle = () => setOpen(!open);

  const onChangeRange = (range) => {
    props["onChange"](range);

    setOpen(false);
  };

  const getDefaultRanges = (date) => [
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
      }),
      startDate: null,
      endDate: null,
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_TODAY",
      }),
      startDate: date,
      endDate: date,
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_YESTERDAY",
      }),
      startDate: addDays(date, -1),
      endDate: addDays(date, -1),
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_THIS_WEEK",
      }),
      startDate: startOfWeek(date),
      endDate: endOfWeek(date),
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_LAST_WEEK",
      }),
      startDate: startOfWeek(addWeeks(date, -1)),
      endDate: endOfWeek(addWeeks(date, -1)),
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_LAST_7_DAYS",
      }),
      startDate: addWeeks(date, -1),
      endDate: date,
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_THIS_MONTH",
      }),
      startDate: startOfMonth(date),
      endDate: endOfMonth(date),
    },
    {
      label: intl.formatMessage({
        id: "CLIENT.GENERAL.DATE_RANGE_PICKER_LAST_MONTH",
      }),
      startDate: startOfMonth(addMonths(date, -1)),
      endDate: endOfMonth(addMonths(date, -1)),
    },
  ];

  React.useEffect(() => {
    setDateRange(props["value"]);

    if (predefinedRanges.length === 0) {
      setPredefinedRanges(getDefaultRanges(new Date()));
    }
  }, [props["value"]]);

  return (
    <div className={clsx("date-range-picker-wrapper", className && className)}>
      {!props["isNormal"] ? (
        <>
          <div className="date-range-picker" onClick={toggle}>
            {dateRange["startDate"] && dateRange["endDate"] ? (
              <span className="date-range-picker-value">
                {moment(dateRange["startDate"]).format(DATE_FORMAT)} -{" "}
                {moment(dateRange["endDate"]).format(DATE_FORMAT)}
              </span>
            ) : (
              <span>
                {intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
                })}
              </span>
            )}
            <span className="date-range-picker-icon">
              <ArrowDropDownIcon />
            </span>
          </div>

          <div
            style={{
              position: "absolute",
              right: !left ? -34 : "unset",
              left: left || "unset",
              zIndex: "9999",
              ...(bottom && {
                bottom: bottom,
              }),
            }}
          >
            <DateRangePicker
              open={open}
              maxDate={maxDate}
              minDate={minDate}
              toggle={toggle}
              onChange={(range) => onChangeRange(range)}
              definedRanges={predefinedRanges}
              startDateLabel={intl.formatMessage({
                id: "CLIENT.GENERAL.DATE_RANGE_PICKER_START_DATE",
              })}
              endDateLabel={intl.formatMessage({
                id: "CLIENT.GENERAL.DATE_RANGE_PICKER_END_DATE",
              })}
              weekDaysLabels={[
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SUNDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MONDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_TUESDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_WEDNESDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_THURSDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_FRIDAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SATURDAY",
                }),
              ]}
              monthsLabels={[
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JANUARY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_FEBRUARY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MARCH",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_APRIL",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MAY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JUNE",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JULY",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_AUGUST",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SEPTEMBER",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_OCTOBER",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_NOVEMBER",
                }),
                intl.formatMessage({
                  id: "CLIENT.GENERAL.DATE_RANGE_PICKER_DECEMBER",
                }),
              ]}
            />
          </div>
        </>
      ) : (
        <DateRangePicker
          open
          onChange={(range) => onChangeRange(range)}
          maxDate={maxDate}
          minDate={minDate}
          toggle={toggle}
          definedRanges={predefinedRanges}
          startDateLabel={intl.formatMessage({
            id: "CLIENT.GENERAL.DATE_RANGE_PICKER_START_DATE",
          })}
          endDateLabel={intl.formatMessage({
            id: "CLIENT.GENERAL.DATE_RANGE_PICKER_END_DATE",
          })}
          weekDaysLabels={[
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SUNDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MONDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_TUESDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_WEDNESDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_THURSDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_FRIDAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SATURDAY",
            }),
          ]}
          monthsLabels={[
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JANUARY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_FEBRUARY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MARCH",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_APRIL",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_MAY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JUNE",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_JULY",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_AUGUST",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_SEPTEMBER",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_OCTOBER",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_NOVEMBER",
            }),
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE_RANGE_PICKER_DECEMBER",
            }),
          ]}
        />
      )}
    </div>
  );
}

export default injectIntl(DateRangePickerComponent);
