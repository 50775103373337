export const getRandomColor = () => {
  return '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
}

export const getRandomDarkColor = () => {
  let color = '';
  do {
    color = '#' + (0x1000000 + (Math.random() * 0xffffff)).toString(16).substr(1, 6);
  } while (parseInt(color.substr(1, 2), 16) + parseInt(color.substr(3, 2), 16) + parseInt(color.substr(5, 2), 16) > 382);
  return color;
}
