import { useLanguage } from "app/shared/hooks";
import React from "react";
import { MODAL_STYLES } from "./contants";

const useModal = ({ handleClose, modalOpen }) => {
  const [language] = useLanguage();
  const [openModal, setOpenModal] = React.useState(modalOpen || false);
  const [modalStyle] = React.useState(MODAL_STYLES);

  const toggleOpen = () => {
    if (handleClose) {
      handleClose();
    }
  };
  return {
    language,
    openModal,
    modalStyle,
    setOpenModal,
    toggleOpen,
  }
}

export default useModal;