import React from "react";

import "./styles.css";

import ReactResizeDetector from "react-resize-detector";
import { invertColor } from "app/utils/utils";

const ArcGauge = (props) => {
  const MAX_PORTION = 20;
  const SPACE_BETWEEN_TWO_LINE = 50;
  const SPACE_BETWEEN_LABEL_CIRCLE = 15;
  const LINE_LENGTH = 8;
  const CHART_SIZE_RATE = 0.8;
  const BOTTOM = 0.22;

  const maxValue = props["maxValue"] || 100;
  const minValue = props["minValue"] || 0;
  const value = props["value"] || 0;
  const nearOptions = [2, 4, 5, 10];

  const color = props["color"] || "#46AE4E";
  const label = props["label"] || "";

  const refChartContainer = React.useRef(null);

  const [data, setData] = React.useState([]);

  const [paneConfig, setPaneConfig] = React.useState({
    containerWidth: 0,
    containerHeight: 0,
    halfCircumference: 0,
    halfCircumferenceInner: 0,
    x: 0,
    y: 0,
    r: 0,
    d: 0,
    rInner: 0,
    rHint: 0,
    valueWidth: 0,
    valueOffset: 0,
    lineDefault: [0, 0, 0, 0],
    pointDefault: [0, 0, 0, 0],
  });

  const getNumOfPortion = (circumference) => {
    var num = Math.min(
      Math.round(Math.floor(circumference / SPACE_BETWEEN_TWO_LINE) / 5) * 5,
      MAX_PORTION
    );
    while (180 % num !== 0) {
      num--;

      if (num <= 2) {
        num = 2;
        break;
      }
    }
    return num;
  };

  const roundNear = (number, value) => {
    return (Math.round(number * value) / value).toFixed(2);
  };

  const getPercent = (value) => {
    const range = maxValue - minValue;
    return (value - minValue) / Math.abs(range);
  };

  const getHalfCircumference = (r) => {
    return r * Math.PI;
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ].join(" ");

    return d;
  };

  const setSize = () => {
    const containerWidth = refChartContainer?.current?.clientWidth;
    const containerHeight = refChartContainer?.current?.clientHeight;
    const d = CHART_SIZE_RATE * Math.min(containerWidth, containerHeight);
    const r = d / 2;
    const rInner = r * 0.75;
    const rHint = rInner - 5;
    const percent = getPercent(value);
    const halfCircumference = getHalfCircumference(rInner);
    const offset = halfCircumference - percent * halfCircumference * -1;
    const x = refChartContainer?.current?.clientWidth / 2;
    const y =
      refChartContainer?.current?.clientHeight - BOTTOM * containerHeight;
    setPaneConfig({
      containerWidth: containerWidth,
      containerHeight: containerHeight,
      d: d,
      r: r,
      x: x,
      y: y,
      rInner: rInner,
      rHint: rHint,
      valueWidth: (r - rInner) * 2, // diameter of inner circle
      valueOffset: offset,
      halfCircumferenceInner: halfCircumference,
      lineDefault: [x - r, y, x - r + LINE_LENGTH, y], // 8 is the length of line
      pointDefault: [x - r - SPACE_BETWEEN_LABEL_CIRCLE, y],
      halfCircumference: getHalfCircumference(r),
    });
  };

  const rotateWithDefaultCenter = (x, y, degree) => {
    var angleInRadians = (degree * Math.PI) / 180.0;
    const xa =
      paneConfig.x +
      (x - paneConfig.x) * Math.cos(angleInRadians) -
      (y - paneConfig.y) * Math.sin(angleInRadians);
    const ya =
      paneConfig.y +
      (x - paneConfig.x) * Math.sin(angleInRadians) +
      (y - paneConfig.y) * Math.cos(angleInRadians);
    return {
      x: xa,
      y: ya,
    };
  };

  const drawData = () => {
    var nearOptionIndex = 0;
    var numOfPortion = getNumOfPortion(paneConfig.halfCircumference);
    const range = maxValue - minValue;
    var step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
    while (step == 0) {
      nearOptionIndex++;
      step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      while (nearOptionIndex == 3 && step == 0) {
        numOfPortion = numOfPortion * 0.8;
        step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      }
    }

    var vl = Number(Math.floor(minValue));
    var newDataLines = [];
    while (vl <= maxValue) {
      if (vl >= minValue) {
        vl = Number(Number(vl).toFixed(2));
        newDataLines.push({
          value: vl,
          label: Number(vl) > 1000 ? `${Math.floor(vl / 1000)}k` : vl,
        });
      }
      vl += Number(step);
    }
    setData(newDataLines);
  };

  const onResize = React.useCallback(() => {
    if (refChartContainer) {
      setSize();
    }
  }, [refChartContainer, props["minValue"], props["maxValue"], props["value"]]);

  React.useEffect(() => {
    if (refChartContainer) {
      setSize();
    }
  }, [refChartContainer, props["minValue"], props["maxValue"], props["value"]]);

  React.useEffect(() => {
    if (paneConfig.halfCircumference > 0) drawData();
  }, [paneConfig]);

  // React.useEffect(() => {
  //   if (refChartContainer) {
  //     setSize();
  //   }
  // }, [props["minValue"], props["maxValue"], props["value"]]);

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      onResize={onResize}
      refreshRate="500"
      refreshMode="debounce"
    >
      {({ width, height }) => (
        <div className="arc-gauge" ref={refChartContainer}>
          <div
            className="arc-gauge-container"
            style={{
              width: paneConfig.containerWidth,
              height: paneConfig.containerHeight,
              color: invertColor(color),
            }}
          >
            <svg
              viewBox={`0 0 ${paneConfig.containerWidth} ${paneConfig.containerHeight}`}
            >
              <path
                fill={`${invertColor(color)}4d`}
                d={describeArc(
                  paneConfig.x,
                  paneConfig.y,
                  paneConfig.r,
                  -90,
                  90
                )}
              ></path>

              <path
                fill="transparent"
                strokeWidth={paneConfig.valueWidth}
                stroke={invertColor(color)}
                strokeDasharray={`${paneConfig.halfCircumferenceInner} ${paneConfig.halfCircumferenceInner}`}
                strokeDashoffset={paneConfig.valueOffset}
                d={describeArc(
                  paneConfig.x,
                  paneConfig.y,
                  paneConfig.rInner,
                  -90,
                  90
                )}
              ></path>

              <path
                fill={color}
                d={describeArc(
                  paneConfig.x,
                  paneConfig.y + 1,
                  paneConfig.rInner - 1,
                  -90,
                  90
                )}
              ></path>

              {props["showLines"] && data.length > 0 && (
                <g>
                  {data.map((line, index) => {
                    const alpha = getPercent(line.value) * 180;

                    if (line.value > maxValue) return null;

                    return (
                      <path
                        key={line.value}
                        fill="none"
                        stroke={invertColor(color)}
                        strokeWidth="1.2"
                        d={`M ${paneConfig.lineDefault[0]} ${paneConfig.lineDefault[1]}
                        L ${paneConfig.lineDefault[2]} ${paneConfig.lineDefault[3]}`}
                        opacity="1"
                        transform={`rotate(${alpha} ${paneConfig.x} ${paneConfig.y})`}
                      ></path>
                    );
                  })}
                </g>
              )}

              {props["showNumbers"] && data.length > 0 && (
                <g>
                  {data.map((line, index) => {
                    var alpha = getPercent(line.value) * 180;
                    const { x, y } = rotateWithDefaultCenter(
                      paneConfig.pointDefault[0],
                      paneConfig.pointDefault[1],
                      alpha
                    );
                    return (
                      <text
                        x={x}
                        y={y}
                        fill={invertColor(color)}
                        font-family="sans-serif"
                        font-size="12"
                        text-anchor="middle"
                        alignment-baseline="middle"
                      >
                        {line.label}
                      </text>
                    );
                  })}
                </g>
              )}
            </svg>
            <div className="arc-gauge-info">
              <div className="circular-gauge-currentvalue">
                <p
                  style={{
                    fontSize: Math.min(paneConfig.r * 0.18, 60),
                    color: invertColor(color),
                  }}
                >
                  {value}
                </p>
                <p
                  style={{
                    fontSize: Math.min(paneConfig.r * 0.13, 43),
                    color: invertColor(color),
                  }}
                >{`(${props["unit"]})`}</p>
              </div>
              <div
                className="circular-gauge-currentstate"
                style={{
                  fontSize: Math.min(paneConfig.r * 0.13, 30),
                  color: invertColor(color),
                }}
              >
                <span
                  style={{
                    width: paneConfig.r + paneConfig.valueWidth - 20,
                    color: invertColor(color),
                  }}
                >
                  {label}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactResizeDetector>
  );
};

export default ArcGauge;
