const { useRef, useState, useEffect } = require("react");

const FitText = ({ minFontSize, compressor, maxFontSize, children }) => {
  const parentRef = useRef(null);
  const [size, setSize] = useState();
  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [parentRef]);

  const handleResize = (entries) => {
    entries.forEach((entry) => {
      const { width, height } = entry.contentRect;
      if (width > 250) {
        let widthChange = (compressor * 250) / (maxFontSize - minFontSize);
        let sizeNew = Math.floor(minFontSize + (width - 250) / widthChange);
        if (sizeNew > maxFontSize) {
          setSize(maxFontSize);
        } else {
          setSize(sizeNew);
        }
      } else {
        setSize(minFontSize);
      }
    });
  };

  return (
    <div className="flex">
      <div
        className="w-full "
        ref={parentRef}
        style={{
          fontSize: size,
        }}
      >
        {children}
      </div>
    </div>
  );
};
export default FitText;
