const termsContent = [
  {
    id: 1,
    title: {
      vi: "Phạm vi áp dụng",
      en: "Scope of application",
    },
    content: {
      vi: "Điều khoản sử dụng này áp dụng cho dịch vụ phần mềm VnEmisoft. Bằng cách sử dụng dịch vụ, bạn tuyên bố và đảm bảo rằng bạn có đầy đủ quyền, năng lực và thẩm quyền để tham gia vào Thỏa thuận này và đồng ý thực hiện đầy đủ tất cả các nghĩa vụ của bạn dưới đây. VnEmisoft có thể chỉnh sửa, cập nhật bản điều khoản sử dụng dịch vụ này bất cứ lúc nào mà không cần báo trước. Việc chỉnh sửa này sẽ có hiệu lực ngay khi được cập nhật trên phần mềm. Bạn cần thường xuyên kiểm tra các thay đổi này và việc bạn tiếp tục sử dụng dịch vụ có nghĩa là bạn chấp nhận và đồng ý tuân theo điều khoản sử dụng mới được cập nhật. Bất cứ sự vi phạm nào của bạn đối với các điều khoản và điều kiện này đều có thể dẫn đến việc đình chỉ hoặc kết thúc tài khoản.",
      en: "These Terms of Use apply to VnEmisoft software services. By using the Service, you represent and warrant that you have the full right, capacity and authority to enter into this Agreement and agree to fulfill all of your obligations hereunder. VnEmisoft may modify and update these terms of service at any time without prior notice. This modification will take effect as soon as it is updated on the software. You should regularly check for these changes and your continued use of the service means that you accept and agree to abide by the updated terms of use. Any breach by you of these terms and conditions may result in account suspension or termination.",
    },
  },
  {
    id: 2,
    title: {
      vi: "Tài khoản sử dụng phần mềm",
      en: "Software user account",
    },
    content: {
      vi: `Tài khoản của bạn cung cấp cho bạn quyền truy cập vào các dịch vụ và chức năng mà VnEmisoft có thể thiết lập, duy trì theo thời gian và theo quyết định riêng của VnEmisoft.
      Tài khoản của bạn bao gồm tên người dùng và mật khẩu do bạn chọn.
      Bạn không bao giờ được sử dụng tài khoản của Người dùng khác mà không được phép. Trong quá trình đăng ký, bạn có nghĩa vụ cung cấp các thông tin chính xác và đầy đủ theo yêu cầu của VnEmisoft.
      Bạn hoàn toàn chịu trách nhiệm về hoạt động xảy ra trên tài khoản của mình. Chúng tôi khuyến khích bạn sử dụng mật khẩu “mạnh” (mật khẩu sử dụng kết hợp chữ hoa và chữ thường, số và ký hiệu) cho tài khoản của mình. VnEmisoft khuyến cáo bạn đổi mật khẩu sau khi cung cấp mật khẩu cho các bên liên quan nhằm mục đích hỗ trợ sử dụng
      Nếu phát hiện ra tài khoản cá nhân bị lộ mật khẩu hoặc bị người khác sử dụng sai mục đích, bạn phải tiến hành đổi mật khẩu và thông báo ngay cho chúng tôi tại support@vnemisoft.com
      `,
      en: `Your account provides you with access to services and functions that VnEmisoft may establish, maintain from time to time and at VnEmisoft's sole discretion.
      Your account includes a username and password of your choice.
      You may never use another User's account without permission. During the registration process, you are obliged to provide accurate and complete information as required by VnEmisoft.
      You are solely responsible for the activity that occurs on your account. We encourage you to use a “strong” password (a password that uses a combination of upper and lower case letters, numbers and symbols) for your account. VnEmisoft recommends that you change your password after providing it to relevant parties for the purpose of supporting the use of
      If you discover that your personal account has been leaked or misused by others, you must change your password and notify us immediately at support@vnemisoft.com`,
    },
  },
  {
    id: 3,
    title: {
      vi: "Sử dụng hợp pháp",
      en: "Legal Use",
    },
    content: {
      vi: `Bạn phải nhận thức và chấp nhận rằng bạn không được sử dụng dịch vụ để tuyên truyền nội dung chống Nhà nước Cộng hòa xã hội chủ nghĩa Việt Nam; kích động gây bạo loạn, phá rối an ninh, gây rối trật tự công cộng; làm nhục, vu khống; xâm phạm trật tự quản lý kinh tế theo quy định tại điều 16 Luật An ninh mạng số 24/2018/QH14 hiệu lực từ ngày 01/01/2019. Đặc biệt các nội dung này cũng không được trái với các quy định khác của pháp luật nhà nước hiện hành và quy tắc đạo đức.
      Bạn chỉ có thể tải lên các nội dung không vi phạm quyền của bên thứ ba, cụ thể là quyền được bảo vệ dữ liệu cá nhân, quyền sở hữu trí tuệ, bao gồm cả bản quyền. Bạn phải. hoàn toàn chịu trách nhiệm về tính chính xác của nội dung tải lên.
      VnEmisoft không chịu bất kỳ trách nhiệm nào đối với các nội dung Người dùng mà bạn hoặc bất kỳ Người dùng khác hoặc bên thứ 3 đăng tải hoặc hành động thông qua nào liên quan việc đến sử dụng dịch vụ. Bạn sẽ hoàn toàn chịu trách nhiệm về nội dung của mình cũng như các hậu quả đi kèm
      `,
      en: `You must acknowledge and accept that you may not use the service to propagate content against the State of the Socialist Republic of Vietnam; inciting riots, disrupting security and disrupting public order; humiliate, slander; infringing upon the economic management order as prescribed in Article 16 of the Law on Cybersecurity No. 24/2018/QH14 effective from January 1, 2019. In particular, these contents must not contravene other provisions of current state laws and ethical codes.
      You can only upload content that does not infringe the rights of third parties, in particular the right to protect personal data, intellectual property rights, including copyright. You have to. solely responsible for the accuracy of the uploaded content.
      VnEmisoft is not responsible for any User Content that you or any other User or 3rd party posts or acts through in connection with the use of the Service. You will be solely responsible for your content and any consequences
      `,
    },
  },
  {
    id: 4,
    title: {
      vi: "Quyền sở hữu trí tuệ",
      en: "Intellectual property rights",
    },
    content: {
      vi: `Phần mềm VnEmisoft thuộc bản quyền của Công Ty TNHH Khoa Học Và Kỹ Thuật Reeco
      Tất cả nội dung tại phần mềm thuộc sở hữu của công ty. Nếu sử dụng, bạn phải bảo đảm là việc sử dụng nội dung phần mềm không vi phạm quyền, lợi ích của các cá nhân/tổ chức khác và phải ghi rõ nguồn từ chúng tôi. Bạn không được sao chép hoặc phân phối phần mềm mà không có sự cho phép bằng văn bản từ chúng tôi, đồng thời  bạn cũng không được đăng ký dù là cố ý hay vô ý bất kỳ thương hiệu hoặc logo nào tương tự có thể gây hiểu lầm hoặc trùng lặp với thương hiệu và logo của VnEmisoft.
      Khi bạn truy cập và sử dụng ứng dụng, VnEmisoft có thể thu thập và lưu trữ các thông tin như các số liệu thống kê quá trình truy cập, các thông tin cá nhân cung cấp cho VnEmisoft khi đăng ký.  Các thông tin cá nhân khách hàng cung cấp bao gồm nhưng không giới hạn bởi tên, ngày sinh, số điện thoại, địa chỉ email, địa chỉ thường trú, địa chỉ sử dụng dịch vụ.... VnEmisoft có quyền sử dụng các thông tin này vào việc lập kế hoạch, nghiên cứu, quản lý, tư vấn, hỗ trợ giữa Công ty với Khách hàng hoặc cung cấp thông tin cho các cơ quan pháp luật theo yêu cầu của Luật pháp.      
      `,
      en: `VnEmisoft software is copyrighted by Reeco Science and Technology Co., Ltd
      All content at the software is owned by the company. If you use it, you must ensure that the use of the software content does not violate the rights and interests of other individuals/organizations and must clearly state the source from us. You may not copy or distribute the software without our written permission, nor may you intentionally or unintentionally register any similar trademark or logo that may cause misunderstanding or duplication with VnEmisoft's trademark and logo.
      When you access and use the application, VnEmisoft may collect and store information such as access statistics, personal information provided to VnEmisoft when registering. The personal information provided by the customer includes but is not limited to name, date of birth, phone number, email address, permanent address, service use address.... VnEmisoft has the right to use the services. This information is used in planning, research, management, consulting, support between the Company and the Client or providing information to legal authorities as required by Law.
      `,
    },
  },
  {
    id: 5,
    title: {
      vi: "Bảo mật thông tin",
      en: "Information security",
    },
    content: {
      vi: `Bạn phải có trách nhiệm lưu giữ thông tin truy cập vào phần mềm để tránh trường hợp tài khoản bị đánh cắp hoặc bị lạm dụng với mục đích không an toàn cho cả bạn và VnEmisoft. VnEmisoft quan tâm đến tính toàn vẹn và bảo mật thông tin cá nhân của bạn. VnEmisoft cam kết giữ kín mọi thông tin của bạn khi sử dụng các dịch vụ trên phần mềm của chúng tôi và không tiết lộ cho bên thứ ba. VnEmisoft chỉ thay đổi thông tin của bạn trên phần mềm khi có sự đồng ý hoặc yêu cầu của bạn. Tuy nhiên, VnEmisoft không thể đảm bảo rằng các bên thứ ba trái phép sẽ không bao giờ có thể thực hiện được các biện pháp bảo mật của VnEmisoft hoặc sử dụng thông tin cá nhân của bạn cho các mục đích không chính đáng. Bạn xác nhận rằng bạn tự chịu rủi ro khi cung cấp thông tin cá nhân của mình.`,
      en: `You are responsible for maintaining access to the software to prevent your account from being stolen or misused for unsafe purposes for both you and VnEmisoft. VnEmisoft cares about the integrity and security of your personal information. VnEmisoft is committed to keeping all your information private when using the services on our software and not disclosing it to third parties. VnEmisoft only changes your information on the software with your consent or request. However, VnEmisoft cannot guarantee that unauthorized third parties will never be able to implement VnEmisoft's security measures or use your personal information for improper purposes. You acknowledge that you provide your personal information at your own risk.`,
    },
  },
  {
    id: 6,
    title: {
      vi: "Xử lí sự cố",
      en: "Troubleshooting",
    },
    content: {
      vi: `Bạn có trách nhiệm thông báo ngay cho VnEmisoft khi phát hiện sự cố để VnEmisoft khắc phục sớm nhất cho bạn tránh thất thoát gây thiệt hại cho bạn. Trong trường hợp sự cố liên quan đến bên thứ ba, bạn có trách nhiệm phối hợp với các bên liên quan để giải quyết. Bạn hiểu và chấp nhận rằng, trong mọi trường hợp, VnEmisoft luôn cố gắng hỗ trợ và khắc phục. Tuy nhiên, VnEmisoft sẽ không chịu trách nhiệm trước những thiệt hại phát sinh do sự chậm trễ không thông báo hoặc che giấu của bạn.`,
      en: `You are responsible for immediately informing VnEmisoft when detecting a problem so that VnEmisoft can fix it as soon as possible to avoid loss and damage to you. In the event of an incident involving a third party, it is your responsibility to coordinate with the parties involved to resolve it. You understand and accept that, in any case, VnEmisoft always tries to support and fix. However, VnEmisoft will not be liable for damages arising from your delay or concealment.`,
    },
  },
  {
    id: 7,
    title: {
      vi: "Giới hạn trách nhiệm",
      en: "Limitation of Liability",
    },
    content: {
      vi: `Bạn đồng ý với chúng tôi, bất kỳ việc sử dụng dịch vụ nào cũng có rủi ro riêng. Trong mọi trường hợp, VnEmisoft không chịu trách nhiệm đối với bất kỳ thiệt hại trực tiếp hay gián tiếp nào phát sinh từ việc sử dụng số liệu từ VnEmisoft đối với người sử dụng. Chúng tôi cũng không đảm bảo mọi nội dung trong phần mềm VnEmisoft  sẽ thỏa mãn mọi yêu cầu, tương thích hoặc phù hợp với mọi mục đích của người dùng; rằng dịch vụ không bị gián đoạn hoặc an toàn; rằng mọi khiếm khuyết hoặc sai sót sẽ được sửa chữa; hoặc rằng dịch vụ không có vi-rút hoặc các thành phần có hại khác. Bất kỳ nội dung nào được tải xuống hoặc có được thông qua việc sử dụng dịch vụ đều do bạn tự chịu rủi ro và bạn sẽ tự chịu trách nhiệm về bất kỳ thiệt hại nào đối với hệ thống máy tính hoặc thiết bị di động của mình hoặc mất mát dữ liệu do tải xuống đó hoặc việc bạn sử dụng dịch vụ.
      Bạn hoàn toàn bãi bỏ và miễn trừ VnEmisoft khỏi bất kỳ và mọi trách nhiệm pháp lý, khiếu nại, nguyên nhân khởi tố, hay thiệt hại nào xuất phát từ việc sử dụng phần mềm hoặc trong bất kỳ cách nào liên quan đến các bên thứ ba đã được giới thiệu tới bạn thông qua phần mềm.
      Bạn hoàn toàn chịu trách nhiệm về các tương tác của mình với những Người dùng VnEmisoft khác. Chúng tôi có quyền, nhưng không có nghĩa vụ giám sát các tranh chấp giữa bạn và những Người dùng khác. VnEmisoft sẽ không có trách nhiệm pháp lý đối với các tương tác của bạn với Người dùng khác, hoặc đối với bất kỳ hành động hoặc không hành động nào của Người dùng.
      VnEmisoft đồng thời không chịu trách nhiệm về bất kỳ tranh chấp phát sinh nào giữa bạn và bên thứ ba (nếu có) phát sinh từ  hoặc liên quan đến các giao dịch được thực hiện thông qua dịch vụ của phần mềm. 
      `,
      en: `You agree with us that any use of the service is at its own risk. Under no circumstances shall VnEmisoft be liable for any direct or indirect damages arising from the use of data from VnEmisoft against the user. We also do not guarantee that all content in VnEmisoft software will satisfy all requirements, be compatible or suitable for all purposes of users; that the service is uninterrupted or secure; that any defect or error will be corrected; or that the service is free of viruses or other harmful components. Any content downloaded or obtained through the use of the service is at your own risk and you will be solely responsible for any damage to your computer system or mobile device. yourself or data loss as a result of such download or your use of the service.
      You completely waive and release VnEmisoft from any and all liabilities, claims, causes of action, or damages arising out of the use of the software or in any way relating to the parties. third parties have been referred to you through the software.
      You are solely responsible for your interactions with other VnEmisoft Users. We have the right, but not the obligation, to monitor disputes between you and other Users. VnEmisoft will have no liability for your interactions with other Users, or for any actions or inactions of Users.
      VnEmisoft is also not responsible for any disputes arising between you and third parties (if any) arising out of or related to transactions made through the software's services.
      `,
    },
  },
  {
    id: 8,
    title: {
      vi: "Đảm bảo cung cấp dịch vụ",
      en: "Guaranteed service delivery",
    },
    content: {
      vi: `VnEmisoft không bảo đảm rằng luôn luôn sẵn sàng có thể sử dụng, không bị gián đoạn, không có lỗi. Bất kì một sản phẩm công nghệ nào đều có lỗi, ít hay nhiều và khắc phục nhanh hay chậm. Tuy nhiên, VnEmisoft cam kết cố gắng trong mọi điều kiện có thể để đảm bảo dịch vụ luôn được sẵn sàng.
      Bạn cần lưu ý rằng phần mềm được dựa trên những dịch vụ đường truyền internet và có thể bị mất điện hoặc gián đoạn, bị bên ngoài tấn công và xảy ra chậm trễ. Trong những trường hợp như vậy, VnEmisoft cam kết nỗ lực khắc phục sự gián đoạn và đưa ra sự điều chỉnh, sửa chữa và thay thế trong khả năng có thể để phục hồi hệ thống.      
      VnEmisoft đồng thời không chịu trách nhiệm về bất kỳ tranh chấp phát sinh nào giữa bạn và bên thứ ba (nếu có) phát sinh từ  hoặc liên quan đến các giao dịch được thực hiện thông qua dịch vụ của phần mềm. 
      `,
      en: `VnEmisoft does not guarantee that it is always ready to be used, without interruption, without errors. Any technology product has errors, more or less and quickly or slowly rectified. However, VnEmisoft is committed to trying in all possible conditions to ensure the service is always available.
      You should note that the software is based on internet connection services and is subject to power outages or interruptions, external attacks and delays. In such cases, VnEmisoft commits to make every effort to remedy the disruption and to make adjustments, repairs and replacements to the extent possible to restore the system.
      `,
    },
  },
  {
    id: 9,
    title: {
      vi: "Trường hợp vi phạm",
      en: "Infringement case",
    },
    content: {
      vi: `Nếu các Điều khoản sử dụng này bị vi phạm, VnEmisoft có thể, mà không cần thông báo trước cho bạn, xóa nội dung đó và bất kỳ nội dung nào khác mà bạn đã tải lên, ngay cả khi không đưa ra lý do và không phải bồi thường;
      Nếu các Điều khoản sử dụng này bị vi phạm, VnEmisoft có thể thay đổi Dịch vụ mà không cần thông báo trước; ngừng cung cấp Dịch vụ hoặc các tính năng của Dịch vụ cho bạn hoặc cho Người dùng nói chung; hoặc tạo giới hạn sử dụng cho Dịch vụ. VnEmisoft có thể chấm dứt vĩnh viễn hoặc tạm thời hoặc đình chỉ quyền truy cập của bạn vào Dịch vụ mà không cần thông báo và chịu trách nhiệm pháp lý vì bất kỳ lý do gì. Sau khi chấm dứt vì bất kỳ lý do gì hoặc không có lý do, bạn tiếp tục bị ràng buộc bởi Thỏa thuận này. Nếu tài khoản của bạn bị chấm dứt vì bất kỳ lý do gì, bạn phải được VnEmisoft ủy quyền bằng văn bản trước khi thiết lập một tài khoản khác. Nếu bạn cố gắng thiết lập một tài khoản khác mà không được sự cho phép đó, VnEmisoft có thể cấm bạn vĩnh viễn khỏi Dịch vụ.
      `,
      en: `If these Terms of Use are breached, VnEmisoft may, without prior notice to you, remove such content and any other content you have uploaded, even without giving a reason and no compensation;
      If these Terms of Use are violated, VnEmisoft may change the Service without prior notice; stop providing the Service or its features to you or to Users generally; or create usage limits for the Service. VnEmisoft may permanently or temporarily terminate or suspend your access to the Service without notice and liability for any reason. Upon termination for any or no reason, you continue to be bound by this Agreement. If your account is terminated for any reason, you must be authorized in writing by VnEmisoft before setting up another account. If you attempt to set up another account without such permission, VnEmisoft may permanently ban you from the Service.
      `,
    },
  },
  {
    id: 10,
    title: {
      vi: "Điều khoản chung",
      en: "General Terms",
    },
    content: {
      vi: `Trường hợp có bất kỳ điều nào trong bảng điều khoản sử dụng này hết hiệu lực hoặc không thể thi hành vì bất cứ lý do gì sẽ chỉ ảnh hưởng đến điều khoản đã xác định hết hiệu lực đó và không ảnh hưởng đến hiệu lực của các điều khoản còn lại. Nếu có sự khác biệt giữa điều khoản sử dụng này và các thỏa thuận sử dụng dịch vụ thì quy định nào mới nhất sẽ có hiệu lực.
      Các vấn đề chưa được quy định tại điều khoản này sẽ được thực hiện theo quy định của pháp luật, hướng dẫn của cơ quan Nhà nước có thẩm quyền và/hoặc các cam kết/thỏa thuận có hiệu lực khác giữa các bên.
      Trong quá trình thực hiện nếu phát sinh tranh chấp, hai bên sẽ chủ động giải quyết trên cơ sở thương lượng, hòa giải. Trường hợp không giải quyết được, tranh chấp sẽ được đưa ra Tòa án có thẩm quyền theo quy định pháp luật để giải quyết, trừ trường hợp pháp luật có quy định khác.
      `,
      en: `The event that any provision of these Terms of Use becomes invalid or unenforceable for any reason shall affect only such invalidated provisions and shall not affect the validity of the Terms of Use. remaining terms. If there is a discrepancy between these Terms of Use and the Terms of Service, whichever is the most recent will prevail.
      Matters not specified in this clause will be carried out in accordance with the law, the guidance of the competent State agency and/or other valid commitments/agreements between the parties.
      During the implementation process, if a dispute arises, the two parties will actively resolve it on the basis of negotiation and conciliation. In case the dispute cannot be resolved, the dispute will be brought to a competent court in accordance with law for settlement, unless otherwise provided for by law.
      `,
    },
  },
  {
    id: 11,
    title: {
      vi: "Thông tin liên lạc",
      en: "Communications",
    },
    content: {
      vi: `Các thông báo tự động từ hệ thống, xác nhận đổi mật khẩu, quên mật khẩu được gửi đến bạn từ email chính thức e-support@vnemisoft.com Mọi thông báo tự động từ các nguồn khác đều không đáng tin cậy. Bằng cách cung cấp cho VnEmisoft địa chỉ email của bạn, bạn đồng ý cho phép VnEmisoft sử dụng địa chỉ email để gửi cho bạn các thông báo liên quan đến Dịch vụ và các thông báo khác nếu cần.
      Trong trường hợp bạn nhận được email không đến từ nguồn trên, VnEmisoft khuyến nghị bạn liên hệ ngay với trung tâm hỗ trợ khách hàng để được hỗ trợ.
      Xin trân trọng cảm ơn
      `,
      en: `Automatic notifications from the system, confirmation of password change, and forgotten password are sent to you from the official email e-support@vnemisoft.com Any automatic notifications from other sources are unreliable. By providing VnEmisoft with your email address, you agree to allow VnEmisoft to use your email address to send you Service-related notices and other notices as needed.
      In case you receive an email that does not come from the above source, VnEmisoft recommends that you immediately contact the customer support center for assistance.
      Thank you very much
      `,
    },
  },
];

export default termsContent;
