import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M36.75 18.5h-6.568l5.103-4.86-2.22-2.13-7.34 6.99h-3.15v-3l7.34-6.99-2.237-2.115-5.103 4.86V5h-3.15v6.255l-5.103-4.86-2.236 2.115 7.339 6.99v3h-3.15l-7.34-6.99-2.22 2.13 5.103 4.86H5.25v3h6.568l-5.103 4.86 2.22 2.13 7.34-6.99h3.15v3l-7.34 6.99 2.237 2.115 5.103-4.86V35h3.15v-6.255l5.103 4.86 2.236-2.115-7.339-6.99v-3h3.15l7.34 6.99 2.22-2.13-5.103-4.86h6.568v-3z"
      ></path>
    </svg>
  );
}

export default Icon;
