import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import SharedSelectField from '../../../../shared/components/SharedSelectField';
import { useFormik } from 'formik';
import * as Yup from "yup";
import IndexTypeDropdown from "../../../../shared/components/IndexTypeDropdown"
import SharedInputField from '../../../../shared/components/SharedInputField';

import './styles.css';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLanguage } from '../../../../shared/hooks';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '80vh',
    overflowY: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: '100%',
  },
}));

const styles = {
  label: {
    fontWeight: 'bold',
    display: 'block',
    marginBottom: 0,
    color: '#3f4254'
  },
};

const getDefaultValues = () => {
  return {
    location_type: 0,
    categories: [],
    mode: 0,
    frequency_period: 0,
  };
};

const initialValues = {
  category: null,
  indicatorId: "",
  frequency: null,
  time_type: null,
  method: null,
  unit: "",
  name: "",
  modern: "",
  origin: "",
  provider: "",

};

export default function IndexModal(props) {
  const { categories, timeTypes, methods, handleCreate, handleClose, data, editing, handleEdit, activeData, viewMode } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [values, setValues] = React.useState(getDefaultValues());
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props['open'] || false);
  const [loading, setLoading] = useState(false);
  const [language] = useLanguage();
  const [indicators, setIndicators] = useState([]);
  const actives = activeData.map(item => item.indicatorId.split("-")[1]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open])

  const toggleOpen = () => {
    props['handleClose']();
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const getFrequencyPeriod = (frequencyPeriod) => {
    return ['Second', 'Minute', 'Hour', 'Day'][parseInt(frequencyPeriod)];
  };

  const getMode = (mode) => {
    return ['Manual', 'Semi-automatic', 'Automatic'][parseInt(mode)];
  };

  const prepareData = () => {
    const output = {
      ...values,
      frequency_name: `${values.frequency} ${getFrequencyPeriod(
        values.frequency_period
      )}${values.frequency > 0 && 's'}`,
      mode_name: getMode(values.mode),
    };

    return output;
  };

  const onSubmit = () => {
    const outputData = prepareData();

    if (props['editing']) {
      const { id } = props['data'];

      props['handleEdit'](id, outputData);
    } else {
      props['handleCreate'](outputData);
    }

    props['handleClose']();
  };

  const onChangeCategories = (categories) => {
    setValues({ ...values, categories, index_type: '' });
  };

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          {viewMode ?
            <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.VIEW_ITEM" /> :
            props['editing'] ?
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_ITEM" /> :
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_NEW_ITEM" />}
        </div>

        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={props['handleClose']} />
          </span>
        </div>
      </div>
    );
  };

  const schema = Yup.object().shape({
    category: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    method: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    unit: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    frequency: Yup.number().nullable()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ).positive(
        intl.formatMessage({
          id: "CLIENT.GENERAL.POSITIVE_NUMBER"
        })
      ).integer(
        intl.formatMessage({
          id: "CLIENT.GENERAL.INTERGER_NUMBER"
        })
      ),

  });

  const formik = useFormik({
    initialValues: editing ? {
      ...data
    } : {
      ...initialValues,
      category: categories && categories.length > 0 && categories[0].id,
      time_type: timeTypes && timeTypes.length > 0 && timeTypes[0].id,
      method: methods && methods.length > 0 && methods[0].id,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (!formik.values["indicator"]) {
        formik.setFieldError("indicator", intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        }))
        return;
      }
      if (editing) {
        const { id } = data;

        handleEdit(id, values);
      } else {
        handleCreate(values);
      }
      handleClose()
    },
  });

  useEffect(() => {
    const indicators = categories.find(item => item.id === formik.values["category"]) &&
      categories.find(item => item.id === formik.values["category"]).indicator_group.map(elm => ({
        value: elm.id,
        label: elm[`name_${language}`],
        items: elm.indicator.map(subElm => ({
          value: subElm.id,
          label: language === "en" ? subElm.name : subElm.name_vi
        })).filter(elmSub => !actives.includes(elmSub.value.toString()))
      }))
    setIndicators(indicators)
  }, [formik.values["category"], activeData])

  const onChangeIndicator = (value) => {
    formik.setFieldValue("indicatorId", value.value);
    formik.setFieldValue("indicator", value.label);
  }

  const handleChangeCategory = (name, value) => {
    formik.setFieldValue(name, value);
    formik.setFieldValue("indicator", "");
    formik.setFieldValue("indicatorId", "");
  }

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <form onSubmit={formik.handleSubmit}>
          <h3 className="sub-title">
            <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDEX_TITLE" />
          </h3>
          <Box mb={3}>
            <SharedSelectField
              className={viewMode ? "disabled-field text-normal" : "text-normal"}
              required={!viewMode}
              disabled={viewMode || formik.isSubmitting}
              label={intl.formatMessage({ id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.CATEGORY_LABEL" })}
              disableClearable
              name="category"
              options={categories.map(item => ({
                id: item.id,
                value: item.id,
                label: item[`name_${language}`]
              }))}
              defaultValue={formik.values["category"]}
              onChange={handleChangeCategory}
            />
          </Box>

          <Box mb={3}>
            <Box className="form-label">
              {
                !viewMode &&
                <p className="required">*</p>
              }
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDICATOR_LABEL" />
            </Box>

            <IndexTypeDropdown
              data={indicators}
              value={formik.values["indicator"]}
              onChange={onChangeIndicator}
              disabled={viewMode}
            />
            {
              formik.errors["indicator"] &&
              <FormHelperText className="error-message">{formik.errors["indicator"]}</FormHelperText>
            }
          </Box>

          <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
            <Box width="49%" >
              <SharedSelectField
                className={viewMode ? "disabled-field text-normal" : "text-normal"}
                required={!viewMode}
                disabled={viewMode || formik.isSubmitting}
                disableClearable
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.METHOD_LABEL' })}
                name="method"
                options={methods.map(item => ({
                  id: item.id,
                  value: item.id,
                  label: item[`name_${language}`]
                }))}
                defaultValue={formik.values["method"]}
                onChange={formik.setFieldValue}
              />
            </Box>
            <Box
              width="49%"
            >
              <SharedInputField
                name="unit"
                className={viewMode ? "disabled-field" : ""}
                required={!viewMode}
                disabled={viewMode || formik.isSubmitting}
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.UNIT_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.UNIT_HINT' })}
                {...formik.getFieldProps("unit")}
                errorMsg={formik.touched["unit"] && formik.errors["unit"]}
              />
            </Box>
          </Box>

          <Box mb={3} display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box width="49%">
              <SharedInputField
                name="frequency"
                className={viewMode ? "disabled-field" : ""}
                required={!viewMode}
                disabled={viewMode || formik.isSubmitting}
                type="number"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FREQUENCY_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FREQUENCY_HINT' })}
                {...formik.getFieldProps("frequency")}
                errorMsg={formik.touched["frequency"] && formik.errors["frequency"]}
              />
            </Box>
            <Box
              width="49%"
              style={{ marginBottom: (formik.touched["frequency"] && formik.errors["frequency"]) ? "19px" : "0" }}
            >
              <SharedSelectField
                className={viewMode ? "disabled-field text-normal" : "text-normal"}
                required={!viewMode}
                disabled={viewMode || formik.isSubmitting}
                disableClearable
                name="time_type"
                options={timeTypes.map(item => ({
                  id: item.id,
                  value: item.id,
                  label: item[`name_${language}`]
                }))}
                defaultValue={formik.values["time_type"]}
                onChange={formik.setFieldValue}
              />
            </Box>
          </Box>

          <h3 className="sub-title">
            <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_TITLE" />
          </h3>

          <Box mb={3} display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box width="49%" style={{ marginBottom: (formik.touched["frequency"] && formik.errors["frequency"]) ? "19px" : "0" }}>
              <SharedInputField
                name="name"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_NAME_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_NAME_HINT' })}
                {...formik.getFieldProps("name")}
                className={viewMode ? "disabled-field" : ""}
                disabled={viewMode || formik.isSubmitting}
                errorMsg={formik.touched["name"] && formik.errors["name"]}
              />
            </Box>
            <Box
              width="49%"
              style={{ marginBottom: (formik.touched["frequency"] && formik.errors["frequency"]) ? "19px" : "0" }}
            >
              <SharedInputField
                name="modern"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_MODERN_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_MODERN_HINT' })}
                {...formik.getFieldProps("modern")}
                className={viewMode ? "disabled-field" : ""}
                disabled={viewMode || formik.isSubmitting}
                errorMsg={formik.touched["modern"] && formik.errors["modern"]}
              />
            </Box>
          </Box>

          <Box mb={3} display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box width="49%" style={{ marginBottom: (formik.touched["frequency"] && formik.errors["frequency"]) ? "19px" : "0" }}>
              <SharedInputField
                name="origin"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_ORIGIN_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_ORIGIN_HINT' })}
                {...formik.getFieldProps("origin")}
                className={viewMode ? "disabled-field" : ""}
                disabled={viewMode || formik.isSubmitting}
                errorMsg={formik.touched["origin"] && formik.errors["origin"]}
              />
            </Box>
            <Box
              width="49%"
              style={{ marginBottom: (formik.touched["frequency"] && formik.errors["frequency"]) ? "19px" : "0" }}
            >
              <SharedInputField
                name="provider"
                label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_PROVIDER_LABEL' })}
                placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_PROVIDER_HINT' })}
                {...formik.getFieldProps("provider")}
                className={viewMode ? "disabled-field" : ""}
                disabled={viewMode || formik.isSubmitting}
                errorMsg={formik.touched["provider"] && formik.errors["provider"]}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {
              !viewMode &&
              <Button
                className="custom-button"
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid
                }
              >
                <FormattedMessage id="CLIENT.TICKET_ACTIONS.SAVE" />
                {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
              </Button>
            }
            <Box ml={3}>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={handleClose}
              >
                <FormattedMessage id="CLIENT.TICKET_ACTIONS.CANCEL" />
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    setOpen(props['open']);

    if (props['data']) {
      setValues({ ...props['data'] });
    } else {
      setValues(getDefaultValues());
    }
  }, [props['open'], props['data']]);

  return (
    <Modal
      open={open}
      onClose={props['handleClose']}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}
