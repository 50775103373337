import axiosClient from "./axiosClient";
import customAxios from "./customAxios.js";

const workspaceApi = {
  getAllworkspaces(params, token) {
    const url = "/api/v1/workspace/";
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  },
  getworkspace(id, token) {
    const url = `/api/v1/workspace/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getChartOptions(id, token) {
    const url = `/api/v1/workspace/get-workspace-with-station-parameter/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getChartByStationOptions(id, token) {
    const url = `/api/v1/workspace/get-workspace-with-station-parameter-by-station/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  postworkspace(data, token) {
    const url = "/api/v1/workspace";
    return axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteworkspace(id, token) {
    const url = `/api/v1/workspace/${id}`;
    return axiosClient.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  putworkspace(id, data, token) {
    const url = `/api/v1/workspace/${id}`;
    return axiosClient.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  postWorkspaceChart(token, data) {
    const url = "/api/v1/station-chart/workspace-chart";
    // eslint-disable-next-line no-undef
    return customAxios(token).post(url, data);
  },
  getAllWorkspacesWithAllStationsParameter() {
    const url =
      "/api/v1/workspace/get-all-workspace-with-all-station-parameter"; // get-all-workspace-with-all-station-parameter
    return axiosClient.get(url);
  },
  getAllWorkspacesWithAllStationConnectionParameter() {
    const url =
      "/api/v1/workspace/get-all-workspace-with-all-station-connection-parameter";
    return axiosClient.get(url);
  },
  getAllWorkspacesWithAllStationConnectionParameterV2() {
    const url =
      "/api/v1/workspace/get-all-workspace-with-all-station-connection-parameter-v2?user=1";
    return axiosClient.get(url);
  },
};

export default workspaceApi;
