import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLanguage, usePermissions } from "../../../shared/hooks";

const useDataFileList = ({ dataFileApi }) => {
  const history = useHistory();
  const { isAllowed } = usePermissions();
  const [language] = useLanguage();
  const [loading, setLoading] = useState(false);
  const [dataFiles, setDataFiles] = useState({
    data: [],
    total: 0,
  });
  const [templateFile, setTemplateFile] = useState([]);
  const [files, setFiles] = useState(null);
  const [postFiles, setPostFiles] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([
    {
      field: "submitted_date",
      sort: "desc",
    },
  ]);
  const [data, setData] = useState({
    users: [],
    status: [],
  });
  const [dateRange, setDateRange] = useState({
    startTime: moment().startOf("day"),
    endTime: moment().endOf("day"),
  });
  const fetchData = async (values) => {
    try {
      setLoading(true);
      const params = {
        page,
        amount: pageSize,
        search: values && values.search,
        start_date:
          values.startTime ||
          moment(dateRange["startTime"]).format("YYYY-MM-DD"),
        end_date:
          values.endTime || moment(dateRange["endTime"]).format("YYYY-MM-DD"),
        user_id: values.user_id,
        data_file_status_id:
          values.data_file_status_id !== null
            ? values?.data_file_status_id?.map((item) => item.value)
            : [1, 2, 3],
        order: sortModel[0].field || "",
        arrangement: sortModel[0].sort.toUpperCase() || "",
      };
      const res = await dataFileApi.getAllDataFiles(params);
      if (res.success) {
        setDataFiles((prevState) => ({
          ...prevState,
          data: res.data,
          total: res.count,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSortModelChange = (params) => {
    setSortModel([
      {
        field: params.field,
        sort: params.sort,
      },
    ]);
  };

  const onPageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  const onPageChange = (params) => {
    setPage(params.page);
  };

  const onEdit = (item, { page }) => {
    const params = {};

    if (page > 0) {
      params["ppi"] = page;
    }

    history.push({
      pathname: `/data-files/edit/${item.id}`,
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const onView = (item, { page }) => {
    const params = {};

    if (page > 0) {
      params["ppi"] = page;
    }

    history.push({
      pathname: `/data-files/edit/${item.id}`,
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const onChangeDateRange = (range) => {
    let start = moment(range["startDate"]).startOf("day");
    let end = moment(range["endDate"]).endOf("day");

    setDateRange({
      startTime: start,
      endTime: end,
    });
  };

  const dataFileMaster = {
    history,
    loading,
    dataFiles,
    page,
    pageSize,
    sortModel,
    data,
    language,
    dateRange,
    files,
    postFiles,
    templateFile,
    setTemplateFile,
    setPostFiles,
    setFiles,
    isAllowed,
    setLoading,
    setDataFiles,
    setPage,
    setPageSize,
    setSortModel,
    setData,
    setDateRange,
    fetchData,
    onSortModelChange,
    onPageChange,
    onEdit,
    onView,
    onPageSizeChange,
    onChangeDateRange,
  };

  return dataFileMaster;
};

export default useDataFileList;
