/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box, Container
} from "@material-ui/core";
import FilterBar from "app/pages/DataSharingRequest/components/FilterBar";
import { SearchListInitialValues } from "app/pages/DataSharingRequest/constants";
import { useDataSharingList } from "app/pages/DataSharingRequest/hooks";
import PageLoading from "app/shared/components/Loader/PageLoading";
import { useFormik } from "formik";
import { useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import DataSharingRequestTableContent from "../../../../content/EndUserDataSharingRequestTableContent";
import dataSharingApi from "../../../api/dataSharingApi";
import SharedTable from "../../../shared/components/SharedTable";
import "./index.css";

const DataSharingRequestList = (props) => {
  const { intl } = props;
  const {
    data,
    page,
    pageSize,
    dataSharing,
    sortModel,
    language,
    loading,
    pageLoading,
    enablePageLoading,
    disablePageLoading,
    setData,
    onSortModelChange,
    onPageSizeChange,
    onPageChange,
    fetchData,
    onEdit,
    handleClear,
    submitList,
    isAllowed
  } = useDataSharingList({ intl, dataSharingApi });

  useEffect(() => {
    fetchData(formik.values);
  }, [page, pageSize, sortModel]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        enablePageLoading();
        const [
          status,
          locationTypes
        ] = await Promise.all([
          dataSharingApi.getStatus(),
          dataSharingApi.getLocationTypes(),
        ])
        if (locationTypes.success && status.success) {
          setData(prevData => ({
            ...prevData,
            status: status.data,
            locationTypes: locationTypes.data,
          }))
        }
      } catch (error) {
      } finally {
        disablePageLoading();
      }
    }

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: SearchListInitialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      submitList(values);
    },
  });

  return (

    <Container className="enduser-page">
      <div className="main-content sharing-requests data-sharing enduser">
        <h2 className="main-title">
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.PAGE_TITLE" />
        </h2>
        <PageLoading loading={pageLoading}>

          <FilterBar
            formik={formik}
            intl={intl}
            data={data}
            language={language}
            searchListInitialValues={SearchListInitialValues}
            handleClear={handleClear}
            isAllowed={isAllowed} />
          <Box>
            <SharedTable
              count={dataSharing.total}
              data={dataSharing.data.map(item => ({
                ...item,
                "location_type.name_en": item.location_type.name_en,
                "location_type.name_vi": item.location_type.name_vi,
                "user.fullname": item.user.fullname,
                "status.name_en": item.status.name_en,
                "status.name_vi": item.status.name_vi,
              }))}
              page={page}
              pageSize={pageSize}
              sortModel={sortModel}
              loading={loading}
              content={DataSharingRequestTableContent()}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onSortModelChange={onSortModelChange}
              actionHandlers={{
                edit: onEdit,
                view: onEdit,
              }}
              // disabledControls={{
              //   edit: () => !isAllowed("data_sharing_request", "edit"),
              // }}
              hideControls={{
                edit: (row) => row.status.id !== 1,
                view: (row) => row.status.id === 1,
              }}
            />
          </Box>
        </PageLoading>
      </div>
    </Container>
  );
};

export default injectIntl(DataSharingRequestList);
