import { Units } from './constants';
import JSZipUtils from 'jszip-utils';

const getFileSize = (size) => {
  let i = 0;
  let newSize = size;

  while (newSize > 900) {
    newSize /= 1024;
    i++;
  }

  return Math.round(newSize * 100) / 100
    ? Math.round(newSize * 100) / 100 + ' ' + Units[i]
    : '';
};

const trimFileName = (fileName, max = 20) => {
  const name = fileName.split('.')[0];
  const type = fileName.split('.')[1];

  if (name.length > max) {
    return name.substr(0, 10) + '...' + name.substr(-5) + '.' + type;
  }

  return fileName;
};

const downloadTxtFile = (filename) => {
  fetch(filename).then((r) => {
    r.text().then((d) => {
      let CONTENT = d;
      console.log(CONTENT);
      const blob = new Blob([CONTENT], { type: 'text/plain' });
      const file_path = window.URL.createObjectURL(blob);
      const a = document.createElement('A');
      a.href = file_path;
      a.download = 'file_text';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  });
};

function urlToPromise(url) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export { getFileSize, trimFileName, downloadTxtFile, urlToPromise };
