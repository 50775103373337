import style0 from "../icon/marker-type/style0.svg";
import style9 from "../icon/marker-type/style9.svg";
import style10 from "../icon/marker-type/style10.svg";
import style17 from "../icon/marker-type/style17.svg";
import style18 from "../icon/marker-type/style18.svg";
import style20 from "../icon/marker-type/style20.svg";
const data = [
    {
        icon: style0,
        value: 0,
        name_en: "None",
        name_vi: "None",
    },
    {
        icon: style9,
        value: 1,
        name_en: "Arrow 1",
        name_vi: "Arrow 1"
    },
    {
        icon: style10,
        value: 2,
        name_en: "Arrow 2",
        name_vi: "Arrow 2",
    },
    {
        icon: style17,
        value: 3,
        name_en: "Arrow 3",
        name_vi: "Arrow 3"
    },
    {
        icon: style18,
        value: 4,
        name_en: "Arrow 4",
        name_vi: "Arrow 4"
    },
    {
        icon: style20,
        value: 5,
        name_en: "Arrow 5",
        name_vi: "Arrow 5"
    },
];
export default data;