import swal from "sweetalert";

export const showLoginFailedModal = ({ intl }) => {
  swal({
    title: intl.formatMessage({
      id: "CLIENT.LOGIN.LOGIN_FAILED_TITLE",
    }),
    text: intl.formatMessage({
      id: "CLIENT.LOGIN.INCORRECT_ERROR_MESSAGE",
    }),
    icon: "error",
    button: intl.formatMessage({
      id: "CLIENT.GENERAL.OK_BUTTON",
    }),
  });
};
