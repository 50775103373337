const { makeStyles } = require("@material-ui/styles");

export const useStyles = makeStyles({
	modalBody: {},
	formLabel: {},
	radioGroup: {},
	formDataSectionContainer: {
		justifyContent: 'space-between',
	},
	formDataSectionDisabled: {
		'& .index-types-dropdown-value': {
			backgroundColor: '#dadada'
		}
	},
	formFieldLabel: {
		display: 'inline-flex',
		marginBottom: 10,
		'& > span': {
			color: 'red',
			marginRight: 3,
			paddingTop: 1
		},
		'& > p': {
			fontWeight: 'bold',
		}
	},
	errorMsg: {
		color: 'red'
	},
	gridContainerPaddingRight: {
		paddingRight: 10
	},
	gridContainerPaddingLeft: {
		paddingLeft: 10
	},
	disabled: {
		backgroundColor: '#dadada'
	},
	buttonAddParameter: {
		padding: 15,
		color: '#3599ff',
		cursor: 'pointer',
		width: 'fit-content'
	},
	gridAddParameter: {
		border: '0.5px solid #bdbdbd',

		"& .MuiOutlinedInput-notchedOutline": {
			border: '0.5px solid #bdbdbd'
		}
	},
	predictionParameterSelectColorContainer: {
		padding: '5px 10px',
		// height: 52,
		border: '0.5px solid #bdbdbd'
	},
	predictionParameterSelectColor: {
		"& span.color-picker-color": {
			border: "none",
			height: '100% !important'
		},
		"& > div": {
			height: '100%'
		},
	},
	actionButtonGroup: {
		display: 'flex',
		alignItems:'center',
		justifyContent: 'flex-end',

		"& .check-icon": {
			color: '#69ae3a',
			cursor: 'pointer',
		},
		"& .close-icon": {
			color: 'red',
			marginLeft: 10,
			cursor: 'pointer',
		},
		"& .edit-icon": {
			color: 'black',
			marginLeft: 10,
			cursor: 'pointer',
		},
		"& .delete-icon": {
			color: 'black',
			marginLeft: 10,
			cursor: 'pointer',
		},
	}
});
