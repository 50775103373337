export const DASHBOARD_KEYS = Object.freeze({
  SECURITY: "workspace_dashboard_security",
  PERMISSION_ID: "workspace_dashboard_permission_id",
});

export const DASHBOARD_TYPE = Object.freeze({
  VALUE: "VALUE",
  VALUE_LIST: "VALUE_LIST",
  HISTORIC_TABLE: "HISTORIC_TABLE",
  STATIC_TEXT: "STATIC_TEXT",
  ATTACHMENT_IMAGE: "ATTACHMENT_IMAGE",
  DIRECTION: "DIRECTION",
  LOCATION_MAP: "LOCATION_MAP",
  ALARM_SUMMARY: "ALARM_SUMMARY",
  STANDARD_CHART: "STANDARD_CHART",
  GAUGE_CHART: "GAUGE_CHART",
  CUSTOM_CHART: "CUSTOM_CHART",
  PREDICTION_PARAMETER_HISTORY_TABLE: "PREDICTION_PARAMETER_HISTORY_TABLE",
  PREDICTION_PARAMETER_STANDARD_CHART: "PREDICTION_PARAMETER_STANDARD_CHART",
  AGGREGATE_TABLE: "AGGREGATE_TABLE",
});

export const DASHBOARD_API_TYPE = Object.freeze({
  VALUE: "value",
  VALUE_LIST: "value-list",
  HISTORIC_TABLE: "historic-table",
  STATIC_TEXT: "static-text",
  ATTACHMENT_IMAGE: "attachment-image",
  DIRECTION: "direction",
  LOCATION_MAP: "location-map",
  ALARM_SUMMARY: "alarm-summary",
  STANDARD_CHART: "standard-chart",
  GAUGE_CHART: "gauge-chart",
  CUSTOM_CHART: "custom-chart",
  PREDICTION_PARAMETER_HISTORY_TABLE: "prediction-parameter-history-table",
  PREDICTION_PARAMETER_STANDARD_CHART: "prediction-parameter-standard-chart",
  AGGREGATE_TABLE: "aggregate-table",
});

export const STATION_STATUS = Object.freeze({
  1: { name: "Connected", color: "#63C024" },
  2: { name: "Disconnected", color: "#9C9C9C" },
});
export const STATION_COLOR = Object.freeze({
  DISABLE: "#b5b5b5",
  DEFAUlT: "#3699FF",
  WARNING: "#E49600",
  ERROR: "#FB1F1F",
  SUCCESS: "#26A92C",
});
export const NON_TABLE_ALARM_COLOR = "#fcc400";
export const TABLE_ALARM_COLOR = "#fe9200";

export const STATION_MARKER_STATUS_COLOR = {
  FULL_DISCONNECTED: "#9C9C9C",
  PARTLY_CONNECTED: "#FFA800",
  FULL_CONNECTED: "#63C024",
};

export const STATION_STATUS_ID = {
  CONNECTED: 1,
  DISCONNECTED: 2,
};
