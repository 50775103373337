import { Box, Tooltip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from "react";
import "./index.css";

const ChatUserIcon = (props) => {
  const {
    id,
    onUnHide,
    onClose,
    socket,
    item,
    roomNoti
  } = props;
  const [messageList, setMessageList] = useState([])
  
  useEffect(() => {
    socket?.on(item.roomChatId, (res) => {
      if (res.success) {
        setMessageList(prevList => ([
          ...prevList,
          res.data
        ]))
      }
    })
  }, [item.roomChatId])

  useEffect(() => {
    if (!messageList?.length) {
      setMessageList(item.data)
    }
  }, [item.data])

  return (
    <Tooltip title={
      <Box style={{fontSize: "12px", padding: "2px"}}>
        { item.username }
      </Box>
    } placement="left">
      <div className="chat-user-icon">
        <div className="wrapper">
          <img src={item.avatar} onClick={() => onUnHide && onUnHide(item.id, messageList, roomNoti && item.roomChatMemberId)}/>
        </div>
        { roomNoti &&
          <div className="noti"></div> 
        }
        <div className="close-icon" onClick={() => onClose && onClose(item.id)}>
          <CloseIcon />
        </div>
      </div>
    </Tooltip>
  )
}

export default ChatUserIcon;