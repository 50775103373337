export const MAP_INITIAL_ZOOM_RATE = 7;

export const MAP_ZOOM_RATE = 0.18;

export const STREET_MODE = 1;

export const SATELLITE_MODE = 2;

export const MAP_BOUNDS = [
  [-180, -85],
  [180, 85],
];

export const INITIAL_POSITION = [106.7009, 10.7769];

export const TAB_DRAWER = {
  NONE: "none",
  STATION: "Station",
  DASHBOARD: "Dashboard",
};

export const DURATION_PARAMETER_OPTION = [
  {
    id: 1,
    value: 1,
    label: "CLIENT.GENERAL.24_HOUR",
  },
  {
    id: 2,
    value: 3,
    label: "CLIENT.GENERAL.3_DAY",
  },
  {
    id: 3,
    value: 7,
    label: "CLIENT.GENERAL.7_DAY",
  },
];

export const ONE_DAY = 0;

export const ALL_WORKSPACE_ID = 0;

export const ALL_WORKSPACE_VALUE = {
  id: ALL_WORKSPACE_ID,
  value: ALL_WORKSPACE_ID,
  label: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
};

export const ALL_STATION_ID = 0;

export const ALL_STATION_VALUE = {
  id: ALL_STATION_ID,
  value: ALL_STATION_ID,
  label: "CLIENT.GENERAL.DATE_RANGE_PICKER_ALL",
};
