import { Box, Grid, Typography } from "@material-ui/core";
import ColorPicker from "app/shared/components/ColorPicker";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import {
	historicalTimeRange
} from "app/shared/components/Dashboard/DataModeConfigurator/data";
import TimeRangeSeletor from "app/shared/components/Dashboard/DataModeConfigurator/TimeRangeSeletor";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { useLanguage } from "app/shared/hooks";
import clsx from "clsx";
import { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { usePredictionParameters } from "../hooks/usePredictionParameters";
import { useStyles } from "../styles";

const HistoryTableForm = (props) => {
	const { formik, intl, parameters } = props;
	const [language] = useLanguage();
	const { predictionParameterList, fetchPredictionParametersByParameter } =
		usePredictionParameters(formik.values["parameterId"]);
	const classes = useStyles();
	const historical_time_range = useMemo(() => {
		return `${formik.values["historical_time_range"]}-${formik.values["historical_time_range_type"]}`;
	}, [formik.values]);
	const prediction_time_range = useMemo(() => {
		return `${formik.values["prediction_time_range"]}-${formik.values["prediction_time_range_type"]}`;
	}, [formik.values]);

	const timePoint = useMemo(() => {
		let predictionParameterDetail = null;
		if (formik.values.prediction_parameter_id) {
			predictionParameterDetail = predictionParameterList.filter((parameter) => parameter.id === formik.values.prediction_parameter_id)[0];
		}
		return predictionParameterDetail;
	}, [formik.values.prediction_parameter_id, predictionParameterList])

  return (
    <div className={classes.modalBody}>
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />

          {/* <Grid item xs={12} style={{ marginTop: 16 }}>
						<Box className={classes.formLabel}>
							<FormattedMessage id="CLIENT.DASHBOARD.DISPLAY_MODE" />
						</Box>

						<RadioGroup
							className={classes.radioGroup}
							{...formik.getFieldProps("display_mode")}
						>
							{DISPLAY_MODES.map((item) => (
								<FormControlLabel
									key={item.value}
									value={item.value}
									disabled={formik.isSubmitting}
									control={<Radio size="small" color="primary" />}
									label={item?.label}
								/>
							))}
						</RadioGroup>
					</Grid> */}
        </ConfigureArea>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.DATA",
          })}
        >
          <Grid container>
            <Grid container item className={classes.formDataSectionContainer}>
              <Grid item xs={6} className={classes.gridContainerPaddingRight}>
                <Box className={classes.formFieldLabel}>
                  <span>*</span>
                  <Typography component="p">
                    <FormattedMessage id="CLIENT.DASHBOARD.PARAMETER" />
                  </Typography>
                </Box>
                <SelectParamsByStaionDropdown
                  data={props.parameters || []}
                  value={{
                    parameterId: formik.values["parameter_id"],
                    stationId: formik.values["station_id"],
                  }}
                  // disabled={!item?.editing}
                  onChange={({ stationId, parameterId }) => {
                    formik.setFieldValue("parameter_id", parameterId);
                    formik.setFieldValue("station_id", stationId);
                    // formik.setFieldValue("prediction_parameter_id", null);
                    fetchPredictionParametersByParameter(parameterId);
                    predictionParameterList.some(
                      (item) =>
                        item.id === formik.values.prediction_parameter_id
                    ) && formik.setFieldValue("prediction_parameter_id", null);
                  }}
                />
                {formik.touched["name_vi"] && formik.errors["name_vi"] && (
                  <Typography className={classes.errorMsg}>
                    {formik.errors["name_vi"]}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} className={classes.gridContainerPaddingLeft}>
                <SharedSelectField
                  required
                  className="bg-white"
                  label={
                    <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.HISTORICAL.TIMERANGE" />
                  }
                  disableClearable
                  name="historical_time_range"
                  options={historicalTimeRange.map((item) => ({
                    ...item,
                    label: item[`name_${language}`],
                  }))}
                  defaultValue={
                    historicalTimeRange.some(
                      (item) => item.value === historical_time_range
                    )
                      ? historical_time_range
                      : historicalTimeRange.at(0)?.value
                  }
                  onChange={(name, value) => {
                    const times = value.split("-");
                    formik.setFieldValue(`${name}`, times[0]);
                    formik.setFieldValue(`${name}_type`, times[1]);
                  }}
                />
              </Grid>
            </Grid>

            {historical_time_range === historicalTimeRange.at(9).value && (
              <Grid xs={12} item>
                <TimeRangeSeletor
                  // error={formik.error}
                  error={
                    formik.errors["historical_start_time"] ||
                    formik.errors["historical_end_time"]
                  }
                  onChange={(field, value) => {
                    formik.setFieldValue(field, value);
                  }}
                  endTime={formik.values.historical_end_time}
                  startTime={formik.values.historical_start_time}
                />
              </Grid>
            )}

            <Grid
              container
              item
              style={{ marginTop: 20 }}
              className={clsx(
                { [classes.formDataSectionContainer]: true },
                {
                  [classes.formDataSectionDisabled]: !(
                    formik.values && predictionParameterList.length !== 0
                  ),
                }
              )}
            >
              <Grid item xs={6} className={classes.gridContainerPaddingRight}>
                <SharedSelectField
                  required
                  className={clsx(
                    {
                      [classes.disabled]: predictionParameterList.length === 0,
                    },
                    { "bg-white": predictionParameterList.length !== 0 }
                  )}
                  label={
                    <FormattedMessage id="CLIENT.DASHBOARD.LIST_PREDICTION_PARAMETER" />
                  }
                  disabled={predictionParameterList.length === 0}
                  disableClearable
                  name="prediction_parameter_id"
                  options={predictionParameterList.map((item) => ({
                    value: item.id,
                    label: language === "en" ? item.name : item.name_vi,
                  }))}
                  defaultValue={
                    predictionParameterList.some(
                      (item) =>
                        item.id === formik.values.prediction_parameter_id
                    )
                      ? formik.values.prediction_parameter_id
                      : historicalTimeRange.at(0)?.value
                  }
                  onChange={(name, value) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Grid>
              <Grid item xs={3} className={classes.gridContainerPaddingLeft}>
                <Grid item xs={12}>
									<SharedInputField
										name="name_en"
										label={intl.formatMessage({ id: "CLIENT.DASHBOARD.DATAMODE.PREDICTION.STEP" })}
										type="text"
										className={clsx(
											{
												[classes.disabled]: predictionParameterList.length === 0,
											},
											{ "bg-white": predictionParameterList.length !== 0 }
										)}
										value={timePoint?.n_step || ''}
										disabled
									/>
                </Grid>
              </Grid>
							<Grid item xs={3} className={classes.gridContainerPaddingLeft}>
                <Grid item xs={12}>
									<SharedInputField
										name="name_en"
										label={intl.formatMessage({ id: "CLIENT.DASHBOARD.DATAMODE.PREDICTION.FREQUENCY" })}
										type="text"
										className={clsx(
											{
												[classes.disabled]: predictionParameterList.length === 0,
											},
											{ "bg-white": predictionParameterList.length !== 0 }
										)}
										value={timePoint?.freq || ''}
										disabled
									/>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              xs={8}
              item
              style={{ marginTop: 20 }}
              className={classes.formDataSectionContainer}
            >
              <Grid item xs={3}>
                <Box className={classes.formFieldLabel}>
                  <span>*</span>
                  <Typography component="p">
                    <FormattedMessage id="CLIENT.DASHBOARD.HISTORY_TABLE.COLUMN_HEADER_COLOR" />
                  </Typography>
                </Box>
                <ColorPicker
                  // disabled={!formik.values?.show_header}
                  color={formik.values?.column_header_color}
                  position="right"
                  onChange={(colorValue) =>
                    formik.setFieldValue("column_header_color", colorValue)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.formFieldLabel}>
                  <span>*</span>
                  <Typography component="p">
                    <FormattedMessage id="CLIENT.DASHBOARD.HISTORY_TABLE.HISTORY_ROW_COLOR" />
                  </Typography>
                </Box>
                <ColorPicker
                  // disabled={!formik.values?.show_header}
                  color={formik.values?.history_row_color}
                  position="right"
                  onChange={(colorValue) =>
                    formik.setFieldValue("history_row_color", colorValue)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.formFieldLabel}>
                  <span>*</span>
                  <Typography component="p">
                    <FormattedMessage id="CLIENT.DASHBOARD.HISTORY_TABLE.PREDICTION_ROW_COLOR" />
                  </Typography>
                </Box>
                <ColorPicker
                  // disabled={!formik.values?.show_header}
                  color={formik.values?.prediction_row_color}
                  position="right"
                  onChange={(colorValue) =>
                    formik.setFieldValue("prediction_row_color", colorValue)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </ConfigureArea>
      </form>
    </div>
  );
};

export default injectIntl(HistoryTableForm);
