import {
  SET_DASHBOARD_NAME,
  SET_ACTIVE_SUB_ITEM,
  SET_RELOAD_DATA_DASHBOARD,
  SET_DASHBOARD,
} from "../actions/types";

const initialState = {
  id: "",
  name: "",
  activeSubItem: "",
  icon: null,
  isPublic: null,
  url: null,
  reload: true,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_NAME: {
      const { id, name } = action.payload;
      const isChangedId = id !== state.id;
      const icon = !isChangedId ? state.icon : null;
      const isPublic = !isChangedId ? state.isPublic : null;
      const url = !isChangedId ? state.url : null;
      return { ...state, id, name, icon, isPublic, url };
    }

    case SET_ACTIVE_SUB_ITEM: {
      return { ...state, activeSubItem: action.payload };
    }

    case SET_RELOAD_DATA_DASHBOARD: {
      return { ...state, reload: !state.reload };
    }

    case SET_DASHBOARD: {
      const { id, icon, isPublic, url } = action.payload;
      const isChangedId = id !== state.id;
      const name = !isChangedId ? state.name : null;

      return { ...state, id, icon, name, isPublic, url };
    }

    default: {
      return state;
    }
  }
};
export default dashboardReducer;
