import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@material-ui/core";

const TermsCheckBox = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item md={6} xs={12}>
      <Box mb={3}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={formik.values.acceptTerms}
              {...formik.getFieldProps("acceptTerms")}
              name="acceptTerms"
              color="primary"
              disabled={formik.isSubmitting}
            />
          }
          label={
            <div>
              {`${intl.formatMessage({ id: "CLIENT.SIGN_UP.TERMS" })} `}
              <a href="/terms-conditions" target="__blank">
                <span
                  style={{
                    color: "#3699ff",
                    textDecoration: "underline",
                  }}
                >
                  {intl.formatMessage({
                    id: "CLIENT.SIGN_UP.TERMS_LINK",
                  })}
                </span>
              </a>
            </div>
          }
        />

        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
          <FormHelperText className="error-message">
            {formik.errors.acceptTerms}
          </FormHelperText>
        ) : null}
      </Box>
    </Grid>
  );
};

export default TermsCheckBox;
