import React, { useRef, useState, useEffect } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormHelperText } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { useLanguage } from "app/shared/hooks";

const SelectParamsByStaionDropdown = ({
  data = [],
  value = {},
  disabled,
  errorMsg,
  onChange,
}) => {
  const wrapperRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [openStates, setOpenStates] = useState({});
  const [selectedItem, setSelectedItem] = useState();

  const [language] = useLanguage();
  const handleOpen = (stationId) => {
    const newStates = { ...openStates };

    newStates[stationId] = !newStates[stationId];

    setOpenStates(newStates);
  };

  const toggleList = () => {
    setShowList(!showList);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef && !wrapperRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  const handleSelect = (
    stationLabel,
    parameterLabel,
    stationId,
    parameterId
  ) => {
    const parent = data.find((d) => d.id === stationId);
    const newItem = parent.items.find((x) => x.id === parameterId);
    const display_name = language === "en" ? newItem?.name : newItem?.name_vi;
    setSelectedItem(newItem);
    setOpenStates({});

    if (onChange) {
      onChange({ stationId, parameterId, display_name });
    }

    toggleList();
  };

  useEffect(() => {
    data.forEach((station) => {
      if (station.id !== value.stationId) return;

      station?.items?.forEach((parameter) => {
        if (parameter.id === value.parameterId) {
          setSelectedItem(parameter);
        }
      });
    });
  }, [JSON.stringify(value)]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`index-types-dropdown ${
        disabled ? "index-types-dropdown-disabled" : ""
      }`}
      ref={wrapperRef}
    >
      <div className="index-types-dropdown-value bg-white" onClick={toggleList}>
        {selectedItem !== undefined ? (
          <span className="index-types-dropdown-text">
            {selectedItem.display_name}
          </span>
        ) : (
          <span className="index-types-dropdown-text" style={{ color: "gray" }}>
            <FormattedMessage id="CLIENT.DASHBOARD.PARAMETER_HINT" />
          </span>
        )}

        <span className="index-types-dropdown-icon">
          <ArrowDropDownIcon />
        </span>
      </div>

      {showList && (
        <div className="index-types-dropdown-list">
          {data.length > 0 ? (
            data.map((indexType) => (
              <div key={indexType.value}>
                <div
                  className="index-types-dropdown-item-inner-wrapper"
                  onClick={() => handleOpen(indexType.value)}
                >
                  <span className="index-types-dropdown-checkbox-wrapper">
                    {indexType.label}
                  </span>

                  <span className="index-types-dropdown-icon">
                    {openStates[indexType.value] ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </span>
                </div>

                {openStates[indexType.value] && (
                  <div className="index-types-dropdown-nested-items">
                    {indexType["items"].map((dataItem) => (
                      <div
                        className={`index-types-dropdown-item ${
                          dataItem.disabled ? "disabled" : ""
                        }`}
                        onClick={() =>
                          handleSelect(
                            dataItem.label,
                            indexType.label,
                            indexType.value,
                            dataItem.value
                          )
                        }
                      >
                        {dataItem.label}
                      </div>
                    ))}
                    {indexType["items"]?.length === 0 && (
                      <div className={`index-types-dropdown-item disabled`}>
                        <FormattedMessage id="CLIENT.STATION.NO_DATA" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p style={{ margin: 0 }}>
              <FormattedMessage id="CLIENT.GENERAL.NO_OPTION" />
            </p>
          )}
        </div>
      )}
      {errorMsg && (
        <FormHelperText className="error-message">{errorMsg}</FormHelperText>
      )}
    </div>
  );
};

export default SelectParamsByStaionDropdown;
