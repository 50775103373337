import { Container } from "@material-ui/core";
import "./index.css";
import Logo from "../../../../../assets/images/logo-footer.png";

const Footer = (props) => {
  return (
    <div className="auth-footer">
      <Container maxWidth={false} className="container">
        <div className="logo">
          <a href="https://reecotech.com.vn/" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <div className="copyright">
          <p>Copyright © 2021, <strong>Reecotech</strong></p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;


