import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const PrettoSlider = withStyles({
  root: {
    color: '#3699ff',
    height: 10,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    '& > span > span': {
      color: '#fff',
      fontWeight: 600,
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

export default PrettoSlider;