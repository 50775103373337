import React from 'react';

import './styles.css';
import ReactResizeDetector from 'react-resize-detector';
import { invertColor } from 'app/utils/utils';

const VerticalGauge = (props) => {
  const SPACE_BETWEEN_LABEL_CHART = 20;
  const MAX_PORTION = 40;
  const SPACE_BETWEEN_TWO_LINE = 40;
  const CHART_WIDTH_SPACE = 45;
  const CHART_HEIGHT_SPACE = 20;
  const X0 = 36;
  const Y0 = 10;
  const refChartContainer = React.useRef(null);
  const nearOptions = [2, 4, 5, 10];

  const maxValue = props['maxValue'] || 100;
  const minValue = props['minValue'] || 0;
  const value = props['value'] || 0;

  const [containerSize, setContainerSize] = React.useState([0, 0]);
  const [chartSize, setChartSize] = React.useState([0, 0]);
  const [lines, setLines] = React.useState([]);
  const color = props['color'] || '#46AE4E';

  const label = props['label'] || '';

  /* How many portions does this width can be divided */
  const getNumOfPortion = (width) => {

    /* If want to calculate, uncomment this line below */
    return Math.min(
      Math.round(Math.floor(width / SPACE_BETWEEN_TWO_LINE) / 2) * 2
      , MAX_PORTION);

  }

  const roundNear = (number, value) => {
    return (Math.round(number * value) / value).toFixed(2);
  };

  const GetPosY = (value) => {
    const range = Math.ceil(maxValue) - minValue;
    return ((value - minValue) / Math.abs(range)) * chartSize[1];
  }

  const reDrawChart = () => {
    var nearOptionIndex = 0;
    var numOfPortion = getNumOfPortion(chartSize[1]);
    const range = Math.ceil(maxValue) - Math.floor(minValue);
    var step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
    while (step == 0) {

      nearOptionIndex++;
      step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      while (nearOptionIndex == 3 && step == 0) {
        numOfPortion = numOfPortion * 0.8;
        step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      }
    }

    var vl = Number(Math.floor(minValue));
    var newDataLines = [];
    while (vl <= Math.ceil(maxValue)) {
      if (vl >= minValue) {
        vl = Number(Number(vl).toFixed(2))
        newDataLines.push({
          value: vl,
          label: Number(vl) > 1000 ? `${Math.floor(vl / 1000)}k` : vl
        });
      }
      vl += Number(step);
    }
    setLines(newDataLines);

  }

  const setSize = () => {
    setContainerSize([refChartContainer?.current?.clientWidth,
    refChartContainer?.current?.clientHeight]);
    setChartSize([refChartContainer?.current?.clientWidth * 0.3 - CHART_WIDTH_SPACE,
    refChartContainer?.current?.clientHeight - CHART_HEIGHT_SPACE]);
  }

  const onResize = React.useCallback(() => {
    if (refChartContainer) {
      setSize();
    }
  }, []);

  React.useEffect(() => {
    if (refChartContainer) {
      setSize();
    }
  }, [refChartContainer]);

  React.useEffect(() => {
    if (chartSize) {
      reDrawChart();
    }
  }, [chartSize, props['minValue'], props['maxValue'], props['value']]);


  return (
    <ReactResizeDetector
      handleWidth handleHeight
      onResize={onResize}
      refreshRate="500"
      refreshMode='debounce'>
      {({ width, height }) => (
        <div className="vertical-gauge" ref={refChartContainer}
          style={{
            top: props['showHeader'] ? "15%" : "10%",
            height: props['showHeader'] ? "70%" : "80%"
          }}>
          <div className="vertical-chart-container"
            style={{
              width: containerSize[0],
              height: containerSize[1]
            }}>
            <svg viewBox={`0 0 ${containerSize[0]} ${containerSize[1]}`}>
              <rect x={X0}
                y={Y0}
                width={chartSize[0]}
                height={chartSize[1]}
                fill={`${invertColor(color)}4d`} />
              <rect
                x={X0}
                y={chartSize[1] + Y0 - GetPosY(value)}
                width={chartSize[0]}
                height={GetPosY(value)}
                fill={invertColor(color)}
              />
              <rect fill="none"
                class="highcharts-plot-border"
                data-z-index="1"
                stroke={invertColor(color)}
                stroke-width="1"
                x={X0} y={Y0}
                width={chartSize[0]}
                height={chartSize[1]} />

              {props['showLines'] && lines.length > 0 && (
                <g>
                  {lines.map((line, index) => {
                    const y = chartSize[1] - GetPosY(line.value) + Y0;

                    return (
                      <path fill="none"
                        stroke={invertColor(color)}
                        stroke-width="1.3"
                        d={`M ${X0} ${y} L ${X0 + 8} ${y}`} opacity="1"></path>
                    )
                  })}
                </g>
              )}

              {props['showNumbers'] && lines.length > 0 && (
                <g>

                  {lines.map((line, index) => {
                    const y = chartSize[1] - GetPosY(line.value) + Y0;

                    return (<text
                      x={X0 - SPACE_BETWEEN_LABEL_CHART}
                      y={y}
                      fill={invertColor(color)}
                      font-family="sans-serif"
                      font-size="13"
                      text-anchor="middle"
                      alignment-baseline="middle"
                    >
                      {line.label}
                    </text>);
                  })}
                </g>
              )}
            </svg>
            <div className="vertical-gauge-info">
              <div className="horizontal-gauge-currentvalue" style={{ fontSize: Math.min(height * 0.1, 70), color: invertColor(color) }}>
                <span>
                  {value} ({props['unit']})
                </span>
              </div>
              <div className="horizontal-gauge-currentstate" style={{ fontSize: Math.min(height * 0.07, 40), color: invertColor(color) }}>
                <span>
                  {label}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactResizeDetector>
  );
};

export default VerticalGauge;
