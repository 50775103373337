import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const PhoneInput = ({ intl, pageData, xs = 6, ml = 0, required }) => {
  const { formik } = pageData;

  return (
    <Grid item md={6} xs={12}>
      <Box mb={3} ml={ml}>
        <SharedInputField
          name="phone_number"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.PHONE_NUMBER_LABEL",
          })}
          type="text"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.PHONE_NUMBER_HINT",
          })}
          required={required}
          errorMsg={
            formik.touched["phone_number"] && formik.errors["phone_number"]
          }
          {...formik.getFieldProps("phone_number")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default PhoneInput;
