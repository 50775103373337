import { Box, Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import IndexTable from "../IndexTable";

const General = ({
  mainData,
  toggleModal,
  masterData,
  data,
  onDelete,
  toggleEditItem,
}) => {
  return (
    <>
      <h3 className="sub-title">
        <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDEX_DEVICE_TITLE" />
      </h3>
      {mainData.status && mainData.status.id === 1 && (
        <Box mb={3}>
          <Button className="custom-button" onClick={toggleModal}>
            <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_NEW_ITEM" />
          </Button>
        </Box>
      )}
      {Object.keys(data).length > 0 && (
        <IndexTable
          data={data}
          timeTypes={masterData.timeTypes}
          categories={masterData.categories}
          methods={masterData.methods}
          handleEdit={toggleEditItem}
          handleRemove={onDelete}
          showActions={
            mainData.status && mainData.status.id !== 1 ? false : true
          }
        />
      )}
    </>
  );
};

export default General;
