import swal from "sweetalert";
import chatApi from "../../api/chatApi";
import endUserChatApi from "../../api/endUserChatApi";
import {
  CHAT_END_USER_RESET_DATA,
  SET_END_USER_CHAT_ICONS,
  SET_END_USER_CHAT_WINDOWS,
  SET_END_USER_ROOMS_INIT_NOTI,
  SET_END_USER_ROOMS_NOTI,
  SET_END_USER_ROOM_ID_LIST
} from "./types";

export const removeRoomInitNotification = (roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { user, endUserChat } = getState();
    const { token } = user;
    const { roomsInitNoti } = endUserChat;
    try {
      const resp = await chatApi.removeChatNoti({ roomChatMemberId }, token);
      if (resp.success) {
        const list = [...roomsInitNoti];
        const index = list.indexOf(roomChatMemberId);
        list.splice(index, 1);
        dispatch({
          type: SET_END_USER_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti: list
          }
        })
      }
    } catch (error) {

    }
  }
}

export const handleInitChatWindow = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { user, endUserChat } = getState();
      const { token } = user;
      const { roomsInitNoti } = endUserChat;
      const data = {
        userId,
        page: 1,
        limit: 8
      }
      const resp = await endUserChatApi.initChatRoom(data, token);
      if (resp.success) {
        dispatch(removeRoomNotification(resp?.roomChatId, resp?.roomChatMemberId))
        if (roomsInitNoti.includes(resp.roomChatMemberId)) {
          dispatch(removeRoomInitNotification(resp.roomChatMemberId))
        }
        const windowItem = {
          id: resp?.roomChatId,
          roomChatMemberId: resp?.roomChatMemberId,
          roomChatId: resp?.roomChatId,
          data: resp?.listMessage?.reverse() || [],
        }
        dispatch({
          type: SET_END_USER_CHAT_WINDOWS,
          payload: {
            chatWindows: [windowItem]
          }
        })
      }
    } catch (error) {

    }
  }
}
export const closeChatWindow = (id) => {
  return (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatWindows } = endUserChat;
    const list = [...chatWindows];
    const index = list.findIndex(item => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: SET_END_USER_CHAT_WINDOWS,
        payload: {
          chatWindows: list
        }
      })
    }
  }
}
export const closeChatIcon = (id) => {
  return (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatIcons } = endUserChat;
    const list = [...chatIcons];
    const index = list.findIndex(item => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: SET_END_USER_CHAT_ICONS,
        payload: {
          chatIcons: list
        }
      })
    }
  }
}
export const handleHideChatWindow = (id, messageList) => {
  return (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatWindows, chatIcons } = endUserChat;
    const item = chatWindows?.find(item => item.id === id);
    const newChatIcons = [
      ...chatIcons,
      {
        ...item,
        data: messageList
      },
    ]
    dispatch(closeChatWindow(id))
    dispatch({
      type: SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: newChatIcons
      }
    })
  }
}
export const fetchRoomIds = (token, userId) => {
  return async (dispatch) => {
    try {
      const data = { userId }
      const resp = await chatApi.getAllRoomIds(data, token)
      if (resp.success) {
        dispatch({
          type: SET_END_USER_ROOM_ID_LIST,
          payload: {
            roomIdList: resp.data.map(item => item.roomChatId)
          }
        })
      }
    } catch (error) {
      
    }
  }
}
export const setInitRoomsNotification = (roomChatId, userId) => {
  return (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatWindows, roomsNoti } = endUserChat;
    if (!chatWindows.length || (chatWindows && chatWindows?.length > 0 && chatWindows.findIndex(item => item.roomChatId === roomChatId) === -1)) {
      dispatch({
        type: SET_END_USER_ROOMS_NOTI,
        payload: {
          roomsNoti: [
            ...roomsNoti,
            {
              roomChatId,
              userId
            }
          ]
        }
      })
    }
  }
}
export const getAllNotification = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { token } = user;
      const resp = await chatApi.getAllNoti({ userId }, token)
      if (resp.success && resp.data?.length > 0) {
        const roomsInitNoti = resp.data?.filter(item => item.isSeen === 0)?.map(item => item.roomChatMemberId)
        dispatch({
          type: SET_END_USER_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti
          }
        })
      }
    } catch (error) {
      
    }
  }
}
export const setRoomNotification = (roomsNoti) => {
  return {
    type: SET_END_USER_ROOMS_NOTI,
    payload: {
      roomsNoti
    }
  }
}
export const removeRoomNotification = (roomChatId, roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { endUserChat, user } = getState();
    const { token } = user;
    const { roomsNoti } = endUserChat;
    if (roomsNoti?.find(elm => elm?.roomChatId === roomChatId)) {
      try {
        const resp = await chatApi.removeChatNoti({ roomChatMemberId: roomChatMemberId }, token);
        if (resp.success) {
          const list = [...roomsNoti];
          const index = list.findIndex(elm => elm.roomChatId === roomChatId);
          list.splice(index, 1);
          dispatch(setRoomNotification(list))
        }
      } catch (error) {
        swal({
          title: error,
          icon: 'error',
        });
      }
    }
  }
}
export const handleUnHideChatWindow = (id, messageList, roomChatMemberId, isMediate = false) => {
  return async (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatIcons } = endUserChat;
    const item = chatIcons.find(item => item.id === id);
    // remove noti if have 
    if (roomChatMemberId) {
      const roomChatId = chatIcons.find(item => item.roomChatMemberId === roomChatMemberId)?.roomChatId;
      dispatch(removeRoomNotification(roomChatId, roomChatMemberId))
    }
    dispatch({
      type: SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: [{
          ...item,
          data: messageList
        }]
      }
    })
    dispatch(closeChatIcon(id))
  }
}
export const handleCloseAllChat = () => {
  return (dispatch) => {
    dispatch({
      type: SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: []
      }
    })
    dispatch({
      type: SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: []
      }
    })
  }
}
export const handleHideAllChat = () => {
  return (dispatch, getState) => {
    const { endUserChat } = getState();
    const { chatWindows } = endUserChat;
    dispatch({
      type: SET_END_USER_CHAT_WINDOWS,
      payload: {
        chatWindows: []
      }
    })
    dispatch({
      type: SET_END_USER_CHAT_ICONS,
      payload: {
        chatIcons: [...chatWindows]
      }
    })
  }
}
export const resetData = () => {
  return {
    type: CHAT_END_USER_RESET_DATA
  }
}