import { useState } from "react";
import { useForm } from "../../../../shared/hooks";
import authApi from "../../../../api/authApi";
import queryString from "query-string";
import { showLoginFailedModal } from "../helpers/modals";

export const useLogin = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const query = queryString.parse(props.location.search);
  const formData = [
    {
      name: "username",
      label: intl.formatMessage({ id: "CLIENT.LOGIN.IDENTITY_LABEL" }),
      fieldType: "input",
      type: "text",
      placeholder: intl.formatMessage({ id: "CLIENT.LOGIN.IDENTITY_HINT" }),
      required: true,
      validationType: "string",
      value: "",
      width: 12,
      validations: [
        {
          type: "nullable",
          params: [],
        },
        {
          type: "min",
          params: [
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            }),
          ],
        },
        {
          type: "max",
          params: [
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            }),
          ],
        },
        {
          type: "required",
          params: [
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            }),
          ],
        },
      ],
    },
    {
      name: "password",
      label: intl.formatMessage({ id: "CLIENT.LOGIN.PASSWORD_LABEL" }),
      fieldType: "input",
      type: "password",
      placeholder: intl.formatMessage({ id: "CLIENT.LOGIN.PASSWORD_HINT" }),
      required: true,
      validationType: "string",
      value: "",
      width: 12,
      isPasswordField: true,
      validations: [
        {
          type: "nullable",
          params: [],
        },
        {
          type: "min",
          params: [
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            }),
          ],
        },
        {
          type: "max",
          params: [
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            }),
          ],
        },
        {
          type: "required",
          params: [
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            }),
          ],
        },
      ],
    },
  ];

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onSubmit = async (values, setSubmitting) => {
    try {
      enableLoading();
      const res = await authApi.login(values);
      if (res.success) {
        props.logIn(res.data.user, res.token, res.data.role);
      }
    } catch (error) {
      showLoginFailedModal({ intl });
    } finally {
      setSubmitting(false);
      disableLoading();
    }
  };
  const buttons = [
    {
      id: 1,
      label: intl.formatMessage({ id: "CLIENT.LOGIN.SUBMIT" }),
      type: "submit",
      variant: "contained",
    },
  ];

  const { renderForm } = useForm({
    formData,
    buttons,
    onSubmit,
    spacing: 3,
    loading: loading,
    noteMsg: intl.formatMessage({
      id: "GENERAL.REQUIRED_FIELDS",
    }),
    forgotPasswordText: intl.formatMessage({ id: "CLIENT.LOGIN.FORGOT" }),
    isLoginPage: true,
  });

  return {
    // Getters
    loading,
    query,
    formData,

    // Setters
    setLoading,

    // Utils
    renderForm,
  };
};
