import { makeStyles } from "@material-ui/styles";
import {
  find,
  findIndex,
  filter,
  map,
  difference,
  cloneDeep,
  forEach,
} from "lodash";
import { useState } from "react";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import {
  getUnitNameId,
  isValidLayerFile,
} from "../helpers/layer-setting.helper";
import { useIntl } from "react-intl";

const ModalStyle = {
  top: "50%",
  left: "50%",
  transform: `translate(-50%, -50%)`,
  maxHeight: "80vh",
  overflowY: "auto",
  scrollBehavior: "smooth",
};

export const useLayerSetting = ({ modalOpen, handleClose, data, mapApi }) => {
  const intl = useIntl();
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "50%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 6,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 5),
    },
    formControl: {
      width: "100%",
    },
  }));

  const classes = useStyles();
  const [modalStyle] = useState(ModalStyle);
  const [open, setOpen] = useState(modalOpen || false);
  const [items, setItems] = useState([]);
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const toggleOpen = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleMoveLayer = (id, direction) => {
    const position = findIndex(layerMaster?.items, (i) => i.row_id === id);

    if (position < 0) {
      throw new Error("Given item not found.");
    } else if (
      (direction === -1 && position === 0) ||
      (direction === 1 && position === layerMaster?.items.length - 1)
    ) {
      return;
    }

    const item = find(items, (item) => item.row_id === id);
    const newItems = filter(items, (i) => i.row_id !== id);
    newItems.splice(position + direction, 0, item);

    setItems(
      map(newItems, (item, index) => ({
        ...item,
        apply_order: index,
      })),
    );
  };

  const getNewLayer = () => {
    return {
      layer_label_en: "Layer",
      data_type: "geojson",
      data_file_url: "",
      geo_json_url: "",
      apply_order: Infinity,
      expanded: true,
      // map_id: mapId,
      error: false,
    };
  };

  const onAddLayer = () => {
    const nameId = (Number(getUnitNameId(items)) || 0) + 1;
    const newLayer = {
      ...getNewLayer(),
      row_id: uuidv4(),
      layer_label_en: `Layer ${nameId}`,
      apply_order: items.length,
    };

    setItems((pre) => [...pre, newLayer]);
  };

  const handleRemoveLayer = (id) => {
    const newLayers = filter(items, (item) => item.row_id !== id);
    setItems(newLayers);
  };

  const handleItemChange = (data) => {
    const newLayers = cloneDeep(items);
    const Index = findIndex(items, (item) => item.row_id === data?.row_id);

    newLayers[Index] = data;
    setItems(newLayers);
  };

  const saveLayers = async (newItems) => {
    setLoading(true);
    const createLayers = filter(newItems, (item) => !item.id);
    const updateLayers = map(difference(newItems, createLayers), (item) => ({
      ...item,
      map_layer_id: item.id,
    }));

    const deleteLayers = filter(
      data,
      (item) => map(newItems, (d) => d.id)?.includes(item.id) === false,
    );

    try {
      let resCreate, resUpadte;

      if (createLayers?.length) {
        const postData = {
          create_map_layer: createLayers,
        };

        resCreate = await mapApi.createMapLayers(postData);
      }

      if (updateLayers?.length || deleteLayers?.length) {
        const dataUpadte = {
          updates_map_layer: updateLayers,
          deletes_map_layer: map(deleteLayers, (item) => item.id),
        };

        resUpadte = await mapApi.updateMapLayers(dataUpadte);
      }

      if (resCreate || resUpadte) {
        handleClose(true);
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.GENERAL.UNKNOWN_ERROR_MESSAGE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.GENERAL.EXCEPTION_ERROR_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubbmit = async () => {
    let errorItems = [];
    const newItems = cloneDeep(items);

    forEach(items, (item, index) => {
      if (isValidLayerFile(item) === false) {
        errorItems.push(index);
      } else {
        newItems[index].layer_label_vi = newItems[index].layer_label_en;
      }
    });

    if (errorItems.length !== 0) {
      forEach(errorItems, (item) => {
        newItems[item].error = true;
        newItems[item].expanded = true;
      });

      setItems(newItems);
    } else {
      saveLayers(newItems);
    }
  };

  const layerMaster = {
    items,
    open,
    touched,
    loading,
    modalStyle,
    classes,

    handleMoveLayer,
    handleRemoveLayer,
    handleItemChange,
    toggleOpen,
    handleSubbmit,
    setOpen,
    onAddLayer,
    setTouched,
    setItems,
  };

  return layerMaster;
};
