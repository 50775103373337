import Header from "../Header";

const Nav = (props) => {

  return (
    <>
      <Header
        {...props}
      />
    </>
  )
}

export default Nav;