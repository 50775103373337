import React, { useState, useEffect, useRef } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";
import GetAppIcon from "@material-ui/icons/GetApp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Lightbox from "react-image-lightbox";
import FileSaver from "file-saver";

import "react-image-lightbox/style.css";
import "./styles.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@material-ui/core";
import { useLanguage } from "../../../hooks";
import workspaceDashboard from "../../../../api/workspaceDashboardApi";
import socketIOClient from "socket.io-client";
import crypto from "crypto-js";
import { invertColor } from "app/utils/utils";
import { DashboardCardButtons } from "../DashboardCardButtons";

const hashRtspLink = (str) => {
  return crypto.MD5(str).toString();
};

const ValueCard = (props) => {
  const intl = useIntl();
  const [language] = useLanguage();
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [data, setData] = useState(props["data"]);
  const [rtspImage, setRtspImage] = useState("");

  const name =
    (data && data[`name_${language}`]) ||
    intl.formatMessage({
      id: "CLIENT.DASHBOARD.MODAL.ADD_NEW_ATTACHMENT_IMAGE",
    });
  const image =
    data && data["dashboard_attachment_image"]["url_image"]
      ? `${process.env.REACT_APP_API_URL}/${data["dashboard_attachment_image"]["url_image"]}`
      : "https://eoffice.reecotech.com.vn/bitrix/templates/login/logo150.png";
  const headerColor = (data && data["header_color"]) || "#1E1E2D";
  const uploadType =
    (data && data["dashboard_attachment_image"]["upload_type"]) ||
    "STATIC_IMAGE";
  const showHeader =
    data && "show_header" in data ? data && data["show_header"] : true;
  const socketRef = useRef();

  const handleDownload = () => FileSaver.saveAs(image, "image");

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    setData(props["data"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["data"]]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await workspaceDashboard.getDashboard(
          props.id,
          props.token
        );

        if (resp.success) {
          setData(resp.data);
        }

        // setTimeout(() => fetchData(), 300000);
      } catch (error) {
        console.log(error);
      }
    };

    // setTimeout(() => fetchData(), 30000);

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.data?.dashboard_attachment_image?.upload_type === "RTSP") {
      socketRef.current = socketIOClient.io(
        `${process.env.REACT_APP_API_URL}/rtsp`
      );
    }

    return () => {
      if (socketRef && socketRef?.current) {
        socketRef?.current?.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data?.dashboard_attachment_image]);

  useEffect(() => {
    if (props?.data?.dashboard_attachment_image?.upload_type === "RTSP") {
      const dashboardId = props?.data?.dashboard_attachment_image?.id || -1;

      if (socketRef && dashboardId !== -1) {
        socketRef.current.emit("init", {
          dashboard_id: dashboardId,
        });

        const hashedUrl = hashRtspLink(
          props?.data?.dashboard_attachment_image?.link?.trim()
        );

        socketRef.current.on(`rtsp-response-${hashedUrl}`, (data) => {
          setRtspImage(`data:image/jpeg;base64,${data?.image}`);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data?.dashboard_attachment_image]);

  return (
    <div className="attachment-image-card dashboard-item-card">
      {showHeader && (
        <Box
          className="attachment-image-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <Box
            display="flex"
            alignItems="center"
            className="attachment-image-card-title-name"
          >
            <ImageIcon />
            <span>&nbsp;&nbsp;{name}</span>
          </Box>

          <span>
            {data["dashboard_attachment_image"]["url_image"] && (
              <span
                onClick={handleDownload}
                style={{ cursor: "pointer" }}
                onMouseDown={stopPropagation}
              >
                <GetAppIcon />
              </span>
            )}

            {data["dashboard_attachment_image"]["url_image"] && (
              <span style={{ cursor: "pointer" }}>
                <FullscreenIcon
                  onClick={() => setIsFullscreen(true)}
                  onMouseDown={stopPropagation}
                />
              </span>
            )}
          </span>
        </Box>
      )}

      <div className="attachment-image-card-body">
        {uploadType === "STATIC_IMAGE" && (
          <>
            {data && data["dashboard_attachment_image"]["url_image"] ? (
              <img src={image} alt="" className="attachment-image-card-image" />
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_IMAGE_LABEL" />
                </p>
              </div>
            )}
          </>
        )}

        {uploadType === "DYNAMIC_IMAGE" && (
          <>
            <>
              {data && data["dashboard_attachment_image"]["link"] ? (
                <img
                  src={data["dashboard_attachment_image"]["link"]}
                  alt=""
                  className="attachment-image-card-image"
                />
              ) : (
                <div className="no-data-area">
                  <p className="no-data-label">
                    <FormattedMessage id="CLIENT.DASHBOARD.NO_IMAGE_LABEL" />
                  </p>
                </div>
              )}
            </>

            {/* {data && data['link'] ? (
              <img
                src="https://www.persolvietnam.com/media/COMMON/images/no-image.png"
                alt=""
                className="attachment-image-card-image"
                style={{ objectFit: 'cover' }}
              />
            ) : (
              <p style={{ marginTop: 24 }}>No Image to Display</p>
            )} */}
          </>
        )}

        {uploadType === "RTSP" && (
          <>
            {rtspImage !== "" ? (
              <img
                src={rtspImage}
                alt=""
                className="attachment-image-card-image"
              />
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_IMAGE_LABEL" />
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />

      {isFullscreen && (
        <Lightbox
          mainSrc={
            data && data["dashboard_attachment_image"]["url_image"] && image
          }
          onCloseRequest={() => setIsFullscreen(false)}
        />
      )}
    </div>
  );
};

export default ValueCard;
