import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from "react-router-dom";
import "./index.css";
import { Container } from '@material-ui/core';

const CustomBreadcrumbs = (props) => {
  const {
    title, 
    items
  } = props;

  return (
    <div className="breadcrumbs">
      <Container maxWidth={false} className="breadcrumbs-container">
        <h2>{title}</h2>
        <div className="items">
          <ul>
            <li className="home">
              <Link to="/">
                <HomeOutlinedIcon />
              </Link>
            </li>
            {
              items && items.length > 0 &&
              items.map(item =>
                <li key={item.id}>
                  <FiberManualRecordIcon />
                  <Link to={item.link}>
                    {item.name}
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default CustomBreadcrumbs;