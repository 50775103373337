import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import TodayIcon from "@material-ui/icons/Today";
import DateRangeModal from "../DateRangeModal";
import WindroseChart from "../WindroseChart";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Box, Button, CircularProgress, IconButton, Modal, Tooltip } from "@material-ui/core";
import  QueryBuilderIcon  from '@material-ui/icons/QueryBuilder';
import { useLanguage } from "../../../hooks";
import CloseIcon from "@material-ui/icons/Close";
import workspaceDashboardApi from "../../../../api/workspaceDashboardApi";
import { useSelector, shallowEqual } from "react-redux";
import Direction from "./direction-selections";
// import historicalTimeRange from './day-ranges'
import TimeResolutions from "../../../constants/history-time-resolutions";
import { historicalTimeRange } from "../DataModeConfigurator/data";

import "./styles.css";
import { DATE_TIME_FORMAT } from "../../../../constant/date-time-format";
import { groupBy } from "lodash";
import { PREDICTION_DATA } from "app/pages/Dashboard/components/Modals/contants";
import { invertColor } from "app/utils/utils";
import { DashboardCardButtons } from "../DashboardCardButtons";

const CustomChartCard = (props) => {
  const ZERO_TIME_RANGE = "0";

  const [language] = useLanguage();
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_CUSTOM_CHART" />
  );
  const wrapperRef = React.useRef(null);
  const [showFilter, setShowFilter] = React.useState(false);
  const [activeRange, setActiveRange] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState(moment().startOf("day"));
  const [hovered, setHovered] = React.useState(false);
  const [endDate, setEndDate] = React.useState(moment().endOf("day"));
  const [data, setData] = React.useState({
    data: [],
    columns: [],
    colors: [],
  });
  const [dataConfig, setDataConfig] = React.useState({
    dataObject: {},
    dashboardConfig: {},
  });
  const [fullScreen, setFullScreen] = React.useState(false);

  // 24 & 28 are default parameter id having data from Ari
  // TODO: Remove when having data from all parameter
  const directionParamId =
    props?.data?.dashboard_custom_chart?.direction_parameter_id;
  const speedParamId = props?.data?.dashboard_custom_chart?.speed_parameter_id;

  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const legendTitle =
    props?.data?.dashboard_custom_chart?.legend_title || "New Wind Rose Chart";
  const speedUnits = props?.data?.dashboard_custom_chart?.speed_unit || "m/s";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;
  const [loading, setLoading] = useState(false);
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const prepareOutput = (values) => {
    const dataUpdate = {
      ...values,
    };

    const output = {
      ...values,
      dataUpdate,
    };

    return output;
  };

  const onSelectRange = (id) => {
    const foundRange = historicalTimeRange.find((item) => item.id === id);
    const value = foundRange.value;
    // setActiveRange(id);

    const CustomTimeRangeValue = "0-DAY";
    if (value === CustomTimeRangeValue) {
      setShowModal(true);
    } else {
      const timeRange = `${value}`.split("-")?.at(0);
      const timeRangeType = `${value}`.split("-")?.at(1);
      const isHistoricalData =
        props.data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA;
      const isCustomRange = timeRange === "0";
      const values = {
        ...getOutputDefault(),
        time_range: isHistoricalData && !isCustomRange ? timeRange : "",
        time_range_type:
          isHistoricalData && !isCustomRange ? timeRangeType : "",
        prediction_time_range:
          isHistoricalData || isCustomRange ? "" : timeRange,
        prediction_time_range_type:
          isHistoricalData || isCustomRange ? "" : timeRangeType,
        historical_time_range:
          isHistoricalData && !isCustomRange ? timeRange : "",
        historical_time_range_type:
          isHistoricalData && !isCustomRange ? timeRangeType : "",
      };
      props["onEditDashboard"](props["type"])(
        props["id"],
        prepareOutput(values)
      );
      setShowFilter(!showFilter);
    }
  };

  const getOutputDefault = () => {
    return {
      name_en: props.data?.name_en || "",
      name_vi: props.data?.name_vi || "",
      header_color: props.data?.header_color || "#1E1E2D",
      show_header: props.data?.show_header,
      data_mode: props.data?.data_mode,
      historical_time_range: props.data?.time_range,
      prediction_time_range: props.data?.time_range,
      number_of_direction:
        props.data?.dashboard_custom_chart?.number_of_direction,
      speed_parameter_id:
        props.data?.dashboard_custom_chart?.speed_parameter_id,
      direction_parameter_id:
        props.data?.dashboard_custom_chart?.direction_parameter_id,
      legend_title: props.data?.dashboard_custom_chart?.legend_title,
      speed_unit: props.data?.dashboard_custom_chart?.speed_unit,
      zoom: props.data?.dashboard_custom_chart?.zoom,
      legend: props.data?.dashboard_custom_chart?.legend,
      range_selection: props.data?.dashboard_custom_chart?.range_selection,
      full_screen: props.data?.dashboard_custom_chart?.full_screen,
      station_id: props.data?.dashboard_custom_chart?.station_id,
      dashboard_custom_chart_range:
        props.data?.dashboard_custom_chart?.dashboard_custom_chart_range,
      dashboard_custom_chart_range_delete: [],
    };
  };

  const onSubmitCustomTimeRange = (value) => {
    const isHistoricalData =
      props.data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA;
    const values = {
      ...getOutputDefault(),
      prediction_time_range: isHistoricalData ? "" : "0",
      prediction_time_range_type: isHistoricalData ? "" : "DAY",
      historical_time_range: isHistoricalData ? "0" : "",
      historical_time_range_type: isHistoricalData ? "DAY" : "",
      time_range: isHistoricalData ? "0" : "",
      time_range_type: isHistoricalData ? "DAY" : "",
      historical_start_time: (value?.startTime || moment())
        ?.set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      historical_end_time: (value?.endTime || moment())
        ?.set("hour", 23)
        .set("minute", 59)
        .set("second", 59),
    };
    props["onEditDashboard"](props["type"])(props["id"], prepareOutput(values));
    setShowFilter(false);
  };

  const updateTimeValueCustomChart = (value) => {
    if (
      !value ||
      !props.data ||
      (props.data?.time_range === value &&
        props?.data?.data_mode === "HISTORICAL_DATA") ||
      (props.data?.time_point === value &&
        props?.data?.data_mode === "PREDICTION_DATA")
    ) {
      return;
    }

    const values = {
      name_en: props.data?.name_en || "",
      name_vi: props.data?.name_vi || "",
      header_color: props.data?.header_color || "#1E1E2D",
      show_header: props.data?.show_header,
      data_mode: props.data?.data_mode,
      historical_time_range: props.data?.time_range,
      prediction_time_range: props.data?.time_range,
      number_of_direction:
        props.data?.dashboard_custom_chart?.number_of_direction,
      speed_parameter_id:
        props.data?.dashboard_custom_chart?.speed_parameter_id,
      direction_parameter_id:
        props.data?.dashboard_custom_chart?.direction_parameter_id,
      legend_title: props.data?.dashboard_custom_chart?.legend_title,
      speed_unit: props.data?.dashboard_custom_chart?.speed_unit,
      zoom: props.data?.dashboard_custom_chart?.zoom,
      legend: props.data?.dashboard_custom_chart?.legend,
      range_selection: props.data?.dashboard_custom_chart?.range_selection,
      full_screen: props.data?.dashboard_custom_chart?.full_screen,
      station_id: props.data?.dashboard_custom_chart?.station_id,
      dashboard_custom_chart_range:
        props.data?.dashboard_custom_chart?.dashboard_custom_chart_range,
      dashboard_custom_chart_range_delete: [],
    };

    if (props?.data?.data_mode === "HISTORICAL_DATA") {
      values["time_range"] = value;
      values["time_point"] = "";
    } else if (props?.data?.data_mode === "PREDICTION_DATA") {
      values["time_point"] = value;
      values["time_range"] = "";
    }

    const output = {
      ...values,
      dataUpdate: {
        ...values,
      },
    };

    if (props["onEditDashboard"]) {
      props["onEditDashboard"](props["type"])(props["id"], output);
    }
  };

  const onSelectCustomRange = (range) => {
    setStartDate(moment(new Date(range.startDate)));
    setEndDate(moment(new Date(range.endDate)));
  };

  const handleClickOutside = (e) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      setShowFilter(false);
    }
  };

  const displayNumber = (number) => {
    return number < 0 ? `(${number})` : number;
  };

  const getDisplayRange = (item) => {
    if (Number(item?.max) == Infinity) {
      return `> ${displayNumber(item.min)}`;
    }

    if (Number(item?.min) == -Infinity) {
      return `< ${displayNumber(item.max)}`;
    }

    return `${displayNumber(item.min)} - ${displayNumber(item.max)}`;
  };

  const generateRanges = (chartRanges) => {
    var result = [];
    chartRanges?.forEach((item) => {
      result.push(getDisplayRange(item));
    });

    return result;
  };

  const findRangeMatchWithValue = (value, range) => {
    return range?.find((x) => Number(x.max) > value);
  };

  const loadDataDashboard = async (id) => {
    setLoading(true);
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      return res?.data;
    } catch {
    } finally {
      setLoading(false);
    }
    return 0;
  };

  function degToIndexOfDirectionArray(degree, numberOfDirection) {
    var val = Math.floor(degree / (360.0 / numberOfDirection) + 0.5);
    return val % numberOfDirection;
  }

  const prepareDataObject = (
    speedParamsDataPoints,
    directionParamsDataPoints,
    speedParamId,
    directionParamId
  ) => {
    let dataLength = Math.max(
      speedParamsDataPoints.length,
      directionParamsDataPoints.length
    );

    let allParameterDatas = [];

    for (let i = 0; i < dataLength; i++) {
      const eventTime = speedParamsDataPoints[i]?.eventTime;
      const speedValue = speedParamsDataPoints[i]?.value;
      const directionValue = directionParamsDataPoints[i]?.value;

      allParameterDatas.push({
        eventTime,
        [speedParamId]: speedValue,
        [directionParamId]: directionValue,
      });
    }

    const groupByEventTimeResult = groupBy(
      allParameterDatas,
      (result) => result.eventTime
    );
    return groupByEventTimeResult;
  };

  const pushDataCustomChart = (customChartDataConfig, dataObject) => {
    var directions = Direction[customChartDataConfig.number_of_direction];

    let sortedRange = customChartDataConfig?.dashboard_custom_chart_range?.sort(
      (a, b) => {
        if (Number(a.min) === Number(b.min))
          return Number(a.max) - Number(b.max);
        return Number(a.min) - Number(b.min);
      }
    );

    // generate data
    let newData = [];
    let rangesGenerated = generateRanges(sortedRange);

    directions?.forEach((value) => {
      let item = {
        angle: value,
      };
      rangesGenerated.forEach((range) => {
        item[`${range}`] = 0;
      });

      item["total"] = 0;
      newData.push(item);
    });
    Object.keys(dataObject)?.forEach(function (key) {
      // Theo yêu cầu của BE, value của direction sẽ là Number. Hiện tại đang trả về string => ko xử lý
      // Sau này sẽ trả về đúng number, hiện tại lấy đỡ direction value = speed value để có giá trị hiển thị lên chart.
      let directionValue = dataObject[key]?.[0]?.[directionParamId];
      let speedValue = dataObject[key]?.[0]?.[speedParamId];

      // if direction have value and value does not number type
      if (directionValue && isNaN(Number(directionValue))) {
        directionValue = speedValue;
      }

      if (!directionValue || !speedValue) return;

      // Nếu dữ liêu backend trả về cho `direction parameter` là number thì cần phải sử dụng hàm này để tìm index
      let indexDirection = degToIndexOfDirectionArray(
        directionValue,
        Number(customChartDataConfig.number_of_direction)
      );

      // Nếu dữ liêu backend trả về cho `direction parameter` là string thì dùng cái này
      // let indexDirection = directions.findIndex(x => x.trim() === dataObject[key][directionParamId])

      if (!newData[indexDirection]) return;

      let itemRange = findRangeMatchWithValue(speedValue, sortedRange);
      if (!itemRange) return;

      let value = newData[indexDirection][getDisplayRange(itemRange)];
      let total = newData[indexDirection]["total"];
      newData[indexDirection][getDisplayRange(itemRange)] =
        value + Number(speedValue);
      newData[indexDirection]["total"] = total + Number(speedValue);
    });

    let columns = ["angle"];
    rangesGenerated?.forEach((range) => {
      columns.push(range);
    });

    let colorRanges = [];
    sortedRange?.forEach((item) => {
      colorRanges.push(item.color);
    });

    setData({
      data: newData,
      columns: columns,
      colors: colorRanges,
    });
  };

  const stopPropagation = (e) => e.stopPropagation();

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  React.useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      if (!dashboardData) return;

      let dataObject = {};

      /* Prepare data from raw data */
      const getParameterData = (id) => {
        return dashboardData?.parameterDatas?.find(
          (item) => item.parameterDto?.parameterId === id
        );
      };

      let speedParameterData = getParameterData(speedParamId);
      let directionParameterData = getParameterData(directionParamId);

      if (
        speedParameterData !== undefined &&
        directionParameterData !== undefined
      ) {
        let speedParamsDataPoints = speedParameterData.dataPointDtos || [];
        let directionParamsDataPoints =
          directionParameterData.dataPointDtos || [];
        dataObject = prepareDataObject(
          speedParamsDataPoints,
          directionParamsDataPoints,
          speedParamId,
          directionParamId
        );
        pushDataCustomChart(props?.data?.dashboard_custom_chart, dataObject);
        setStartDate(moment(dashboardData?.startTime));
        setEndDate(moment(dashboardData?.endTime));

        setDataConfig({
          dataObject: dataObject,
          dashboardConfig: {
            ...props?.data?.dashboard_custom_chart,
            time_range: props?.data?.time_range,
            data_mode: props.data?.data_mode,
            time_point: props.data?.time_point,
          },
        });
      }
    };

    if (
      props?.data?.dashboard_custom_chart &&
      props?.data?.dashboard_custom_chart?.dashboard_custom_chart_range
    ) {
      initData();
    }
  }, [props?.data?.dashboard_custom_chart]);

  React.useEffect(() => {
    if (
      props.data.historical_time_range !== ZERO_TIME_RANGE &&
      dataConfig?.dataObject &&
      dataConfig?.dashboardConfig &&
      props.realTimeData?.eventTime &&
      (props.realTimeData.paramId === speedParamId ||
        props.realTimeData.paramId === directionParamId)
    ) {
      // Hiện tại chưa lấy đc value của 2 params cùng lúc nên lấy 1 value sử dụng cho 2 param luôn
      // const dataObject = {
      //   ...dataConfig?.dataObject,
      //   [props.realTimeData?.eventTime]: {
      //     ...dataConfig?.dataObject?.[props.realTimeData?.eventTime],
      //     [speedParamId]: props.realTimeData?.value,
      //     [directionParamId]: props.realTimeData?.value
      //   }
      // }
      const dataObject = {
        ...dataConfig?.dataObject,
        [props.realTimeData?.eventTime]: {
          ...dataConfig?.dataObject?.[props.realTimeData?.eventTime],
          [props.realTimeData.paramId]: props.realTimeData?.value,
        },
      };

      const timeKey =
        dataConfig?.dashboardConfig?.data_mode === "HISTORICAL_DATA"
          ? dataConfig?.dashboardConfig?.time_range
          : dataConfig?.dashboardConfig?.time_point;

      const range = TimeResolutions[timeKey];

      pushDataCustomChart(dataConfig?.dashboardConfig, dataObject);
      setDataConfig((pre) => ({
        ...pre,
        dataObject: {
          ...pre.dataObject,
          ...dataObject,
        },
      }));
      setEndDate(moment(range?.end()));
    }
  }, [
    props.data.historical_time_range,
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
  ]);

  return (
    <div className="custom-chart-card dashboard-item-card"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
      {showHeader && (
        <Box
          className="custom-chart-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <Box display="flex" alignItems="center">
            <EqualizerIcon />
            &nbsp;&nbsp;{name}
          </Box>
        </Box>
      )}
      <span
        className="custom-chart-action-box"
        style={{ color: showHeader ? "#fff" : "#000" }}
      >
        {props.isPublic !== true &&
          props.data?.dashboard_custom_chart?.range_selection && (
            <TodayIcon onClick={toggleFilter} onMouseDown={stopPropagation} />
          )}

        {props?.data?.dashboard_custom_chart?.full_screen && (
          <FullscreenIcon
            onClick={toggleFullScreen}
            onMouseDown={stopPropagation}
          />
        )}
      </span>

      {showFilter && (
        <div className="date-range-box" ref={wrapperRef}>
          <div className="date-range-box-extended"></div>
          <div className="date-range-box-main">
            {historicalTimeRange.map((range) => (
              <div
                key={range.id}
                className={
                  range.id === activeRange
                    ? "date-range-box-item date-range-box-item--active"
                    : "date-range-box-item"
                }
                onClick={() => onSelectRange(range.id)}
                onMouseDown={stopPropagation}
              >
                {range[`name_${language}`]}
              </div>
            ))}
          </div>
        </div>
      )}

      <DateRangeModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        onChange={onSelectCustomRange}
        maxDate={new Date()}
        onSubmit={onSubmitCustomTimeRange}
      />
      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div className="custom-chart-card-body">
          <div className="custom-chart-card-content">
            {props?.data?.dashboard_custom_chart?.dashboard_custom_chart_range
              ?.length ? (
              <div
                style={{
                  paddingTop: 10,
                }}
              >
                <WindroseChart
                  data={data.data}
                  columns={data.columns}
                  colors={data.colors}
                  displayLegend={props?.data?.dashboard_custom_chart?.legend}
                  legend={legendTitle}
                  unit={speedUnits}
                  width={500}
                  height={500}
                />
              </div>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
            
          </div>
          <Tooltip
            title={`${startDate.format(DATE_TIME_FORMAT)} - ${endDate.format(DATE_TIME_FORMAT)}`}
            placement="right"
          >
              <span
                className="time-tooltip"
                style={{
                  left: 24,
                  bottom: hovered && !props?.isView ? 64 : 24
                }}
              >
                <QueryBuilderIcon />
              </span>
        </Tooltip>
        </div>
      )}

      <Modal
        open={fullScreen}
        onClose={toggleFullScreen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="custom-chart-modal-fullscreen">
          <Box display="flex" justifyContent="flex-end">
            <span
              style={{ cursor: "pointer" }}
              onClick={toggleFullScreen}
              onMouseDown={stopPropagation}
            >
              <CloseIcon />
            </span>
          </Box>

          <div
            className="chart-fullscreen-content"
            style={{
              maxWidth: props?.data?.dashboard_custom_chart?.legend
                ? "80%"
                : "50%",
            }}
          >
            <WindroseChart
              data={data.data}
              columns={data.columns}
              colors={data.colors}
              displayLegend={props?.data?.dashboard_custom_chart?.legend}
              legend={legendTitle}
              unit={speedUnits}
              width={500}
              height={500}
            />
          </div>
        </Box>
      </Modal>

      <DashboardCardButtons
        visible={hovered && !props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default CustomChartCard;
