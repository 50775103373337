import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import './styles.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button } from '@material-ui/core';
import SharedInputField from '../../../../../shared/components/SharedInputField';
import SharedSelectField from '../../../../../shared/components/SharedSelectField';
import { useFormik } from 'formik';
import ConfigureArea from '../../../../../shared/components/Dashboard/ConfigureArea';
import HeaderConfigurator from '../../../../../shared/components/Dashboard/HeaderConfigurator';
import * as Yup from "yup";
import { useLanguage } from '../../../../../shared/hooks';
import useModal from '../useModal';
import ModalContainer from '../../common/ModalContainer';
import StaticTextForm from './StaticTextForm';
import { prepareStaticTextOutput } from '../helpers';
import StaticTextFormSchema from '../schemas/StaticTextFormSchema';

const initialValues = {
  name_en: "",
  name_vi: "",
  show_header: false,
  content_type: "TEXT",
  header_color: "#1E1E2D",
}

export default function MenuModal(props) {
  const { open, handleClose } = props;
  const intl = useIntl();

  const { openModal, language, setOpenModal, toggleOpen } = useModal({ modalOpen: open, handleClose });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || initialValues.name_en,
      name_vi: props.data?.name_vi || initialValues.name_vi,
      header_color: props.data?.header_color || initialValues.header_color,
      show_header: props.data?.show_header || initialValues.show_header,
      content_type: props.data?.dashboard_static_text?.content_type || initialValues.content_type,
      content: props.data?.dashboard_static_text?.content?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>') || initialValues.content?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>'),
    },
    enableReinitialize: true,
    validationSchema: StaticTextFormSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;

      props['handleEdit'](id, prepareStaticTextOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  return <ModalContainer
    formik={formik}
    editing={props?.editing}
    handleClose={props?.handleClose}
    open={openModal}
    toggleOpen={toggleOpen}
    newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_STATIC_TEXT" />}
    editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_STATIC_TEXT" />}>
    <StaticTextForm
      formik={formik}
      intl={intl}
      language={language}
    />
  </ModalContainer>
}
