import React from 'react';
import './style.css';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Box } from '@material-ui/core';
import { useLanguage } from '../../../../../../shared/hooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DISPLAY_TEXT = 'text';
const DISPLAY_TOOL_TIP = 'tooltip';

function SharedDropdown(props) {
  const classes = useStyles();
  const [language] = useLanguage();
  const {
    data,
    label,
    displayNameType = DISPLAY_TEXT || DISPLAY_TOOL_TIP,
    onChange,
    value,
    required
  } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderItem = (item) => {
    if (displayNameType === DISPLAY_TOOL_TIP) {
      return (
        <Tooltip title={item[`name_${language}`]} arrow>
          <Box width="100%">
            <img className="item-image" alt="" src={item.icon} style={{verticalAlign: "middle"}} />
          </Box>
        </Tooltip>
      );
    }

    return (
      <Box width="100%">
        <img className="item-image" alt="" src={item.icon} />
        <span className="ml-2">{item[`name_${language}`]}</span>
      </Box>
    );
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {
        label &&
        <Box className="form-label">
          {
            required &&
            <p className="required">*</p>
          }
          { label }
        </Box>
      }

      <Select className="select-style bg-white" value={value} onChange={handleChange}>
        {data.map((item, index) => (
          <MenuItem
            className="item-have-check-icon menu-item-line"
            key={index}
            value={item.value}
          >
            {renderItem(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SharedDropdown;
