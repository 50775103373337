import { Box, CircularProgress, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
// import RestoreIcon from "@material-ui/icons/Restore";
import TodayIcon from "@material-ui/icons/Today";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { PREDICTION_DATA } from "app/pages/Dashboard/components/Modals/contants";
import { invertColor } from "app/utils/utils";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import {
  default as workspaceDashboard,
  default as workspaceDashboardApi,
} from "../../../../api/workspaceDashboardApi";
import { DATE_TIME_FORMAT } from "../../../../constant/date-time-format";
import { useLanguage } from "../../../hooks";
import { historicalTimeRange } from "../DataModeConfigurator/data";
import DateRangeModal from "../DateRangeModal";
import StationStandardChartPrediction from "../StationPredictionStandardChart";
import { DashboardCardButtons } from "../DashboardCardButtons";

import "./styles.css";

const initialValues = {
  name_en: "",
  name_vi: "",
  show_header: true,
  header_color: "#1E1E2D",
  prediction_time_range: "1-DAY",
  historical_time_range: "1-DAY",
  data_mode: "HISTORICAL_DATA",
  zoom: true,
  legend: true,
  range_selection: true,
  full_screen: true,
  dashboard_standard_chart_parameter: [],
};

const ZERO_TIME_RANGE = "0";
const minCachedDate = 0;
const maxCachedDate = 0;

const StandardChartCardForPrediction = (props) => {
  const wrapperRef = React.useRef(null);
  const [showFilter, setShowFilter] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const dashboardParameters =
    props.data.dashboard_prediction_parameter_standard_chart
      ?.dashboard_prediction_parameter_standard_chart_parameter || [];
  const dashboardId = props.data?.id;
  const [language] = useLanguage();
  const intl = useIntl();
  const chartRef = useRef(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [state, setState] = useState({
    data: {},
    loading: false,
    timeRange: {
      startTime: "",
      endTime: "",
    },
    masterTimeRange: {
      startTime: "",
      endTime: "",
    },
  });
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [zoomableRight, setZoomableRight] = useState(true);
  const [zoomableLeft, setZoomableLeft] = useState(true);
  const [sliderZoomRate, setSliderZoomRate] = useState(0);

  const renderStandardChartTitle = () =>
    intl.formatMessage({
      id: "CLIENT.DASHBOARD.MODAL.NEW_PREDICTION_STANDARD_CHART",
    });
  const name =
    (props["data"] && props["data"][`name_${language}`]) ||
    renderStandardChartTitle();
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;

  const enableLoading = () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  };

  const disableLoading = () => {
    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res;
      }
    } catch (error) {
      console.log(error);
    } finally {
      disableLoading();
    }
    return null;
  };

  useEffect(() => {
    const initData = async () => {
      const res = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      if (res) {
        const dashboardData = res?.data;
        const data = {};
        if (res.isMultipleLines) {
          const dashboardDetail = res?.dashboardDetail;
          // mapping for all prediction parameters
          dashboardData?.parameterDatas?.forEach((item) => {
            const values = {};
            const parameterDetail =
              dashboardDetail.dashboard_prediction_parameter_standard_chart_parameter.filter(
                (predictionParameter) => {
                  return (
                    predictionParameter.prediction_parameter_id ===
                    item.parameterDto.parameterId
                  );
                }
              );

            item.dataPointDtos?.forEach((point) => {
              values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                point.value;
            });
            if (parameterDetail.length > 0) {
              data[item.parameterDto.parameterId] = {
                ...parameterDetail[0],
                values,
                parameter: null,
              };
            } else {
              data[item.parameterDto.parameterId] = {
                ...dashboardDetail
                  .dashboard_prediction_parameter_standard_chart_parameter[0],
                values,
                main_color: "#000000",
                prediction_parameter: null,
              };
            }
          });
        } else {
          dashboardParameters?.forEach((item) => {
            const values = {};

            dashboardData?.parameterDatas
              ?.find(
                (elm) => elm?.parameterDto?.parameterId === item?.parameter_id
              )
              ?.dataPointDtos?.forEach((point) => {
                values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                  point.value;
              });

            if (
              res?.dataPrediction &&
              res?.dataPrediction?.parameterDatas?.length > 0 &&
              res?.dataPrediction?.parameterDatas[0].dataPointDtos
            ) {
              res.dataPrediction.parameterDatas[0].dataPointDtos.forEach(
                (point) => {
                  values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                    point.value;
                }
              );
            }
            data[item.parameter_id] = {
              ...item,
              values,
            };
          });
        }
        setState((prevState) => ({
          ...prevState,
          data,
          timeRange: {
            startTime: dashboardData.startTime,
            endTime: dashboardData.endTime,
          },
          masterTimeRange: {
            startTime: dashboardData.startTime,
            endTime: dashboardData.endTime,
          },
        }));
      }
    };
    if (
      props.data.dashboard_prediction_parameter_standard_chart?.dashboard_id
    ) {
      initData();
      console.log("init data");
    }
  }, [props["data"]]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const prepareOutput = (values, historicalValue) => {
    const dataUpdate = {
      ...values,
      dashboard_standard_chart_parameter:
        values["dashboard_standard_chart_parameter"].length > 0
          ? values["dashboard_standard_chart_parameter"].map((item, index) => ({
              ...item,
              id: item.originId || "",
              index,
            }))
          : [],
      dashboard_standard_chart_parameter_delete:
        props.data?.dashboard_prediction_parameter_standard_chart?.dashboard_prediction_parameter_standard_chart_parameter
          ?.map((item) => item.id)
          ?.filter(
            (item) =>
              !values["dashboard_standard_chart_parameter"]
                ?.map((item) => item.id)
                ?.includes(item)
          ) || [],
      // historical_time_range: historicalValue,
    };

    const output = {
      ...values,
      dataUpdate,
    };

    return output;
  };

  const getOutputDefault = () => {
    return {
      name_en: props.data?.name_en || initialValues.name_en,
      name_vi: props.data?.name_vi || initialValues.name_vi,
      header_color: props.data?.header_color || initialValues.header_color,
      show_header:
        props.data?.show_header !== undefined
          ? props.data?.show_header
          : initialValues.show_header,
      data_mode:
        props.data?.dashboard_standard_chart?.data_mode ||
        props.data?.dashboard_prediction_parameter_standard_chart?.data_mode ||
        initialValues.data_mode,
      zoom:
        props.data?.dashboard_standard_chart?.zoom !== undefined ||
        props.data?.dashboard_prediction_parameter_standard_chart?.zoom !==
          undefined
          ? props.data?.dashboard_standard_chart?.zoom ||
            props.data?.dashboard_prediction_parameter_standard_chart?.zoom
          : initialValues.zoom,
      legend:
        props.data?.dashboard_standard_chart?.legend !== undefined ||
        props.data?.dashboard_prediction_parameter_standard_chart?.legend !==
          undefined
          ? props.data?.dashboard_standard_chart?.legend ||
            props.data?.dashboard_prediction_parameter_standard_chart?.legend
          : initialValues.legend,
      range_selection:
        props.data?.dashboard_standard_chart?.range_selection !== undefined ||
        props.data?.dashboard_prediction_parameter_standard_chart
          ?.range_selection !== undefined
          ? props.data?.dashboard_standard_chart?.range_selection ||
            props.data?.dashboard_prediction_parameter_standard_chart
              ?.range_selection
          : initialValues.range_selection,
      full_screen:
        props.data?.dashboard_standard_chart?.full_screen !== undefined ||
        props.data?.dashboard_prediction_parameter_standard_chart
          ?.full_screen !== undefined
          ? props.data?.dashboard_standard_chart?.full_screen ||
            props.data?.dashboard_prediction_parameter_standard_chart
              ?.full_screen
          : initialValues.full_screen,
      dashboard_standard_chart_parameter:
        props.data?.dashboard_standard_chart?.dashboard_standard_chart_parameter
          ?.map((item) => ({
            ...item,
            marker_size: parseInt(item.marker_size),
            originId: item.id,
          }))
          ?.sort((a, b) => a.index - b.index) ||
        props.data?.dashboard_prediction_parameter_standard_chart?.dashboard_prediction_parameter_standard_chart_parameter
          ?.map((item) => ({
            ...item,
            marker_size: parseInt(item.marker_size),
            originId: item.id,
          }))
          ?.sort((a, b) => a.index - b.index) ||
        initialValues.dashboard_standard_chart_parameter,
    };
  };

  const onSelectRange = (value) => {
    const CustomTimeRangeValue = "0-DAY";
    if (value === CustomTimeRangeValue) {
      setShowModal(true);
    } else {
      const timeRange = `${value}`.split("-")?.at(0);
      const timeRangeType = `${value}`.split("-")?.at(1);
      const isHistoricalData =
        props.data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA ||
        true;
      const isCustomRange = timeRange === "0";
      const values = {
        ...getOutputDefault(),
        prediction_time_range:
          isHistoricalData || isCustomRange ? "" : timeRange,
        prediction_time_range_type:
          isHistoricalData || isCustomRange ? "" : timeRangeType,
        historical_time_range:
          isHistoricalData && !isCustomRange ? timeRange : "",
        historical_time_range_type:
          isHistoricalData && !isCustomRange ? timeRangeType : "",
      };
      props["onEditDashboard"](props["type"])(
        props["id"],
        prepareOutput(values)
      );
      setShowFilter(!showFilter);
    }
  };

  const onSubmitCustomTimeRange = (value) => {
    const isHistoricalData =
      props.data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA;
    const values = {
      ...getOutputDefault(),
      prediction_time_range: isHistoricalData ? "" : "0",
      prediction_time_range_type: isHistoricalData ? "" : "DAY",
      historical_time_range: isHistoricalData ? "0" : "",
      historical_time_range_type: isHistoricalData ? "DAY" : "",
      historical_start_time: (value?.startTime || moment())
        ?.set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      historical_end_time: (value?.endTime || moment())
        ?.set("hour", 23)
        .set("minute", 59)
        .set("second", 59),
    };
    props["onEditDashboard"](props["type"])(props["id"], prepareOutput(values));
    setShowFilter(false);
  };

  // const onSelectCustomRange = (range) => {
  // 	setStartDate(moment(new Date(range.startDate)));
  // 	setEndDate(moment(new Date(range.endDate)));
  // };

  const handleClickOutside = (e) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      setShowFilter(false);
    }
  };

  const onZoomIn = () => {
    chartRef.current?.zoom(1.1);
  };

  const onZoomOut = () => {
    chartRef.current?.zoom(0.9);
  };

  const onZoomInSlider = () => {
    if (sliderZoomRate < 1) {
      const diff = Math.abs(
        moment(new Date(state?.timeRange?.endTime)).diff(
          moment(new Date(state?.timeRange?.startTime)),
          "hours"
        )
      );
      const spaceRange = Math.round(diff * 0.1);
      const newTimeRangeStart = moment(new Date(state?.timeRange?.startTime))
        .add(spaceRange, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss");
      const newTimeRangeEnd = moment(new Date(state?.timeRange?.endTime))
        .subtract(spaceRange, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss");

      setSliderZoomRate((prev) => prev + 0.1);

      let newTimeRange = {};

      if (zoomableLeft) {
        newTimeRange["startTime"] = newTimeRangeStart;
      }

      if (zoomableRight) {
        newTimeRange["endTime"] = newTimeRangeEnd;
      }

      setState((prevState) => ({
        ...prevState,
        timeRange: {
          ...prevState?.timeRange,
          ...newTimeRange,
        },
      }));
    }
  };

  const onZoomOutSlider = () => {
    if (sliderZoomRate > 0) {
      const diff = Math.abs(
        moment(new Date(state?.timeRange?.endTime)).diff(
          moment(new Date(state?.timeRange?.startTime)),
          "hours"
        )
      );
      const spaceRange = Math.round(diff * 0.1);
      const newTimeRangeStart = moment(new Date(state?.timeRange?.startTime))
        .subtract(spaceRange, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss");
      const newTimeRangeEnd = moment(new Date(state?.timeRange?.endTime))
        .add(spaceRange, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss");

      setSliderZoomRate((prev) => prev - 0.1);

      let newTimeRange = {};

      if (zoomableLeft) {
        newTimeRange["startTime"] = newTimeRangeStart;
      }

      if (zoomableRight) {
        newTimeRange["endTime"] = newTimeRangeEnd;
      }

      setState((prevState) => ({
        ...prevState,
        timeRange: {
          ...prevState?.timeRange,
          ...newTimeRange,
        },
      }));
    }
  };

  // const onRestoreRange = () => {
  // 	const newStartTime = moment(
  // 		new Date(state?.masterTimeRange?.startTime)
  // 	).valueOf();

  // 	const newEndTime = moment(
  // 		new Date(state?.masterTimeRange?.endTime)
  // 	).valueOf();

  // 	setState((prevState) => ({
  // 		...prevState,
  // 		timeRange: state?.masterTimeRange,
  // 	}));

  // 	setStartTime(newStartTime);
  // 	setEndTime(newEndTime);
  // 	setZoomableRight(true);
  // 	setZoomableLeft(true);
  // 	setSliderZoomRate(0);

  // 	dispatch(
  // 		setChartRange({
  // 			chartId: props.isPublic ? props.data?.public_id : props.data?.id,
  // 			chartData: {
  // 				startTime: newStartTime,
  // 				endTime: newEndTime,
  // 			},
  // 		})
  // 	);
  // };
  const fetchApiData = async (params) => {
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getDashboardPublicDataWithDate(
          dashboardId,
          moment(new Date(params.start)).utc(),
          moment(new Date(params.end)).utc()
        );
      } else {
        res = await workspaceDashboard.getDashboardDataWithDate(
          dashboardId,
          moment(new Date(params.start)).utc(),
          moment(new Date(params.end)).utc()
        );
      }

      if (res?.data && res?.message !== "Invalid Parameter!") {
        const dashboardData = res?.data;
        const data = {};
        if (res.isMultipleLines) {
          const dashboardDetail = res?.dashboardDetail;
          // mapping for all prediction parameters
          dashboardData?.parameterDatas?.forEach((item) => {
            const values = {};
            const parameterDetail =
              dashboardDetail.dashboard_prediction_parameter_standard_chart_parameter.filter(
                (predictionParameter) => {
                  return (
                    predictionParameter.prediction_parameter_id ===
                    item.parameterDto.parameterId
                  );
                }
              );

            item.dataPointDtos?.forEach((point) => {
              values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                point.value;
            });
            if (parameterDetail.length > 0) {
              data[item.parameterDto.parameterId] = {
                ...parameterDetail[0],
                values,
                parameter: null,
              };
            } else {
              data[item.parameterDto.parameterId] = {
                ...dashboardDetail
                  .dashboard_prediction_parameter_standard_chart_parameter[0],
                values,
                main_color: "#000000",
                prediction_parameter: null,
              };
            }
          });
        } else {
          dashboardParameters?.forEach((item) => {
            const values = {};

            dashboardData?.parameterDatas
              ?.find(
                (elm) => elm?.parameterDto?.parameterId === item?.parameter_id
              )
              ?.dataPointDtos?.forEach((point) => {
                values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                  point.value;
              });

            if (
              res?.dataPrediction &&
              res?.dataPrediction?.parameterDatas?.length > 0 &&
              res?.dataPrediction?.parameterDatas[0].dataPointDtos
            ) {
              res.dataPrediction.parameterDatas[0].dataPointDtos.forEach(
                (point) => {
                  values[moment(point.eventTime).format(DATE_TIME_FORMAT)] =
                    point.value;
                }
              );
            }
            data[item.parameter_id] = {
              ...item,
              values,
            };
          });
        }

        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);

      return null;
    }
  };

  const getChartData = async (params) => {
    if (params.start !== 0 && params.end !== 0) {
      setIsLoading(true);

      const output = await fetchApiData({
        start: params.start,
        end: params.end,
      });

      setIsLoading(false);
      console.log("output", output);
      if (output !== null) {
        setState((prev) => ({
          ...prev,
          data: output,
        }));
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleFullScreen = () => setFullScreen(!fullScreen);

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    const canReceiveRealtimeData =
      props.data.historical_time_range !== ZERO_TIME_RANGE;
    if (
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId &&
      canReceiveRealtimeData
    ) {
      if (state.data?.[props?.realTimeData?.paramId]) {
        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            [props?.realTimeData?.paramId]: {
              ...prevState.data?.[props?.realTimeData?.paramId],
              values: {
                ...prevState.data?.[props?.realTimeData?.paramId]?.values,
                [moment(props.realTimeData?.eventTime).format(
                  DATE_TIME_FORMAT
                )]: props.realTimeData?.value?.toString(),
              },
            },
          },
        }));
        if (!isNaN(startTime) && startTime) {
          getChartData(
            { start: startTime, end: moment().valueOf() },
            minCachedDate,
            maxCachedDate
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
    state.loading,
    props.data.historical_time_range,
  ]);

  useEffect(() => {
    const timeRangeStartTime = moment(
      new Date(state?.timeRange?.startTime)
    )?.valueOf();
    const timeRangeEndTime = moment(
      new Date(state?.timeRange?.endTime)
    )?.valueOf();

    if (endTime !== timeRangeEndTime && !isNaN(timeRangeEndTime)) {
      setEndTime(timeRangeEndTime);
    }

    if (startTime !== timeRangeStartTime && !isNaN(timeRangeStartTime)) {
      setStartTime(timeRangeStartTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.timeRange.startTime, state?.timeRange.endTime]);

  useEffect(() => {
    const timeRangeStartTime = moment(
      new Date(state?.timeRange?.startTime)
    )?.valueOf();
    const timeRangeEndTime = moment(
      new Date(state?.timeRange?.endTime)
    )?.valueOf();

    if (startTime <= timeRangeStartTime) {
      setZoomableLeft(false);
    } else {
      setZoomableLeft(true);
    }

    if (endTime >= timeRangeEndTime) {
      setZoomableRight(false);
    } else {
      setZoomableRight(true);
    }
  }, [state?.timeRange]);

  useEffect(() => {
    if (!isNaN(startTime) && !isNaN(endTime) && startTime && endTime) {
      getChartData(
        { start: startTime, end: endTime },
        minCachedDate,
        maxCachedDate
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  return (
    <div className="standard-chart-card dashboard-item-card">
      <Box
        className="standard-chart-card-title"
        style={{
          background: showHeader ? headerColor : "transparent",
          color: invertColor(headerColor),
        }}
      >
        {showHeader && (
          <Box display="flex" alignItems="center">
            <EqualizerIcon />
            &nbsp;&nbsp;{name}
          </Box>
        )}

        <Box
          ml="auto"
          color={showHeader ? "#fff" : "#111"}
          p={showHeader ? 0 : 2}
        >
          {/* <RestoreIcon onClick={onRestoreRange} style={{ cursor: "pointer" }} /> */}

          {props.isPublic !== true &&
            props["data"] &&
            (props.data?.dashboard_standard_chart
              ?.dashboard_standard_chart_parameter?.length > 0 ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.dashboard_prediction_parameter_standard_chart_parameter
                ?.length > 0) &&
            (props.data.dashboard_standard_chart?.range_selection ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.range_selection) && (
              <TodayIcon
                onClick={toggleFilter}
                style={{ cursor: "pointer" }}
                onMouseDown={stopPropagation}
              />
            )}

          {props["data"] &&
            (props.data?.dashboard_standard_chart
              ?.dashboard_standard_chart_parameter?.length > 0 ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.dashboard_prediction_parameter_standard_chart_parameter
                ?.length > 0) &&
            (props.data.dashboard_standard_chart?.zoom ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.zoom) && (
              <ZoomOutIcon
                onClick={onZoomOut}
                style={{ cursor: "pointer" }}
                onMouseDown={stopPropagation}
              />
            )}

          {props["data"] &&
            (props.data?.dashboard_standard_chart
              ?.dashboard_standard_chart_parameter?.length > 0 ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.dashboard_prediction_parameter_standard_chart_parameter
                ?.length > 0) &&
            (props.data.dashboard_standard_chart?.zoom ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.zoom) && (
              <ZoomInIcon
                onClick={onZoomIn}
                style={{ cursor: "pointer" }}
                onMouseDown={stopPropagation}
              />
            )}

          {props["data"] &&
            (props.data?.dashboard_standard_chart
              ?.dashboard_standard_chart_parameter?.length > 0 ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.dashboard_prediction_parameter_standard_chart_parameter
                ?.length > 0) &&
            (props.data.dashboard_standard_chart?.full_screen ||
              props.data?.dashboard_prediction_parameter_standard_chart
                ?.full_screen) && (
              <FullscreenIcon
                style={{ cursor: "pointer" }}
                onClick={toggleFullScreen}
                onMouseDown={stopPropagation}
              />
            )}
        </Box>
      </Box>

      {showFilter && (
        <div className="date-range-box" ref={wrapperRef}>
          <div className="date-range-box-extended"></div>

          <div className="date-range-box-main">
            {historicalTimeRange.map((range) => (
              <div
                key={range.id}
                className={
                  range.value === props["data"]?.["historical_time_range"]
                    ? "date-range-box-item date-range-box-item--active"
                    : "date-range-box-item"
                }
                onClick={() => onSelectRange(range.value)}
                onMouseDown={stopPropagation}
              >
                {range[`name_${language}`]}
              </div>
            ))}

            {/* <div className="date-range-box-item" onClick={toggleModal}>
              {language === "en" ? "Custom" : "Tuỳ Chỉnh"}
            </div> */}
          </div>
        </div>
      )}

      <DateRangeModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        // onChange={onSelectCustomRange}
        onSubmit={onSubmitCustomTimeRange}
        maxDate={new Date()}
      />
      {props["loading"] || state.loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <>
          <div
            class="standard-chart-card-content"
            style={{ padding: "20px 0" }}
          >
            {props["data"] &&
            props.data?.dashboard_prediction_parameter_standard_chart
              ?.dashboard_prediction_parameter_standard_chart_parameter
              ?.length > 0 ? (
              <div style={{ padding: 24, paddingTop: 0 }}>
                <StationStandardChartPrediction
                  isPublic={props.isPublic}
                  data={state.data}
                  showRangeSelection={true}
                  chartRef={chartRef}
                  showLegend={
                    props.data.dashboard_prediction_parameter_standard_chart
                      ?.legend
                  }
                  timeRange={state.timeRange}
                  dashboardParameters={
                    props.data.dashboard_prediction_parameter_standard_chart
                      ?.dashboard_prediction_parameter_standard_chart_parameter
                  }
                  startTime={startTime}
                  endTime={endTime}
                  shouldShowZoom={
                    props.data.historical_time_range !== ZERO_TIME_RANGE
                  }
                  // maxRangeIndex={parseInt(props['data']['range'])}
                  sliderZoomRate={sliderZoomRate}
                  onZoomInSlider={onZoomInSlider}
                  onZoomOutSlider={onZoomOutSlider}
                  isEnabledMultipleColor={
                    props.data.dashboard_prediction_parameter_standard_chart
                      ?.dashboard_prediction_parameter_standard_chart_parameter
                  }
                  isLoading={isLoading}
                  showGridlines={props.data.dashboard_standard_chart?.gridlines}
                />
              </div>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </div>

          <DashboardCardButtons
            visible={!props?.isView}
            onEdit={props?.handleEdit}
            onDelete={props?.handleRemove}
          />

          <Modal
            open={fullScreen}
            onClose={toggleFullScreen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              style={{
                background: "#fff",
                width: "70vw",
                margin: "50px auto 0 auto",
                padding: "30px",
                borderRadius: "10px",
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={toggleFullScreen}
                  onMouseDown={stopPropagation}
                >
                  <CloseIcon />
                </span>
              </Box>

              <StationStandardChartPrediction
                isPublic={props.isPublic}
                data={state.data}
                showRangeSelection={true}
                chartRef={chartRef}
                showLegend={
                  props.data.dashboard_standard_chart?.legend ||
                  props.data.dashboard_prediction_parameter_standard_chart
                    ?.legend
                }
                dashboardId={
                  props.isPublic ? props.data?.public_id : props.data?.id
                }
                timeRange={state.timeRange}
                dashboardParameters={
                  props.data.dashboard_prediction_parameter_standard_chart
                    ?.dashboard_prediction_parameter_standard_chart_parameter
                }
                startTime={startTime}
                endTime={endTime}
                shouldShowZoom={
                  props.data.historical_time_range !== ZERO_TIME_RANGE
                }
                // maxRangeIndex={parseInt(props['data']['range'])}
                sliderZoomRate={sliderZoomRate}
                onZoomInSlider={onZoomInSlider}
                onZoomOutSlider={onZoomOutSlider}
                isEnabledMultipleColor={
                  props.data.dashboard_prediction_parameter_standard_chart
                    ?.dashboard_prediction_parameter_standard_chart_parameter
                }
                isLoading={isLoading}
                showGridlines={props.data.dashboard_standard_chart?.gridlines}
              />
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};
const StandardChartCardForPredictionMemo = React.memo(
  StandardChartCardForPrediction
);
export default StandardChartCardForPredictionMemo;
