import { SET_CHART_RANGE, REMOVE_CHART_RANGE } from "../actions/types";

const initialState = {};

const chartRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_RANGE: {
      const { chartId, chartData } = action?.payload;

      return { ...state, [chartId]: chartData };
    }

    case REMOVE_CHART_RANGE: {
      const { chartId } = action?.payload;
      let newState = { ...state };

      delete newState[chartId];

      return newState;
    }

    default: {
      return state;
    }
  }
};
export default chartRangeReducer;
