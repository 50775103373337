import { useLanguage, useLoading, usePermissions } from "app/shared/hooks";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchListInitialValues } from "../constants";

const useDataSharingList = ({ intl, dataSharingApi }) => {
  const [loading, enableLoading, disableLoading] = useLoading();
  const [pageLoading, enablePageLoading, disablePageLoading] = useLoading();
  const [openModalAction, setOpenModalAction] = useState(false);
  const [openModalMerge, setOpenModalMerge] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [dataSharing, setDataSharing] = useState({
    data: [],
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([
    {
      field: "title",
      sort: "desc",
    },
  ]);
  const [data, setData] = useState({
    status: [],
    locationTypes: [],
    stations: [],
  });
  const history = useHistory();
  const [language] = useLanguage();
  const { isAllowed } = usePermissions();
  const [connections, setConnections] = useState([]);
  const [stationData, setStationData] = useState({});
  const [autoPush, setAutoPush] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [createTabIndex, setCreateTabIndex] = useState(0);

  const onSortModelChange = (params) => {
    setSortModel([
      {
        field: params.field,
        sort: params.sort,
      },
    ]);
  };
  const onPageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  const onPageChange = (params) => {
    setPage(params.page);
  };

  const fetchData = async (values) => {
    if (!values) {
      return;
    }

    try {
      enableLoading();
      const params = {
        page,
        amount: pageSize,
        search: values && values.search,
        status_id: values.status,
        location_type_id: values.location_type,
        order: sortModel[0].field || "",
        arrangement: sortModel[0].sort.toUpperCase() || "",
      };
      const res = await dataSharingApi.getAllDataSharingRequest(params);
      if (res.success) {
        setDataSharing((prev) => ({
          ...prev,
          data: res.data,
          total: res.count,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      disableLoading();
    }
  };

  const onEdit = (item, { page }) => {
    const params = {};

    if (page > 0) {
      params["ppi"] = page;
    }

    history.push({
      pathname: `/data-sharing-request/edit/${item.id}`,
      search: "?" + new URLSearchParams(params).toString(),
    });
  };

  const onShare = (item) => {
    setSelectedItem(item);
    handleOpenModalAction();
  };

  const toggleModalAction = () => {
    setOpenModalAction(!openModalAction);
  };

  const handleCloseModalAction = () => {
    setOpenModalAction(false);
  };

  const handleOpenModalAction = () => {
    setOpenModalAction(true);
  };

  const toggleModalCreate = () => {
    setOpenModalCreate(!openModalCreate);
  };

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
  };
  const toggleModalMerge = () => {
    setOpenModalMerge(!openModalMerge);
  };

  const handleCloseModalMerge = (needRefresh) => {
    setOpenModalMerge(false);
    if (needRefresh) {
      fetchData();
    }
  };

  const handleOpenModalMerg = () => {
    setOpenModalMerge(true);
  };

  const handleClear = (initialValues) => {
    // formik.setValues(initialValues);
    fetchData(initialValues);
    setPage(0);
    setPageSize(5);
  };

  const submitList = (values) => {
    if (page === 0) {
      fetchData(values);
    } else {
      setPage(0);
      setPageSize(5);
    }
  };

  // Connections
  const onAddConnection = (item) => {
    setConnections((prev) => prev?.concat(item));
  };

  const onEditConnection = (item) => {
    const newConnections = connections?.map((connection) => {
      if (connection?.username === item?.username) {
        connection = {
          ...connection,
          ...item,
        };
      }

      return connection;
    });

    setConnections(newConnections);
  };

  const onDeleteConnection = (id) => {
    setConnections((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onClearConnection = () => {
    setConnections([]);
  };

  // Parameters
  const onAddParameter = (item) => {
    setParameters((prev) => prev?.concat(item));
  };

  const onEditParameter = (data) => {
    let newParameters = [...parameters];

    setParameters(newParameters);
  };

  const onDeleteParameter = (id) => {
    setParameters((prev) => prev?.filter((item) => item?.id !== id));
  };

  const onClearParameter = () => {
    setParameters([]);
  };

  const handleCloseModalCreate = () => {
    onClearConnection();
    onClearParameter();
    setCreateTabIndex(0);

    setOpenModalCreate(false);
    handleClear(SearchListInitialValues);
  };

  const dataSharingMaster = {
    data,
    page,
    pageSize,
    dataSharing,
    sortModel,
    history,
    language,
    loading,
    enableLoading,
    disableLoading,
    pageLoading,
    enablePageLoading,
    disablePageLoading,
    openModalAction,
    openModalMerge,
    openModalCreate,
    selectedItem,
    isAllowed,
    setDataSharing,
    setData,
    onSortModelChange,
    onPageSizeChange,
    onPageChange,
    fetchData,
    onEdit,
    onShare,
    handleClear,
    submitList,
    toggleModalAction,
    toggleModalCreate,
    toggleModalMerge,
    handleCloseModalAction,
    handleCloseModalCreate,
    handleCloseModalMerge,

    // Main
    autoPush,
    setAutoPush,

    // Connections
    connections,
    onAddConnection,
    onEditConnection,
    onDeleteConnection,
    onClearConnection,

    // Parameters
    parameters,
    onAddParameter,
    onEditParameter,
    onDeleteParameter,
    onClearParameter,

    // Station
    stationData,
    setStationData,

    createTabIndex,
    setCreateTabIndex,
  };

  return dataSharingMaster;
};

export default useDataSharingList;
