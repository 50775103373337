import React from "react";
import * as d3 from "d3";

class Chartt extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: props.width, height: props.height };
    this.containerRef = React.createRef();
    this.axisContainerRef = React.createRef();
    this.updateSize = this.updateSize.bind(this);
    this.drawChart = this.drawChart.bind(this);
  }

  componentDidUpdate() {
    const { data, columns, colors, width, height } = this.props;
    if (
      data &&
      this.containerRef.current &&
      this.axisContainerRef.current &&
      width > 0 &&
      height > 0
    ) {
      this.drawChart(data, columns, colors, width, height);
    }
  }

  componentDidMount() {
    const { data, columns, colors } = this.props;
    const { width, height } = this.state;

    window.addEventListener("resize", this.updateSize);
    this.updateSize();

    if (
      data &&
      this.containerRef.current &&
      this.axisContainerRef.current &&
      width > 0 &&
      height > 0
    ) {
      this.drawChart(data, columns, colors, width, height);
    }
    return window.removeEventListener("resize", this.updateSize);
  }

  updateSize() {
    this.setState({
      width: this.axisContainerRef.current.offsetWidth,
      height: this.axisContainerRef.current.offsetHeight,
    });
  }

  drawChart(data, columns, colors, width, height) {
    const svg = d3.select(this.containerRef.current);
    svg.selectAll("*").remove();
    const margin = { top: 40, right: 80, bottom: 40, left: 80 };
    const innerRadius = 0;
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    const outerRadius = Math.min(chartWidth, chartHeight) / 2;
    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const angle = d3.scaleLinear().range([0, 2 * Math.PI]);
    const radius = d3.scaleLinear().range([innerRadius, outerRadius]);
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI])
      .align(0);
    const y = d3
      .scaleLinear() // you can try scaleRadial but it scales differently
      .range([innerRadius, outerRadius]);
    const z = d3.scaleOrdinal().range(colors);

    const circleCover = g.append("g");
    circleCover
      .append("circle")
      .attr("fill", "none")
      .attr("stroke", "#b3b3b3")
      .attr("r", outerRadius + 10);

    const yAxis = g.append("g").attr("text-anchor", "middle");
    const yTick = yAxis
      .selectAll("g")
      .data(y.ticks(5).slice(1))
      .enter()
      .append("g");
    yTick
      .append("circle")
      .attr("fill", "none")
      .attr("stroke", "#b3b3b3")
      .attr("stroke-dasharray", "4,4")
      .attr("r", y);

    x.domain(data.map((d) => d.angle));
    y.domain([
      0,
      d3.max(data, (d) => d.total) > 1 ? d3.max(data, (d) => d.total) : 1,
    ]);
    z.domain(columns.slice(1));
    // Extend the domain slightly to match the range of [0, 2π].
    angle.domain([0, d3.max(data, (d, i) => i + 1)]);
    radius.domain([0, d3.max(data, (d) => d.y0 + d.y)]);
    const angleOffset = -360.0 / data.length / 2.0;

    g.append("g")
      .selectAll("g")
      .data(d3.stack().keys(columns.slice(1))(data))
      .enter()
      .append("g")
      .attr("fill", (d) => z(d.key))
      .selectAll("path")
      .data((d) => d)
      .enter()
      .append("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius((d) => y(d[0]))
          .outerRadius((d) => y(d[1]))
          .startAngle((d) => x(d.data.angle))
          .endAngle((d) => x(d.data.angle) + x.bandwidth())
          .padAngle(0.01)
          .padRadius(innerRadius)
      )
      .attr("transform", () => `rotate(${angleOffset})`);

    const label = g
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        (d) =>
          `rotate(${
            ((x(d.angle) + x.bandwidth() / 2) * 180) / Math.PI -
            (90 - angleOffset)
          })translate(${outerRadius + 30},0)`
      );
    label
      .append("text")
      // eslint-disable-next-line no-confusing-arrow
      .attr("transform", (d) => {
        const radian =
          ((x(d.angle) + x.bandwidth() / 2) * 180) / Math.PI -
          (90 - angleOffset);
        return `rotate(${-radian})translate(0,4)`;
      })
      .text((d) => d.angle)
      .attr("font-weight", "600")
      .attr("fill", "#4d4d4d")
      .style("font-size", 14);

    g.selectAll(".axis")
      .data(d3.range(angle.domain()[1]))
      .enter()
      .append("g")
      .attr("class", "axis")
      .attr("transform", (d) => `rotate(${(angle(d) * 180) / Math.PI})`)
      .call(
        d3
          .axisLeft()
          .scale(radius.copy().range([-innerRadius, -(outerRadius + 10)]))
      );

    if (this.props.displayLegend) {
      const legend = g
        .append("g")
        .selectAll("g")
        .data(columns.slice(1).reverse())
        .enter()
        .append("g")
        .attr(
          "transform",
          (d, i) =>
            `translate(${outerRadius + 100},${
              -outerRadius + 200 + (i - (columns.length - 1) / 2) * 20
            })`
        );
      legend
        .append("rect")
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", z);
      legend
        .append("text")
        .data([0])
        .attr("x", 24)
        .attr("y", 9)
        .attr("dx", -24)
        .attr("dy", -48)
        .text(this.props.legend)
        .style("font-size", 18)
        .style("font-weight", "bold");
      legend
        .append("text")
        .data([0])
        .attr("x", 24)
        .attr("y", 9)
        .attr("dx", -24)
        .attr("dy", -24)
        .text(`(${this.props.unit})`)
        .style("font-size", 15)
        .style("font-weight", "bold");
      legend
        .append("text")
        .attr("x", 24)
        .attr("y", 9)
        .attr("dy", "0.35em")
        .text((d) => d)
        .style("font-size", 14);
    }
    g.exit().remove();
  }

  render() {
    return (
      <div
        ref={this.axisContainerRef}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          margin: "0 auto",
        }}
      >
        <svg
          className="axis"
          viewBox={`0 0 ${this.props.displayLegend ? 780 : 500} 500`}
          ref={this.containerRef}
          style={{ margin: "auto", color: "#b3b3b3" }}
        />
      </div>
    );
  }
}

export default Chartt;
