export const isValidLayerFile = (item) => {
  return !!item.data_file_name && !!item.layer_label_en;
}

export const acceptedFilesToString = (values) => {
  if (values === "*")
    return "";
  return values?.join(", ") || "";
}

export const getUnitNameId = (data) => {
  const sortedData = data?.map(item => {
    const nameEnId = item["layer_label_en"]?.split(" ")[1] ? Number(item["layer_label_en"]?.split(" ")[item["layer_label_en"]?.split(" ")?.length - 1]) : 0;
    return nameEnId;
  })?.sort((a, b) => a - b)
  return sortedData[sortedData?.length - 1];
}