import { IconButton } from "@material-ui/core";
import { MyLocation } from "@material-ui/icons";
import useDetectDevice from "app/shared/hooks/useDetectDevice";
import PropTypes from "prop-types";
import SwitchSelector from "react-switch-selector";

export const MapAction = (props) => {
  const { onLocateMyLocation, onZoom, onChangeLayer, intl } = props;
  const { deviceType } = useDetectDevice();
  const onChangeType = (newValue) => {
    if (onChangeLayer) {
      onChangeLayer(newValue);
    }
  };

  const handleZoom = (option) => {
    if (onZoom) {
      onZoom(option);
    }
  };

  return (
    <div className="reeco-map-action">
      <div className="action-item">
        <IconButton className="action-buttonicon" onClick={onLocateMyLocation}>
          <MyLocation />
        </IconButton>
      </div>

      <div className="action-item zoom-buttons">
        <button
          onClick={() => handleZoom("In")}
          className="item-button item-button-zoom-in"
        >
          +
        </button>

        <button
          onClick={() => handleZoom("Out")}
          className="item-button item-button-zoom-out"
        >
          −
        </button>
      </div>

      <div className="action-item sw-selector-200">
        <SwitchSelector
          name="layerType"
          backgroundColor="#111111a6"
          selectedBackgroundColor="#3699FF"
          fontColor="#fff"
          selectionIndicatorMargin={0}
          onChange={onChangeType}
          fontSize={12}
          options={[
            {
              label: intl.formatMessage({ id: "CLIENT.MAP.DEFAULT_STYLE" }),
              value: 1,
            },
            {
              label: intl.formatMessage({ id: "CLIENT.MAP.SATELLITE_STYLE" }),
              value: 2,
            },
          ]}
        />
      </div>
    </div>
  );
};

MapAction.propTypes = {
  onLocateMyLocation: PropTypes.func,
  onZoom: PropTypes.func,
  on3DClick: PropTypes.func,
  onChangeLayer: PropTypes.func,
};
