/* eslint-disable react-hooks/exhaustive-deps */
import { DATE_FORMAT } from "app/constant/date-time-format";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ModalContainer from "../../common/ModalContainer";
import { HISTORICAL_DATA, PREDICTION_DATA } from "../contants";
import { prepareHistoricTableOutput } from "../helpers";
import { HISTORIC_TABLE_INITIALVALUES } from "../initialValues";
import { HistoricTableFormSchema } from "../schemas";
import useModal from "../useModal";
import HistoricTableForm from "./HistoricTableForm";
import "./styles.css";

function MenuModal(props) {
  const { intl, open, handleClose } = props;
  const [disableSave , setDisableSave] = React.useState(false);

  const { openModal, setOpenModal, toggleOpen } = useModal({
    handleClose,
    modalOpen: open,
  });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || HISTORIC_TABLE_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || HISTORIC_TABLE_INITIALVALUES.name_vi,
      header_color:
        props.data?.header_color || HISTORIC_TABLE_INITIALVALUES.header_color,
      show_header:
        props.data?.show_header === undefined
          ? HISTORIC_TABLE_INITIALVALUES.show_header
          : props.data.show_header,
      params:
        props.data?.dashboard_historic_table
          ?.dashboard_historic_table_parameter?.sort((a ,b ) => a.order_number - b.order_number) || [],
      params_delete: [],
      data_mode: [HISTORICAL_DATA, PREDICTION_DATA].includes(props.data?.data_mode) ? props.data?.data_mode: HISTORIC_TABLE_INITIALVALUES.data_mode,
      historical_time_range:
        props.data?.time_range ||
        HISTORIC_TABLE_INITIALVALUES.historical_time_range,
      historical_time_range_type:
        props.data?.time_range_type ||
        HISTORIC_TABLE_INITIALVALUES.historical_time_range_type,
      prediction_time_range:
        props.data?.time_point ||
        HISTORIC_TABLE_INITIALVALUES.prediction_time_range,
      prediction_time_range_type:
        props.data?.time_point_type ||
        HISTORIC_TABLE_INITIALVALUES.prediction_time_range_type,
      display_mode:
        props?.data?.dashboard_historic_table?.display_mode || HISTORIC_TABLE_INITIALVALUES.display_mode,
      historical_start_time: props.data?.historical_start_time ?
        moment(props.data?.historical_start_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      historical_end_time: props.data?.historical_end_time ?
        moment(props.data?.historical_end_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      aggregate_function: props.data?.dashboard_historic_table?.aggregate_function || HISTORIC_TABLE_INITIALVALUES.aggregate_function,
      interval: props.data?.dashboard_historic_table?.interval || HISTORIC_TABLE_INITIALVALUES.interval,
    },
    enableReinitialize: true,
    validationSchema: HistoricTableFormSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;

      props["handleEdit"](id, prepareHistoricTableOutput(props?.data, values));
      props["handleClose"]();
    },
  });
  
  React.useEffect(() => {
    setOpenModal(props["open"]);
    return () => {
      formik.resetForm();
    };
  }, [props["open"]]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      disabledSave={disableSave}
      open={openModal}
      toggleOpen={toggleOpen}
      newText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_HISTORIC_TABLE" />
      }
      editText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_HISTORIC_TABLE" />
      }
    >
      <HistoricTableForm
        setDisableSave={setDisableSave}
        formik={formik}
        intl={intl}
        parameters={props?.parameters || []}
      />
    </ModalContainer>
  );
}

export default injectIntl(MenuModal);
