import "./index.css";
import CloseIcon from "@material-ui/icons/Close";

const SubChatUserIcon = (props) => {
  const {
    items,
    onUnHide,
    onClose,
    roomsNoti
  } = props;

  const handleClose = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    onClose && onClose(id)
  }

  return (
    <div className="sub-chat-user-icon">
      <div className="wrapper">
        <img src="/static/media/user-avatar.8c3ca8b3.jpg" />
        <div className="overlay">
          +{ items.length }
        </div>
      </div>
      <div className="list">
        <div className="wrapper">
          { items?.map(item =>
            <div className="item" key={item.id} onClick={() => onUnHide && onUnHide(item.id, item.userId, item.username, roomsNoti.find(elm => elm.roomChatId === item.roomChatId))}>
              <span>{item.username}</span>
              <span onClick={(e) => handleClose(e, item.id)}>
                <CloseIcon />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubChatUserIcon;