export const organizationTypes = [
  {
    label: "CLIENT.SIGN_UP.PREMIUM_ACCOUNT",
    value: "PREMIUM_ACCOUNT",
  },
  {
    label: "CLIENT.SIGN_UP.ORGANIZATION_PARTNER",
    value: "ORGANIZATION_PARTNER",
  },
];

export const accountTypes = [
  {
    id: 1,
    label: "CLIENT.SIGN_UP.NO",
    subLabel: "CLIENT.SIGN_UP.PUBLIC_ACCOUNT",
    value: "public",
  },
  {
    id: 2,
    label: "CLIENT.SIGN_UP.YES",
    subLabel: "CLIENT.SIGN_UP.PARTNER_MEMBER",
    value: "member",
  },
];

export const channelTypes = [
  {
    id: 1,
    subLabel: "CLIENT.SIGN_UP.EMAIL_LABEL",
    value: "email",
  },
  {
    id: 2,
    subLabel: "CLIENT.SIGN_UP.PHONE_NUMBER_LABEL",
    value: "phone",
  },
];
