import axiosClient from "./axiosClient";
import queryString from "query-string";

const workspaceDashboard = {
  createDashboard(data) {
    const url = "/api/v1/dashboard/";
    return axiosClient.post(url, data, {});
  },
  deleteDashboard(id) {
    const url = `/api/v1/dashboard/workspace/${id}`;
    return axiosClient.delete(url, {});
  },
  updateDashboard(id, data) {
    const url = `/api/v1/dashboard/${id}`;
    return axiosClient.put(url, data, {});
  },
  getDashboard(dashboardId) {
    const url = `/api/v1/dashboard/${dashboardId}`;
    return axiosClient.get(url, {});
  },
  getAllDashboard(workspaceId) {
    const url = `/api/v2/workspaces/${workspaceId}/dashboards`;
    return axiosClient.get(url, {});
  },
  getAllDashboardType() {
    const url = "/api/v1/dashboard/type";
    return axiosClient.get(url, {});
  },
  createItem(type, workspaceId) {
    const url = `/api/v1/dashboard/${type}/${workspaceId}`;
    return axiosClient.post(url, {}, {});
  },
  updatePosition(data) {
    const url = `/api/v2/dashboards/${data.idView}/layouts`;
    return axiosClient.put(url, data, {});
  },
  updateItem(type, id, data) {
    const url = `/api/v1/dashboard/${type}/${id}`;
    return axiosClient.put(url, data, {});
  },
  updateFormItem(type, id, data) {
    const url = `/api/v1/dashboard/${type}/${id}`;
    return axiosClient.put(url, data, {});
  },
  deleteItem(id) {
    const url = `/api/v1/dashboard/${id}`;
    return axiosClient.delete(url, {});
  },
  getGeneral(id) {
    const url = `/api/v1/dashboard/general/${id}`;
    return axiosClient.get(url, {});
  },
  updateGeneral(id, data) {
    const url = `/api/v1/dashboard/general/${id}`;
    return axiosClient.put(url, data, {});
  },
  getPublicDashboard(id, password) {
    const url = `/api/v2/workspaces/${id}/public-dashboards?password=${password}`;
    return axiosClient.get(url, {});
  },
  getDashboardData(dashboardId, token, params) {
    const queryParams = queryString.stringify(params);
    const url = `/api/v1/dashboard/get-data-ari/${dashboardId}?${queryParams}`;
    return axiosClient.get(url);
  },
  getPublicDashboardData(dashboardId, params) {
    const queryParams = queryString.stringify(params);
    const url = `/api/v1/dashboard/get-data-ari-public/${dashboardId}?${queryParams}`;
    return axiosClient.get(url);
  },
  getDashboardDataWithDate(dashboardId, startDate, endDate) {
    const url = `/api/v1/dashboard/get-data-app-by-timerange/${dashboardId}?startDate=${startDate}&endDate=${endDate}`;
    return axiosClient.get(url, {});
  },
  getDashboardPublicDataWithDate(dashboardId, startDate, endDate) {
    const url = `/api/v1/dashboard/get-data-app-by-timerange-public/${dashboardId}?startDate=${startDate}&endDate=${endDate}`;
    return axiosClient.get(url, {});
  },
  getLatestAriData(id, data, token) {
    const url = `/api/v1/dashboard/get-latest-ari-data/${id}`;
    return axiosClient.post(url, data, {});
  },
  getLatestAriDataPublic(id, data) {
    const url = `/api/v1/dashboard/get-latest-ari-data-public/${id}`;
    return axiosClient.post(url, data, {});
  },
  getAllDashboardView() {
    const url = `/api/v2/dashboard-views`;
    return axiosClient.get(url);
  },
};

export default workspaceDashboard;
