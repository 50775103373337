import { isEmpty } from "lodash";

export const isFeatureEnabled = (featureId) => {
  const envKey = `REACT_APP_FEATURE_${featureId}_ENABLED`;
  let featureEnabled = process.env?.[envKey];

  if (isEmpty(featureEnabled)) {
    featureEnabled = true;
  } else {
    featureEnabled = featureEnabled?.toLowerCase()?.trim() === "true";
  }

  return featureEnabled;
};
