/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, memo } from "react";

import LanguageSwitcher from "../../shared/components/LanguageSwitcher";
import Footer from "../../shared/layouts/AuthLayout/Footer";
import DashboardCard from "./components/DashboardCard";

import GridLayout from "react-grid-layout";

import { Button, Container } from "@material-ui/core";
import workspaceDashboardApi from "../../api/workspaceDashboardApi";
import "./styles.css";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ReactResizeDetector from "react-resize-detector";
import socketIOClient from "socket.io-client";

import PageLoading from "app/shared/components/Loader/PageLoading";
import { useQuery } from "app/shared/hooks";
import _ from "lodash";
import { useIntl } from "react-intl";
import { decryptKey } from "../../utils/crypto";
import ModalPassword from "./components/Modals/ModalPassword";
import { columns, rowHeight } from "./constant";
import { getDashboardPassword, storeDashboardPassword } from "./helper";
import useDashboard from "./hooks/useDashboard";

const MemoizedDashboardCard = memo(DashboardCard, (prevProps, nextProps) => {
  return (
    prevProps.item.x === nextProps.item.x &&
    prevProps.item.y === nextProps.item.y &&
    prevProps.item.w === nextProps.item.w &&
    prevProps.item.h === nextProps.item.h &&
    prevProps.loading === nextProps.loading &&
    JSON.stringify(prevProps?.data) === JSON.stringify(nextProps?.data) &&
    JSON.stringify(prevProps?.realTimeData) ===
      JSON.stringify(nextProps?.realTimeData)
  );
});

const DashboardPublic = (props) => {
  const intl = useIntl();
  const {
    openPasswordModal,
    history,
    publicSocketRef,
    socketRef,
    items,
    data,
    realTimeData,
    realTimeDataAlarm,
    pageLoading,
    itemsLoading,
    onPressCtrlKey,
    isFullScreen,
    setRealTimeDataAlarm,
    setRealTimeData,
    allowDragging,
    controlKeyHandler,
    getAllPublicDashboard,
    toggleFullScreen,
    handleCloseModalPassword,
  } = useDashboard({ intl, workspaceDashboardApi });

  const _DASHBOARD_KEY = "key";
  const _PASSWORD_KEY = "p";
  const query = useQuery();

  const renderChildrenCard = () => {
    return _.map(items, (item) => (
      <div
        className="dashboard-card"
        key={item.id.toString()}
        data-grid={{
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          minH: item.minH,
        }}
      >
        <DashboardCard
          // item={item}
          type={item["type"]}
          data={item["data"]}
          loading={itemsLoading[item.id]}
          key={item["id"]}
          dragging={onPressCtrlKey}
          id={item["id"]}
          realTimeData={realTimeData}
          realTimeDataAlarm={realTimeDataAlarm}
          isPublic={true}
        />
      </div>
    ));
  };

  const queryPublicDashboard = (key, password) => {
    handleCloseModalPassword();
    if (key) {
      const dashboardId = decryptKey(key);
      if (dashboardId) {
        getAllPublicDashboard(key, password);
      } else {
        history.replace("/not-found");
      }
    }
  };

  const handleChange = (values) => {
    const password = values?.password;
    const key = query.get(_DASHBOARD_KEY);

    storeDashboardPassword(key, password);

    queryPublicDashboard(key, password);
  };

  useEffect(() => {
    const key = query.get(_DASHBOARD_KEY);
    const password = query.get(_PASSWORD_KEY) || getDashboardPassword(key);
    queryPublicDashboard(key, password);
  }, []);

  useEffect(() => {
    socketRef.current = socketIOClient.io(
      `${process.env.REACT_APP_API_URL}/dashboard-public`
    );
    publicSocketRef.current = socketIOClient.io(
      `${process.env.REACT_APP_API_URL}/dashboard-alarm-public`
    );
    return () => {
      socketRef.current.disconnect();
      publicSocketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const socketData = {
      room: data.workspace_id,
    };
    socketRef.current?.emit("join", socketData);
    publicSocketRef.current?.emit("join", socketData);
  }, [data.workspace_id]);

  useEffect(() => {
    socketRef.current?.on("ari-realtime-data", (resp) => {
      setRealTimeData(resp);
    });
    publicSocketRef.current?.on("ari-realtime-data", (resp) => {
      setRealTimeDataAlarm(resp);
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", controlKeyHandler);
    document.addEventListener("keyup", allowDragging);
    return () => {
      document.removeEventListener("keydown", controlKeyHandler);
      document.removeEventListener("keyup", allowDragging);
    };
  }, []);
  const showHeaderFooter = (show_footer) => {
    if (show_footer) {
      return "public";
    } else {
      return "public-no-header-footer public";
    }
  };

  return (
    <div className={showHeaderFooter(data?.show_footer)} id="dashboard-public">
      {data?.show_footer && (
        <div className={`main-header`}>
          <Container maxWidth={false} className="container">
            <div className="logo">
              {data?.show_logo && <img src={data?.logo_url} alt="logo" />}
            </div>
            <div className="actions">
              <LanguageSwitcher />
              <Button
                style={{ color: "gray" }}
                onClick={() => {
                  toggleFullScreen(document.getElementById("dashboard-public"));
                }}
              >
                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
            </div>
          </Container>
        </div>
      )}

      <PageLoading loading={pageLoading}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          refreshRate="500"
          refreshMode="debounce"
        >
          {({ width, height }) => (
            <GridLayout
              cols={columns}
              rowHeight={rowHeight}
              width={width}
              isResizable={false}
              isDraggable={false}
            >
              {renderChildrenCard()}
            </GridLayout>
          )}
        </ReactResizeDetector>
      </PageLoading>
      {data?.show_footer && <Footer />}
      <ModalPassword
        open={openPasswordModal}
        handleClose={handleCloseModalPassword}
        handleChange={handleChange}
      />
    </div>
  );
};

export default DashboardPublic;
