import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ColorPicker from '../../ColorPicker';
import { Grid, Box, Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage, injectIntl } from 'react-intl';
import SharedInputField from '../../SharedInputField';
import { useLanguage } from '../../../../shared/hooks';
import './styles.scss';

const styles = {
  label: {
    fontWeight: 'bold',
    display: 'block',
    marginBottom: 0,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  footer: {
    marginTop: 24,
    marginBottom: 32,
  },
  button: {
    color: '#B6B6B6',
    cursor: 'pointer',
    marginRight: 12,
  },
};

const defaultRanges = [
  {
    id: "",
    row_id: uuidv4(),
    threshold: 0,
    color: "#2D46B9",
    editing: false,
    min: 0,
    max: Infinity
  },
  {
    id: "",
    row_id: uuidv4(),
    threshold: Infinity * -1,
    min: Infinity * -1,
    max: 0,
    color: "#1E3163",
    editing: false,
  },
];

const DashboardValueList = (props) => {
  const { intl } = props;
  const [language] = useLanguage();

  const [thresholds, setThresholds] = React.useState([]);
  const [tempThresholds, setTempThresholds] = React.useState([]);

  const handleChange = (id, name, value) => {
    const newData = tempThresholds.map((item, idx) => {
      if (item.row_id === id) {
        let errorMsg = "";
        if (value === "" || value === undefined) {
          errorMsg = intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          });
        }
        const newItem = {
          ...item,
          [name]: value,
          error: errorMsg,
        };
        return newItem;
      }
      return item;
    });
    setTempThresholds(newData);
  };

  const randomColor = () => {
    return '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
  }

  const addNew = (e) => {
    const newThreshold = {
      id: "",
      row_id: uuidv4(),
      threshold: Math.ceil(Number(tempThresholds[0].min)) + 1,
      min: Math.ceil(Number(tempThresholds[0].min) + 1),
      max: Infinity,
      color: randomColor(),
      editing: false,
    };
    let newData = [newThreshold, ...tempThresholds]
    newData = newData.sort((a, b) => {
      if (Number(a.min) === Number(b.min)) return Number(b.max) - Number(a.max);
      return Number(b.min) - Number(a.min);
    }).map((item, index) => {
      if (index === 0) {
        return {
          ...item,
          max: Infinity
        }
      }

      return {
        ...item,
        max: Number(newData[index - 1].min)
      }
    })
    props.onChange(newData);
  };

  const toggleEdit = (id) => {
    const newData = thresholds.map(item => {
      if (item.row_id === id) {
        return {
          ...item,
          editing: true
        }
      }
      return item;
    });
    props.onChange(newData);
  };

  const onApply = (id) => {

    var valid = true;
    var newData = tempThresholds.map(item => {
      if (item.row_id === id) {
        if (item.error) {
          valid = false;
        }

        return {
          ...item,
          editing: false
        }
      }
      return item;
    });
    newData = newData.sort((a, b) => {
      if (Number(a.min) === Number(b.min)) return Number(b.max) - Number(a.max);
      return Number(b.min) - Number(a.min);
    }).map((item, index) => {
      if (index === 0) {
        return {
          ...item,
          max: Infinity
        }
      }

      return {
        ...item,
        max: Number(newData[index - 1].min)
      }
    })
    if (valid) {
      props.onChange(newData);
    }
  }

  const deleteItem = (id) => {
    var newData = tempThresholds.filter(item => item.row_id !== id)
      .sort((a, b) => {
        if (Number(a.min) === Number(b.min)) return Number(b.max) - Number(a.max);
        return Number(b.min) - Number(a.min);
      });

    newData = newData.map((item, index) => {
      if (index === 0) {
        return {
          ...item,
          max: Infinity
        }
      }
      if (index === newData.length - 1) {
        return {
          ...item,
          min: -Infinity,
          threshold: -Infinity,
          max: newData[index - 1].min
        }
      }

      return {
        ...item,
        max: newData[index - 1].min
      }
    });
    // setThresholds(newData);
    props.onChange(newData)
  };

  const displayNumber = (number) => {
    return number < 0 ? `(${number})` : number
  }

  const renderDescription = (item) => {

    if (language === "en") {

      if (item.max == Infinity)
        return (<span>Range will include values greater than <b>{displayNumber(item.min)}</b></span>);

      if (item.min == - Infinity)
        return (<span>Range will include values less than <b>{displayNumber(item.max)}</b></span>);

      return (<span>Range will include values between <b>{displayNumber(item.min)}</b> and <b>{displayNumber(item.max)}</b></span>);
    }

    if (item.max == Infinity)
      return (<span>Khoảng sẽ bao gồm các giá trị lớn hơn <b>{displayNumber(item.min)}</b></span>);

    if (item.min == - Infinity)
      return (<span>Khoảng sẽ bao gồm các giá trị nhỏ hơn <b>{displayNumber(item.max)}</b></span>);

    return (<span>Khoảng sẽ bao gồm các giá trị từ <b>{displayNumber(item.min)}</b> đến <b>{displayNumber(item.max)}</b></span>);

  }

  const renderTitle = (item) => {
    if (item.max == Infinity)
      return `> ${displayNumber(item?.min)}`;

    if (item.min == - Infinity)
      return `< ${displayNumber(item?.max)}`;

    return `${displayNumber(item?.min)} - ${displayNumber(item?.max)}`
  }

  React.useEffect(() => {
    if (props.data && props.data.length) {
      setThresholds(props.data.map(item => ({
        ...item,
        row_id: item.row_id || uuidv4(),
        threshold: item.min,
      })));
    }
    else {
      props.onChange(defaultRanges);
    }
  }, [props['data']]);

  React.useEffect(() => {
    const newValue = thresholds.map(item => {
      if (tempThresholds.some(temp =>
        temp.row_id === item.row_id
        && item.editing
        && temp.editing)) {
        return tempThresholds.find(x => x.row_id === item.row_id);
      }
      return item;
    })
    setTempThresholds(newValue);
  }, [thresholds]);

  return (
    <div className="ranges-list-editor mt-6">
      {!props.viewOnly && (
        <Button
          onClick={addNew}
          className="custom-button light-button"
        >
          <FormattedMessage id='CLIENT.DASHBOARD.CUSTOM_CHART.NEW_RANGE' />
        </Button>
      )}
      {tempThresholds.map((item, index) => (<div className="range-item" key={item.row_id}>
        <div className="item-title">
          <div className="item-color" style={{ backgroundColor: item.color }}></div>
          <span><b>{renderTitle(item)}</b></span>
        </div>
        <div className="item-description">
          {renderDescription(item)}
        </div>
        <div className="item-menu">
          {item.editing ? (
            <span onClick={() => onApply(item.row_id)} style={{ color: '#008a00' }}>
              <CheckIcon />
            </span>
          ) : (
            <>
              <span onClick={() => toggleEdit(item.row_id)}>
                <EditIcon />
              </span>
              {thresholds?.length > 2 && (
                <span onClick={() => deleteItem(item.row_id)}>
                  <DeleteIcon />
                </span>
              )}
            </>
          )}
        </div>
        <div className={`item-content-edit ${item.editing ? "editing" : ""} `}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <SharedInputField
                name="min"
                label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.THRESHOLD' })}
                type="number"
                disabled={Number(item.min) == Infinity * -1}
                required={Number(item.min) != Infinity * -1}
                className={`bg-white ${Number(item.min) == - Infinity ? 'disabled-field' : ''}`}
                value={item.min}
                placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.THRESHOLD_HINT' })}
                onChange={(e) => {
                  handleChange(item.row_id, "min", e.target.value)
                }}
                errorMsg={item.error}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    onApply(item.row_id);
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <label style={styles.label}>
                <span className="required">*</span> {intl.formatMessage({
                  id: 'CLIENT.DASHBOARD.COLOR_LABEL',
                })}
              </label>
              <Box style={{ marginTop: '13px' }}>
                <ColorPicker
                  color={item.color}
                  onChange={(colorValue) =>
                    handleChange(item.row_id, "color", colorValue)
                  }
                />
              </Box>
            </Grid>
          </Grid>

        </div>
      </div>))}
    </div>
  );
};

export default injectIntl(DashboardValueList);
