import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import "./index.css";

const Footer = (props) => {
  return (
    <div className="footer" style={{ marginTop: "auto" }}>
      <Container maxWidth={false} className="container">
        {/* <ul className="nav">
          {
            NAV.map(item =>
              <li key={item.id}>
                <Link to={item.link}>
                  { item.name }
                </Link>
              </li>  
            )
          }
        </ul> */}

        {/* <div className="copyright">
          <span>2021</span>&copy;<Link to="/">Reecotech</Link>
        </div> */}
      </Container>
    </div>
  );
};

export default Footer;
