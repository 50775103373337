import { useState, useRef, useEffect } from "react";
import { useLanguage, useLoading } from "../../../shared/hooks";
import { useHistory } from "react-router-dom";
import Papa from "papaparse";
import { urlToPromise } from "../helper";
import FileSaver from "file-saver";
import JSZip from "jszip";
import swal from "sweetalert";
import { useQuery } from "app/shared/hooks";
import queryString from "query-string";
import _ from "lodash";

const zip = new JSZip();

const useDataFile = ({ intl, dataFileApi }) => {
  const [loading, setLoading] = useState(false);
  const fileInputEl = useRef(null);
  const [files, setFiles] = useState(null);
  const [filesError, setFilesError] = useState("");
  const [data, setData] = useState({
    users: [],
    status: [],
    dataSharingRequests: [],
  });
  const [templateFile, setTemplateFile] = useState([]);
  const [postFiles, setPostFiles] = useState({});
  const [language] = useLanguage();

  const [pageLoading, enablePageLoading, disablePageLoading] = useLoading();
  const [mainData, setMainData] = useState({});
  const history = useHistory();
  const [backUrl, setBackUrl] = useState("");
  const query = useQuery();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const onChangeFilesAdd = (e) => {
    let newFiles = !files ? {} : { ...files };
    let _files = !postFiles ? {} : { ...postFiles };
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = new File([e.target.files[i]], e.target.files[i].name, {
          type: e.target.files[i].type,
        });
        let src = URL.createObjectURL(file);
        file.src = src;
        file.isNew = true;
        newFiles[Object.keys(newFiles).length + i] = file;
        _files[Object.keys(_files).length + i] = file;
      }
    }
    setFiles(newFiles);
    setPostFiles(_files);
  };

  const removeFileAdd = (index) => {
    if (Object.keys(files).length > 0) {
      const newFiles = { ...files };
      const newPostFiles = { ...postFiles };
      delete newFiles[index];
      delete newPostFiles[index];

      setFiles(newFiles);
      setPostFiles(newPostFiles);
    } else {
      setFiles(null);
      setPostFiles({});
    }
  };

  const onChangeFilesEdit = (e) => {
    let newFiles = !files ? {} : { ...files };
    let _files = !postFiles ? {} : { ...postFiles };
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = new File([e.target.files[i]], e.target.files[i].name, {
          type: e.target.files[i].type,
        });
        let src = URL.createObjectURL(file);
        file.src = src;
        file.isNew = true;
        newFiles[new Date().valueOf() + i] = file;
        _files[new Date().valueOf() + i] = file;
      }
    }
    setFiles(newFiles);
    setPostFiles(_files);
  };

  const removeFileEdit = (index) => {
    if (Object.keys(files).length > 1) {
      const newFiles = { ...files };
      const newPostFiles = { ...postFiles };
      delete newFiles[index];
      delete newPostFiles[index];

      setFiles(newFiles);
      setPostFiles(newPostFiles);
    } else {
      setFiles(null);
      setPostFiles({});
    }
  };

  const validateFiles = (files) => {
    if (!dataFileMaster?.templateFile.length) return true;
    for (let file of files) {
      const arr = dataFileMaster?.templateFile[1].filter((item) =>
        file?.[1]?.includes(item)
      );
      if (arr?.length !== dataFileMaster?.templateFile[1]?.length) return false;
    }
    return true;
  };

  const parseFilesAdd = () => {
    const filesData = [];
    const tempFiles = Object.values(files);

    return Promise.all(
      tempFiles.map(
        (file) =>
          new Promise((resolve, reject) =>
            Papa.parse(file, {
              download: true,
              skipEmptyLines: true,
              complete: resolve, // Resolve each promise
              error: reject,
            })
          )
      )
    )
      .then((results) => {
        results.forEach((result) => {
          filesData.push(result?.data);
        });
        return validateFiles(filesData);
      })
      .catch((err) => console.log("Something went wrong:", err));
  };

  const parseFilesEdit = () => {
    const filesData = [];
    // for(let file of mainData?.data_upload) {
    //   delete _files[file.id]
    // }
    const tempFiles = Object.values(files);
    return Promise.all(
      tempFiles.map(
        (file) =>
          new Promise((resolve, reject) =>
            Papa.parse(!file?.isNew ? file?.src : file, {
              download: true,
              skipEmptyLines: true,
              complete: resolve, // Resolve each promise
              error: reject,
            })
          )
      )
    )
      .then((results) => {
        results.forEach((result) => {
          filesData.push(result?.data);
        });
        return validateFiles(filesData);
      })
      .catch((err) => console.log("Something went wrong:", err));
  };

  const handleDownloadAll = () => {
    var filesFolder = zip.folder("files");
    for (let item of Object.values(dataFileMaster?.files)) {
      filesFolder.file(item?.name, urlToPromise(item.src), { binary: true });
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, "files.zip");
    });
    zip.remove("files");
  };

  const onPush = async (id) => {
    try {
      const resp = await dataFileApi.pushDataFile(id);

      if (resp.success) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_FILES.PUSH_FILE_SUCCESS_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_FILES.PUSH_FILE_SUCCESS_MESSAGE",
          }),
          icon: "success",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DATA_FILES.PUSH_FILE_FAILED_TITLE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.DATA_FILES.PUSH_FILE_FAILED_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    }
  };

  useEffect(() => {
    const params = {};
    let url = "/data-files";

    if (query.get("ppi") && +query.get("ppi") > 0) {
      params["ppi"] = +query.get("ppi");
    }

    if (!_.isEmpty(params)) {
      url += `?${queryString.stringify(params)}`;
    }

    setBackUrl(url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataFileMaster = {
    loading,
    fileInputEl,
    files,
    filesError,
    data,
    templateFile,
    postFiles,
    language,
    pageLoading,
    mainData,
    history,
    enablePageLoading,
    disablePageLoading,
    backUrl,

    setLoading,
    setFiles,
    setFilesError,
    setData,
    setTemplateFile,
    setPostFiles,
    enableLoading,
    disableLoading,
    removeFileAdd,
    onChangeFilesAdd,
    setMainData,
    onChangeFilesEdit,
    removeFileEdit,
    parseFilesEdit,
    parseFilesAdd,
    validateFiles,
    handleDownloadAll,
    onPush,
  };

  return dataFileMaster;
};

export default useDataFile;
