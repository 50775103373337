import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M8.904 17.822a8.329 8.329 0 017.39-4.489 8.322 8.322 0 018.166 6.711c2.889.2 5.167 2.578 5.167 5.512a5.558 5.558 0 01-5.556 5.555H9.627a6.672 6.672 0 01-6.667-6.667 6.66 6.66 0 015.944-6.622zm13.378 2.656a6.115 6.115 0 00-5.989-4.922 6.076 6.076 0 00-5.41 3.3l-.556 1.055-1.19.122a4.415 4.415 0 00-3.955 4.411 4.443 4.443 0 004.445 4.445H24.07c1.833 0 3.333-1.5 3.333-3.333a3.311 3.311 0 00-3.088-3.29l-1.7-.122-.334-1.666z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M24.293 8a8.322 8.322 0 018.167 6.711c2.889.2 5.167 2.578 5.167 5.511a5.558 5.558 0 01-5.556 5.556c.356-.711.148-1.778 0-2.222 1.833 0 3.333-1.5 3.333-3.334a3.311 3.311 0 00-3.089-3.289l-1.7-.122-.333-1.667a6.115 6.115 0 00-5.989-4.922A6.058 6.058 0 0019.995 12c-.239-.391-1.678-.602-2.368-.659A8.315 8.315 0 0124.293 8z"
      ></path>
    </svg>
  );
}

export default Icon;
