import React from 'react';
import PermissionsContext from './PermissionsContext';
import { shallowEqual, useSelector } from "react-redux";

export default function PermissionProvider(props) {
    const { permissions } = useSelector(
      ({ user }) => ({
          permissions: user.role && user.role.permission_sys
      }),
      shallowEqual
  );

  return (
    <PermissionsContext.Provider value={permissions}>
      {props.children}
    </PermissionsContext.Provider>
  );
}