import React from "react";
import { injectIntl } from "react-intl";
import AuthLayout from "../../../shared/layouts/AuthLayout";
import { Box, Container } from "@material-ui/core";
import { logIn } from "../../../redux/actions";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import { useLogin } from "./hooks";

function Login(props) {
  const { intl } = props;
  const pageData = useLogin(props);
  const { query, renderForm } = pageData;

  return (
    <AuthLayout
      title={intl.formatMessage({ id: "CLIENT.LOGIN.PAGE_TITLE" })}
      description={intl.formatMessage({ id: "CLIENT.LOGIN.PAGE_SUBTITLE" })}
    >
      <div className="form login-form">
        <Container className="small-container">
          {query.message && (
            <Box mb={3}>
              <Alert severity="success" color="info">
                {query.message}
              </Alert>
            </Box>
          )}

          {renderForm()}
        </Container>
      </div>
    </AuthLayout>
  );
}

export default injectIntl(connect(null, { logIn })(Login));
