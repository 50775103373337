import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import './styles.css';
import { FormattedMessage } from 'react-intl';

const IndexTypeDropdown = (props) => {
  const { data, disabled } = props; 
  const wrapperRef = React.useRef(null);
  const [showList, setShowList] = React.useState(false);
  const [openStates, setOpenStates] = React.useState({});
  const [selectedItem, setSelectedItem] = React.useState('');

  const handleOpen = (stationId) => {
    const newStates = { ...openStates };

    newStates[stationId] = !newStates[stationId];

    setOpenStates(newStates);
  };

  const toggleList = () => {
    setShowList(!showList);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef && !wrapperRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  const handleSelect = (item, index, value1, value2) => {
    const newItem = `${index} >> ${item}`;

    setSelectedItem(newItem);
    setOpenStates({});

    if (props['onChange']) {
      props['onChange']({
        label: newItem,
        value: `${value1}-${value2}`
      });
    }
    toggleList();
  };

  React.useEffect(() => {
    setSelectedItem(props['value'] || '');
  }, [props['value']]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  return (
    <div className={`index-types-dropdown ${disabled ? "index-types-dropdown-disabled" : ""}`} ref={wrapperRef}>
      <div className="index-types-dropdown-value" onClick={toggleList}>
        {selectedItem !== '' ? (
          <span className="index-types-dropdown-text">{selectedItem}</span>
        ) : (
          <span className="index-types-dropdown-text" style={{ color: 'gray' }}>
            <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDICATOR_HINT" />
          </span>
        )}

        <span className="index-types-dropdown-icon">
          <ArrowDropDownIcon />
        </span>
      </div>

      {showList && (
        <div className="index-types-dropdown-list">
          {data && data.length > 0 ? 
          data.map(indexType => (
            <div key={indexType.value}>
              <div
                className="index-types-dropdown-item-inner-wrapper"
                onClick={() => handleOpen(indexType.value)}
              >
                <span className="index-types-dropdown-checkbox-wrapper">
                  { indexType.label }
                </span>

                <span className="index-types-dropdown-icon">
                  {openStates[indexType.value] ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </span>
              </div> 

              {openStates[indexType.value] && (
                <div className="index-types-dropdown-nested-items">
                  {indexType['items'].map((dataItem, index) => (
                    <div
                      className="index-types-dropdown-item"
                      onClick={() =>
                        handleSelect(dataItem.label, indexType.label, indexType.value,  dataItem.value)
                      }
                      key={index}
                    >
                      { dataItem.label }
                    </div>
                  ))}
                </div>
              )}
            </div>
          )
      ) : (
        <p style={{ margin: 0 }}>
          <FormattedMessage id="CLIENT.GENERAL.NO_OPTION" />
        </p>
      )}
        </div>
      )}
    </div>
  );
};

export default IndexTypeDropdown;
