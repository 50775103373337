import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import AuthPage from "./AuthPage";
import BasePage from "./BasePage";
import NotFound from "./pages/NotFound";
import EndUserPage from "./EndUserPage";
import DashboardPublic from "./pages/Dashboard/DashboardPublic";
import PublicMap from "./pages/Maps/pages/public-map.page";
import EmbeddedWidget from "./pages/EmbeddedWidget";
import DashboardPublicV2 from "./pages/Dashboard/DashboardPublicV2";
import { DashboardPublicV3 } from "./pages/Dashboard";

export function Routes() {
  const { token, roleId } = useSelector(
    ({ user }) => ({
      token: user.token,
      roleId: user.user?.roleId,
    }),
    shallowEqual
  );

  return (
    <Switch>
      <Route path="/public/embedded-widget" exact component={EmbeddedWidget} />
      <Route path="/public/dashboard" exact component={DashboardPublicV3} />
      {/* <Route path="/public/dashboard/v2" exact component={DashboardPublicV2} /> */}
      <Route path="/public/map" exact component={PublicMap} />
      <Route path="/not-found" component={NotFound} />

      {!token ? <AuthPage /> : <Redirect from="/auth" to="/" />}

      {!token ? (
        <Redirect to="/auth/login" />
      ) : roleId === 6 ? (
        <EndUserPage />
      ) : (
        <BasePage />
      )}
    </Switch>
  );
}
