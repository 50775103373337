import { useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

import { init, getInstanceByDom, use as echartsUse } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  LegendComponent,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  DataZoomComponent,
} from "echarts/components";

// export interface ReactEChartsProps {
//   option: EChartsOption;
//   style?: CSSProperties;
//   settings?: SetOptionOpts;
//   loading?: boolean;
//   theme?: 'light' | 'dark';
// }

export function ReactECharts({
  chartId,
  option,
  style,
  settings,
  loading,
  theme,
}) {
  const onResize = (width, height) => {
    const isResizing = document.querySelector(".placeholder-resizing");
    if ((chartRef.current && !isResizing) || width === 200 || height === 200) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.resize({
        width: width <= 200 ? 200 : "auto",
        height: height <= 200 ? 200 : "auto",
      });
    }
  };
  const { ref: chartRef } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
    onResize,
  });

  useEffect(() => {
    // Initialize chart
    let chart;
    if (chartRef.current !== null) {
      echartsUse([
        LegendComponent,
        ToolboxComponent,
        DataZoomComponent,
        TooltipComponent,
        GridComponent,
        BarChart,
        LineChart,
        CanvasRenderer,
      ]);

      chart = init(chartRef.current, theme, { renderer: "canvas" });
    }

    return () => {
      chart?.dispose();
    };
  }, [theme]);

  useEffect(() => {
    // Update chart

    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
      chart.dispatchAction({
        type: "takeGlobalCursor",
        key: "dataZoomSelect",
        dataZoomSelectActive: true,
      });
    }
  }, [option, settings, theme]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      loading === true ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [loading, theme]);

  return (
    <div
      className={`echart-${chartId}`}
      ref={chartRef}
      style={{ width: "100%", height: "100px", ...style }}
    />
  );
}
