import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SharedInputField from "../../../../shared/components/SharedInputField";
import { Link, Redirect } from "react-router-dom";

const MainForm = ({ intl, pageData }) => {
  const { formik, code, username, code_confirm, loading } = pageData;

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <Grid container className="small-container">
        <Grid item xs={12}>
          {code === "20001" && username && code_confirm ? (
            <Box mb={5}>
              <Alert severity="success" color="info">
                <FormattedMessage id="CLIENT.RESET.SUCCESSFUL_CODE" />
              </Alert>
            </Box>
          ) : (
            <Redirect to="/auth/login" />
          )}
        </Grid>
        {code === "20001" && (
          <>
            <Grid item xs={12}>
              <Box mb={2}>
                <SharedInputField
                  name="username"
                  label={intl.formatMessage({
                    id: "CLIENT.RESET.PASSWORD_EMAIL_PHONE_LABEL",
                  })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "CLIENT.RESET.PASSWORD_EMAIL_PHONE_HINT",
                  })}
                  required
                  errorMsg={
                    formik.touched["username"] && formik.errors["username"]
                  }
                  {...formik.getFieldProps("username")}
                  disabled={true}
                  className="disabled-field"
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mb={2}>
                <SharedInputField
                  name="password"
                  label={intl.formatMessage({
                    id: "CLIENT.RESET.PASSWORD_LABEL",
                  })}
                  type="password"
                  isPasswordField
                  placeholder={intl.formatMessage({
                    id: "CLIENT.RESET.PASSWORD_HINT",
                  })}
                  required
                  errorMsg={
                    formik.touched["password"] && formik.errors["password"]
                  }
                  {...formik.getFieldProps("password")}
                  disabled={formik.isSubmitting}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mb={2}>
                <SharedInputField
                  name="confirmPassword"
                  label={intl.formatMessage({
                    id: "CLIENT.RESET.CONFIRM_PASSWORD_LABEL",
                  })}
                  type="password"
                  isPasswordField
                  placeholder={intl.formatMessage({
                    id: "CLIENT.RESET.CONFIRM_PASSWORD_HINT",
                  })}
                  required
                  errorMsg={
                    formik.touched["confirmPassword"] &&
                    formik.errors["confirmPassword"]
                  }
                  {...formik.getFieldProps("confirmPassword")}
                  disabled={formik.isSubmitting}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                height="100%"
                className="note-msg"
                mb={3}
              >
                *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box mr={2}>
                  <Button
                    type="submit"
                    className="custom-button form-button"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    <FormattedMessage id="CLIENT.RESET.SUBMIT" />
                    {loading && (
                      <CircularProgress
                        color="inherit"
                        size={16}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </Button>
                </Box>
                <Box ml={2}>
                  <Link to="/auth/login">
                    <Button className="custom-button light-button form-button">
                      <FormattedMessage id="CLIENT.RESET.CANCEL" />
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default MainForm;
