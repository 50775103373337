import { Tooltip } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { FormattedMessage } from "react-intl";
export default function EmptyColorPoint() {
  return (
    <Tooltip
      title={
        <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.DATA_THRESHOLD_EMPTY_TOOLTIP" />
      }
    >
      <ErrorOutlineIcon style={emptyColorPointColor} />
    </Tooltip>
  );
}

const emptyColorPointColor = {
  color: "#979797",
  width: "15px",
  height: "15px",
};
