import React, { useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  Switch,
  Checkbox,
  FormControlLabel,
  Box,
  Grid,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import SharedInputField from "../../../../../shared/components/SharedInputField";
import icons from "./icons-data";
import workspaceDashboardApi from "../../../../../api/workspaceDashboardApi";
import { useFormik } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./styles.css";
import Security from "./Security";
import SecurityAdd from "./Security/SecurityAdd";
import * as Yup from "yup";
import { encryptKey } from "../../../../../utils/crypto";
import { setDashboardSetting } from "app/redux/actions/dashboardActions";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
}));

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const getDefaultValues = () => {
  return {
    name: "Dashboard",
    url: "https://ba.reecotech.vn/public/dashboard/yuydb8l1lxkfiku",
    header: 0,
    icon: 1,
    show_logo: false,
    show_header: false,
    show_footer: false,
    has_password: false,
    password: null,
    public: false,
  };
};

function MenuModal(props) {
  const { intl, dashboardId } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState(getDefaultValues());
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props["open"] || false);
  const [tab, setTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [securityPage, setSecurityPage] = React.useState("list"); // list | new
  const dispatch = useDispatch();
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => {
      setTab(0);
    };
  }, []);

  const toggleOpen = () => {
    setTab(0);
    props["handleClose"]();
  };

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChangeTab = (event, newTab) => {
    if (tab === 1 && securityPage === "new") {
      setSecurityPage("list");
    }
    setTab(newTab);
  };

  const generatePublicLink = (url) => {
    return `${window.location.origin}/public/dashboard?key=${url}`;
  };

  const onClickCopy = async () => {
    formik.setFieldValue("urlCopied", true);
  };

  const updateGeneral = async (values) => {
    try {
      dispatch(
        setDashboardSetting({
          id: dashboardId,
          icon: values?.icon,
          isPublic: values?.public,
          url: values?.url,
        })
      );

      const data = {
        ...values,
        public_access: `${values.public}`,
      };
      const res = await workspaceDashboardApi.updateGeneral(
        dashboardId,
        data,
        token
      );
    } catch (error) {}
  };
  const GeneralSchema = Yup.object().shape({
    password: Yup.string()
      .nullable()
      .when("has_password", {
        is: (val) => (val ? true : false),
        then: Yup.string()

          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          ),
      }),
  });

  const formik = useFormik({
    initialValues: {
      url: values.url,
      icon: values.icon,
      public: values.public,
      show_logo: values.show_logo,
      show_footer: values.show_footer,
      show_header: values.show_header,
      has_password: values.has_password,
      password: values.password,
      urlCopied: false,
    },
    validationSchema: GeneralSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      updateGeneral(values);
      props["handleClose"]();
    },
  });

  const onSubmit = () => {
    props["handleEdit"](values);
    props["handleClose"]();
  };

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.DASHBOARD.SETTING.HEADER" />
        </div>
        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={props["handleClose"]} />
          </span>
        </div>
      </div>
    );
  };

  const renderGeneralTab = () => {
    return (
      <>
        <FormControlLabel
          control={
            <Android12Switch
              checked={formik.values.public}
              color="primary"
              onChange={(e) => {
                formik.setFieldValue("public", e.target.checked);
              }}
            />
          }
          label={intl.formatMessage({
            id: "CLIENT.DASHBOARD.SETTING.PUBLIC_ACCESS",
          })}
        />
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            formik.values.public && (
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <SharedInputField
                      label={intl.formatMessage({
                        id: "CLIENT.DASHBOARD.SETTING.URL",
                      })}
                      name="name_vi"
                      className="disabled-field target-url"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "CLIENT.DASHBOARD.NAME_VI_HINT",
                      })}
                      disabled={true}
                      value={
                        formik.values.url !== ""
                          ? generatePublicLink(formik.values.url)
                          : ""
                      }
                    />
                    {formik.values.urlCopied && (
                      <FormHelperText className="success-message">
                        <FormattedMessage id="CLIENT.DASHBOARD.SETTING.COPY_SUCCESS" />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <CopyToClipboard
                      text={generatePublicLink(formik.values.url)}
                      onCopy={onClickCopy}
                    >
                      <Button
                        className="custom-button light-button"
                        type="button"
                        size="large"
                        style={{
                          marginTop: "30.2px",
                          padding: "16px 27px",
                          width: "100%",
                        }}
                      >
                        <FormattedMessage id="CLIENT.DASHBOARD.SETTING.COPY" />
                      </Button>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
                <Box mt={3} mb={3}>
                  <FormControlLabel
                    control={
                      <Android12Switch
                        checked={formik.values.has_password}
                        color="primary"
                        onChange={(e) => {
                          formik.setFieldValue(
                            "has_password",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "CLIENT.LOGIN.PASSWORD_LABEL",
                    })}
                  />
                </Box>

                {loading ? (
                  <CircularProgress />
                ) : (
                  formik.values.has_password && (
                    <Grid container spacing={2} mt={3}>
                      <Grid item xs={12}>
                        <SharedInputField
                          label={intl.formatMessage({
                            id: "CLIENT.CONNECTION_ACTIONS.PASSWORD_LABEL",
                          })}
                          name="password"
                          className=""
                          type="password"
                          isPasswordField
                          required
                          placeholder={intl.formatMessage({
                            id: "CLIENT.CONNECTION_ACTIONS.PASSWORD_HINT",
                          })}
                          disabled={formik.isSubmitting}
                          value={formik.values.password}
                          errorMsg={
                            formik.touched["password"] &&
                            formik.errors["password"]
                          }
                          {...formik.getFieldProps("password")}
                        />
                      </Grid>
                    </Grid>
                  )
                )}
                <Box className="form-label" mt={3}>
                  <FormattedMessage id="CLIENT.DASHBOARD.SHOW_HEADER_FOOTER_LABEL" />
                </Box>

                <Box mb={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="show_footer"
                        checked={formik.values.show_footer}
                        onChange={(e) => {
                          formik.setFieldValue("show_footer", e.target.checked);
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "CLIENT.DASHBOARD.SHOW_HEADER/FOOTER_LABEL",
                    })}
                  />
                </Box>
              </Box>
            )
          )}
        </Box>

        <Box className="form-label" mt={3}>
          <FormattedMessage id="CLIENT.DASHBOARD.SETTING.ICON" />
        </Box>

        <div
          className="icon-container"
          onChange={(e) => formik.setFieldValue("icon", e.target.value)}
          defaultValue={formik.values.icon}
        >
          {(icons || []).map((item) => (
            <label className="icon-item-container" key={item.id}>
              <input
                type="radio"
                name="icon"
                value={item.id}
                defaultChecked={
                  formik.values.icon == item.id ? "defaultChecked" : null
                }
              />
              <div class="dashboard-icon">
                <img className="icon" alt="" src={item.imageUrl} />
              </div>
            </label>
          ))}
        </div>

        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="show_logo"
                checked={formik.values.show_logo}
                onChange={(e) => {
                  formik.setFieldValue("show_logo", e.target.checked);
                }}
              />
            }
            label={intl.formatMessage({
              id: "CLIENT.DASHBOARD.SETTING.SHOW_LOGO",
            })}
          />
        </Box>
      </>
    );
  };

  const renderSecurityTab = () => {
    return (
      <>
        {securityPage === "list" ? (
          <Security
            setSecurityPage={setSecurityPage}
            dashboardId={dashboardId}
          />
        ) : (
          <SecurityAdd
            setSecurityPage={setSecurityPage}
            dashboardId={dashboardId}
          />
        )}
      </>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <form onSubmit={formik.handleSubmit}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor=""
            style={{ marginBottom: 16 }}
          >
            <Tab
              label={intl.formatMessage({
                id: "CLIENT.DASHBOARD.SETTING.GENERAL",
              })}
            />
            <Tab
              label={intl.formatMessage({
                id: "CLIENT.DASHBOARD.SETTING.SECURITY",
              })}
            />
          </Tabs>

          {tab === 0 && renderGeneralTab()}
          {tab === 1 && renderSecurityTab()}
          {tab === 0 && (
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              mt={4}
            >
              <Box display="flex" alignItems="center" ml="auto">
                <Button
                  className="custom-button"
                  type="submit"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <FormattedMessage id="CLIENT.DASHBOARD.SAVE_ITEM" />
                </Button>
                <Box ml={3}>
                  <Button
                    className="custom-button light-button"
                    type="button"
                    onClick={toggleOpen}
                  >
                    <FormattedMessage id="CLIENT.DASHBOARD.CANCEL" />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    setOpen(props["open"]);
    return () => {
      formik.resetForm();
    };
  }, [props["open"], props["data"]]);

  React.useEffect(() => {
    const getGeneralData = async () => {
      try {
        const res = await workspaceDashboardApi.getGeneral(dashboardId, token);
        if (res.success) {
          setValues((pre) => ({
            ...pre,
            icon: Number(res.data.icon),
            public: res.data.public_access,
            show_logo: res.data.show_logo,
            show_header: res.data.show_header,
            show_footer: res.data.show_footer,
            has_password: res.data.has_password,
            password: res.data.password,
            url: res.data.url,
            urlCopied: false,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (open && dashboardId) {
      getGeneralData();
    }
  }, [dashboardId, open]);

  React.useEffect(() => {
    if (formik.values.public !== values.public && dashboardId) {
      const newUrl = encryptKey(dashboardId);
      formik.setFieldValue("url", newUrl);
    }
  }, [formik.values.public]);

  return (
    <Modal
      open={open}
      onClose={props["handleClose"]}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

export default injectIntl(MenuModal);
