/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import swal from 'sweetalert';

import { useFormik } from "formik";
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalContainer from '../../common/ModalContainer';
import { LOCATION_MAP_INITIALVALUES } from '../initialValues';
import LocationMapSchema from '../schemas/LocationMapFormSchema';
import useModal from '../useModal';
import LocationMapForm from './LocationMapForm';
import './styles.css';
import { prepareLocatopnMapOutput } from '../helpers';

function MenuModal(props) {
  const { intl, stations, open, handleClose } = props;

  const {
    openModal,
    setOpenModal,
    toggleOpen,
    language } = useModal({ handleClose, modalOpen: open });

  const formik = useFormik({
    initialValues: {
      ...LOCATION_MAP_INITIALVALUES,
      name_en: props.data?.name_en || '',
      name_vi: props.data?.name_vi || '',
      header_color: props.data?.header_color || '#1E1E2D',
      show_header: props.data?.show_header === undefined ? LOCATION_MAP_INITIALVALUES.show_header : props.data.show_header,

      text_color: props.data?.dashboard_location_map?.text_color || LOCATION_MAP_INITIALVALUES.text_color,
      dashboard_location_map_station: props.data?.dashboard_location_map?.dashboard_location_map_station || [],
      display_coordinate: props.data?.dashboard_location_map?.display_coordinate || LOCATION_MAP_INITIALVALUES.display_coordinate,
      zoom: props.data?.dashboard_location_map?.zoom || LOCATION_MAP_INITIALVALUES.zoom,
      style: props.data?.dashboard_location_map?.style || LOCATION_MAP_INITIALVALUES.style,
      data_mode: props.data?.data_mode || LOCATION_MAP_INITIALVALUES.data_mode,
      prediction_time_range: (props.data?.time_point && props.data?.time_point !== "0") ? props.data?.time_point : LOCATION_MAP_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: (props.data?.time_point_type && props.data?.time_point_type !== "0") ? props.data?.time_point_type : LOCATION_MAP_INITIALVALUES.prediction_time_range_type,
    },
    enableReinitialize: true,
    validationSchema: LocationMapSchema({ intl }),
    onSubmit: async (values) => {
      if (!(formik.values.dashboard_location_map_station || []).filter(x => x?.station_id && x?.station_id !== "").length) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.LOCATIONMAP.MISSING_STATION',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return
      }

      formik?.values?.dashboard_location_map_station?.forEach(item => {
        if ("station" in item) {
          delete item.station
        }
      })

      const { id } = props;
      props['handleEdit'](id, prepareLocatopnMapOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);

    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  return (<ModalContainer
    formik={formik}
    editing={props?.editing}
    handleClose={props?.handleClose}
    open={openModal}
    disabledSave={formik.isSubmitting ||
      !formik.isValid ||
      formik.values['dashboard_location_map_station']?.find(item => item?.editing === true)}
    toggleOpen={toggleOpen}
    newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_LOCATION_MAP" />}
    editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_LOCATION_MAP" />}>
    <LocationMapForm
      formik={formik}
      intl={intl}
      stations={stations}
      language={language}
    />
  </ModalContainer>)
}

export default injectIntl(MenuModal)