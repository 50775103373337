import { useRef, useState } from "react";

export const useLayerItemConfig = ({
  formik,
  data,
  handleRemove,
  disabledDelete,
  onChange,
  handleMove,
}) => {
  const itemRef = useRef(null);
  const executeScroll = () => itemRef?.current?.scrollIntoView();
  const [filePlaceholder, setFilePlaceholder] = useState("");

  const onClickRemove = () => {
    // if (disabledDelete) return;

    if (handleRemove && data?.row_id) {
      handleRemove(data.row_id);
    }
  };

  const toggleExpand = () => {
    onChange &&
      onChange({
        ...data,
        ...formik?.values,
        expanded: !data?.expanded,
      });
  };

  const onClickMove = (direction) => {
    if (handleMove && data?.row_id) {
      handleMove(data.row_id, direction);
    }
  };

  const onFileChange = (file) => {
    formik?.setFieldValue("data_file_name", file?.originalname);
    formik?.setFieldValue("data_file_url", file?.path);
    formik?.setFieldValue("geo_json_url", file?.geo_json_url);
  };

  const onFileTypeChange = (field, value) => {
    formik?.setFieldValue(field, value);
    formik?.setFieldValue("data_file_name", "");
    formik?.setFieldValue("data_file_url", "");
    formik?.setFieldValue("geo_json_url", "");
  };

  const layerMaster = {
    itemRef,
    filePlaceholder,

    executeScroll,
    setFilePlaceholder,
    onClickRemove,
    onFileChange,
    onFileTypeChange,
    toggleExpand,
    onClickMove,
  };

  return layerMaster;
};
