import { Button } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ColorPicker from "app/shared/components/ColorPicker";
import { EmptyColorPoint } from "app/shared/components/ColorPoint";
import ColorPoint from "app/shared/components/ColorPoint/color-point.component";
import { DragDropTable } from "app/shared/components/DragDropTable";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { useLanguage } from "app/shared/hooks";
import useDataAlarm from "app/shared/hooks/DataAlarm/data-alarm.hook";
import React from "react";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";

const _displayTypes = [
  {
    id: 1,
    value: "value",
    name_en: "Value",
    name_vi: "Giá Trị",
  },
  {
    id: 2,
    value: "status",
    name_en: "State",
    name_vi: "Trạng Thái",
  },
];

const styles = {
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 0,
    color: "#3f4254",
  },
  list: {
    display: "div",
    divTemplateColumns: "repeat(5, 1fr)",
  },
  footer: {
    marginTop: 24,
    marginBottom: 32,
  },
  button: {
    color: "#B6B6B6",
    cursor: "pointer",
    marginRight: 3,
    marginLeft: 3,
  },
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: "none",
    background: isDragging ? "rgb(236, 236, 236)" : "rgb(236, 236, 236)",
    border: isDragging ? "1px solid #eee" : "none",
    // borderBottom: "1px solid black",
    paddingTop: "6px",
    paddingBottom: "6px",
    ...draggableStyle,
  };
};

const DashboardHistoricTable = (props) => {
  const [data, setData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [language] = useLanguage();
  const { intl } = props;
  const { isLoadingDataAlarm, getDataAlarm } = useDataAlarm();

  const handleChange = (id, event) => {
    const newValues = values.map((item) => {
      if (item.row_id === id) {
        const newItem = { ...item };
        newItem[event.target.name] = event.target.value;
        newItem["error"] = {
          ...newItem.error,
          display_name: newItem["display_name"]
            ? undefined
            : intl.formatMessage({ id: "CLIENT.GENERAL.REQUIRED_FIELD" }),
        };
        return newItem;
      }
      return item;
    });

    // const newData = [...data];

    // newData[index][event.target.name] = event.target.value;

    // newValues[index][event.target.name] = event.target.value;

    setValues(newValues);
    // setData(newData);

    // props['onChange'](newData);
  };

  const handleChangeParameter = async (
    id,
    parameterId,
    stationId,
    display_name
  ) => {
    const dataAlarm = await getDataAlarm(parameterId);

    const newValues = values.map((item, idx) => {
      if (item.row_id === id) {
        return {
          ...item,
          station_id: stationId,
          parameter_id: parameterId,
          display_name,
          parameter: {
            ...item.parameter,
            data_alarm: dataAlarm,
          },
        };
      }

      return item;
    });

    setValues(newValues);
  };

  const addNew = (e) => {
    e.preventDefault();

    const newValueParameter = {
      row_id: uuidv4(),
      parameter_id: -1,
      station_id: null,
      main_color: "#CCCCCC",
      alarm_color: "#FE9200",
      editing: true,
      display_type: "value",
      display_name: "",
      new: true,
    };
    props["onChange"]([...data, newValueParameter]);
  };

  const onApply = (id) => {
    const newValue = values.find((x) => x.row_id === id);
    const newData = [...data].map((item) => {
      if (item.row_id === id) {
        return {
          ...newValue,
          new: false,
          editing: false,
        };
      }
      return item;
    });
    props["onChange"](newData);
  };

  const onCancel = (id) => {
    if (data.some((d) => d.row_id === id && d.new === true)) {
      const newData = data.filter((d) => d.row_id !== id);
      props["onChange"](newData);
    } else {
      const oldData = data.map((item) => {
        if (item.row_id === id) {
          const oldItem = {
            ...item,
            editing: false,
          };

          return oldItem;
        }

        return item;
      });
      props["onChange"](oldData);
    }
  };

  const toggleEdit = (id) => {
    const newData = data.map((item) => {
      if (item.row_id === id) {
        return {
          ...item,
          editing: true,
        };
      }
      return item;
    });

    props["onChange"](newData);
  };

  const deleteItem = (id) => {
    const newValues = values.filter((item) => item.row_id !== id);

    const indexDeleted = data.findIndex((item) => item.row_id === id);
    if (indexDeleted > -1) {
      let newListDelete = [...props["params_delete"]];
      if (
        newListDelete.some((item) => item === data[indexDeleted].id) === false
      ) {
        props["onDelete"]([...newListDelete, data[indexDeleted].id]);
      }
    }

    props["onChange"](newValues);
  };

  React.useEffect(() => {
    if (props["data"]) {
      const dataReceive = [...props["data"]].map((item) => {
        const newItem = { ...item };
        if (!newItem.row_id) {
          newItem.row_id = uuidv4();
        }
        return newItem;
      });
      setData(dataReceive);
    }
  }, [props["data"]]);

  React.useEffect(() => {
    const newValue = data.map((item, index) => {
      if (
        values.some(
          (vl) => vl.row_id === item.row_id && item.editing && vl.editing
        )
      ) {
        return values.find((x) => x.row_id === item.row_id);
      }
      return item;
    });
    setValues(newValue);
  }, [data]);

  const renderValueRow = (item, provided, snapshot) => {
    let canApply = !item?.error?.display_name;

    return (
      <div
        container
        spacing={0.5}
        key={item.row_id}
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        className="historic-table-parameter-row"
      >
        <div>
          <SelectParamsByStaionDropdown
            data={(props.parameters || []).map((row) => ({
              ...row,
              items: row.items.map((item) => ({
                ...item,
                disabled: values.some((x) => x.parameter_id === item.id),
              })),
            }))}
            value={{
              parameterId: item["parameter_id"],
              stationId: item["station_id"],
            }}
            disabled={!item?.editing || isLoadingDataAlarm}
            onChange={({ stationId, parameterId, display_name }) => {
              handleChangeParameter(
                item.row_id,
                parameterId,
                stationId,
                display_name
              );
            }}
          />
        </div>
        <div>
          <SharedInputField
            style={{
              backgroundColor: "#fff",
            }}
            className="text-normal"
            name="name"
            disabled={!item?.editing}
            placeholder="display name"
            value={item?.display_name}
            onChange={(event) =>
              handleChange(item.row_id, {
                target: { name: "display_name", value: event.target.value },
              })
            }
            errorMsg={item?.error?.display_name}
          />
        </div>
        <div>
          <SharedSelectField
            style={{
              backgroundColor: "#fff",
            }}
            className="text-normal"
            disableClearable
            name="display_type"
            disabled={!item?.editing}
            options={_displayTypes.map((item) => ({
              ...item,
              label: item[`name_${language}`],
            }))}
            defaultValue={item?.display_type || "value"}
            onChange={(name, value) =>
              handleChange(item.row_id, {
                target: { name: "display_type", value: value },
              })
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ColorPicker
            style={{
              border: "1px solid #ccc",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
            }}
            color={item?.main_color}
            disabled={!item?.editing}
            onChange={(newColor) => {
              handleChange(item.row_id, {
                target: { name: "main_color", value: newColor },
              });
            }}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gridGap: "5px",
            minHeight: "38px",
            alignItems: "center",
          }}
        >
          {item?.parameter?.data_alarm?.length > 0 ? (
            item?.parameter?.data_alarm?.map((alarm) => (
              <ColorPoint
                key={item?.parameter?.data_alarm?.id || uuidv4()}
                color={alarm?.alarm_color}
                defaultColor="#fe9200"
                tooltip={alarm[`name_${language}`]}
                size="small"
              />
            ))
          ) : (
            <EmptyColorPoint />
          )}
        </div>
        <div
          style={{
            marginTop: "12px",
          }}
        >
          {item?.editing && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props["pickColors"] && (
                <span
                  style={
                    canApply
                      ? {
                          color: "#008a00",
                          cursor: "pointer",
                          marginRight: 3,
                          marginLeft: 3,
                        }
                      : {
                          color: "#B6B6B6",
                          pointerEvents: "none",
                          marginRight: 3,
                          marginLeft: 3,
                        }
                  }
                  onClick={() => onApply(item.row_id)}
                >
                  <CheckIcon />
                </span>
              )}

              {props["pickColors"] && (
                <span
                  style={{
                    color: "#db3700",
                    cursor: "pointer",
                    marginRight: 3,
                    marginLeft: 3,
                  }}
                  onClick={() => onCancel(item.row_id)}
                >
                  <CloseIcon />
                </span>
              )}

              {!props["pickColors"] && (
                <span
                  style={{
                    ...styles.button,
                    marginRight: props["pickColors"] ? 12 : 0,
                  }}
                >
                  <DeleteIcon onClick={() => deleteItem(item.row_id)} />
                </span>
              )}

              <span {...provided.dragHandleProps}>
                <DragIndicator />
              </span>
            </div>
          )}

          {!item?.editing && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={styles.button}>
                <EditIcon onClick={() => toggleEdit(item.row_id)} />
              </span>

              <span style={styles.button}>
                <DeleteIcon onClick={() => deleteItem(item.row_id)} />
              </span>
              <span {...provided.dragHandleProps}>
                <DragIndicator />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const headerForDragDrop = (
    <div className="historic-table-parameter-header">
      <div className="historic-table-parameter-header-cell">
        <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
      </div>
      <div className="historic-table-parameter-header-cell">
        <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.DISPLAY_NAME" />
      </div>
      <div className="historic-table-parameter-header-cell">
        <FormattedMessage id="CLIENT.PARAMETERS_MANAGEMENT.DISPLAY_TYPE_LABEL" />
      </div>
      {props["pickColors"] && (
        <div className="historic-table-parameter-header-cell">
          <FormattedMessage id="CLIENT.DASHBOARD.MAIN_COLOR_LABEL" />
        </div>
      )}
      {props["pickColors"] && (
        <div className="historic-table-parameter-header-cell">
          <FormattedMessage id="CLIENT.DASHBOARD.ALARM_COLOR_LABEL" />
        </div>
      )}
      {!props.viewOnly && (
        <div
          className="historic-table-parameter-header-cell"
          style={{
            textAlign: "center",
          }}
        >
          <FormattedMessage id="GENERAL.ACTIONS" />
        </div>
      )}
    </div>
  );

  const _onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    let newValues = [...values];
    const [removed] = newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, removed);
    newValues = newValues.map((item, index) => ({
      ...item,
      order_number: index,
    }));
    setValues(newValues);
    props["onChange"](newValues);
  };
  return (
    <div
      className="threshold-editor mt-6"
      style={{
        marginTop: "6px",
      }}
    >
      {!props.viewOnly && (
        <Button
          className="custom-button"
          style={{
            border: "1px solid #ccc",
          }}
          onClick={addNew}
        >
          <FormattedMessage id="CLIENT.DASHBOARD.NEW_VALUE" />
        </Button>
      )}
      <DragDropTable
        visible={values?.length > 0}
        containerClassName="historic-table-parameter"
        rows={values}
        rowKeyPropertyName="row_id"
        headerComponent={headerForDragDrop}
        onDragEnd={_onDragEnd}
        onRenderRow={renderValueRow}
      />
    </div>
  );
};

export default DashboardHistoricTable;
