import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={`reeco-icon ${props?.className}`}
      {...props}
      viewBox="0 0 40 40"
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M20 3.333c2.767 0 5 2.234 5 5v13.334a8.35 8.35 0 013.334 6.666c0 4.6-3.734 8.334-8.334 8.334a8.336 8.336 0 01-8.333-8.334A8.35 8.35 0 0115 21.668V8.333c0-2.766 2.234-5 5-5zm0 3.334c-.916 0-1.666.75-1.666 1.667v10h3.333V15H20v-1.666h1.667V10H20V8.334h1.667c0-.917-.75-1.667-1.667-1.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
