import React from "react";
import { injectIntl } from "react-intl";
import AuthLayout from "../../../shared/layouts/AuthLayout";
import { useRegistration } from "./hooks";
import { MainForm } from "./components";
import "../index.css";

function Registration(props) {
  const { intl } = props;
  const pageData = useRegistration(props);

  return (
    <AuthLayout
      title={intl.formatMessage({ id: "CLIENT.SIGN_UP.PAGE_TITLE" })}
      description={intl.formatMessage({ id: "CLIENT.SIGN_UP.PAGE_SUBTITLE" })}
    >
      <MainForm intl={intl} pageData={pageData} />
    </AuthLayout>
  );
}

export default injectIntl(Registration);
