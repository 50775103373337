const secretKey = process.env.EN_KEY || "reeCoTech2021Crypto";
const rowHeight = 150;
const columns = 6;

const defaultX = 0;
const defaultY = 0;
const defaultWH = {
  //1:moble 2:tablet 3: destop
  1: {
    VALUE: { w: 3, h: 1 },
    VALUE_LIST: { w: 6, h: 1 },
    STATIC_TEXT: { w: 3, h: 1 },
    ATTACHMENT_IMAGE: { w: 6, h: 1 },
    HISTORIC_TABLE: { w: 6, h: 1 },
    LOCATION_MAP: { w: 6, h: 2 },
    ALARM_SUMMARY: { w: 3, h: 1 },
    DIRECTION: { w: 3, h: 1 },
    STANDARD_CHART: { w: 6, h: 1 },
    GAUGE_CHART: { w: 3, h: 1 },
    CUSTOM_CHART: { w: 6, h: 1 },
    PREDICTION_PARAMETER_HISTORY_TABLE: { w: 6, h: 1 },
    PREDICTION_PARAMETER_STANDARD_CHART: { w: 6, h: 1 },
    AGGREGATE_TABLE: { w: 6, h: 1 },
  },
  2: {
    VALUE: { w: 3, h: 1 },
    VALUE_LIST: { w: 6, h: 1 },
    STATIC_TEXT: { w: 3, h: 1 },
    ATTACHMENT_IMAGE: { w: 6, h: 1 },
    HISTORIC_TABLE: { w: 6, h: 1 },
    LOCATION_MAP: { w: 6, h: 2 },
    ALARM_SUMMARY: { w: 3, h: 1 },
    DIRECTION: { w: 3, h: 1 },
    STANDARD_CHART: { w: 6, h: 1 },
    GAUGE_CHART: { w: 3, h: 1 },
    CUSTOM_CHART: { w: 6, h: 1 },
    PREDICTION_PARAMETER_HISTORY_TABLE: { w: 6, h: 1 },
    PREDICTION_PARAMETER_STANDARD_CHART: { w: 6, h: 1 },
    AGGREGATE_TABLE: { w: 6, h: 1 },
  },
  3: {
    VALUE: { w: 3, h: 1 },
    VALUE_LIST: { w: 6, h: 1 },
    STATIC_TEXT: { w: 3, h: 1 },
    ATTACHMENT_IMAGE: { w: 6, h: 1 },
    HISTORIC_TABLE: { w: 6, h: 1 },
    LOCATION_MAP: { w: 6, h: 2 },
    ALARM_SUMMARY: { w: 3, h: 1 },
    DIRECTION: { w: 3, h: 1 },
    STANDARD_CHART: { w: 6, h: 1 },
    GAUGE_CHART: { w: 3, h: 1 },
    CUSTOM_CHART: { w: 6, h: 1 },
    PREDICTION_PARAMETER_HISTORY_TABLE: { w: 6, h: 1 },
    PREDICTION_PARAMETER_STANDARD_CHART: { w: 6, h: 1 },
    AGGREGATE_TABLE: { w: 6, h: 1 },
  },
};

const defaultW50 = 3;
const defaultH = 1;
const defaultMinH = 1;
const PUBLIC_DASHBOARD_SESSION_KEY = "pdss";
const TIMEOUT_SESSION = {
  amount: 2,
  unit: "hour",
};
const CHECK_SAVE_MODE = {
  NAVIGATE: "NAVIGATE",
  SCREEN: "SCREEN",
  PAGE: "PAGE",
};

const PAGE_MODE = {
  VIEW: "VIEW",
  ARRANGE: "ARRANGE",
  EDIT: "EDIT",
};
export {
  secretKey,
  rowHeight,
  columns,
  defaultX,
  defaultY,
  defaultWH,
  defaultW50,
  defaultH,
  defaultMinH,
  PUBLIC_DASHBOARD_SESSION_KEY,
  TIMEOUT_SESSION,
  PAGE_MODE,
  CHECK_SAVE_MODE,
};
