import { useState } from "react";
import { Box, IconButton, Avatar } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";
import DefaultUser from "../../../../../assets/images/user.png";
import VI_FLAG from "../../../../../assets/images/vi-flag.svg";
import EN_FLAG from "../../../../../assets/images/en-flag.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { MobileLanguageSelector } from "./MobileLanguageSelector";
import { useLanguage } from "app/shared/hooks";
import styles from "./MobileHeader.module.css";

export const MobileHeader = ({
  onToggleMenu = () => {},
  onToggleRightDrawer = () => {},
  title = "",
  breadcrumbs = [],
}) => {
  const [language] = useLanguage();
  const { user } = useSelector(
    ({ user }) => ({
      user: user.user,
      role: user.role,
    }),
    shallowEqual,
  );

  const [showsLanguageSelector, setShowsLanguageSelector] = useState(false);

  const onShowLanguageSelector = () => setShowsLanguageSelector(true);

  const onCloseLanguageSelector = () => setShowsLanguageSelector(false);

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.topBar}>
          <Box className={styles.topBarLeft}>
            <IconButton aria-label="menu" onClick={onToggleMenu}>
              <MenuIcon
                fontSize="large"
                style={{ color: "rgba(255, 255, 255, 0.5)" }}
              />
            </IconButton>
          </Box>

          <Box className={styles.topBarCenter}>
            <h1 className={styles.brand}>VnEmisoft</h1>
          </Box>

          <Box className={styles.topBarRight}>
            <Link to="/chats">
              <Avatar
                alt=""
                src="/images/header-chat-icon.png"
                style={{
                  border: "rgba(255, 255, 255, 0.5)",
                  marginRight: 12,
                  width: 32,
                  height: 32,
                }}
              />
            </Link>

            <Avatar
              alt=""
              src={language === "en" ? EN_FLAG : VI_FLAG}
              style={{
                border: "rgba(255, 255, 255, 0.5)",
                marginRight: 12,
                width: 32,
                height: 32,
                cursor: "pointer",
              }}
              onClick={onShowLanguageSelector}
            />

            <Avatar
              alt=""
              src={
                user.avatar
                  ? `${process.env.REACT_APP_API_URL}/${user.avatar}`
                  : DefaultUser
              }
              sizes="small"
              style={{ border: "1px solid white" }}
              onClick={onToggleRightDrawer}
            />
          </Box>
        </Box>

        <Box className={styles.breadcrumbs}>
          <h2 className={styles.breadcrumbsTitle}>{title}</h2>

          {breadcrumbs?.length > 0 &&
            breadcrumbs?.map((breadcrumb, index) => (
              <>
                <ChevronRightIcon style={{ color: "gray" }} />

                <Link to={breadcrumb?.link} className={styles.breadcrumb}>
                  {breadcrumb?.name}
                </Link>

                {index !== breadcrumbs?.length - 1 && (
                  <ChevronRightIcon style={{ color: "gray" }} />
                )}
              </>
            ))}
        </Box>
      </Box>

      <MobileLanguageSelector
        visible={showsLanguageSelector}
        onClose={onCloseLanguageSelector}
      />
    </>
  );
};
