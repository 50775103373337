import axiosClient from "./axiosClient";

const paramtersApi = {
  getParameterType(token) {
    const url = "/api/v1/parameter-type";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getIndexType(token) {
    const url = "/api/v1/indicator/category";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getDisplayType(token) {
    const url = "/api/v1/display-type/";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getAllParameters(id, params, token) {
    const url = `/api/v1/parameter/get-all-parameter/${id}`;
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}, params})
  },
  getAllParametersByConnection(id, params, token) {
    const url = `/api/v1/parameter/get-all-parameter-by-connection/${id}`;
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}, params})
  },
  getAllParametersByStation(stationId) {
    const url = `/api/v2/stations/${stationId}/parameters`;
    return axiosClient.get(url)
  },
  getAllDataAlarmByParameter(id) {
    const url = `/api/v1/parameter/${id}/data-alarm`;
    return axiosClient.get(url)
  },
  getParameterHistory(id, data) {
    const url = `/api/v2/parameters/${id}/history-data`;
    return axiosClient.post(url, data)
  },
  getParameter(id, token) {
    const url = `/api/v1/parameter/${id}`;
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  addParameter(data, token) {
    const url = "/api/v1/parameter/";
    return axiosClient.post(url, data, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type": "multipart/form-data"}})
  },
  deleteParameter(id, token) {
    const url = `/api/v1/parameter/${id}`;
    return axiosClient.delete(url, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  updateParameter(id, data, token) {
    const url = `/api/v1/parameter/${id}`;
    return axiosClient.put(url, data, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type": "multipart/form-data"}});
  },
  getAlarmTypes(token) {
    const url = "/api/v1/parameter/alarm-type";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getNotifications(token) {
    const url = "/api/v1/parameter/notification";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getWarningTypes(token) {
    const url = "api/v1/notification/type?group=WARNING_TYPE";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getTimeTypes(token) {
    const url = "/api/v1/parameter/time-type";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  getWarningType(token) {
    const url = "api/v1/notification/type?group=WARNING_TYPE";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}})
  },
  validateTerminal(equation, token) {
    const url = "api/v1/parameter/validate-parameter";
    return axiosClient.post(url, {equation: equation}, {headers: {"Authorization" : `Bearer ${token}`}})
  },
  getParameterPrediction(parameter_id) {
    const url = `api/v1/parameter-prediction/get-all-parameter-prediction/${parameter_id}`;
    return axiosClient.get(url);
  },
  deleteParameterPrediction(predict_parameter_id) {
    const url = `api/v1/parameter-prediction/delete-parameter-prediction/${predict_parameter_id}`;
    return axiosClient.delete(url);
  },
  updateRemoveParameterPredictionInLineChart({listParameterDelete}) {
    const url = `api/v1/dashboard/prediction-parameter-standard-chart-remove-parameter`;
    return axiosClient.put(url, {listParameterDelete: listParameterDelete});
  }
}

export default paramtersApi;
