import paramtersApi from "app/api/parametersApi";
import { useCallback, useMemo, useState } from "react";

export const usePredictionParameters = () => {
    const [predictionParameterList, setPredictionParameterList] = useState([]);
    const fetchPredictionParametersByParameter = useCallback(async (parameterId) => {
        if (parameterId) {
            const res = await paramtersApi.getParameterPrediction(parameterId);
            if (res.data && res.data.length > 0) {
                res.data.map((elm) => {
                    return {
                        ...elm,
                        isSelected: false
                    }
                })
                setPredictionParameterList(res.data);
            } else {
                setPredictionParameterList([]);
            }
        } else {
            setPredictionParameterList([]);
        }
    }, [])

    return useMemo(() => ({
        predictionParameterList,
        fetchPredictionParametersByParameter
    }), [fetchPredictionParametersByParameter, predictionParameterList])
}