/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import { ContactInformation, GeneralInformation, LocationInformation, TemplateFileInformation } from 'app/pages/DataSharingRequest/components/FormItems';
import { DataSharingEditInitialValues, DummyDataKey } from 'app/pages/DataSharingRequest/constants';
import { useDataSharing } from 'app/pages/DataSharingRequest/hooks';
import { EditDataSharingSchema } from 'app/pages/DataSharingRequest/Schemas';
import clsx from "clsx";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import dataSharingApi from '../../../api/dataSharingApi';
import SharedInputField from '../../../shared/components/SharedInputField';
import "./index.css";
import IndexModal from './IndexModal';

const DataSharingEdit = (props) => {
  const { intl } = props;

  const {
    language,
    data,
    loading,
    activeItem,
    activeId,
    isEditing,
    showIndexModal,
    masterData,
    mainData,
    locationType,
    pageLoading,
    enablePageLoading,
    disablePageLoading,
    file,
    generateLoading,
    setData,
    setFile,
    setMainData,
    setLocationType,
    setMasterData,
    handleCreateItem,
    toggleEditItem,
    onDelete,
    toggleModal,
    handleEditItem,
    setIsEditing,
    onSubmitEdit,

  } = useDataSharing({ intl, dataSharingApi });

  useEffect(() => {
    const fetchData = async () => {
      enablePageLoading()
      try {
        const [
          indexTypes,
          user,
          timeTypes,
          categories,
          methods,
          locationTypes
        ] = await Promise.all([
          dataSharingApi.getIndexTypes(),
          dataSharingApi.getUsers(),
          dataSharingApi.getTimeTypes(),
          dataSharingApi.getCategories(),
          dataSharingApi.getAllMethods(),
          dataSharingApi.getLocationTypes()
        ])
        if (indexTypes.success && user.success && timeTypes.success && categories.success && locationTypes.success) {
          setMasterData(prevData => ({
            ...prevData,
            indexTypes: indexTypes.data,
            user: user.data,
            timeTypes: timeTypes.data,
            categories: categories.data,
            methods: methods.data,
            locationTypes: locationTypes.data
          }))
        }
      } catch (error) {

      } finally {
        disablePageLoading();
      }

    }

    fetchData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        enablePageLoading();
        const id = parseInt(props.match.params.id);
        const res = await dataSharingApi.getRequest(id);
        if (res.success) {
          setMainData(res.data);

          if (res.data.template_file) {
            const BOM = "\uFEFF";
            const csvContent = new Buffer.from(res.data.template_file.data).toString();
            const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            setFile({
              name: res.data.name_template_file,
              url
            });
          }
          setLocationType(res.data.location_type_id)
          if (res.data.data_sharing_request_indicator && res.data.data_sharing_request_indicator.length > 0) {
            let newData = {};
            for (let item of res.data.data_sharing_request_indicator) {
              const dataItem = {
                category: item.indicator.indicator_group.indicator_category_id,
                frequency: item.frequency,
                id: item.id,
                indicator: language === "en" ? `${item.indicator.indicator_group.name_en} >> ${item.indicator.name}` : `${item.indicator.indicator_group.name_vi} >> ${item.indicator.name_vi}`,
                indicatorId: `${item.indicator.indicator_group_id}-${item.indicator.id}`,
                method: item.data_sharing_request_method_id,
                modern: item.device_modern,
                name: item.device_name,
                origin: item.device_origin,
                provider: item.device_provider,
                time_type: item.data_sharing_request_time_type_id,
                unit: item.unit
              }
              newData[item.id] = { id: item.id, ...dataItem };
              setData(newData)
            }
          }
        }
      } catch (error) {
        // history.push("/not-found")
      } finally {
        disablePageLoading();
      }
    }
    fetchData()
  }, [props.match.params.id])

  const handleSubmitForm = () => {
    formik.handleSubmit();
  }

  const formik = useFormik({
    initialValues: {
      ...DataSharingEditInitialValues,
      data_key: mainData.data_key || DummyDataKey,
      address: mainData.address,
      latitude: mainData.latitude,
      longtitude: mainData.longtitude,
      fullname: mainData.fullname,
      title: mainData.title,
      organization_name: mainData.organization_name,
      organization_address: mainData.organization_address,
      email: mainData.email,
      phone: mainData.phone,
      comment: mainData.comment,
      user: mainData.user_id
    },
    enableReinitialize: true,
    validationSchema: EditDataSharingSchema({ intl, locationType }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!Object.keys(data).length) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.MISSING_INDEX_WARNING',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return
      }
      const id = parseInt(props.match.params.id);
      if (id) {
        const postData = {
          status_id: 1,
          user_id: values.user,
          email: values.email,
          phone: values.phone,
          fullname: values.fullname,
          location_type_id: locationType,
          title: values.title,
          organization_name: values.organization_name,
          organization_address: values.organization_address,
          latitude: locationType === 1 ? values.latitude : undefined,
          longtitude: locationType === 1 ? values.longtitude : undefined,
          address: locationType !== 1 ? values.address : undefined,
          comment: values.comment,
          indicator_info: Object.values(data).map(item => ({
            indicator_id: item.indicatorId.split("-")[1],
            data_sharing_request_method_id: item.method,
            unit: item.unit,
            frequency: item.frequency,
            data_sharing_request_time_type_id: item.time_type,
            device_name: item.name,
            device_modern: item.modern,
            device_origin: item.origin,
            device_provider: item.provider
          }))
        }
        onSubmitEdit(id, postData);
      }
    },
  });

  const handleLocationTypeChange = (event) => {
    setLocationType(parseInt(event.target.value));
    if (event.target.value === 1) {
      formik.setErrors("address", "");
      formik.setFieldValue("address", mainData.address)
    } else {
      formik.setErrors({
        latitude: "",
        longtitude: ""
      });
      formik.setValues({
        ...formik.values,
        latitude: mainData.latitude,
        longtitude: mainData.longtitude
      })
    }
  };

  return (
    <Container className="enduser-page">
      {
        pageLoading ?
          <Box textAlign="center">
            <CircularProgress />
          </Box> :
          <div className="main-content data-sharing enduser">
            <h2 className="main-title">
              {
                mainData.status && mainData.status.id !== 1 ?
                  <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.PAGE_TITLE_VIEW" /> :
                  <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.PAGE_TITLE_EDIT" />
              }
            </h2>
            <form>
              <Box mb={3}>
                <SharedInputField
                  name="title"
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.REQUEST_TITLE_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.REQUEST_TITLE_HINT' })}
                  {...formik.getFieldProps("title")}
                  className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
                  required={mainData.status && mainData.status.id === 1}
                  disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
                  errorMsg={formik.touched["title"] && formik.errors["title"]}
                />
              </Box>

              <GeneralInformation
                mainData={mainData}
                toggleEditItem={toggleEditItem}
                data={data}
                masterData={masterData}
                toggleModal={toggleModal}
                onDelete={onDelete}
              />

              <LocationInformation
                mainData={mainData}
                locationType={locationType}
                handleLocationTypeChange={handleLocationTypeChange}
                formik={formik}
                language={language}
                intl={intl}
                clsx={clsx}
                masterData={masterData}
              />

              <ContactInformation
                formik={formik}
                clsx={clsx}
                intl={intl}
                mainData={mainData}
              />

              <Box mb={3}>
                <SharedInputField
                  name="comment"
                  disabled
                  className={'disabled-field'}
                  label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.COMMENT_LABEL' })}
                  type="text"
                  {...formik.getFieldProps("comment")}
                  multiline
                  rows={4}

                />
              </Box>
              <Box mb={3}>
                <TemplateFileInformation
                  generateLoading={generateLoading}
                  language={language}
                  file={file}
                  formik={formik}
                  mainData={mainData}
                  setFile={setFile}
                />
              </Box>

              <IndexModal
                editing={isEditing}
                open={showIndexModal}
                handleClose={() => {
                  toggleModal();
                  setIsEditing(false);
                }}
                id={activeId}
                data={activeItem}
                handleCreate={handleCreateItem}
                handleEdit={handleEditItem}
                categories={masterData.categories}
                timeTypes={masterData.timeTypes}
                methods={masterData.methods}
                activeData={Object.values(data)}
                viewMode={mainData.status && mainData.status.id !== 1}
              />

              <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {
                    mainData.status && mainData.status.id === 1 &&
                    <Button
                      className="custom-button"
                      // type="submit"
                      onClick={handleSubmitForm}
                      disabled={
                        formik.isSubmitting ||
                        !formik.isValid
                      }
                    >
                      <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
                      {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
                    </Button>
                  }
                  <Box ml={mainData.status && mainData.status.id === 1 ? 3 : 0}>
                    <Link to='/data-sharing-request'>
                      <Button
                        className="custom-button light-button"
                        type="button"
                      >
                        <FormattedMessage id="CLIENT.GENERAL.ACTION_CANCEL" />
                      </Button>
                    </Link>
                  </Box>
                </Box>
                {
                  mainData.status && mainData.status.id === 1 &&
                  <Box className="note-msg">
                    *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
                  </Box>
                }
              </Box>
            </form>
          </div>
      }
    </Container>
  );
}

export default injectIntl(DataSharingEdit);