import "./index.css";
import { useState } from "react";
import {
  Link
} from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { logOut } from "../../../../../redux/actions";
import { injectIntl } from "react-intl";
import DefaultUser from "../../../../../../assets/images/user.png"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const User = (props) => {
  const {
    intl
  } = props;
  const { user } = useSelector(
    ({ user }) => ({
      user: user.user,
      role: user.role
    }),
    shallowEqual
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="user-header-enduser">
      <div className="name" onClick={handleClick}>
        <div className="avatar">
          <img src={user.avatar ? `${process.env.REACT_APP_API_URL}/${user.avatar}` : DefaultUser} alt="" />
        </div>
      </div>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>
          <Link to="/profile/edit/" className="profile-menu-item">
            {
              intl.formatMessage({
                id: "CLIENT.PROFILE.PAGE_TITLE"
              })
            }
          </Link>
        </MenuItem> */}

        <MenuItem onClick={handleClose}>
          <Link
            to="/data-sharing-request"
            className="profile-menu-item"
          >
            {
              intl.formatMessage({
                id: "CLIENT.DATA_SHARING_REQUEST.PAGE_TITLE"
              })
            }
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Link
            to="/data-files"
            className="profile-menu-item"
          >
            {
              intl.formatMessage({
                id: "CLIENT.DATA_FILES.PAGE_TITLE"
              })
            }
          </Link>
        </MenuItem>

        <MenuItem onClick={() => props.logOut()}>
          <Link to="/" className="profile-menu-item">
            {
              intl.formatMessage({
                id: "CLIENT.GENERAL.SIGN_OUT"
              })
            }
          </Link>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default injectIntl(connect(null, { logOut })(User));