export const USER_LOG_IN = "USER_LOG_IN";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const USER_UPDATE = "USER_UPDATE";
export const RELOAD_DATA = "RELOAD_DATA";
export const USER_ORGANIZATION_SET = "USER_ORGANIZATION_SET";

// chat
export const TOGGLE_CHAT_WINDOW = "TOGGLE_CHAT_WINDOW";
export const SET_CHAT_WINDOWS = "SET_CHAT_WINDOWS";
export const SET_CHAT_ICONS = "SET_CHAT_ICONS";
export const SET_ROOM_ID_LIST = "SET_ROOM_ID_LIST";
export const CHAT_WINDOWS_LOAD = "CHAT_WINDOWS_LOAD";
export const CHAT_WINDOWS_FINISH = "CHAT_WINDOWS_FINISH";
export const ROOM_IDS_FINISH = "ROOM_IDS_FINISH";
export const ROOM_IDS_LOAD = "ROOM_IDS_LOAD";
export const SET_ROOMS_NOTI = "SET_ROOMS_NOTI";
export const SET_ROOMS_INIT_NOTI = "SET_ROOMS_INIT_NOTI";
export const RESET_DATA = "RESET_DATA";
export const SET_END_USER_ROOMS = "SET_END_USER_ROOMS";
export const ADD_WAITING_MESSAGE = "ADD_WAITING_MESSAGE";
export const REMOVE_WAITING_MESSAGE = "REMOVE_WAITING_MESSAGE";

// end user chat
export const SET_END_USER_CHAT_WINDOWS = "SET_END_USER_CHAT_WINDOWS";
export const SET_END_USER_CHAT_ICONS = "SET_END_USER_CHAT_ICONS";
export const SET_END_USER_ROOM_ID_LIST = "SET_END_USER_ROOM_ID_LIST";
export const SET_END_USER_ROOMS_NOTI = "SET_END_USER_ROOMS_NOTI";
export const SET_END_USER_ROOMS_INIT_NOTI = "SET_END_USER_ROOMS_INIT_NOTI";
export const CHAT_END_USER_RESET_DATA = "CHAT_END_USER_RESET_DATA";

// not login end user
export const NOT_LOGIN_SET_END_USER_CHAT_WINDOWS =
  "NOT_LOGIN_SET_END_USER_CHAT_WINDOWS";
export const NOT_LOGIN_SET_END_USER_CHAT_ICONS =
  "NOT_LOGIN_SET_END_USER_CHAT_ICONS";
export const NOT_LOGIN_SET_END_USER_ROOM_ID_LIST =
  "NOT_LOGIN_SET_END_USER_ROOM_ID_LIST";
export const NOT_LOGIN_SET_END_USER_ROOMS_NOTI =
  "NOT_LOGIN_SET_END_USER_ROOMS_NOTI";
export const NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI =
  "NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI";
export const NOT_LOGIN_CHAT_END_USER_RESET_DATA =
  "NOT_LOGIN_CHAT_END_USER_RESET_DATA";

// dashboard
export const SET_DASHBOARD_NAME = "SET_DASHBOARD_NAME";
export const SET_ACTIVE_SUB_ITEM = "SET_ACTIVE_SUB_ITEM";
export const SET_RELOAD_DATA_DASHBOARD = "SET_RELOAD_DATA_DASHBOARD";
export const SET_TOP_MENU = "SET_TOP_MENU";
export const SET_DASHBOARD = "SET_DASHBOARD";

// chart range
export const SET_CHART_RANGE = "SET_CHART_RANGE";
export const REMOVE_CHART_RANGE = "REMOVE_CHART_RANGE";

//sidebar
export const TRIGGER_SIDEBAR = "TRIGGER_SIDEBAR";

//workspace map station panel
export const SET_SELECTED_STATION = "SET_SELECTED_STATION";
export const SET_SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";
export const SET_ANCHOR_POPOVER_MARKER = "SET_ANCHOR_POPOVER_MARKER";
export const SET_HAS_LOADED_ALL_ICONS_TO_MAP =
  "SET_HAS_LOADED_ALL_ICONS_TO_MAP";
export const CLEAR_STATION_PANEL_STATE = "CLEAR_STATION_PANEL_STATE";

//maps
export const MAP_ELLIPSIS = "MAP_ELLIPSIS";
export const MAP_DETAIL_STATION = "MAP_DETAIL_STATION";
export const TOGGLE_DRAWER_TAB = "TOGGLE_DRAWER_TAB";
export const SET_MAP_INSTANCE = "SET_MAP_INSTANCE";
export const CLEAR_MAP_STATE = "CLEAR_MAP_STATE";
