import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";

const FormButtons = ({ pageData }) => {
  const { formik, loading } = pageData;

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="register-form-buttons"
      >
        <Box mr={2}>
          <Button
            type="submit"
            className="custom-button form-button"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
          >
            <FormattedMessage id="CLIENT.SIGN_UP.SUBMIT" />
            {loading && (
              <CircularProgress
                color="inherit"
                size={16}
                style={{ marginLeft: "5px" }}
              />
            )}
          </Button>
        </Box>

        <Box ml={2}>
          <Link to="/auth/login">
            <Button className="custom-button light-button form-button">
              <FormattedMessage id="CLIENT.SIGN_UP.CANCEL" />
            </Button>
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};

export default FormButtons;
