import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../app/shared/hooks";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';

const getValueClass = (value) => {
  if (["new", "mới"].includes(value.toLowerCase())) return (
    <Chip
      size="small"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "orange"
      }}
    />
  );
  if (["approved", "đã chấp thuận", "đã chấp nhận"].includes(value.toLowerCase())) return (
    <Chip
      size="small"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "rgb(74, 174, 32)"
      }}
    />
  );
  return (
    <Chip
      size="small"
      color="primary"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "#f64e60"
      }}
    />
  );
}

const EndUserDataSharingRequestTableContent = () => {
  const [language] = useLanguage();
  return {
    columns: [
      {
        field: "title",
        headerName: (
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_TITLE" />
        ),
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        field: `location_type.name_${language}`,
        headerName: (
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_LOCATION_TYPE" />
        ),
        align: "left",
        sortable: true,
        width: 150,
      },
      {
        field: `status.name_${language}`,
        headerName: (
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_STATUS" />
        ),
        align: "left",
        sortable: false,
        width: 150,
        renderCell: (params) => getValueClass(params)
      },
    ],
    rows: [
      {
        field: "title",
        type: "text",
        default: "",
      },
      {
        field: `location_type.name_${language}`,
        type: "text",
        default: "",
      },
      {
        field: `status.name_${language}`,
        type: "text",
        default: "",
      },
    ],
    actions: {
      view: <VisibilityIcon style={{ fontSize: '20px', marginRight: "5px" }} />,
      edit: <EditIcon style={{ fontSize: "20px", marginRight: "5px" }} />,
    },
  };
};
export default EndUserDataSharingRequestTableContent;
