import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ColorPicker from "../../ColorPicker";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";
import { Box, Button } from "@material-ui/core";
import SharedSelectField from "../../SharedSelectField";
import { useLanguage } from "../../../../shared/hooks";
import _ from "lodash";
import { DragIndicator } from "@material-ui/icons";
import { DragDropTable } from "../../DragDropTable";
import SharedInputField from "../../SharedInputField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "12px 16px",
    paddingBottom: 24,
  },
  table: {
    minWidth: 750,
  },
  contentWrapper: {
    padding: "0 24px",
  },
  formControl: {
    width: "100%",
  },
}));

const styles = {
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 0,
    color: "#3f4254",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  footer: {
    marginTop: 24,
    marginBottom: 32,
  },
  button: {
    color: "#B6B6B6",
    cursor: "pointer",
    marginRight: 3,
    marginLeft: 3,
  },
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: "none",
    padding: "5px 0",
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "rgb(236, 236, 236)" : "white",
    border: isDragging ? "1px solid #eee" : "none",
    ...draggableStyle,
  };
};

const DashboardAggregateTable = ({ formik, ...props }) => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [stationList, setStationList] = React.useState({});
  const { intl } = props;

  const [language] = useLanguage();

  const handleChange = (id, event) => {
    const newValues = values.map((item) => {
      if (item.row_id === id) {
        const newItem = { ...item };
        newItem[event.target.name] = event.target.value;
        newItem["error"] = {
          ...newItem.error,
          display_name: newItem["display_name"]
            ? undefined
            : intl.formatMessage({ id: "CLIENT.GENERAL.REQUIRED_FIELD" }),
        };
        return newItem;
      }
      return item;
    });
    setValues(newValues);
  };

  const handleChangeParameter = (id, parameterId) => {
    const newValues = values.map((item, idx) => {
      if (item.row_id === id) {
        const newItem = { ...item };
        newItem["parameter"] = stationList[
          formik.values.station_id
        ]?.items?.find((index) => index.id === parameterId);
        newItem["display_name"] = newItem.parameter.name_vi;
        return newItem;
      }
      return item;
    });
    setValues(newValues);
  };

  const addNew = (e) => {
    e.preventDefault();
    const newValueParameter = {
      row_id: uuidv4(),
      main_color: "#CCCCCC",
      alarm_color: "#FE9200",
      editing: true,
      display_name: null,
      new: true,
    };

    props["onChange"]([...data, newValueParameter]);
  };

  const onApply = (id) => {
    const newValue = values.find((x) => x.row_id === id);
    const newData = [...data].map((item) => {
      if (item.row_id === id) {
        return {
          ...newValue,
          new: false,
          editing: false,
        };
      }
      return item;
    });
    props["onChange"](newData);
  };

  const onCancel = (id) => {
    if (data.some((d) => d.row_id === id && d.new === true)) {
      const newData = data.filter((d) => d.row_id !== id);
      props["onChange"](newData);
    } else {
      const oldData = data.map((item) => {
        if (item.row_id === id) {
          const oldItem = {
            ...item,
            editing: false,
          };
          return oldItem;
        }
        return item;
      });
      props["onChange"](oldData);
    }
  };

  const toggleEdit = (id) => {
    const newData = data.map((item) => {
      if (item.row_id === id) {
        return {
          ...item,
          editing: true,
        };
      }
      return item;
    });
    props["onChange"](newData);
  };

  const deleteItem = (id) => {
    const newValues = values.filter((item) => item.row_id !== id);
    props["onChange"](newValues);
  };

  React.useEffect(() => {
    setStationList(_.keyBy(props["stations"], "id"));
  }, [props["stations"]]);

  React.useEffect(() => {
    const newValue = data.map((item, index) => {
      if (
        values.some(
          (vl) => vl.row_id === item.row_id && item.editing && vl.editing
        )
      ) {
        return values.find((x) => x.row_id === item.row_id);
      }
      return item;
    });
    setValues(newValue);
  }, [data]);

  React.useEffect(() => {
    if (props["data"]) {
      const dataReceive = [...props["data"]].map((item) => {
        const newItem = { ...item };
        if (!newItem.row_id) {
          newItem.row_id = uuidv4();
        }
        return newItem;
      });
      setData(dataReceive);
    }
  }, [props["data"]]);

  const renderValueLine = (item, provided, snapshot) => {
    let listParameter = stationList[formik.values.station_id]?.items;
    let canApply = !item?.error?.display_name;
    return (
      <div
        className="aggregate-data-table-row"
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <div>
          <SharedSelectField
            className="text-normal"
            disableClearable
            name="params"
            disabled={!item?.editing}
            placeholder={intl.formatMessage({
              id: "CLIENT.FTP_SHARE_ACTIONS.SELECT_PARAMETER",
            })}
            options={(listParameter || [])?.map((index) => {
              return {
                label: index?.[language === "en" ? "name" : `name_${language}`],
                value: index,
                id: index?.id,
                disabled: values?.some(
                  (vl) =>
                    vl?.parameter?.id === index?.id &&
                    vl?.row_id !== item?.row_id
                ),
              };
            })}
            defaultValue={stationList[formik.values.station_id]?.items?.find(
              (index) => index.id === item?.parameter?.id
            )}
            onChange={(name, value) =>
              handleChangeParameter(item.row_id, value?.id)
            }
          />
        </div>
        <div>
          <SharedInputField
            type="text"
            name="display_name"
            disabled={!item?.editing}
            placeholder={intl.formatMessage({
              id: "CLIENT.PARAMETERS_ACTIONS.DISPLAY_NAME_DESC",
            })}
            value={item?.display_name}
            onChange={(event) =>
              handleChange(item.row_id, {
                target: { name: "display_name", value: event.target.value },
              })
            }
            errorMsg={item?.error?.display_name}
          />
        </div>
        <div>
          <ColorPicker
            style={{ height: "47px" }}
            color={item?.main_color}
            onChange={(newColor) => {
              handleChange(item.row_id, {
                target: { name: "main_color", value: newColor },
              });
            }}
            disabled={!item?.editing}
          />
        </div>
        <div
          style={{
            marginTop: "12px",
          }}
        >
          {item?.editing && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props["pickColors"] && (
                <span
                  style={
                    canApply
                      ? {
                          color: "#008a00",
                          cursor: "pointer",
                          marginRight: 3,
                          marginLeft: 3,
                        }
                      : {
                          color: "#B6B6B6",
                          pointerEvents: "none",
                          marginRight: 3,
                          marginLeft: 3,
                        }
                  }
                  onClick={() => onApply(item.row_id)}
                >
                  <CheckIcon />
                </span>
              )}

              {props["pickColors"] && (
                <span
                  style={{
                    color: "#db3700",
                    cursor: "pointer",
                    marginRight: 3,
                    marginLeft: 3,
                  }}
                  onClick={() => onCancel(item.row_id)}
                >
                  <CloseIcon />
                </span>
              )}

              {!props["pickColors"] && (
                <span
                  style={{
                    ...styles.button,
                    marginRight: props["pickColors"] ? 12 : 0,
                  }}
                >
                  <DeleteIcon onClick={() => deleteItem(item.row_id)} />
                </span>
              )}
              <span {...provided.dragHandleProps}>
                <DragIndicator />
              </span>
            </div>
          )}
          {!item?.editing && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={styles.button}>
                <EditIcon onClick={() => toggleEdit(item.row_id)} />
              </span>

              <span style={styles.button}>
                <DeleteIcon onClick={() => deleteItem(item.row_id)} />
              </span>

              <span {...provided.dragHandleProps}>
                <DragIndicator />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const headerForDragDrop = () => (
    <div className="aggregate-data-table-header">
      <div className="aggregate-data-table-header-cell">
        <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
      </div>
      <div className="aggregate-data-table-header-cell">
        <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.DISPLAY_NAME" />
      </div>
      <div className="aggregate-data-table-header-cell">
        <FormattedMessage id="CLIENT.DASHBOARD.MAIN_COLOR_LABEL" />
      </div>

      <div
        className="aggregate-data-table-header-cell"
        style={{
          textAlign: "center",
        }}
      >
        <FormattedMessage id="GENERAL.ACTIONS" />
      </div>
    </div>
  );

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const newValues = [...values];
    const [removed] = newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, removed);
    newValues.forEach((item, index) => {
      item.order_number = index;
    });
    setValues(newValues);
    props["onChange"](newValues);
  };

  return (
    <div className="threshold-editor">
      {!props.viewOnly && (
        <Box className="pick-station-component">
          <div className="pick-station-title">
            <p className="required">*</p>
            <FormattedMessage id="CLIENT.DASHBOARD.STATION" />
          </div>
          <SharedSelectField
            className="pick-station-select"
            name="station_id"
            disableClearable
            options={props?.stations?.map((item) => ({
              label: item?.label,
              value: item,
              id: item?.id,
            }))}
            placeholder={intl.formatMessage({
              id: "CLIENT.SYNC_DATA.SELECT.STATION",
            })}
            disabled={data.length > 0}
            defaultValue={stationList[formik.values?.station_id]}
            onChange={(name, value) => {
              formik.setFieldValue("station_id", value?.id);
            }}
          />
          <Box mt={1}>
            <Button
              className="custom-button light-button"
              disabled={!formik.values?.station_id}
              onClick={addNew}
            >
              <FormattedMessage id="CLIENT.DASHBOARD.AGGREGATE_TABLE.NEW_PARAMETER" />
            </Button>
          </Box>
        </Box>
      )}

      <DragDropTable
        visible={values?.length > 0}
        containerClassName="aggregate-data-table"
        rows={values}
        rowKeyPropertyName="row_id"
        headerComponent={headerForDragDrop()}
        onDragEnd={onDragEnd}
        onRenderRow={renderValueLine}
      />
    </div>
  );
};

export default DashboardAggregateTable;
