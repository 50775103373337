import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Zoom,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/styles";
import { triggerSidebar } from "app/redux/actions/sidebar.action";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

export default function RenderMenu({
  items = [],
  open = false,
  anchorEl = null,
  deviceType = "",
  handleMenuClick = () => {},
  handleMenuClose = () => {},
}) {
  switch (deviceType) {
    case "desktop":
      return (
        <>
          <MoreVertIcon
            className="navi-icon"
            style={commonStyle.moreVertIcon}
            onClick={handleMenuClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            TransitionComponent={Zoom}
          >
            {items.map((item) => (
              <RenderItem
                key={item.messageId}
                deviceType={deviceType}
                messageId={item.messageId}
                handleAction={item.handleAction}
                visible={item.visible}
                divider={item.divider}
              />
            ))}
          </Menu>
        </>
      );

    default: //drawer
      return (
        <>
          <MoreVertIcon
            className="navi-icon"
            style={commonStyle.moreVertIcon}
            onClick={handleMenuClick}
          />
          <Drawer
            anchor="bottom"
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              style: drawerStyle.paperProps,
            }}
          >
            <div role="presentation" onClick={handleMenuClose}>
              <List style={drawerStyle.list}>
                {items.map((item) => (
                  <RenderItem
                    key={item.messageId}
                    visible={item.visible}
                    deviceType={deviceType}
                    divider={item.divider}
                    disabled={item.disabled}
                    Icon={item.Icon}
                    iconColor={item.iconColor}
                    messageId={item.messageId}
                    handleAction={item.handleAction}
                  />
                ))}
              </List>
            </div>
          </Drawer>
        </>
      );
  }
}

function RenderItem({
  visible = false,
  deviceType = "desktop",
  divider = false,
  disabled = false,
  messageId = "",
  Icon = () => {},
  iconColor = "",
  handleAction = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onListItemClick = useCallback(
    (e) => {
      const isVisibleSidebar =
        /(move|rename|delete|setting|add.*?(dashboard|map))/i;
      if (!isVisibleSidebar.test(messageId)) dispatch(triggerSidebar());
      handleAction(e);
    },
    [dispatch]
  );

  if (!visible) return null;

  switch (deviceType) {
    case "desktop":
      return (
        <>
          {divider ? <Divider style={commonStyle.divider} /> : null}
          <MenuItem
            style={menuStyle.menuItem}
            className="sidebar-action-item"
            onClick={handleAction}
            disabled={disabled}
          >
            <FormattedMessage id={messageId} />
          </MenuItem>
        </>
      );

    default:
      return (
        <>
          {divider ? <Divider style={commonStyle.divider} /> : null}
          <ListItem
            button
            onClick={onListItemClick}
            style={drawerStyle.listItem}
            disabled={disabled}
          >
            {iconColor ? (
              <ListItemIcon classes={{ root: classes.root }}>
                <Icon />
              </ListItemIcon>
            ) : null}

            <ListItemText
              style={{ color: iconColor }}
              classes={{ primary: classes.primary }}
              primary={<FormattedMessage id={messageId} />}
            />
          </ListItem>
        </>
      );
  }
}

const useStyles = makeStyles({
  primary: {
    fontSize: "14px !important",
    fontWeight: "bold",
  },
  root: {
    minWidth: "auto",
    marginRight: "12px",
  },
});

const drawerStyle = {
  paperProps: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    paddingBottom: "16px",
  },
  list: {
    paddingTop: "8px",
    paddingBottom: "0px",
  },
  listItem: {
    padding: "12px 24px",
  },
  listItemText: {
    fontSize: "14px",
  },
};

const menuStyle = {
  menuItem: {
    fontSize: "14px",
    padding: "4px 20px",
    minWidth: "200px",
  },
};

const commonStyle = {
  moreVertIcon: {
    fontSize: 20,
    color: "#ffffff",
  },
  divider: {
    margin: "5px 0",
  },
};
