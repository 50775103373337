export const INITIAL_POSITION = [16.4533875, 107.5420937];

export const FILE_TYPES = {
  geojson: {
    id: 1,
    label: "GeoJson",
    value: "geojson",
    extensions: [".geojson", ".json"],
  },
  kml: {
    id: 2,
    label: "KML",
    value: "kml",
    extensions: [".kml", ".kmz"],
  },
  shapefile: {
    id: 3,
    label: "Shapefile",
    value: "shapefile",
    extensions: [".zip"],
  },
};
