import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ColorPicker from "app/shared/components/ColorPicker";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { Button } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import ColorPoint from "app/shared/components/ColorPoint/color-point.component";
import { DragDropTable } from "app/shared/components/DragDropTable";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedInputField from "app/shared/components/SharedInputField";
import { useLanguage } from "app/shared/hooks";
import useDataAlarm from "app/shared/hooks/DataAlarm/data-alarm.hook";
import "./styles.scss";
import { EmptyColorPoint } from "app/shared/components/ColorPoint";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "12px 16px",
    paddingBottom: 24,
  },
  table: {
    minWidth: 750,
  },
  contentWrapper: {
    padding: "0 24px",
  },
  formControl: {
    width: "100%",
  },
}));

const styles = {
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 0,
    color: "#3f4254",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  footer: {
    marginTop: 24,
    marginBottom: 32,
  },
  button: {
    color: "#B6B6B6",
    cursor: "pointer",
    marginRight: 3,
    marginLeft: 3,
  },
};
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => {
  return {
    userSelect: "none",
    padding: "5px 0",
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "rgb(236, 236, 236)" : "white",
    border: isDragging ? "1px solid #eee" : "none",
    ...draggableStyle,
  };
};
const DashboardValueList = (props) => {
  const [data, setData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [language] = useLanguage();
  const { intl } = props;
  const { isLoadingDataAlarm, getDataAlarm } = useDataAlarm();

  const handleChange = (id, event) => {
    const newValues = values.map((item, idx) => {
      if (item.row_id === id) {
        const newItem = { ...item };
        newItem[event.target.name] = event.target.value;
        newItem["error"] = {
          ...newItem.error,
          display_name: newItem["display_name"]
            ? undefined
            : intl.formatMessage({ id: "CLIENT.GENERAL.REQUIRED_FIELD" }),
        };
        return newItem;
      }
      return item;
    });
    // const newData = [...data];

    // newData[index][event.target.name] = event.target.value;

    // newValues[index][event.target.name] = event.target.value;
    setValues(newValues);
    // setData(newData);

    // props['onChange'](newData);
  };

  const handleChangeParameter = async (
    id,
    parameterId,
    stationId,
    display_name
  ) => {
    const dataAlarm = await getDataAlarm(parameterId);

    const newValues = values.map((item, idx) => {
      if (item.row_id === id && display_name) {
        return {
          ...item,
          station_id: stationId,
          parameter_id: parameterId,
          display_name,
          parameter: {
            ...item.parameter,
            data_alarm: dataAlarm,
          },
        };
      }
      return item;
    });

    setValues(newValues);
  };

  const addNew = (e) => {
    e.preventDefault();

    const newValueParameter = {
      id: "",
      row_id: uuidv4(),
      parameter_id: -1,
      station_id: null,
      main_color: "#CCCCCC",
      alarm_color: "#FE9200",
      editing: true,
      new: true,
    };

    props["onChange"]([...data, newValueParameter]);

    // setValues(pre => ([...pre, newValueParameter]));

    // const newData = [...data, newValueParameter];
    // const newValues = [...values, newValueParameter];

    // setValues(newValues);
    // setData(newData);
    // props['onChange'](newData);
  };

  const onCancel = (id) => {
    // if (data.some(d => d.row_id === id)) {
    //   const oldData = data.map((item) => {
    //     if (item.row_id === id) {
    //       const oldItem = { ...item };
    //       oldItem.editing = false;
    //       return oldItem;
    //     }

    //     return item;
    //   })
    //   props['onChange'](oldData);
    // }
    // else {
    //   const oldData = values.filter(item => item.row_id !== id);
    //   setValues(oldData);
    // }
    if (data.some((d) => d.row_id === id && d.new === true)) {
      const newData = data.filter((d) => d.row_id !== id);
      props["onChange"](newData);
    } else {
      const oldData = data.map((item) => {
        if (item.row_id === id) {
          const oldItem = {
            ...item,
            editing: false,
          };
          return oldItem;
        }

        return item;
      });
      props["onChange"](oldData);
    }
  };

  const onApply = (id) => {
    const newValue = values.find((x) => x.row_id === id);
    const newData = [...data].map((item) => {
      if (item.row_id === id) {
        return {
          ...newValue,
          new: false,
          editing: false,
        };
      }
      return item;
    });
    props["onChange"](newData);
  };

  const toggleEdit = (id) => {
    // const newValues = [...values];
    // newValues[index]['editing'] = !newValues[index]['editing'];

    // setData(newValues);
    const newData = data.map((item) => {
      if (item.row_id === id) {
        return {
          ...item,
          editing: true,
        };
      }
      return item;
    });
    props["onChange"](newData);
  };

  // const confirmValueChange = (index, confirm) => {
  //   if (confirm) {
  //     const newData = values.map((item, idx) => {
  //       if (idx === index) {
  //         const newItem = { ...item };
  //         newItem.editing = false;
  //         return newItem;
  //       }
  //       return item;
  //     });
  //     props['onChange'](newData);
  //     setData(newData)
  //   }
  //   else {
  //     const oldData = data.map((item, idx) => {
  //       if (idx === index) {
  //         const newItem = { ...item };
  //         newItem.editing = false;
  //         return newItem;
  //       }
  //       return item;
  //     });
  //     setValues(oldData)
  //   }
  // }

  const deleteItem = (id) => {
    // var newData = [...data].filter((item, i) => i !== index);

    // var newValues = [...values].filter((item, i) => i !== index);

    // var newListParameterDelete = (configData.listParameterDelete && [...configData.listParameterDelete]) || [];
    // if (!newListParameterDelete.some(item => item?.id === data[index]?.id))
    //   newListParameterDelete.push(data[index].id);

    // setConfigData(pre => ({ ...pre, listParameterDelete: newListParameterDelete }))

    // setValues(newValues);
    // setData(newData);
    // props['onChange'](newData);
    // props['onDelete'](newListParameterDelete);
    const newValues = values.filter((item) => item.row_id !== id);

    const indexDeleted = data.findIndex((item) => item.row_id === id);
    if (indexDeleted > -1) {
      let newListDelete = [...props["params_delete"]];
      if (
        newListDelete.some((item) => item === data[indexDeleted].id) === false
      ) {
        props["onDelete"]([...newListDelete, data[indexDeleted].id]);
      }
    }

    props["onChange"](newValues);
  };
  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const newValues = [...values];
    const [removed] = newValues.splice(source.index, 1);
    newValues.splice(destination.index, 0, removed);
    newValues.forEach((item, index) => {
      item.order_number = index;
    });
    setValues(newValues);
    props["onChange"](newValues);
  };
  const handleChangeSelect = (id, event) => {
    const newValues = values.map((item) => {
      if (item.row_id === id) {
        const newItem = { ...item };
        newItem[event.target.name] = event.target.value;
        newItem["error"] = {
          ...newItem.error,
          display_name: newItem["display_name"]
            ? undefined
            : intl.formatMessage({ id: "CLIENT.GENERAL.REQUIRED_FIELD" }),
        };
        return newItem;
      }
      return item;
    });
    setValues(newValues);
  };
  React.useEffect(() => {
    if (props["data"]) {
      const dataReceive = [...props["data"]].map((item) => {
        const newItem = { ...item };
        if (!newItem.row_id) {
          newItem.row_id = uuidv4();
        }
        if (!newItem?.display_name) {
          newItem["display_name"] =
            language === "en"
              ? item?.parameter?.name
              : item?.parameter?.name_vi;
        }

        return newItem;
      });
      dataReceive?.sort(function (a, b) {
        return a.order_number - b.order_number;
      });
      setData(dataReceive);
    }
  }, [props["data"]]);
  React.useEffect(() => {
    const newValue = data.map((item, index) => {
      if (
        values.some(
          (vl) => vl.row_id === item.row_id && item.editing && vl.editing
        )
      ) {
        return values.find((x) => x.row_id === item.row_id);
      }
      return item;
    });
    setValues(newValue);
  }, [data]);
  const renderValueLine = (item, provided, snapshot) => {
    let canApply = !item?.error?.display_name;

    return (
      <div
        key={item.row_id}
        className="data-table-row"
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <div>
          {/* <SharedSelectField
            className="text-normal"
            disableClearable
            name="parameter_id"
            disabled={!item?.editing}
            options={[{
              value: -1,
              id: -1,
              label: "-- Select Parameter --",
              disabled: true,
            }].concat(props['parameters']?.map(parameter => ({
              id: parameter.id,
              value: parameter.id,
              label: language === "en" ? parameter.name : parameter.name_vi
            })))?.map(parameter => ({
              id: parameter?.id,
              label: parameter?.label,
              value: parameter?.id,
              disabled: parameter?.value === -1
                || values.some(x => x.parameter_id === parseInt(parameter?.id))
            }))}
            defaultValue={parseInt(item?.parameter_id || 0)}
            onChange={(name, value) => handleChange(index, {
              target: { name: 'parameter_id', value: value },
            })}
          /> */}
          <SelectParamsByStaionDropdown
            data={(props.parameters || []).map((row) => ({
              ...row,
              items: row.items.map((item) => ({
                ...item,
                disabled: values.some((x) => x.parameter_id === item.id),
              })),
            }))}
            value={{
              parameterId: item["parameter_id"],
              stationId: item["station_id"],
            }}
            disabled={!item?.editing || isLoadingDataAlarm}
            onChange={({ stationId, parameterId, display_name }) => {
              handleChangeParameter(
                item.row_id,
                parameterId,
                stationId,
                display_name
              );
            }}
          />
        </div>
        <SharedInputField
          type="text"
          name="display_name"
          disabled={!item?.editing}
          placeholder={intl.formatMessage({
            id: "CLIENT.PARAMETERS_ACTIONS.DISPLAY_NAME",
          })}
          value={item?.display_name}
          onChange={(event) =>
            handleChangeSelect(item.row_id, {
              target: { name: "display_name", value: event.target.value },
            })
          }
          errorMsg={item?.error?.display_name}
        />
        {props["pickColors"] && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <ColorPicker
              style={{ borderRadius: "50%", height: "30px", width: "30px" }}
              color={item?.main_color}
              onChange={(newColor) => {
                handleChange(item.row_id, {
                  target: { name: "main_color", value: newColor },
                });
              }}
              disabled={!item?.editing}
            />
          </div>
        )}

        {props["pickColors"] && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gridGap: "5px",
              minHeight: "38px",
              alignItems: "center",
            }}
          >
            {item?.parameter?.data_alarm?.length > 0 ? (
              item?.parameter?.data_alarm?.map((alarm) => (
                <ColorPoint
                  key={item?.parameter?.data_alarm?.id || uuidv4()}
                  color={alarm?.alarm_color}
                  defaultColor="#fe9200"
                  tooltip={alarm[`name_${language}`]}
                  size="small"
                />
              ))
            ) : (
              <EmptyColorPoint />
            )}
          </div>
        )}

        {!props.viewOnly && (
          <div style={{ textAlign: "center" }}>
            {item?.editing && (
              <>
                {props["pickColors"] && (
                  <span
                    style={
                      canApply
                        ? {
                            color: "#008a00",
                            cursor: "pointer",
                            marginRight: 3,
                            marginLeft: 3,
                          }
                        : {
                            color: "#B6B6B6",
                            pointerEvents: "none",
                            marginRight: 3,
                            marginLeft: 3,
                          }
                    }
                    onClick={() => onApply(item.row_id)}
                  >
                    <CheckIcon />
                  </span>
                )}

                {props["pickColors"] && (
                  <span
                    style={{
                      color: "#db3700",
                      cursor: "pointer",
                      marginRight: 3,
                      marginLeft: 3,
                    }}
                    onClick={() => onCancel(item.row_id)}
                  >
                    <CloseIcon />
                  </span>
                )}

                {/* {!props['pickColors'] && (
                  <span
                    style={{
                      ...styles.button,
                      marginRight: props['pickColors'] ? 12 : 0,
                    }}
                  >
                    <DeleteIcon onClick={() => deleteItem(index)} />
                  </span>
                )} */}
              </>
            )}

            {!item?.editing && (
              <>
                <span style={styles.button}>
                  <EditIcon onClick={() => toggleEdit(item.row_id)} />
                </span>

                <span style={styles.button}>
                  <DeleteIcon onClick={() => deleteItem(item.row_id)} />
                </span>
              </>
            )}
            <span {...provided.dragHandleProps}>
              <DragIndicator />
            </span>
          </div>
        )}
      </div>
    );
  };
  const rederHeaderValueLine = () => {
    return (
      <div className="value-list-parameter-header">
        <div>
          <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
        </div>
        <div>
          <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.DISPLAY_NAME" />
        </div>

        {props["pickColors"] && (
          <div style={valueListStyle.mainColorLabel}>
            <FormattedMessage id="CLIENT.DASHBOARD.MAIN_COLOR_LABEL" />
          </div>
        )}
        {props["pickColors"] && (
          <div>
            <FormattedMessage id="CLIENT.DASHBOARD.ALARM_COLOR_LABEL" />
          </div>
        )}
        {!props.viewOnly && (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <FormattedMessage id="GENERAL.ACTIONS" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="threshold-editor mt-6">
      {!props.viewOnly && (
        <Button className="custom-button light-button" onClick={addNew}>
          <FormattedMessage id="CLIENT.DASHBOARD.NEW_VALUE" />
        </Button>
      )}

      {values?.length > 0 && (
        <DragDropTable
          visible={values?.length > 0}
          containerClassName="aggregate-data-table"
          rows={values}
          rowKeyPropertyName="row_id"
          headerComponent={rederHeaderValueLine()}
          onDragEnd={onDragEnd}
          onRenderRow={renderValueLine}
        />
      )}
    </div>
  );
};

export default DashboardValueList;

const valueListStyle = {
  mainColorLabel: {
    textAlign: "center",
  },
};
