/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Container, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataFileAddInitialValues } from 'app/pages/DataFiles/constants';
import useDataFile from 'app/pages/DataFiles/hooks/useDataFile';
import { useFormik } from 'formik';
import Papa from "papaparse";
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import * as Yup from "yup";
import dataFileApi from '../../../api/dataFileApi';
import SharedSelectField from '../../../shared/components/SharedSelectField';

function DataFilesAdd(props) {
  const { intl } = props;

  const {
    loading,
    data,
    files,
    postFiles,
    fileInputEl,
    templateFile,
    setFiles,
    setData,
    setPostFiles,
    enableLoading,
    disableLoading,
    setTemplateFile,
  } = useDataFile({ dataFileApi });

  const { userId } = useSelector(
    ({ user }) => ({
      userId: user.user?.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    const fetchData = async () => {
      const users = await dataFileApi.getUsers();
      if (users.success) {
        setData(preData => ({
          ...preData,
          users: users.data,
        }))
      }
    }
    fetchData();
  }, []);

  const onChangeFiles = (e) => {
    let newFiles = !files ? {} : { ...files };
    let _files = !postFiles ? {} : { ...postFiles };
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = new File([e.target.files[i]], e.target.files[i].name, { type: e.target.files[i].type });
        let src = URL.createObjectURL(file);
        file.src = src;
        file.isNew = true;
        newFiles[Object.keys(newFiles).length + i] = file
        _files[Object.keys(_files).length + i] = file
      }
    }
    setFiles(newFiles);
    setPostFiles(_files);
  };

  const getFileSize = (size) => {
    const units = ['Bytes', 'KB', 'MB', 'GB'];
    let i = 0;
    let newSize = size;

    while (newSize > 900) {
      newSize /= 1024;
      i++;
    }

    return Math.round(newSize * 100) / 100 ? Math.round(newSize * 100) / 100 + ' ' + units[i] : "";
  };

  const trimFileName = (fileName, max = 20) => {
    const name = fileName.split('.')[0];
    const type = fileName.split('.')[1];

    if (name.length > max) {
      return name.substr(0, 10) + '...' + name.substr(-5) + '.' + type;
    }

    return fileName;
  };

  const removeFile = (index) => {
    if (Object.keys(files).length > 0) {
      const newFiles = { ...files };
      const newPostFiles = { ...postFiles };
      delete newFiles[index];
      delete newPostFiles[index];

      setFiles(newFiles);
      setPostFiles(newPostFiles)
    } else {
      setFiles(null);
      setPostFiles({})
    }
  }

  const validateFiles = (files) => {
    if (!templateFile.length) return true;
    for (let file of files) {
      const arr = templateFile[1].filter(item => file?.[1]?.includes(item));
      if (arr?.length !== templateFile[1]?.length) return false;
    }
    return true;
  }

  const parseFiles = () => {
    const filesData = [];
    const tempFiles = Object.values(files)

    return Promise.all(tempFiles.map((file) =>
      new Promise((resolve, reject) =>
        Papa.parse(file, {
          download: true,
          skipEmptyLines: true,
          complete: resolve,  // Resolve each promise
          error: reject,
        }),
      )),
    ).then((results) => {
      results.forEach((result) => {
        filesData.push(result?.data)
      })
      return validateFiles(filesData)
    }).catch((err) => console.log('Something went wrong:', err))
  }

  const schema = Yup.object().shape({
    user_id: Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    data_sharing_request_id: Yup.string().nullable().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
  });


  const formik = useFormik({
    initialValues: {
      ...DataFileAddInitialValues,
      user_id: userId
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      if (!Object.values(postFiles).length) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.DATA_FILES.MISSING_FILES_WARNING',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return;
      }
      const validFile = await parseFiles();
      if (!validFile) {
        swal({
          title: intl.formatMessage({
            id: 'CLIENT.DATA_FILES.INVALID_FILES_WARNING',
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: 'CLIENT.GENERAL.OK_BUTTON',
          })
        })
        return;
      }
      try {
        enableLoading();
        setSubmitting(true);
        const form = new FormData();
        // form.append("user_id", values.user_id);
        form.append("data_sharing_request_id", values.data_sharing_request_id);
        for (let file of Object.values(postFiles)) {
          const _file = new File([file], file.name, { type: file.type });
          if (_file.isNew) {
            delete _file.src;
            delete _file.isNew;
          }
          form.append("data_upload", _file);
        }
        const res = await dataFileApi.addDataFile(form);
        if (res.success) {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.DATA_FILES.ADD_DATA_FILE_SUCCESS_TITLE"
            }),
            text: intl.formatMessage({
              id: "CLIENT.DATA_FILES.ADD_DATA_FILE_SUCCESS_MESSAGE"
            }),
            icon: 'success',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          }).then(() => {
            props.history.push("/data-files")
          })
        } else {
          swal({
            title: intl.formatMessage({
              id: "CLIENT.DATA_FILES.ADD_DATA_FILE_FAILED_TITLE"
            }),
            text: intl.formatMessage({
              id: "CLIENT.DATA_FILES.ADD_DATA_FILE_FAILED_MESSAGE"
            }),
            icon: 'error',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          })
        }
      } catch (error) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_FILES.ADD_DATA_FILE_FAILED_TITLE"
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_FILES.ADD_DATA_FILE_FAILED_MESSAGE"
          }),
          icon: 'error',
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON"
          })
        })
      } finally {
        setSubmitting(false);
        disableLoading();
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const dataSharingRequests = await dataFileApi.getDataSharingRequestByUsers(formik.values["user_id"]);
      if (dataSharingRequests.success) {
        const newDataSharingRequests = [...dataSharingRequests.data].map(item => {
          if (item.template_file) {
            const BOM = "\uFEFF";
            const csvContent = new Buffer.from(item.template_file.data).toString();
            const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob);
            return {
              ...item,
              template_file: {
                url,
                name: item.name_template_file,
              }
            }
          }
          return item
        })
        setData(preData => ({
          ...preData,
          dataSharingRequests: newDataSharingRequests,
        }))
      }
    }
    if (formik.values["user_id"]) {
      fetchData();
    }
  }, [formik.values["user_id"]]);

  useEffect(() => {
    if (formik.values["data_sharing_request_id"]) {
      if (data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file) {
        Papa.parse(data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.url, {
          download: true,
          complete: (results) => {
            setTemplateFile(results?.data);
          }
        })
      } else {
        setTemplateFile([])
      }
    }
  }, [formik.values["data_sharing_request_id"]])

  return (
    <Container className="enduser-page">
      <div className="main-content enduser data-files">
        <h2 className="main-title">
          <FormattedMessage id="CLIENT.DATA_FILES.NEW" />
        </h2>
        <form onSubmit={formik.handleSubmit}>

          <Box mb={3} display="flex" justifyContent="space-between">
            <Box width={data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file ?
              "49%" : "100%"}>
              <SharedSelectField
                className="text-normal"
                label={intl.formatMessage({ id: "CLIENT.DATA_FILES.DATA_SHARING_REQUEST" })}
                placeholder={intl.formatMessage({ id: "CLIENT.DATA_FILES.DATA_SHARING_REQUEST_HINT" })}
                disableClearable
                required
                name="data_sharing_request_id"
                options={data.dataSharingRequests?.map(item => ({
                  id: item.id,
                  value: item.id,
                  label: item.title
                }))}
                defaultValue={formik.values["data_sharing_request_id"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
                errorMsg={formik.touched["data_sharing_request_id"] && formik.errors["data_sharing_request_id"]}
              />
            </Box>
            {
              data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file &&
              <Box width="49%">
                <Box className="form-label">
                  {intl.formatMessage({ id: "CLIENT.DATA_FILES.TEMPLATE" })}
                </Box>
                <Box className="uploaded-files template">
                  <Grid item className="uploaded-file">
                    <div className="uploaded-file-left">
                      <span className="uploaded-file-icon">
                        <DescriptionIcon fontSize="large" />
                      </span>

                      <div className="uploaded-file-text">
                        <h5>
                          {
                            data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.name
                          }
                        </h5>
                      </div>
                    </div>

                    <Box display="flex" className="uploaded-file-right">
                      <a
                        href={data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.url}
                        download={`${data.dataSharingRequests?.find(item => item.id === formik.values["data_sharing_request_id"])?.template_file?.name}.csv`}
                        target="__blank"
                      >
                        <span className="uploaded-file-icon-button">
                          <GetAppIcon />
                        </span>
                      </a>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            }
          </Box>

          <Box mb={3}>
            <div className="form-label">
              <span className="required">*</span> <FormattedMessage id="CLIENT.DATA_FILES.DATA_UPLOAD" />
            </div>
            <Grid container className="uploaded-files">
              {files &&
                <> {
                  Object.keys(files).map((key, index) => (
                    <Grid item className="uploaded-file" key={key}>
                      <div className="uploaded-file-left">
                        <span className="uploaded-file-icon">
                          <DescriptionIcon fontSize="large" />
                        </span>

                        <div className="uploaded-file-text">
                          <h5>
                            {trimFileName(files[key]['name'])}
                          </h5>
                          <p>{getFileSize(files[key]['size'])}</p>
                        </div>
                      </div>

                      <Box display="flex" className="uploaded-file-right">
                        <a href={files[key]["src"]} download={files[key]["name"]} target="__blank" >
                          <span className="uploaded-file-icon-button">
                            <GetAppIcon />
                          </span>
                        </a>
                        <span
                          className="uploaded-file-icon-button"
                          onClick={() => removeFile(key)}
                        >
                          <CloseIcon />
                        </span>
                      </Box>
                    </Grid>
                  ))}
                </>
              }
            </Grid>
            <input
              type="file"
              accept=".csv"
              multiple
              style={{ display: 'none' }}
              ref={fileInputEl}
              onChange={onChangeFiles}
              onClick={(event) => {
                event.target.value = null
              }}
            />
            <Box display="flex" alignItems="flex-end">
              <Box mt={3}>
                <Button
                  className="custom-button light-button"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputEl.current.click();
                  }}
                >
                  <FormattedMessage id="CLIENT.DATA_FILES.UPLOAD_FILES" />
                </Button>
              </Box>
              {/* {
                                files && Object.keys(files)?.length > 0 &&
                                <Box mt={3} ml={3}>
                                    <Button
                                        className="custom-button light-button"
                                        onClick={handleDownloadAll}
                                    >
                                        <FormattedMessage id="CLIENT.DATA_FILES.DOWNLOAD_ALL" />
                                    </Button>
                                </Box>
                              } */}
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Button
                className="custom-button"
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid
                }
              >
                <FormattedMessage id="CLIENT.DATA_FILES.SAVE" />
                {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
              </Button>
              <Box ml={3}>
                <Link to='/data-files'>
                  <Button
                    className="custom-button light-button"
                    type="button"
                  >
                    <FormattedMessage id="CLIENT.DATA_FILES.CANCEL" />
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box className="note-msg">
              *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
            </Box>
          </Box>
        </form>
      </div>
    </Container>
  )
}

export default injectIntl(DataFilesAdd);