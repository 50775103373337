import axios from 'axios';
import queryString from 'query-string';

const CustomAxios = (token) => {
    const axiosIns = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'content-type': 'application/json',
        },
        paramsSerializer: params => queryString.stringify(params),
    });

    axiosIns.interceptors.request.use(async (config) => {
        // request config
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    });

    axiosIns.interceptors.response.use((response) => {
        // response config
        if (response && response.data) {
          return response.data;
        }
      
        return response;
    }, (error) => {
        // Handle errors
        throw error;
    });

    return axiosIns;
}

export default CustomAxios;