import { Box, Grid } from "@material-ui/core";
import { EmptyColorPoint } from "app/shared/components/ColorPoint";
import ColorPoint from "app/shared/components/ColorPoint/color-point.component";
import { useLanguage } from "app/shared/hooks";
import { FormattedMessage } from "react-intl";

const AlarmColorField = ({ formik }) => {
  const { data_alarm = [] } = formik.values;
  const [language] = useLanguage();

  return (
    <>
      <Box className="form-label">
        <FormattedMessage id="CLIENT.DASHBOARD.ALARM_COLOR_LABEL" />
      </Box>
      <div style={alarmColorfield.gridColorPoint}>
        {data_alarm.length > 0 ? (
          data_alarm?.map((alarm) => (
            <Grid item xs={4.8}>
              <ColorPoint
                tooltip={alarm[`name_${language}`]}
                color={alarm?.alarm_color}
                defaultColor="#fcc400"
                size="small"
              />
            </Grid>
          ))
        ) : (
          <EmptyColorPoint />
        )}
      </div>
    </>
  );
};

export default AlarmColorField;

const alarmColorfield = {
  gridColorPoint: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: "5px",
    minHeight: "38px",
    alignItems: "center",
  },
};
