import axiosClient from "./axiosClient";

const userApi = {
  getInfo(token) {
    const url = "/api/v1/users/get-my-profile";
    return axiosClient.get(url, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  updateProfile(data, token) {
    const url = "/api/v1/users/update-my-profile";
    return axiosClient.put(url, data, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  changePassword(data, token) {
    const url = "/api/v1/users/change-my-password";
    return axiosClient.put(url, data, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  changeLanguage(token) {
    const url = "/api/v1/users/change-language";
    return axiosClient.put(url, {}, { headers: {"Authorization" : `Bearer ${token}`}});
  },
  uploadAvatar(data, token) {
    const url = `/api/v1/users/upload-avatar`;
    return axiosClient.put(url, data, { headers: {"Authorization" : `Bearer ${token}`, "Content-Type": "multipart/form-data"}});
  },
}

export default userApi;
