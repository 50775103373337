/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import ModalContainer from '../../common/ModalContainer';
import { CUSTOM_CHART_INITIALVALUES } from '../initialValues';
import { prepareCustomChartOutput } from '../helpers';
import { CustomChartFormSchema } from '../schemas';
import useModal from '../useModal';
import CustomChartModalForm from './CustomChartForm';
import './styles.css';
import moment from "moment";
import { DATE_FORMAT } from "app/constant/date-time-format";
import { HISTORICAL_DATA, PREDICTION_DATA } from "../contants";

function MenuModal(props) {
  const { intl, stations } = props;

  const [parameters, setParameters] = React.useState([]);

  const {
    openModal,
    language,
    setOpenModal,
    toggleOpen,
  } = useModal({
    modalOpen: props?.open,
    handleClose: props?.handleClose
  })

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || '',
      name_vi: props.data?.name_vi || '',
      header_color: props.data?.header_color || '#1E1E2D',
      show_header: props.data?.show_header !== undefined ? props.data?.show_header : CUSTOM_CHART_INITIALVALUES.show_header,
      data_mode: [HISTORICAL_DATA, PREDICTION_DATA].includes(props.data?.data_mode) ? props.data?.data_mode: CUSTOM_CHART_INITIALVALUES.data_mode,
      historical_time_range: props.data?.time_range || CUSTOM_CHART_INITIALVALUES.historical_time_range,
      historical_time_range_type: props.data?.time_range_type || CUSTOM_CHART_INITIALVALUES.historical_time_range_type,
      prediction_time_range: props.data?.time_range || CUSTOM_CHART_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: props.data?.time_range_type || CUSTOM_CHART_INITIALVALUES.prediction_time_range_type,

      number_of_direction: props.data?.dashboard_custom_chart?.number_of_direction || CUSTOM_CHART_INITIALVALUES.number_of_direction,

      speed_parameter_id: props.data?.dashboard_custom_chart?.speed_parameter_id || CUSTOM_CHART_INITIALVALUES.speed_parameter_id,

      direction_parameter_id: props.data?.dashboard_custom_chart?.direction_parameter_id || CUSTOM_CHART_INITIALVALUES.direction_parameter_id,

      legend_title: props.data?.dashboard_custom_chart?.legend_title || CUSTOM_CHART_INITIALVALUES.legend_title,
      speed_unit: props.data?.dashboard_custom_chart?.speed_unit || CUSTOM_CHART_INITIALVALUES.speed_unit,

      zoom: props.data?.dashboard_custom_chart?.zoom || CUSTOM_CHART_INITIALVALUES.zoom,
      legend: props.data?.dashboard_custom_chart?.legend || CUSTOM_CHART_INITIALVALUES.legend,
      range_selection: props.data?.dashboard_custom_chart?.range_selection || CUSTOM_CHART_INITIALVALUES.range_selection,
      full_screen: props.data?.dashboard_custom_chart?.full_screen || CUSTOM_CHART_INITIALVALUES.full_screen,

      station_id: props.data?.dashboard_custom_chart?.station_id || CUSTOM_CHART_INITIALVALUES.station_id,

      dashboard_custom_chart_range: props.data?.dashboard_custom_chart?.dashboard_custom_chart_range
        ?.sort((a, b) => {
          if (Number(a.min) === Number(b.min)) return Number(b.max) - Number(a.max);
          return Number(b.min) - Number(a.min);
        })
        ?.map(item => ({
          ...item,
          threshold: Number(item.min),
        })) || CUSTOM_CHART_INITIALVALUES.dashboard_custom_chart_range,
      dashboard_custom_chart_range_delete: [],
      historical_start_time: props.data?.historical_start_time ?
        moment(props.data?.historical_start_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      historical_end_time: props.data?.historical_end_time ?
        moment(props.data?.historical_end_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT)
    },
    enableReinitialize: true,
    validationSchema: CustomChartFormSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props['handleEdit'](id, prepareCustomChartOutput(props?.data, values));
      props['handleClose']();
    }
  })

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  React.useEffect(() => {

    if (props.parameters && formik.values.station_id) {
      const selectStationParams = props.parameters.find(item => item.id === formik.values.station_id);
      setParameters(selectStationParams?.items || []);

      if (selectStationParams?.items
        ?.some(item => item.id === formik.values.direction_parameter_id
          || item.id === formik.values.speed_parameter_id) === false) {
        formik.setFieldValue('direction_parameter_id', CUSTOM_CHART_INITIALVALUES.direction_parameter_id)
        formik.setFieldValue('speed_parameter_id', CUSTOM_CHART_INITIALVALUES.speed_parameter_id)
      }
    }

  }, [formik.values.station_id, props.parameters])

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      toggleOpen={toggleOpen}
      disabledSave={
        formik.isSubmitting ||
        !formik.isValid ||
        formik.values?.dashboard_custom_chart_range?.find(item => item.editing === true)
      }
      newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_CUSTOM_CHART" />}
      editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_CUSTOM_CHART" />}>
      <CustomChartModalForm
        formik={formik}
        intl={intl}
        language={language}
        stations={stations}
        parameters={parameters}
      />
    </ModalContainer>
  )
}

export default injectIntl(MenuModal)