import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage, injectIntl } from "react-intl";
import { Box, Button, TextField } from "@material-ui/core";
import workspaceDashboard from "app/api/workspaceDashboardApi";
import { useLanguage } from "app/shared/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateCurrentDashboard } from "app/redux/actions/dashboardActions";
import styles from "./index.module.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
}));

function RenameModal(props) {
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(props?.open || false);
  const [valueName, setValueName] = useState(props?.name);
  const [language] = useLanguage();
  const { intl } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual
  );
  const onClose = () => {
    if (props?.handleClose) {
      props?.handleClose();
    }
  };

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.DASHBOARD.HEADER.MODULE.RENAME" />
        </div>

        <div className="value-modal-header-right">
          <span className="value-modal-header-icon">
            <CloseIcon onClick={onClose} />
          </span>
        </div>
      </div>
    );
  };

  const handleRename = async () => {
    try {
      const resp = await workspaceDashboard.updateDashboard(props?.id, {
        [`name_${language}`]: valueName,
      });

      if (resp.success) {
        dispatch(
          updateCurrentDashboard({
            id: props?.id,
            name: valueName.trim(),
          })
        );

        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (valueName.length > 0) {
      handleRename();
    }
  };

  const renderBody = () => {
    return (
      <Box component="form" autoComplete="off">
        <div className={styles.labelRename}>
          <p> *</p>
          <FormattedMessage id="CLIENT.DASHBOARD.LABEL.RENAME" />
        </div>

        <TextField
          type="text"
          required
          label=""
          placeholder={intl.formatMessage({
            id: "CLIENT.DASHBOARD.PLACEHOLDER.RENAME",
          })}
          variant="outlined"
          fullWidth
          onChange={(e) => setValueName(e.target.value)}
          defaultValue={
            dashboardState.id === props.id && dashboardState.name !== ""
              ? dashboardState.name
              : props?.name
          }
        />

        {valueName.length === 0 ? (
          <div className={styles.requiredText}>
            <FormattedMessage id="CLIENT.GENERAL.REQUIRED_FIELD" />
          </div>
        ) : (
          ""
        )}

        <div className={styles.groupBtn}>
          <Button
            variant="contained"
            className="custom-button"
            onClick={handleSave}
          >
            <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
          </Button>

          <Button
            className="custom-button light-button"
            type="button"
            onClick={onClose}
          >
            <FormattedMessage id="CLIENT.GENERAL.ACTION_CANCEL" />
          </Button>
        </div>
      </Box>
    );
  };

  useEffect(() => {
    setOpen(props?.open);
  }, [props?.open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

export default injectIntl(RenameModal);
