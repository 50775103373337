import { useState } from "react";
import Sidebar from "../Sidebar";

const Nav = () => {
  const [active, setActive] = useState(
    JSON.parse(localStorage.getItem("active_nav")),
  );

  return <Sidebar active={active} setActive={setActive} />;
};

export default Nav;
