import { useState, useEffect } from "react";
import { useLanguage, useLoading, useQuery } from "app/shared/hooks";
import { getResponseMessage } from "app/shared/utils/functions";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import queryString from "query-string";
import _ from "lodash";

const useDataSharing = ({ intl, dataSharingApi }) => {
  const [language] = useLanguage();
  const history = useHistory();
  const [pageLoading, enablePageLoading, disablePageLoading] = useLoading();
  const [loading, enableLoading, disableLoading] = useLoading();
  const [generateLoading, enableGenerateLoading, disableGenerateLoading] =
    useLoading();
  const [activeItem, setActiveItem] = useState(null);
  const [activeId, setActiveId] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [showIndexModal, setShowIndexModal] = useState(false);
  const [data, setData] = useState([]);
  const [locationType, setLocationType] = useState(1);
  const [masterData, setMasterData] = useState({
    indexTypes: [],
    user: [],
    timeTypes: [],
    categories: [],
    methods: [],
    locationTypes: [],
  });
  const [file, setFile] = useState({});
  const [mainData, setMainData] = useState({});
  const [connections, setConnections] = useState([]);
  const [backUrl, setBackUrl] = useState("");
  const query = useQuery();

  const toggleModal = () => {
    setShowIndexModal(!showIndexModal);
  };

  const handleCreateItem = (dataItem) => {
    const newData = { ...data };
    const newId = Object.keys(data).length + 1;
    newData[newId] = { id: newId, ...dataItem };

    setData(newData);
    setIsEditing(false);
    setActiveItem(null);
    setActiveId(-1);

    toggleModal();
  };

  const toggleEditItem = (id) => {
    setActiveId(id);
    setActiveItem(data[id]);
    setIsEditing(true);

    toggleModal();
  };

  const handleEditItem = (id, dataItem) => {
    const newData = { ...data };
    newData[id] = dataItem;

    setData(newData);
  };

  const onDelete = (id) => {
    swal({
      title: intl.formatMessage({
        id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.DELETE_INDEX_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.DELETE_INDEX_WARNING_TEXT",
      }),
      icon: "warning",
      // // dangerMode: true,
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
    }).then((yes) => {
      if (yes) {
        const handleRemoveItem = () => {
          const newData = { ...data };
          delete newData[id];

          setData(newData);
        };

        handleRemoveItem();
      }
    });
  };

  const onSubmitAdd = async (values) => {
    if (!Object.keys(data).length) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.MISSING_INDEX_WARNING",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
      return;
    }
    try {
      // setSubmitting(true);
      enableLoading();

      const postData = {
        user_id: values.user,
        email: values.email,
        phone: values.phone,
        fullname: values.fullname,
        location_type_id: locationType,
        latitude: locationType === 1 ? values.latitude : undefined,
        longtitude: locationType === 1 ? values.longtitude : undefined,
        address: locationType !== 1 ? values.address : undefined,
        title: values.title,
        organization_name: values.organization_name,
        organization_address: values.organization_address,
        indicator_info: Object.values(data).map((item) => ({
          indicator_id: item.indicatorId.split("-")[1],
          data_sharing_request_method_id: item.method,
          unit: item.unit,
          frequency: item.frequency,
          data_sharing_request_time_type_id: item.time_type,
          device_name: item.name,
          device_modern: item.modern,
          device_origin: item.origin,
          device_provider: item.provider,
        })),
      };

      const res = await dataSharingApi.addRequest(postData);
      if (res.success) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_DATA_SHARING_REQUEST_SUCCESS_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_DATA_SHARING_REQUEST_SUCCESS_MESSAGE",
          }),
          icon: "success",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        }).then(() => {
          history.push(backUrl);
        });
      } else {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_DATA_SHARING_REQUEST_FAILED_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_DATA_SHARING_REQUEST_FAILED_MESSAGE",
          }),
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
      }
    } catch (error) {
      const message = intl.formatMessage({
        id: getResponseMessage(
          error.response && error.response.data.code,
          "organization"
        ),
      });
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ADD_DATA_SHARING_REQUEST_FAILED_TITLE",
        }),
        text: message,
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    } finally {
      // setSubmitting(false);
      disableLoading();
    }
  };

  const onSubmitEdit = async (id, postData) => {
    try {
      enableLoading();
      const res = await dataSharingApi.saveRequest(id, postData);
      if (res.success) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_DATA_SHARING_REQUEST_SUCCESS_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_DATA_SHARING_REQUEST_SUCCESS_MESSAGE",
          }),
          icon: "success",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        }).then(() => {
          history.push(backUrl);
        });
      } else {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_DATA_SHARING_REQUEST_FAILED_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_DATA_SHARING_REQUEST_FAILED_MESSAGE",
          }),
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
      }
    } catch (error) {
      const message = intl.formatMessage({
        id: getResponseMessage(
          error.response && error.response.data.code,
          "organization"
        ),
      });
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.EDIT_DATA_SHARING_REQUEST_FAILED_TITLE",
        }),
        text: message,
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    } finally {
      disableLoading();
    }
  };

  const handleAction = (type, id, values) => {
    if (type === "approve" && !Object.keys(data).length) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.MISSING_INDEX_WARNING",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
      return;
    }
    if (type === "approve" && !file.name) {
      swal({
        title: intl.formatMessage({
          id:
            type === "approve"
              ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_FAILED_TITLE"
              : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_FAILED_TITLE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.MISSING_FILE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
      return;
    }
    swal({
      title: intl.formatMessage({
        id:
          type === "approve"
            ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ACTION_APPROVE_TITLE"
            : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ACTION_REJECT_TITLE",
      }),
      text: intl.formatMessage({
        id:
          type === "approve"
            ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ACTION_APPROVE_TEXT"
            : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.ACTION_REJECT_TEXT",
      }),
      icon: "info",
      // // dangerMode: true,
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
    }).then((yes) => {
      if (yes) {
        const onActions = async () => {
          try {
            enableLoading();
            // const values = formik.values;
            const postData = {
              status_id: type === "approve" ? 2 : 3,
              title: values.title,
              organization_name: values.organization_name,
              organization_address: values.organization_address,
              user_id: values.user,
              email: values.email,
              phone: values.phone,
              fullname: values.fullname,
              data_key: values.data_key,
              comment: values.comment,
              location_type_id: locationType,
              latitude: locationType === 1 ? values.latitude : undefined,
              longtitude: locationType === 1 ? values.longtitude : undefined,
              address: locationType !== 1 ? values.address : undefined,
              name_template_file: file.name,
              indicator_info: Object.values(data).map((item) => ({
                indicator_id: item.indicatorId.split("-")[1],
                data_sharing_request_method_id: item.method,
                unit: item.unit,
                frequency: item.frequency,
                data_sharing_request_time_type_id: item.time_type,
                device_name: item.name,
                device_modern: item.modern,
                device_origin: item.origin,
                device_provider: item.provider,
              })),
            };
            // const id = parseInt(props.match.params.id);
            const res = await dataSharingApi.saveRequest(id, postData);
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id:
                    type === "approve"
                      ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_SUCCESS_TITLE"
                      : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_SUCCESS_TITLE",
                }),
                text: intl.formatMessage({
                  id:
                    type === "approve"
                      ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_SUCCESS_MESSAGE"
                      : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_SUCCESS_MESSAGE",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              }).then(() => {
                history.push(backUrl);
              });
            } else {
              swal({
                title: intl.formatMessage({
                  id:
                    type === "approve"
                      ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_FAILED_TITLE"
                      : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id:
                    type === "approve"
                      ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_FAILED_MESSAGE"
                      : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_FAILED_MESSAGE",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            const message = intl.formatMessage({
              id: getResponseMessage(
                error.response && error.response.data.code,
                "organization"
              ),
            });
            swal({
              title: intl.formatMessage({
                id:
                  type === "approve"
                    ? "CLIENT.DATA_SHARING_REQUEST_ACTIONS.APPROVE_DATA_SHARING_REQUEST_FAILED_TITLE"
                    : "CLIENT.DATA_SHARING_REQUEST_ACTIONS.REJECT_DATA_SHARING_REQUEST_FAILED_TITLE",
              }),
              text: message,
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          } finally {
            disableLoading();
          }
        };

        onActions();
      }
    });
  };

  const handleGenerateFile = async (id, values) => {
    try {
      // if (!formik.isValid) {
      //   return;
      // }
      if (!Object.keys(data).length) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DATA_SHARING_REQUEST_ACTIONS.MISSING_INDEX_WARNING",
          }),
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
        return;
      }
      enableGenerateLoading();
      // const id = parseInt(props.match.params.id);
      // const values = formik.values;
      const postData = {
        user_id: values.user,
        email: values.email,
        phone: values.phone,
        fullname: values.fullname,
        location_type_id: locationType,
        title: values.title,
        latitude: locationType === 1 ? values.latitude : undefined,
        longtitude: locationType === 1 ? values.longtitude : undefined,
        address: locationType !== 1 ? values.address : undefined,
        indicator_info: Object.values(data).map((item) => ({
          indicator_id: item.indicatorId.split("-")[1],
          data_sharing_request_method_id: item.method,
          unit: item.unit,
          frequency: item.frequency,
          data_sharing_request_time_type_id: item.time_type,
          device_name: item.name,
          device_modern: item.modern,
          device_origin: item.origin,
          device_provider: item.provider,
        })),
      };
      const resp = await dataSharingApi.generateFile(id, postData);
      if (resp.success) {
        const BOM = "\uFEFF";
        const csvContent = new Buffer.from(resp.data.template.data).toString();
        const blob = new Blob([BOM + csvContent], {
          type: "text/csv;charset=utf-8",
        });
        const url = window.URL.createObjectURL(blob);
        setFile({
          name: resp.data.file_name,
          url,
        });
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setTimeout(() => {
        disableGenerateLoading();
      }, 1000);
    }
  };

  const onAddConnection = (item) => {
    setConnections((prev) => prev?.concat(item));
  };

  useEffect(() => {
    const params = {};
    let url = "/data-sharing-request";

    if (query.get("ppi") && +query.get("ppi") > 0) {
      params["ppi"] = +query.get("ppi");
    }

    if (!_.isEmpty(params)) {
      url += `?${queryString.stringify(params)}`;
    }

    setBackUrl(url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSharingMaster = {
    history,
    data,
    masterData,
    language,
    loading,
    activeItem,
    isEditing,
    activeId,
    showIndexModal,
    locationType,
    enableLoading,
    disableLoading,
    pageLoading,
    enablePageLoading,
    disablePageLoading,
    mainData,
    file,
    generateLoading,
    enableGenerateLoading,
    disableGenerateLoading,
    setFile,
    setMainData,
    onDelete,
    handleEditItem,
    toggleEditItem,
    handleCreateItem,
    toggleModal,
    setLocationType,
    setMasterData,
    setData,
    setShowIndexModal,
    setIsEditing,
    setActiveId,
    setActiveItem,
    onSubmitAdd,
    onSubmitEdit,
    handleAction,
    handleGenerateFile,
    backUrl,

    connections,
    // setConnections
    onAddConnection,
  };

  return dataSharingMaster;
};

export default useDataSharing;
