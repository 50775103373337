import moment from 'moment';
const ranges = {
  "1-DAY": {
    value: "1-DAY",
    name_en: "1 Day",
    name_vi: "1 Ngày",
    start: () => moment().subtract(24, 'hours'),
    end: () => moment(),
  },
  "2-DAY": {
    value: "2-DAY",
    name_en: "2 Days",
    name_vi: "2 Ngày",
    start: () => moment().subtract(48, 'hours'),
    end: () => moment(),
  },
  "3-DAY": {
    value: "3-DAY",
    name_en: "3 Days",
    name_vi: "3 Ngày",
    start: () => moment().subtract(3, 'days'),
    end: () => moment(),
  },
  "7-DAY": {
    value: "7-DAY",
    name_en: "7 Days",
    name_vi: "7 Ngày",
    start: () => moment().subtract(7, 'days'),
    end: () => moment(),
  },
  "2-WEEK": {
    value: "2-WEEK",
    name_en: "2 Weeks",
    name_vi: "2 Tuần",
    start: () => moment().subtract(14, 'days'),
    end: () => moment(),
  },
  "1-MONTH": {
    value: "1-MONTH",
    name_en: "1 Month",
    name_vi: "1 Tháng",
    start: () => moment().subtract(1, 'months'),
    end: () => moment(),
  },
  "2-MONTH": {
    value: "2-MONTH",
    name_en: "2 Months",
    name_vi: "2 Tháng",
    start: () => moment().subtract(2, 'months'),
    end: () => moment(),
  },
  "3-MONTH": {
    value: "3-MONTH",
    name_en: "3 Months",
    name_vi: "3 Tháng",
    start: () => moment().subtract(3, 'months'),
    end: () => moment(),
  },
  "4-MONTH": {
    value: "4-MONTH",
    name_en: "4 Months",
    name_vi: "4 Tháng",
    start: () => moment().subtract(4, 'months'),
    end: () => moment(),
  },
  "6-MONTH": {
    value: "6-MONTH",
    name_en: "6 Months",
    name_vi: "6 Tháng",
    start: () => moment().subtract(6, 'months'),
    end: () => moment(),
  },
  "1-YEAR": {
    value: "1-YEAR",
    name_en: "1 Year",
    name_vi: "1 Năm",
    start: () => moment().subtract(1, 'years'),
    end: () => moment(),
  },
  "2-YEAR": {
    value: "2-YEAR",
    name_en: "2 Years",
    name_vi: "2 Năm",
    start: () => moment().subtract(2, 'years'),
    end: () => moment(),
  }
};

export default ranges;