import { alpha, Box, Button, Container, InputBase } from "@material-ui/core";
import Logo from "../../../../../assets/images/logo.png";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import SearchIcon from "@material-ui/icons/Search";
import "./index.css";
import User from "./User";
import { shallowEqual, useSelector } from "react-redux";
import { connect } from "react-redux";
import { setUserOrganization } from "../../../../redux/actions";
import { styled } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 20, //theme.shape.borderRadius,
  backgroundColor: alpha("#737373", 0.15), //alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha("#737373", 0.25), //alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0px 16px", //theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "8px 8px 8px 48px", //theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Header = (props) => {
  const { isAuthorized } = useSelector(
    ({ user }) => ({
      isAuthorized: user.token !== undefined && user !== null,
    }),
    shallowEqual,
  );

  return (
    <>
      <div className={`main-header enduser`}>
        <Container maxWidth={false} className="container">
          <div className="cl-search-map-container">
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search> */}
          </div>

          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          <div className="actions">
            <LanguageSwitcher />

            {isAuthorized ? (
              <User />
            ) : (
              <Box display="flex" alignItems="center" ml="auto">
                <Box mr={2}>
                  <Link to="/auth/login">
                    <Button className="custom-button header-button">
                      <FormattedMessage id="CLIENT.GENERAL.LOGIN" />
                    </Button>
                  </Link>
                </Box>

                <Box>
                  <Link to="/auth/registration">
                    <Button className="custom-button light-button header-button">
                      <FormattedMessage id="CLIENT.GENERAL.SIGN_UP" />
                    </Button>
                  </Link>
                </Box>
              </Box>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default connect(null, { setUserOrganization })(Header);
