import PropTypes from 'prop-types';

import AlarmSummaryModal from '../Modals/AlarmSummaryModal';
import AttachmentImageModal from '../Modals/AttachmentImageModal';
import CustomChartModal from '../Modals/CustomChartModal';
import DirectionModal from '../Modals/DirectionModal';
import GaugeChartModal from '../Modals/GaugeChartModal';
import HistoricTableModal from '../Modals/HistoricTableModal';
import LocationMapModal from '../Modals/LocationMapModal';
import StandardChartModal from '../Modals/StandardChartModal';
import StaticTextModal from '../Modals/StaticTextModal';
import ValueListModal from '../Modals/ValueListModal';
import ValueModal from '../Modals/ValueModal';

import { DASHBOARD_TYPE } from '../../../../shared/constants/dashboard';
import PredictionParameterModal from '../Modals/PredictionParameterModal';
import AggregateTableModal from '../Modals/AggregateTableModal';

const DashboardModal = (props) => {
  const {
    type, configData,
    editing, open,
    id, data, token,
    onClose, onEdit
  } = props;

  const handleClose = () => {
    if (onClose) onClose(type)
  }

  const getModal = () => {
    switch (type) {
      case DASHBOARD_TYPE.VALUE: return (
        <ValueModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          token={token}
          parameters={configData.parameters}
          workspaceId={configData.workspaceId}
        />
      )
      case DASHBOARD_TYPE.VALUE_LIST: return (
        <ValueListModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          token={token}
          parameters={configData.parameters}
          workspaceId={configData.workspaceId}
        />
      )
      case DASHBOARD_TYPE.STATIC_TEXT: return (
        <StaticTextModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
        />
      )
      case DASHBOARD_TYPE.ATTACHMENT_IMAGE: return (
        <AttachmentImageModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
        />
      )
      case DASHBOARD_TYPE.ALARM_SUMMARY: return (
        <AlarmSummaryModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.HISTORIC_TABLE: return (
        <HistoricTableModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.PREDICTION_PARAMETER_HISTORY_TABLE: return (
        <PredictionParameterModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          type={type}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.PREDICTION_PARAMETER_STANDARD_CHART: return (
        <PredictionParameterModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          type={type}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.DIRECTION: return (
        <DirectionModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.LOCATION_MAP: return (
        <LocationMapModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          stations={configData.stations}
          token={token}
        />
      )
      case DASHBOARD_TYPE.STANDARD_CHART: return (
        <StandardChartModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.GAUGE_CHART: return (
        <GaugeChartModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
        />
      )
      case DASHBOARD_TYPE.CUSTOM_CHART: return (
        <CustomChartModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
          stations={configData.stations}
          token={token}
        />)
      case DASHBOARD_TYPE.AGGREGATE_TABLE: return (
        <AggregateTableModal
          editing={editing}
          open={open}
          handleClose={handleClose}
          id={id}
          data={data}
          handleEdit={onEdit(type)}
          parameters={configData.parameters}
      />
      )
      default: return <></>
    }
  }

  return (
    <>
      {getModal()}
    </>
  )
}

DashboardModal.propTypes = {
  type: PropTypes.string.isRequired,
  configData: PropTypes.object,
  editing: PropTypes.bool,
  open: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  data: PropTypes.object,
  token: PropTypes.string,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DashboardModal;