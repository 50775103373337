import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid } from "@material-ui/core";

const FormHeader = ({ messageId }) => {
  return (
    <Grid item xs={12} className="form-header">
      <h3 className="form-title">
        <FormattedMessage id={messageId} />
      </h3>
    </Grid>
  );
};

export default FormHeader;
