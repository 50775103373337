import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewListIcon from "@material-ui/icons/ViewList";

import "./styles.css";
import LocationLeafletMap from "../../../../shared/components/Dashboard/LocationLeafletMap";
import L from "leaflet";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import { useLanguage } from "../../../hooks";
import { useSelector, shallowEqual } from "react-redux";
import workspaceDashboardApi from "../../../../api/workspaceDashboardApi";
import { BASE_MAPS } from "app/pages/Dashboard/components/Modals/LocationMapModal/base-map-data";
import { invertColor } from "app/utils/utils";
import { DashboardCardButtons } from "../DashboardCardButtons";

const initialPos = {
  lat: 16.4533875,
  lng: 107.5420939,
};

const ValueListCard = (props) => {
  const [language] = useLanguage();
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_LOCATION_MAP" />
  );
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";

  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;

  const [sureControl, setSureControl] = useState(props.sureControl || false);
  const [data, setData] = useState(null);

  const onSureControl = () => {
    setSureControl(true);
  };

  const generateMarker = (item) => {
    const iconUrl =
      item.point_marker?.data?.data &&
      new Buffer.from(item.point_marker.data.data).toString("base64");

    const icon = L.icon({
      iconUrl: `data:;base64,${iconUrl}`,
      iconSize: [41, 41], // size of the icon
      iconAnchor: [20, 41], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -41], // point from which the popup should open relative to the iconAnchor
    });

    return {
      id: item.id,
      position: { lat: item.latitude, lng: item.longtitude },
      name: language === "en" ? item.name : item.name_vi,
      icon: icon,
    };
  };

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const loadDataDashboard = async (id) => {
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
    }
    return null;
  };

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      const getParameterData = (id) => {
        const parameter = dashboardData?.parameterDatas?.find(
          (item) => item.parameterDto?.parameterId === id
        );

        if (parameter) {
          const lastDataPoint = parameter.dataPointDtos.at(-1);

          if (lastDataPoint && !isNaN(Number(lastDataPoint?.value))) {
            return lastDataPoint.value;
          }
        }

        return null;
      };

      const stationList =
        props.data.dashboard_location_map?.dashboard_location_map_station
          ?.map((stationItem) => {
            if (stationItem.location_type === "STATIC") {
              if (stationItem.station)
                return {
                  ...stationItem.station,
                };

              return null;
            }

            const lat = getParameterData(stationItem.latitude_parameter_id);
            const long = getParameterData(stationItem.longtitude_parameter_id);

            if (lat !== null && long !== null) {
              return {
                ...stationItem.station,
                latitude: lat,
                longtitude: long,
              };
            }

            return null;
          })
          .filter((item) => item != null) || [];

      const data = {
        ...props.data.dashboard_location_map,
        style: parseInt(props.data.dashboard_location_map?.style) || 1,
        display_coordinate:
          props.data.dashboard_location_map?.display_coordinate || "none",
        zoom: props.data.dashboard_location_map?.zoom || 6,
        initialPos:
          stationList.length > 0
            ? { lat: stationList[0].latitude, lng: stationList[0].longtitude }
            : initialPos,
        display_stations: stationList,
      };

      setData(data);
    };

    if (props.data && props.data.dashboard_location_map) {
      initData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props["data"]]);

  useEffect(() => {
    if (props.realTimeData?.eventTime && props.realTimeData?.paramId) {
      if (
        data?.dashboard_location_map_station?.some(
          (station) =>
            station.latitude_parameter_id === props.realTimeData?.paramId ||
            station.longtitude_parameter_id === props.realTimeData?.paramId
        )
      ) {
        const lastValue = props.realTimeData?.value;

        if (isNaN(Number(lastValue))) return;

        const stationItem = data?.dashboard_location_map_station?.find(
          (item) =>
            item.latitude_parameter_id === props.realTimeData?.paramId ||
            item.longtitude_parameter_id === props.realTimeData?.paramId
        );

        if (!stationItem) return;

        const newStationList = [...data?.display_stations];
        const indexStation = newStationList?.findIndex(
          (item) => item.id === stationItem.station_id
        );

        if (indexStation === -1) return;

        newStationList[indexStation] = {
          ...newStationList[indexStation],
          latitude:
            props.realTimeData?.paramId === stationItem?.latitude_parameter_id
              ? lastValue
              : newStationList[indexStation]?.latitude,
          longtitude:
            props.realTimeData?.paramId === stationItem?.longtitude_parameter_id
              ? lastValue
              : newStationList[indexStation]?.longtitude,
        };

        setData((pre) => ({
          ...pre,
          display_stations: newStationList,
          initialPos:
            newStationList.length > 0
              ? {
                  lat: newStationList[0].latitude,
                  lng: newStationList[0].longtitude,
                }
              : initialPos,
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.realTimeData?.eventTime, props.realTimeData?.paramId]);
  return (
    <div
      className={`location-map-card dashboard-item-card ${
        !props?.isView && "mapboxgl-ctrl-bottom-right-hover"
      }`}
    >
      {showHeader && (
        <p
          className="location-map-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <ViewListIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}
      {props["loading"] ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div class="location-map-card-content">
          {data !== null ? (
            <>
              <LocationLeafletMap
                id={data?.id}
                coordinateFormat={data.display_coordinate}
                textColor={data.text_color}
                zoom={data.zoom}
                baseMaps={BASE_MAPS}
                style={data.style}
                markers={data?.display_stations?.map((item) =>
                  generateMarker(item)
                )}
                initialPos={data.initialPos}
                dragging={props.dragging}
                language={language}
              />

              {!sureControl && (
                <div
                  className="tips-area"
                  style={
                    props?.isView
                      ? {
                          bottom: 20,
                        }
                      : null
                  }
                >
                  <div className="location-map-tips">
                    {language === "en" ? (
                      <span>
                        Press “Ctrl” or “<b>&#8984;</b>” to drag the map.{" "}
                        <a
                          href="#"
                          onClick={onSureControl}
                          onMouseDown={stopPropagation}
                        >
                          {" "}
                          Sure
                        </a>
                      </span>
                    ) : (
                      <span>
                        Giữ phím “Ctrl” hoặc “<b>&#8984;</b>” để di chuyển bản
                        đồ.{" "}
                        <a
                          href="#"
                          onClick={onSureControl}
                          onMouseDown={stopPropagation}
                        >
                          {" "}
                          Đã hiểu
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="no-data-area">
              <p className="no-data-label">
                <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
              </p>
            </div>
          )}
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default ValueListCard;
