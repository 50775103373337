import axiosClient from "./axiosClient";

const dataSharingApi = {
  getAllDataSharingRequest(params) {
    const url = "/api/v1/data-sharing-request";
    return axiosClient.get(url, { params });
  },
  getStatus() {
    const url = "/api/v1/data-sharing-request/status";
    return axiosClient.get(url, {});
  },
  getIndexTypes() {
    const url = "/api/v1/indicator";
    return axiosClient.get(url, {});
  },
  getCategories() {
    const url = "/api/v1/indicator/category";
    return axiosClient.get(url, {});
  },
  getAllMethods() {
    const url = "/api/v1/data-sharing-request/method/";
    return axiosClient.get(url, {});
  },
  getLocationTypes() {
    const url = "/api/v1/data-sharing-request/location-type/";
    return axiosClient.get(url, {});
  },
  getTimeTypes() {
    const url = "/api/v1/data-sharing-request/time-type";
    return axiosClient.get(url, {});
  },
  getUsers() {
    const url = "/api/v1/data-sharing-request/enduser";
    return axiosClient.get(url, {});
  },
  addRequest(data) {
    const url = "/api/v1/data-sharing-request";
    return axiosClient.post(url, data, {});
  },
  getRequest(id) {
    const url = `/api/v1/data-sharing-request/${id}`;
    return axiosClient.get(url, {});
  },
  generateFile(id, data) {
    const url = `/api/v1/data-sharing-request/generate-template-file/${id}`;
    return axiosClient.post(url, data, {});
  },
  saveRequest(id, data) {
    const url = `/api/v1/data-sharing-request/${id}`;
    return axiosClient.put(url, data, {});
  },
  getAllStations(params) {
    const url =
      "/api/v1/workspace/get-all-workspace-with-all-station-parameter";
    return axiosClient.get(url, { params });
  },
  createForSharing(id, data) {
    const url = `/api/v1/data-sharing-request/${id}/create-station`;
    return axiosClient.post(url, data, {});
  },
  mergeStation(id, params) {
    const url = `/api/v1/data-sharing-request/${id}/merge-station`;
    return axiosClient.post(url, params, {});
  }
};

export default dataSharingApi;
