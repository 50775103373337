import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import RangesList from "app/shared/components/Dashboard/RangesList";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { FormattedMessage } from "react-intl";
import SelectionStationField from "../../common/SelectionStationField";
import { DIRECTION_OPTIONS, HISTORICAL_DATA, PREDICTION_DATA } from "../contants";

const CustomChartModalForm = ({ formik, intl, language, parameters, stations }) => {
  return (
    <form>
      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.DASHBOARD.HEADER_LABEL',
        })}>

        <HeaderConfigurator formik={formik} />
      </ConfigureArea>

      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.PARAMETERS_ACTIONS.PARAMETERS',
        })}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectionStationField formik={formik} stations={stations} language={language} />
            </Grid>
            <Grid item xs={6}>
              <SharedSelectField
                required
                className="bg-white"
                label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.NUMBER_OF_DIRECTIONS' })}
                disableClearable
                name="number_of_direction"
                options={DIRECTION_OPTIONS}
                defaultValue={formik.values["number_of_direction"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mb={3}>
              <SharedSelectField
                required={formik.values.location_type !== 'STATIC'}
                className={`bg-white ${formik.values.location_type === 'STATIC' && "disabled-field"}`}
                label={<FormattedMessage id='CLIENT.DASHBOARD.CUSTOM_CHART.SPEED' />}
                disableClearable
                name="speed_parameter_id"
                options={[{
                  id: "",
                  name: "-- Select Parameter --",
                  name_vi: "-- Chọn Thông Số --"
                }].concat(parameters).map(item => ({
                  id: item.id,
                  value: item.id,
                  label: language === 'en' ? item.name : item.name_vi,
                  disabled: item.id === ""
                }))}
                defaultValue={formik.values["speed_parameter_id"]}
                onChange={formik.setFieldValue}
                disabled={formik.values.location_type === 'STATIC'}
                errorMsg={formik.touched["speed_parameter_id"] && formik.errors["speed_parameter_id"]}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mb={3}>
              <SharedSelectField
                required={formik.values.location_type !== 'STATIC'}
                className={`bg-white ${formik.values.location_type === 'STATIC' && "disabled-field"}`}
                label={<FormattedMessage id='CLIENT.DASHBOARD.CUSTOM_CHART.DIRECTION' />}
                disableClearable
                name="direction_parameter_id"
                options={[{
                  id: "",
                  name: "-- Select Parameter --",
                  name_vi: "-- Chọn Thông Số --"
                }].concat(parameters).map(item => ({
                  id: item.id,
                  value: item.id,
                  label: language === 'en' ? item.name : item.name_vi,
                  disabled: item.id === ""
                }))}
                defaultValue={formik.values["direction_parameter_id"]}
                onChange={formik.setFieldValue}
                disabled={formik.values.location_type === 'STATIC'}
                errorMsg={formik.touched["direction_parameter_id"] && formik.errors["direction_parameter_id"]}
              />
            </Box>
          </Grid>
        </Grid>

      </ConfigureArea>

      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.DASHBOARD.CUSTOM_CHART.SPEED_UNIT',
        })}>
        <Grid container spacing={2}>
          <Grid item xs>
            <SharedInputField
              name="legend_title"
              label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.LEGEND_TITLE' })}
              type="text"
              required
              className={`bg-white`}
              placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.LEGEND_TITLE_HINT' })}
              disabled={formik.isSubmitting}
              {...formik.getFieldProps("legend_title")}
              errorMsg={formik.touched["legend_title"] && formik.errors["legend_title"]}
            />
          </Grid>
          <Grid item xs>
            <SharedInputField
              name="speed_unit"
              label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.SPEED_UNIT_LABEL' })}
              type="text"
              required
              className={`bg-white`}
              placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CUSTOM_CHART.SPEED_UNIT_HINT' })}
              disabled={formik.isSubmitting}
              {...formik.getFieldProps("speed_unit")}
              errorMsg={formik.touched["speed_unit"] && formik.errors["speed_unit"]}
            />
          </Grid>
        </Grid>
      </ConfigureArea>

      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.DASHBOARD.FEATURES',
        })}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.legend}
              onChange={(e) =>
                formik.setFieldValue("legend", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.CUSTOM_CHART.LEGEND',
          })}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.range_selection}
              onChange={(e) =>
                formik.setFieldValue("range_selection", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.CUSTOM_CHART.RANGE_SELECTION',
          })}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.full_screen}
              onChange={(e) =>
                formik.setFieldValue("full_screen", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.CUSTOM_CHART.FULL_SCREEN',
          })}
        />

      </ConfigureArea>

      <Box className="form-label">
        <p className="required">*</p>
        <FormattedMessage id='CLIENT.DASHBOARD.CUSTOM_CHART.RANGES' />
      </Box>

      <RangesList
        data={formik.values.dashboard_custom_chart_range}
        onChange={(data) => {
          formik.setFieldValue('dashboard_custom_chart_range', data)
        }}
        pickColors={true}
      />
    </form>
  )
}

export default CustomChartModalForm