/* eslint-disable react-hooks/exhaustive-deps */
import { DATE_FORMAT } from "app/constant/date-time-format";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ModalContainer from "../../common/ModalContainer";
import { HISTORICAL_DATA, PREDICTION_DATA } from "../contants";
import { prepareStandardChartOutput } from "../helpers";
import { STANDARD_CHART_INITIALVALUES } from "../initialValues";
import StandardChartSchema from "../schemas/StandardChartFormSchema";
import useModal from "../useModal";
import StandardChartForm from "./StandardChartForm";
import "./styles.css";

export default function MenuModal(props) {
  const { handleClose, open } = props;
  const intl = useIntl();

  const { language, toggleOpen, openModal, setOpenModal } = useModal({
    handleClose,
    modalOpen: open,
  });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || STANDARD_CHART_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || STANDARD_CHART_INITIALVALUES.name_vi,
      header_color:
        props.data?.header_color || STANDARD_CHART_INITIALVALUES.header_color,
      show_header:
        props.data?.show_header !== undefined
          ? props.data?.show_header
          : STANDARD_CHART_INITIALVALUES.show_header,
      prediction_time_range:
        props.data?.prediction_time_range ||
        STANDARD_CHART_INITIALVALUES.prediction_time_range,
      prediction_time_range_type:
        props.data?.prediction_time_range_type ||
        STANDARD_CHART_INITIALVALUES.prediction_time_range_type,
      historical_time_range:
        props.data?.historical_time_range ||
        STANDARD_CHART_INITIALVALUES.historical_time_range,
      historical_time_range_type:
        props.data?.historical_time_range_type ||
        STANDARD_CHART_INITIALVALUES.historical_time_range_type,
      data_mode: [HISTORICAL_DATA, PREDICTION_DATA].includes(
        props.data?.data_mode
      )
        ? props.data?.data_mode
        : STANDARD_CHART_INITIALVALUES.data_mode,
      zoom:
        props.data?.dashboard_standard_chart?.zoom !== undefined
          ? props.data?.dashboard_standard_chart?.zoom
          : STANDARD_CHART_INITIALVALUES.zoom,
      legend:
        props.data?.dashboard_standard_chart?.legend !== undefined
          ? props.data?.dashboard_standard_chart?.legend
          : STANDARD_CHART_INITIALVALUES.legend,
      range_selection:
        props.data?.dashboard_standard_chart?.range_selection !== undefined
          ? props.data?.dashboard_standard_chart?.range_selection
          : STANDARD_CHART_INITIALVALUES.range_selection,
      gridlines:
        props.data?.dashboard_standard_chart?.gridlines !== undefined
          ? props.data?.dashboard_standard_chart?.gridlines
          : STANDARD_CHART_INITIALVALUES.gridlines,
      full_screen:
        props.data?.dashboard_standard_chart?.full_screen !== undefined
          ? props.data?.dashboard_standard_chart?.full_screen
          : STANDARD_CHART_INITIALVALUES.full_screen,
      dashboard_standard_chart_parameter:
        props.data?.dashboard_standard_chart?.dashboard_standard_chart_parameter
          ?.map((item) => ({
            ...item,
            marker_size: parseInt(item.marker_size),
            originId: item.id,
          }))
          ?.sort((a, b) => a.index - b.index) ||
        STANDARD_CHART_INITIALVALUES.dashboard_standard_chart_parameter,
      historical_start_time: props.data?.historical_start_time
        ? moment(props.data?.historical_start_time)
        : moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      historical_end_time: props.data?.historical_end_time
        ? moment(props.data?.historical_end_time)
        : moment(moment().format(DATE_FORMAT), DATE_FORMAT),
    },
    enableReinitialize: true,
    validationSchema: StandardChartSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props["handleEdit"](id, prepareStandardChartOutput(props.data, values));
      props["handleClose"]();
    },
  });

  React.useEffect(() => {
    setOpenModal(props["open"]);
    return () => {
      formik.resetForm();
    };
  }, [props["open"]]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      toggleOpen={toggleOpen}
      newText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_STANDARD_CHART" />
      }
      editText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_STANDARD_CHART" />
      }
    >
      <StandardChartForm
        formik={formik}
        intl={intl}
        language={language}
        parameters={props?.parameters || []}
      />
    </ModalContainer>
  );
}
