/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './styles.css';

import { useFormik } from "formik";
import ModalContainer from '../../common/ModalContainer';
import { prepareValueOutput } from '../helpers';
import { VALUE_INITIALVALUES } from '../initialValues';
import ValueFormSchema from '../schemas/ValueFormSchema';
import useModal from '../useModal';
import ValueForm from './ValueForm';

function MenuModal(props) {
  const { intl, open, handleClose } = props;

  const { openModal, language, setOpenModal, toggleOpen } = useModal({ modalOpen: open, handleClose });

  const formik = useFormik({
    initialValues: {
      ...VALUE_INITIALVALUES,
      name_en: props.data?.name_en || VALUE_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || VALUE_INITIALVALUES.name_vi,
      header_color: props.data?.header_color || VALUE_INITIALVALUES.header_color,
      main_color: props.data?.dashboard_value?.main_color || VALUE_INITIALVALUES.main_color,
      data_alarm: props.data?.dashboard_value?.parameter?.data_alarm || [],
      parameter_id: props.data?.dashboard_value?.parameter_id,
      station_id: props.data?.dashboard_value?.station_id,
      parameter_display: '',
      show_header: props.data?.show_header === undefined ? VALUE_INITIALVALUES.show_header : props.data.show_header,
      data_mode: props.data?.data_mode || VALUE_INITIALVALUES.data_mode,
      prediction_time_range: (props.data?.time_point && props.data?.time_point !== "0") ? props.data?.time_point : VALUE_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: (props.data?.time_point_type && props.data?.time_point_type !== "0") ? props.data?.time_point_type : VALUE_INITIALVALUES.prediction_time_range_type,
    },
    enableReinitialize: true,
    validationSchema: ValueFormSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props['handleEdit'](id, prepareValueOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  return <ModalContainer
    formik={formik}
    editing={props?.editing}
    handleClose={props?.handleClose}
    open={openModal}
    toggleOpen={toggleOpen}
    disabledSave={formik.isSubmitting ||
      !formik.isValid}
    newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.ADD_NEW_VALUE" />}
    editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_VALUE" />}>
    <ValueForm
      formik={formik}
      intl={intl}
      language={language}
      parameters={props?.parameters || []}
    />
  </ModalContainer>
}

export default injectIntl(MenuModal)