import React from "react";

function IconNoData(props) {
  return (
    <svg
      width="208"
      height="272"
      viewBox="0 0 208 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className={`reeco-icon ${props?.className}`}
      // {...props}
    >
      <path
        d="M151.479 171.582C150.169 159.111 136.003 130.213 89.8222 114.394C32.0967 94.6203 80.014 65.6905 52.021 36.4178"
        stroke="#9C9C9C"
        stroke-width="2"
        stroke-dasharray="2 5"
      />
      <ellipse
        cx="34.1994"
        cy="27.9933"
        rx="12.1225"
        ry="5.88805"
        transform="rotate(20.7062 34.1994 27.9933)"
        fill="#DADADA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.8104 29.8967C25.8301 27.9873 26.1796 26.0487 26.8941 24.1586C27.3177 23.0378 27.8489 21.9891 28.4714 21.0198C29.8263 20.9813 31.3424 21.1336 32.9345 21.4844C32.0171 22.7117 31.2544 24.0867 30.6856 25.5917C29.8372 27.836 29.5034 30.1486 29.6254 32.3998C28.16 31.6511 26.8695 30.7968 25.8104 29.8967Z"
        fill="#F1F1F1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.3278 33.9236C33.186 31.6324 33.5163 29.2749 34.3806 26.9883C34.9806 25.4009 35.7963 23.9581 36.7817 22.6806C38.0665 23.1976 39.255 23.7988 40.3183 24.4536C39.4467 25.6453 38.7198 26.9733 38.1723 28.4216C37.3783 30.5222 37.035 32.6825 37.0944 34.7964C35.8905 34.6281 34.6211 34.3385 33.3278 33.9236Z"
        fill="#F1F1F1"
      />
      <path
        d="M41.2645 5.99603C39.3017 8.29414 36.4848 17.0752 35.3217 21.1784C42.7988 19.0197 52.0787 13.9355 50.3553 8.42121C48.6319 2.90695 43.7179 3.1234 41.2645 5.99603Z"
        fill="#DADADA"
      />
      <path
        d="M37.4005 4.76968C37.8969 7.66317 35.7101 16.5967 34.5547 20.7018C29.1762 15.4307 23.7726 6.79144 28.1911 2.7642C32.6096 -1.26303 36.7801 1.15283 37.4005 4.76968Z"
        fill="#DADADA"
      />
      <path
        d="M26 122C26 119.239 28.2386 117 31 117H182C184.761 117 187 119.239 187 122V214C187 219.523 182.523 224 177 224H36C30.4772 224 26 219.523 26 214V122Z"
        fill="#DADADA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31 117C28.2386 117 26 119.239 26 122V214C26 219.523 30.4772 224 36 224H149C154.523 224 159 219.523 159 214V117H31ZM76 130C73.2386 130 71 132.239 71 135C71 137.761 73.2386 140 76 140H109.333C112.095 140 114.333 137.761 114.333 135C114.333 132.239 112.095 130 109.333 130H76Z"
        fill="#F1F1F1"
      />
    </svg>
  );
}

export default IconNoData;
