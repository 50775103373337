export const EXPIRY_OPTIONS = (intl) => {
  return [
    {
      id: 0,
      value: "all",
      label: intl.formatMessage({id: "CLIENT.GENERAL.ALL_ITEM"})
    },
    {
      id: 1,
      value: "",
      label: intl.formatMessage({id: "CLIENT.GENERAL.NO_EXPIRY"})
    },
    {
      id: 2,
      value: "1",
      label: intl.formatMessage({id: "CLIENT.GENERAL.1_DAY"})
    },
    {
      id: 3,
      value: "7",
      label: intl.formatMessage({id: "CLIENT.GENERAL.7_DAY"})
    },
    {
      id: 4,
      value: "30",
      label: intl.formatMessage({id: "CLIENT.GENERAL.30_DAY"})
    },
    {
      id: 5,
      value: "custom",
      label: intl.formatMessage({id: "CLIENT.GENERAL.CUSTOM_EXPIRY"})
    },
  ]
}