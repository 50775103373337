import { 
  NOT_LOGIN_CHAT_END_USER_RESET_DATA, 
  NOT_LOGIN_SET_END_USER_CHAT_ICONS, 
  NOT_LOGIN_SET_END_USER_CHAT_WINDOWS, 
  NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI, 
  NOT_LOGIN_SET_END_USER_ROOMS_NOTI, 
  NOT_LOGIN_SET_END_USER_ROOM_ID_LIST 
} from "../actions/types";

const initialState = {
  chatWindows: [],
  chatIcons: [],
  roomIdList: [],
  roomsNoti: [],
  roomsInitNoti: [],
};

const notLoginEndUserChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOT_LOGIN_SET_END_USER_CHAT_WINDOWS: {
      const { chatWindows } = action.payload;
      return { ...state, chatWindows };
    }
    case NOT_LOGIN_SET_END_USER_CHAT_ICONS: {
      const { chatIcons } = action.payload;
      return { ...state, chatIcons };
    }
    case NOT_LOGIN_SET_END_USER_ROOM_ID_LIST: {
      const { roomIdList } = action.payload;
      return { ...state, roomIdList };
    }
    case NOT_LOGIN_SET_END_USER_ROOMS_NOTI: {
      const { roomsNoti } = action.payload;
      return { ...state, roomsNoti };
    }
    case NOT_LOGIN_SET_END_USER_ROOMS_INIT_NOTI: {
      const { roomsInitNoti } = action.payload;
      return { ...state, roomsInitNoti };
    }
    case NOT_LOGIN_CHAT_END_USER_RESET_DATA: {
      return {
        chatWindows: [],
        chatIcons: [],
        roomIdList: [],
        roomsNoti: [],
        roomsInitNoti: [],
      };
    }
    default: {
      return state;
    }
  }
}
export default notLoginEndUserChatReducer;