import { Box, FormHelperText, Grid } from "@material-ui/core";
import ColorPicker from "app/shared/components/ColorPicker";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import StationList from "app/shared/components/Dashboard/StationList";
import SharedSelectField from "app/shared/components/SharedSelectField";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrettoSlider from "../../common/PrettoSlider";
import { COORDINATE_FORMATS } from "../contants";
import { BASE_MAPS } from "./base-map-data";

const LocationMapForm = ({
  formik,
  intl,
  stations,
  language
}) => {
  return (
    <div className="location-map-modal-body">
        <form onSubmit={formik.handleSubmit}>
          <ConfigureArea
            mb={30}
            title={intl.formatMessage({
              id: 'CLIENT.DASHBOARD.HEADER_LABEL',
            })}>

            <HeaderConfigurator formik={formik} />
          </ConfigureArea>

          <ConfigureArea title={intl.formatMessage({
            id: 'CLIENT.GENERAL.MENU_STATIONS',
          })}>
            <div className="form-group">
              <StationList
                data={formik.values['dashboard_location_map_station']}
                stations={stations}
                dataMode={formik.values.data_mode}
                dashboardLocationMapStationDelete={formik.values['dashboard_location_map_station_delete']}
                onChange={(data) => formik.setFieldValue('dashboard_location_map_station', data)}
                onDelete={(data) => formik.setFieldValue('dashboard_location_map_station_delete', data)}
              />
            </div>
          </ConfigureArea>

          <ConfigureArea title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.MAP',
          })}>

            <Box className="form-label">
              <span className="required">*</span>
              <FormattedMessage id='CLIENT.DASHBOARD.MAP_STYLE' />
            </Box>
            <div
              className="map-style-field"
              defaultValue={formik.values['style']}
              onChange={(e) => formik.setFieldValue('style', e.target.value)}>
              <Grid container spacing={2}>
                {(BASE_MAPS || []).map(item => (
                  <Grid item xs={2}>
                    <label class="map-style-container" key={item.id}>
                      <input
                        type="radio"
                        name="mapStyle"
                        value={item.id}
                        defaultChecked={
                          formik.values['style'] == item.id ? 'defaultChecked' : null
                        }
                      />
                      <div class="map-style-thumbnail-container">
                        <img
                          className="map-style-thumbnail"
                          alt=""
                          src={item.thumbnail}
                        />
                      </div>
                    </label>
                  </Grid>
                ))}
              </Grid>

              {formik.touched["dashboard_location_map_station"] && formik.errors["dashboard_location_map_station"] &&
                <FormHelperText className="error-message">{formik.errors["dashboard_location_map_station"]}</FormHelperText>}
            </div>

            <Box mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SharedSelectField
                    required
                    className="bg-white"
                    label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.DISPLAY_COORDINATE' })}
                    disableClearable
                    name="display_coordinate"
                    options={COORDINATE_FORMATS.map(item => ({
                      id: item.token,
                      value: item.token,
                      label: item[`name_${language}`]
                    }))}
                    defaultValue={formik.values["display_coordinate"]}
                    onChange={formik.setFieldValue}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box className="form-label">
                    <span className="required">*</span>
                    <FormattedMessage id='CLIENT.DASHBOARD.ZOOM' />
                  </Box>
                  <div style={{ paddingTop: 8.5 }}>
                    <PrettoSlider
                      onChange={(event, newValue) => formik.setFieldValue('zoom', newValue)}
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      value={formik.values['zoom']}
                      step={10}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>

            {formik.values['display_coordinate'] !== 'none' && (
              <>
                <Box className="form-label">
                  <FormattedMessage id='CLIENT.DASHBOARD.TEXT_COLOR' />
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <ColorPicker
                        color={formik.values.text_color}
                        position="right"
                        onChange={(colorValue) =>
                          formik.setFieldValue('text_color', colorValue)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

          </ConfigureArea>
        </form>
      </div>
  )
}

export default LocationMapForm