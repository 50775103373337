import {
  Tooltip,
  MenuItem,
  Divider,
  Button,
  MenuList,
  Popper,
  Grow,
  ClickAwayListener,
} from "@material-ui/core";

import workspaceDashboard from "app/api/workspaceDashboardApi";
import { reloadData } from "app/redux/actions";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
const EllipsisMenu = (prop) => {
  const { toggleRename, toggleSettings } = prop;
  const [anchorEl, setAnchorEl] = useState(null);
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const param = useParams();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteDashboard = (e) => {
    e.preventDefault();
    swal({
      title: intl.formatMessage({
        id: "CLIENT.DASHBOARD.DELETE_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.DASHBOARD.DELETE_WARNING_TEXT",
      }),
      icon: "warning",
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
      // dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const handleDeleteWorkspace = async () => {
          try {
            const res = await workspaceDashboard.deleteDashboard(
              param.id,
              token
            );
            if (res.success) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
              const currentPath = `/dashboards/${
                location.pathname.split("/")[
                  location.pathname.split("/").length - 1
                ]
              }`;
              dispatch(reloadData());
              if (currentPath === `/dashboards/${param?.id}`) {
                history.push(`/workspaces/edit/${param?.workspaceId}`);
              }
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.DELETE_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.DASHBOARD.DELETE_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.DASHBOARD.DELETE_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteWorkspace();
      }
    });
  };
  return (
    <div>
      <Tooltip
        title={<FormattedMessage id="CLIENT.DASHBOARD.HEADER.ACTION_TOOLTIP" />}
        placement="top"
      >
        <Button onClick={handleClick}>
          <MoreHorizIcon />
        </Button>
      </Tooltip>
      <Popper
        anchorEl={anchorEl}
        open={open}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={styles.menu}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <MenuList
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={styles.menu}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                className="body-dropdown-button"
              >
                <MenuItem
                  onClick={() => {
                    toggleRename();
                    handleClose();
                  }}
                >
                  <FormattedMessage id="CLIENT.GENERAL.MENU_RENAME" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleSettings();
                    handleClose();
                  }}
                >
                  <FormattedMessage id="CLIENT.DASHBOARD.SETTINGS" />
                </MenuItem>
                <Divider style={styles.divider} />

                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    handleDeleteDashboard(e);
                  }}
                >
                  <FormattedMessage id="CLIENT.GENERAL.MENU_DELETE" />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
const styles = {
  divider: {
    margin: "5px 0",
  },
  origin: {
    vertical: "top",
    horizontal: "left",
  },
  menu: {
    marginTop: 5,
    zIndex: 9999,
    width: 152,
  },
};

export default EllipsisMenu;
