import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M37.625 26.667v-3.334l-14-8.333V5.834c0-1.384-1.172-2.5-2.625-2.5s-2.625 1.116-2.625 2.5V15l-14 8.334v3.333l14-4.167v9.167l-3.5 2.5v2.5L21 35l6.125 1.667v-2.5l-3.5-2.5V22.5l14 4.167z"
      ></path>
    </svg>
  );
}

export default Icon;
