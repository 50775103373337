import React from "react";
import _ from "lodash";
import Parameter from "./Parameter";

const Station = ({ station }) => {
  const { name, address = "", parameter } = station;

  const renderParameters = () => {
    return parameter?.map((param) => (
      <Parameter key={param?.id} parameter={param} />
    ));
  };

  return (
    <div className="station">
      <div className="station-header">
        <div className="station-details">
          <h4 className="station-name">{name}</h4>
          {address !== "" && <p className="station-address">{address}</p>}
        </div>
      </div>

      <div className="station-main">{renderParameters()}</div>
    </div>
  );
};

export default Station;
