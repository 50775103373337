import { SET_TOP_MENU } from "../actions/types";

const initialState = {
  title: "",
  breadcrumbs: [],
};

const topMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOP_MENU: {
      const { title, breadcrumbs } = action.payload;

      return { ...state, title, breadcrumbs };
    }

    default: {
      return state;
    }
  }
};
export default topMenuReducer;
