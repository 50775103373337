import { useEffect, useRef, useState } from "react";
import "./index.css";
import ChatWindow from "../ChatWindow/ChatWindow";
import ChatUserIcon from "../ChatUserIcon";
import SubChatUserIcon from "../SubChatIcon";
import ChatActions from "../ChatActions";
import accountApi from "../../../../api/accountApi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import {
  toggleChatWindow,
  closeChatWindow,
  handleInitChatWindow,
  handleHideChatWindow,
  handleUnHideChatWindow,
  handleCloseAllChat,
  handleHideAllChat,
  closeChatIcon,
  fetchRoomIds,
  setInitRoomsNotification,
  getAllNotification,
  resetData,
  getAllEndUserNotification,
  joinEndUserRoom,
} from "../../../../redux/actions/chatActions";
import { USER_ROLE_ID } from "../../../constants/users";
import { useLocation } from "react-router-dom";

const Chat = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { token, user } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user,
    }),
    shallowEqual,
  );
  const {
    chatWindows,
    chatIcons,
    roomsNoti,
    roomsInitNoti,
    endUserRooms,
    waitMessage,
  } = useSelector(
    ({ chat, chatMessage }) => ({
      chatWindows: chat.chatWindows,
      chatIcons: chat.chatIcons,
      roomIdList: chat.roomIdList,
      roomsNoti: chat.roomsNoti,
      roomsInitNoti: chat.roomsInitNoti,
      endUserRooms: chat.endUserRooms,
      waitMessage: chatMessage.waitMessage,
    }),
    shallowEqual,
  );
  const [userList, setUserList] = useState([]);
  const socketRef = useRef();
  const subSocketRef = useRef();
  const notLoginSocketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient.connect(process.env.REACT_APP_API_URL);
    if (user.roleId === USER_ROLE_ID.CONTENT_ROLE) {
      // check if user is Content Role
      subSocketRef.current = socketIOClient.connect(
        `${process.env.REACT_APP_API_URL}/customer-service`,
      );
      notLoginSocketRef.current = socketIOClient.io(
        `${process.env.REACT_APP_API_URL}/end-user-no-login-chat`,
      );
    }
    return () => {
      socketRef.current.disconnect();
      if (user.roleId === USER_ROLE_ID.CONTENT_ROLE) {
        // check if user is Content Role
        subSocketRef.current?.disconnect();
        notLoginSocketRef.current?.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const resp = await accountApi.getAllAccountsFullName(
          { organizationId: user.organizationId },
          token,
        );
        if (resp.success) {
          setUserList(
            resp.data
              .filter((item) => item.id !== user.id)
              .map((item) => ({
                id: item.id,
                value: item.id,
                avatar: item.avatar,
                email: item.email || item.phone,
                label: `${item.fullname} (${item.email || item.phone})`,
              })),
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserList();
  }, []);

  useEffect(() => {
    dispatch(fetchRoomIds(token, user.id));
  }, [user.id]);

  useEffect(() => {
    if (user.roleId === USER_ROLE_ID.CONTENT_ROLE) {
      dispatch(getAllEndUserNotification());
    }
  }, []);

  useEffect(() => {
    if (userList?.length > 0) {
      socketRef.current?.on("Server-send-all-user", (resp) => {
        if (
          resp.receiverUserId === user.id ||
          user.roleId === USER_ROLE_ID.CONTENT_ROLE
        ) {
          const senderUser = userList.find(
            (item) => item.id === resp.senderUserId,
          );
          dispatch(
            setInitRoomsNotification(resp.roomchatId, resp.senderUserId),
          );
          if (
            chatWindows.find((item) => item.roomChatId === resp.roomChatId) ||
            chatIcons.find((item) => item.roomChatId === resp.roomChatId)
          ) {
            dispatch(
              handleInitChatWindow(
                token,
                chatWindows.find((item) => item.roomChatId === resp.roomChatId)
                  ?.id,
                user,
                {
                  username: senderUser?.label || resp?.senderEmail,
                  id: resp.senderUserId,
                  avatar: senderUser?.avatar || resp?.senderAvatar,
                },
              ),
            );
          } else {
            dispatch(
              handleInitChatWindow(token, "new-message", user, {
                username: senderUser?.label || resp?.senderEmail,
                id: resp.senderUserId,
                avatar: senderUser?.avatar || resp?.senderAvatar,
              }),
            );
          }
        }
      });
    }
    if (user.roleId === USER_ROLE_ID.CONTENT_ROLE) {
      subSocketRef.current?.on("customer-service", () => {
        dispatch(getAllEndUserNotification());
      });
      notLoginSocketRef.current?.on("Server-send-all-user", (resp) => {
        dispatch(getAllEndUserNotification());
        dispatch(joinEndUserRoom(resp?.roomchatId));
      });
    }
  }, [userList]);

  useEffect(() => {
    dispatch(getAllNotification(user.id));
  }, [user.id]);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
  }, []);

  const handleToggleChatWindow = () => {
    if (endUserRooms?.length > 0 && user.roleId === USER_ROLE_ID.CONTENT_ROLE) {
      dispatch(joinEndUserRoom(endUserRooms?.[endUserRooms?.length - 1]));
    } else {
      dispatch(toggleChatWindow());
    }
  };

  const closeWindow = (id) => {
    dispatch(closeChatWindow(id));
  };

  const closeIcons = (id) => {
    dispatch(closeChatIcon(id));
  };

  const handleInitChat = async (id, userId, username, avatar) => {
    dispatch(
      handleInitChatWindow(token, id, user, { username, id: userId, avatar }),
    );
  };

  const handleHideChat = (id, messageList) => {
    dispatch(handleHideChatWindow(id, messageList));
  };

  const handleUnHide = (id, messageList, roomNoti) => {
    dispatch(handleUnHideChatWindow(id, messageList, roomNoti));
  };

  const handleCloseAll = () => {
    dispatch(handleCloseAllChat());
  };

  const handleHideAll = () => {
    dispatch(handleHideAllChat());
  };

  const renderFloatedButton = () => {
    return (
      <div className="chat-icon" onClick={handleToggleChatWindow}>
        <img src="/images/chat-icon.png" />
        {(chatWindows.filter((item) => item.status !== "new").length > 0 ||
          chatIcons.length > 0) && (
          <ChatActions onHideAll={handleHideAll} onCloseAll={handleCloseAll} />
        )}

        {/* { !chatWindows.find(item => item.roomChatId === roomNoti) &&
          <div className={`notification-message ${!!roomNoti ? "show" : ""}`}>
            Có tin nhắn mới
          </div> 
        } */}

        {(endUserRooms?.length > 0 ||
          roomsInitNoti?.length > 0 ||
          roomsNoti?.length > 0) && <div className="all-noti"></div>}
      </div>
    );
  };

  useEffect(() => {
    if (!location?.pathname?.startsWith("/chats")) {
      dispatch(handleCloseAllChat());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="chat-box">
      <div className="chat-windows">
        {chatWindows?.map((item) => (
          <ChatWindow
            key={item.id}
            isNew={item.status === "new"}
            item={item}
            onClose={closeWindow}
            handleInitChat={handleInitChat}
            onHide={handleHideChat}
            userList={userList}
            userId={user.id}
            userAvatar={user.avatar}
            socket={socketRef.current}
            subSocket={notLoginSocketRef.current}
            token={token}
            waitMessage={waitMessage}
          />
        ))}
      </div>

      <div className="chat-user-icons">
        {chatIcons?.length > 5 && (
          <SubChatUserIcon
            items={chatIcons?.slice(5, chatIcons.length)}
            onClose={closeIcons}
            onUnHide={handleUnHide}
            roomsNoti={roomsNoti}
          />
        )}
        {chatIcons?.slice(0, 5).map((item) => (
          <ChatUserIcon
            key={item.id}
            item={item}
            onUnHide={handleUnHide}
            onClose={closeIcons}
            socket={socketRef.current}
            roomNoti={roomsNoti.find(
              (elm) => elm?.roomChatId === item?.roomChatId,
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default Chat;
