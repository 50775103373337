import { Box } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import RestoreIcon from "@material-ui/icons/Restore";
import TodayIcon from "@material-ui/icons/Today";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
// import ZoomSelection from "assets/icon/dashboard-icon/zoom-selection";
// import { useState } from "react";
import { HEADER_TOOLBAR_ICONS } from "../constant";
import {
  onResetZoom,
  onZoomIn,
  onZoomOut,
  shouldRenderToolbarIcon,
  // toggleZoomSelect,
} from "app/shared/components/Dashboard/Echart/echart.utils";

function ToolbarButton({ icon = null, onClick = () => {} }) {
  const Component = icon;

  if (Component === null) {
    return null;
  }

  return (
    <Component
      onClick={onClick}
      onMouseDown={(e) => e.stopPropagation()}
      style={{ cursor: "pointer" }}
    />
  );
}

export default function StandardChartToolbar({
  standardChartData = {},
  isPublic = false,
  showHeader,
  toggleFilter = () => {},
  toggleFullScreen = () => {},
}) {
  const { id } = standardChartData;

  // const [zoomSelectState, setZoomSelectState] = useState(false);

  return (
    <Box
      display="flex"
      ml="auto"
      gridGap="4px"
      color={showHeader ? "#fff" : "#111"}
      p={showHeader ? 0 : 2}
    >
      {shouldRenderToolbarIcon(
        standardChartData,
        isPublic,
        HEADER_TOOLBAR_ICONS.RESTORE_ICON
      ) && <ToolbarButton icon={RestoreIcon} onClick={() => onResetZoom(id)} />}

      {/* <ZoomSelection
        height="24px"
        width="24px"
        style={{ cursor: "pointer" }}
        onClick={() =>
          toggleZoomSelect(id, zoomSelectState, setZoomSelectState)
        }
        onMouseDown={(e) => e.stopPropagation()}
      /> */}

      {shouldRenderToolbarIcon(
        standardChartData,
        isPublic,
        HEADER_TOOLBAR_ICONS.TODAY_ICON
      ) && <ToolbarButton icon={TodayIcon} onClick={toggleFilter} />}

      {shouldRenderToolbarIcon(
        standardChartData,
        isPublic,
        HEADER_TOOLBAR_ICONS.ZOOM_OUT_ICON
      ) && <ToolbarButton icon={ZoomOutIcon} onClick={() => onZoomOut(id)} />}
      {shouldRenderToolbarIcon(
        standardChartData,
        isPublic,
        HEADER_TOOLBAR_ICONS.ZOOM_IN_ICON
      ) && <ToolbarButton icon={ZoomInIcon} onClick={() => onZoomIn(id)} />}

      {shouldRenderToolbarIcon(
        standardChartData,
        isPublic,
        HEADER_TOOLBAR_ICONS.FULLSCREEN_ICON
      ) && <ToolbarButton icon={FullscreenIcon} onClick={toggleFullScreen} />}
    </Box>
  );
}
