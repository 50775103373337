/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import GridLayout from "react-grid-layout";
import DashboardCard from "./components/DashboardCard";
import DashboardModal from "./components/Modal";
import MenuModal from "./components/Modals/MenuModal";

import { CircularProgress, Container } from "@material-ui/core";
import stationApi from "app/api/stationApi";
import PageLoading from "app/shared/components/Loader/PageLoading";
import _ from "lodash";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import socketIOClient from "socket.io-client";
import workspaceApi from "../../api/workspaceApi";
import workspaceDashboardApi from "../../api/workspaceDashboardApi";
import MainLayout from "../../shared/layouts/MainLayout";
import { hasDashboardPermission } from "../../shared/utils/functions";
import { DashboardHeader } from "./components/DashboardHeader";
import { NoData } from "./components/NoData";
import { CHECK_SAVE_MODE, PAGE_MODE, columns, rowHeight } from "./constant";
import useDashboard from "./hooks/useDashboard";
import "./styles.css";
import useDetectDevice from "app/shared/hooks/useDetectDevice";

const Dashboard = (props) => {
  const intl = useIntl();
  const { deviceType } = useDetectDevice();
  const {
    publicSocketRef,
    socketRef,
    showMenu,
    showTopLogo,
    items,
    openStates,
    configData,
    activeItem,
    data,
    realTimeData,
    realTimeDataAlarm,
    pageLoading,
    itemsLoading,
    onPressCtrlKey,
    screenSize,
    layoutOld,
    dashboardView,
    screenMode,
    pageMode,
    viewMax,
    layout,
    initLayout,
    resetLayout,
    toggleModal,
    toggleMenu,
    setRealTimeDataAlarm,
    setRealTimeData,
    setConfigData,
    allowDragging,
    controlKeyHandler,
    onLayoutChange,
    getAllDashboard,
    getWorkspaceParameters,
    getWorkSpaceStations,
    onEditCardItem,
    onRemoveItem,
    onEditItem,
    onCreateItem,
    setLayoutDashboard,
    handleResize,
    getAllDashboardView,
    activeScreen,
    screenEdit,
    modalConfirmSave,
    setCheckPageMode,
    isRendering,
    setIsRendering,
    gridResizeEvent,
    setGridResizeEvent,
  } = useDashboard({ intl, workspaceDashboardApi, workspaceApi, stationApi });
  const { dashboard: dashboardState } = useSelector(
    ({ dashboard }) => ({
      dashboard,
    }),
    shallowEqual,
  );

  const { token, currentDashboard } = useSelector(
    ({ user, dashboard }) => ({
      token: user.token,
      user: user.user,
      currentDashboard: dashboard,
    }),
    shallowEqual,
  );

  const renderChildrenCard = () => {
    return _.map(items, (item) => (
      <div className="dashboard-card" key={item.id.toString()}>
        <DashboardCard
          // item={item}
          type={item["type"]}
          data={item["data"]}
          onEdit={onEditCardItem}
          loading={itemsLoading[item.id]}
          onRemove={onRemoveItem}
          id={item["id"]}
          isView={pageMode !== PAGE_MODE.EDIT || !hasDashboardPermission(data)}
          dragging={onPressCtrlKey}
          onEditDashboard={onEditItem}
          realTimeData={realTimeData}
          token={token}
          realTimeDataAlarm={realTimeDataAlarm}
          isRendering={isRendering}
          gridResizeEvent={gridResizeEvent}
          setGridResizeEvent={setGridResizeEvent}
        />
      </div>
    ));
  };

  useEffect(() => {
    if (dashboardView?.length > 0 && pageMode !== PAGE_MODE.ARRANGE) {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    } else if (pageMode === PAGE_MODE.ARRANGE) {
      window.removeEventListener("resize", handleResize);
    }
  }, [dashboardView, pageMode]);

  useEffect(() => {
    if (dashboardView?.length > 0) {
      // handleResize();
      screenEdit();
    }
  }, [pageMode]);

  useEffect(() => {
    screenEdit();
  }, [screenMode]);

  useEffect(() => {
    getAllDashboardView();

    document.addEventListener("keydown", controlKeyHandler);
    document.addEventListener("keyup", allowDragging);

    return () => {
      document.removeEventListener("keydown", controlKeyHandler);
      document.removeEventListener("keyup", allowDragging);
    };
  }, []);

  // get all dashboard
  useEffect(() => {
    configData.workspaceId && getWorkspaceParameters(configData.workspaceId);
    configData.workspaceId && getWorkSpaceStations(configData.workspaceId);
    configData.dashboardId && getAllDashboard(true);
  }, [configData.workspaceId, configData.dashboardId]);

  useEffect(() => {
    const dashboardId = parseInt(props.match.params.id);
    const workspaceId = parseInt(props.match.params.workspaceId);
    setConfigData((pre) => ({
      ...pre,
      workspaceId,
      dashboardId,
    }));
  }, [props.match.params.id, props.match.params.workspaceId]);

  useEffect(() => {
    socketRef.current = socketIOClient.io(
      `${process.env.REACT_APP_API_URL}/dashboard`,
    );
    publicSocketRef.current = socketIOClient.io(
      `${process.env.REACT_APP_API_URL}/dashboard-alarm`,
    );
    return () => {
      socketRef.current.disconnect();
      publicSocketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    const data = {
      token,
      room: configData.workspaceId,
    };
    socketRef.current?.emit("join", data);
    publicSocketRef.current?.emit("join", data);
  }, [configData.workspaceId]);

  useEffect(() => {
    socketRef.current?.on("ari-realtime-data", (resp) => {
      setRealTimeData(resp);
    });
    publicSocketRef.current?.on("ari-realtime-data", (resp) => {
      setRealTimeDataAlarm(resp);
    });
  }, []);
  useEffect(() => {
    if (data.id) {
      getAllDashboard(false);
    }
  }, [dashboardState.reload]);

  useEffect(() => {
    initLayout();
  }, [items]);

  useEffect(() => {
    if (isRendering === true) {
      let timer = setTimeout(() => {
        setIsRendering(false);
        clearTimeout(timer);
      }, 2000);
    }
  }, [isRendering]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenMode, pageMode]);

  return (
    <MainLayout
      title={intl.formatMessage({ id: "CLIENT.DASHBOARD.PAGE_TITLE" })}
      // breadcrumbs={
      //   (
      //     configData.dashboardId === currentDashboard.id
      //       ? currentDashboard.name
      //       : data["name_en"]
      //   )
      //     ? [
      //         {
      //           id: 1,
      //           link: props.location.pathname,
      //           name: intl.formatMessage({ id: "CLIENT.DASHBOARD.PAGE_TITLE" }),
      //         },
      //       ]
      //     : []
      // }
    >
      <PageLoading loading={pageLoading}>
        <Container maxWidth={false}>
          {showTopLogo && (
            <div className="dashboard-top-header mb-4">
              <img
                src="https://eoffice.reecotech.com.vn/bitrix/templates/login/logo150.png"
                alt=""
                className="dashboard-logo"
              />

              {Object.keys(openStates)?.map((item) => (
                <DashboardModal
                  key={item}
                  type={item}
                  editing={true}
                  open={openStates[item]}
                  onClose={toggleModal}
                  onEdit={onEditItem}
                  id={activeItem["id"]}
                  data={activeItem["data"]}
                  token={token}
                  configData={configData}
                />
              ))}
            </div>
          )}

          <DashboardHeader
            dashboard={data}
            onCreate={toggleMenu}
            // isEditMode={isEditMode}
            // setIsEditMode={setIsEditMode}
            screenMode={screenMode}
            setScreenMode={activeScreen}
            pageMode={pageMode}
            setPageMode={setCheckPageMode}
            hasPermission={hasDashboardPermission(data)}
            dashboardView={dashboardView}
            onLayoutChange={onLayoutChange}
            disableSaveScreen={!layoutOld}
            resetLayout={resetLayout}
            isRendering={isRendering}
            deviceType={deviceType}
          />

          {/* <Box mb={3} pt={1}>
            <div className="dashboard-header">
              <h2 className="main-title">
                {configData.dashboardId === currentDashboard.id
                  ? currentDashboard.name
                  : data["name_en"]}
              </h2>

              {hasDashboardPermission(data) && (
                <Button
                  type="button"
                  className="custom-button"
                  onClick={toggleMenu}
                >
                  <FormattedMessage id="CLIENT.DASHBOARD.NEW_ITEM" />
                </Button>
              )}
            </div>
          </Box> */}

          {isRendering && (
            <div
              style={{
                width: 96,
                height: 96,
                textAlign: "center",
                background: "white",
                position: "fixed",
                zIndex: 999,
                top: "50%",
                left: "55%",
                transform: "translate(-55%, -50%)",
                borderRadius: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
            >
              <CircularProgress color="inherit" size={42} />
            </div>
          )}

          <div
            className={`dashboard-body ${
              pageMode !== PAGE_MODE.ARRANGE ? "hidenMove" : "border-arrange"
            }`}
            style={{
              maxWidth:
                pageMode !== PAGE_MODE.ARRANGE || viewMax === screenMode
                  ? "100%"
                  : screenSize,
              position: "relative",
              paddingTop: 24,
              visibility: isRendering ? "hidden" : "visible",
            }}
          >
            {items.length > 0 ? (
              <ReactResizeDetector
                handleWidth
                handleHeight
                refreshRate="500"
                refreshMode="debounce"
                style={{
                  background: pageMode === PAGE_MODE.ARRANGE ? "#E0E0E0" : null,
                }}
              >
                {({ width, height }) => (
                  <GridLayout
                    cols={columns}
                    rowHeight={rowHeight}
                    width={width}
                    onLayoutChange={(e) => setLayoutDashboard(e)}
                    // isDraggable={
                    //   !isEditMode
                    //     ? false
                    //     : !onPressCtrlKey && hasDashboardPermission(data)
                    // }
                    isDraggable={
                      pageMode === PAGE_MODE.ARRANGE &&
                      !onPressCtrlKey &&
                      hasDashboardPermission(data)
                    }
                    isResizable={pageMode === PAGE_MODE.ARRANGE}
                    verticalCompact={false}
                    preventCollision={true}
                    layout={layout}
                    onResizeStart={() =>
                      setGridResizeEvent((pre) => ({
                        ...pre,
                        isResized: false,
                      }))
                    }
                    onResizeStop={(_, oldItem) =>
                      setGridResizeEvent((pre) => ({
                        ...pre,
                        cardId: oldItem.i,
                      }))
                    }
                  >
                    {renderChildrenCard()}
                  </GridLayout>
                )}
              </ReactResizeDetector>
            ) : (
              <NoData />
            )}
          </div>
        </Container>
      </PageLoading>

      <MenuModal
        open={showMenu}
        handleClose={toggleMenu}
        handleCreate={onCreateItem}
      />

      {Object.keys(openStates)?.map((item) => (
        <DashboardModal
          key={item}
          type={item}
          editing={true}
          open={openStates[item]}
          onClose={toggleModal}
          onEdit={onEditItem}
          id={activeItem["id"]}
          data={activeItem["data"]}
          token={token}
          configData={configData}
        />
      ))}
      <Prompt
        when={layoutOld}
        message={(location) => {
          modalConfirmSave(location, CHECK_SAVE_MODE.NAVIGATE);
          return false;
        }}
      ></Prompt>
    </MainLayout>
  );
};

export default Dashboard;
