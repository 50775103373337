import PersonIcon from "../../../../../../assets/images/user-color.svg";
import BusinessIcon from "../../../../../../assets/images/organization-color.svg";
import LockIcon from "../../../../../../assets/images/lock-color.svg";
import ChatIcon from "@material-ui/icons/Chat";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ApiKeyIcon from "../../../../../../assets/images/api-key-icon.svg";
import MqttIcon from "../../../../../../assets/images/mqtt-icon.svg";
import { FEATURE_ID } from "app/constant/features";
import { isFeatureEnabled } from "app/utils/features";

export const USER_MENUS = (intl) => {
  return [
    {
      id: 1,
      name: intl.formatMessage({ id: "CLIENT.PROFILE.PAGE_TITLE" }),
      description: intl.formatMessage({ id: "CLIENT.GENERAL.MY_PROFILE_DES" }),
      icon: PersonIcon,
      link: "/profile/edit",
      code: "my_profile",
      enabled: true,
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "CLIENT.ORGANIZATION_ACTIONS.MY_ORGANIZATION_TITLE",
      }),
      description: intl.formatMessage({
        id: "CLIENT.GENERAL.MY_ORGANIZATION_DES",
      }),
      icon: BusinessIcon,
      link: "/organization/edit",
      code: "my_organization",
      enabled: true,
    },
    {
      id: 3,
      name: intl.formatMessage({ id: "CLIENT.CHANGE_PASSWORD.PAGE_TITLE" }),
      description: intl.formatMessage({
        id: "CLIENT.GENERAL.CHANGE_PASSWORD_DES",
      }),
      icon: LockIcon,
      link: "/profile/change-password",
      code: "change_password",
      enabled: true,
    },
    {
      id: 4,
      name: intl.formatMessage({ id: "CLIENT.CHATS.PAGE_TITLE" }),
      description: intl.formatMessage({ id: "CLIENT.GENERAL.CHAT_DES" }),
      icon: <ChatIcon />,
      link: "/chats",
      code: "chats",
      enabled: true,
    },
    {
      id: 5,
      name: intl.formatMessage({
        id: "CLIENT.NOTIFICATIONS_MANAGEMENT.NOTIFICATIONS",
      }),
      description: intl.formatMessage({
        id: "CLIENT.GENERAL.NOTIFICATION_DES",
      }),
      icon: <NotificationsIcon />,
      link: "/notifications",
      code: "my_notifications",
      enabled: true,
    },
    {
      id: 6,
      name: intl.formatMessage({
        id: "CLIENT.NOTIFICATIONS_MANAGEMENT.API_KEYS",
      }),
      description: intl.formatMessage({
        id: "CLIENT.GENERAL.API_KEYS_DES",
      }),
      icon: ApiKeyIcon,
      link: "/api-keys",
      code: "change_password",
      enabled: isFeatureEnabled(FEATURE_ID.API_KEYS),
    },
    {
      id: 7,
      name: intl.formatMessage({
        id: "CLIENT.MQTT.MQTT_MENU_TITLE",
      }),
      description: intl.formatMessage({
        id: "CLIENT.MQTT.MQTT_MENU_DESC",
      }),
      icon: MqttIcon,
      link: "/mqtt",
      code: "change_password",
      enabled: isFeatureEnabled(FEATURE_ID.MQTT),
    },
  ];
};
