import {
  SET_DASHBOARD_NAME,
  SET_ACTIVE_SUB_ITEM,
  SET_RELOAD_DATA_DASHBOARD,
  SET_DASHBOARD,
} from "./types";

export const updateCurrentDashboard = (dashboard) => {
  return {
    type: SET_DASHBOARD_NAME,
    payload: dashboard,
  };
};

export const setActiveSubItem = (itemId) => {
  return {
    type: SET_ACTIVE_SUB_ITEM,
    payload: itemId,
  };
};

export const reloadDataDashboard = (reload) => {
  return {
    type: SET_RELOAD_DATA_DASHBOARD,
    payload: reload,
  };
};

export const setDashboardSetting = (dashboard) => {
  return {
    type: SET_DASHBOARD,
    payload: dashboard,
  };
};
