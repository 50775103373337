import { Box } from "@material-ui/core";
import SharedInputField from "app/shared/components/SharedInputField";
import { FormattedMessage } from "react-intl";

const ContactInformation = ({mainData, formik, intl, clsx}) => {
  return (
    <>
      <h3 className="sub-title">
        <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.CONTACT_TITLE" />
      </h3>
      <Box mb={3}>
        <SharedInputField
          name="fullname"
          label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FULL_NAME_LABEL' })}
          type="text"
          required={mainData.status && mainData.status.id === 1}
          placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.FULL_NAME_HINT' })}
          {...formik.getFieldProps("fullname")}
          disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
          className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
          errorMsg={formik.touched["fullname"] && formik.errors["fullname"]}
        />
      </Box>

      <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
        <Box width="49%"
        >
          <SharedInputField
            name="email"
            label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.EMAIL_LABEL' })}
            type="text"
            placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.EMAIL_HINT' })}
            {...formik.getFieldProps("email")}
            disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
            className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
            errorMsg={formik.touched["email"] && formik.errors["email"]}
          />
        </Box>
        <Box width="49%"
        >
          <SharedInputField
            name="phone"
            label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.PHONE_NUMBER_LABEL' })}
            type="text"
            required={mainData.status && mainData.status.id === 1}
            placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.PHONE_NUMBER_HINT' })}
            {...formik.getFieldProps("phone")}
            disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
            className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
            errorMsg={formik.touched["phone"] && formik.errors["phone"]}
          />
        </Box>
      </Box>

      <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
        <Box width="49%">
          <SharedInputField
            name="organization_name"
            label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_LABEL' })}
            placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_HINT' })}
            type="text"
            {...formik.getFieldProps("organization_name")}
            disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
            className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
            errorMsg={formik.touched["organization_name"] && formik.errors["organization_name"]}
          />
        </Box>
        <Box width="49%">
          <SharedInputField
            name="organization_address"
            label={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_ADDRESS_LABEL' })}
            placeholder={intl.formatMessage({ id: 'CLIENT.DATA_SHARING_REQUEST_ACTIONS.ORGANIZATION_ADDRESS_HINT' })}
            type="text"
            {...formik.getFieldProps("organization_address")}
            disabled={mainData.status && mainData.status.id !== 1 ? true : formik.isSubmitting}
            className={clsx(mainData.status && mainData.status.id !== 1 && "disabled-field", "")}
            errorMsg={formik.touched["organization_address"] && formik.errors["organization_address"]}
          />
        </Box>
      </Box>
    </>
  )
}

export default ContactInformation