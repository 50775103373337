import { CircularProgress, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ViewListIcon from "@material-ui/icons/ViewList";
import { DASHBOARD_TYPE } from "app/shared/constants/dashboard";
import { invertColor } from "app/utils/utils";
import clsx from "clsx";
import {
  cloneDeep,
  findIndex,
  groupBy,
  isEmpty,
  keyBy,
  map,
  reverse,
} from "lodash";
import moment from "moment";
import { useEffect, useState, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import workspaceDashboardApi from "../../../../api/workspaceDashboardApi";
import { DATE_FORMAT } from "../../../../constant/date-time-format";
import { useLanguage } from "../../../../shared/hooks";
import { formatString } from "../../../../utils/string-formatter";
import { DateTimeFiltersModal } from "app/shared/components/DateTimeFiltersModal";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";

const AggregateTableCard = (props) => {
  const [language] = useLanguage();

  // create name
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.ITEM.AGGREGATE_TABLE_NEW" />
  );
  // create header color
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";

  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;
  const displayMode =
    (props["data"] && props["data"]?.dashboard_aggregate_table?.display_mode) ||
    "portrait";
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [tableConfig, setTableConfig] = useState(null); // using for prediction table

  const [showsFilters, setShowsFilters] = useState(false);
  const [filterStartTime, setFilterStartTime] = useState(
    props?.data?.dashboard_aggregate_table?.start_date
      ? moment(props?.data?.dashboard_aggregate_table?.start_date)
      : null
  );
  const [filterEndTime, setFilterEndTime] = useState(
    props?.data?.dashboard_aggregate_table?.end_date
      ? moment(props?.data?.dashboard_aggregate_table?.end_date)
      : null
  );
  const [filterRangeOption, setFilterRangeOption] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const { width: cardWidth, ref: cardRef } = useResizeDetector();

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async ({ id, params = null }) => {
    enableLoading();

    try {
      let res;

      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id, params);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token, params);
      }

      if (res.success) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      disableLoading();
    }
    return null;
  };

  const sortDataByParameterId = (data) => {
    data?.sort((a, b) => {
      if (a?.order_number > b?.order_number) {
        return 1;
      }
      if (a?.order_number < b?.order_number) {
        return -1;
      }
      return 0;
    });
    return data;
  };

  const sortDataByFunctionType = (data) => {
    data?.sort((a, b) => {
      if (a?.function_type > b?.function_type) {
        return 1;
      }
      if (a?.function_type < b?.function_type) {
        return -1;
      }
      return 0;
    });
    return data;
  };

  const prepareDataObject = (data) => {
    let functionLabel = data?.functions || [];
    let parameterData = data?.parameterData || [];
    let parameterLabel = [];
    let functionData = {};

    if (displayMode === "landscape") {
      parameterLabel =
        data?.parameterData?.map((item) => ({ ...item.parameter })) || [];
      for (const item of data?.functions) {
        functionData[item] = [];
      }

      for (const item of parameterData) {
        const keyByFunction = keyBy(item?.data, "function_type");

        for (const key in keyByFunction) {
          const data = keyByFunction[key];
          functionData[key].push({
            ...item?.parameter,
            ...data,
          });
        }
      }
      Object.keys(functionData).forEach((key) => {
        functionData[key] = sortDataByParameterId(functionData[key]);
      });
      parameterLabel = sortDataByParameterId(parameterLabel);
    } else {
      parameterData.forEach((item, index) => {
        parameterData[index].data = sortDataByFunctionType(item.data);
      });
      functionLabel = functionLabel.sort();
    }

    return {
      dataGroup: data?.parameterData || [],
      functionLabel,
      dataReverseGroup: {
        parameterLabel,
        functionData,
      },
    };
  };

  const getWidth = (n) => {
    //  && n * COLUMN_WIDTH + 240 > cardWidth
    if (n > 2) {
      return 160;
    }

    if (n === 1) n = 2;

    if (cardWidth / n <= 120) {
      return cardWidth / n;
    }

    return 160;
  };

  const TableHeader = () => {
    return (
      <table className="aggregate-table-header-container">
        <tr
          className={clsx(
            { "aggregate-table-header": tableConfig === null },
            { "aggregate-table-custom": tableConfig }
          )}
        >
          <th
            style={
              tableConfig
                ? {
                    width: getWidth(data?.functionLabel?.length),
                    minWidth: getWidth(data?.functionLabel?.length),
                    backgroundColor: `${tableConfig.column_header_color}`,
                    color: `${invertColor(
                      tableConfig.column_header_color,
                      true
                    )}`,
                    borderColor: `${invertColor(
                      tableConfig.column_header_color,
                      true
                    )}`,
                  }
                : {
                    width: getWidth(data?.functionLabel?.length),
                    minWidth: getWidth(data?.functionLabel?.length),
                  }
            }
          >
            <div>
              <FormattedMessage id="CLIENT.DASHBOARD.AGGREGATE_TABLE.PARAMETER_NAME" />
            </div>
            <div>
              (
              <FormattedMessage id="CLIENT.DASHBOARD.AGGREGATE_TABLE.UNIT" />)
            </div>
          </th>

          {data?.functionLabel?.map((method) => {
            return (
              <th
                style={
                  tableConfig
                    ? {
                        width: getWidth(data?.functionLabel?.length),
                        minWidth: getWidth(data?.functionLabel?.length),
                        backgroundColor: `${tableConfig.column_header_color}`,
                        color: `${invertColor(
                          tableConfig.column_header_color,
                          true
                        )}`,
                        borderBottomColor: `${invertColor(
                          tableConfig.column_header_color,
                          true
                        )}`,
                        borderColor: `${invertColor(
                          tableConfig.column_header_color,
                          true
                        )}`,
                      }
                    : {
                        width: getWidth(data?.functionLabel?.length),
                        minWidth: getWidth(data?.functionLabel?.length),
                      }
                }
              >
                <div>
                  <FormattedMessage
                    id={`CLIENT.DASHBOARD.AGGREGATE_TABLE.SUMMARIZE.${method}`}
                  />
                </div>
              </th>
            );
          })}
        </tr>
      </table>
    );
  };

  const Row = () => {
    const parameters = data?.dataGroup;
    return parameters?.map((item) => {
      return (
        <table
          className="aggregate-table"
          style={{
            minWidth: cardWidth,
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <tr>
            <th
              style={{
                width: getWidth(data?.functionLabel?.length),
                minWidth: getWidth(data?.functionLabel?.length),
              }}
            >
              <div>
                {item?.parameter?.display_name ||
                  item?.parameter?.[`name_${language}`]}
              </div>
              <div>({item?.parameter?.unit})</div>
            </th>

            {item?.data?.map((data) => {
              return (
                <td
                  style={{
                    width: getWidth(data?.functionLabel?.length),
                    minWidth: getWidth(data?.functionLabel?.length),
                    backgroundColor: `${item?.parameter?.main_color}`,
                    color: `${invertColor(item?.parameter?.main_color, true)}`,
                    borderColor: `${invertColor(
                      item?.parameter?.main_color,
                      true
                    )}`,
                    borderBottomColor: `${invertColor(
                      item?.parameter?.main_color,
                      true
                    )}`,
                  }}
                >
                  {data?.value?.toFixed(2)}
                </td>
              );
            })}
          </tr>
        </table>
      );
    });
  };

  const TableHeaderReverse = () => {
    const {
      dataReverseGroup: { parameterLabel },
    } = data;
    return (
      <table
        className="aggregate-table-header-container"
        style={{ borderCollapse: "separate", borderSpacing: 0 }}
      >
        <tr className="aggregate-table-header">
          <th
            style={{
              width: getWidth(data?.functionLabel?.length),
              minWidth: getWidth(data?.functionLabel?.length),
            }}
          >
            <div>
              <FormattedMessage id="CLIENT.DASHBOARD.AGGREGATE_TABLE.FUNCTION" />
            </div>
          </th>

          {parameterLabel?.map((item) => (
            <th
              style={{
                width: getWidth(data?.functionLabel?.length),
                minWidth: getWidth(data?.functionLabel?.length),
              }}
            >
              <div>{item?.display_name || item?.[`name_${language}`]}</div>
              <div>({item?.unit})</div>
            </th>
          ))}
        </tr>
      </table>
    );
  };

  const RowReverse = () => {
    const { dataReverseGroup } = data;
    return (
      <table
        className="aggregate-table"
        style={{
          minWidth: cardWidth,
          borderCollapse: "separate",
          borderSpacing: 0,
        }}
      >
        {data?.functionLabel?.map((method) => {
          return (
            <tr>
              <th
                style={{
                  width: getWidth(data?.parameterLabel?.length),
                  minWidth: getWidth(data?.parameterLabel?.length),
                }}
              >
                <FormattedMessage
                  id={`CLIENT.DASHBOARD.AGGREGATE_TABLE.SUMMARIZE.${method}`}
                />
              </th>

              {dataReverseGroup?.functionData?.[method]?.map((item) => {
                return (
                  <td
                    style={{
                      width: getWidth(data?.parameterLabel?.length),
                      minWidth: getWidth(data?.parameterLabel?.length),
                      backgroundColor: `${item?.main_color}`,
                      color: `${invertColor(item?.main_color, true)}`,
                      borderColor: `${invertColor(item?.main_color, true)}`,
                      borderBottomColor: `${invertColor(
                        item?.main_color,
                        true
                      )}`,
                    }}
                  >
                    {item?.value?.toFixed(2)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    );
  };

  const handleApplyFilters = (data) => {
    setFilterStartTime(data?.startTime);
    setFilterEndTime(data?.endTime);
    setFilterRangeOption(data?.rangeOption);
  };

  const fetchData = async (rangeOption) => {
    let params = {};

    if (rangeOption !== null) {
      params = {
        start_time: filterStartTime?.utc()?.format(),
        end_time: filterEndTime?.utc()?.format(),
      };
    }

    const dashboardData = await loadDataDashboard({
      id: props.isPublic ? props.data?.public_id : props.data?.id,
      params,
    });

    if (dashboardData?.tableConfig) {
      setTableConfig(dashboardData.tableConfig);
    }

    const dataObject = prepareDataObject(dashboardData);

    setData(dataObject);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const tooltipTitle = useMemo(() => {
    if (filterRangeOption) {
      return `${filterStartTime?.format(DATE_FORMAT)} - ${filterEndTime?.format(
        DATE_FORMAT
      )}`;
    } else {
      return `${moment(
        props?.data?.dashboard_aggregate_table?.start_date
      )?.format(DATE_FORMAT)} - ${moment(
        props?.data?.dashboard_aggregate_table?.end_date
      )?.format(DATE_FORMAT)}`;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEndTime, filterStartTime]);

  useEffect(() => {
    if (props?.data && props?.data?.dashboard_aggregate_table) {
      setFilterStartTime(
        moment(props?.data?.dashboard_aggregate_table?.start_date)
      );
      setFilterEndTime(
        moment(props?.data?.dashboard_aggregate_table?.end_date)
      );
      setShowTooltip(
        props?.data?.dashboard_aggregate_table?.start_date &&
          props?.data?.dashboard_aggregate_table?.end_date
      );
      setFilterRangeOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);

  useEffect(() => {
    fetchData(filterRangeOption);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEndTime, filterStartTime]);

  return (
    <div
      ref={cardRef}
      className={`aggregate-table-card aggregate-table-card--portrait dashboard-item-card
      ${
        displayMode === "landscape"
          ? "aggregate-table-card--landscape"
          : "aggregate-table-card--portrait"
      }
      ${
        cardWidth <= 300
          ? "aggregate-table-card--mobile"
          : "aggregate-table-card--normal"
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {showHeader && (
        <div className="aggregate-table-card-header">
          <p
            className="aggregate-table-card-title"
            style={{
              background: headerColor,
              color: invertColor(headerColor, true),
              borderColor: invertColor(headerColor, true),
              borderBottomColor: invertColor(headerColor, true),
            }}
          >
            <ViewListIcon />
            &nbsp;&nbsp;<span>{name}</span>
          </p>
        </div>
      )}

      <div className="aggregate-table-card-main">
        {(props["loading"] || loading) && (
          <div className="no-data-area">
            <p className="no-data-label">
              <CircularProgress />
            </p>
          </div>
        )}

        {!props[loading] && !loading && (
          <>
            {data && data?.dataGroup?.length > 0 ? (
              <>
                <table
                  className="aggregate-table"
                  style={{ borderCollapse: "separate", borderSpacing: 0 }}
                >
                  {displayMode === "portrait"
                    ? TableHeader()
                    : TableHeaderReverse()}
                  {displayMode === "portrait" ? Row() : RowReverse()}
                </table>
              </>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Line_chart_icon_Noun_70892_cc_White.svg/1200px-Line_chart_icon_Noun_70892_cc_White.svg.png"
        alt=""
        className="aggregate-table-card-image"
      />
      {showTooltip && (
        <Tooltip
          title={`${props?.intl.formatMessage({
            id: "CLIENT.DASHBOARD.AGGREGATE_TABLE.TIME_RANGE",
          })}: ${tooltipTitle}`}
          placement="right"
        >
          <span
            className="filters-tooltip"
            style={{
              left: 24,
              bottom: hovered && props["isView"] === false ? 64 : 24,
              boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
              border: "1px solid #eee",
            }}
            onClick={() => setShowsFilters(true)}
            onMouseDown={stopPropagation}
          >
            {filterRangeOption !== null && <span className="filters-on"></span>}
            <FilterListIcon />
          </span>
        </Tooltip>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />

      {showsFilters && (
        <DateTimeFiltersModal
          startTime={filterStartTime}
          endTime={filterEndTime}
          rangeOption={filterRangeOption}
          onClose={() => setShowsFilters(false)}
          onSubmit={handleApplyFilters}
          minTime={moment(props?.data?.dashboard_aggregate_table?.start_date)}
          maxTime={moment(props?.data?.dashboard_aggregate_table?.end_date)}
        />
      )}
    </div>
  );
};

export default injectIntl(AggregateTableCard);
