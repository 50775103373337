import {
  TOGGLE_CHAT_WINDOW,
  SET_CHAT_WINDOWS,
  SET_CHAT_ICONS,
  SET_ROOM_ID_LIST,
  SET_ROOMS_NOTI,
  SET_ROOMS_INIT_NOTI,
  RESET_DATA,
  SET_END_USER_ROOMS,
} from './types';
import chatApi from '../../api/chatApi';
import { v4 as uuidv4 } from 'uuid';
import DefaultAvatar from '../../../assets/images/user.png';
import swal from 'sweetalert';

export const toggleChatWindow = () => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows, chatIcons } = chat;
    if (chatWindows.find((item) => item.status === 'new')) return;
    if (chatWindows.length < 2) {
      const newWindows = {
        id: uuidv4(),
        status: 'new',
      };
      const newChatWindows = [...chatWindows, newWindows];
      dispatch({
        type: TOGGLE_CHAT_WINDOW,
        payload: {
          chatWindows: newChatWindows,
        },
      });
    } else {
      const newWindows = {
        id: uuidv4(),
        status: 'new',
      };
      const newChatWindows = [
        {
          ...chatWindows[0],
        },
        newWindows,
      ];
      const newChatIcons = [...chatIcons, chatWindows[1]];
      dispatch({
        type: TOGGLE_CHAT_WINDOW,
        payload: {
          chatWindows: newChatWindows,
          chatIcons: newChatIcons,
        },
      });
    }
  };
};

export const closeChatWindow = (id) => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows } = chat;
    const list = [...chatWindows];
    const index = list.findIndex((item) => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: SET_CHAT_WINDOWS,
        payload: {
          chatWindows: list,
        },
      });
    }
  };
};

export const closeChatIcon = (id) => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatIcons } = chat;
    const list = [...chatIcons];
    const index = list.findIndex((item) => item.id === id);
    if (index !== -1) {
      list.splice(index, 1);
      dispatch({
        type: SET_CHAT_ICONS,
        payload: {
          chatIcons: list,
        },
      });
    }
  };
};

export const handleHideChatWindow = (id, messageList, page) => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows, chatIcons } = chat;
    const item = chatWindows?.find((item) => item.id === id);
    const newChatIcons = [
      ...chatIcons,
      {
        ...item,
        data: messageList,
      },
    ];
    dispatch(closeChatWindow(id));
    dispatch({
      type: SET_CHAT_ICONS,
      payload: {
        chatIcons: newChatIcons,
      },
    });
  };
};

export const handleCloseAllChat = () => {
  return {
    type: TOGGLE_CHAT_WINDOW,
    payload: {
      chatWindows: [],
      chatIcons: [],
    },
  };
};

export const handleHideAllChat = () => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows, chatIcons } = chat;
    const list = [...chatWindows].filter((item) => item.status !== 'new');
    dispatch({
      type: TOGGLE_CHAT_WINDOW,
      payload: {
        chatWindows: [],
        chatIcons: chatWindows.length > 0 ? [...chatIcons, ...list] : [],
      },
    });
  };
};

export const removeRoomNotification = (roomChatId, roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { chat, user } = getState();
    const { token } = user;
    const { roomsNoti } = chat;
    try {
      if (roomsNoti?.find((elm) => elm?.roomChatId === roomChatId)) {
        const resp = await chatApi.removeChatNoti(
          { roomChatMemberId: roomChatMemberId },
          token
        );
        if (resp.success) {
          const list = [...roomsNoti];
          const index = list.findIndex((elm) => elm.roomChatId === roomChatId);
          list.splice(index, 1);
          dispatch(setRoomNotification(list));
        }
      }
    } catch (error) {}
  };
};

export const removeEndUserRoomNotification = (roomChatId) => {
  return async (dispatch, getState) => {
    const { chat, user } = getState();
    const { token } = user;
    const { endUserRooms } = chat;
    try {
      const resp = await chatApi.removeEndUserChatNoti({ roomChatId }, token);
      if (resp.success) {
        const list = [...endUserRooms];
        const index = list.indexOf(roomChatId);
        list.splice(index, 1);
        dispatch({
          type: SET_END_USER_ROOMS,
          payload: {
            endUserRooms: list,
          },
        });
      }
    } catch (error) {}
    // if (endUserRooms?.includes(roomChatId)) {
    // }
  };
};

export const removeRoomInitNotification = (roomChatMemberId) => {
  return async (dispatch, getState) => {
    const { user, chat } = getState();
    const { token } = user;
    const { roomsInitNoti } = chat;
    try {
      const resp = await chatApi.removeChatNoti({ roomChatMemberId }, token);
      if (resp.success) {
        const list = [...roomsInitNoti];
        const index = list.indexOf(roomChatMemberId);
        list.splice(index, 1);
        dispatch({
          type: SET_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti: list,
          },
        });
      }
    } catch (error) {}
  };
};

export const handleInitChatWindow = (token, id, user, userTarget) => {
  return async (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows, chatIcons, roomsNoti, roomsInitNoti } = chat;
    try {
      const index =
        chatWindows.findIndex((item) => item.userId === userTarget.id) !== -1
          ? chatWindows.findIndex((item) => item.userId === userTarget.id)
          : chatIcons.findIndex((item) => item.userId === userTarget.id);
      if (index === -1) {
        const data = {
          userSourceId: user.id,
          userTargetId: userTarget.id,
          limit: 8,
          page: 1,
        };
        const resp = await chatApi.initChatRoom(data, token);
        if (resp.success) {
          dispatch(
            removeRoomNotification(resp?.roomChatId, resp?.roomChatMemberId)
          );
          // console.log(roomsInitNoti, resp.roomChatMemberId)
          if (roomsInitNoti.includes(resp.roomChatMemberId)) {
            dispatch(removeRoomInitNotification(resp.roomChatMemberId));
          }
          if (id === 'new-message') {
            const newItem = {
              id: uuidv4(),
              status: 'init',
              userId: userTarget.id,
              roomChatMemberId: resp?.roomChatMemberId,
              username: userTarget.username,
              roomChatId: resp?.roomChatId,
              data: resp?.data?.reverse() || [],
              avatar: userTarget.avatar
                ? `${process.env.REACT_APP_API_URL}/${userTarget.avatar}`
                : DefaultAvatar,
            };
            if (chatWindows.length >= 2) {
              const newChatWindows = [
                newItem,
                {
                  ...chatWindows[0],
                },
              ];
              const newChatIcons = [...chatIcons, chatWindows[1]];
              dispatch({
                type: TOGGLE_CHAT_WINDOW,
                payload: {
                  chatWindows: newChatWindows,
                  chatIcons: newChatIcons,
                },
              });
            } else {
              dispatch({
                type: TOGGLE_CHAT_WINDOW,
                payload: {
                  chatWindows: [...chatWindows, newItem],
                },
              });
            }
          } else {
            const list = [...chatWindows].map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  status: 'init',
                  userId: userTarget.id,
                  roomChatMemberId: resp?.roomChatMemberId,
                  username: userTarget.username,
                  roomChatId: resp?.roomChatId,
                  data: resp?.data?.reverse() || [],
                  avatar: userTarget.avatar
                    ? `${process.env.REACT_APP_API_URL}/${userTarget.avatar}`
                    : DefaultAvatar,
                };
              }
              return item;
            });
            dispatch({
              type: SET_CHAT_WINDOWS,
              payload: {
                chatWindows:
                  id?.split('-')?.[0] === 'list'
                    ? [
                        ...list,
                        {
                          id,
                          status: 'init',
                          userId: userTarget.id,
                          roomChatMemberId: resp?.roomChatMemberId,
                          username: userTarget.username,
                          roomChatId: resp?.roomChatId,
                          data: resp?.data?.reverse() || [],
                          avatar:
                            userTarget.id === 1
                              ? 'https://i0.wp.com/cdn-prod.medicalnewstoday.com/content/images/articles/266/266749/aging-man.jpg?w=1155&h=1537'
                              : '/static/media/user-avatar.8c3ca8b3.jpg',
                        },
                      ]
                    : list,
              },
            });
          }
        }
      } else {
        if (
          chatWindows.findIndex((item) => item.userId === userTarget.id) !== -1
        ) {
          dispatch(closeChatWindow(id));
        }
        if (
          chatIcons.findIndex((item) => item.userId === userTarget.id) !== -1
        ) {
          const item = chatIcons[index];
          dispatch(
            handleUnHideChatWindow(
              item.id,
              item.data,
              roomsNoti.find((elm) => elm.roomChatId === item.roomChatId) &&
                item.roomChatMemberId,
              true
            )
          );
        }
      }
    } catch (error) {}
  };
};

export const setRoomNotification = (roomsNoti) => {
  return {
    type: SET_ROOMS_NOTI,
    payload: {
      roomsNoti,
    },
  };
};

export const handleUnHideChatWindow = (
  id,
  messageList,
  roomChatMemberId,
  isMediate = false
) => {
  return async (dispatch, getState) => {
    const { chat, user } = getState();
    const { chatWindows, chatIcons } = chat;
    const item = chatIcons.find((item) => item.id === id);
    // remove noti if have
    if (roomChatMemberId) {
      const roomChatId = chatIcons.find(
        (item) => item.roomChatMemberId === roomChatMemberId
      )?.roomChatId;
      dispatch(removeRoomNotification(roomChatId, roomChatMemberId));
    }
    if (chatWindows?.filter((elm) => elm.status !== 'new').length < 2) {
      const prevList = isMediate
        ? chatWindows.filter((elm) => elm.status !== 'new')
        : chatWindows;
      dispatch({
        type: SET_CHAT_WINDOWS,
        payload: {
          chatWindows: [
            ...prevList,
            {
              ...item,
              data: messageList,
            },
          ],
        },
      });
      dispatch(closeChatIcon(id));
    } else {
      const list = [...chatIcons];
      const index = list.findIndex((item) => item.id === id);
      list.splice(index, 1);
      dispatch({
        type: TOGGLE_CHAT_WINDOW,
        payload: {
          chatWindows: [
            {
              ...chatWindows[1],
            },
            {
              ...item,
              data: messageList,
            },
          ],
          chatIcons: [...list, chatWindows[0]],
        },
      });
    }
  };
};

export const fetchRoomIds = (token, userId) => {
  return async (dispatch, getState) => {
    try {
      const data = {
        userId,
      };
      const resp = await chatApi.getAllRoomIds(data, token);
      if (resp.success) {
        dispatch({
          type: SET_ROOM_ID_LIST,
          payload: {
            roomIdList: resp.data.map((item) => item.roomChatId),
          },
        });
      }
    } catch (error) {}
  };
};

export const setInitRoomsNotification = (roomChatId, userId) => {
  return (dispatch, getState) => {
    const { chat } = getState();
    const { chatWindows, roomsNoti } = chat;
    if (
      !chatWindows.length ||
      (chatWindows &&
        chatWindows?.length > 0 &&
        chatWindows.findIndex((item) => item.roomChatId === roomChatId) === -1)
    ) {
      dispatch({
        type: SET_ROOMS_NOTI,
        payload: {
          roomsNoti: [
            ...roomsNoti,
            {
              roomChatId,
              userId,
            },
          ],
        },
      });
    }
  };
};

export const getAllNotification = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { token } = user;
      const resp = await chatApi.getAllNoti({ userId }, token);
      if (resp.success && resp.data?.length > 0) {
        const roomsInitNoti = resp.data
          ?.filter((item) => item.isSeen === 0)
          ?.map((item) => item.roomChatMemberId);
        dispatch({
          type: SET_ROOMS_INIT_NOTI,
          payload: {
            roomsInitNoti,
          },
        });
      }
    } catch (error) {}
  };
};

export const getAllEndUserNotification = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { token } = user;

      const resp = await chatApi.getAllEndUserNoti(token);
      if (resp.success && resp.data?.length > 0) {
        const endUserRooms = resp.data
          ?.filter((item) => item.isSeen === 0)
          ?.map((item) => item.roomChatId);
        dispatch({
          type: SET_END_USER_ROOMS,
          payload: {
            endUserRooms,
          },
        });
      }
    } catch (error) {}
  };
};

export const joinEndUserRoom = (roomChatId) => {
  return async (dispatch, getState) => {
    try {
      const { user, chat } = getState();
      const { token } = user;
      const { chatWindows, roomsInitNoti } = chat;

      const data = {
        userId: user.user.id,
        roomChatId,
        page: 1,
        limit: 8,
      };
      const resp = await chatApi.joinEndUserRoom(data, token);
      if (resp.success) {
        dispatch(
          removeEndUserRoomNotification(resp?.chatRoomMember?.roomChatId)
        );
        dispatch(
          removeRoomNotification(
            resp?.chatRoomMember?.roomChatId,
            resp?.chatRoomMember?.id
          )
        );
        dispatch(removeRoomInitNotification(resp?.chatRoomMember?.id));
        // if (roomsInitNoti.includes(resp?.chatRoomMember?.id)) {
        // }
        const list = [
          ...chatWindows,
          {
            id: uuidv4(),
            status: 'init',
            userId: resp?.chatRoomMemberEndUser?.userId,
            roomChatMemberId: resp?.chatRoomMember?.id,
            username:
              resp?.chatRoomMemberEndUser?.['User.email'] ||
              resp?.chatRoomMemberEndUser?.['User.phone'] ||
              resp?.chatRoomMemberEndUser?.['EndUserInfos.email'] ||
              resp?.chatRoomMemberEndUser?.['EndUserInfos.phoneNumber'],
            roomChatId: resp?.chatRoomMember?.roomChatId,
            data: resp?.listMessage?.reverse() || [],
            avatar: resp?.chatRoomMemberEndUser?.['User.avatar']
              ? `${process.env.REACT_APP_API_URL}/${resp?.chatRoomMemberEndUser?.['User.avatar']}`
              : DefaultAvatar,
            type: 'external',
          },
        ];
        dispatch({
          type: SET_CHAT_WINDOWS,
          payload: {
            chatWindows: list,
          },
        });
      }
    } catch (error) {}
  };
};
export const resetData = () => {
  return {
    type: RESET_DATA,
  };
};
