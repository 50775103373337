import React, { useEffect } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { DeleteOutline, Menu } from "@material-ui/icons";
import SharedInputField from "app/shared/components/SharedInputField";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useIntl } from "react-intl";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { Box } from "@material-ui/core";
import { LayerUploader } from "./layer-uploader.component";
import { FILE_TYPES } from "../../constants";
import { useLayerItemConfig } from "../../hooks";
import { acceptedFilesToString } from "../../helpers/layer-setting.helper";
import { ExpandButton } from "./expand-button.component";

export const LayerConfigItem = (props) => {
  const intl = useIntl();
  const {
    handleMove,
    data,
    disabledMoveDown,
    disabledMoveUp,
    disabledDelete,
    handleRemove,
    onChange,
    // touched,
  } = props;
  const schema = Yup.object().shape({
    layer_label_en: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
    data_file_name: Yup.string().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    data_type: Yup.string().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      ...data,
      data_type: data?.data_type,
      layer_label_en: data?.layer_label_en,
      data_file_name: data?.data_file_name,
      data_file_url: data?.data_file_url,
      geo_json_url: data?.geo_json_url,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      onChange &&
        onChange({
          ...values,
        });
    },
  });

  const itemLayerMaster = useLayerItemConfig({
    formik,
    data,
    handleRemove,
    disabledDelete,
    onChange,
    handleMove,
  });

  const renderBody = () => {
    return (
      <div className={`config-body ${data?.expanded ? "expand" : ""}`}>
        <Box mb={3}>
          <SharedSelectField
            required
            className="bg-white"
            label={intl.formatMessage({ id: "CLIENT.MAP.LAYER_FILE_TYPE" })}
            disableClearable
            name="data_type"
            options={_.values(FILE_TYPES)}
            defaultValue={formik?.values["data_type"]}
            onChange={itemLayerMaster?.onFileTypeChange}
            errorMsg={formik?.touched?.data_type && formik?.errors?.data_type}
          />
        </Box>

        <Box mb={3}>
          <LayerUploader
            required
            accept={FILE_TYPES[formik?.values?.data_type]?.extensions}
            label={intl.formatMessage({ id: "CLIENT.GENERAL.CHOOSE_FILE" })}
            placeholder={itemLayerMaster?.filePlaceholder}
            fileName={formik?.values?.data_file_name}
            onChange={itemLayerMaster?.onFileChange}
            errorMsg={
              formik?.touched?.data_file_name > 0 &&
              formik?.errors?.data_file_name
            }
          />
        </Box>

        <Box mb={3}>
          <SharedInputField
            name="layer_label_en"
            label={intl.formatMessage({ id: "CLIENT.MAP.LAYER_LABEL" })}
            type="text"
            required
            className={`bg-white`}
            placeholder={intl.formatMessage({
              id: "CLIENT.MAP.ENTER_LAYER_LABEL",
            })}
            {...formik?.getFieldProps("layer_label_en")}
            errorMsg={
              formik?.touched["layer_label_en"] &&
              formik?.errors["layer_label_en"]
            }
          />
        </Box>
      </div>
    );
  };

  useEffect(() => {
    if (data?.expanded === true) {
      setTimeout(itemLayerMaster?.executeScroll, 300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.expanded]);

  useEffect(() => {
    itemLayerMaster?.setFilePlaceholder(
      `${intl.formatMessage({
        id: "CLIENT.MAP.LAYER_FILE_UPLOAD_MESSAGE",
      })} (${acceptedFilesToString(
        FILE_TYPES[formik?.values?.data_type]?.extensions
      )})`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values["data_type"]]);

  useEffect(() => {
    if (data?.error) {
      formik.submitForm();
      formik.setFieldValue("error", true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.error]);

  useEffect(() => {
    onChange &&
      onChange({
        ...formik.values,
        expanded: data?.expanded,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <form>
      <div
        className={`item-config-layer ${data?.error > 0 && "error"}`}
        ref={itemLayerMaster?.itemRef}
      >
        <div className="be-left-item">
          <div className="drag-button">
            <Menu />
          </div>
        </div>
        <div className="item-config">
          <div className="item-config-header">
            <div className="layer-info">
              <div className="layer-name">{formik?.values.layer_label_en}</div>
              <div className="layer-file-name">
                {formik?.values?.data_file_name}
              </div>
            </div>
            <div className="layer-action">
              <ArrowUpwardIcon
                className={`action-icon ${disabledMoveUp && "disabled"}`}
                onClick={() => itemLayerMaster?.onClickMove(-1)}
              />
              <ArrowDownwardIcon
                className={`action-icon ${disabledMoveDown && "disabled"}`}
                onClick={() => itemLayerMaster?.onClickMove(1)}
              />
              <DeleteOutline
                className={`action-icon ${disabledDelete && "disabled"}`}
                onClick={itemLayerMaster?.onClickRemove}
              />
              <ExpandButton
                expanded={data?.expanded}
                onClick={() => itemLayerMaster?.toggleExpand()}
              />
            </div>
          </div>
          {renderBody()}
        </div>
      </div>
    </form>
  );
};

LayerConfigItem.propTypes = {
  disabledMoveUp: PropTypes.bool,
  disabledMoveDown: PropTypes.bool,
  data: PropTypes.object,
  handleMove: PropTypes.func,
  handleRemove: PropTypes.func,
  disabledDelete: PropTypes.bool,
  onChange: PropTypes.func,
  touched: PropTypes.number,
};
