import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import swal from 'sweetalert';
import { phoneRegExp } from "../../shared/utils/validateReg";
import AuthLayout from "../../shared/layouts/AuthLayout";
import "./index.css";
import { 
  Box, 
  Button,
  Checkbox, 
  CircularProgress, 
  FormControlLabel, 
  FormHelperText, 
  Grid, 
} from "@material-ui/core";
import SharedSelectField from "../../shared/components/SharedSelectField";
import SharedInputField from "../../shared/components/SharedInputField";
import LockIcon from '@material-ui/icons/Lock';
import { isEmail } from "../../shared/utils/functions";
import queryString from "query-string";
import accountApi from "../../api/accountApi";
import DatePicker from "../../shared/components/DatePicker";
import moment from "moment";

const genders = [
  {
    label: "CLIENT.UPDATE_PROFILE.MALE",
    value: true
  },
  {
    label: "CLIENT.UPDATE_PROFILE.FEMALE",
    value: false
  }
]


function CreateAccount(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const query = queryString.parse(props.location.search);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { username, code } = query;
        const res = await accountApi.getAccountInfo(username, code);
        if (res.success) {
          setData(res.data)
        } else {
          props.history.push("/auth/login")
        }
      } catch (error) {
        // props.history.push("/auth/login")        
      }
    }

    fetchData();
  }, [query.username, query.code])

  const RegistrationSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(3, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })  
      )
      .max(50, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })    
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    email: isEmail(query.username) ? Yup.string()
      .email(
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_EMAIL_FORMAT",
        }) 
      )
      .min(3, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })  
      )
      .max(50, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })    
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ) :
      Yup.string()
      .min(3, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })  
      )
      .max(50, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })    
      )
      .email(
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_EMAIL_FORMAT",
        }) 
      ),
    phone_number: !isEmail(query.username) ? Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
        })
      ) :
      Yup.string()
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
        })
      )
    ,
    password: Yup.string()
      .min(3, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })  
      )
      .max(50, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })    
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "CLIENT.GENERAL.INVALID_CONFIRM_PASSWORD",
          })
        ),
      }),
    acceptTerms: Yup.bool().oneOf([true], 
      intl.formatMessage({
        id: "CLIENT.GENERAL.MUST_ACCEPT_TERMS",
      })  
    ),
    address: Yup.string()
      .min(3, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })  
      )
      .max(50, 
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })    
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      full_name: data.fullname,
      email: data.email,
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      phone_number: data.phone,
      date_of_birth: data.birthday || undefined,
      address: data.address,
      gender: data.gender || null,
    },
    validationSchema: RegistrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        enableLoading();
        const { username, code } = query;
        const data = {
          username: username,
          code: code,
          email: values.email,
          phone: values.phone_number,
          fullname: values.full_name,
          gender: values.gender,
          birthday: values.date_of_birth ? moment(values.date_of_birth).format("YYYY-MM-DD") : undefined,
          address: values.address,
          password: values.password,
          passwordConfirm: values.confirmPassword
        }
        const res = await accountApi.activeAccount(data);
        if (res.success) {
          const message = intl.formatMessage({
            id: "CLIENT.UPDATE_PROFILE.CREATE_COMPLETED_MESSAGE"
          });

          swal({
            title: intl.formatMessage({
              id: "CLIENT.UPDATE_PROFILE.CREATE_COMPLETED_MESSAGE"
            }),
            text: message,
            icon: 'success',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          }).then(() => {
            props.history.push('/auth/login');
          });
        } else {
          const message = intl.formatMessage({
            id: "CLIENT.UPDATE_PROFILE.CREATE_FAILED_MESSAGE"
          })
          swal({
            title: intl.formatMessage({
              id: "CLIENT.UPDATE_PROFILE.CREATE_FAILED_TITLE"
            }),
            text: message,
            icon: 'error',
            button: intl.formatMessage({
              id: "CLIENT.GENERAL.OK_BUTTON"
            })
          })
        }
      } catch (error) {
        const message = intl.formatMessage({
          id: "CLIENT.UPDATE_PROFILE.CREATE_FAILED_MESSAGE"
        })
        swal({
          title: intl.formatMessage({
            id: "CLIENT.UPDATE_PROFILE.CREATE_FAILED_TITLE"
          }),
          text: message,
          icon: 'error',
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON"
          })
        })
      } finally {
        setSubmitting(false);
        disableLoading();
      }
    },
  });

  return (
    <AuthLayout
      title={intl.formatMessage({id: "CLIENT.UPDATE_PROFILE.PAGE_TITLE"})}
      description={intl.formatMessage({id: "CLIENT.UPDATE_PROFILE.PAGE_SUBTITLE"})}
    >
       <form
        className="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container className="container">
          <Grid item xs={12} className="form-header">
            <h3 className="form-title">
              <FormattedMessage id="CLIENT.UPDATE_PROFILE.ACCOUNT_INFORMATION" />
            </h3>
          </Grid>
          
          {
            isEmail(query.username) && (
              <Grid item xs={12}>
                <Box mb={3}>
                  <SharedInputField 
                    name="email"
                    label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.EMAIL_LABEL' })}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.EMAIL_HINT' })}
                    required
                    errorMsg={formik.touched["email"] && formik.errors["email"]}
                    {...formik.getFieldProps("email")}
                    disabled
                    className="disabled-field"
                  />
                </Box>
              </Grid>
            )
          }

          {
            !isEmail(query.username) && (
              <Grid item xs={12}>
                <Box mb={3}>
                  <SharedInputField 
                    name="phone_number"
                    label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PHONE_NUMBER_LABEL' })}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PHONE_NUMBER_HINT' })}
                    required
                    errorMsg={formik.touched["phone_number"] && formik.errors["phone_number"]}
                    {...formik.getFieldProps("phone_number")}
                    disabled
                    className="disabled-field"
                  />
                </Box>
              </Grid>
            )
          }
          <Grid item xs={6}>
            <Box mb={3} mr={2}>
              <SharedInputField 
                name="password"
                label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PASSWORD_LABEL' })}
                type="password"
                isPasswordField
                placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PASSWORD_HINT' })}
                required
                errorMsg={formik.touched["password"] && formik.errors["password"]}
                {...formik.getFieldProps("password")}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} ml={2}>
              <SharedInputField 
                name="confirmPassword"
                label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.CONFIRM_PASSWORD_LABEL' })}
                type="password"
                isPasswordField
                placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.CONFIRM_PASSWORD_HINT' })}
                required
                errorMsg={formik.touched["confirmPassword"] && formik.errors["confirmPassword"]}
                {...formik.getFieldProps("confirmPassword")}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Grid>
          
          <Grid item xs={12} className="form-header">
            <h3 className="form-title">
              <FormattedMessage id="CLIENT.UPDATE_PROFILE.CONTACT_INFORMATION" />
            </h3>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} mr={2}>
              <SharedInputField 
                name="full_name"
                label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.FULL_NAME_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.FULL_NAME_HINT' })}
                required
                errorMsg={formik.touched["full_name"] && formik.errors["full_name"]}
                {...formik.getFieldProps("full_name")}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} ml={2}>
              <SharedSelectField 
                label={intl.formatMessage({id: "CLIENT.UPDATE_PROFILE.GENDER_LABEL"})}
                disableClearable
                name="gender"
                options={genders.map(item => ({
                  id: item.value,
                  value: item.value,
                  label: intl.formatMessage({id: item.label}),
                }))}
                defaultValue={formik.values["gender"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} mr={2}>
              <DatePicker 
                name="date_of_birth"
                label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.DOB_LABEL' })}
                type="date"
                placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.DOB_HINT' })}
                value={formik.values["date_of_birth"]}
                disabled={formik.isSubmitting}
                onChange={formik.setFieldValue}
              />
            </Box>
          </Grid>

          {
            isEmail(query.username) &&
            <Grid item xs={6}>
              <Box mb={3} ml={2}>
                <SharedInputField 
                  name="phone_number"
                  label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PHONE_NUMBER_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.PHONE_NUMBER_HINT' })}
                  errorMsg={formik.touched["phone_number"] && formik.errors["phone_number"]}
                  {...formik.getFieldProps("phone_number")}
                  disabled={formik.isSubmitting}
                />
              </Box>
            </Grid>
          }

          {
            !isEmail(query.username) &&
            <Grid item xs={6}>
              <Box mb={3} ml={2}>
                <SharedInputField 
                  name="email"
                  label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.EMAIL_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.EMAIL_HINT' })}
                  errorMsg={formik.touched["email"] && formik.errors["email"]}
                  {...formik.getFieldProps("email")}
                  disabled={formik.isSubmitting}
                />
              </Box>
            </Grid>
          }

          <Grid item xs={12}>
            <Box mb={3}>
              <SharedInputField 
                name="address"
                label={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.ADDRESS_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.UPDATE_PROFILE.ADDRESS_HINT' })}
                errorMsg={formik.touched["address"] && formik.errors["address"]}
                {...formik.getFieldProps("address")}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={formik.values.acceptTerms}
                    {...formik.getFieldProps("acceptTerms")}
                    name="acceptTerms"
                    color="primary"
                    disabled={formik.isSubmitting}
                  />
                }
                label={
                  <div>
                    {
                      `${intl.formatMessage({id: "CLIENT.UPDATE_PROFILE.TERMS"})} `
                    }
                    <a href="/terms-conditions" target="__blank">
                      <span style={{color: "#3699ff", textDecoration: "underline"}}>
                        {
                          intl.formatMessage({id: "CLIENT.UPDATE_PROFILE.TERMS_LINK"})
                        }
                      </span>
                    </a>
                  </div>
                }
              />
               {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <FormHelperText className="error-message">{formik.errors.acceptTerms}</FormHelperText>
                ) : null}
            </Box>
          </Grid>
         
          <Grid item xs={6}>
            <Box 
              display="flex" 
              justifyContent="flex-end" 
              height="100%" 
              className="note-msg"
              style={{paddingTop: "9px"}}
            >
              *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
            </Box>
          </Grid>

          <Grid item xs={12}> 
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="center"
            >
              <Box mr={2}>
                <Button 
                  type="submit"
                  className="custom-button form-button"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.values.acceptTerms
                  }  
                >
                  <FormattedMessage id="CLIENT.UPDATE_PROFILE.SUBMIT" />
                  {loading && <CircularProgress color="inherit" size={16} style={{marginLeft: "5px"}}/>}
                </Button>
              </Box>
              <Box ml={2}>
                <Link to="/auth/login">
                  <Button className="custom-button light-button form-button">
                    <FormattedMessage id="CLIENT.UPDATE_PROFILE.CANCEL" />
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </AuthLayout>
  );
}

export default injectIntl(CreateAccount);
