import React from "react";

const SVGComponent = (props) => (
  <svg
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`reeco-icon ${props?.className}`}
    {...props}
  >
    <path
      fill="currentColor"
      fillOpacity="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9527 5.00195C18.4377 5.00195 13.9227 6.63529 10.4577 9.91862L10.4402 9.93529C3.52773 16.5186 3.52773 27.202 10.4402 33.7853L35.4652 9.93529C32.0177 6.65195 27.4852 5.00195 22.9527 5.00195ZM36.7448 32.6173L34.2453 34.9979L22.9723 24.2618L25.4719 21.8813L36.7448 32.6173ZM8.74273 21.8686C8.74273 24.352 9.44273 26.7186 10.7377 28.7853L13.1702 26.4853C11.1052 23.7353 9.88023 20.602 9.54773 17.4186C9.02273 18.8353 8.74273 20.3186 8.74273 21.8686ZM15.7077 24.052C13.3452 20.6353 12.4527 16.552 13.2927 12.6686C14.3077 12.4686 15.3227 12.3686 16.3552 12.3686C19.5052 12.3686 22.5677 13.2853 25.2452 14.9686L15.7077 24.052ZM22.9527 8.33529C21.3252 8.33529 19.7677 8.60195 18.2802 9.10195C21.6227 9.41862 24.8952 10.5853 27.7827 12.552L30.2152 10.2353C28.0452 9.00195 25.5602 8.33529 22.9527 8.33529Z"
    />
  </svg>
);

export default SVGComponent;
