import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ListPageButtons } from "app/shared/components/ListPageButtons";

const FilterBar = ({
  formik,
  intl,
  data,
  language,
  searchListInitialValues,
  handleClear,
  isAllowed,
}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item xs="12" sm={6}>
            <SharedInputField
              name="search"
              label={intl.formatMessage({
                id: "CLIENT.ORGANIZATION_MANAGEMENT.KEYWORD_LABEL",
              })}
              type="text"
              value={formik.values?.search}
              {...formik.getFieldProps("search")}
              placeholder={intl.formatMessage({
                id: "CLIENT.ORGANIZATION_MANAGEMENT.KEYWORD_HINT",
              })}
            />
          </Grid>
          <Grid item xs="12" sm={3}>
            <SharedSelectField
              className="text-normal"
              label={intl.formatMessage({
                id: "CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_LOCATION_TYPE",
              })}
              disableClearable
              name="location_type"
              options={[
                {
                  id: "none",
                  value: "",
                  label: intl.formatMessage({
                    id: "CLIENT.GENERAL.ALL_ITEM",
                  }),
                },
              ].concat(
                data.locationTypes?.map((item) => ({
                  id: item.id,
                  value: item.id,
                  label: item[`name_${language}`],
                }))
              )}
              defaultValue={formik.values?.location_type}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Grid>
          <Grid item xs="12" sm={3}>
            <SharedSelectField
              className="text-normal"
              label={intl.formatMessage({
                id: "CLIENT.DATA_SHARING_REQUEST.STATUS_LABEL",
              })}
              disableClearable
              name="status"
              options={[
                {
                  id: "none",
                  value: "",
                  label: intl.formatMessage({
                    id: "CLIENT.GENERAL.ALL_ITEM",
                  }),
                },
              ].concat(
                data.status?.map((item) => ({
                  id: item.id,
                  value: item.id,
                  label: item[`name_${language}`],
                }))
              )}
              defaultValue={formik.values?.status}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Grid>
        </Grid>
      </Box>
      <ListPageButtons
        showsAddNew={isAllowed("data_sharing_request", "new")}
        onClear={() => {
          formik.setValues(searchListInitialValues);
          handleClear(searchListInitialValues);
        }}
        addPagePath="/data-sharing-request/add"
        addLabel={
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.NEW_REQUEST" />
        }
        clearLabel={
          <FormattedMessage id="CLIENT.ORGANIZATION_MANAGEMENT.CLEAR_BUTTON" />
        }
        searchLabel={
          <FormattedMessage id="CLIENT.ORGANIZATION_MANAGEMENT.APPLY_BUTTON" />
        }
        onSearch={formik.submitForm}
      />
    </form>
  );
};

FilterBar.propTypes = {};

export default FilterBar;
