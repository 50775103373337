import React from 'react';

import './styles.css';
import ReactResizeDetector from 'react-resize-detector';
import { invertColor } from 'app/utils/utils';

const HorizontalGauge = (props) => {
  const CHART_WIDTH_SPACE = 50;
  const CHART_HEIGHT_SPACE = 45;
  const X0 = 25;
  const Y0 = 64;
  const refChartContainer = React.useRef(null);
  const nearOptions = [2, 4, 5, 10];

  const maxValue = props['maxValue'] || 100;
  const minValue = props['minValue'] || 0;
  const value = props['value'] || 0;
  const color = props['color'] || '#46AE4E';


  const [containerSize, setContainerSize] = React.useState([0, 0]);
  const [chartSize, setChartSize] = React.useState([0, 0]);
  const [lines, setLines] = React.useState([]);

  const label = props['label'] || '';

  /* How many portions does this width can be divided */
  const getNumOfPortion = (width) => {

    /* If want to calculate, uncomment this line below */
    // return Math.min(
    //   Math.round(Math.floor(width / SPACE_BETWEEN_TWO_LINE) / 5) * 5
    //   , MAX_PORTION);

    if (width > 900)
      return 20;

    if (width > 600)
      return 10;

    return 5;
  }

  const roundNearHalf = (number) => {
    return (Math.round(number * 2) / 2).toFixed(2);
  };

  const roundNearQuarter = (number) => {
    return (Math.round(number * 4) / 4).toFixed(2);
  };

  const roundNear = (number, value) => {
    return (Math.round(number * value) / value).toFixed(2);
  };

  const GetPosX = (value) => {
    const range = Math.ceil(maxValue) - minValue;
    return ((value - minValue) / Math.abs(range)) * chartSize[0];
  }

  const reDrawChart = () => {
    var nearOptionIndex = 0;
    var numOfPortion = getNumOfPortion(chartSize[0]);
    const range = Math.ceil(maxValue) - Math.floor(minValue);
    var step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
    while (step == 0) {

      nearOptionIndex++;
      step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      while (nearOptionIndex == 3 && step == 0) {
        numOfPortion = numOfPortion * 0.8;
        step = roundNear(range / numOfPortion, nearOptions[nearOptionIndex]);
      }
    }

    var vl = Number(Math.floor(minValue));
    var newDataLines = [];
    while (vl <= Math.ceil(maxValue)) {
      if (vl >= minValue) {
        vl = Number(Number(vl).toFixed(2))
        newDataLines.push({
          value: vl,
          label: Number(vl) > 1000 ? `${Math.floor(vl / 1000)}k` : vl
        });
      }
      vl += Number(step);
    }
    setLines(newDataLines);

  }

  const setSize = () => {
    setContainerSize([refChartContainer?.current?.clientWidth,
    refChartContainer?.current?.clientHeight]);
    setChartSize([refChartContainer?.current?.clientWidth - CHART_WIDTH_SPACE,
    refChartContainer?.current?.clientHeight * 0.35 - CHART_HEIGHT_SPACE]);
  }

  const onResize = React.useCallback(() => {
    if (refChartContainer) {
      setSize();
    }
  }, []);

  React.useEffect(() => {
    if (refChartContainer) {
      setSize();
    }
  }, [refChartContainer]);

  React.useEffect(() => {
    if (chartSize) {
      reDrawChart();
    }
  }, [chartSize, props['minValue'], props['maxValue'], props['value']]);


  return (
    <ReactResizeDetector
      handleWidth handleHeight
      onResize={onResize}
      refreshRate="500"
      refreshMode='debounce'>
      {({ width, height }) => (
        <div className="horizontal-gauge" ref={refChartContainer}>
          <div className="horizontal-gauge-container"
            style={{
              width: containerSize[0],
              height: containerSize[1],
              color: invertColor(color)
            }}>
            <svg viewBox={`0 0 ${containerSize[0]} ${containerSize[1]}`}>
              <rect x={X0}
                y={Y0}
                width={chartSize[0]}
                height={chartSize[1]}
                fill={`${invertColor(color)}4d`} />
              <rect
                x={X0}
                y={Y0}
                width={GetPosX(value)}
                height={chartSize[1]}
                fill={invertColor(color)}
              />
              <rect fill="none"
                class="highcharts-plot-border"
                data-z-index="1"
                stroke={invertColor(color)}
                stroke-width="1"
                x={X0} y={Y0}
                width={chartSize[0]}
                height={chartSize[1]} />

              {props['showLines'] && lines.length > 0 && (
                <g>
                  {lines.map((line, index) => {
                    const x = X0 + GetPosX(line.value);

                    if ((index == 0 && minValue > line.value)
                      || (x > chartSize[0] + X0)
                      || (line.value > maxValue))
                      return null;

                    return (
                      <path fill="none"
                        stroke={invertColor(color)}
                        stroke-width="1.3"
                        d={`M ${x} ${Y0} L ${x} ${Y0 + 8}`} opacity="1"></path>
                    )
                  })}
                </g>
              )}

              {props['showNumbers'] && lines.length > 0 && (
                <g>

                  {lines.map((line, index) => {
                    const x = X0 + GetPosX(line.value);

                    if ((index == 0 && minValue > line.value)
                      || (x > chartSize[0] + X0)
                      || (line.value > maxValue))
                      return null;

                    return (<text
                      x={x}
                      y={Y0 - 8}
                      fill={invertColor(color)}
                      font-family="sans-serif"
                      font-size="12"
                      text-anchor="middle"
                    >
                      {line.label}
                    </text>);
                  })}
                </g>
              )}
            </svg>
            <div className="horizontal-gauge-info">
              <div className="horizontal-gauge-currentvalue" style={{ fontSize: Math.min(height * 0.08, 70), color: invertColor(color) }}>
                <p>
                  {value} ({props['unit']})
                </p>
              </div>
              <div className="horizontal-gauge-currentstate" style={{ fontSize: Math.min(height * 0.06, 40), color: invertColor(color) }}>
                <span>
                  {label}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactResizeDetector>
  );
};

export default HorizontalGauge;
