import { useContext } from 'react';
import PermissionsContext from './PermissionsContext';

export const usePermissions = () => {
  const permissions = useContext(PermissionsContext);

  const isAllowed = (code, mode) => {
    const permission = permissions?.find((item) => item['code'] === code);

    if (permission && permission[mode] === true) {
      return true;
    }

    return false;
  };

  return {
    isAllowed,
  };
};
