import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import {
  MapPage,
  DataFilesList,
  DataFilesAdd,
  DataFilesEdit,
  DataSharingRequestList,
  DataSharingAdd,
  DataSharingEdit,
  ProfileEdit,
} from "./pages/EndUser";
import Nav from "./shared/layouts/EndUserLayout/Nav";
import Chat from "./shared/components/EndUserChatBox/Chat";

function RouteWithNavBar({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Nav {...props} />
            <Component {...props} />
          </>
        );
      }}
    ></Route>
  );
}

function EndUserPage(props) {
  return (
    <Suspense>
      <Chat />
      <Switch>
        <Redirect exact from="/" to="/data-sharing-request" />
        {/* <RouteWithNavBar path="/maps" component={MapPage} /> */}
        <RouteWithNavBar exact path="/data-files" component={DataFilesList} />
        <RouteWithNavBar exact path="/profile/edit/" component={ProfileEdit} />
        <RouteWithNavBar path="/data-files/add" component={DataFilesAdd} />
        <RouteWithNavBar
          exact
          path="/data-files/edit/:id"
          component={DataFilesEdit}
        />

        <RouteWithNavBar
          exact
          path="/data-sharing-request"
          component={DataSharingRequestList}
        />
        <RouteWithNavBar
          path="/data-sharing-request/add"
          component={DataSharingAdd}
        />
        <RouteWithNavBar
          exact
          path="/data-sharing-request/edit/:id"
          component={DataSharingEdit}
        />

        <Route exact path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default EndUserPage;
