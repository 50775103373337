import { Button, Drawer } from "@material-ui/core";
import { USER_MENUS } from "./RightDrawer.constant";
import CloseIcon from "@material-ui/icons/Close";
import MailIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useLanguage } from "app/shared/hooks";
import OverflowTip from "app/shared/components/OverflowTip";
import PhoneIcon from "@material-ui/icons/Phone";
import DefaultUser from "../../../../../../assets/images/user.png";
import { FormattedMessage, useIntl } from "react-intl";
import authApi from "app/api/authApi";
import { logOut } from "app/redux/actions";
import "./RightDrawer.css";

const RightDrawer = (props) => {
  const intl = useIntl();
  const { visible = false, onClose = () => {} } = props;
  const { user, role } = useSelector(
    ({ user }) => ({
      user: user.user,
      role: user.role,
    }),
    shallowEqual,
  );
  const [language] = useLanguage();
  const roles =
    role.permission_sys &&
    role.permission_sys.length &&
    role.permission_sys.filter((item) => item.view).map((item) => item.code);

  const handleLogout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
    } finally {
      props.logOut();
    }
  };

  return (
    <Drawer anchor="right" open={visible} onClose={onClose}>
      <div className="user-sidebar">
        <div className="header">
          <h3>
            <FormattedMessage id="CLIENT.GENERAL.USER_PROFILE" />
          </h3>

          <div className="close" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="content">
          <div className="info">
            <div className="avatar">
              <img
                src={
                  user.avatar
                    ? `${process.env.REACT_APP_API_URL}/${user.avatar}`
                    : DefaultUser
                }
                alt=""
              />
              <span></span>
            </div>
            <div className="main-info">
              <p className="name">{user.fullname}</p>
              {role && (
                <p className="role">
                  {language === "en" ? role.name : role.nameVi}
                </p>
              )}
              <p className="mail" style={{ width: "200px" }}>
                {user.select_username === "email" ? (
                  <MailIcon />
                ) : (
                  <PhoneIcon />
                )}
                <OverflowTip value={user.email}>
                  <span>
                    {user.select_username === "email" ? user.email : user.phone}
                  </span>
                </OverflowTip>
              </p>
              <Button
                className="custom-button light-button button"
                onClick={handleLogout}
              >
                <FormattedMessage id="CLIENT.GENERAL.SIGN_OUT" />
              </Button>
            </div>
          </div>
          <div className="separator"></div>
          <div className="user-menus">
            <ul>
              {USER_MENUS(intl)
                .filter(
                  (item) =>
                    item?.enabled === true &&
                    roles &&
                    roles.length &&
                    roles.indexOf(item.code) !== -1,
                )
                .map((item) => (
                  <Link key={item.id} to={item.link}>
                    <li key={item.id} onClick={onClose}>
                      <div className="icon">
                        {["chats", "my_notifications"].includes(item.code) ? (
                          item.icon
                        ) : (
                          <img
                            src={item.icon}
                            style={{ maxWidth: 24, maxHeight: 24 }}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="menu-content">
                        <p>{item.name}</p>
                        <p>{item.description}</p>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default connect(null, { logOut })(RightDrawer);
