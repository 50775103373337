import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { FormattedMessage, injectIntl } from "react-intl";
import { Box, Button } from "@material-ui/core";
import SharedSelectField from "app/shared/components/SharedSelectField";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import stationsApi from "app/api/stationApi";
import { useFormik } from "formik";
import swal from "sweetalert";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
}));

function MoveStationModal(props) {
  const { intl, selectedValues, mapId, onChange, handleClose, reloadData } =
    props;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(props["open"] || false);
  const [selected, setSelected] = useState([]);
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user,
    }),
    shallowEqual
  );

  const toggleOpen = () => {
    if (handleClose) {
      handleClose();
    }
  };

  const handleSubmit = () => {
    if (onChange) {
      onChange(
        selected.map((item) => ({
          ...item,
          checked: true,
        }))
      );
      handleClose();
    }
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      toWorkspace: null,
    },
    onSubmit: async (values) => {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.DASHBOARD.MOVE.WARNING_TITLE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.DASHBOARD.MOVE.WARNING_TEXT",
        }),
        icon: "warning",
        // dangerMode: true,
        buttons: [
          intl.formatMessage({
            id: "CLIENT.GENERAL.CANCEL_BUTTON",
          }),
          intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        ],
      }).then((yes) => {
        if (yes) {
          const handleMoveStation = async () => {
            try {
              const res = await stationsApi.moveStation(mapId, values, token);
              if (res.success) {
                reloadData();
                swal({
                  title: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.MOVE.SUCCESS_TITLE",
                  }),
                  text: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.MOVE.SUCCESS_TEXT",
                  }),
                  icon: "success",
                  button: intl.formatMessage({
                    id: "CLIENT.GENERAL.OK_BUTTON",
                  }),
                });
              } else {
                swal({
                  title: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.MOVE.FAILED_TITLE",
                  }),
                  text: intl.formatMessage({
                    id: "CLIENT.DASHBOARD.MOVE.FAILED_TEXT",
                  }),
                  icon: "error",
                  button: intl.formatMessage({
                    id: "CLIENT.GENERAL.OK_BUTTON",
                  }),
                });
              }
            } catch (error) {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.MOVE.FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.DASHBOARD.MOVE.FAILED_TEXT",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          };
          handleMoveStation();
        }
      });
    },
  });

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id="CLIENT.DASHBOARD.MOVE.MOVE_TO" />
        </div>
        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={toggleOpen} />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="value-modal-body">
        <Box>
          <Box className="form-label">
            {intl.formatMessage({
              id: "CLIENT.DASHBOARD.MOVE.SELECT_WORKSPACE",
            })}
          </Box>
          <SharedSelectField
            disableClearable
            name="toWorkspace"
            required
            options={props?.workspaces}
            defaultValue={formik.values["toWorkspace"]}
            disabled={false}
            placeholder={intl.formatMessage({
              id: "CLIENT.DASHBOARD.MOVE.SELECT_WORKSPACE_LABEL",
            })}
            onChange={formik.setFieldValue}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={4}
        >
          <Box display="flex" alignItems="center">
            <Button
              className="custom-button"
              onClick={handleSubmit}
              disabled={formik.isSubmitting || !formik.values["toWorkspace"]}
            >
              <FormattedMessage id="CLIENT.DASHBOARD.MOVE.SUBMIT" />
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    setOpen(props?.open);
    setSelected(selectedValues || []);
  }, [props?.open, selectedValues]);

  return (
    <Modal
      open={open}
      onClose={props["handleClose"]}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

MoveStationModal.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  handleClose: PropTypes.func,
  selectedValues: PropTypes.array,
};

export default injectIntl(MoveStationModal);
