import IconNoData from "assets/icon/no-data/noData";
import classes from "./styles.module.css";
import { FormattedMessage } from "react-intl";

export const NoData = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <IconNoData />

        <p>
          <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
        </p>
      </div>
    </div>
  );
};
