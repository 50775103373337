/* eslint-disable react-hooks/exhaustive-deps */
import { DATE_FORMAT } from 'app/constant/date-time-format';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalContainer from '../../common/ModalContainer';
import { HISTORICAL_DATA, PREDICTION_DATA } from '../contants';
import { prepareAlarmSummaryOutput } from '../helpers';
import { ALARM_SUMMARY_INITIALVALUES } from '../initialValues';
import AlarmSummaryModalSchema from '../schemas/AlarmSummaryFormlSchema';
import useModal from '../useModal';
import AlarmSummaryForm from './AlarmSummaryForm';
import './styles.css';

export default function MenuModal(props) {
  const intl = useIntl();

  const {
    openModal,
    setOpenModal,
    toggleOpen }
    = useModal({ modalOpen: props?.open, handleClose: props?.handleClose });

  const formik = useFormik({
    initialValues: {
      name_en: props?.data?.name_en || ALARM_SUMMARY_INITIALVALUES.name_en,
      name_vi: props?.data?.name_vi || ALARM_SUMMARY_INITIALVALUES.name_vi,
      parameter_id: props?.data?.dashboard_alarm_summary?.parameter_id || ALARM_SUMMARY_INITIALVALUES.parameter_id,
      station_id: props?.data?.dashboard_alarm_summary?.station_id || ALARM_SUMMARY_INITIALVALUES.station_id,
      header_color: props?.data?.header_color || ALARM_SUMMARY_INITIALVALUES.header_color,
      main_color: props?.data?.dashboard_alarm_summary?.main_color || ALARM_SUMMARY_INITIALVALUES.main_color,
      alarm_color: props?.data?.dashboard_alarm_summary?.alarm_color || ALARM_SUMMARY_INITIALVALUES.alarm_color,
      show_header: props.data?.show_header === undefined ? ALARM_SUMMARY_INITIALVALUES.show_header : props.data.show_header,
      period: props?.data?.dashboard_alarm_summary?.period || ALARM_SUMMARY_INITIALVALUES.period,
      data_mode: [HISTORICAL_DATA, PREDICTION_DATA].includes(props.data?.data_mode) ? props.data?.data_mode: ALARM_SUMMARY_INITIALVALUES.data_mode,
      historical_time_range: props.data?.time_range || ALARM_SUMMARY_INITIALVALUES.historical_time_range,
      historical_time_range_type: props.data?.time_range_type || ALARM_SUMMARY_INITIALVALUES.historical_time_range_type,
      prediction_time_range: props.data?.time_point || ALARM_SUMMARY_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: props.data?.time_point_type || ALARM_SUMMARY_INITIALVALUES.prediction_time_range_type,
      historical_start_time: props.data?.historical_start_time ?
        moment(props.data?.historical_start_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      historical_end_time: props.data?.historical_end_time ?
        moment(props.data?.historical_end_time) :
        moment(moment().format(DATE_FORMAT), DATE_FORMAT)
    },
    enableReinitialize: true,
    validationSchema: AlarmSummaryModalSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;

      props['handleEdit'](id, prepareAlarmSummaryOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  React.useEffect(() => {
    setOpenModal(props['open']);
  }, [props['open']]);

  return <ModalContainer
    formik={formik}
    editing={props?.editing}
    handleClose={props?.handleClose}
    open={openModal}
    toggleOpen={toggleOpen}
    newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_ALARM_SUMMARY" />}
    editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_ALARM_SUMMARY" />}>
    <AlarmSummaryForm
      formik={formik}
      intl={intl}
      parameters={props?.parameters || []} />
  </ModalContainer>
}