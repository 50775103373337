import {
  Box,
  FormHelperText,
  Grid,
  Icon,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import DateTimePicker from "app/shared/components/DateTimePicker";
import { FormattedMessage } from "react-intl";

import { DateRange } from "@material-ui/icons";
import { DATE_FORMAT } from "app/constant/date-time-format";
import { disableFutureDate } from "./helper";

function TimeRangeSeletor({ onChange, endTime, startTime, error, field }) {
  return (
    <Grid container spacing={3} style={{ marginTop: 3 }}>
      <Grid item xs={6}>
        <Box className="form-label">
          <p className="required">*</p>
          <FormattedMessage id="CLIENT.GENERAL.DATE_RANGE_PICKER_START_DATE" />
        </Box>
        <DateTimePicker
          className={`bg-white`}
          value={startTime}
          initialValue={startTime}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
          onChange={(value) => {
            if(field) {
              onChange?.(
                field.startTime,
                value
              );
            } else {
              onChange?.("historical_start_time", value);
            }
          }}
          isValidDate={disableFutureDate}
          renderInput={(params) => (
            <TextField
              fullWidth
              variant="outlined"
              {...params}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"center"}>
                    <Icon>
                      <DateRange />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {error && (
          <FormHelperText className="error-message">{error}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={6}>
        <Box className="form-label">
          <p className="required">*</p>
          <FormattedMessage id="CLIENT.GENERAL.DATE_RANGE_PICKER_END_DATE" />
        </Box>
        <DateTimePicker
          className={`bg-white`}
          value={endTime}
          initialValue={endTime}
          dateFormat={DATE_FORMAT}
          timeFormat={false}
          onChange={(value) => {
            if(field) {
              onChange?.(
                field.endTime,
                value
              );
            } else {
              onChange?.("historical_end_time", value);
            } 
          }}
          isValidDate={disableFutureDate}
          renderInput={(params) => (
            <TextField
              fullWidth
              variant="outlined"
              {...params}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"center"}>
                    <Icon>
                      <DateRange />
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default TimeRangeSeletor;
