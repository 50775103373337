export const CHANNEL_TYPE = {
  email: "email",
  phone: "phone",
};
export const statusList = [
  { key: 0, value: "true", name_en: "Active", name_vi: "Hoạt Động" },
  { key: 1, value: "false", name_en: "Inactive", name_vi: "Không Hoạt Động" },
];

export const genders = [
  {
    label: "CLIENT.GENERNAL.SELECT_GENDER_LABEL",
    value: null,
  },
  {
    label: "CLIENT.UPDATE_PROFILE.MALE",
    value: true,
  },
  {
    label: "CLIENT.UPDATE_PROFILE.FEMALE",
    value: false,
  },
];

export const channelTypes = [
  {
    id: 1,
    subLabel: "CLIENT.SIGN_UP.EMAIL_LABEL",
    value: "email",
  },
  {
    id: 2,
    subLabel: "CLIENT.SIGN_UP.PHONE_NUMBER_LABEL",
    value: "phone",
  },
];
