import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import "./index.css";

const ChatActions = (props) => {
  const {
    onCloseAll,
    onHideAll,
  } = props;
  const [active, setActive] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation()
    setActive(!active)
  }

  const handleCloseAll = (e) => {
    e.preventDefault();
    e.stopPropagation()
    onCloseAll && onCloseAll();
    toggle(e)
  }

  const handleHideAll = (e) => {
    e.preventDefault();
    e.stopPropagation()
    onHideAll && onHideAll();
    toggle(e)
  } 

  return (
    <div className={`chat-actions ${active ? "active" : ""}`}>
      <MoreHorizIcon onClick={toggle}/>
      <div className="action-list">
        <div className="action-item" onClick={handleCloseAll}>
          <FormattedMessage id="CLIENT.CHAT.CLOSE_ALL_CHAT" />
        </div>
        <div className="action-item" onClick={handleHideAll}>
          <FormattedMessage id="CLIENT.CHAT.HIDE_ALL_CHAT" />
        </div>
      </div>
    </div>
  )
}

export default ChatActions;