import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MapAction } from "../map-action";
import $ from "jquery";
import provinces from "./provinces.json";
import { MAPBOX_ACCESS_TOKEN } from "app/constant/mapbox";
import { DEFAULT_ICON } from "../../../../../assets/icon/default-icon/marker";
import styles from "./map-content.style.module.css";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const INITIAL_ZOOM = 7;
const FOCUS_ZOOM = 15;
const STREET_MODE = 1;
const SATELLITE_MODE = 2;

export const MapContent = (props) => {
  const { intl, style = 1, initialPos, markers, language, layers } = props;
  const mapContainerRef = useRef(null);
  const [globalMap, setGlobalMap] = useState();
  const [activeStyle, setActiveStyle] = useState(style);
  const [currentPos, setCurrentPos] = useState([]);

  const flyToCurrentPos = () => {
    if (currentPos?.length > 0) {
      globalMap.flyTo({
        center: currentPos,
        zoom: FOCUS_ZOOM,
        essential: true,
      });
    }
  };
  // console.log(markers);
  const getStyle = (styleId) => {
    // eslint-disable-next-line default-case
    switch (styleId) {
      case STREET_MODE:
        return process.env.REACT_APP_MAPBOX_STREET_MAP_URL;

      case SATELLITE_MODE:
        return process.env.REACT_APP_MAPBOX_SATELLITE_MAP_URL;
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const lng = position.coords.longitude;
        const lat = position.coords.latitude;

        setCurrentPos([lng, lat]);
      });
    }
  }, []);

  useEffect(() => {
    if (globalMap) {
      const newStyle = getStyle(style);

      globalMap?.setStyle(newStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: getStyle(activeStyle),
      center: [initialPos?.[1], initialPos?.[0]],
      zoom: INITIAL_ZOOM,
      minZoom: 4,
      maxBounds: [
        [-180, -85],
        [180, 85],
      ],
    });

    map.addControl(new mapboxgl.NavigationControl());

    map.on("load", function () {
      map.loadImage("/images/current-position.png", function (error, image) {
        map.addImage(`current-position`, image, {
          width: 24,
          height: 24,
        });
      });

      markers?.forEach((marker) => {
        const iconUrl = marker?.point_marker
          ? new Buffer.from(marker?.point_marker?.data).toString("base64")
          : DEFAULT_ICON;

        map.loadImage(`data:;base64,${iconUrl}`, function (error, image) {
          map.addImage(`image-${marker?.id}`, image, {
            width: 24,
            height: 24,
          });
        });
      });

      // Layer bản đồ Hoàng Sa, Trường Sa
      map.addSource(`source-0`, {
        type: "geojson",
        data: provinces,
      });

      map.addLayer({
        id: `line-layer-0`,
        type: "line",
        source: `source-0`,
        paint: {
          "line-color": "#aaa",
          "line-width": 1,
        },
      });

      if (layers?.length > 0) {
        layers?.forEach((layer) => {
          map.addSource(`source-${layer?.id}`, {
            type: "geojson",
            data: layer?.url,
          });

          map.addLayer({
            id: `line-layer-${layer?.id}`,
            type: "line",
            source: `source-${layer?.id}`,
            filter: ["==", "$type", "MultiPolygon"],
            paint: {
              "line-color": "#4285F4",
              "line-width": 3,
            },
          });

          map.addLayer({
            id: `fill-layer-${layer?.id}`,
            type: "fill",
            source: `source-${layer?.id}`,
            filter: ["==", "$type", "MultiPolygon"],
            paint: {
              "fill-color": "lightblue",
              "fill-opacity": 0.8,
            },
          });

          map.addLayer({
            id: `line-layer-${layer?.id}`,
            type: "circle",
            source: `source-${layer?.id}`,
            filter: ["==", "$type", "Point"],
            paint: {
              "circle-radius": 5,
              "circle-stroke-width": 2,
              "circle-color": "red",
              "circle-stroke-color": "white",
            },
          });

        });
      }

      let timer = setTimeout(() => {
        let features = markers?.map((marker) => {
          return {
            type: "Feature",
            properties: {
              title: language === "en" ? marker?.name : marker?.name_vi,
              image: `image-${marker?.id}`,
            },
            geometry: {
              coordinates: [
                Number(marker?.longtitude),
                Number(marker?.latitude),
              ],
              type: "Point",
            },
          };
        });

        map.addSource("points", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: features,
          },
        });

        map.addLayer({
          id: "points",
          type: "symbol",
          source: "points",
          layout: {
            "icon-image": ["get", "image"],
            "icon-size": 0.1,
            "text-field": ["get", "title"] || "",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 2],
            "text-anchor": "top",
          },
          paint: {
            "text-color": activeStyle === 1 ? "#000" : "#fff",
          },
        });

        navigator?.geolocation?.getCurrentPosition((position) => {
          if (currentPos?.length > 0) {
            features.push({
              type: "Feature",
              properties: {
                image: "current-position",
              },
              geometry: {
                coordinates: currentPos,
                type: "Point",
              },
            });
          }
        });

        clearTimeout(timer);
      }, 1000);
    });

    setGlobalMap(map);

    return () => map.remove();
  }, [style, markers, language, activeStyle, layers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container} ref={mapContainerRef}>
      <div className={styles.innerContainer}>
        <MapAction
          intl={intl}
          onLocateMyLocation={flyToCurrentPos}
          onChangeLayer={(style) => setActiveStyle(style)}
          onZoom={(zoomLevel) => {
            if (zoomLevel === "In") {
              $(".mapboxgl-ctrl-zoom-in").click();
            } else {
              $(".mapboxgl-ctrl-zoom-out").click();
            }
          }}
        />
      </div>
    </div>
  );
};
