import { useIntl } from "react-intl";
import AuthLayout from "../../../shared/layouts/AuthLayout";
import { MainForm } from "./components";
import { useResetPassword } from "./hooks";

function ResetPassword(props) {
  const intl = useIntl();
  const pageData = useResetPassword({ ...props, intl });

  return (
    <AuthLayout
      title={intl.formatMessage({ id: "CLIENT.RESET.PAGE_TITLE" })}
      description={intl.formatMessage({ id: "CLIENT.RESET.PAGE_SUBTITLE" })}
    >
      <MainForm intl={intl} pageData={pageData} />
    </AuthLayout>
  );
}

export default ResetPassword;
