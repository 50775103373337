import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { LayersOutlined, LocationOnOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { FormattedMessage, useIntl } from "react-intl";
import mapApi from "app/api/mapApi";
import { map, orderBy } from "lodash";
import { decryptKey } from "app/utils/crypto";
import {
  StationsDropdown,
  AddStationModal,
  MapContent,
  LayerSettingModal,
} from "../components";
import { INITIAL_POSITION } from "../constants";
import { useMapPage } from "../hooks";

// import "leaflet/dist/leaflet.css";
import "./map.style.scss";

export default function PublicMap(props) {
  const intl = useIntl();

  const {
    layers,
    stations,
    configData,
    history,
    checked,
    language,
    openAddStationModal,
    openLayerModal,
    setChecked,
    getDisplayPublicStations,
    getDisplayLayers,
    setConfigData,
    onDropdownChange,
    handleOpenAddStationModal,
    handleOpenLayerModal,
    handleCloseAddStationModal,
    onChangeStations,
    handleCloseLayerModal,
  } = useMapPage({ intl, mapApi });

  const getStations = () => {
    let results = [];

    stations?.forEach((item) => {
      if (checked?.stations?.includes(Number(item?.station?.id || 0))) {
        results?.push(item?.station);
      }
    });

    return results;
  };

  const getLayers = () => {
    let results = [];

    layers?.forEach((item) => {
      if (checked?.layers?.includes(Number(item?.id || 0))) {
        results?.push({
          ...item,
          url: `${process.env.REACT_APP_API_URL}/${
            item?.geo_json_url || item?.data_file_url
          }`,
        });
      }
    });

    return orderBy(results, ["apply_order"], ["desc"]);
  };

  useEffect(() => {
    setChecked((pre) => ({
      ...pre,
      stations: stations?.map((item) => item.value) || [],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations]);

  useEffect(() => {
    setChecked((pre) => ({
      ...pre,
      layers: layers?.map((item) => item.id) || [],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers]);

  useEffect(() => {
    if (configData.mapId) {
      getDisplayPublicStations(configData.mapId);
      const mapId = decryptKey(configData.mapId);
      getDisplayLayers(mapId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData.mapId]);

  useEffect(() => {
    const queries = queryString.parse(props?.location?.search);

    if (!queries.key) {
      history.replace("/not-found");
    }

    setConfigData({
      mapId: queries.key,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.location?.search]);

  return (
    <div className="reeco-map">
      <div className="map-page-container">
        <MapContent
          intl={intl}
          zoom={20}
          initialPos={INITIAL_POSITION}
          markers={getStations()}
          layers={getLayers()}
          language={"en"}
        />
      </div>

      <Box className="map-config">
        <StationsDropdown
          label={<FormattedMessage id="CLIENT.GENERAL.MENU_STATIONS" />}
          icon={<LocationOnOutlined />}
          addActionLabel={<FormattedMessage id="CLIENT.MAP.ADD_STATION" />}
          noDataMessage={<FormattedMessage id="CLIENT.MAP.MSG_NO_STATIONS" />}
          data={stations}
          checked={checked.stations || []}
          onChange={onDropdownChange("stations")}
          onClickAdd={handleOpenAddStationModal}
          isPublic
        />

        <StationsDropdown
          label={<FormattedMessage id="CLIENT.MAP.LAYERS" />}
          icon={<LayersOutlined />}
          addActionLabel={<FormattedMessage id="CLIENT.MAP.ADD_LAYER" />}
          noDataMessage={<FormattedMessage id="CLIENT.MAP.MSG_NO_LAYERS" />}
          data={map(layers, (item, index) => ({
            value: item.id,
            label: item.layer_label_en,
          }))}
          checked={checked.layers || []}
          onChange={onDropdownChange("layers")}
          onClickAdd={handleOpenLayerModal}
          isPublic
        />
      </Box>

      <AddStationModal
        open={openAddStationModal}
        handleClose={handleCloseAddStationModal}
        data={configData.stations?.map((item) => ({
          station_id: item.id,
          station: {
            ...item,
            point_marker: item?.["point_marker.data"],
          },
          value: item.id,
          label: language === "en" ? item.name : item.name_vi,
        }))}
        selectedValues={stations}
        onChange={onChangeStations}
        mapId={configData.mapId}
      />

      <LayerSettingModal
        open={openLayerModal}
        handleClose={handleCloseLayerModal}
        data={layers}
        mapId={configData.mapId}
      />
    </div>
  );
}
