import * as Yup from "yup";

const AggregateTableSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      }),
    name_vi: Yup.string().nullable()
      .when("show_header", {
        is: true,
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          }))
          .min(3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          )
      })
    ,
    // start_time: Yup.date().typeError(
    //   intl.formatMessage({
    //     id: "CLIENT.GENERAL.DATE.INVALID",
    //   })
    // ).max(Yup.ref('end_time'), intl.formatMessage({
    //   id: "CLIENT.SYNC_DATA_REQUEST.FROM_TO_DATE.INVALID",
    // })),
    // end_time: Yup.date().typeError(
    //   intl.formatMessage({
    //     id: "CLIENT.GENERAL.DATE.INVALID",
    //   })
    // ),
    start_date: Yup.date().typeError(
        intl.formatMessage({
          id: "CLIENT.GENERAL.DATE.INVALID",
        })).
      test(
        'start date',
        intl.formatMessage({
          id: "CLIENT.GENERAL.DATE.INVALID",
        }),
        function (value) {
          const { end_date } = this.parent;
          if (value && end_date) {
            return value <= end_date;
          }
          return true;
        }
      )
    ,
    end_date: Yup.date().typeError(
      intl.formatMessage({
        id: "CLIENT.GENERAL.DATE.INVALID",
      })).
      test(
        'end date',
        intl.formatMessage({
          id: "CLIENT.GENERAL.DATE.INVALID",
        }),
        function (value) {
          const { start_date } = this.parent;
          if (value && start_date) {
            return value >= start_date;
          }
          return true;
        }
      ),
    // functions.length > 0
    functions : Yup.array().of(
      Yup.string().required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ))
      .min(1,
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
      station_id: Yup.number().required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),

  });

  return schema;
}

export default AggregateTableSchema