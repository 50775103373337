/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Container } from '@material-ui/core';
import { useDataFileList } from 'app/pages/DataFiles/hooks';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import DataFilesContent from '../../../../content/EndUserDataFilesContent';
import dataFileApi from '../../../api/dataFileApi';
import DateRangePicker from '../../../shared/components/DateRangePicker';
import SharedInputField from '../../../shared/components/SharedInputField';
import SharedMultiSelect from '../../../shared/components/SharedLiteMultiSelect';
import SharedTable from '../../../shared/components/SharedTable';
import "./index.css";

const initialValues = {
  search: "",
  user_id: "",
  data_file_status_id: null,
}

const DataFilesList = (props) => {
  const { intl } = props;

  const {
    loading,
    dataFiles,
    page,
    pageSize,
    sortModel,
    data,
    language,
    dateRange,

    setPage,
    setPageSize,
    setData,
    fetchData,
    setDateRange,
    onPageChange,
    onEdit,
    onView,
    onPageSizeChange,
    onSortModelChange,
    onChangeDateRange
  } = useDataFileList({ dataFileApi });

  useEffect(() => {
    const fetchData = async () => {
      const [
        users,
        status
      ] = await Promise.all([
        dataFileApi.getUsers(),
        dataFileApi.getAllStatus(),
      ])
      if (users.success && status.success) {
        setData(prevData => ({
          ...prevData,
          users: users.data,
          status: status.data,
        }))
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    fetchData(formik.values);
  }, [page, pageSize, sortModel]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      data_file_status_id: data.status.map(item => ({
        value: item.id,
        label: item[`language_${language}`]
      }))
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (page === 0) {
        fetchData(values)
      } else {
        setPage(0);
        setPageSize(5)
      }
    },
  });

  const handleClear = () => {
    formik.setValues({
      ...initialValues,
      data_file_status_id: data.status.map(item => ({
        value: item.id,
        label: item[`language_${language}`]
      }))
    })
    fetchData({
      ...initialValues,
      data_file_status_id: data.status.map(item => ({
        value: item.id,
        label: item[`language_${language}`]
      })),
      startTime: moment().startOf('day'),
      endTime: moment(),
    })
    setDateRange(prevData => ({
      ...prevData,
      startTime: moment().startOf('day'),
      endTime: moment(),
    }))
    setPage(0);
    setPageSize(5)
  }

  const getOptionLabel = (option) => `${option.label}`;
  const getOptionDisabled = (option) => option.value === 'foo';
  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("data_file_status_id", selectedOptions);
  }

  const handleClearOptions = () => {
    formik.setFieldValue("data_file_status_id", [])
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      formik.setFieldValue("data_file_status_id", data.status.map(item => ({
        value: item.id,
        label: item[`language_${language}`]
      })))
    } else {
      handleClearOptions();
    }
  };

  const handleSelectParam = (options) => {
    if (options.length === 0) {
      handleClearOptions();
    }
  };

  return (
    <Container className="enduser-page">
      <div className="main-content data-files enduser">
        <h2 className="main-title">
          <FormattedMessage id="CLIENT.DATA_FILES.PAGE_TITLE" />
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" justifyContent="space-between">
            <Box mb={3} width="49%">
              <SharedInputField
                name="search"
                label={intl.formatMessage({ id: 'CLIENT.GENERAL.TABLE_KEYWORD_LABEL' })}
                type="text"
                placeholder={intl.formatMessage({ id: 'CLIENT.GENERAL.TABLE_KEYWORD_HINT' })}
                {...formik.getFieldProps("search")}
                disabled={formik.isSubmitting}
              />
            </Box>
            <Box mb={3} width="23%">
              <Box className="form-label">
                <FormattedMessage id="CLIENT.DATA_FILES.DATE_LABEL" />
              </Box>
              <DateRangePicker
                className="date-picker"
                value={{
                  startDate: dateRange['startTime'].toDate(),
                  endDate: dateRange['endTime'].toDate(),
                }}
                onChange={(range) => onChangeDateRange(range)}
                left={"0"}
              />
            </Box>
            <Box mb={3} width="23%">
              <Box className="form-label">
                {intl.formatMessage({ id: "CLIENT.DATA_FILES.STATUS_LABEL" })}
              </Box>
              <SharedMultiSelect
                items={data.status.map((item) => ({
                  value: item.id,
                  label: item[`name_${language}`]
                }))}
                className="data-files-select text-normal"
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                selectedValues={formik.values["data_file_status_id"] || []}
                label=""
                selectAllLabel={intl.formatMessage({
                  id: 'CLIENT.GENERAL.SELECT_ALL_LABEL',
                })}
                noOptionsText={intl.formatMessage({
                  id: 'CLIENT.GENERAL.NO_OPTION',
                })}
                onToggleOption={handleToggleOption}
                onClearOptions={handleClearOptions}
                onSelectAll={handleSelectAll}
                onSelect={handleSelectParam}
              />
            </Box>
          </Box>
          <Box mb={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Link to="/data-files/add">
                <Button
                  className="custom-button light-button"
                  type="button"
                >
                  <FormattedMessage id="CLIENT.DATA_FILES.NEW" />
                </Button>
              </Link>
            </Box>
            <Box display="flex" ml="auto">
              <Box mr={2}>
                <Button
                  className="custom-button light-button"
                  type="button"
                  onClick={handleClear}
                >
                  <FormattedMessage id="CLIENT.GENERAL.TABLE_CLEAR_BUTTON" />
                </Button>
              </Box>
              <Button
                className="custom-button"
                type="submit"
              >
                <FormattedMessage id="CLIENT.GENERAL.TABLE_APPLY_BUTTON" />
              </Button>
            </Box>
          </Box>
        </form>
        <Box>
          <SharedTable
            count={dataFiles.total}
            data={dataFiles.data.map(item => ({
              ...item,
              "status.name_en": item.status.name_en,
              "status.name_vi": item.status.name_vi,
              "data_sharing_request.title": item.data_sharing_request.title,
              "user.fullname": item.user.fullname,
            }))}
            page={page}
            pageSize={pageSize}
            sortModel={sortModel}
            loading={loading}
            content={DataFilesContent()}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortModelChange}
            actionHandlers={{
              edit: onEdit,
              view: onView,
            }}
            // disabledControls={{
            //   edit: (row) => !isAllowed('data_files', 'edit'),
            // }}
            hideControls={{
              edit: (row) => row.status.id !== 1,
              view: (row) => row.status.id === 1,
            }}
          />
        </Box>
      </div>
    </Container>
  );
};

export default injectIntl(DataFilesList);
