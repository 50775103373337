import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import DateRangePicker from "../../DateRangePicker"
import moment from "moment";

import './styles.css';
import { Box, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '80vh',
    overflowY: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: '100%',
  },
}));

export default function DateRangeModal(props) {
  const { maxDate } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(props['open'] || false);
  const intl = useIntl();
  const [dateRange, setDateRange] = React.useState({
    startTime: moment().startOf('day'),
    endTime: moment(),
  });

  const toggleOpen = () => {
    props['handleClose']();
  };

  const onSubmit = () => {
    props['handleClose']();
    props?.onSubmit?.(dateRange);
  };

  const onChangeDateRange = (range) => {
    let start = moment(range['startDate']).startOf('day');
    let end = moment(range['endDate']).endOf('day');

    setDateRange({
      startTime: start,
      endTime: end,
    });
  };

  const renderHeader = () => {
    return (
      <div className="date-range-modal-header">
        <div className="date-range-modal-header-left">
          <FormattedMessage id="CLIENT.DASHBOARD.SELECT_DATE_RANGE" />
        </div>
        <div className="date-range-modal-header-right">
          <span className="date-range-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon />
          </span>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="date-range-modal-body">
        <DateRangePicker
          value={{
            startDate: dateRange['startTime'].toDate(),
            endDate: dateRange['endTime'].toDate(),
          }}
          onChange={(range) => onChangeDateRange(range)}
          isNormal
          maxDate={maxDate}
        />

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              className="custom-button"
              onClick={onSubmit}
            >
              <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
            </Button>
          </Box>
          <Button
            className="custom-button light-button"
            onClick={toggleOpen}
          >
            <FormattedMessage id="CLIENT.GENERAL.ACTION_CANCEL" />
          </Button>
        </Box>
      </div>
    );
  };

  React.useEffect(() => {
    setOpen(props['open']);

    // const rows = getAll();

    // if (props['data']) {
    //   setValues({ ...props['data'] });
    // } else {
    //   setValues(getDefaultValues());
    // }

    // setRows(rows);
  }, [props['open']]);

  return (
    <Modal
      open={open}
      onClose={props['handleClose']}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}
