import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import AuthLayout from "../../../shared/layouts/AuthLayout";
import { MainForm } from "./components";
import { useForgotPassword } from "./hooks";

function ForgotPassword(props) {
  const { intl } = props;
  const pageData = useForgotPassword({ intl });
  const { counter, isRequested, setResend, setCounter } = pageData;

  useEffect(() => {
    const timer =
      counter > 0 &&
      isRequested &&
      setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setResend(true);
    }

    return () => {
      clearInterval(timer);
      setResend(false);
    };
  }, [counter, isRequested]);

  return (
    <AuthLayout
      title={intl.formatMessage({ id: "CLIENT.FORGOT.PAGE_TITLE" })}
      description={intl.formatMessage({ id: "CLIENT.FORGOT.PAGE_SUBTITLE" })}
    >
      <MainForm intl={intl} pageData={pageData} />
    </AuthLayout>
  );
}

export default injectIntl(ForgotPassword);
