import { lazy } from "react";
import Loadable from "app/shared/components/Loader/Loadable";

export const stationRoutes = [
  {
    path: "/station/add/:id",
    component: Loadable(lazy(() => import("./pages/station-add.page"))),
    permissionId: "stations",
    action: "new",
  },
  {
    path: "/station/edit/:id",
    component: Loadable(lazy(() => import("./pages/station-edit.page"))),
    permissionId: "stations",
    action: "view",
    exact: true,
  },
];
