import React from "react";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import "./index.css";
import { Button } from "@material-ui/core";
import { useLanguage } from "../../shared/hooks";

export default function NotFound(props) {
  const [language] = useLanguage();

  return (
    <div className="not-found">
      <div className="lock-icon">
        <LockIcon style={{ fontSize: 50 }} />
      </div>

      <h1>{language === "en" ? "Access Denied" : "Truy cập bị từ chối"}</h1>

      <p>
        {language === "en"
          ? "You don't have permissions to access this page."
          : "Bạn không có quyền truy cập trang này."}
      </p>

      <Link to="/">
        <Button className="custom-button light-button">
          {language === "en" ? "Go To Dashboard" : "Trở Về Trang Chủ"}
        </Button>
      </Link>
    </div>
  );
}
