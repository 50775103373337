export const DataSharingAddInitialValues = {
  address: "",
  latitude: "",
  longtitude: "",
  user: null,
  email: "",
  fullname: "",
  phone: "",
  organization_name: "",
  organization_address: "",
  title: ""
}

export const DataSharingEditInitialValues = {
  address: "",
  latitude: "",
  longtitude: "",
  user: null,
  email: "",
  fullname: "",
  phone: "",
  data_key: "",
  comment: "",
}

export const SearchListInitialValues = {
  search: "",
  location_type: "",
  status: "",
};

export const DummyDataKey = 'dummykey';