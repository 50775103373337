import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import { FormattedMessage } from "react-intl";
import { COMBINED_DATA, HISTORICAL_DATA, PREDICTION_DATA } from "../contants";
import ChartList from "./ChartList";
import { FEATURES_OPTIONS } from "./constants";


const StandardChartForm = ({
  formik,
  intl,
  language,
  parameters
}) => {
  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.HEADER_LABEL',
          })}>

          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.DATAMODE',
          })}>

          <DataModeConfigurator
            showHistoricTimeRangep
            options={[
              HISTORICAL_DATA,
              PREDICTION_DATA,
              COMBINED_DATA
            ]}
            values={{
              historical_time_range: `${formik.values['historical_time_range']}-${formik.values['historical_time_range_type']}`,
              prediction_time_range: `${formik.values['prediction_time_range']}-${formik.values['prediction_time_range_type']}`,
              historical_start_time: formik.values.historical_start_time,
              historical_end_time: formik.values.historical_end_time,
            }}
            mode={formik.values['data_mode']}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            predictionLabel={<FormattedMessage id='CLIENT.DASHBOARD.DATAMODE.HISTORICAL.TIMEPOINT' />}
            combinedDataPredictionLabel={<FormattedMessage id='CLIENT.DASHBOARD.DATAMODE.COMBINED_PREDICTION_LABEL' />}
            combinedDataHistoricalLabel={<FormattedMessage id='CLIENT.DASHBOARD.DATAMODE.COMBINED_HISTORICAL_LABEL' />}
            error={formik.errors["historical_start_time"]}
          />
        </ConfigureArea>

        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.FEATURE',
          })}>
          <Grid container>
            {
              FEATURES_OPTIONS.map(item =>
                <Grid item xs={6} md={3} key={item.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values[item.value]}
                        onChange={(e) =>
                          formik.setFieldValue(item.value, e.target.checked)
                        }
                      />
                    }
                    label={item[`name_${language}`]}
                  />
                </Grid>

              )
            }
          </Grid>
        </ConfigureArea>

        <Box mb={3}>
          <h2 className="dashboard-title-1">
            <FormattedMessage id='CLIENT.DASHBOARD.CHARTS' />
          </h2>
          <ChartList
            data={formik.values["dashboard_standard_chart_parameter"]}
            onChange={(data) => {
              formik.setFieldValue('dashboard_standard_chart_parameter', data);
            }}
            parameters={parameters || []}
          />
        </Box>
      </form>
    </div>
  );
}

StandardChartForm.propTypes = {
};

export default StandardChartForm