import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid } from "@material-ui/core";

const RequiredFields = () => {
  return (
    <Grid item md={6} xs={12}>
      <Box
        display="flex"
        justifyContent="flex-end"
        height="100%"
        className="note-msg"
        style={{ paddingTop: "9px" }}
      >
        *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
      </Box>
    </Grid>
  );
};

export default RequiredFields;
