import { Box, CircularProgress } from "@material-ui/core";
import ExploreIcon from "@material-ui/icons/Explore";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { DATE_TIME_FORMAT } from "app/constant/date-time-format";
import { NON_TABLE_ALARM_COLOR } from "app/shared/constants/dashboard";
import { useLanguage } from "app/shared/hooks";
import { invertColor } from "app/utils/utils";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";

const DirectionCard = (props) => {
  const intl = useIntl();
  const [language] = useLanguage();
  const name =
    (props["data"] && props["data"][`name_${language}`]) ||
    intl.formatMessage({ id: "CLIENT.DASHBOARD.MODAL.NEW_DIRECTION" });
  const directions =
    (props["data"] &&
      props["data"]["dashboard_direction"]?.["number_of_direction"]) ||
    4;
  const showNumbers =
    props["data"] && props["data"]?.["dashboard_direction"]?.["show_number"];
  const mainColor =
    (props["data"] && props["data"]["dashboard_direction"]?.["main_color"]) ||
    "white";

  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;

  const dataAlarm =
    props.data?.dashboard_direction?.parameter?.data_alarm || [];
  const [backgroundColor, setBackgroundColor] = useState();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    value: 0,
    date: null,
  });

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      const parameter = dashboardData?.parameterDatas?.at(0);
      if (parameter?.dataPointDtos?.length > 0) {
        const lastDataPoint = parameter.dataPointDtos.at(-1); // get last value

        const isAlarm = lastDataPoint?.isAlarm;

        const parameter_name =
          language === "en"
            ? props.data?.dashboard_direction?.parameter?.name
            : props.data?.dashboard_direction?.parameter?.name_vi;

        const data = {
          ...props.data.dashboard_direction,
          unit: props.data?.dashboard_direction?.parameter?.unit,
          name: parameter_name,
          value: Number(lastDataPoint?.value || 0) % 360,
          date: moment(lastDataPoint.eventTime).format(DATE_TIME_FORMAT),
          isAlarm,
        };

        if (isAlarm) {
          dataAlarm?.forEach((alarm) => {
            if (alarm.id === lastDataPoint?.alarmId) {
              setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
            }
          });
        } else setBackgroundColor();

        setData((pre) => ({
          ...pre,
          ...data,
        }));
      }
    };

    if (props.data && props.data.dashboard_direction) {
      initData();
    }
  }, [props["data"]]);

  // Data
  useEffect(() => {
    if (
      data?.parameter_id &&
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId
    ) {
      if (data?.parameter_id === props.realTimeData?.paramId) {
        const lastDataPoint = props.realTimeData?.value;
        const isAlarm =
          props.realTimeData?.name_en !== undefined &&
          props.realTimeData?.name_vi !== undefined;

        const newData = {
          ...data,
          value: Number(lastDataPoint || 0) % 360,
          isAlarm: false,
          date: moment(props.realTimeData?.eventTime).format(DATE_TIME_FORMAT),
        };
        setData(newData);
      }
    }
  }, [
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
    data?.parameter_id,
  ]);

  // Alarm
  useEffect(() => {
    if (
      data?.parameter_id &&
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId
    ) {
      if (data?.parameter_id === props.realTimeDataAlarm?.paramId) {
        const lastDataPoint = props.realTimeDataAlarm?.value;

        const newData = {
          ...data,
          value: Number(lastDataPoint || 0) % 360,
          isAlarm: true,
          date: moment(props.realTimeDataAlarm?.eventTime).format(
            DATE_TIME_FORMAT
          ),
        };

        dataAlarm?.forEach((alarm) => {
          if (alarm.id === props.realTimeDataAlarm?.alarm_id) {
            setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
          }
        });
        setData(newData);
      }
    }
  }, [
    props.realTimeDataAlarm?.eventTime,
    props.realTimeDataAlarm?.paramId,
    data?.parameter_id,
  ]);

  return (
    <div className="direction-card dashboard-item-card">
      {showHeader && (
        <p
          className="direction-card-title"
          style={{ background: headerColor, color: invertColor(headerColor) }}
        >
          <ExploreIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}

      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div className="direction-card-body">
          <div className="direction-card-content">
            {props["data"] &&
            props["data"]["dashboard_direction"]["parameter_id"] ? (
              <>
                <div className="compass">
                  <img
                    alt=""
                    src={`/images/directions/${
                      showNumbers === true ? "1" : "0"
                    }-${directions}.png`}
                    className="compass-sample"
                    style={{
                      height: "90%",
                      width: "90%",
                      margin: "0 auto",
                      objectFit: "contain",
                    }}
                  />
                  <img
                    alt=""
                    class="compass-pointer"
                    src="/images/directions/pointer.png"
                    style={{
                      transform: `translate(-50%, -50%) rotate(${
                        data?.value - 52
                      }deg)`,
                      objectFit: "contain",
                    }}
                  />
                </div>

                <p
                  className="direction-card-value"
                  style={{
                    color: backgroundColor ?? mainColor,
                  }}
                >
                  {Number(data?.value).toFixed(2)}°
                </p>

                <Box mt={2} mb={2}>
                  <FormattedMessage id="CLIENT.DASHBOARD.LAST_RECORDED_TIME" />:{" "}
                  {data?.date}
                </Box>
              </>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default DirectionCard;
