import { Box, Button, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styles from "./index.module.css";
import {
  ADD_NEW_DASHBOARD,
  ADD_NEW_MAP,
} from "app/shared/layouts/MainLayout/Sidebar/constants";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: "80vh",
    overflowY: "auto",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 2.5, 4.25),
  },
  formControl: {
    width: "100%",
  },
}));

function MenuRenameModal({
  isOpen = false,
  intl = {},
  name = "",
  title = "",
  label = "",
  placeholder = "",
  modalType = "",
  handleClose = () => {},
  handleRename = () => {},
}) {
  const defaultName =
    modalType === ADD_NEW_DASHBOARD
      ? intl.formatMessage({
          id: "CLIENT.DASHBOARD.PAGE_TITLE",
        })
      : modalType === ADD_NEW_MAP
      ? intl.formatMessage({
          id: "CLIENT.DASHBOARD.MAP",
        })
      : name || "";

  const [modalStyle] = useState(getModalStyle);
  const [valueName, setValueName] = useState(defaultName);
  const classes = useStyles();

  const onClose = () => {
    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  useEffect(() => {
    setValueName(defaultName);
  }, [modalType]);

  const renderHeader = () => {
    return (
      <div className="value-modal-header">
        <div className="value-modal-header-left">
          <FormattedMessage id={title} />
        </div>

        <div className="value-modal-header-right">
          <span className="value-modal-header-icon">
            <CloseIcon onClick={onClose} />
          </span>
        </div>
      </div>
    );
  };

  const handleSave = (e) => {
    if (valueName.length > 0) {
      handleRename(e, valueName);
      handleClose();
    }
  };

  const renderBody = () => {
    return (
      <Box component="form" autoComplete="off">
        <div className={styles.labelRename}>
          <p> *</p>
          <FormattedMessage id={label} />
        </div>

        <TextField
          type="text"
          required
          label=""
          placeholder={intl.formatMessage({
            id: placeholder,
          })}
          variant="outlined"
          fullWidth
          onChange={(e) => setValueName(e.target.value)}
          defaultValue={defaultName}
        />

        {valueName.length === 0 ? (
          <div className={styles.requiredText}>
            <FormattedMessage id="CLIENT.GENERAL.REQUIRED_FIELD" />
          </div>
        ) : (
          ""
        )}

        <div className={styles.groupBtn}>
          <Button
            variant="contained"
            className="custom-button"
            onClick={handleSave}
          >
            <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
          </Button>

          <Button
            className="custom-button light-button"
            type="button"
            onClick={onClose}
          >
            <FormattedMessage id="CLIENT.GENERAL.ACTION_CANCEL" />
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {renderHeader()}
        {renderBody()}
      </div>
    </Modal>
  );
}

export default injectIntl(MenuRenameModal);
