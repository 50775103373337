import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Button,
  Divider,
  Popper,
  ClickAwayListener,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, Add } from "@material-ui/icons";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  rootPopup: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    marginTop: "10px",
    minWidth: 200,
  },
  group: {
    position: "relative",
    overflow: "visible",
    marginRight: "30px",
  },
  popup: {
    zIndex: 102,
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
  },
  button: {
    backgroundColor: "#fff",
    color: "#1e1e2d",
    textTransform: "capitalize",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderRadius: "5px",
    minWidth: 150,
    fontWeight: 400,
  },
  text: {
    padding: "0px 12px",
  },
  itemText: {
    maxWidth: 180,
    marginRight: 10,
    "& span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      "text-overflow": "ellipsis",
    },
  },
  itemIcon: {
    minWidth: "30px",
  },
  scrollList: {
    maxHeight: 200,
    overflowY: "auto",
  },
}));

export const StationsDropdown = (props) => {
  const {
    label,
    icon,
    addActionLabel,
    onClickAdd,
    data,
    checked = [],
    noDataMessage,
    onChange,
    isPublic = false,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (value) => () => {
    if (value === -1) {
      handleSelectAll();
    } else {
      const currentIndex = checked?.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      if (onChange) {
        onChange(newChecked);
      }
    }
  };

  const handleSelectAll = () => {
    let newChecked = [...checked];

    if (newChecked.length === 0) {
      // No option selected
      newChecked = data?.map((item) => item.value) || [];
    } else if (newChecked.length === data?.length) {
      // Is selecting all
      newChecked = [];
    } else {
      // Is selecting some option
      data?.forEach((item) => {
        if (checked?.indexOf(item.value) === -1) {
          newChecked.push(item.value);
        }
      });
    }

    if (onChange) {
      onChange(newChecked);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${label}-popper` : undefined;

  return (
    <div className={classes.group}>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={classes.button}
        startIcon={icon}
        endIcon={<KeyboardArrowDown sx={{ marginLeft: 10 }} />}
        variant="contained"
        color="default"
      >
        <span className={classes.text}>{label}</span>
      </Button>

      <Popper
        placement="bottom-start"
        className={classes.popup}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={handleClick}>
          <Box sx={{ boxShadow: 3 }} className={classes.rootPopup}>
            <List className={classes.scrollList}>
              {data?.length ? (
                [
                  {
                    value: -1,
                    label: intl.formatMessage({
                      id: "CLIENT.GENERAL.SELECT_ALL",
                    }),
                  },
                  ...data,
                ]?.map((item) => {
                  const labelId = `checkbox-list-label-${item.value}`;
                  let valueChecked =
                    (item.value === -1 && data?.length === checked?.length) ||
                    checked?.indexOf(Number(item.value)) !== -1;

                  return (
                    <ListItem
                      key={item.value}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(item.value)}
                    >
                      <Checkbox
                        edge="start"
                        checked={valueChecked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                      <ListItemText
                        className={classes.itemText}
                        id={labelId}
                        primary={item.label}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText
                    className={classes.itemText}
                    primary={
                      noDataMessage ?? (
                        <FormattedMessage id="CLIENT.STATION.NO_DATA" />
                      )
                    }
                  />
                </ListItem>
              )}
            </List>
            <Divider />
            {!isPublic && (
              <List>
                <ListItem
                  dense
                  button
                  onClick={() => {
                    onClickAdd();
                    handleClick();
                  }}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <Add />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.itemText}
                    primary={addActionLabel}
                  />
                </ListItem>
              </List>
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

StationsDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  checked: PropTypes.array,
  label: PropTypes.node,
  icon: PropTypes.node,
  noDataMessage: PropTypes.node,
  onChange: PropTypes.func,
  onClickAdd: PropTypes.func,
  isPublic: PropTypes.bool,
};
