import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function LoadingChartData({ isInitLoading = false }) {
  if (isInitLoading) {
    return (
      <div className="no-data-area">
        <p className="no-data-label">
          <CircularProgress />
        </p>
      </div>
    );
  }

  return (
    <span style={loadingStyles.loader}>
      <CircularProgress />
    </span>
  );
}

const loadingStyles = {
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    padding: "24px",
    borderRadius: "100%",
    border: "1px solid #eee",
  },
  zoomBar: {
    display: "flex",
    alignItems: "center",
  },
  zoomButton: {
    color: "white",
    background: "#3499fe",
    width: 30,
    height: 30,
    marginTop: 30,
    borderRadius: 3,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px 4px",
  },
  timeRangeLabel: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 24,
  },
  sliderLoaderContainer: {
    textAlign: "center",
    marginTop: 12,
  },
};
