import { RouteWithNavBar } from "app/shared/components/RouteWithNavBar";
import { usePermissions } from "app/shared/hooks";
import { flatten } from "lodash";

export const FeatureRoutes = ({ routes = [] }) => {
  const { isAllowed } = usePermissions();
  const flattenRoutes = flatten(routes);

  return (
    <>
      {flattenRoutes?.map((route) => {
        if (isAllowed(route?.permissionId, route?.action)) {
          return (
            <RouteWithNavBar
              key={route?.path}
              path={route?.path}
              component={route?.component}
              isAllowed={isAllowed(route?.permissionId, route?.action)}
              exact={route?.exact}
            />
          );
        }

        return null;
      })}
    </>
  );
};
