import * as Yup from "yup";

const PredictionParameterHistoryTableSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    name_vi: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    parameter_id: Yup.number().required(
      intl.formatMessage({
        id: "CLIENT.GENERAL.REQUIRED_FIELD",
      })
    ),
    prediction_parameter_id: Yup.number()
      .nullable()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    historical_start_time: Yup.object().test(
      "start-time",
      intl.formatMessage({ id: "CLIENT.GENERAL.DATE.INVALID" }),
      (value, ctx) => {
        return value <= ctx.parent.historical_end_time;
      }
    ),
    historical_end_time: Yup.object().test(
      "end-time",
      intl.formatMessage({ id: "CLIENT.GENERAL.DATE.INVALID" }),
      (value, ctx) => {
        return value >= ctx.parent.historical_start_time;
      }
    ),
    // historical_start_time: Yup.date()
    //   .when('data_mode', {
    //     is: (option) => option === HISTORICAL_DATA,
    //     then: Yup.date()
    //       .when('historical_time_range', {
    //         is: value => value === '0',
    //         then: Yup.date()
    //           .typeError(
    //             intl.formatMessage({
    //               id: "CLIENT.GENERAL.DATE.INVALID",
    //             })
    //           )
    //           .max(Yup.ref('historical_end_time'), intl.formatMessage({
    //             id: "CLIENT.SYNC_DATA_REQUEST.FROM_TO_DATE.INVALID",
    //           }))
    //           .nullable()
    //           .required(
    //             intl.formatMessage({
    //               id: "CLIENT.GENERAL.REQUIRED_FIELD",
    //             })
    //           )
    //       })
    //   }),
    // historical_end_time: Yup.date()
    //   .when('data_mode', {
    //     is: (option) => option === HISTORICAL_DATA,
    //     then: Yup.date()
    //       .when('historical_time_range', {
    //         is: value => value === '0',
    //         then: Yup.date()
    //           .typeError(
    //             intl.formatMessage({
    //               id: "CLIENT.GENERAL.DATE.INVALID",
    //             })
    //           )
    //           .nullable()
    //           .required(
    //             intl.formatMessage({
    //               id: "CLIENT.GENERAL.REQUIRED_FIELD",
    //             })
    //           )
    //       })
    //   }),
  });

  return schema;
};

export default PredictionParameterHistoryTableSchema;
