import { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { Button, Icon, InputAdornment, TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import DateTimePicker from "app/shared/components/DateTimePicker";
import { DateRange, Close } from "@material-ui/icons";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "app/constant/date-time-format";
import { Alert } from "@material-ui/lab";
import {
  RANGE_OPTIONS,
  DEFAULT_RANGE_OPTION,
  CONTAINER_DOM_ELEMENT,
} from "./constants";
import styles from "./styles.module.css";
import { useLanguage } from "app/shared/hooks";

export const DateTimeFiltersModal = ({
  rangeOption: inputRangeOption = null,
  startTime: inputStartTime = null,
  endTime: inputEndTime = null,
  onClose = () => {},
  onSubmit = () => {},
  minTime,
  maxTime,
  isDateTime = false,
}) => {
  const [startTime, setStartTime] = useState(minTime);
  const [endTime, setEndTime] = useState(maxTime);
  const [activeRangeOption, setActiveRangeOption] =
    useState(DEFAULT_RANGE_OPTION);
  const [language] = useLanguage();
  const onSelectRangeOption = (rangeOption) => () => {
    setActiveRangeOption(rangeOption);

    if (rangeOption?.start_time && rangeOption?.end_time) {
      setStartTime(rangeOption?.start_time);
      setEndTime(rangeOption?.end_time);
    }

    if (rangeOption?.is_custom || rangeOption?.is_all_time) {
      setStartTime(minTime);
      setEndTime(maxTime);
    }
  };

  const handleSubmit = () => {
    onSubmit({
      startTime,
      endTime,
      rangeOption: activeRangeOption?.is_all_time ? null : activeRangeOption,
    });

    onClose();
  };

  const handleReset = () => {
    const defaultRangeOption = RANGE_OPTIONS[0];

    onSubmit({
      startTime: defaultRangeOption?.start_time,
      endTime: defaultRangeOption?.end_time,
      rangeOption: null,
    });

    onClose();
  };

  const handleClose = () => {
    if (inputRangeOption === null) {
      onSelectRangeOption(DEFAULT_RANGE_OPTION);
    } else {
      onSelectRangeOption(inputRangeOption);
    }

    onClose();
  };

  const isValidDate = (current) => {
    return current.isBetween(minTime, maxTime);
  };

  const filteredOptions = useMemo(() => {
    return RANGE_OPTIONS?.filter((option) => {
      if (option?.is_custom === true || option?.is_all_time === true) {
        return true;
      }

      return (
        option?.start_time?.isBetween(minTime, maxTime) &&
        option?.end_time?.isBetween(minTime, maxTime)
      );
    });
  }, [minTime, maxTime]);

  useEffect(() => {
    if (inputRangeOption !== null) {
      setActiveRangeOption(inputRangeOption);
    } else {
      setActiveRangeOption(DEFAULT_RANGE_OPTION);
    }

    if (inputStartTime !== null) {
      setStartTime(inputStartTime);
    }

    if (inputEndTime !== null) {
      setEndTime(inputEndTime);
    }
  }, [inputRangeOption, inputStartTime, inputEndTime]);

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <h3 className={styles.headerTitle}>
            <FormattedMessage id="CLIENT.DASHBOARD.DATE_TIME_FILTER_MODAL.MODAL_TITLE" />
          </h3>

          <Close onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <div className={styles.body}>
          <div className={styles.leftPanel}>
            {filteredOptions?.map((range) => (
              <div
                className={`${styles.rangeOption} ${
                  activeRangeOption?.id === range?.id
                    ? styles.rangeOptionActive
                    : ""
                }`}
                key={range?.id}
                onClick={onSelectRangeOption(range)}
              >
                {range?.[`name_${language}`]}
              </div>
            ))}
          </div>

          <div className={styles.rightPanel}>
            {/* {activeRangeOption?.is_custom === true && (
              <Alert severity="info">
                <p>
                  <FormattedMessage id="CLIENT.DASHBOARD.DATE_TIME_FILTER_MODAL.TIME_RANGE_FROM" />{" "}
                  <strong>{minTime?.format(DATE_FORMAT)}</strong>{" "}
                  <FormattedMessage id="CLIENT.DASHBOARD.DATE_TIME_FILTER_MODAL.TIME_RANGE_TO" />{" "}
                  <strong>{maxTime?.format(DATE_FORMAT)}</strong>
                </p>
              </Alert>
            )} */}

            <div className={styles.inputGroup}>
              <div className={styles.label}>
                <FormattedMessage id="CLIENT.DASHBOARD.DATE_TIME_FILTER_MODAL.START_DATE_TIME" />
              </div>

              <DateTimePicker
                value={startTime}
                initialValue={startTime}
                dateFormat={DATE_FORMAT}
                timeFormat={isDateTime ? "HH:mm:ss" : false}
                // isValidDate={isValidDate}
                onChange={(value) => setStartTime(value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"center"}>
                          <Icon>
                            <DateRange />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    style={{ background: "white" }}
                    disabled={activeRangeOption?.is_custom !== true}
                  />
                )}
              />
            </div>

            <div className={styles.inputGroup}>
              <div className={styles.label}>
                <FormattedMessage id="CLIENT.DASHBOARD.DATE_TIME_FILTER_MODAL.END_DATE_TIME" />
              </div>

              <DateTimePicker
                value={endTime}
                initialValue={endTime}
                dateFormat={DATE_FORMAT}
                timeFormat={isDateTime ? "HH:mm:ss" : false}
                // isValidDate={isValidDate}
                onChange={(value) => setEndTime(value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    {...params}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"center"}>
                          <Icon>
                            <DateRange />
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    style={{ background: "white" }}
                    disabled={activeRangeOption?.is_custom !== true}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerLeft}>
            <Button
              className="custom-button light-button"
              type="button"
              style={{ marginRight: 12 }}
              onClick={handleReset}
            >
              <FormattedMessage id="CLIENT.GENERAL.RESET" />
            </Button>
          </div>

          <div className={styles.footerRight}>
            <Button
              className="custom-button light-button"
              type="button"
              style={{ marginRight: 12 }}
              onClick={handleClose}
            >
              <FormattedMessage id="CLIENT.GENERAL.CANCEL_BUTTON" />
            </Button>

            <Button
              className="custom-button"
              type="button"
              onClick={handleSubmit}
            >
              <FormattedMessage id="CLIENT.GENERAL.APPLY" />
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(CONTAINER_DOM_ELEMENT)
  );
};
