import { lazy } from "react";
import Loadable from "app/shared/components/Loader/Loadable";

export const mapRoutes = [
  {
    path: "/workspaces/:workspaceId/maps/:id",
    component: Loadable(lazy(() => import("./pages/private-map.page"))),
    permissionId: "stations",
    action: "view",
  },
  {
    path: "/monitoring-map/:workspaceId/maps/:id",
    component: Loadable(lazy(() => import("./pages/private-map-v2.page"))),
    permissionId: "stations",
    action: "view",
  },
  {
    path: "/monitoring-map",
    component: Loadable(lazy(() => import("./pages/private-map-v2.page"))),
    permissionId: "stations",
    action: "view",
  },
];
