import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M15.752 1.667h10.5v5h5.25c1.925 0 3.5 1.5 3.5 3.333v7.7l2.24.7c.455.133.84.433 1.05.833.21.4.245.867.105 1.3L35.09 31.666h-.088c-2.8 0-5.285-1.466-7-3.333-1.715 1.867-4.2 3.334-7 3.334s-5.285-1.467-7-3.334c-1.715 1.867-4.2 3.334-7 3.334h-.087L3.59 20.532c-.158-.433-.105-.9.105-1.3s.595-.7 1.05-.833l2.257-.7V10c0-1.834 1.575-3.333 3.5-3.333h5.25v-5zm7 3.333v1.667h-3.5V5h3.5zm-1.75 11.85l9.415 2.95 4.183 1.3-1.96 6.616c-.945-.5-1.645-1.183-1.995-1.566l-2.643-2.883-2.642 2.866c-.595.667-2.24 2.2-4.358 2.2-2.117 0-3.762-1.533-4.357-2.2l-2.643-2.866-2.642 2.866a7.649 7.649 0 01-1.995 1.55l-1.978-6.6 4.2-1.316 9.415-2.917zm-10.5-.234V10h21v6.616l-10.5-3.283-10.5 3.283zm10.5 18.317c2.433 0 4.865-.716 7-2.133 2.135 1.417 4.568 2.2 7 2.2h3.5v3.333h-3.5c-2.415 0-4.795-.566-7-1.65a15.863 15.863 0 01-7 1.617c-2.397 0-4.795-.55-7-1.617-2.205 1.067-4.585 1.65-7 1.65h-3.5V35h3.5c2.433 0 4.865-.783 7-2.2a12.648 12.648 0 007 2.133z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
