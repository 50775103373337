import { SvgIcon } from "@material-ui/core";

function FileExportIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
        <path d="M18 22a2 2 0 002-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 00-2 2v16a2 2 0 002 2h12zM13 4l5 5h-5V4z" />
      </svg>
    </SvgIcon>
  );
}

export default FileExportIcon;
