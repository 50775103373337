import {
  ClickAwayListener,
  Fade,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import "./index.css";
import { useEffect, useState } from "react";

const SelectDropdownButton = (props, className) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(props?.option[0]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const onChange = (value) => {
    props?.onChange(value?.key);
    setAnchorEl(null);
    setActiveItem(value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  useEffect(() => {
    if (props?.value) {
      setActiveItem(props?.option?.find((e) => e?.key === props?.value));
    }
  }, [props?.option, props?.value]);
  return (
    <div
      {...props}
      className={clsx(
        "select-dropdown-popper-dropdown",
        props?.disabled && "select-dropdown-button-disabled",
        className
      )}
    >
      <div
        aria-describedby={id}
        className={clsx("select-dropdown-button")}
        onClick={handleClick}
      >
        {props?.children ? (
          props?.children(
            props?.value
              ? props?.option?.find((e) => e?.key === props?.value)?.value
              : activeItem?.value
          )
        ) : (
          <>
            {props?.value
              ? props?.option?.find((e) => e?.key === props?.value)?.value
              : activeItem?.value}

            <div style={styles.icon}>
              {!open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </div>
          </>
        )}
      </div>

      <Popper
        anchorEl={anchorEl}
        open={open}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={styles.menu}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <MenuList autoFocusItem className="body-dropdown-button">
                {props?.option?.map((e) => {
                  return (
                    <MenuItem
                      key={e?.key}
                      onClick={() => onChange(e)}
                      className="item-dropdown-button"
                    >
                      {e?.value}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
const styles = {
  menu: {
    marginTop: 5,
    zIndex: 9999,
    width: 152,
  },
  icon: {
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    color: "rgba(0, 0, 0, 0.54)",
  },
};
export default SelectDropdownButton;
