import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from "../../hooks";
import VI_FLAG from "../../../../assets/images/vi-flag.svg";
import EN_FLAG from "../../../../assets/images/en-flag.svg";
import { injectIntl } from "react-intl";
import { LOCALES } from "../../../languages/constants";
import "./index.css";
import { shallowEqual, useSelector } from "react-redux";
import userApi from "../../../api/userApi";

const LanguageSwitcher = (props) => {
  const { intl } = props;
  const { user, token } = useSelector(
    ({ user }) => ({
      user: user.user,
      token: user.token,
    }),
    shallowEqual,
  );
  const [language, setLanguage] = useLanguage(user && user.language);
  const LANGUAGES = [
    {
      id: 1,
      label: intl.formatMessage({
        id: "GENERAL.VIETNAMESE",
      }),
      icon: VI_FLAG,
      code: LOCALES.VIETNAMESE,
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: "GENERAL.ENGLISH",
      }),
      icon: EN_FLAG,
      code: LOCALES.ENGLISH,
    },
  ];
  const [active, setActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (active) {
      document.addEventListener("click", handleClick);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [active]);

  const handleClick = (event) => {
    const { target } = event;
    if (!ref?.current?.contains(target)) {
      setActive(false);
      document.removeEventListener("click", handleClick);
    }
  };

  const switchLanguage = async (code) => {
    if (code === language) return;
    try {
      if (token) {
        const res = await userApi.changeLanguage(token);
        if (res.success) {
          setLanguage(code);
        }
      } else {
        setLanguage(code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdown = () => {
    setActive(!active);
  };

  return (
    <div className="language-switcher">
      <div className="current-language" onClick={handleDropdown} ref={ref}>
        <div className="wrapper">
          <img
            src={
              LANGUAGES.find((item) => item.code === language) &&
              LANGUAGES.find((item) => item.code === language).icon
            }
            alt=""
          />
          <span>
            {LANGUAGES.find((item) => item.code === language) &&
              LANGUAGES.find((item) => item.code === language).label}
          </span>
        </div>
      </div>
      <div className={`dropdown ${active ? "active" : ""}`}>
        <ul className="list">
          {LANGUAGES.map((item) => (
            <li key={item.id} onClick={() => switchLanguage(item.code)}>
              <img src={item.icon} alt="" />
              <span>{item.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default injectIntl(LanguageSwitcher);
