import { Box, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { PREDICTION_DATA } from "app/pages/Dashboard/components/Modals/contants";
import { useLanguage } from "app/shared/hooks";
import { invertColor } from "app/utils/utils";
import { getInstanceByDom } from "echarts/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardCardButtons } from "../DashboardCardButtons";
import { historicalTimeRange } from "../DataModeConfigurator/data";
import DateRangeModal from "../DateRangeModal";
import StandardChartToolbar from "./components/chart-toolbar.component";
import LoadingChartData from "./components/loading-chart-data.component";
import PopoverFilterDate from "./components/popover-filter-date.component";
import StandardChart from "./components/standard-chart.component";
import { INITIAL_VALUES } from "./constant";
import useStandardChart from "./standardChart.hook";
import "./styles.css";
import { renderStandardChartDetails } from "../Echart/echart.utils";

const StandardChartCardV2 = ({
  id,
  header_color = "#1E1E2D",
  data = {},
  loading: isInitDataLoading = false,
  isPublic = false,
  isView = true,
  onEditDashboard = () => {},
  handleEdit = () => {},
  handleRemove = () => {},
  type = "",
  realTimeData = {},

  isRendering = false,
}) => {
  const dashboardChartId = data.id;
  const [showFilter, setShowFilter] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [language] = useLanguage();
  const intl = useIntl();

  const { chartConfig, listParameterId, receiveDataAndConfig } =
    useStandardChart({
      language,
      header_color,
    });

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  // const [isRealTimeLoading, setIsRealTimeLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const [fullScreen, setFullScreen] = useState(false);

  const { name, headerColor, showHeader } = renderStandardChartDetails(
    data,
    language,
    intl
  );

  const loadDataDashboard = async (id) => {
    setIsChartLoading(true);
    try {
      let res;
      if (isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsChartLoading(false);
    }
    return null;
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        isPublic ? data.public_id : data.id
      );
      if (dashboardData) {
        receiveDataAndConfig(dashboardData, data.dashboard_standard_chart);
      }
    };
    if (
      data &&
      data.dashboard_standard_chart &&
      data.dashboard_standard_chart?.dashboard_id
    ) {
      initData();
    }
  }, [data]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const prepareOutput = (values) => {
    const dataUpdate = {
      ...values,
      dashboard_standard_chart_parameter:
        values["dashboard_standard_chart_parameter"].length > 0 ||
        values["dashboard_prediction_parameter_standard_chart"].length > 0
          ? (
              values["dashboard_standard_chart_parameter"] ||
              values["dashboard_prediction_parameter_standard_chart"]
            ).map((item, index) => ({
              ...item,
              id: item.originId || "",
              index,
            }))
          : [],
      dashboard_standard_chart_parameter_delete:
        data?.dashboard_standard_chart?.dashboard_standard_chart_parameter
          ?.map((item) => item.id)
          ?.filter(
            (item) =>
              !values["dashboard_standard_chart_parameter"]
                ?.map((item) => item.id)
                ?.includes(item)
          ) || [],
    };

    const output = {
      ...values,
      dataUpdate,
    };

    return output;
  };

  const getOutputDefault = () => {
    const {
      data_mode = INITIAL_VALUES.data_mode,
      zoom = INITIAL_VALUES.zoom,
      legend = INITIAL_VALUES.legend,
      range_selection = INITIAL_VALUES.range_selection,
      full_screen = INITIAL_VALUES.full_screen,
    } = data.dashboard_standard_chart || {};

    return {
      name_en: data.name_en || INITIAL_VALUES.name_en,
      name_vi: data.name_vi || INITIAL_VALUES.name_vi,
      header_color: data.header_color || INITIAL_VALUES.header_color,
      show_header:
        data.show_header !== undefined
          ? data.show_header
          : INITIAL_VALUES.show_header,
      data_mode,
      zoom,
      legend,
      range_selection,
      full_screen,

      dashboard_standard_chart_parameter:
        data?.dashboard_standard_chart?.dashboard_standard_chart_parameter
          ?.map((item) => ({
            ...item,
            marker_size: parseInt(item.marker_size),
            originId: item.id,
          }))
          ?.sort((a, b) => a.index - b.index) ||
        INITIAL_VALUES.dashboard_standard_chart_parameter,
    };
  };

  const onSelectRange = (value) => {
    const CustomTimeRangeValue = "0-DAY";
    if (value === CustomTimeRangeValue) {
      setShowModal(true);
    } else {
      const timeRange = `${value}`.split("-")?.at(0);
      const timeRangeType = `${value}`.split("-")?.at(1);
      const isHistoricalData =
        data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA || true;
      const isCustomRange = timeRange === "0";
      const values = {
        ...getOutputDefault(),
        prediction_time_range:
          isHistoricalData || isCustomRange ? "" : timeRange,
        prediction_time_range_type:
          isHistoricalData || isCustomRange ? "" : timeRangeType,
        historical_time_range:
          isHistoricalData && !isCustomRange ? timeRange : "",
        historical_time_range_type:
          isHistoricalData && !isCustomRange ? timeRangeType : "",
      };
      onEditDashboard(type)(id, prepareOutput(values));
      setShowFilter(!showFilter);
    }
  };

  const onSubmitCustomTimeRange = (value) => {
    const isHistoricalData =
      data?.dashboard_standard_chart?.data_mode !== PREDICTION_DATA;
    const values = {
      ...getOutputDefault(),
      prediction_time_range: isHistoricalData ? "" : "0",
      prediction_time_range_type: isHistoricalData ? "" : "DAY",
      historical_time_range: isHistoricalData ? "0" : "",
      historical_time_range_type: isHistoricalData ? "DAY" : "",
      historical_start_time: (value?.startTime || moment())
        ?.set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      historical_end_time: (value?.endTime || moment())
        ?.set("hour", 23)
        .set("minute", 59)
        .set("second", 59),
    };
    onEditDashboard(type)(id, prepareOutput(values));
    setShowFilter(false);
  };

  const toggleFullScreen = () => setFullScreen(!fullScreen);

  useEffect(() => {
    const chartDom = document.querySelector(`.echart-${dashboardChartId}`);
    const chart = getInstanceByDom(chartDom);
    let series = chart?.getOption()?.series;
    let realTimeSeries = [];

    if (
      realTimeData?.eventTime &&
      realTimeData?.paramId &&
      listParameterId[realTimeData?.paramId] &&
      realTimeData?.value &&
      series
    ) {
      let existRealTimeValueParameter = false;
      realTimeSeries = series?.map((serie) => {
        if (serie.id === realTimeData?.paramId) {
          serie.data?.push([realTimeData.eventTime, realTimeData.value]);
          existRealTimeValueParameter = true;
        }
        return serie;
      });

      if (existRealTimeValueParameter) {
        chart.setOption({
          series: realTimeSeries,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeData?.eventTime, realTimeData?.paramId]);

  return (
    <div className="standard-chart-card dashboard-item-card">
      <Box
        className="standard-chart-card-title"
        style={{
          background: showHeader ? headerColor : "transparent",
          color: invertColor(headerColor),
        }}
      >
        {showHeader && (
          <Box display="flex" alignItems="center">
            <EqualizerIcon />
            &nbsp;&nbsp;{name}
          </Box>
        )}
        <StandardChartToolbar
          standardChartData={data}
          isPublic={isPublic}
          showHeader={showHeader}
          toggleFilter={toggleFilter}
          toggleFullScreen={toggleFullScreen}
        />
      </Box>

      <PopoverFilterDate
        standardChartData={data}
        language={language}
        showFilter={showFilter}
        historicalTimeRange={historicalTimeRange}
        handleShowFilter={setShowFilter}
        onSelectRange={onSelectRange}
      />

      <DateRangeModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        onSubmit={onSubmitCustomTimeRange}
        maxDate={new Date()}
      />

      <StandardChart
        isChartLoading={isChartLoading}
        chartConfig={chartConfig}
        dashboardChartId={dashboardChartId}
        // isRealTimeLoading={isRealTimeLoading}
      />
      <DashboardCardButtons
        visible={!isView}
        onEdit={handleEdit}
        onDelete={handleRemove}
      />
      <Modal
        open={fullScreen}
        onClose={toggleFullScreen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            background: "#fff",
            width: "70vw",
            height: "70vh",
            margin: "50px auto 0 auto",
            padding: "30px 0px 30px 0px",
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <span style={{ cursor: "pointer" }} onClick={toggleFullScreen}>
              <CloseIcon />
            </span>
            <div style={{ padding: "15px" }} />
          </Box>

          {isChartLoading ? (
            <LoadingChartData />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <StandardChart
                chartConfig={chartConfig}
                dashboardChartId={dashboardChartId}
                // isRealTimeLoading={isRealTimeLoading}
              />
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default StandardChartCardV2;
