import { Box, CircularProgress } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { DATE_TIME_FORMAT } from "app/constant/date-time-format";
import FitText from "app/shared/components//FitText";
import { NON_TABLE_ALARM_COLOR } from "app/shared/constants/dashboard";
import { useLanguage } from "app/shared/hooks";
import { formatString } from "app/utils/string-formatter";
import { invertColor } from "app/utils/utils";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";

const ValueCard = (props) => {
  const [language] = useLanguage();
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.ITEM.VALUE_NEW" />
  );
  const mainColor =
    (props["data"] && props["data"]["dashboard_value"]["main_color"]) || "#fff";

  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const dataAlarm = props.data?.dashboard_value?.parameter?.data_alarm || [];
  const [backgroundColor, setBackgroundColor] = useState();

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(
        props.isPublic ? props.data?.public_id : props.data?.id
      );

      const parameter = dashboardData?.parameterDatas?.at(0); // Value card have only 1 parameter.

      if (parameter?.dataPointDtos?.length > 0) {
        const lastDataPoint = parameter?.dataPointDtos?.at(-1); // get last value
        const preDataPoint = parameter?.dataPointDtos?.at(-2); // previous of last value

        const decrease =
          Number(preDataPoint?.value || Number(lastDataPoint?.value || 0)) -
          Number(lastDataPoint?.value || 0);
        let rateDecrease = (decrease / Number(preDataPoint?.value || 1)) * 100;
        rateDecrease = Number(Number(rateDecrease).toFixed(2));

        const parmeter_name =
          language === "en"
            ? props.data?.dashboard_value?.parameter?.name
            : props.data?.dashboard_value?.parameter?.name_vi;

        const status = {
          name_vi: lastDataPoint?.name_vi,
          name_en: lastDataPoint?.name_en,
        };

        const isAlarm = lastDataPoint?.isAlarm;

        const data = {
          ...props.data.dashboard_value,
          unit: props.data?.dashboard_value?.parameter?.unit,
          name: parmeter_name,
          // value: isNaN(Number(lastDataPoint?.value))
          //   ? 0
          //   : Number(lastDataPoint?.value).toFixed(2),
          value: isNaN(Number(lastDataPoint?.value))
            ? 0
            : props.data?.dashboard_value?.parameter?.parameter_format_text
            ? formatString(
                props.data?.dashboard_value?.parameter?.parameter_format_text,
                lastDataPoint?.value
              )
            : Number(lastDataPoint?.value).toFixed(2),
          status,
          increasing: decrease <= 0,
          increasingRate: isNaN(Number(rateDecrease))
            ? 0
            : Math.abs(rateDecrease),
          alarming: isAlarm,
          date: moment(lastDataPoint?.eventTime).format(DATE_TIME_FORMAT),
        };
        if (isAlarm) {
          dataAlarm?.forEach((alarm) => {
            if (alarm.id === lastDataPoint?.alarmId) {
              setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
            }
          });
        } else setBackgroundColor();
        setData(data);
      } else {
        setData({});
      }
    };

    if (props.data && props.data.dashboard_value) {
      initData();
    }
  }, [props["data"]]);

  // Data
  useEffect(() => {
    if (
      data?.parameter_id &&
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId
    ) {
      if (data?.parameter_id === props.realTimeData?.paramId) {
        const lastDataPoint = props.realTimeData?.value; // get last value
        const preDataPoint = data.value; // previous of last value

        const decrease =
          Number(preDataPoint || Number(lastDataPoint || 0)) -
          Number(lastDataPoint || 0);
        let rateDecrease = (decrease / Number(preDataPoint || 1)) * 100;
        rateDecrease = Number(Number(rateDecrease).toFixed(2));

        const newData = {
          ...data,
          // value: isNaN(Number(lastDataPoint))
          //   ? 0
          //   : Number(lastDataPoint).toFixed(2),
          value: isNaN(Number(lastDataPoint))
            ? 0
            : props.data?.dashboard_value?.parameter?.parameter_format_text
            ? formatString(
                props.data?.dashboard_value?.parameter?.parameter_format_text,
                lastDataPoint
              )
            : Number(lastDataPoint).toFixed(2),
          increasing: decrease <= 0,
          increasingRate: isNaN(Number(rateDecrease))
            ? 0
            : Math.abs(rateDecrease),
          alarming: false,
          status: "",
          date: moment(props.realTimeData?.eventTime).format(DATE_TIME_FORMAT),
        };
        setData(newData);
      }
    }
  }, [
    props.realTimeData?.eventTime,
    props.realTimeData?.paramId,
    data?.parameter_id,
  ]);

  // Alarm
  useEffect(() => {
    if (
      data?.parameter_id &&
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId
    ) {
      if (data?.parameter_id === props.realTimeDataAlarm?.paramId) {
        const lastDataPoint = props.realTimeDataAlarm?.value; // get last value
        const preDataPoint = data.value; // previous of last value

        const decrease =
          Number(preDataPoint || Number(lastDataPoint || 0)) -
          Number(lastDataPoint || 0);
        let rateDecrease = (decrease / Number(preDataPoint || 1)) * 100;
        rateDecrease = Number(Number(rateDecrease).toFixed(2));

        const isAlarm =
          props.realTimeDataAlarm?.name_en !== undefined &&
          props.realTimeDataAlarm?.name_vi !== undefined;

        const newData = {
          ...data,
          // value: isNaN(Number(lastDataPoint))
          //   ? 0
          //   : Number(lastDataPoint).toFixed(2),
          value: isNaN(Number(lastDataPoint))
            ? 0
            : props.data?.dashboard_value?.parameter?.parameter_format_text
            ? formatString(
                props.data?.dashboard_value?.parameter?.parameter_format_text,
                lastDataPoint
              )
            : Number(lastDataPoint).toFixed(2),
          increasing: decrease <= 0,
          increasingRate: Math.abs(rateDecrease),
          alarming: isAlarm,
          status: {
            name_en: props.realTimeDataAlarm?.name_en,
            name_vi: props.realTimeDataAlarm?.name_vi,
          },
          date: moment(props.realTimeDataAlarm?.eventTime).format(
            DATE_TIME_FORMAT
          ),
        };
        if (isAlarm) {
          dataAlarm?.forEach((alarm) => {
            if (alarm.id === props.realTimeDataAlarm?.alarm_id) {
              setBackgroundColor(alarm?.alarm_color || NON_TABLE_ALARM_COLOR);
            }
          });
        }
        setData(newData);
      }
    }
  }, [
    props.realTimeDataAlarm?.eventTime,
    props.realTimeDataAlarm?.paramId,
    data?.parameter_id,
  ]);

  return (
    <div
      className="value-card dashboard-item-card"
      style={{
        background: backgroundColor ?? mainColor,
        color: invertColor(backgroundColor ?? mainColor, true),
      }}
    >
      {showHeader && (
        <p
          className="value-card-title"
          style={{
            background: headerColor,
            color: invertColor(headerColor, true),
          }}
        >
          <TrendingUpIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}
      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div className="value-card-body">
          <div className="value-card-content">
            {data?.parameter_id ? (
              <>
                <Box>
                  <p className="value-card-parameter-name">
                    <FitText minFontSize={14} compressor={2.5} maxFontSize={20}>
                      {data?.name}
                    </FitText>
                  </p>
                </Box>
                <Box mb={0.5}>
                  <p className="value-card-value">
                    <FitText minFontSize={16} compressor={1.5} maxFontSize={20}>
                      {data?.value}
                    </FitText>
                  </p>
                </Box>
                <Box mb={1}>
                  <FitText minFontSize={12} compressor={2.5} maxFontSize={16}>
                    <p className="value-card-unit">{`(${data?.unit})`}</p>
                  </FitText>
                </Box>
                <Box mb={1}>
                  <p className="value-card-status">
                    <FitText minFontSize={13} compressor={2.5} maxFontSize={18}>
                      {data?.status[`name_${language}`]}
                    </FitText>
                  </p>
                </Box>
                <Box mb={1}>
                  <div style={{ display: "flex" }}>
                    <p className="status-value">
                      <FitText
                        minFontSize={13}
                        compressor={2.5}
                        maxFontSize={18}
                      >
                        {data?.increasing ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                        {data?.increasingRate}%
                      </FitText>
                    </p>
                  </div>
                </Box>
                <p
                  className="mt-6 value-card-date"
                  style={{
                    color: invertColor(backgroundColor ?? mainColor, true),
                  }}
                >
                  <FitText minFontSize={10} compressor={2.5} maxFontSize={14}>
                    <FormattedMessage id="CLIENT.DASHBOARD.LAST_RECORDED_TIME" />
                    : {data?.date}
                  </FitText>
                </p>
              </>
            ) : (
              <div className="no-data-area">
                <p className="no-data-label">
                  <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default memo(ValueCard);
