import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Box } from "@material-ui/core";
import { useIntl } from "react-intl";

export default function DatePicker(props) {
  const {
    placeholder,
    required,
    nowrap,
    label,
    name,
    value,
    onChange,
    errorMsg,
    disabled,
    className,
    disableFuture = true,
    minDate,
    minDateError,
    currentError,
    setFieldError,
  } = props;
  const intl = useIntl();
  const handleDateChange = (date) => {
    onChange(name, date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box
        className="form-label"
        style={nowrap ? { whiteSpace: "nowrap" } : null}
      >
        {required && <p className="required">*</p>}
        {label}
      </Box>

      <KeyboardDatePicker
        className={className}
        disableToolbar
        variant="inline"
        fullWidth
        format="dd/MM/yyyy"
        placeholder={placeholder}
        id="date-picker-inline"
        helperText={currentError}
        error={currentError ? true : false}
        value={value || null}
        onChange={handleDateChange}
        inputVariant="outlined"
        PopoverProps={{
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { horizontal: "left", vertical: "top" },
        }}
        maxDateMessage={
          disableFuture &&
          intl.formatMessage({
            id: "CLIENT.GENERAL.INVALID_DATE",
          })
        }
        invalidDateMessage={intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_DATE_FORMAT",
        })}
        disableFuture={disableFuture}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        disabled={disabled}
        minDate={minDate}
        minDateMessage={minDateError}
        onError={(error) => {
          // handle as a side effect
          if (setFieldError) {
            if (!error) return;
            if (error !== currentError) {
              setFieldError(name, error);
            }
          }
        }}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );
}
