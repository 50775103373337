import paramtersApi from "app/api/parametersApi";
import { useState } from "react";

export default function useDataAlarm() {
  const [isLoadingDataAlarm, setIsLoadingDataAlarm] = useState(false);

  const getDataAlarm = async (id) => {
    try {
      setIsLoadingDataAlarm(true);

      let res;
      res = await paramtersApi.getAllDataAlarmByParameter(id);
      if (res.success && res?.data?.length > 0) {
        return res.data || [];
      }
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoadingDataAlarm(false);
    }
  };

  return { isLoadingDataAlarm, getDataAlarm };
}
