import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 44 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M13.316 11.716c1.655-3 4.977-5.05 8.802-5.05 4.817 0 8.828 3.238 9.728 7.55 3.44.226 6.154 2.9 6.154 6.2 0 3.45-2.965 6.25-6.618 6.25H14.176c-4.38 0-7.94-3.362-7.94-7.5 0-3.862 3.096-7.05 7.08-7.45zm15.935 2.988c-.675-3.213-3.666-5.538-7.133-5.538-2.727 0-5.188 1.425-6.446 3.713l-.662 1.188-1.416.137c-2.687.262-4.712 2.4-4.712 4.962 0 2.763 2.37 5 5.295 5h17.205c2.184 0 3.971-1.687 3.971-3.75 0-1.937-1.615-3.575-3.68-3.7l-2.024-.137-.398-1.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillOpacity="1"
        d="M14.9 34.044c1.047 0 1.9-.912 1.9-2.032 0-1.414-1.653-3.387-1.725-3.47l-.175-.209-.176.208c-.071.084-1.724 2.057-1.724 3.471 0 1.12.852 2.032 1.9 2.032zM22.5 34.044c1.047 0 1.9-.912 1.9-2.032 0-1.414-1.654-3.387-1.725-3.47l-.176-.209-.175.208c-.072.084-1.725 2.057-1.725 3.471 0 1.12.853 2.032 1.9 2.032zM30.1 34.044c1.047 0 1.9-.912 1.9-2.032 0-1.414-1.653-3.387-1.724-3.47l-.176-.209-.176.208c-.071.084-1.724 2.057-1.724 3.471 0 1.12.853 2.032 1.9 2.032z"
      ></path>
    </svg>
  );
}

export default Icon;
