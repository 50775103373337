import { USER_LOG_IN, USER_LOG_OUT, USER_UPDATE, RELOAD_DATA, USER_ORGANIZATION_SET } from '../actions/types';

let initialState = {
  user: null,
  token: null,
  role: null,
  reloadData: false,
  userOrganization: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOG_IN: {
      const { user, token, role } = action.payload;
      return { user, token, role };
    }

    case USER_LOG_OUT: {
      localStorage.setItem("language_changed", false)
      const newState = {};
      return newState;
    }

    case USER_UPDATE: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }

    case RELOAD_DATA: {
      return {
        ...state,
        reloadData: !state.reloadData 
      };
    }

    case USER_ORGANIZATION_SET: {
      const { userOrganization } = action.payload;
      return {
        ...state,
        userOrganization
      }
    }

    default: {
      return state;
    }
  }
}
export default userReducer;