import axiosClient from "./axiosClient";

const userGuide = {
  getAllCategory() {
    const url = "/api/v1/user-guide/category";
    return axiosClient.get(url, {});
  },
  addUserGuideCategory(token, data) {
    const url = "/api/v1/user-guide/category";
    return axiosClient.post(url, data, { headers: { "Authorization": `Bearer ${token}` } })
  },
  updateUserGuideCategory(id, data, token) {
    const url = `/api/v1/user-guide/category/${id}`;
    return axiosClient.put(url, data, { headers: { "Authorization": `Bearer ${token}` } })
  },
  addUserGuideByCategoryId(token, data, categoryId) {
    const url = `/api/v1/user-guide/${categoryId}`;
    return axiosClient.post(url, data, { headers: { "Authorization": `Bearer ${token}` } })
  },
  updateUserGuide(id, data, token) {
    const url = `/api/v1/user-guide/${id}`;
    return axiosClient.put(url, data, { headers: { "Authorization": `Bearer ${token}` } })
  },
  getUserGuide(id) {
    const url = `/api/v1/user-guide/${id}`;
    return axiosClient.get(url, {});
  },
  deleteUserGuide(id) {
    const url = `/api/v1/user-guide/${id}`;
    return axiosClient.delete(url, {});
  },
  deleteUserGuideCategory(id) {
    const url = `/api/v1/user-guide/category/${id}`;
    return axiosClient.delete(url, {});
  },
  getAllUserGuidePost(params) {
    const url = "/api/v1/user-guide-post/get-list-user-guide-post";
    return axiosClient.get(url, { params })
  },
  getUserGuidePostById(id) {
    const url = `/api/v1/user-guide-post/get-user-guide-post-detail?id=${id}`;
    return axiosClient.get(url, {})
  },
  createUserGuidePost(data) {
    const url = `/api/v1/user-guide-post/create-user-guide-post`;
    return axiosClient.post(url, data, {})
  },
  updateUserGuidePost(data) {
    const url = "/api/v1/user-guide-post/edit-user-guide-post";
    return axiosClient.put(url, data, { headers: { "Content-Type": "multipart/form-data" } })
  },
  reOrderUserGuidePost(data) {
    const url = "/api/v1/user-guide-post/reorder-user-guide-post";
    return axiosClient.put(url, data, { headers: { "Content-Type": "multipart/form-data" } })
  },
  deleteUserGuidePost(id) {
    const url = `/api/v1/user-guide-post/delete-user-guide-post?id=${id}`;
    return axiosClient.delete(url, {});
  },
  deleteUserGuideGroupPost(id) {
    const url = `/api/v1/user-guide-post/delete-user-guide-post-group?id=${id}`;
    return axiosClient.delete(url, {});
  },
  moveUserGuideGroupUp(id) {
    const url = `/api/v1/user-guide-post/reorder-user-guide-group-post-up`;
    return axiosClient.put(url, { id }, {});
  },
  moveUserGuideGroupDown(id) {
    const url = `/api/v1/user-guide-post/reorder-user-guide-group-post-down`;
    return axiosClient.put(url, { id }, {});
  },
  moveUserGuideGroupToFolder(data) {
    const url = `/api/v1/user-guide-post/move-user-guide-group-post`;
    return axiosClient.put(url, data, {});
  }
}

export default userGuide;
