import React from "react";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { Box, Grid } from "@material-ui/core";
import ImageUploader from "app/shared/components/Dashboard/ImageUploader";
import { FormattedMessage } from "react-intl";
import SharedInputField from "app/shared/components/SharedInputField";
import {
  IMAGE_FORMATS,
  SCAN_TIMES,
  UPLOAD_TYPE,
  UPLOAD_TYPES,
} from "../contants";

const AttachmentImageForm = ({
  formik,
  intl,
  language,
  image,
  imageFile,
  setImageFile,
  setImage,
}) => {
  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>
        <ConfigureArea
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.IMAGE",
          })}
        >
          <Box mb={3}>
            <SharedSelectField
              className="bg-white"
              label={intl.formatMessage({
                id: "CLIENT.DASHBOARD.UPLOAD_TYPE_LABEL",
              })}
              disableClearable
              name="upload_type"
              required
              options={UPLOAD_TYPES?.map((item) => ({
                id: item.id,
                value: item.value,
                label: item[`name_${language}`],
              }))}
              defaultValue={formik.values["upload_type"]}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Box>

          {formik.values.upload_type === UPLOAD_TYPE.STATIC_IMAGE && (
            <div className="image-uploader">
              <Box className="form-label">
                <span className="required">*</span>
                <FormattedMessage id="CLIENT.DASHBOARD.IMAGE_FILE_LABEL" />
              </Box>

              <ImageUploader
                error={formik.errors["image"]}
                imageFile={imageFile}
                image={image}
                onChange={(imageData) => {
                  setImageFile(imageData["imageFile"]);
                  setImage(imageData["image"]);
                }}
              />
            </div>
          )}

          {formik.values.upload_type === UPLOAD_TYPE.RTSP && (
            <Box mb={3}>
              <SharedInputField
                className="bg-white"
                name="link"
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.LINK_LABEL",
                })}
                type="text"
                placeholder={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.LINK_HINT",
                })}
                {...formik.getFieldProps("link")}
                disabled={formik.isSubmitting}
                required
                errorMsg={formik.touched["link"] && formik.errors["link"]}
              />
            </Box>
          )}

          {formik.values.upload_type === UPLOAD_TYPE.DYNAMIC_IMAGE && (
            <>
              <Box mb={3}>
                <SharedInputField
                  className="bg-white"
                  name="link"
                  label={intl.formatMessage({
                    id: "CLIENT.DASHBOARD.LINK_LABEL",
                  })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "CLIENT.DASHBOARD.LINK_HINT",
                  })}
                  {...formik.getFieldProps("link")}
                  disabled={formik.isSubmitting}
                  required
                  errorMsg={formik.touched["link"] && formik.errors["link"]}
                />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box mb={3}>
                    <SharedSelectField
                      className="bg-white"
                      label={intl.formatMessage({
                        id: "CLIENT.DASHBOARD.IMAGE_FORMAT_LABEL",
                      })}
                      disableClearable
                      name="image_format"
                      required
                      options={IMAGE_FORMATS?.map((item) => ({
                        id: item.id,
                        value: item.value,
                        label: item.label,
                      }))}
                      defaultValue={formik.values["image_format"]}
                      onChange={formik.setFieldValue}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mb={3}>
                    <SharedSelectField
                      className="bg-white"
                      label={intl.formatMessage({
                        id: "CLIENT.DASHBOARD.SCAN_TIME_LABEL",
                      })}
                      disableClearable
                      name="scan_time"
                      required
                      options={SCAN_TIMES?.map((item) => ({
                        id: item.id,
                        value: item.value,
                        label: item.label,
                      }))}
                      defaultValue={formik.values["scan_time"]}
                      onChange={formik.setFieldValue}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </ConfigureArea>
      </form>
    </div>
  );
};

export default AttachmentImageForm;
