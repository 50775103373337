import EditIcon from "@material-ui/icons/Edit";
import { FormattedMessage } from "react-intl";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useLanguage } from "../app/shared/hooks";
import Chip from '@material-ui/core/Chip';
import moment from "moment";
import { DATE_FORMAT } from "../app/constant/date-time-format";

const getValueClass = (value) => {
  if (["submitted", "đã gửi"].includes(value.trim().toLowerCase())) return (
    <Chip
      size="small"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "#993300"
      }}
    />
  );
  if (["in review", "đang xem xét"].includes(value.trim().toLowerCase())) return (
    <Chip
      size="small"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "#cc33cc"
      }}
    />
  );
  return (
    <Chip
      size="small"
      color="primary"
      label={value}
      style={{
        color: "#fff",
        backgroundColor: "#663399"
      }}
    />
  );
}

const DataFilesContent = () => {
  const [language] = useLanguage();
  return {
    columns: [
      {
        field: "data_sharing_request.title",
        headerName: (
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_TITLE" />
        ),
        width: 150,
        align: "left",
        sortable: true,
      },
      {
        field: "submitted_date",
        headerName: (
          <FormattedMessage id="CLIENT.DATA_FILES.TABLE_HEAD_DATE" />
        ),
        width: 150,
        align: "left",
        sortable: true,
        renderCell: (params) => <>{params ? moment(params, 'YYYY-MM-DD').format(DATE_FORMAT) : ""}</>
      },
      {
        field: `status.name_${language}`,
        headerName: (
          <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST.TABLE_HEAD_STATUS" />
        ),
        width: 150,
        align: "left",
        sortable: false,
        renderCell: (params) => getValueClass(params)
      }
    ],
    rows: [
      {
        field: "data_sharing_request.title",
        type: "text",
        default: "",
      },
      {
        field: "submitted_date",
        type: "text",
        default: "",
      },
      {
        field: `status.name_${language}`,
        type: "text",
        default: "",
      },
    ],
    actions: {
      edit: <EditIcon style={{ fontSize: "20px", marginRight: "5px" }} />,
      view: <VisibilityIcon style={{ fontSize: "20px", marginRight: "5px" }} />,
    },
  };
};
export default DataFilesContent;
