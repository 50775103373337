import { Box } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { TEXT_CONTENT_TYPES } from "../contants";

const StaticTextForm = ({
  intl,
  formik,
  language
}) => {
  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.HEADER_LABEL',
          })}>

          <HeaderConfigurator formik={formik} />
        </ConfigureArea>
        <ConfigureArea
          title={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.CONTENT_LABEL',
          })}>
          <Box mb={3}>
            <SharedSelectField
              className="bg-white"
              label={intl.formatMessage({ id: "CLIENT.DASHBOARD.CONTENT_TYPE_LABEL" })}
              disableClearable
              name="content_type"
              required
              options={TEXT_CONTENT_TYPES?.map(item => ({
                id: item.value,
                value: item.value,
                label: item[`name_${language}`]
              }))}
              defaultValue={formik.values["content_type"]}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Box>
          <Box mb={3}>
            <SharedInputField
              className="bg-white"
              name="content"
              label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CONTENT_LABEL' })}
              type="text"
              placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.CONTENT_HINT' })}
              {...formik.getFieldProps("content")}
              disabled={formik.isSubmitting}
              multiline
              rows={5}
              required
              errorMsg={formik.touched["content"] && formik.errors["content"]}
            />
          </Box>
        </ConfigureArea>
      </form>
    </div>
  )
}

StaticTextForm.propTypes = {
};

export default StaticTextForm