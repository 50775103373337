import React from "react";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { FormattedMessage } from "react-intl";

const SelectionStationField = ({
  stations,
  language,
  formik }) => {
  return (
    <>
      <SharedSelectField
        required
        className="bg-white"
        label={<FormattedMessage id='CLIENT.TICKETS_MANAGEMENT.STATION_LABEL' />}
        disableClearable
        name="station_id"
        options={[{
          id: "",
          name: "-- Select Station --",
          name_vi: "-- Chọn Trạm --",
        }].concat((stations || [])).map(item => ({
          id: item.id,
          value: item.id,
          label: language === 'en' ? item.name : item.name_vi,
          disabled: item.id === ""
        }))}
        defaultValue={formik.values["station_id"]}
        onChange={formik.setFieldValue}
        errorMsg={formik.touched["station_id"] && formik.errors["station_id"]}
      />
    </>
  )
}

export default SelectionStationField