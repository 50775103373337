import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M21 38.333h-1.75v-10.95a10.732 10.732 0 01-7 2.617c-5.688 0-10.5-4.584-10.5-10v-1.667h11.498C11.55 16.55 10.5 14.2 10.5 11.667c0-5.417 4.813-10 10.5-10h1.75v10.95a10.732 10.732 0 017-2.617c5.688 0 10.5 4.583 10.5 10v1.666H28.753C30.45 23.45 31.5 25.8 31.5 28.334c0 5.417-4.813 10-10.5 10zm1.75-3.55c2.975-.767 5.25-3.4 5.25-6.45 0-3.05-2.275-5.683-5.25-6.45v12.9zm-10.5-8.117c-3.203 0-5.968-2.166-6.772-5h13.545c-.805 2.834-3.57 5-6.773 5zm24.273-8.333c-.806-2.833-3.588-5-6.773-5-3.185 0-5.968 2.167-6.773 5h13.546zM14 11.667c0-3.034 2.275-5.684 5.25-6.45v12.9C16.275 17.35 14 14.7 14 11.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
