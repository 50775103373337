import { lazy } from "react";
import Loadable from "app/shared/components/Loader/Loadable";

export const ftpShareRoutes = [
  {
    path: "/station/edit/:id/ftpshare/add",
    component: Loadable(lazy(() => import("./pages/ftp-share-add.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/ftpshare/edit/:connectionId",
    component: Loadable(lazy(() => import("./pages/ftp-share-edit.page"))),
    permissionId: "stations",
    action: "edit",
  },
  {
    path: "/station/edit/:id/ftpshare/history/:connectionId",
    component: Loadable(lazy(() => import("./pages/ftp-share-history.page"))),
    permissionId: "stations",
    action: "edit",
  },
];
