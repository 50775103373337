import { TRIGGER_SIDEBAR } from "../actions/types";

const initialState = {
  openSidebar: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_SIDEBAR: {
      return { openSidebar: !state.openSidebar };
    }

    default: {
      return state;
    }
  }
};
export default sidebarReducer;
