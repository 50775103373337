import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../../constant/date-time-format";
import { groupBy, lowerCase, reverse } from "lodash";

const getNextTime = (eventTime, interval) => {
  if (interval == null) return null;
  const time = +interval?.split("_")[1];
  const type = lowerCase(interval?.split("_")[0]) != "min" ? `${lowerCase(interval.split("_")[0])}s` : 'minutes';
  const nextTime = moment(eventTime,DATE_TIME_FORMAT).add(time, type);
  return nextTime;
}

const adjustFunction = (value) => {
  switch (lowerCase(value)) {
    case "avg":
      return "mean";
    case "raw":
      return "value";
    default:
      return lowerCase(value);
  }
}

const getListKeysSorted = (dataObject) => {
  return Object.keys(dataObject).sort((a, b) => 
    moment(b, DATE_TIME_FORMAT).diff(moment(a, DATE_TIME_FORMAT))
  );
  // return Object.keys(dataObject);
};

const prepareDataObject = (parameterDatas, language) => {
  const totalParameters = parameterDatas?.length;

  let allParameterDatas = [];

  for (let paramIndex = 0; paramIndex < totalParameters; paramIndex++) {
    const reverseDataPointDtos = reverse(
      parameterDatas?.[paramIndex]?.dataPointDtos
    );
    for (
      let itemIndex = 0;
      itemIndex < reverseDataPointDtos?.length;
      itemIndex++
    ) {
      allParameterDatas.push({
        ...reverseDataPointDtos?.[itemIndex],
        status: reverseDataPointDtos?.[itemIndex]?.[`name_${language}`],
        parameterId: parameterDatas?.[paramIndex]?.parameterDto?.parameterId,
        eventTime: moment(reverseDataPointDtos?.[itemIndex]?.eventTime).format(DATE_TIME_FORMAT),
      });
    }
  }

  const groupByEventTimeResult = groupBy(
    allParameterDatas,
    (result) => result.eventTime
  );
  return groupByEventTimeResult;
};

const checkInTimeRange = (eventTime, startDate, endDate) => {
  const eventTimeMoment = moment(eventTime);
  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate) ? moment(endDate) : undefined;
  return eventTimeMoment.isBetween(startDateMoment, endDateMoment);
};

export {
  getNextTime,
  adjustFunction,
  getListKeysSorted,
  prepareDataObject,
  checkInTimeRange
}