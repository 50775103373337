import { Box, CircularProgress } from "@material-ui/core";
import { bool } from "prop-types";
import React from "react";

const PageLoading = (props) => {
  const { loading, children } = props;
  if (loading) return (
    <Box display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="50vh" >
      <CircularProgress />
    </Box>
  )

  return children || <></>;
}

PageLoading.propTypes = {
  loading: bool,
}

export default PageLoading