import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './styles.css';
import { useLanguage } from '../../../../shared/hooks';
import { FormattedMessage } from 'react-intl';

const getName = (name, number) => {
  switch (name) {
    case "Second(s)": {
      if (number < 2) {
        return "Second"
      } else {
        return "Seconds"
      }
    }
    case "Minute(s)": {
      if (number < 2) {
        return "Minute"
      } else {
        return "Minutes"
      }
    }
    case "Hour(s)": {
      if (number < 2) {
        return "Hour"
      } else {
        return "Hours"
      }
    }
    case "Day(s)": {
      if (number < 2) {
        return "Day"
      } else {
        return "Days"
      }
    }
    case "Month(s)": {
      if (number < 2) {
        return "Month"
      } else {
        return "Months"
      }
    }
    default: return name
  }
}

const IndexTable = (props) => {
  const [language] = useLanguage();
  const handleEdit = (itemId) => {
    const {
      [itemId]: { id },
    } = props['data'];

    props['handleEdit'](id);
  };

  const handleRemove = (itemId) => {
    const {
      [itemId]: { id },
    } = props['data'];

    props['handleRemove'](id);
  };

  const getTimeType = (type, number) => {
    if (props["timeTypes"].find(elm => elm.id.toString() === type.toString())) {
      return getName(props["timeTypes"].find(elm => elm.id.toString() === type.toString())[`name_${language}`], number)
    }
    return ""
  }

  return (
    <div className="index-table">
      <table>
        <thead>
          <tr>
            <th colSpan={4}>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDEX_TABLE" />
            </th>
            <th colSpan={2}>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_TABLE" />
            </th>
            <th rowSpan={2}>
              <FormattedMessage id="GENERAL.ACTIONS" />
            </th>
          </tr>

          <tr>
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.CATEGORIES_LABEL" />
            </td>
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.INDICATOR_LABEL" />
            </td>
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.FREQUENCY_LABEL" />
            </td>
            {/* <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.UNIT_LABEL" />
            </td> */}
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.METHOD_LABEL" />
            </td>
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_NAME_LABEL" />
            </td>
            <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_MODERN_LABEL" />
            </td>
            {/* <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_ORIGIN_LABEL" />
            </td> */}
            {/* <td>
              <FormattedMessage id="CLIENT.DATA_SHARING_REQUEST_ACTIONS.DEVICE_PROVIDER_LABEL" />
            </td> */}
          </tr>
        </thead>

        <tbody>
          {Object.keys(props['data']).reverse().map((item) => (
            <tr key={item.id}>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['category'] &&
                  props['data'][item]['category'] &&
                  props["categories"].find(elm => elm.id === props['data'][item]['category']) &&
                  props["categories"].find(elm => elm.id === props['data'][item]['category'])[`name_${language}`]}
              </td>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['indicator']}
              </td>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  `${props['data'][item]['frequency']} ${getTimeType(props['data'][item]['time_type'], props['data'][item]['frequency'])}`}
              </td>
              {/* <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['unit']}
              </td> */}
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['method'] &&
                  props["methods"].find(elm => elm.id === props['data'][item]['method']) &&
                  props["methods"].find(elm => elm.id === props['data'][item]['method'])[`name_${language}`]}
              </td>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['name']}
              </td>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['modern']}
              </td>
              {/* <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['origin']}
              </td>
              <td>
                {props['data'] &&
                  props['data'][item] &&
                  props['data'][item]['provider']}
              </td> */}

              {props['showActions'] ? (
                <td>
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center" }}
                  >
                    <span
                      className="index-table-button"
                      onClick={() => handleEdit(item)}
                    >
                      <EditIcon />
                    </span>

                    <span
                      className="index-table-button"
                      onClick={() => handleRemove(item)}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </td>
              ) :
                <td>
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center" }}
                  >
                    <span
                      className="index-table-button"
                      onClick={() => handleEdit(item)}
                    >
                      <VisibilityIcon />
                    </span>
                  </div>
                </td>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IndexTable;
