import { Button, FormHelperText, LinearProgress } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import swal from "sweetalert";
import mapApi from "app/api/mapApi";
import { shallowEqual, useSelector } from "react-redux";

export const LayerUploader = (props) => {
  const {
    required,
    label,
    errorMsg,
    accept = "*",
    multiple = false,
    onChange,
    fileName,
    placeholder,
  } = props;

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const fileInputEl = useRef();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const onChangeFiles = (e) => {
    var re = /(?:\.([^.]+))?$/;
    const file = e.target.files[0];
    const fileName = file?.name;
    const fileExtension = re.exec(fileName)[0];
    if (accept.includes(fileExtension) === false) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.MAP.LAYER_FILE_ERROR_TITLE",
        }),
        text: `${intl.formatMessage({
          id: "CLIENT.MAP.LAYER_FILE_ERROR_MESSAGE",
        })} (${fileExtension})`,
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    } else {
      uploadFile(file);
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
    setProgress(0);
  };

  const uploadFile = async (file) => {
    const form = new FormData();
    form.append("mapLayer", file);
    enableLoading();
    try {
      const res = await mapApi.uploadLoadFileLayer(form, token, (percent) => {
        setProgress(percent);
      });
      if (res.success) {
        onChange(res.data);
      }
    } catch (error) {
      swal({
        title: intl.formatMessage({
          id: "CLIENT.MAP.LAYER_FILE_ERROR_TITLE",
        }),
        text: intl.formatMessage({
          id: "CLIENT.MAP.LAYER_FILE_ERROR_500_MESSAGE",
        }),
        icon: "error",
        button: intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      });
    } finally {
      setTimeout(disableLoading, 500);
    }
  };

  return (
    <div>
      <div className="form-label">
        {required && <p className="required">*</p>}
        {label}
      </div>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        style={{ display: "none" }}
        ref={fileInputEl}
        onChange={onChangeFiles}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <div className={`layer-uploader ${!!errorMsg && "error"}`}>
        <Button
          className="custom-button light-button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            fileInputEl.current.click();
          }}
          disabled={loading}
        >
          <FormattedMessage id="CLIENT.GENERAL.CHOOSE_FILE" />
        </Button>
        {loading ? (
          <LinearProgress style={{ flex: 1, marginLeft: 10 }} />
        ) : (
          <span className="file-name">{fileName || placeholder}</span>
        )}
      </div>
      {errorMsg && (
        <FormHelperText className="error-message">{errorMsg}</FormHelperText>
      )}
    </div>
  );
};

LayerUploader.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  accept: PropTypes.any,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  fileName: PropTypes.string,
};
