import PropTypes from "prop-types"
import Footer from "./Footer";
import Header from "./Header";
import "./index.css";

const AuthLayout = (props) => {
  const {
    children,
    title,
    description
  } = props;

  return (
    <div className="auth-layout">
      <Header />
      <div className="background">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      <div className="content">
        { children && children}
      </div>

      <Footer />
    </div>
  );
};

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string,
  description: PropTypes.string 
}

AuthLayout.defaultProps = {
  title: "",
  description: ""
}

