import React, { useEffect } from "react";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import DashboardHistoricTable from "app/shared/components/Dashboard/DashboardHistoricTable";
import { HISTORICAL_DATA, STATISTIC_TABLE, TIME_RANGE_TYPE } from "../contants";
import SharedSelectField from "app/shared/components/SharedSelectField";
import moment from "moment";

const {
  FUNCTIONS,
  INTERVALS,
} = STATISTIC_TABLE;

const HistoricTableForm = ({ formik, intl, parameters, setDisableSave }) => {
  const [intervalList, setIntervalList] = React.useState(INTERVALS);
  const DISPLAY_MODES = [
    {
      label: intl.formatMessage({ id: "CLIENT.DASHBOARD.PORTRAIT" }),
      value: "portrait",
    },
    {
      label: intl.formatMessage({ id: "CLIENT.DASHBOARD.LANDSCAPE" }),
      value: "landscape",
    },
  ];

  const checkValidTimeRange = (formik) => {
    let check = false;
    const intervalValue = INTERVALS.find(item => item.value == formik?.values?.interval);
    if (formik?.values?.aggregate_function !== FUNCTIONS[0].value) {
      setDisableSave(false);
      if (`${formik?.values?.historical_time_range}-${formik?.values?.historical_time_range_type}` == '0-DAY') {
        const timeRange = moment(formik?.values?.historical_end_time).diff(moment(formik?.values?.historical_start_time), intervalValue?.type);
        check = timeRange <= intervalValue?.time;
      } else {
        switch (formik?.values?.historical_time_range_type) {
          case TIME_RANGE_TYPE.DAY:
            if (intervalValue?.type == 'days') {
              check = formik?.values?.historical_time_range <= intervalValue?.time;
            }
            if (intervalValue?.type == 'weeks' || intervalValue?.type == 'months') {
              check = true;
            }
            break;
          case TIME_RANGE_TYPE.WEEK:
            if (intervalValue?.type == 'weeks') {
              check = formik?.values?.historical_time_range <= intervalValue?.time;
            }
            if (intervalValue?.type == 'months') {
              check = true;
            }
            break;
          case TIME_RANGE_TYPE.MONTH:
            if (intervalValue?.type == 'months') {
              check = formik?.values?.historical_time_range <= intervalValue?.time;
            }
            break;
          default:
            break;
        }
      }
    }
    if (check) {
      setDisableSave(true);
      return intl.formatMessage({ id: "CLIENT.DASHBOARD.STATISTIC_TABLE.INVALID_INTERVAL_AND_BASE_TIME" });
    }
    else {
      return null;
    }
  }

  const getIntervalList = (time, type) => {
    let list = [];
    const formikTime = time
    switch (type) {
      case TIME_RANGE_TYPE.DAY:
      case 'days':
        list = INTERVALS.filter(item => (item.type == 'days' && item.time < formikTime) || item.type == 'hours' || (item.type == 'minutes' && formikTime < 3));
        break;
      case TIME_RANGE_TYPE.WEEK:
      case 'weeks':
        list = INTERVALS.filter(item => (item.type == 'weeks' && item.time < formikTime) || item.type == 'days');
        break;
      case TIME_RANGE_TYPE.MONTH:
      case 'months':
        list = INTERVALS.filter(item => ((item.type == 'months' && item.time < formikTime) || item.type == 'weeks' || (item.type == 'days' && formikTime < 3)));
        break;
      case TIME_RANGE_TYPE.YEAR:
      case 'years':
        list = INTERVALS.filter(item => item.type == 'months' || (item.type == 'weeks' && formikTime < 3));
        break;
      default:
        list = INTERVALS;
        break;
    }
    setIntervalList(list);
    formik.setFieldValue("interval", list[0]?.value);
  }

  useEffect(() => {
    if (formik.values?.aggregate_function !== FUNCTIONS[0].value) {
      const intervalString = `${formik.values["historical_time_range"]}-${formik.values["historical_time_range_type"]}`;
      if (intervalString == '0-DAY') {
        const type = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']
        for (const item of type) {
          const timeRange = moment(formik?.values?.historical_end_time).diff(moment(formik?.values?.historical_start_time), item);
          if (timeRange > 0) {
            getIntervalList(timeRange, item);
            break;
          }
        }
      } else {
        getIntervalList(+formik.values["historical_time_range"], formik.values["historical_time_range_type"]);
      }
    }
  }, [formik.values.historical_time_range_type, formik.values.historical_time_range, formik?.values?.historical_start_time, formik?.values?.historical_end_time]);

  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.PARAMETER_SETTING",
          })}
        >
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <div className="form-label">
              <FormattedMessage id="CLIENT.DASHBOARD.DISPLAY_MODE" />
            </div>

            <RadioGroup
              className="radio-group"
              {...formik.getFieldProps("display_mode")}
            >
              {DISPLAY_MODES.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  disabled={formik.isSubmitting}
                  control={<Radio size="small" color="primary" />}
                  label={item?.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <SharedSelectField
            name="aggregate_function"
            style={{ backgroundColor: "#fff" }}
            label={intl.formatMessage({
              id: "CLIENT.DASHBOARD.STATISTIC_TABLE.AGGREGATE_FUNCTION",
            })}
            required={true}
            options={FUNCTIONS.map((item) => ({
              id: item.id,
              value: item.value,
              label: item?.[`name_${intl.locale}`],
            }))}
            defaultValue={formik?.values["aggregate_function"]}
            className="text-normal"
            disableClearable
            onChange={(name, value) => {
              formik.setFieldValue("aggregate_function", value);
              if (value === FUNCTIONS[0].value) {
                formik.setFieldValue("interval", null);
              } else {
                formik.setFieldValue("interval", formik?.values["interval"] || INTERVALS[0].value);
              }
            }}
          />

          <Box mt={3} mb={2}>
            <div className="form-label">
              <FormattedMessage id="CLIENT.DASHBOARD.VALUE_LIST.VALUE" />
            </div>
            <DashboardHistoricTable
              data={formik.values["params"]}
              params_delete={formik.values["params_delete"]}
              intl={intl}
              onChange={(data) => {
                formik.setFieldValue("params", data);
              }}
              onDelete={(data) => {
                formik.setFieldValue("params_delete", data);
              }}
              pickColors={true}
              parameters={parameters || []}
            />
          </Box>

        </ConfigureArea>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.TIME_SETTING",
          })}
        >
          <DataModeConfigurator
            showHistoricTimeRange
            options={[HISTORICAL_DATA]}
            values={{
              historical_time_range: `${formik.values["historical_time_range"]}-${formik.values["historical_time_range_type"]}`,
              prediction_time_range: `${formik.values["prediction_time_range"]}-${formik.values["prediction_time_range_type"]}`,
              historical_start_time: formik.values.historical_start_time,
              historical_end_time: formik.values.historical_end_time,
            }}
            mode={formik.values["data_mode"]}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            error={formik.errors["historical_start_time"]}
            errorMsg={checkValidTimeRange(formik)}
            historicalLabel={intl.formatMessage({
              id: "CLIENT.DASHBOARD.STATISTIC_TABLE.BASE_TIME",
            })}
          />
          {
            (formik.values["aggregate_function"] !== FUNCTIONS[0].value) && (
              <Box
                mt={2}
              >
                <SharedSelectField
                  required={true}
                  name="interval"
                  style={{
                    backgroundColor: "#fff",
                  }}
                  label={intl.formatMessage({
                    id: "CLIENT.DASHBOARD.STATISTIC_TABLE.INTERVAL",
                  })}
                  options={intervalList.map((item) => ({
                    id: item.id,
                    value: item.value,
                    label: item?.[`name_${intl.locale}`],
                  }))}
                  defaultValue={formik?.values["interval"]}
                  className="text-normal"
                  errorMsg={checkValidTimeRange(formik)}
                  disableClearable
                  onChange={(name, value) => {
                    formik.setFieldValue("interval", value);
                  }}
                />
              </Box>
            )
          }
        </ConfigureArea>
      </form>
    </div>
  );
};

export default HistoricTableForm;
