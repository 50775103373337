import React from "react";
import { Box, Grid } from "@material-ui/core";
import SharedInputField from "../../../../shared/components/SharedInputField";

const ConfirmPasswordInput = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Grid item xs={12} md={6}>
      <Box mb={3} ml={2}>
        <SharedInputField
          name="confirmPassword"
          label={intl.formatMessage({
            id: "CLIENT.SIGN_UP.CONFIRM_PASSWORD_LABEL",
          })}
          type="password"
          placeholder={intl.formatMessage({
            id: "CLIENT.SIGN_UP.CONFIRM_PASSWORD_HINT",
          })}
          required
          isPasswordField
          errorMsg={
            formik.touched["confirmPassword"] &&
            formik.errors["confirmPassword"]
          }
          {...formik.getFieldProps("confirmPassword")}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Grid>
  );
};

export default ConfirmPasswordInput;
