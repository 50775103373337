import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import SharedInputField from "../../../../../../shared/components/SharedInputField";
import SharedTable from "../../../../../../shared/components/SharedTable";
import { useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import swal from "sweetalert";
import { MapSecurityGrid } from "./content";
import securityApi from "../../../../../../api/securityApi";
import { useLanguage, usePermissions } from "../../../../../../shared/hooks";
import { EXPIRY_OPTIONS } from "./constant";
import { useFormik } from "formik";
import SharedSelectField from "../../../../../../shared/components/SharedSelectField";
import * as Yup from "yup";
import mapApi from "app/api/mapApi";

const initialValues = {
  search: "",
  expiry_day: "all",
  custom_expiry_day: "",
  map_permission_id: "",
};

const MapsSecutityList = (props) => {
  const { intl, mapId, setSecurityPage } = props;
  const [loading, setLoading] = useState(false);
  const [stationTypes, setStationTypes] = useState({
    data: [],
    total: 0,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortModel, setSortModel] = useState([
    {
      field: "user.fullname",
      sort: "desc",
    },
  ]);
  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );
  const [language] = useLanguage();
  const [permissions, setPermissions] = useState([]);
  const { isAllowed } = usePermissions();

  const schema = Yup.object().shape({
    custom_expiry_day: Yup.number().when("expiry_day", {
      is: (val) => val && val === "custom",
      then: Yup.number()
        .positive(
          intl.formatMessage({
            id: "CLIENT.GENERAL.POSITIVE_NUMBER",
          })
        )
        .integer(
          intl.formatMessage({
            id: "CLIENT.GENERAL.INTERGER_NUMBER",
          })
        ),
    }),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (page === 0) {
        fetchData(values);
      } else {
        setPage(0);
        setPageSize(5);
      }
    },
  });

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const res = await securityApi.getAllStationPermission(token);
        if (res.success) {
          setPermissions(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    fetchData(formik.values);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sortModel]);

  const fetchData = async (values) => {
    try {
      setLoading(true);
      const params = {
        page,
        amount: pageSize,
        expiry:
          values && values.expiry_day === "all"
            ? ""
            : !values.expiry_day
            ? "null"
            : values.expiry_day === "custom"
            ? values.custom_expiry_day || "null"
            : values.expiry_day,
        map_permission_id: values && values.map_permission_id,
        search: values && values.search,
        order: sortModel[0].field || "",
        arrangement: sortModel[0].sort.toUpperCase() || "",
      };
      const res = await mapApi.getAllSecurityByMap(mapId, params, token);
      if (res.success) {
        setStationTypes((prevStationTypes) => ({
          ...prevStationTypes,
          data: res.data,
          total: res && res.count ? res.count : 0,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSortModelChange = (params) => {
    setSortModel([
      {
        field: params.field,
        sort: params.sort,
      },
    ]);
  };

  const onPageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  const onPageChange = (params) => {
    setPage(params.page);
  };

  const onDelete = (item) => {
    swal({
      title: intl.formatMessage({
        id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_WARNING_TITLE",
      }),
      text: intl.formatMessage({
        id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_WARNING_TEXT",
      }),
      icon: "warning",
      // dangerMode: true,
      buttons: [
        intl.formatMessage({
          id: "CLIENT.GENERAL.CANCEL_BUTTON",
        }),
        intl.formatMessage({
          id: "CLIENT.GENERAL.OK_BUTTON",
        }),
      ],
    }).then((yes) => {
      if (yes) {
        const handleDeleteStationType = async () => {
          try {
            const res = await mapApi.deleteMapSecurity(item.id, token);
            if (res.success) {
              if (page > 0) {
                if (stationTypes?.data?.length === 1) {
                  let newPage = page - 1;

                  setPage(newPage);
                } else {
                  fetchData(formik?.values);
                }
              } else {
                fetchData(formik?.values);
              }

              swal({
                title: intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_DESCRIPTION",
                }),
                icon: "success",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            } else {
              swal({
                title: intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_FAILED_TITLE",
                }),
                text: intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_FAILED_DESCRIPTION",
                }),
                icon: "error",
                button: intl.formatMessage({
                  id: "CLIENT.GENERAL.OK_BUTTON",
                }),
              });
            }
          } catch (error) {
            swal({
              title: intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_FAILED_TITLE",
              }),
              text: intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.DELETE_SECURITY_FAILED_DESCRIPTION",
              }),
              icon: "error",
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON",
              }),
            });
          }
        };
        handleDeleteStationType();
      }
    });
  };

  const handleClear = () => {
    formik.setValues(initialValues);
    fetchData(initialValues);
    setPage(0);
    setPageSize(5);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Box
            mb={3}
            width={formik.values["expiry_day"] === "custom" ? "30%" : "60%"}
          >
            <SharedInputField
              name="search"
              label={intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.EMAIL_LABEL",
              })}
              type="text"
              placeholder={intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.EMAIL_HINT",
              })}
              {...formik.getFieldProps("search")}
              disabled={formik.isSubmitting}
            />
          </Box>
          <Box
            mb={3}
            width={formik.values["expiry_day"] === "custom" ? "22%" : "18%"}
          >
            <SharedSelectField
              label={intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.EXPIRY_LABEL",
              })}
              disableClearable
              name="expiry_day"
              options={EXPIRY_OPTIONS(intl)}
              defaultValue={formik.values["expiry_day"]}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
              className="text-normal"
            />
          </Box>
          {formik.values["expiry_day"] === "custom" && (
            <Box
              mb={3}
              width={formik.values["expiry_day"] === "custom" ? "22%" : "18%"}
            >
              <SharedInputField
                name="custom_expiry_day"
                label={intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.CUSTOM_EXPIRY_LABEL",
                })}
                type="number"
                placeholder={intl.formatMessage({
                  id: "CLIENT.SECURITY_MANAGEMENT.CUSTOM_EXPIRY_HINT",
                })}
                {...formik.getFieldProps("custom_expiry_day")}
                disabled={formik.isSubmitting}
                nowrap
                errorMsg={
                  formik.touched["custom_expiry_day"] &&
                  formik.errors["custom_expiry_day"]
                }
              />
            </Box>
          )}
          <Box
            mb={3}
            width={formik.values["expiry_day"] === "custom" ? "22%" : "18%"}
          >
            <SharedSelectField
              label={intl.formatMessage({
                id: "CLIENT.SECURITY_MANAGEMENT.PERMISSION_LABEL",
              })}
              disableClearable
              name="map_permission_id"
              className="text-normal"
              options={[
                {
                  id: "none",
                  value: "",
                  label: intl.formatMessage({ id: "CLIENT.GENERAL.ALL_ITEM" }),
                },
              ].concat(
                permissions.map((item) => ({
                  id: item.id,
                  value: item.id,
                  label: language === "en" ? item.name : item.name_vi,
                }))
              )}
              defaultValue={formik.values["map_permission_id"]}
              onChange={formik.setFieldValue}
              disabled={formik.isSubmitting}
            />
          </Box>
        </Box>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {isAllowed("workspaces", "new") && (
            <Box>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={() => setSecurityPage("new")}
              >
                <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.NEW_SECURITY" />
              </Button>
            </Box>
          )}
          <Box display="flex" ml="auto">
            <Box mr={2}>
              <Button
                className="custom-button light-button"
                type="button"
                onClick={handleClear}
              >
                <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.CLEAR_BUTTON" />
              </Button>
            </Box>
            <Button
              className="custom-button"
              type="button"
              onClick={formik.handleSubmit}
            >
              <FormattedMessage id="CLIENT.SECURITY_MANAGEMENT.APPLY_BUTTON" />
            </Button>
          </Box>
        </Box>
      </form>

      <Box>
        <SharedTable
          count={stationTypes.total}
          data={stationTypes.data.map((item) => ({
            ...item,
            expiry_day: EXPIRY_OPTIONS(intl).find(
              (itemOption) =>
                itemOption.value ===
                (item.expiry_day
                  ? item.expiry_day < 0
                    ? "0"
                    : item.expiry_day.toString()
                  : "")
            )
              ? EXPIRY_OPTIONS(intl).find(
                  (itemOption) =>
                    itemOption.value ===
                    (item.expiry_day
                      ? item.expiry_day < 0
                        ? "0"
                        : item.expiry_day.toString()
                      : "")
                ).label
              : item.expiry_day > 1
              ? `${item.expiry_day} ${language === "en" ? "Days" : "Ngày"}`
              : `${item.expiry_day} ${language === "en" ? "Day" : "Ngày"}`,
          }))}
          page={page}
          pageSize={pageSize}
          sortModel={sortModel}
          loading={loading}
          content={MapSecurityGrid(language)}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          actionHandlers={{
            delete: onDelete,
          }}
          disabledControls={{
            edit: (row) => !isAllowed("workspaces", "edit"),
            delete: (row) => !isAllowed("workspaces", "delete"),
          }}
        />
      </Box>
    </div>
  );
};

export default injectIntl(MapsSecutityList);
