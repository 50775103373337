/* eslint-disable no-use-before-define */
import React from "react";
import {
  Checkbox,
  Paper,
  Popper,
  TextField,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useLanguage } from "../../hooks";

const CustomPaper = (props) => {
  return (
    <Popper {...props} placement="bottom-start" transition>
      <Paper elevation={8}>{props.children}</Paper>
    </Popper>
  );
};

const SharedMultiSelect = ({
  items,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  getOptionLabel,
  className,
  onSelect,
  defaultRenderTags,
  customRenderer,
  handleBlur = () => {},
  onClose = () => {},
}) => {
  const allSelected = items.length === selectedValues.length;
  const [language] = useLanguage();

  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
      } else {
        onToggleOption && onToggleOption(selectedOptions);
        onSelect(selectedOptions);
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions();
    }
  };

  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      option.value === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = (params) => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      variant="outlined"
    />
  );
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;
  const filter = createFilterOptions();
  const tagsRenderer = (value, getTagProps) => {
    return language === "en"
      ? `${value.length} ${value.length > 1 ? "Items" : "Item"} Selected`
      : `${value.length} Lựa Chọn`;
  };

  return (
    <Autocomplete
      PopperComponent={CustomPaper}
      fullWidth
      multiple={true}
      handleHomeEndKeys={true}
      size="medium"
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      className={className}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (options.length > 0) {
          return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
        }

        return filtered;
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
      renderTags={
        customRenderer || (defaultRenderTags ? undefined : tagsRenderer)
      }
      openOnFocus
      onClose={handleBlur}
    />
  );
};

SharedMultiSelect.defaultProps = {
  limitTags: 3,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
  noOptionsText: "No Record Available",
  required: false,
};

export default SharedMultiSelect;
