import React from "react";
import { Box } from "@material-ui/core";
import SharedSelectField from "../../../../shared/components/SharedSelectField";
import { organizationTypes } from "../constants";

const OrganizationTypeSelect = ({ intl, pageData }) => {
  const { formik } = pageData;

  return (
    <Box mb={3}>
      <SharedSelectField
        disableClearable
        name="organizationType"
        options={organizationTypes.map((item) => ({
          id: item.value,
          value: item.value,
          label: intl.formatMessage({ id: item.label }),
        }))}
        defaultValue={formik.values["organizationType"]}
        onChange={formik.setFieldValue}
        disabled={formik.isSubmitting}
      />
    </Box>
  );
};

export default OrganizationTypeSelect;
