import axiosClient from "./axiosClient";
import customAxios from "./customAxios";

const stationsApi = {
  getAllStations(id, params, token) {
    const url = `/api/v1/station/get-all-station/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  },
  getAllStationsV2(params) {
    const url = "/api/v2/stations";
    return axiosClient.get(url, { params });
  },
  getAllStationsIncludeParameters(params) {
    const url = "/api/v2/stations/detailed";
    return axiosClient.get(url, { params });
  },
  getAllUsersWithInfoRole(params, token) {
    const url = `/api/v1/users/all-user-and-owner-with-info-role`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });
  },
  getAllStationPermission(token) {
    const url = `/api/v1/station-permission/`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getStation(id, token) {
    const url = `/api/v1/station/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getChartOptions(id, token) {
    const url = `/api/v1/station/get-station-with-parameter/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  addStation(workspaceId, data, token) {
    const url = `/api/v1/station/${workspaceId}`;
    return axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteStation(id, token) {
    const url = `/api/v1/station/${id}`;
    return axiosClient.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  putStation(id, data, token) {
    const url = `/api/v1/station/${id}`;
    return axiosClient.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  addStation(workspaceId, data, token) {
    const url = `/api/v1/station/${workspaceId}`;
    return axiosClient.post(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getStation(id, token) {
    const url = `/api/v1/station/${id}`;
    return axiosClient.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStation(id, data, token) {
    const url = `/api/v1/station/${id}`;
    return axiosClient.put(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  postStationChart(token, data) {
    const url = "/api/v1/station-chart";
    return customAxios(token).post(url, data);
  },
  moveStation(id, data, token) {
    const url = `/api/v1/station/move/${id}`;
    return axiosClient.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default stationsApi;
