import { Box } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const DragDropTable = ({
  visible = true,
  rows = [],
  containerClassName,
  rowKeyPropertyName,
  headerComponent,
  draggingDisabled = false,

  onDragEnd = () => {},
  onRenderRow = () => {},
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Box className={containerClassName}>
      {headerComponent}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: "white",
              }}
            >
              {rows?.map((item, index) => (
                <Draggable
                  key={item?.[rowKeyPropertyName]}
                  draggableId={item?.[rowKeyPropertyName]?.toString()}
                  isDragDisabled={draggingDisabled}
                  index={index}
                >
                  {(provided, snapshot) =>
                    onRenderRow(item, provided, snapshot)
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
