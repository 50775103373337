import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedInputField from "app/shared/components/SharedInputField";
import SharedSelectField from "app/shared/components/SharedSelectField";
import { FormattedMessage } from "react-intl";
import { AlarmColorField, MainColorField } from "../../common";
import { GAUGE_CHART_TYPES, RANGE_TYPES } from "../contants";
import useDataAlarm from "app/shared/hooks/DataAlarm/data-alarm.hook";

const GaugeChartForm = ({
  formik,
  intl,
  parameters,
  language
}) => {
  const { isLoadingDataAlarm, getDataAlarm } = useDataAlarm();

  async function onChangeParameter(stationId, parameterId) {
    const dataAlarm = await getDataAlarm(parameterId);
    formik.setValues((pre) => ({
      ...pre,
      parameter_id: parameterId,
      station_id: stationId,
      data_alarm: dataAlarm,
    }));
  }
  return (
    <div className="gauge-chart-body">

    <form onSubmit={formik.handleSubmit}>
      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.DASHBOARD.HEADER_LABEL',
        })}>

        <HeaderConfigurator formik={formik} />
      </ConfigureArea>

      <ConfigureArea title={intl.formatMessage({
        id: 'CLIENT.PARAMETERS_ACTIONS.PARAMETERS',
      })}>
        <Box mb={3}>
          <Box className="form-label">
            <p className="required">*</p>
            <FormattedMessage id='CLIENT.PARAMETERS_ACTIONS.PARAMETERS' />
          </Box>
          <SelectParamsByStaionDropdown
            errorMsg={formik.touched["parameter_id"] && formik.errors["parameter_id"]}
            data={parameters || []}
            disabled={isLoadingDataAlarm}
            value={{
              parameterId: formik.values["parameter_id"],
              stationId: formik.values["station_id"],
            }}
            onChange={({ stationId, parameterId }) =>
              onChangeParameter(stationId, parameterId)
            }
          />
        </Box>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SharedSelectField
                required
                className={`bg-white`}
                label={<FormattedMessage id='CLIENT.DASHBOARD.GAUGE.CHART_TYPE' />}
                disableClearable
                name="chart_type"
                options={GAUGE_CHART_TYPES.map(item => ({
                  ...item,
                  label: item[`name_${language}`]
                }))}
                defaultValue={formik.values["chart_type"]}
                onChange={formik.setFieldValue}
                errorMsg={formik.touched["chart_type"] && formik.errors["chart_type"]}
              />
            </Grid>
            <Grid item xs={6}>
              <SharedSelectField
                required
                className={`bg-white`}
                label={<FormattedMessage id='CLIENT.DASHBOARD.GAUGE.RANGE_TYPE' />}
                disableClearable
                name="range_type"
                options={RANGE_TYPES.map(item => ({
                  ...item,
                  label: item[`name_${language}`]
                }))}
                defaultValue={formik.values["range_type"]}
                onChange={formik.setFieldValue}
                errorMsg={formik.touched["range_type"] && formik.errors["range_type"]}
              />
            </Grid>
          </Grid>
        </Box>

        {
          formik.values.range_type === "FIXED" && (
            <Box mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SharedInputField
                    name="range_min"
                    label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.GAUGE.RANGE_MINIMUM' })}
                    type="number"
                    required={formik.values["range_type"] === 'FIXED'}
                    className={`bg-white`}
                    placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.GAUGE.RANGE_MINIMUM_HINT' })}
                    disabled={formik.isSubmitting}
                    errorMsg={formik.touched["range_min"] && formik.errors["range_min"]}
                    {...formik.getFieldProps("range_min")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SharedInputField
                    name="range_max"
                    label={intl.formatMessage({ id: 'CLIENT.DASHBOARD.GAUGE.RANGE_MAXIMUM' })}
                    type="number"
                    required={formik.values["range_type"] === 'FIXED'}
                    className={`bg-white`}
                    placeholder={intl.formatMessage({ id: 'CLIENT.DASHBOARD.GAUGE.RANGE_MAXIMUM_HINT' })}
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps("range_max")}
                    errorMsg={(formik.touched["range_min"] || formik.touched["range_max"]) && formik.errors["range_max"]}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        }
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <MainColorField formik={formik} />
          </Grid>
          <Grid item xs={3}>
            <AlarmColorField formik={formik} />
          </Grid>
        </Grid>
      </ConfigureArea>
      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: 'CLIENT.DASHBOARD.FEATURES',
        })}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.show_line}
              onChange={(e) =>
                formik.setFieldValue("show_line", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.FEATURES.SHOW_LINES',
          })}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values?.show_number}
              onChange={(e) =>
                formik.setFieldValue("show_number", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({
            id: 'CLIENT.DASHBOARD.FEATURES.SHOW_NUMBER',
          })}
        />
      </ConfigureArea>
    </form>
  </div>
  )
}

GaugeChartForm.propTypes = {
};

export default GaugeChartForm