import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../../../hooks";
import SharedSelectField from "../../SharedSelectField";

import {
  COMBINED_DATA,
  HISTORICAL_DATA,
  PREDICTION_DATA,
} from "app/pages/Dashboard/components/Modals/contants";
import { historicalTimeRange, predictionTimeRange } from "./data";
import TimeRangeSeletor from "./TimeRangeSeletor";

function DataModeConfigurator(props) {
  const {
    options,
    values,
    mode,
    predictionLabel,
    historicalLabel,
    combinedDataHistoricalLabel,
    combinedDataPredictionLabel,
    error,
    showHistoricTimeRange,
    showPredictionTimeRange,
    errorMsg,
  } = props;

  const [language] = useLanguage();

  return (
    <div>
      <RadioGroup
        row
        className="radio-group"
        aria-label="location_type"
        name="location_type"
        value={
          [HISTORICAL_DATA, PREDICTION_DATA, COMBINED_DATA].includes(mode)
            ? mode
            : HISTORICAL_DATA
        }
        onChange={(e) => {
          props["onChange"](
            "data_mode",
            [HISTORICAL_DATA, PREDICTION_DATA, COMBINED_DATA].includes(
              e.target.value
            )
              ? e.target.value
              : HISTORICAL_DATA
          );
        }}
      >
        {options.includes(HISTORICAL_DATA) && options.length > 1 && (
          <FormControlLabel
            value={HISTORICAL_DATA}
            control={<Radio color="primary" />}
            label={
              <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.HISTORICAL" />
            }
          />
        )}
        {options.includes(PREDICTION_DATA) && (
          <FormControlLabel
            value={PREDICTION_DATA}
            control={<Radio color="primary" />}
            label={
              <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.PREDICTION" />
            }
          />
        )}
        {options.includes(COMBINED_DATA) && (
          <FormControlLabel
            value={COMBINED_DATA}
            control={<Radio color="primary" />}
            label={<FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.COMBINED" />}
          />
        )}
      </RadioGroup>

      {(mode === HISTORICAL_DATA || mode === COMBINED_DATA) &&
        (showHistoricTimeRange ||
          (props.values.historical_time_range !== undefined &&
            props.values.historical_time_range?.split("-")?.length > 1)) && (
          <>
            <Box>
              <SharedSelectField
                required
                className="bg-white"
                label={
                  combinedDataHistoricalLabel ||
                  historicalLabel || 
                  
                  (
                    <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.HISTORICAL.TIMEPOINT" />
                  )
                }
                disableClearable
                name="historical_time_range"
                options={historicalTimeRange.map((item) => ({
                  ...item,
                  label: item[`name_${language}`],
                }))}
                defaultValue={
                  historicalTimeRange.some(
                    (item) => item.value === values?.historical_time_range
                  )
                    ? values?.historical_time_range
                    : historicalTimeRange.at(0)?.value
                }
                onChange={(name, value) => {
                  const times = value.split("-");
                  props.onChange(`${name}`, times[0]);
                  props.onChange(`${name}_type`, times[1]);
                }}
                errorMsg={props.values.historical_time_range !== "0-DAY" ? errorMsg : null}
              />
            </Box>
            {props.values.historical_time_range === "0-DAY" && (
              <TimeRangeSeletor
                error={error || errorMsg}
                onChange={props.onChange}
                endTime={values?.historical_end_time}
                startTime={values?.historical_start_time}
              />
            )}
          </>
        )}

      {(mode === PREDICTION_DATA ||
        mode === COMBINED_DATA ||
        showPredictionTimeRange) && (
        <Box mt={3}>
          <SharedSelectField
            required
            className="bg-white"
            label={
              combinedDataPredictionLabel ||
              predictionLabel || (
                <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.PREDICTION.TIMEPOINT" />
              )
            }
            disableClearable
            name="prediction_time_range"
            options={predictionTimeRange.map((item) => ({
              ...item,
              label: item[`name_${language}`],
            }))}
            defaultValue={
              predictionTimeRange.some(
                (item) => item.value === values?.prediction_time_range
              )
                ? values?.prediction_time_range
                : predictionTimeRange.at(0)?.value
            }
            onChange={(name, value) => {
              const times = value.split("-");
              props.onChange(`${name}`, times[0]);
              props.onChange(`${name}_type`, times[1]);
            }}
          />
        </Box>
      )}
      {/* {errorMsg && (
        <Box 
          style={{
            marginTop: 1,
            fontSize: 12,
            color: "#f44336",
          }}
        >
          <span className="text-danger">{errorMsg}</span>
        </Box>
      )} */}
    </div>
  );
}

export default DataModeConfigurator;
