import React, { useRef, useEffect, useState } from "react";
import formatcoords from "formatcoords";
import ReactFitText from "react-fittext";
import { useResizeDetector } from "react-resize-detector";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { MAPBOX_ACCESS_TOKEN } from "app/constant/mapbox";
import { isEmpty } from "lodash";
import "./styles.css";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const INITIAL_ZOOM = 7;
const ZOOM_RATE = 0.18;

const STREET_MODE = 1;
const SATELLITE_MODE = 2;
const VIET_NAM_INITIALPOS = {
  lat: 16.4533875,
  lng: 107.5420939,
};

const DisplayPosition = ({ map, position, format, textColor }) => {
  const DECIMAL_PLACES = format === "FFf" ? 2 : 6;

  if (position) {
    return (
      <ReactFitText minFontSize={19} compressor={2.5} maxFontSize={30}>
        <div className="location-map-position" style={{ color: textColor }}>
          {position &&
            position.lat !== 0 &&
            position.lng !== 0 &&
            formatcoords(position).format(format, {
              latLonSeparator: ", ",
              decimalPlaces: DECIMAL_PLACES,
            })}
        </div>
      </ReactFitText>
    );
  }

  return null;
};

export default function Map(props) {
  const {
    id,
    textColor = "#fff",
    coordinateFormat,
    style = 1,
    initialPos, // [lat, lng]
    markers,
    dragging = false,
    language,
  } = props;
  const mapContainerRef = useRef(null);

  const [lng, setLng] = useState(initialPos?.lng);
  const [lat, setLat] = useState(initialPos?.lat);
  const [position, setPosition] = useState(null);
  const [globalMap, setGlobalMap] = useState();

  const displayPosition = coordinateFormat !== "none";

  const isValidPosition = (pos) => {
    return (
      +pos?.lat > -90 && +pos?.lat < 90 && +pos?.lng > -180 && +pos?.lng < 180
    );
  };
  const getStyle = (styleId) => {
    switch (styleId) {
      case STREET_MODE:
        return process.env.REACT_APP_MAPBOX_STREET_MAP_URL;

      case SATELLITE_MODE:
        return process.env.REACT_APP_MAPBOX_SATELLITE_MAP_URL;
      default:
        break;
    }
  };

  const onResize = () => {
    if (globalMap) {
      globalMap?.resize();
    }
  };

  const { ref } = useResizeDetector({
    onResize,
    refreshMode: "debounce",
    refreshRate: 500,
  });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: getStyle(style),
      center: isValidPosition(initialPos) ? initialPos : VIET_NAM_INITIALPOS,
      zoom: (props.zoom && props.zoom * ZOOM_RATE) || INITIAL_ZOOM,
      minZoom: 4,
      maxBounds: [
        [-180, -85],
        [180, 85],
      ],
    });
    map.addControl(new mapboxgl.FullscreenControl(), "bottom-right");
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.on("move", () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setPosition({
        lat: map.getCenter().lat,
        lng: map.getCenter().lng,
      });
    });

    setGlobalMap(map);

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // enable drag when press CRTL
  useEffect(() => {
    if (dragging) {
      globalMap?.dragging?.enable();
      globalMap?.scrollZoom?.enable();
    } else {
      globalMap?.scrollZoom?.disable();
      globalMap?.dragging?.disable();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging]);

  useEffect(() => {
    if (globalMap) {
      const newZoom = (props.zoom && props.zoom * ZOOM_RATE) || INITIAL_ZOOM;

      globalMap?.setZoom(newZoom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.zoom]);

  useEffect(() => {
    if (globalMap) {
      const newStyle = getStyle(style);

      globalMap?.setStyle(newStyle);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]);

  useEffect(() => {
    if (globalMap) {
      let map = globalMap;

      map.on("load", function () {
        if (isValidPosition(position)) {
          setPosition(position);
        } else if (isValidPosition(initialPos)) {
          setPosition(initialPos);
        }

        markers?.forEach((marker) => {
          map.loadImage(
            marker?.icon?.options?.iconUrl,
            function (error, image) {
              const imageName = `image-${marker?.id}`;
              const hasImage = map.hasImage(imageName);

              if (!hasImage) {
                map.addImage(imageName, image, {
                  width: 24,
                  height: 24,
                });
              }
            }
          );
        });

        let timer = setTimeout(() => {
          const features = markers?.map((marker) => {
            return {
              type: "Feature",
              properties: {
                title: marker?.[`name_${language}`] || marker?.name,
                image: `image-${marker?.id}`,
              },
              geometry: {
                coordinates: [
                  Number(marker?.position?.lng),
                  Number(marker?.position?.lat),
                ],
                type: "Point",
              },
            };
          });

          const existingSource = map.getSource("points");

          if (isEmpty(existingSource)) {
            map.addSource("points", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: features,
              },
            });
          }

          if (!map.getLayer("points")) {
            map.addLayer({
              id: "points",
              type: "symbol",
              source: "points",
              layout: {
                "icon-image": ["get", "image"],
                "icon-size": 0.1,
                "text-field": ["get", "title"],
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-offset": [0, 2],
                "text-anchor": "top",
              },
              paint: {
                "text-color": style === 1 ? "#000" : "#fff",
              },
            });
          }

          clearTimeout(timer);
        }, 1000);
      });

      setGlobalMap(map);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalMap,
    props?.coordinateFormat,
    props?.zoom,
    props?.style,
    props?.textColor,
    props?.markers,
  ]);

  return (
    <div className="location-map-container" ref={ref}>
      {/* {globalMap && displayPosition && (
        <DisplayPosition
          map={globalMap}
          format={coordinateFormat || "none"}
          textColor={textColor}
          position={position}
        />
      )} */}

      <div
        className={`map-container map-container-${id}`}
        ref={mapContainerRef}
      />
    </div>
  );
}
