import { useEffect, useState } from "react";

const useGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: []
  });

  const onSuccess = location => {
    setLocation({
      loaded: true,
      coordinates: [location?.coords?.latitude, location?.coords?.longitude]
    })
  }

  const onError = error => {
    setLocation({
      loaded: true,
      error,
    })
  }

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      setLocation(state => ({
        ...state,
        loaded: true,
        error: {
          code: 0,
          message: "Geolocation not supported"
        }
      }))
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
}

export { useGeoLocation };