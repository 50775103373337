/* eslint-disable react-hooks/exhaustive-deps */
import { DATE_FORMAT } from "app/constant/date-time-format";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ModalContainer from "../../common/ModalContainer";
import { prepareAggregateTableOutput } from "../helpers";
import { AGGREGATE_TABLE_INITIALVALUES } from "../initialValues";
import useModal from "../useModal";
import "./styles.css";
import AggregateTableForm from "./AggregateTableForm";
import AggregateTableSchema from "../schemas/AggregateTableFormSchema";

function MenuModal(props) {
  const { intl, open, handleClose  } = props;

  const { openModal, setOpenModal, toggleOpen, language } = useModal({
    handleClose,
    modalOpen: open,
  });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || AGGREGATE_TABLE_INITIALVALUES.name_en,
      name_vi: props.data?.name_vi || AGGREGATE_TABLE_INITIALVALUES.name_vi,
      header_color:
        props.data?.header_color || AGGREGATE_TABLE_INITIALVALUES.header_color,
      show_header:
        props.data?.show_header === undefined
          ? AGGREGATE_TABLE_INITIALVALUES.show_header
          : props.data.show_header,
      params:
        props.data?.dashboard_aggregate_table
          ?.aggregate_table_parameter?.sort((a, b) => a.order_number - b.order_number) || [],
      data_mode: props.data?.dashboard_aggregate_table?.data_mode || AGGREGATE_TABLE_INITIALVALUES.data_mode,
      display_mode: props.data?.dashboard_aggregate_table?.display_mode || AGGREGATE_TABLE_INITIALVALUES.display_mode,
      functions:
        props.data?.dashboard_aggregate_table?.functions || [],
      // start_date: props.data?.dashboard_aggregate_table?.start_date || moment((new Date())).subtract(1, 'days').toLocaleString(),
      // end_date: props.data?.dashboard_aggregate_table?.end_date || (new Date()).toLocaleDateString(),
      start_date: props.data?.dashboard_aggregate_table?.start_date ||moment(moment().subtract(1, 'days').format(DATE_FORMAT), DATE_FORMAT),
      end_date: props.data?.dashboard_aggregate_table?.end_date || moment(moment().format(DATE_FORMAT), DATE_FORMAT),
      station_id : props.data?.dashboard_aggregate_table?.station_id || null,
    },
    enableReinitialize: true,
    validationSchema: AggregateTableSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;
      props["handleEdit"](id, prepareAggregateTableOutput(props?.data, values));
      props["handleClose"]();
    },
  });

  React.useEffect(() => {
    setOpenModal(props["open"]);
    return () => {
      formik.resetForm();
    };
  }, [props["open"]]);

  return (
    <ModalContainer
      formik={formik}
      editing={props?.editing}
      handleClose={props?.handleClose}
      open={openModal}
      toggleOpen={toggleOpen}
      newText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_AGGREGATE_TABLE" />
      }
      editText={
        <FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_AGGREGATE_TABLE" />
      }
      disabledSave={Object.keys(formik?.errors)?.length > 0}
    >
      <AggregateTableForm
        formik={formik}
        intl={intl}
        language={language}
        parameters={props?.parameters || []}
      />
    </ModalContainer>
  );
}

export default injectIntl(MenuModal);
