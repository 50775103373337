/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalContainer from '../../common/ModalContainer';
import { prepareDirectionOutput } from '../helpers';
import { DIRECTION_INITIALVALUES } from '../initialValues';
import DirectionModalSchema from '../schemas/DirectionFormSchema';
import useModal from '../useModal';
import DirectionForm from './DirectionForm';
import './styles.css';

export default function MenuModal(props) {
  const { open, handleClose } = props;
  const intl = useIntl();
  const { openModal, setOpenModal, toggleOpen } = useModal({ handleClose, modalOpen: open })

  const formik = useFormik({
    initialValues: {
      name_en: props?.data?.name_en || DIRECTION_INITIALVALUES.name_en,
      name_vi: props?.data?.name_vi || DIRECTION_INITIALVALUES.name_vi,
      parameter_id: props?.data?.dashboard_direction?.parameter_id || DIRECTION_INITIALVALUES.parameter_id,
      station_id: props?.data?.dashboard_direction?.station_id || DIRECTION_INITIALVALUES.station_id,
      header_color: props?.data?.header_color || DIRECTION_INITIALVALUES.header_color,
      main_color: props?.data?.dashboard_direction?.main_color || DIRECTION_INITIALVALUES.main_color,
      data_alarm: props?.data?.dashboard_direction?.parameter?.data_alarm || [],
      show_header: props.data?.show_header === undefined ? DIRECTION_INITIALVALUES.show_header : props.data.show_header,
      number_of_direction: props?.data?.dashboard_direction?.number_of_direction || DIRECTION_INITIALVALUES.number_of_direction,
      show_number: props.data?.dashboard_direction?.show_number === undefined ? DIRECTION_INITIALVALUES.show_number : props.data?.dashboard_direction?.show_number,
      data_mode: props.data?.data_mode || DIRECTION_INITIALVALUES.data_mode,
      prediction_time_range: (props.data?.time_point && props.data?.time_point !== "0") ? props.data?.time_point : DIRECTION_INITIALVALUES.prediction_time_range,
      prediction_time_range_type: (props.data?.time_point_type && props.data?.time_point_type !== "0") ? props.data?.time_point_type : DIRECTION_INITIALVALUES.prediction_time_range_type,
    },
    enableReinitialize: true,
    validationSchema: DirectionModalSchema({ intl }),
    onSubmit: async (values) => {
      const { id } = props;

      props['handleEdit'](id, prepareDirectionOutput(values));
      props['handleClose']();
    },
  });

  React.useEffect(() => {
    setOpenModal(props['open']);
    return () => {
      formik.resetForm();
    }
  }, [props['open']]);

  React.useEffect(() => {
    setOpenModal(props['open']);
  }, [props['open']]);

  
  return (<ModalContainer
    formik={formik}
    editing={props?.editing}
    handleClose={props?.handleClose}
    open={openModal}
    disabledSave={formik.isSubmitting ||
      !formik.isValid}
    toggleOpen={toggleOpen}
    newText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.NEW_DIRECTION" />}
    editText={<FormattedMessage id="CLIENT.DASHBOARD.MODAL.EDIT_DIRECTION" />}>
    <DirectionForm
      formik={formik}
      intl={intl}
      parameters={props.parameters} />
  </ModalContainer>)
}
