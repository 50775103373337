import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 40"
      className={`reeco-icon ${props?.className}`}
      {...props}
    >
      <path
        fill="currentColor"
        fillOpacity="1"
        fillRule="evenodd"
        d="M33.25 5H8.75c-1.925 0-3.5 1.5-3.5 3.333v23.334C5.25 33.5 6.825 35 8.75 35h24.5c1.925 0 3.5-1.5 3.5-3.333V8.333C36.75 6.5 35.175 5 33.25 5zm0 26.667H8.75V8.333h24.5v23.334zm-19.02-7.81v3.487h-1.97v-10.24h1.826l.073.728c.2-.247.433-.444.7-.592.332-.182.722-.273 1.168-.273.47 0 .882.089 1.238.266.36.178.66.433.902.766.246.333.43.73.554 1.19.127.46.191.97.191 1.53v.144c0 .538-.064 1.037-.191 1.497-.123.46-.306.862-.547 1.204a2.597 2.597 0 01-.902.792c-.356.187-.766.28-1.231.28-.451 0-.843-.09-1.176-.273-.239-.13-.45-.3-.636-.505zm0-1.525c.104.22.252.398.444.534.219.155.506.233.861.233.26 0 .479-.057.656-.171.178-.119.322-.28.431-.486.114-.205.194-.442.24-.71a4.73 4.73 0 00.075-.869v-.143c0-.315-.028-.604-.082-.868a2.251 2.251 0 00-.247-.704 1.179 1.179 0 00-1.087-.636c-.268 0-.498.043-.69.13-.187.086-.34.212-.458.376a1.55 1.55 0 00-.143.244v3.07zM26.315 20.2H22.31v4.3h-2.051v-9.953h2.05V18.6h4.006v-4.054h2.044V24.5h-2.044v-4.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
