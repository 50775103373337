import moment from "moment";

export const RANGE_OPTIONS = [
  {
    id: 1,
    name_en: "All Time",
    name_vi: "Tất Cả",
    is_all_time: true,
  },
  {
    id: 2,
    name_en: "Today",
    name_vi: "Hôm Nay",
    start_time: moment().startOf("day"),
    end_time: moment().endOf("day"),
  },
  {
    id: 3,
    name_en: "Yesterday",
    name_vi: "Hôm Qua",
    start_time: moment()?.subtract(2, "days")?.endOf("day"),
    end_time: moment()?.subtract(1, "days")?.endOf("day"),
  },
  {
    id: 4,
    name_en: "This Week",
    name_vi: "Tuần Này",
    start_time: moment().startOf("week"),
    end_time: moment().endOf("week"),
  },
  // {
  //   id: 5,
  //   name_en: "Last Week",
  //   name_vi: "Tuần Trước",
  //   start_time: moment()?.subtract(1, "weeks")?.startOf("day"),
  //   end_time: moment()?.subtract(1, "weeks")?.endOf("day"),
  // },
  {
    id: 6,
    name_en: "This Month",
    name_vi: "Tháng Này",
    start_time: moment().startOf("month"),
    end_time: moment().endOf("month"),
  },
  // {
  //   id: 7,
  //   name_en: "Last Month",
  //   name_vi: "Tháng Trước",
  //   start_time: moment()?.subtract(1, "months")?.startOf("day"),
  //   end_time: moment()?.subtract(1, "months")?.endOf("day"),
  // },
  {
    id: 8,
    name_en: "This Year",
    name_vi: "Năm Nay",
    start_time: moment().startOf("year"),
    end_time: moment().endOf("year"),
  },
  // {
  //   id: 9,
  //   name_en: "Last Year",
  //   name_vi: "Năm Ngoái",
  //   start_time: moment()?.subtract(1, "years")?.startOf("year"),
  //   end_time: moment()?.subtract(1, "years")?.endOf("year"),
  // },
  {
    id: 10,
    name_en: "Custom",
    name_vi: "Tùy Chỉnh",
    is_custom: true,
  },
];

export const DEFAULT_RANGE_OPTION = RANGE_OPTIONS[0];

export const CONTAINER_DOM_ELEMENT = "#dashboard-date-time-filters-modal";
