import React, { useState } from "react";
import { Box, Button, Divider, makeStyles, Modal } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import userGuide from "app/api/userGuide";
import { FormattedMessage } from "react-intl";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '80vh',
    overflowY: 'auto',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
  },
  formControl: {
    width: '100%',
  },
  padding: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  item: {
    padding: "12px 24px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    "& p": {
      marginLeft: "16px",
    },
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  disabledItem: {
    // opacity: "0.5",
    cursor: "default",
    color: 'rgba(0,0,0,0.2)',
    pointerEvents: "none",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: '#fff',
  },
  stickyFooter: {
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.common.white,
  },
  button: {
    width: "283px",
    maxWidth: "80%",
    fontSize: "16px !important",
  },
}));

const FolderModal = (props) => {
  const { isOpen, handleClose, categoryList, id, token, refetchData } = props;
  const [modalStyle] = useState(getModalStyle);
  const [clickedItemId, setClickedItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const toggleOpen = () => {
    handleClose();
  };

  const renderHeader = () => {
    return (
      <Box className={clsx("value-modal-header", classes.padding)}>
        <div className="value-modal-header-left">
          <FormattedMessage id="MENU.USER_GUIDE.MOVE_TO" />
        </div>
        <div className="value-modal-header-right">
          <span className="value-modal-header-icon" onClick={toggleOpen}>
            <CloseIcon onClick={handleClose} />
          </span>
        </div>
      </Box>
    );
  };

  const handleItemClick = (id) => {
    if (id === clickedItemId) {
      setClickedItemId(null)
    } else {
      setClickedItemId(id)
    }
  }

  const renderBody = () => {
    return (
      <Box className={classes.body}>
        {
          categoryList && categoryList.length > 0 &&
          categoryList?.map(item =>
            <Box
              key={item.id}
              display="flex"
              alignItems="center"
              className={clsx(
                classes.item,
                clickedItemId === item.id && classes.activeItem,
                item?.user_guide?.findIndex(userGuide => userGuide.id === id) !== -1 && classes.disabledItem
              )}
              onClick={() => handleItemClick(item.id)}
            >
              <FolderOpenIcon />
              <p>{item?.["name_en"]}</p>
            </Box>
          )
        }
      </Box>
    )
  }

  const renderFooter = () => {
    return (
      <Box p={2} display="flex" alignItems="center" justifyContent="center">
        <Button
          className={clsx("custom-button", classes.button)}
          disabled={!clickedItemId || loading}
          onClick={handleMoveFolder}
        >
          <FormattedMessage id="MENU.USER_GUIDE.MOVE" />
        </Button>
      </Box>
    )
  }

  const updatePreviousFolderOrder = async (previousUserGuideList) => {
    try {
      await Promise.all(previousUserGuideList.map((item, index) => {
        const data = {
          apply_order: index + 1
        };
        return userGuide.updateUserGuide(item.id, data, token);
      }))
    } catch (error) {
      console.log(error);
    }
  }

  const handleMoveFolder = async () => {
    try {
      setLoading(true);
      const categoryItem = categoryList.find(item => item.id === clickedItemId);
      const data = {
        apply_order: categoryItem?.user_guide?.length + 1,
        user_guide_category_id: clickedItemId,
      }
      const resp = await userGuide.updateUserGuide(id, data, token);
      if (resp.success) {
        const previousUserGuide = categoryList
          .find(item => item?.user_guide?.findIndex(userGuide => userGuide?.id === id) !== -1)?.user_guide?.filter(item => item.id !== id);
        if (previousUserGuide && previousUserGuide.length > 0) {
          await updatePreviousFolderOrder(previousUserGuide)
        }
        await refetchData();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Box className={classes.stickyHeader}>
          {renderHeader()}
          <Divider />
        </Box>
        {renderBody()}
        <Box className={classes.stickyFooter}>
          <Divider />
          {renderFooter()}
        </Box>
      </div>
    </Modal>
  )
}

export default FolderModal;