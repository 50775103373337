import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from "prop-types";

// icons 
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PermMediaIcon from '@material-ui/icons/PermMediaOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import CloseIcon from '@material-ui/icons/Close';

const ChatWindowFooter = (props) => {
  const { onSubmit, id } = props;
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const intl = useIntl();

  const handleInputChange = (e) => {
    setText(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(text, files);
      setText("")
      if (files && files.length > 0) {
        setFiles([])
      }
    }
  }

  const handleImageUpload = (event) => {
    const files = event.currentTarget.files;
    for (let file of files) {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setFiles(prevFiles => ([
          ...prevFiles,
          {
            id: uuidv4(),
            file,
            url: reader.result,
            type: "image"
          }
        ]))
      }
    }
  }

  const handleFileUpload = (event) => {
    const files = event.currentTarget.files;
    for (let file of files) {
      setFiles(prevFiles => ([
        ...prevFiles,
        {
          id: uuidv4(),
          file,
          name: file.name,
          type: "file"
        }
      ]))
    }
  }

  const handleRemoveFile = (id) => {
    const index = files.findIndex(item => item.id === id);
    if (index !== -1) {
      const list = [...files];
      list.splice(index, 1);
      setFiles(list)
    }
  }

  return (
    <div className='chat-window-footer'>
      {files && files?.length > 0 &&
        <div className="file-wrapper">
          {files.map(item =>
            item.type === "image" ?
              <div className="file-image" key={item.id}>
                <img src={item.url} />
                <div className="remove-file" onClick={() => handleRemoveFile(item.id)}>
                  <CloseIcon fontSize="small" />
                </div>
              </div> :
              <div className="file-file" key={item.id}>
                <div className="file-icon">
                  <AttachFileIcon />
                </div>
                <div className="file-name">
                  <span>{item.name.split('.')?.slice(0, -1)?.join()}</span>
                  <span>.{item.name.split('.').pop()}</span>
                </div>
                <div className="remove-file" onClick={() => handleRemoveFile(item.id)}>
                  <CloseIcon fontSize="small" />
                </div>
              </div>
          )}
        </div>
      }
      <form onSubmit={handleSubmit}>
        <div className='chat-window-tools'>
          <span>
            <label htmlFor={`file-${id}`} className='icon-wrapper'>
              <AttachFileIcon color="primary" />
            </label>
            <input
              type="file"
              accept=".csv, 
              application/vnd.ms-excel, 
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
              application/msword,
              text/plain, application/pdf,
              .doc, .docx
              "
              id={`file-${id}`}
              name={`file-${id}`}
              multiple
              hidden
              onChange={handleFileUpload}
              onClick={(event) => {
                event.target.value = null
              }}
            />
          </span>

          <span>
            <label htmlFor={`image-${id}`} className='icon-wrapper'>
              <PermMediaIcon color="primary" />
            </label>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/tif, image/tiff .tif"
              id={`image-${id}`}
              name={`image-${id}`}
              multiple
              hidden
              onChange={handleImageUpload}
              onClick={(event) => {
                event.target.value = null
              }}
            />
          </span>
        </div>
        <div className='chat-window-input'>
          <input
            type='text'
            placeholder={intl.formatMessage({
              id: "CLIENT.CHAT.CHAT_BOX_HINT"
            })}
            value={text}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" className="send-button">
          <SendOutlinedIcon color="primary" />
        </button>
      </form>
    </div>
  )
}


ChatWindowFooter.propTypes = {
  onSubmit: PropTypes.func
};

export default memo(ChatWindowFooter);