import React from "react"
import PropTypes from "prop-types"
import { Box, Checkbox, FormControlLabel, Grid } from "@material-ui/core";

import "./index.css";
import { FormHelperText } from "@material-ui/core";

const SharedCheckboxField = (props) => {
  const { 
    name,
    errorMsg,
    label,
    values,
    disabled,
    onChange,
    required = false,
    options
  } = props;

  const handleCheck = (value) => {
    const newValues = [...values];
    const index = newValues.findIndex(item => item === value);
    if (index === -1) {
      onChange(name, [...newValues, value])
    } else {
      newValues.splice(index, 1);
      onChange(name, newValues)
    }
  }

  return (
    <div className="shared-checkbox-field">
      <Box className="form-label">
        {
          required &&
          <p className="required">*</p>
        }
        { label }
      </Box>
      <Grid container>
        {
          options && options.length > 0 &&
          options.map(({id, label, value}) => (
            <Grid item key={id} xs={6} sm={4} md={3} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox 
                    name={name}
                    disabled={disabled}
                    color="primary"
                    value={value}
                    checked={values.includes(value)}
                    onChange={() => handleCheck(value)}
                    size="small"
                  />
                }
                label={label}
              />
            </Grid>
          ))
        }
      </Grid>
      {
        errorMsg &&
        <FormHelperText className="error-message">{errorMsg}</FormHelperText>
      }
    </div>
  )
}

SharedCheckboxField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
  })).isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
  onChange: PropTypes.func,
  errorMsg: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}


SharedCheckboxField.defaultProps = {
  onChange: null,
  errorMsg: "",
  label: "",
  required: false,
  disabled: false,
  values: []
}


export default SharedCheckboxField