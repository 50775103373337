import * as Yup from "yup";

const AttachmentImageFormSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    name_vi: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    link: Yup.string()
      .nullable()
      .when("upload_type", {
        is: (val) => val === "RTSP",
        then: Yup.string().required(
          intl.formatMessage({
            id: "CLIENT.GENERAL.REQUIRED_FIELD",
          })
        ),
      }),
  });
  return schema;
};

export default AttachmentImageFormSchema;
