import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../../../shared/utils/validateReg";
import authApi from "../../../../api/authApi";
import { getResponseMessage } from "../../../../shared/utils/functions";
import moment from "moment";
import { useLanguage } from "../../../../shared/hooks";
import {
  showRegisterFailedModal,
  showRegisterSuccessModal,
} from "../helpers/modals";

const initialValues = {
  full_name: "",
  email: "",
  password: "",
  organization: "",
  confirmPassword: "",
  acceptTerms: false,
  organizationType: "PREMIUM_ACCOUNT",
  phone_number: "",
  date_of_birth: undefined,
  address: "",
  gender: null,
};

export const useRegistration = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState("public");
  const [channel, setChannel] = useState("email");
  const [language] = useLanguage();

  const handleAccountTypesChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleChannelChange = (event) => {
    setChannel(event.target.value);
    if (event.target.value === "email") {
      formik.setErrors("phone", "");
    } else {
      formik.setErrors("email", "");
    }
  };

  const RegistrationSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    email:
      channel === "email"
        ? Yup.string()
            .email(
              intl.formatMessage({
                id: "CLIENT.GENERAL.INVALID_EMAIL_FORMAT",
              })
            )
            .min(
              3,
              intl.formatMessage({
                id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
              })
            )
            .max(
              50,
              intl.formatMessage({
                id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
              })
            )
            .required(
              intl.formatMessage({
                id: "CLIENT.GENERAL.REQUIRED_FIELD",
              })
            )
        : Yup.string()
            .min(
              3,
              intl.formatMessage({
                id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
              })
            )
            .max(
              50,
              intl.formatMessage({
                id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
              })
            )
            .email(
              intl.formatMessage({
                id: "CLIENT.GENERAL.INVALID_EMAIL_FORMAT",
              })
            ),
    phone_number:
      channel === "phone"
        ? Yup.string()
            .required(
              intl.formatMessage({
                id: "CLIENT.GENERAL.REQUIRED_FIELD",
              })
            )
            .matches(
              phoneRegExp,
              intl.formatMessage({
                id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
              })
            )
        : Yup.string().matches(
            phoneRegExp,
            intl.formatMessage({
              id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
            })
          ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "CLIENT.GENERAL.INVALID_CONFIRM_PASSWORD",
          })
        ),
      }),
    acceptTerms: Yup.bool().oneOf(
      [true],
      intl.formatMessage({
        id: "CLIENT.GENERAL.MUST_ACCEPT_TERMS",
      })
    ),
    address: Yup.string()
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
    date_of_birth: Yup.date()
      .nullable()
      .max(
        new Date(),
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_DATE_OF_BIRTH",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (
      values,
      { setStatus, setSubmitting, resetForm, setFieldValue }
    ) => {
      try {
        setSubmitting(true);
        enableLoading();
        const data = {
          select_username: channel,
          fullname: values.full_name,
          gender: values.gender,
          birthday: values.date_of_birth
            ? moment(values.date_of_birth).format("YYYY-MM-DD")
            : undefined,
          email: values.email || "",
          password: values.password,
          address: values.address || "",
          passwordConfirm: values.confirmPassword,
          phone: values.phone_number || "",
          organization: accountType === "member" ? values.organization : "",
          partner_member_access:
            accountType === "member" ? values.organizationType : "",
          language: language,
        };
        const res = await authApi.register(data);
        if (res.success) {
          const message = intl.formatMessage({
            id:
              accountType === "public"
                ? channel === "email"
                  ? "CLIENT.REGISTRATION.VALID_EMAIL_SIGN_UP_MESSAGE"
                  : "CLIENT.REGISTRATION.VALID_PHONE_SIGN_UP_MESSAGE"
                : "CLIENT.REGISTRATION.VALID_ORGANIZATION_SIGN_UP_MESSAGE",
          });

          showRegisterSuccessModal({
            intl,
            message,
            onAccept: () => {
              setSubmitting(false);
              disableLoading();

              resetForm();
              setFieldValue("acceptTerms", false);

              props.history.push("/auth/login");
            },
          });
        }
      } catch (error) {
        const message = intl.formatMessage({
          id: getResponseMessage(
            error.response && error.response.data.code,
            accountType,
            channel
          ),
        });

        showRegisterFailedModal({
          intl,
          message,
          onAccept: () => setStatus(message),
        });
      } finally {
        setSubmitting(false);
        disableLoading();
      }
    },
  });

  return {
    // Getters
    formik,
    loading,
    accountType,
    channel,
    language,
    RegistrationSchema,

    // Setters
    setLoading,
    setAccountType,
    setChannel,

    // Utils
    enableLoading,
    disableLoading,
    handleAccountTypesChange,
    handleChannelChange,
  };
};
