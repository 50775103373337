import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@material-ui/core";

import "./styles.css";
import { useLanguage } from "../../../hooks";
import workspaceDashboard from "../../../../api/workspaceDashboardApi";
import { invertColor } from "app/utils/utils";
import { DashboardCardButtons } from "../DashboardCardButtons";

const ValueCard = (props) => {
  const intl = useIntl();
  const [language] = useLanguage();
  const [data, setData] = useState(props["data"]);
  const name =
    (data && data[`name_${language}`]) ||
    intl.formatMessage({ id: "CLIENT.DASHBOARD.MODAL.NEW_STATIC_TEXT" });
  const headerColor = (data && data["header_color"]) || "#1E1E2D";
  const showHeader =
    data && "show_header" in data ? data && data["show_header"] : true;

  useEffect(() => {
    setData(props["data"]);
  }, [props["data"]]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await workspaceDashboard.getDashboard(
          props.id,
          props.token
        );
        if (resp.success) {
          setData(resp.data);
        }
        setTimeout(() => fetchData(), 300000);
      } catch (error) {
        console.log(error);
      }
    };

    setTimeout(() => fetchData(), 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="static-text-card dashboard-item-card">
      {showHeader && (
        <p
          className="static-text-card-title"
          style={{
            backgroundColor: headerColor,
            color: invertColor(headerColor),
          }}
        >
          <TextFieldsIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}

      <Box className="static-text-card-body" mt={2}>
        <div className="static-text-card-content">
          {data && data["dashboard_static_text"]["content"] ? (
            <>
              {data &&
              data["dashboard_static_text"]["content_type"] === "HTML" ? (
                <div
                  className="static-text-html-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      data &&
                      data["dashboard_static_text"]["content"]
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">")
                        ?.trim(),
                  }}
                  style={{
                    paddingTop: showHeader ? 0 : 16,
                  }}
                ></div>
              ) : (
                <p className="static-text-text-content">
                  {data && data["dashboard_static_text"]["content"]}
                </p>
              )}
            </>
          ) : (
            <div className="no-data-area">
              <p className="no-data-label">
                <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
              </p>
            </div>
          )}
        </div>
      </Box>

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />
    </div>
  );
};

export default ValueCard;
