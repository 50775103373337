import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import { genders } from 'app/pages/Accounts/constants';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, shallowEqual, useSelector } from "react-redux";
import swal from 'sweetalert';
import * as Yup from "yup";
import DefaultAvatar from "../../../../assets/images/user.png";
import userApi from '../../../api/userApi';
import { updateUser } from "../../../redux/actions";
import DatePicker from '../../../shared/components/DatePicker';
import SharedInputField from '../../../shared/components/SharedInputField';
import SharedSelectField from '../../../shared/components/SharedSelectField';
import { getResponseMessage } from '../../../shared/utils/functions';
import { phoneRegExp } from '../../../shared/utils/validateReg';
import "./index.css";

const intitialValues = {
  full_name: "",
  gender: null,
  date_of_birth: null,
  phone_number: "",
  address: "",
  avatar: null,
  emergency_notification: true,
  warning_notification: true,
  operation_notification: true,
}

const ProfileEdit = (props) => {
  const { intl } = props;
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, user } = useSelector(
    ({ user }) => ({
      token: user.token,
      user: user.user
    }),
    shallowEqual
  );

  useEffect(() => {
    setAvatar(user.avatar ? `${process.env.REACT_APP_API_URL}/${user.avatar}` : null)
  }, [user.avatar])

  const schema = Yup.object().shape({
    full_name: Yup.string()
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      )
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      ),
    gender: Yup.string().nullable(),
    date_of_birth: Yup.date()
      .nullable()
      .max(new Date(),
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_DATE",
        })
      ),
    phone_number: Yup.string()
      .nullable()
      .matches(
        phoneRegExp,
        intl.formatMessage({
          id: "CLIENT.GENERAL.INVALID_PHONE_NUMBER_FORMAT",
        })
      )
      .min(3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
    address: Yup.string()
      .nullable()
      .max(255,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS_255",
        })
      ),
    avatar: Yup.mixed()
      .test(
        "fileSize",
        intl.formatMessage({ id: "CLIENT.GENERAL.MAX_DIMENSIONS_IMAGE" }),
        value => value ? value.size <= 600 * 100 : true
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ...intitialValues,
      full_name: user.fullname,
      gender: user.gender,
      date_of_birth: user.birthday,
      phone_number: user.phone,
      address: user.address,
      emergency_notification: user.emergency_notification,
      warning_notification: user.warning_notification,
      operation_notification: user.operation_notification,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        enableLoading();
        const postData = {
          fullname: values.full_name,
          gender: values.gender,
          birthday: values.date_of_birth ? moment(values.date_of_birth).format("YYYY-MM-DD") : undefined,
          address: values.address,
          phone: values.phone_number,
          emergency_notification: values.emergency_notification,
          warning_notification: values.warning_notification,
          operation_notification: values.operation_notification,
        }
        const res = await userApi.updateProfile(postData, token)
        if (avatar && !values["avatar"]) {
          if (res.success) {
            props.updateUser(res.data)
            const message = intl.formatMessage({
              id: "CLIENT.PROFILE.UPDATE_PROFILE_SUCCESS"
            });

            swal({
              title: intl.formatMessage({
                id: "CLIENT.PROFILE.UPDATE_PROFILE_SUCCESS_TITLE"
              }),
              text: message,
              icon: 'success',
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON"
              })
            })
          } else {
            const message = intl.formatMessage({
              id: "CLIENT.PROFILE.UPDATE_PROFILE_FAILED"
            });

            swal({
              title: intl.formatMessage({
                id: "CLIENT.PROFILE.UPDATE_PROFILE_FAILED_TITLE"
              }),
              text: message,
              icon: 'error',
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON"
              })
            })
          }
        } else {
          const form = new FormData();
          form.append("avatar", values["avatar"] || "")
          const upload = await userApi.uploadAvatar(form, token)
          if (res.success && upload.success) {
            props.updateUser({
              ...res.data,
              avatar: upload?.data?.avatar
            })
            const message = intl.formatMessage({
              id: "CLIENT.PROFILE.UPDATE_PROFILE_SUCCESS"
            });

            swal({
              title: intl.formatMessage({
                id: "CLIENT.PROFILE.UPDATE_PROFILE_SUCCESS_TITLE"
              }),
              text: message,
              icon: 'success',
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON"
              })
            })
          } else {
            const message = intl.formatMessage({
              id: "CLIENT.PROFILE.UPDATE_PROFILE_FAILED"
            });

            swal({
              title: intl.formatMessage({
                id: "CLIENT.PROFILE.UPDATE_PROFILE_FAILED_TITLE"
              }),
              text: message,
              icon: 'error',
              button: intl.formatMessage({
                id: "CLIENT.GENERAL.OK_BUTTON"
              })
            })
          }
        }
      } catch (error) {
        const message = intl.formatMessage({
          id: getResponseMessage(error.response && error.response.data.code)
        });
        swal({
          title: intl.formatMessage({
            id: "CLIENT.PROFILE.UPDATE_PROFILE_FAILED_TITLE"
          }),
          text: message,
          icon: 'error',
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON"
          })
        })
      } finally {
        disableLoading();
        setSubmitting(false);
      }
    },
  });

  const handleLogoChange = (event) => {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setAvatar(reader.result);
    }
    formik.setFieldValue("avatar", file)
  }

  const handleImageCancel = () => {
    setAvatar(null);
    formik.setFieldValue("avatar", null)
  }

  return (

    <Container className="enduser-page">
      <div className="main-content enduser profile">
        <h2 className="main-title">
          <FormattedMessage id="CLIENT.PROFILE.PAGE_TITLE" />
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <Box className="form-label">
                <FormattedMessage id="CLIENT.PROFILE.AVATAR_LABEL" />
              </Box>
              <Box>
                <p style={{ fontWeight: 300 }}><FormattedMessage id="CLIENT.PROFILE.AVATAR_DES" /> <strong>600 x 100px</strong>.</p>
                <Box mt={3} className='avatar-image-wrapper'>
                  {avatar === null && <img src={DefaultAvatar} alt="Avatar" className='image-uploader-icon' />}
                  {avatar !== null && <img src={avatar} alt="Avatar" className="image-uploader-image" />}
                </Box>
                {
                  formik.errors["avatar"] &&
                  <Box>
                    <FormHelperText className="error-message">{formik.errors["avatar"]}</FormHelperText>
                  </Box>
                }
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between" className="upload-wrapper">
                  <Box mb={2} width="48%">
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/tif, image/tiff .tif"
                      id="logo-file"
                      name="avatar"
                      hidden
                      onChange={handleLogoChange}
                      onClick={(event) => {
                        event.target.value = null
                      }}
                    />
                    <label
                      htmlFor="logo-file"
                      className="custom-button light-button custom-upload"
                      style={{ marginBottom: "0", display: "flex", cursor: "pointer", height: "100%", alignItems: "center", justifyContent: "center" }}
                    >
                      <FormattedMessage id="CLIENT.ORGANIZATION_ACTIONS.UPLOAD" />
                    </label>
                  </Box>
                  <Box mb={2} width="48%">
                    <Button
                      className="custom-button light-button custom-upload"
                      type="button"
                      onClick={handleImageCancel}
                      style={{ width: "100%" }}
                    >
                      <FormattedMessage id="CLIENT.ORGANIZATION_ACTIONS.CANCEL" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Box mb={3}>
                <SharedInputField
                  name="full_name"
                  label={intl.formatMessage({ id: 'CLIENT.PROFILE.FULL_NAME_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.PROFILE.FULL_NAME_HINT' })}
                  {...formik.getFieldProps("full_name")}
                  disabled={formik.isSubmitting}
                  required
                  errorMsg={formik.touched["full_name"] && formik.errors["full_name"]}
                />
              </Box>
              <Box mb={3}>
                <SharedSelectField
                  className="text-normal"
                  disabled={formik.isSubmitting}
                  label={intl.formatMessage({ id: "CLIENT.PROFILE.GENDER_LABEL" })}
                  disableClearable
                  name="gender"
                  options={genders.map((item) => ({
                    id: item.value,
                    value: item.value,
                    label: intl.formatMessage({ id: item.label }),
                  }))}
                  defaultValue={formik.values["gender"]}
                  onChange={formik.setFieldValue}
                />
              </Box>
              <Box mb={3}>
                <DatePicker
                  name="date_of_birth"
                  label={intl.formatMessage({ id: 'CLIENT.PROFILE.DOB_LABEL' })}
                  type="date"
                  placeholder={intl.formatMessage({ id: 'CLIENT.PROFILE.DOB_HINT' })}
                  value={formik.values["date_of_birth"]}
                  disabled={formik.isSubmitting}
                  onChange={formik.setFieldValue}
                  currentError={formik.errors["date_of_birth"]}
                  setFieldError={formik.setFieldError}
                />
              </Box>
              <Box mb={3}>
                <SharedInputField
                  name="phone_number"
                  label={intl.formatMessage({ id: 'CLIENT.PROFILE.PHONE_NUMBER_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.PROFILE.PHONE_NUMBER_HINT' })}
                  {...formik.getFieldProps("phone_number")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["phone_number"] && formik.errors["phone_number"]}
                />
              </Box>
              <Box mb={5}>
                <SharedInputField
                  name="address"
                  label={intl.formatMessage({ id: 'CLIENT.PROFILE.ADDRESS_LABEL' })}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'CLIENT.PROFILE.ADDRESS_HINT' })}
                  {...formik.getFieldProps("address")}
                  disabled={formik.isSubmitting}
                  errorMsg={formik.touched["address"] && formik.errors["address"]}
                />
              </Box>
              <Box mb={3}>
                <h3 className="sub-title">
                  <FormattedMessage id="CLIENT.PROFILE.NOTIFICATION_SETTINGS" />
                </h3>
                <p>
                  <FormattedMessage id="CLIENT.PROFILE.NOTIFICATION_SETTINGS_DESC" />
                </p>
                <Box display="flex" justifyContent="space-between">
                  <Box width="33%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formik.values["emergency_notification"]}
                          {...formik.getFieldProps('emergency_notification')}
                          name="emergency_notification"
                          color="primary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'CLIENT.PROFILE.EMERGENCY',
                      })}
                    />
                  </Box>
                  <Box width="33%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formik.values["warning_notification"]}
                          {...formik.getFieldProps('warning_notification')}
                          name="warning_notification"
                          color="primary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'CLIENT.PROFILE.WARNING',
                      })}
                    />
                  </Box>
                  <Box width="33%">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={formik.values["operation_notification"]}
                          {...formik.getFieldProps('operation_notification')}
                          name="operation_notification"
                          color="primary"
                        />
                      }
                      label={intl.formatMessage({
                        id: 'CLIENT.PROFILE.OPERATION',
                      })}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-end" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Button
                    className="custom-button"
                    type="submit"
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid
                    }
                  >
                    <FormattedMessage id="CLIENT.GENERAL.ACTION_SAVE" />
                    {loading && <CircularProgress color="inherit" size={16} style={{ marginLeft: "5px" }} />}
                  </Button>
                </Box>
                <Box className="note-msg">
                  *<FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default injectIntl(connect(null, { updateUser })(ProfileEdit));
