import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useIntl } from "react-intl";
import { useLanguage } from "app/shared/hooks";
import VI_FLAG from "../../../../../../assets/images/vi-flag.svg";
import EN_FLAG from "../../../../../../assets/images/en-flag.svg";
import { LOCALES } from "../../../../../languages/constants";
import userApi from "app/api/userApi";
import { shallowEqual, useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const MenuItem = ({
  label = "",
  icon = "",
  active = false,
  onClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <ListItem button onClick={onClick} style={drawerStyle.listItem}>
      <ListItemIcon classes={{ root: classes.root }}>
        <Avatar alt="" src={icon} style={commonStyle.flagIcon} />
      </ListItemIcon>

      <ListItemText
        classes={{
          primary: active ? classes.activeItemText : classes.itemText,
        }}
        primary={label}
      />

      {active && (
        <ListItemIcon classes={{ root: classes.root }}>
          <CheckIcon style={commonStyle.tickIcon} />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export const MobileLanguageSelector = ({
  visible = false,
  onClose = () => {},
}) => {
  const intl = useIntl();
  const { user, token } = useSelector(
    ({ user }) => ({
      user: user.user,
      token: user.token,
    }),
    shallowEqual,
  );
  const [language, setLanguage] = useLanguage(user && user.language);

  const LANGUAGES = [
    {
      id: 1,
      label: intl.formatMessage({
        id: "GENERAL.VIETNAMESE",
      }),
      icon: VI_FLAG,
      code: LOCALES.VIETNAMESE,
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: "GENERAL.ENGLISH",
      }),
      icon: EN_FLAG,
      code: LOCALES.ENGLISH,
    },
  ];

  const onSelectLanguage = async (code) => {
    onClose();

    if (code === language) {
      return;
    }

    try {
      if (token) {
        const res = await userApi.changeLanguage(token);

        if (res.success) {
          setLanguage(code);
        }
      } else {
        setLanguage(code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      anchor="bottom"
      open={visible}
      onClose={onClose}
      PaperProps={{
        style: drawerStyle.paperProps,
      }}
    >
      <Box role="presentation" onClick={onClose}>
        <Box style={commonStyle.menuHeader}>
          <h3 style={commonStyle.menuHeaderText}>
            {intl.formatMessage({
              id: "CLIENT.MOBILE_LANGUAGE_SELECTOR.TITLE",
            })}
          </h3>

          <CloseIcon style={commonStyle.closeIcon} />
        </Box>

        <Divider style={commonStyle.divider} />

        <List style={drawerStyle.list}>
          {LANGUAGES.map((item, index) => (
            <>
              <MenuItem
                key={item.id}
                label={item?.label}
                icon={item?.icon}
                code={item?.code}
                active={language === item?.code}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectLanguage(item?.code);
                }}
              />

              {index !== LANGUAGES?.length - 1 && (
                <Divider style={commonStyle.divider} />
              )}
            </>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles({
  activeItemText: {
    fontSize: "16px !important",
    fontWeight: "bold",
  },
  itemText: {
    fontSize: "16px !important",
  },
  root: {
    minWidth: "auto",
  },
});

const drawerStyle = {
  paperProps: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    paddingBottom: "16px",
  },
  list: {
    paddingTop: "8px",
    paddingBottom: "0px",
  },
  listItem: {
    padding: "12px 24px",
  },
  listItemText: {
    fontSize: "16px",
  },
};

const commonStyle = {
  divider: {
    margin: "5px 0",
  },
  menuHeader: {
    position: "relative",
  },
  menuHeaderText: {
    textAlign: "center",
    padding: "6px 0",
    fontSize: 16,
  },
  tickIcon: {
    color: "#3699FF",
  },
  closeIcon: {
    position: "absolute",
    right: 24,
    top: "50%",
    transform: "translateY(-50%)",
    color: "#9C9C9C",
    cursor: "pointer",
  },
  flagIcon: {
    border: "rgba(255, 255, 255, 0.5)",
    marginRight: 12,
    width: 32,
    height: 32,
    cursor: "pointer",
  },
};
