import React from 'react';
import StandardChartSection from '../StandardChartSection';

import './styles.css';

export default function MenuModal(props) {
  const handleChange = (newData) => {
    props['handleEdit'](props['id'], newData);
  };
  
  return (
    <div
      className="chart-modal-body-wrapper"
      style={{
        border: '1px solid #e2e2e2',
        padding: 24,
        paddingTop: 6,
        paddingBottom: 32,
        background: 'rgb(241 241 241)',
        borderTop: '1px dashed gray',
      }}
    >
      <div className="chart-modal-body">
        <StandardChartSection 
          data={props['data']} 
          onChange={handleChange} 
          parameters={props["parameters"]} 
          setForms={props["setForms"]}
          id={props["id"]}
          charts={props["charts"]}
        />
      </div>
    </div>
  );
}
