import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import SelectParamsByStaionDropdown from "app/shared/components/SelectParamsByStaionDropdown";
import SharedSelectField from "app/shared/components/SharedSelectField";
import useDataAlarm from "app/shared/hooks/DataAlarm/data-alarm.hook";
import { FormattedMessage } from "react-intl";
import { AlarmColorField, MainColorField } from "../../common";
import { DIRECTION_OPTIONS } from "../contants";

const DirectionForm = ({ formik, intl, parameters }) => {
  const { isLoadingDataAlarm, getDataAlarm } = useDataAlarm();

  async function onChangeParameter(stationId, parameterId) {
    const dataAlarm = await getDataAlarm(parameterId);
    formik.setValues((pre) => ({
      ...pre,
      parameter_id: parameterId,
      station_id: stationId,
      data_alarm: dataAlarm,
    }));
  }

  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        <ConfigureArea
          title={intl.formatMessage({
            id: "CLIENT.PARAMETERS_ACTIONS.PARAMETERS",
          })}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box mb={3} width="49%">
              <Box className="form-label">
                <p className="required">*</p>
                <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
              </Box>
              <SelectParamsByStaionDropdown
                data={parameters || []}
                formik={formik}
                disabled={isLoadingDataAlarm}
                value={{
                  parameterId: formik.values["parameter_id"],
                  stationId: formik.values["station_id"],
                }}
                onChange={({ stationId, parameterId }) =>
                  onChangeParameter(stationId, parameterId)
                }
              />

              {formik.touched["parameter_id"] &&
                formik.errors["parameter_id"] && (
                  <FormHelperText className="error-message">
                    {formik.errors["parameter_id"]}
                  </FormHelperText>
                )}
            </Box>
            <Box mb={3} width="49%">
              <SharedSelectField
                className="bg-white"
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.NUMBER_OF_DIRECTIONS",
                })}
                placeholder={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.NUMBER_OF_DIRECTIONS_HINT",
                })}
                disableClearable
                name="number_of_direction"
                options={DIRECTION_OPTIONS}
                defaultValue={formik.values["number_of_direction"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
              />
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MainColorField formik={formik} />
            </Grid>
            <Grid item xs={3}>
              <AlarmColorField formik={formik} />
            </Grid>
          </Grid>
        </ConfigureArea>

        <ConfigureArea
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.FEATURE",
          })}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values["show_number"]}
                onChange={(e) =>
                  formik.setFieldValue("show_number", e.target.checked)
                }
              />
            }
            label={intl.formatMessage({
              id: "CLIENT.DASHBOARD.SHOW_NUMBERS",
            })}
          />
        </ConfigureArea>
      </form>
    </div>
  );
};

export default DirectionForm;
