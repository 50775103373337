import { Box, Avatar } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import styles from "./User.module.css";

const User = ({ onToggleRightDrawer = () => {} }) => {
  const intl = useIntl();
  const { user } = useSelector(
    ({ user }) => ({
      user: user.user,
      role: user.role,
    }),
    shallowEqual,
  );

  return (
    <Box className={styles.container} onClick={onToggleRightDrawer}>
      {user?.avatar !== "" ? (
        <Avatar
          alt=""
          src={`${process.env.REACT_APP_API_URL}/${user?.avatar}`}
        />
      ) : (
        <Avatar>{user.fullname.charAt(0)}</Avatar>
      )}

      <Box className={styles.text}>
        <span className={styles.greeting}>
          {intl.formatMessage({
            id: "CLIENT.GENERAL.HI",
          })}
          ,
        </span>
        <span className={styles.fullName}>{user.fullname}</span>
      </Box>
    </Box>
  );
};

export default User;
