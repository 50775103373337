import axiosClient from "./axiosClient";

const dashboardSecurityApi = {
  addUser(dashboardId, data, token) {
    const url = `/api/v1/workspace-dashboard/security/${dashboardId}`;
    return axiosClient.post(url, data, { headers: { "Authorization" : `Bearer ${token}` }})
  },
  getAll(dashboardId, params, token) {
    const url = `/api/v1/workspace-dashboard/security/get-all-security/${dashboardId}`;
    return axiosClient.get(url, { headers: { "Authorization" : `Bearer ${token}` }, params})
  },
  deleteSecurity(id, token) {
    const url = `/api/v1/workspace-dashboard/security/${id}`;
    return axiosClient.delete(url, { headers: {"Authorization" : `Bearer ${token}`}});
  },
}

export default dashboardSecurityApi