import React, { useEffect } from "react";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import {
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  ToggleButton,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import DashboardHistoricTable from "app/shared/components/Dashboard/DashboardHistoricTable";
import { AGGREGATE_TABLE } from "../contants";
import DateRangeModal from "app/shared/components/Dashboard/DateRangeModal";
import DateRangePicker from "app/shared/components/DateRangePicker";
import moment from "moment";
import { CheckBox } from "@material-ui/icons";
import DashboardAggregateTable from "app/shared/components/Dashboard/DashboardAggregateTable";
import SharedMultiSelect from "app/shared/components/SharedLiteMultiSelect";
import _ from "lodash";
import TimeRangeSeletor from "app/shared/components/Dashboard/DataModeConfigurator/TimeRangeSeletor";
import { DATE_FORMAT } from "app/constant/date-time-format";


const FUNCTION_OPTIONS = _.keyBy(AGGREGATE_TABLE.FUNCTIONS, "value");


const AggregateTableForm = ({ formik, intl, parameters, language }) => {
  const DISPLAY_MODES = [
    {
      label: intl.formatMessage({ id: "CLIENT.DASHBOARD.AGGREGATE_TABLE.PORTRAIT" }),
      value: "portrait",
    },
    {
      label: intl.formatMessage({ id: "CLIENT.DASHBOARD.AGGREGATE_TABLE.LANDSCAPE" }),
      value: "landscape",
    },
  ];
  const handleToggleOption = (option) => {
    formik.setFieldValue("functions", option.map((item) => item.value));


  };

  const handleClearOptions = () => {
    formik.setFieldValue("functions", []);
  };

  const handleSelectAll = () => {
    const { functions } = formik.values;
    if (functions.length === AGGREGATE_TABLE.FUNCTIONS.length) {
      formik.setFieldValue("functions", []);
    } else {
      formik.setFieldValue(
        "functions",
        AGGREGATE_TABLE.FUNCTIONS.map((item) => item.value)
      );
    }
  };

  const handleSelectParam = (options) => {
    if (options.length === 0) {
      handleClearOptions();
    }
  };
  

  return (
    <div className="value-modal-body">
    <form onSubmit={formik.handleSubmit}>
      <ConfigureArea
        mb={30}
        title={intl.formatMessage({
          id: "CLIENT.DASHBOARD.HEADER_LABEL",
        })}
      >
        <HeaderConfigurator formik={formik} />

        <Grid item xs={12} style={{ marginTop: 16 }}>
            <Box className="form-label">
              <FormattedMessage id="CLIENT.DASHBOARD.DISPLAY_MODE" />
            </Box>

            <RadioGroup
              className="radio-group"
              {...formik.getFieldProps("display_mode")}
            >
              {DISPLAY_MODES.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  disabled={formik.isSubmitting}
                  control={<Radio size="small" color="primary" />}
                  label={item?.label}
                />
              ))}
            </RadioGroup>
          </Grid>
      </ConfigureArea>


      <Box mb={3}>
        <Grid container>
          <Grid item xs={12} className="date-picker-component" >
            <TimeRangeSeletor
                startTime={moment(formik.values?.start_date)}
                endTime={moment(formik.values?.end_date)}
                onChange={(field, value) => {
                  formik.setFieldValue(field, value);
                }}
                field={{
                  startTime: "start_date",
                  endTime: "end_date"
                }}
                error={formik.errors["start_date"] || formik.errors["end_date"]}
              />
          </Grid>
          <Grid item xs={12} className="select-function-component">
            <div className="select-function-title">
              <p className="required">*</p>
              <FormattedMessage id="CLIENT.DASHBOARD.AGGREGATE_TABLE.FUNCTION" />
            </div>
            <Box className="select-function-body">
              <SharedMultiSelect
                placeholder={
                  formik.values["functions"]?.length === 0
                    ? intl.formatMessage({
                        id: "CLIENT.DASHBOARD.AGGREGATE_TABLE.CHOICE_FUNCTION",
                      })
                    : ""
                  }
                error={formik.errors["functions"]}
                items={AGGREGATE_TABLE.FUNCTIONS.map((item) => ({
                  label: item[`name_${language}`],
                  value: item.value,
                }))}
                selectedValues={formik.values["functions"]?.map((item) => {
                  return {
                    label: FUNCTION_OPTIONS[item][`name_${language}`],
                    value: item,
                  };
                }) || []}
                getOptionLabel={(option) => `${option.label}`}

                selectAllLabel={intl.formatMessage({
                  id: "CLIENT.GENERAL.SELECT_ALL_LABEL",
                })}
                noOptionsText={intl.formatMessage({
                  id: "CLIENT.GENERAL.NO_OPTION",
                })}
                onToggleOption={handleToggleOption}
                onClearOptions={handleClearOptions}
                onSelectAll={handleSelectAll}
                onSelect={handleSelectParam}
                className="text-normal"
                label=""
                defaultRenderTags
                limitTags={7}
              />
            </Box>
          </Grid>
        </Grid>

        <DashboardAggregateTable
          formik={formik}
          onChange={(data) => {
            formik.setFieldValue("params", data);
          }}
          intl={intl}
          data={formik.values["params"]}
          pickColors={true}
          stations={parameters || []}
        />
      </Box>
    </form>
  </div>
  );
};

export default AggregateTableForm;
