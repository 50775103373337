import axiosClient from "./axiosClient";

const authApi = {
  register(data) {
    const url = "/api/v1/users/signup";
    return axiosClient.post(url, data);
  },
  login(data) {
    const url = "/api/v1/users/login";
    return axiosClient.post(url, data);
  },
  forgotPassword(params) {
    const url = "/api/v1/users/forgot-password";
    return axiosClient.get(url, { params });
  },
  resetPassword(data) {
    const url = "/api/v1/users/change-forgot-password";
    return axiosClient.put(url, data);
  },
  logout() {
    const url = "/api/v1/users/logout";
    return axiosClient.post(url);
  }
}

export default authApi;
