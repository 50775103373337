import { lazy } from "react";
import { Route } from "react-router-dom";
import Loadable from "app/shared/components/Loader/Loadable";
import Nav from "app/shared/layouts/MainLayout/Nav";

const NotFound = Loadable(lazy(() => import("app/pages/NotFound")));

export const RouteWithNavBar = ({
  component: Component,
  isAllowed,
  ...rest
}) => {
  if (isAllowed) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Component {...props} />
              <Nav />
            </>
          );
        }}
      />
    );
  }

  return <Route component={NotFound} />;
};
