import { CircularProgress } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import { invertColor } from "app/utils/utils";
import _, { keyBy } from "lodash";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import workspaceDashboardApi from "app/api/workspaceDashboardApi";
import { DATE_TIME_FORMAT } from "app/constant/date-time-format";
import { formatString } from "app/utils/string-formatter";
import { useLanguage } from "app/shared/hooks";
import { DashboardCardButtons } from "../DashboardCardButtons";
import "./styles.css";
import { TABLE_ALARM_COLOR } from "app/shared/constants/dashboard";

const ValueListCard = (props) => {
  const [language] = useLanguage();
  const name = (props["data"] && props["data"][`name_${language}`]) || (
    <FormattedMessage id="CLIENT.DASHBOARD.ITEM.VALUE_LIST_NEW" />
  );
  const headerColor =
    (props["data"] && props["data"]["header_color"]) || "#1E1E2D";
  const showHeader =
    props["data"] && "show_header" in props["data"]
      ? props["data"] && props["data"]["show_header"]
      : true;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [dataAlarm, setDataAlarm] = useState({});

  const { token } = useSelector(
    ({ user }) => ({
      token: user.token,
    }),
    shallowEqual
  );

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const loadDataDashboard = async (id) => {
    enableLoading();
    try {
      let res;
      if (props.isPublic) {
        res = await workspaceDashboardApi.getPublicDashboardData(id);
      } else {
        res = await workspaceDashboardApi.getDashboardData(id, token);
      }
      if (res.success) {
        return res.data;
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
    return null;
  };

  const prepareDataObject = (parameterDatas) => {
    // const totalParameters = parameterDatas?.length;

    let result = {};
    _.forEach(parameterDatas, (paramData) => {
      const dataPoint = paramData.dataPointDtos?.at(-1);
      const parameterDto = paramData.parameterDto;
      result = {
        ...result,
        [parameterDto.parameterId]: {
          ...dataPoint,
          status: dataPoint?.[`name_${language}`],
        },
      };
    });

    // for (let paramIndex = 0; paramIndex < totalParameters; paramIndex++) {
    //   const item =
    //     parameterDatas?.[paramIndex]?.dataPointDtos?.[
    //     parameterDatas?.[paramIndex]?.dataPointDtos?.length - 1
    //     ];
    //   result = {
    //     ...result,
    //     [item.parameterId]: {
    //       ...item,
    //       status: item?.[`name_${language}`],
    //     },
    //   };
    // }

    return result;
  };

  const getListKeysSorted = (dataObject, parameterInfo, sortBy) => {
    if (sortBy === "name") {
      return Object.keys(dataObject).sort((a, b) =>
        ("" + parameterInfo[a]?.name).localeCompare(parameterInfo[b]?.name)
      );
    }

    if (sortBy === "status") {
      return Object.keys(dataObject).sort((a, b) =>
        ("" + dataObject[a]?.status).localeCompare(dataObject[b]?.status)
      );
    }

    if (sortBy === "time") {
      return Object.keys(dataObject).sort(
        (a, b) =>
          new Date(dataObject[b]?.eventTime) -
          new Date(dataObject[a]?.eventTime)
      );
    }

    return Object.keys(dataObject).sort(
      (a, b) => Number(dataObject[a]?.value) - Number(dataObject[b]?.value)
    );
  };

  const sortKey = (keys) => {
    let tmp = [];
    keys?.map((e) => {
      tmp.push({
        key: e,
        order_number: data?.parameterInfo[e].order_number,
      });
    });
    tmp.sort(function (a, b) {
      return a.order_number - b.order_number;
    });
    keys = tmp.map((e) => {
      return e?.key;
    });
    return keys;
  };

  useEffect(() => {
    const initData = async () => {
      const dashboardData = await loadDataDashboard(props.data?.id);

      if (!dashboardData) return;

      const parameterInfo = {};
      let alarmList = [];
      props.data.dashboard_value_list.dashboard_value_list_parameter?.forEach(
        (item) => {
          parameterInfo[item?.parameter?.id] = {
            id: item?.parameter?.id,
            name:
              language === "en"
                ? item?.parameter?.name
                : item?.parameter?.name_vi,
            main_color: item.main_color,
            alarm_color: item.alarm_color,
            unit: item?.parameter?.unit,
            format: item?.parameter?.parameter_format_text,
            display_name: item?.display_name,
            order_number: item?.order_number,
          };

          alarmList = alarmList.concat(item.parameter?.data_alarm);
        }
      );
      let dataObject = prepareDataObject(dashboardData?.parameterDatas || []);

      const data = {
        data: dashboardData,
        dataObject,
        parameterInfo,
      };
      setData(data);
      setDataAlarm(keyBy(alarmList, "id"));
    };

    if (props.data && props.data.dashboard_value_list) {
      initData();
    }
  }, [props["data"]]);

  // Receive data realtime
  useEffect(() => {
    if (
      props.realTimeData?.eventTime &&
      props.realTimeData?.paramId &&
      data?.dataObject?.[props.realTimeData?.paramId]
    ) {
      const dataObject = {
        ...data?.dataObject,
        [props.realTimeData?.paramId]: {
          ...data?.dataObject?.[props.realTimeData?.paramId],
          isAlarm: false,
          eventTime: props.realTimeData?.eventTime,
          value: props.realTimeData?.value,
          status: "",
        },
      };

      setData((previous) => ({
        ...previous,
        dataObject: {
          ...previous.dataObject,
          ...dataObject,
        },
      }));
    }
  }, [props.realTimeData?.eventTime, props.realTimeData?.paramId]);

  // Received alarm realtime
  useEffect(() => {
    if (
      props.realTimeDataAlarm?.eventTime &&
      props.realTimeDataAlarm?.paramId &&
      data?.dataObject?.[props.realTimeDataAlarm?.paramId]
    ) {
      const dataObject = {
        ...data?.dataObject,
        [props.realTimeDataAlarm?.paramId]: {
          ...data?.dataObject?.[props.realTimeDataAlarm?.paramId],
          isAlarm: true,
          eventTime: props.realTimeDataAlarm?.eventTime,
          value: props.realTimeDataAlarm?.value,
          status: props.realTimeDataAlarm?.[`name_${language}`],
        },
      };

      setData((previous) => ({
        ...previous,
        dataObject: {
          ...previous.dataObject,
          ...dataObject,
        },
      }));
    }
  }, [props.realTimeDataAlarm?.eventTime, props.realTimeDataAlarm?.paramId]);

  useEffect(() => {
    if (
      data?.dataObject &&
      data?.parameterInfo &&
      props.data?.dashboard_value_list
    ) {
      let keys = getListKeysSorted(
        data?.dataObject,
        data?.parameterInfo,
        props.data?.dashboard_value_list?.sort_parameter_by
      );

      setData((previous) => ({
        ...previous,
        keys: sortKey(keys),
      }));
    }
  }, [data?.dataObject, data?.parameterInfo, props.data?.dashboard_value_list]);

  const Row = ({ index, style }) => {
    const key = data?.keys?.[index];
    const background = data?.dataObject[key]?.isAlarm
      ? dataAlarm?.[data?.dataObject[key]?.alarmId]?.alarm_color ||
        TABLE_ALARM_COLOR
      : data?.parameterInfo[key]?.main_color;
    const textColor = invertColor(background, true);
    const borderBottomColor = invertColor(textColor, true);
    return (
      <table style={style} className="value-list-table">
        <tr>
          <td
            style={{
              fontWeight: "bold",
              background: background,
              color: textColor,
              borderColor: textColor,
              borderBottomColor: borderBottomColor,
            }}
          >
            {data?.parameterInfo[key]?.display_name || ""}
          </td>
          <td
            style={{
              background: background,
              color: textColor,
              borderColor: textColor,
              borderBottomColor: "#fff",
            }}
          >
            {isNaN(Number(data?.dataObject[key]?.value))
              ? data?.dataObject[key]?.value
              : data?.parameterInfo[key]?.format
              ? `${formatString(
                  data?.parameterInfo[key]?.format,
                  data?.dataObject[key]?.value
                )} (${data?.parameterInfo[key]?.unit})`
              : `${Number(data?.dataObject[key]?.value).toFixed(2)} (${
                  data?.parameterInfo[key]?.unit
                })`}
          </td>
          <td
            style={{
              background: background,
              color: textColor,
              borderColor: textColor,
              borderBottomColor,
            }}
          >
            {data?.dataObject[key]?.status || ""}
          </td>
          <td
            style={{
              background: background,
              color: textColor,
              borderColor: textColor,
              borderBottomColor,
            }}
          >
            {moment(data?.dataObject[key]?.eventTime).format(DATE_TIME_FORMAT)}
          </td>
        </tr>
      </table>
    );
  };

  return (
    <div className="value-list-card dashboard-item-card">
      {showHeader && (
        <p
          className="value-list-card-title"
          style={{
            background: headerColor,
            color: invertColor(headerColor, true),
          }}
        >
          <ViewListIcon />
          &nbsp;&nbsp;{name}
        </p>
      )}
      {props["loading"] || loading ? (
        <div className="no-data-area">
          <p className="no-data-label">
            <CircularProgress />
          </p>
        </div>
      ) : (
        <div className="value-list-card-content">
          {data?.dataObject &&
          Object.keys(data?.dataObject).length > 0 &&
          data?.keys ? (
            <>
              <table className="valuelist-table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
                    </th>
                    <th>
                      <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.DATA_THRESHOLD_VALUE_LABEL" />
                    </th>
                    <th>
                      <FormattedMessage id="CLIENT.PARAMETERS_MANAGEMENT.DISPLAY_TYPE_STATE_OPTION" />
                    </th>
                    <th>
                      <FormattedMessage id="CLIENT.GENERAL.TIME_LABEL" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        className="List"
                        height={height}
                        itemCount={data?.keys?.length}
                        itemSize={35}
                        width={width}
                      >
                        {Row}
                      </List>
                    )}
                  </AutoSizer>
                </tbody>
              </table>
            </>
          ) : (
            <div className="no-data-area">
              <p className="no-data-label">
                <FormattedMessage id="CLIENT.DASHBOARD.NO_DATA_LABEL" />
              </p>
            </div>
          )}
        </div>
      )}

      <DashboardCardButtons
        visible={!props?.isView}
        onEdit={props?.handleEdit}
        onDelete={props?.handleRemove}
      />

      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Line_chart_icon_Noun_70892_cc_White.svg/1200px-Line_chart_icon_Noun_70892_cc_White.svg.png"
        alt=""
        className="value-list-card-image"
      />
    </div>
  );
};

export default memo(ValueListCard);
